[
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Score": 99,
    "Course": "Masters in Financial Management",
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "Master of Human Resource Development (MHRD)",
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Big Data Analytics",
    "Category": "General"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Score": 97.5,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "SOIL Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Banking and Financial Services",
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Human Resources Management",
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in International Business",
    "Category": "General"
  },
  {
    "Name": "National Institute of Bank Management",
    "Score": 85,
    "Course": "PGDM in Banking and Financial Services",
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "MMS in Financial Management",
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Marketing",
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 90,
    "Course": "PGDM in International Business",
    "Category": "General"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "St Xavier's University, Kolkata",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "Akemi Business School",
    "Score": 82.75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "SIES College of Management Studies",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "General"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Score": 80,
    "Course": "MBA iConnect in Marketing",
    "Category": "General"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Atal Bihari Vajpayee Indian Institute of Information Technology and Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "IFIM School of Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "N.L. Dalmia Institute of Management Studies and Research",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Score": 80,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "National Insurance Academy- NIA",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Technology, Mesra",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 70,
    "Course": "PGDM in Retail Management",
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 70,
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Score": 70,
    "Course": "PGDM in International Business",
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Score": 70,
    "Course": "PGDM in Research and Business Analytics",
    "Category": "General"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Christ University",
    "Score": 70,
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Category": "General"
  },
  {
    "Name": "Delhi School of Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Calcutta Business School",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 70,
    "Course": "PGDM in Insurance Business Management",
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Score": 70,
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Score": 70,
    "Course": "PGDM in Advertising and Communication",
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Score": 70,
    "Course": "PGDM in Human Resource Management",
    "Category": "General"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Score": 70,
    "Course": "Master of Management Studies (MMS)",
    "Category": "General"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Score": 70,
    "Course": "PGDM in Entrepreneurship",
    "Category": "General"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Jaypee Business School",
    "Score": 65,
    "Course": "Master of Business Administration (MBA)",
    "Category": "General"
  },
  {
    "Name": "International Institute of Management Studies",
    "Score": 65,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "GIBS Business School",
    "Score": 60,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Marketing",
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Finance",
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 35,
    "Course": "Post Graduate Diploma in Rural Management",
    "Category": "General"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Score": 99,
    "Course": "Masters in Financial Management",
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "Master of Human Resource Development (MHRD)",
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Big Data Analytics",
    "Category": "OBC"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Banking and Financial Services",
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Human Resources Management",
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in International Business",
    "Category": "OBC"
  },
  {
    "Name": "National Institute of Bank Management",
    "Score": 85,
    "Course": "PGDM in Banking and Financial Services",
    "Category": "OBC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "MMS in Financial Management",
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Marketing",
    "Category": "OBC"
  },
  {
    "Name": "SOIL Institute of Management",
    "Score": 90,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "Atal Bihari Vajpayee Indian Institute of Information Technology and Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "St Xavier's University, Kolkata",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Score": 80,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "IFIM School of Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "N.L. Dalmia Institute of Management Studies and Research",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "OBC"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Score": 80,
    "Course": "MBA iConnect in Marketing",
    "Category": "OBC"
  },
  {
    "Name": "National Insurance Academy- NIA",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Calcutta Business School",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Birla Institute of Technology, Mesra",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Score": 70,
    "Course": "Master of Management Studies (MMS)",
    "Category": "OBC"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Score": 70,
    "Course": "PGDM in Entrepreneurship",
    "Category": "OBC"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Jaypee Business School",
    "Score": 65,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Score": 65,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Finance",
    "Category": "OBC"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 70,
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Category": "OBC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "Christ University",
    "Score": 70,
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Category": "OBC"
  },
  {
    "Name": "Delhi School of Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC"
  },
  {
    "Name": "EMPI Business School",
    "Score": 65,
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Marketing",
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 35,
    "Course": "Post Graduate Diploma in Rural Management",
    "Category": "OBC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Score": 99,
    "Course": "Masters in Financial Management",
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "Master of Human Resource Development (MHRD)",
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Big Data Analytics",
    "Category": "SC"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Banking and Financial Services",
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Human Resources Management",
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in International Business",
    "Category": "SC"
  },
  {
    "Name": "National Institute of Bank Management",
    "Score": 85,
    "Course": "PGDM in Banking and Financial Services",
    "Category": "SC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "MMS in Financial Management",
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Marketing",
    "Category": "SC"
  },
  {
    "Name": "SOIL Institute of Management",
    "Score": 90,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "Atal Bihari Vajpayee Indian Institute of Information Technology and Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "St Xavier's University, Kolkata",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Score": 80,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "IFIM School of Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "N.L. Dalmia Institute of Management Studies and Research",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "SC"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Score": 80,
    "Course": "MBA iConnect in Marketing",
    "Category": "SC"
  },
  {
    "Name": "National Insurance Academy- NIA",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Calcutta Business School",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Birla Institute of Technology, Mesra",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Score": 70,
    "Course": "Master of Management Studies (MMS)",
    "Category": "SC"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Score": 70,
    "Course": "PGDM in Entrepreneurship",
    "Category": "SC"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Jaypee Business School",
    "Score": 65,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Score": 65,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Finance",
    "Category": "SC"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 70,
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Category": "SC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "Christ University",
    "Score": 70,
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Category": "SC"
  },
  {
    "Name": "Delhi School of Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "SC"
  },
  {
    "Name": "EMPI Business School",
    "Score": 65,
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Marketing",
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 35,
    "Course": "Post Graduate Diploma in Rural Management",
    "Category": "SC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Score": 99,
    "Course": "Masters in Financial Management",
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "Master of Human Resource Development (MHRD)",
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Big Data Analytics",
    "Category": "ST"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Banking and Financial Services",
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Human Resources Management",
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in International Business",
    "Category": "ST"
  },
  {
    "Name": "National Institute of Bank Management",
    "Score": 85,
    "Course": "PGDM in Banking and Financial Services",
    "Category": "ST"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "MMS in Financial Management",
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Marketing",
    "Category": "ST"
  },
  {
    "Name": "SOIL Institute of Management",
    "Score": 90,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "Atal Bihari Vajpayee Indian Institute of Information Technology and Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "St Xavier's University, Kolkata",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Score": 80,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "IFIM School of Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "N.L. Dalmia Institute of Management Studies and Research",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "SIES College of Management Studies",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "ST"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Score": 80,
    "Course": "MBA iConnect in Marketing",
    "Category": "ST"
  },
  {
    "Name": "National Insurance Academy- NIA",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Calcutta Business School",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Birla Institute of Technology, Mesra",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Score": 70,
    "Course": "Master of Management Studies (MMS)",
    "Category": "ST"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Score": 70,
    "Course": "PGDM in Entrepreneurship",
    "Category": "ST"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Jaypee Business School",
    "Score": 65,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Score": 65,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Finance",
    "Category": "ST"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 70,
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Category": "ST"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "Christ University",
    "Score": 70,
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Category": "ST"
  },
  {
    "Name": "Delhi School of Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "ST"
  },
  {
    "Name": "EMPI Business School",
    "Score": 65,
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Marketing",
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 35,
    "Course": "Post Graduate Diploma in Rural Management",
    "Category": "ST"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Score": 99,
    "Course": "Masters in Financial Management",
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "Master of Human Resource Development (MHRD)",
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Big Data Analytics",
    "Category": "PWD"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Banking and Financial Services",
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Human Resources Management",
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in International Business",
    "Category": "PWD"
  },
  {
    "Name": "National Institute of Bank Management",
    "Score": 85,
    "Course": "PGDM in Banking and Financial Services",
    "Category": "PWD"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "MMS in Financial Management",
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Marketing",
    "Category": "PWD"
  },
  {
    "Name": "SOIL Institute of Management",
    "Score": 90,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "Atal Bihari Vajpayee Indian Institute of Information Technology and Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "St Xavier's University, Kolkata",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Score": 80,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "IFIM School of Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "N.L. Dalmia Institute of Management Studies and Research",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "SIES College of Management Studies",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "PWD"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Score": 80,
    "Course": "MBA iConnect in Marketing",
    "Category": "PWD"
  },
  {
    "Name": "National Insurance Academy- NIA",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Calcutta Business School",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Birla Institute of Technology, Mesra",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Score": 70,
    "Course": "Master of Management Studies (MMS)",
    "Category": "PWD"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Score": 70,
    "Course": "PGDM in Entrepreneurship",
    "Category": "PWD"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Jaypee Business School",
    "Score": 65,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Score": 65,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Finance",
    "Category": "PWD"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 70,
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Category": "PWD"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "Christ University",
    "Score": 70,
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Category": "PWD"
  },
  {
    "Name": "Delhi School of Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "PWD"
  },
  {
    "Name": "EMPI Business School",
    "Score": 65,
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Marketing",
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 35,
    "Course": "Post Graduate Diploma in Rural Management",
    "Category": "PWD"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Score": 99,
    "Course": "Masters in Financial Management",
    "Category": "EWS"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "Master of Human Resource Development (MHRD)",
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Big Data Analytics",
    "Category": "EWS"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Banking and Financial Services",
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Human Resources Management",
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in International Business",
    "Category": "EWS"
  },
  {
    "Name": "National Institute of Bank Management",
    "Score": 85,
    "Course": "PGDM in Banking and Financial Services",
    "Category": "EWS"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Score": 99,
    "Course": "MMS in Financial Management",
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 98.5,
    "Course": "PGDM in Healthcare Management",
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Score": 95,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Score": 95,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Score": 95,
    "Course": "MBA in Marketing",
    "Category": "EWS"
  },
  {
    "Name": "SOIL Institute of Management",
    "Score": 90,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "Atal Bihari Vajpayee Indian Institute of Information Technology and Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "St Xavier's University, Kolkata",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Score": 80,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "IFIM School of Management",
    "Score": 85,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "N.L. Dalmia Institute of Management Studies and Research",
    "Score": 85,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "SIES College of Management Studies",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "EWS"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Score": 80,
    "Course": "Post Graduate Diploma in Management",
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Score": 80,
    "Course": "MBA iConnect in Marketing",
    "Category": "EWS"
  },
  {
    "Name": "National Insurance Academy- NIA",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Calcutta Business School",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Birla Institute of Technology, Mesra",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Score": 75,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Score": 70,
    "Course": "Master of Management Studies (MMS)",
    "Category": "EWS"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Score": 70,
    "Course": "PGDM in Entrepreneurship",
    "Category": "EWS"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Jaypee Business School",
    "Score": 65,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Score": 65,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Finance",
    "Category": "EWS"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Score": 75,
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 70,
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Category": "EWS"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "Christ University",
    "Score": 70,
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Category": "EWS"
  },
  {
    "Name": "Delhi School of Business",
    "Score": 70,
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS"
  },
  {
    "Name": "EMPI Business School",
    "Score": 65,
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 45,
    "Course": "Post Graduate Diploma in Marketing",
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Score": 35,
    "Course": "Post Graduate Diploma in Rural Management",
    "Category": "EWS"
  }
]