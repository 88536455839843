// import React, { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate for navigation

// const AdmitCard = (props) => {
//   const navigate = useNavigate(); // Initialize navigate for tab navigation
//   const {examName} = useParams();
//   const [openFAQ, setOpenFAQ] = useState(null);

//   const faqData = [
//     { question: "What are the details mentioned on CAT admit card?", answer: "Details such as name, test date, test time, and center location." },
//     { question: "Can the test centre be changed after the release of CAT admit card?", answer: "No, the test centre cannot be changed after the admit card is issued." },
//     { question: "Will CAT exam 2024 also be conducted in three shifts?", answer: "Yes, CAT 2024 will be conducted in three shifts." },
//   ];

//   const toggleFAQ = (index) => {
//     setOpenFAQ(openFAQ === index ? null : index);
//   };

//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   // { name: "Question Papers", path: "/CatExam/questionpapers" },
//   //   // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" }
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   const admitCardData = [
//     { label: "CAT admit card download start date", value: "05-Nov-2024" },
//     { label: "Download start time", value: "4:00 pm" },
//     { label: "Download end date", value: "24-Nov-2024" },
//     { label: "CAT 2024 admit card release mode", value: "Online" },
//     { label: "CAT admit card login link", value: "iimcat.ac.in" },
//     { label: "Credentials required", value: "CAT login ID and password" },
//     { label: "Browsers for CAT hall ticket download", value: "Google Chrome, Mozilla Firefox, Internet Explorer" },
//     { label: "CAT help desk", value: "+91-080-26993388 / 3017 / 3013" }
//   ];

//   const examDates = [
//     { date: "05 Nov '24 - 24 Nov '24", event: "CAT 2024 admit card download" },
//     { date: "24 Nov '24", event: "CAT 2024 exam" },
//     // Add more objects as needed
//   ];

//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.AdmitcardHeaderHeading1} {props.AdmitcardYear} : {props.Mocktestaddwhichheading2}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.AdmitcardHeaderadmitcardname}: {props.AdmitcardHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.AdmitcardHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.AdmitcardHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)}
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       {/* Header Section */}
//       <div className="bg-gray-100 p-4 rounded-lg mb-6">
//         <h1 className="text-xl font-bold mb-2">{props.Admitcardheading1}</h1>
//         <p className="text-gray-600">
//         {props.Admitcardheading1Description1}
//         </p>
//       </div>

//       <div className="flex flex-col md:flex-row gap-4">
//         <div className="md:w-3/4">
//           {/* Admit Card Info */}
//           <div className="bg-white p-4 shadow rounded-lg mb-6">
//           <h2 className="text-lg font-semibold mb-4">{props.AdmitcardTable1heading1}</h2>
//           <table className="w-full text-sm text-left text-gray-700">
//             <tbody>
//               {admitCardData.map((item, index) => (
//                 <tr key={index} className="border-b">
//                   <td className="p-2 font-semibold">{item.label}</td>
//                   <td className="p-2">{item.value}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//           {/* Exam Dates Table */}
//           <div className="bg-white p-4 shadow rounded-lg mb-6">
//             <h2 className="text-lg font-semibold mb-4">{props.AdmitcardTable2heading2}</h2>
//              <table className="w-full border">
//       <thead>
//         <tr className="bg-gray-100">
//           <th className="p-2">{props.admitcardheading1Dates}</th>
//           <th className="p-2">{props.admitcardheading1UpcaomingDates}</th>
//         </tr>
//       </thead>
//       <tbody>
//         {examDates.map((exam, index) => (
//           <tr key={index} className="border-b">
//             <td className="p-2">{exam.date}</td>
//             <td className="p-2">{exam.event}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//           </div>

//           {/* FAQ Section */}
//           <div className="bg-white p-4 shadow rounded-lg mb-6">
//             <h2 className="text-lg font-semibold mb-4">Frequently Asked Questions</h2>
//             {faqData.map((faq, index) => (
//               <div key={index} className="border-b p-2 cursor-pointer" onClick={() => toggleFAQ(index)}>
//                 <h3 className="font-medium text-gray-700">{faq.question}</h3>
//                 {openFAQ === index && <p className="text-gray-600 mt-2">{faq.answer}</p>}
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Table of Contents */}
//         {/* <div className="md:w-1/4">
//           <div className="bg-white p-4 shadow rounded-lg">
//             <h2 className="text-lg font-semibold mb-4">Table of Contents</h2>
//             <ul className="text-sm text-gray-600 space-y-2">
//               <li className="cursor-pointer hover:text-blue-600">CAT 2024 Admit Card Highlights</li>
//               <li className="cursor-pointer hover:text-blue-600">CAT 2024 Exam Dates & Schedule</li>
//               <li className="cursor-pointer hover:text-blue-600">How to Download CAT 2024 Admit Card?</li>
//               <li className="cursor-pointer hover:text-blue-600">Problems in Downloading CAT Admit Card</li>
//               <li className="cursor-pointer hover:text-blue-600">CAT Exam Day Instructions</li>
//               <li className="cursor-pointer hover:text-blue-600">FAQs Regarding CAT 2024 Admit Card</li>
//             </ul>
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default AdmitCard;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AdmitCard = () => {
  const navigate = useNavigate();
  const { examName } = useParams();
  const [examData, setExamData] = useState(null);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error('Error fetching exam data:', error);
      }
    };

    fetchExamData();
  }, [examName]);

  if (!examData) {
    return <p>Loading...</p>;
  }
  const {
    year,
    admitCardName,
    admitCardDate,
    tabs,
    schedule,
    pastSchedule,
    faqs,
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
    examName: name,
    heading2Description
  } = examData;
  return (
    <div className="bg-gray-100 p-8">
      <div className="p-6 rounded-lg shadow-md bg-white">
      <div className="flex flex-col items-center justify-center px-4">
  <h1 className="text-xl sm:text-2xl font-bold text-gray-800 text-center flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
  {heading2Description && (
      <img
        src={heading2Description}
        alt="image"
        className="h-50 sm:h-12 w-auto" // Adjust size as needed
      />
    )}
    <span className="text-center">{name} {year}: Exam, Syllabus, Exam Pattern, Notification, Dates, Mock Test</span>
  </h1>
</div>
        <div className="flex items-center space-x-2 mt-4">
          {/* <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {examData.admitCardName}: {new Date(examData.admitCardDate).toLocaleDateString()}
          </div> */}
          <div className="ml-auto space-x-4">
            {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
              Download Guide
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Sample Papers
            </button> */}
          </div>
        </div>
      </div>

      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-4 overflow-x-auto">
          {examData.tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="mt-6">
        <h2 className="text-lg font-semibold mb-4">Admit Card Details</h2>
        <ul className="bg-white p-4 rounded-md shadow-md space-y-2">
          {examData.admitCardData.map((item, index) => (
            <li key={index} className="flex justify-between">
              <span>{item.label}</span>
              <span>{item.value}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-6">
        <h2 className="text-lg font-semibold mb-4">Exam Dates</h2>
        <ul className="bg-white p-4 rounded-md shadow-md space-y-2">
          {examData.examDates.map((item, index) => (
            <li key={index} className="flex justify-between">
              <span>{item.date}</span>
              <span>{item.event}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdmitCard;
