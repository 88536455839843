import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  List,
  Typography,
  Card,
  Modal,
  Form,
  message,
} from "antd";
import axios from "axios"; // Import axios for API request
import instituteData from "./llbpredictordata.json"; // Import the JSON file (update path as per location)

const { Title } = Typography;
const { Option } = Select;

const categoryFullForms = {
  NOKMAI: "Kashmiri Migrant",
  BCNOHS: "Delhi OBC",
  EWNOHS: "Delhi EWS",
  OPDFHS: "Delhi General Defence",
  OPNOHS: "Delhi General",
  OPPHHS: "Delhi General PWD",
  SCNOHS: "Delhi Scheduled Caste",
  STNOHS: "Delhi Scheduled Tribe",
  EWNOOS: "Outside Delhi EWS",
  OPDFOS: "Outside Delhi General Defence",
  OPNOOS: "Outside Delhi General",
  OPPHOS: "Outside Delhi General PWD",
  SCDFOS: "Outside Delhi Scheduled Caste Defence",
  SCNOOS: "Outside Delhi Scheduled Caste",
  STNOOS: "Outside Delhi Scheduled Tribe",
};
const PredictInstitute = () => {
  const [category, setCategory] = useState("");
  const [course, setCourse] = useState("");
  const [rank, setRank] = useState("");
  const [results, setResults] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasClickedPredictggsipullb, sethasClickedPredictggsipullb] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state

  useEffect(() => {
    const hasClicked = localStorage.getItem("hasClickedPredictggsipullb");
    if (hasClicked === "true") {
      sethasClickedPredictggsipullb(true); // If user has clicked Predict before, don't show the modal again
    }
  }, []);

  const handleModalSubmit = async (values) => {
    setLoading(true); // Set loading to true when form is being submitted
    console.log("Form Values:", values); // Log the form values to check if they are correct

    try {
      // Send form data to the API
      const response = await axios.post(
        "https://caderaedu.com/api/form/submit",
        values
      );
      console.log("API Response:", response); // Log the response from the API

      if (response.status === 201) {
        message.success("Successfully submitted!");
        localStorage.setItem("hasClickedPredictggsipullb", "true"); // Set to true to avoid showing modal again
        sethasClickedPredictggsipullb(true); // Update the state to avoid future modal shows

        // Close the modal after ensuring the form submission is successful
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error during form submission:", error); // Log the error if the request fails
      message.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after submission completes (either success or error)
    }
  };

  const handlePredict = () => {
    if (!hasClickedPredictggsipullb) {
      setIsModalVisible(true); // Show modal if it's the first time clicking
    } else if (category && course && rank) {
      // Ensure category, course, and rank are provided
      const filtered = instituteData.filter(
        (institute) =>
          institute.Category.toLowerCase() === category.toLowerCase() &&
          institute.Course.toLowerCase() === course.toLowerCase() &&
          parseInt(rank) <= institute.Rank
      );
      setResults(filtered);
    } else {
      message.warning("Please select a category, course, and enter a valid rank.");
    }
  };

  return (
    <>
      <Card
        style={{
          maxWidth: 600,
          margin: "50px auto",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          GGSIPU LLB Predictor
        </Title>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Category:</label>
          <Select
            style={{ width: "100%", marginTop: 5 }}
            value={category}
            onChange={(value) => setCategory(value)}
            placeholder="Select Category"
          >
            {[...new Set(instituteData.map((institute) => institute.Category))].map(
              (categoryName, index) => (
                <Option key={index} value={categoryName}>
                  {categoryFullForms[categoryName] || categoryName}
                </Option>
              )
            )}
          </Select>
        </div>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Course:</label>
          <Select
            style={{ width: "100%", marginTop: 5 }}
            value={course}
            onChange={(value) => setCourse(value)}
            placeholder="Select Course"
          >
            {[...new Set(instituteData.map((institute) => institute.Course))].map(
              (courseName, index) => (
                <Option key={index} value={courseName}>
                  {courseName}
                </Option>
              )
            )}
          </Select>
        </div>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Rank:</label>
          <Input
            type="number"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            placeholder="Enter your rank"
            style={{ marginTop: 5 }}
          />
        </div>

        <Button
          type="primary"
          block
          onClick={handlePredict}
          style={{ marginTop: 10, borderRadius: 5 }}
        >
          Predict
        </Button>

        {results.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            <Title level={4} style={{ textAlign: "center" }}>
              Matching Institutes:
            </Title>
            <List
              bordered
              dataSource={results}
              renderItem={(institute) => (
                <List.Item>
                  <strong>{institute.Institute}</strong> - {institute.Course} (
                  {categoryFullForms[institute.Category] || institute.Category})
                </List.Item>
              )}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : (
          <div style={{ marginTop: 30, textAlign: "center" }}>
            No matching institutes found.
          </div>
        )}
      </Card>

      <Modal
        title="Enter Details to use the predictor"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        closable={false}
      >
        <Form layout="vertical" onFinish={handleModalSubmit}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input type="email" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[
              { required: true, message: "Please enter your mobile number" },
            ]}
          >
            <Input type="tel" placeholder="Enter your mobile number" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="Enter your city" />
          </Form.Item>
          <Form.Item
            label="Course"
            name="courseInterestedIn"
            rules={[{ required: true, message: "Please enter your course" }]}
          >
            <Input placeholder="Enter your course" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading} // Disable button when loading
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PredictInstitute;
