import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};

const LatestNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch('https://caderaedu.com/api/latestnotifications');
        const data = await response.json();
        setNotifications(data); // Assuming `data` is an array of notification objects
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  const scrollLeft = () => {
    const container = document.getElementById('carousel-container');
    container.scrollBy({ left: -110 * 3, behavior: 'smooth' }); // Move 3 cards width to the left
  };

  const scrollRight = () => {
    const container = document.getElementById('carousel-container');
    container.scrollBy({ left: 110 * 3, behavior: 'smooth' }); // Move 3 cards width to the right
  };

  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    console.log(slug);
    navigate(`/notifications/${slug}`);
  };

  return (
    <div className="p-4 my-6 max-w-6xl mx-auto hidden md:block">
      <h2 className="text-3xl font-bold text-center mb-10 text-gray-800">
        Latest Notifications
      </h2>

      {/* Carousel Container */}
      <div className="relative flex items-center mb-6">
        {/* Scroll Left Button */}
        <button
          onClick={scrollLeft}
          className="absolute left-0 z-10 px-4 py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-full shadow-lg"
          style={{ fontSize: "30px" }}
        >
          &#8249;
        </button>

        {/* Notification Cards */}
        <div
          id="carousel-container"
          className="flex overflow-hidden max-w-full px-10 space-x-6"
        >
          <div className="flex transition-transform duration-300" style={{ scrollSnapType: "x mandatory" }}>
            {notifications.map((notification) => (
              <div
                key={notification._id}
                onClick={() => handleCollegeClick(notification.mainheading)} // Make the entire card clickable
                className="bg-gradient-to-tl from-white to-gray-100 p-6 shadow-lg rounded-lg w-80 flex-none transform transition-transform duration-300 hover:-translate-y-3 hover:shadow-xl hover:scale-105 cursor-pointer"
                style={{ scrollSnapAlign: "center" }}
              >
                <div className="flex items-center mb-6">
                  <img
                    src={notification.photo} // Assuming the photo path is provided
                    alt="Icon"
                    className="w-10 h-10 rounded-full border-2 border-blue-400 mr-3"
                  />
                  <h3 className="text-xl font-semibold text-gray-800">
                    {notification.mainheading}
                  </h3>
                </div>
                <div className="text-sm text-gray-600 mb-4 flex items-center">
                  <span className="mr-2">📝</span>
                  By {notification.author} | {notification.datetime}
                </div>
                <p className="text-sm text-gray-700 mb-4">
                  {notification.paragraph1.length > 120 ? `${notification.paragraph1.slice(0, 120)}...` : notification.paragraph1}
                </p>
                <button
                  onClick={() => handleCollegeClick(notification.mainheading)}
                  className="text-blue-600 hover:underline mt-4 inline-block font-semibold"
                >
                  Read More
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Scroll Right Button */}
        <button
          onClick={scrollRight}
          className="absolute right-0 z-10 px-4 py-4 bg-gradient-to-l from-blue-500 to-indigo-600 text-white rounded-full shadow-lg"
          style={{ fontSize: "30px" }}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default LatestNotification;
