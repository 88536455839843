import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { clat, ailet, slat, dullb } from "../../images";

const exams = [
  {
    id: 1,
    name: "GGSIPU LLB  (based on clat ug rank)",
    icon: "https://th.bing.com/th/id/OIP.5kAZnkhYvxW9kd4cU8aiiwAAAA?w=169&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
  },
  {
    id: 2,
    name: "GGSIPU LLM  (based on clat pg rank)",
    icon: "https://th.bing.com/th/id/OIP.5kAZnkhYvxW9kd4cU8aiiwAAAA?w=169&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
  },
  {
    id: 3,
    name: "NLU LLB Predictor(Based on CLAT UG Rank)",
    icon: clat,
  },
  {
    id: 4,
    name: "NLU LLM Predictor(Based on CLAT PG Rank)",
    icon: clat,
  },
  {
    id: 5,
    name: "AILET",
    icon: ailet,
  },
  {
    id: 6,
    name: "DU LLB Entrance Exam",
    icon: dullb,
  },
];

const LawPredictor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = exams.slice(firstIndex, lastIndex);

  const handleExamSelect = (exam) => {
    switch (exam.name) {
      case "GGSIPU LLB  (based on clat ug rank)":
        navigate("/freeggsipullbpredictor");
        break;
      case "GGSIPU LLM  (based on clat pg rank)":
        navigate("/freeggsipullmpredictor");
        break;
      case "NLU LLM Predictor(Based on CLAT PG Rank)":
        navigate("/freenlullmpredictor");
        break;
      case "NLU LLB Predictor(Based on CLAT UG Rank)":
        navigate("/freenlullbpredictor");
        break;
      case "AILET":
        navigate("/");
        break;
      case "DU LLB Entrance Exam":
        navigate("/");
        break;
      default:
        console.log("No valid route for the selected exam");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4 text-center text-black">
        Law College Predictor 
      </h1>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Law exams you have taken.
      </p>

      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              key={exam.id}
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
              <p
                className="font-semibold text-gray-800 mt-1 text-center"
                style={{ fontSize: "13px" }}
              >
                {exam.name}
              </p>
            </label>
          </div>
        ))}
      </div>

      <div className="flex mt-6 space-x-4">
        {/* <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === Math.ceil(exams.length / itemsPerPage)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button> */}
      </div>
    </div>
  );
};

export default LawPredictor;
