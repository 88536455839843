import React, { useState, useEffect } from "react";
import { logo } from "../../images/index";
import ContactPopup from "./ContactPopup";
import LoginPopup from "./LoginPopup";
import SignUpPage from "./SignUpPage";
import { Link } from "react-router-dom";
import SearchNav from "./SearchNav";
import MegaDropdown from "./Navbar/MegaDropdown";
import { FaWhatsapp, FaUserCircle } from "react-icons/fa";
import { auth, signUserOut } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isContactPopupOpen, setContactPopupOpen] = useState(false);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const [isSignUpPopupOpen, setSignUpPopupOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isUserPremium, setIsUserPremium] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchUserData(currentUser.uid);
      } else {
        setUser(null);
        setIsUserPremium(false);
      }
    });
    return () => unsubscribe();
  }, []);
  const fetchUserData = async (uid) => {
    try {
      const response = await axios.get(
        `https://caderaedu.com/api/user/get/${uid}`
      );
      if (response.data && response.data.user) {
        const userData = response.data.user;
        setIsUserPremium(userData.planStatus !== "free");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };
  const handleSignOut = async () => {
    await signUserOut();
  };
  const toggleContactPopup = () => {
    setContactPopupOpen(!isContactPopupOpen);
  };
  const toggleLoginPopup = () => {
    setLoginPopupOpen(!isLoginPopupOpen);
  };
  const toggleSignUpPopup = () => {
    setSignUpPopupOpen(!isSignUpPopupOpen);
  };
  const openSignUpPage = () => {
    setSignUpPopupOpen(true);
    setLoginPopupOpen(false);
  };
  const openLoginPage = () => {
    setLoginPopupOpen(true);
    setSignUpPopupOpen(false);
  };
  return (
    <header>
      <div>
        <nav className="bg-[#68a4a5]">
          <div
            className="logo"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a href="/">
              <img
                src={logo}
                alt="Cadera Edu Logo"
                className="h-[70px] w-[190px]"
              />
            </a>
            <div>
              <div style={{ marginLeft: "30px" }}>
                <SearchNav />
              </div>
              <div style={{ marginLeft: "30px" }}>
                <MegaDropdown />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <a
                style={{ display: "flex", justifyContent: "flex-end" }}
                href="https://api.whatsapp.com/send?phone=919711630487"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 text-white"
              >
                <FaWhatsapp style={{ color: "#00008B", fontSize: "24px" }} />
                <p className="text-white">Ask a query on 9711630487</p>
              </a>
              <div>
                {!user ? (
                  <div className="flex space-x-4">
                    <a
                      href="#"
                      className="text-white"
                      onClick={toggleLoginPopup}
                    >
                      Login
                    </a>
                    <a
                      href="#"
                      className="text-white"
                      onClick={toggleSignUpPopup}
                    >
                      Sign Up
                    </a>
                  </div>
                ) : (
                  <div className="relative">
                    <FaUserCircle
                      style={{ marginRight: "10px" }}
                      className="text-white cursor-pointer text-3xl"
                      onClick={toggleProfileDropdown}
                    />
                    {isProfileDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
                        <a
                          href="/profile"
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                          Profile
                        </a>
                        {isUserPremium && (
                          <Link
                            to="/dashboard"
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                          >
                            Dashboard
                          </Link>
                        )}
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                          onClick={handleSignOut}
                        >
                          Sign Out
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <ContactPopup
        isOpen={isContactPopupOpen}
        closePopup={toggleContactPopup}
      />
      {isLoginPopupOpen && (
        <LoginPopup
          closePopup={toggleLoginPopup}
          openSignUpPage={openSignUpPage}
        />
      )}
      {isSignUpPopupOpen && (
        <SignUpPage
          isOpen={isSignUpPopupOpen}
          closePopup={toggleSignUpPopup}
          openLoginPage={openLoginPage}
        />
      )}
    </header>
  );
};
export default Header;
