import React, { useState } from 'react';

const UniversityDelegates = () => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultCardStyle = {
    width: '300px',
    height: '240px',
    border: '4px solid #fbbf24',
    borderRadius: '0.75rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out',
  };

  return (
    <div className="p-2 bg-gray-200  flex flex-col justify-center items-center">
      <h2 className="text-2xl md:text-3xl font-bold text-blue-900 border-2 border-blue-200 inline-block px-6 py-4 mb-12 mx-auto text-center rounded-lg shadow-lg bg-white transform hover:scale-105 transition duration-300 ease-in-out">
        Get a chance to talk directly with university delegates.
      </h2>

      <div
        className="flex flex-wrap justify-center gap-8"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Image 1 */}
        <div
          className={`relative group w-full sm:w-[300px] h-[200px] sm:h-[240px] rounded-lg overflow-hidden shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out ${
            isHovered ? 'animate-border-clockwise' : ''
          }`}
          style={defaultCardStyle}
        >
          <img
            src="https://th.bing.com/th/id/OIP.B9IYWeJ1QOKhYLrcayuITwHaEz?w=281&h=182&c=7&r=0&o=5&dpr=1.3&pid=1.7"
            alt="Delegate Discussion 1"
            className="w-full h-full object-cover group-hover:opacity-80"
          />
        </div>

        {/* Image 2 */}
        <div
          className={`relative group w-full sm:w-[300px] h-[200px] sm:h-[240px] rounded-lg overflow-hidden shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out ${
            isHovered ? 'animate-border-clockwise' : ''
          }`}
          style={defaultCardStyle}
        >
          <img
            src="https://th.bing.com/th/id/OIP.KB6iMDabO594cUqrX_FeLgAAAA?w=298&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
            alt="Delegate Discussion 2"
            className="w-full h-full object-cover group-hover:opacity-80"
          />
        </div>

        {/* Image 3 */}
        <div
          className={`relative group w-full sm:w-[300px] h-[200px] sm:h-[240px] rounded-lg overflow-hidden shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out ${
            isHovered ? 'animate-border-clockwise' : ''
          }`}
          style={defaultCardStyle}
        >
          <img
            src="https://th.bing.com/th/id/OIP.RVA7Ac_6rC-UZP05fDeGWQHaE8?w=282&h=188&c=7&r=0&o=5&dpr=1.3&pid=1.7"
            alt="Delegate Discussion 3"
            className="w-full h-full object-cover group-hover:opacity-80"
          />
        </div>
      </div>

      <style jsx>{`
        @keyframes moveBorder {
          0% {
            border-top-color: #fbbf24;
            border-right-color: #fbbf24;
            border-bottom-color: #fbbf24;
            border-left-color: #fbbf24;
          }
          25% {
            border-top-color: #4caf50;
            border-right-color: #fbbf24;
            border-bottom-color: #fbbf24;
            border-left-color: #fbbf24;
          }
          50% {
            border-top-color: #4caf50;
            border-right-color: #4caf50;
            border-bottom-color: #fbbf24;
            border-left-color: #fbbf24;
          }
          75% {
            border-top-color: #4caf50;
            border-right-color: #4caf50;
            border-bottom-color: #4caf50;
            border-left-color: #fbbf24;
          }
          100% {
            border-top-color: #4caf50;
            border-right-color: #4caf50;
            border-bottom-color: #4caf50;
            border-left-color: #4caf50;
          }
        }

        .animate-border-clockwise {
          animation: moveBorder 1s forwards;
        }
      `}</style>
    </div>
  );
};

export default UniversityDelegates;
