import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { neetuglogo, aiimslogo, neetpglogo, NEETMDSlogo, pucetlogo, UPPGlogo } from "../../images/index";

const exams = [
  { id: 1, name: "NEET UG", icon: neetuglogo },
  { id: 6, name: "UP PG", icon: UPPGlogo },
  { id: 3, name: "NEET PG", icon: neetpglogo },
  { id: 2, name: "AIIMS", icon: aiimslogo },
  { id: 4, name: "NEET MDS", icon: NEETMDSlogo },
  { id: 5, name: "PU-CET (UG)", icon: pucetlogo },
];

const MedicalPredictor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = exams.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(exams.length / itemsPerPage);

  const handleExamSelect = (exam) => {
    switch (exam.name) {
      case "NEET UG":
        navigate("/freeNeetUGAllIndiaPredictor");
        break;
      case "NEET PG":
        navigate("/freeNeetPGPredictor");
        break;
      case "UP PG":
        navigate("/freeUPPgPredictor");
        break;
      default:
        console.log("No valid route for the selected exam.");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        Medical College Predictor 
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Medical exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
        Select exam you have taken:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">
              {exam.name}
            </p>
          </div>
        ))}
      </div>
      <div className="flex mt-6 space-x-4">
        {/* <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button> */}
      </div>
    </div>
  );
};

export default MedicalPredictor;
