[
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 57.25,
    "Category": "General"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 79.5,
    "Category": "General"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Entrepreneurship and Family Business",
    "Score": 64,
    "Category": "General"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 87.25,
    "Category": "General"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "General"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Healthcare and Hospital Management",
    "Score": 99.5,
    "Category": "OBC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 87.25,
    "Category": "OBC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 90.75,
    "Category": "OBC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "OBC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Healthcare and Hospital Management",
    "Score": 99.5,
    "Category": "SC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 87.25,
    "Category": "SC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 90.75,
    "Category": "SC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "SC"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Healthcare and Hospital Management",
    "Score": 99.5,
    "Category": "ST"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 87.25,
    "Category": "ST"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 90.75,
    "Category": "ST"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "ST"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 87.25,
    "Category": "PWD"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.5,
    "Category": "PWD"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Healthcare and Hospital Management",
    "Score": 66.5,
    "Category": "PWD"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Healthcare and Hospital Management",
    "Score": 99.5,
    "Category": "EWS"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 87.25,
    "Category": "EWS"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 90.75,
    "Category": "EWS"
  },
  {
    "Name": "Jamia Millia Islamia",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "EWS"
  }
]