import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Landingpagepopup from './LandingPagepopup';

const UniversityCard = ({ university, imageUrl, fee, eligibility, applyNowLink, togglePopup, headerLink }) => {
  const navigate = useNavigate();

  const handleApplyNowClick = () => {
    if (applyNowLink) {
      window.open(applyNowLink, '_blank'); // Open the external link in a new tab
    } else {
      togglePopup(); // Show the popup if no link is provided
    }
  };

  return (
    <div
      className="flex flex-col sm:flex-row p-6 bg-white rounded-lg shadow-lg items-center mb-8 mx-auto max-w-4xl hover:shadow-2xl cursor-pointer"
    >
      <div className="w-full sm:w-1/3 flex justify-center mb-4 sm:mb-0">
        <img
          src={imageUrl}
          alt={university}
          className="h-60 sm:h-80 w-full sm:w-50 object-cover rounded-lg shadow-md border-2 border-gray-200"
        />
      </div>
      <div className="w-full sm:w-2/3 sm:pl-6">
        <h2
          onClick={() => navigate(headerLink)}
          className="text-2xl font-semibold text-gray-800 hover:text-blue-600 transition-colors duration-300 cursor-pointer"
        >
          {university}
        </h2>
        <div className="text-gray-600 text-sm flex items-center space-x-2 mt-2">
          <span className="bg-blue-100 text-blue-600 px-2 py-1 rounded-full">MBA & PGDM Courses</span>
          <span>•</span>
          <span>24 Months</span>
        </div>
        <div className="mt-4">
          <p className="font-semibold text-gray-700">Fee:</p>
          <p className="text-lg font-medium text-gray-900">{fee}</p>
          <p className="font-semibold text-gray-700 mt-4">Eligibility:</p>
          <p className="text-gray-700">{eligibility}</p>
        </div>
        <div className="mt-6 flex items-center justify-between">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent navigation when clicking the button
              handleApplyNowClick();
            }}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300 transform hover:scale-105"
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className="bg-gray-200">
      <div className="p-8 max-w-screen-xl mx-auto">
        <h1 className="text-4xl font-extrabold mb-12 text-center text-gray-800">
          Top Universities & Courses for You
        </h1>
        <div className="space-y-8">
          <UniversityCard
            university="Fore School Of Management"
            imageUrl="https://del1.vultrobjects.com/caderaedu/Colleges/Fore%20School%20Of%20Management/Fore%20School%20Of%20ManagementAuditorium.webp"
            fee="₹20,77,000"
            eligibility="Graduate/Bachelors degree (10+2+3 or 10+2+4) or equivalent in any discipline from a recognized university."
            applyNowLink="" // Redirect link for Apply Now
            togglePopup={togglePopup}
            headerLink="/college/foreschoolofmanagement"
          />
          <UniversityCard
            university="Lalbahadur Shastri Management College"
            imageUrl="https://del1.vultrobjects.com/caderaedu/Colleges/Lalbahadur%20Sharsti%20Management%20College/Lalbahadur%20Sharsti%20Management%20CollegeHostel%20and%20Leisure.jpeg"
            fee="₹16,50,000"
            eligibility="Graduate/Bachelors degree (10+2+3 or 10+2+4) or equivalent in any discipline from a recognized university."
            applyNowLink="" // Redirect link for Apply Now
            togglePopup={togglePopup}
            headerLink="/college/lalbahadursharstimanagementcollege"
          />
          <UniversityCard
            university="GNIOT Institute of Management Studies (GIMS)"
            imageUrl="https://del1.vultrobjects.com/caderaedu/Colleges/GIMSManagement/GNIOTClassrooms.jpg"
            fee="₹8,37,000"
            eligibility="Graduate/Bachelors degree (10+2+3 or 10+2+4) or equivalent in any discipline from a recognized university."
            applyNowLink="" // Empty link to trigger the popup
            togglePopup={togglePopup}
            headerLink="/college/gniotinstituteofmanagementstudiesgims"
          />
          <UniversityCard
            university="Bennett University"
            imageUrl="https://del1.vultrobjects.com/caderaedu/Colleges/Bennett%20University/Bennett%20Universitylibrary.jpg"
            fee="₹12,45,000"
            eligibility="Graduate/Bachelors degree (10+2+3 or 10+2+4) or equivalent in any discipline from a recognized university."
            applyNowLink="https://bit.ly/3VG6mak"
            togglePopup={togglePopup}
            headerLink="/college/bennettuniversity"
          />
          <UniversityCard
            university="IILM University"
            imageUrl="https://th.bing.com/th/id/OIP.sT68SAGn3oxfshGmSTLT3AHaEA?w=330&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
            fee="₹10,45,000"
            eligibility="Graduate/Bachelors degree (10+2+3 or 10+2+4) or equivalent in any discipline from a recognized university."
            applyNowLink="" // Redirect link for Apply Now
            togglePopup={togglePopup}
            headerLink="#"
          />
          <UniversityCard
            university="Birla Institute of Management Technology (BIMTECH)"
            imageUrl="https://del1.vultrobjects.com/caderaedu/Colleges/Birla%20Institute%20of%20Management%20Technology/Birla%20Institute%20of%20Management%20TechnologySports.webp"
            fee="₹14,00,000"
            eligibility="Graduate/Bachelors degree (10+2+3 or 10+2+4) or equivalent in any discipline from a recognized university."
            applyNowLink="" // Redirect link for Apply Now
            togglePopup={togglePopup}
            headerLink="/college/birlainstituteofmanagementtechnologybimtech"
          />
        </div>
      </div>

      {isPopupOpen && <Landingpagepopup setIsOpen={setIsPopupOpen} />}
    </div>
  );
};

export default App;
