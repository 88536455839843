[
  {
    "Name": "ANUCDE Guntur",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 47
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Retail Management",
    "Category": "General",
    "Score": 185
  },
  {
    "Name": "Jawaharlal Nehru Technological University, Kakinada",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 255
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 307
  },
  {
    "Name": "Paladugu Parvathi Devi College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 517
  },
  {
    "Name": "Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 966
  },
  {
    "Name": "College of Commerce, Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1036
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1197
  },
  {
    "Name": "Prakasam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1477
  },
  {
    "Name": "Velagapudi Ramakrishna Siddhartha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1543
  },
  {
    "Name": "Dr.Agarala Eswara Reddi Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 67
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Banking and Financial Services",
    "Category": "General",
    "Score": 196
  },
  {
    "Name": "Chaitanya Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 269
  },
  {
    "Name": "Adikavi Nannaya University College of Arts and Commerce",
    "Course": "Master in Business Administration",
    "Category": "General",
    "Score": 322
  },
  {
    "Name": "Krishna University",
    "Course": "Discontinued (Sep 2022)- Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 562
  },
  {
    "Name": "Aditya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1012
  },
  {
    "Name": "Vignan's LARA Institute of Technology & Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1141
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in international Business",
    "Category": "General",
    "Score": 1367
  },
  {
    "Name": "Malineni Lakshmaiah MBA College",
    "Course": "Master of Business Administration",
    "Category": "General",
    "Score": 1535
  },
  {
    "Name": "Gayatri Vidya Parishad College for Degree and PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1561
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in Hospital Administration",
    "Category": "General",
    "Score": 1619
  },
  {
    "Name": "Dr. B.R. Ambedkar University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1807
  },
  {
    "Name": "Sanketika Vidya Parishad Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2068
  },
  {
    "Name": "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2118
  },
  {
    "Name": "Andhra Loyola Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2544
  },
  {
    "Name": "Rayalaseema University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2915
  },
  {
    "Name": "Adikavi Nannaya University College of Arts and Commerce",
    "Course": "MBA in Tourism and Hospitality Management",
    "Category": "General",
    "Score": 3059
  },
  {
    "Name": "Sri Sivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3289
  },
  {
    "Name": "SVKP & Dr. K S Raju Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3578
  },
  {
    "Name": "Visakha Institute for Professional STDS - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3626
  },
  {
    "Name": "Dr. Lankapalli Bullayya College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1758
  },
  {
    "Name": "Vignan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1877
  },
  {
    "Name": "JNTUA - Jawaharlal Nehru Technological University, Anantapur",
    "Course": "MBA in General Management",
    "Category": "General",
    "Score": 2077
  },
  {
    "Name": "Prasad V Potluri Siddhartha Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2196
  },
  {
    "Name": "Adikavi Nannaya University - MSN Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2851
  },
  {
    "Name": "Maharajah's Post Graduate College (MRPG)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3057
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3081
  },
  {
    "Name": "Nalanda Institute of PG Studies",
    "Course": "MBA in Pharmaceutical Management",
    "Category": "General",
    "Score": 3484
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in Travel and Tourism Management",
    "Category": "General",
    "Score": 3584
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3795
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3862
  },
  {
    "Name": "Vikas Group Of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4014
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "MBA (Fintech)",
    "Category": "General",
    "Score": 4414
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Category": "General",
    "Score": 4462
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "MBA in Human Resource Management",
    "Category": "General",
    "Score": 4550
  },
  {
    "Name": "Dr. MRAR PG Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4726
  },
  {
    "Name": "Sasi Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4786
  },
  {
    "Name": "NRI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4910
  },
  {
    "Name": "Aditya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5132
  },
  {
    "Name": "SD College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5266
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "MBA in Business Analytics",
    "Category": "General",
    "Score": 3982
  },
  {
    "Name": "RVR and JC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4211
  },
  {
    "Name": "Sri Kalahastiswara Institute Of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4428
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4541
  },
  {
    "Name": "Noble Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4642
  },
  {
    "Name": "Aditya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4777
  },
  {
    "Name": "Lakireddy Bali Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4825
  },
  {
    "Name": "Krishna Chaitanya Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 5037
  },
  {
    "Name": "Dadi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5177
  },
  {
    "Name": "Adikavi Nannaya University (AKNUDOA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5340
  },
  {
    "Name": "Sri Krishnadevaraya Institute of Management (SKIM College)",
    "Course": "MBA in Finance",
    "Category": "General",
    "Score": 5503
  },
  {
    "Name": "Vikas Institute of Business Management, Kadapa",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5655
  },
  {
    "Name": "Sreerama Institute of Management",
    "Course": "MBA in General Management",
    "Category": "General",
    "Score": 5805
  },
  {
    "Name": "Sri Venkateswara College of Engineering, Tirupati",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5820
  },
  {
    "Name": "Eluru College of Engineering and Technology (ECET, Eluru)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5941
  },
  {
    "Name": "G Pullaiah College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6442
  },
  {
    "Name": "Vasavi MCA And MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6563
  },
  {
    "Name": "Bonam Venkata Chalamayya Engineering College",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6672
  },
  {
    "Name": "Madanapalle Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6866
  },
  {
    "Name": "Samata College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7171
  },
  {
    "Name": "GVR & S College Of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5588
  },
  {
    "Name": "Sreerama Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 5752
  },
  {
    "Name": "Aditya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5816
  },
  {
    "Name": "BABA Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5829
  },
  {
    "Name": "Kakinada Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6127
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute of Technology (KVSRIT)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6490
  },
  {
    "Name": "Rajiv Gandhi Degree College, Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6637
  },
  {
    "Name": "Vignanasudha Institute of Management And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6688
  },
  {
    "Name": "Nadimpalli Satyanarayana Raju Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6952
  },
  {
    "Name": "Universal College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7374
  },
  {
    "Name": "Gudlavalleru Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7625
  },
  {
    "Name": "Rajeev Gandhi Memorial College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7780
  },
  {
    "Name": "GIET Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8325
  },
  {
    "Name": "Nalanda Institute of PG Studies",
    "Course": "MBA in Banking and Financial Services",
    "Category": "General",
    "Score": 8676
  },
  {
    "Name": "Avanthi's St.Theressa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9239
  },
  {
    "Name": "Hindu College of Management",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "General",
    "Score": 9417
  },
  {
    "Name": "Chaitanya Bharathi Institute of Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9756
  },
  {
    "Name": "S.R.K. Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10316
  },
  {
    "Name": "Haindavi PG college (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10640
  },
  {
    "Name": "Mandava Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10778
  },
  {
    "Name": "Sri Srinivasa Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7699
  },
  {
    "Name": "Godavari Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8116
  },
  {
    "Name": "Sanskrithi School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8591
  },
  {
    "Name": "Guntur Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9081
  },
  {
    "Name": "Sree Vahini Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9303
  },
  {
    "Name": "Ramachandra College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9504
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9854
  },
  {
    "Name": "Pydah College PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10414
  },
  {
    "Name": "Sanjeev Institute of Planning and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10735
  },
  {
    "Name": "Sri Vasavi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11241
  },
  {
    "Name": "MVR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11341
  },
  {
    "Name": "Avanthi's Research and Technological Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11471
  },
  {
    "Name": "Anantha Lakshmi Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11522
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "General",
    "Score": 11635
  },
  {
    "Name": "Dr. K.V. Subba Reddy School of Business Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 11700
  },
  {
    "Name": "Lingayas Institute Of Management And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12089
  },
  {
    "Name": "Gayatri College for PG Courses",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 12464
  },
  {
    "Name": "Vignan Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12640
  },
  {
    "Name": "P.V.K.K Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12801
  },
  {
    "Name": "Narayana Engineering College (NEC, Nellore)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12958
  },
  {
    "Name": "GIET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11457
  },
  {
    "Name": "Miracle Educational Society Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11487
  },
  {
    "Name": "Sree Sai MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11555
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology, Chittoor",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11679
  },
  {
    "Name": "Mother Theresa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11817
  },
  {
    "Name": "Hindu College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12449
  },
  {
    "Name": "Nri Institute Of Technology, Guntur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12477
  },
  {
    "Name": "Gitams Business and IT School",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 12760
  },
  {
    "Name": "Satya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12809
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13071
  },
  {
    "Name": "Kakinada Institute of Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13107
  },
  {
    "Name": "Annamacharya P.G. College of Computer Studies (APGCCS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13194
  },
  {
    "Name": "Narasaraopeta Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13677
  },
  {
    "Name": "Priyadarshini Institute of Technology and Management (PITM, Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14094
  },
  {
    "Name": "VSM College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14286
  },
  {
    "Name": "Palivela PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14508
  },
  {
    "Name": "Visakha Institute Of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14532
  },
  {
    "Name": "Sir C R Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14984
  },
  {
    "Name": "S.R.K. Institute Of Technology",
    "Course": "MBA in Applied Management",
    "Category": "General",
    "Score": 15094
  },
  {
    "Name": "TJPS College - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16051
  },
  {
    "Name": "DNR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13193
  },
  {
    "Name": "The Hindu College - MBA",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13407
  },
  {
    "Name": "Kuppam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14014
  },
  {
    "Name": "Annamacharya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14135
  },
  {
    "Name": "Sapthagiri Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14294
  },
  {
    "Name": "Vkr, Vnb and Agk College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14523
  },
  {
    "Name": "Avanthi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14933
  },
  {
    "Name": "Narasaraopeta Engineering College (Autonomous)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15004
  },
  {
    "Name": "Pace Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15532
  },
  {
    "Name": "Mother Theresa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16078
  },
  {
    "Name": "Visakha Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16159
  },
  {
    "Name": "Adithe Satyanarayana PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16277
  },
  {
    "Name": "Global Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16836
  },
  {
    "Name": "Kallam Haranadhareddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16885
  },
  {
    "Name": "Kbn College Pg Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17159
  },
  {
    "Name": "KSRM College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17296
  },
  {
    "Name": "Swarnandhra College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17774
  },
  {
    "Name": "Sai Rajeswari Institute of Technology",
    "Course": "MBA in General Management",
    "Category": "General",
    "Score": 17904
  },
  {
    "Name": "B.A. and K.R. MCA COLLEGE",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18466
  },
  {
    "Name": "Sree Rama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18777
  },
  {
    "Name": "Sri YN College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16266
  },
  {
    "Name": "Wings Business School (WBS, Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16703
  },
  {
    "Name": "Vikas College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16854
  },
  {
    "Name": "St. Ann's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16913
  },
  {
    "Name": "PBR Visvodaya Institute of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17205
  },
  {
    "Name": "Akkineni Nageswara Rao College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17460
  },
  {
    "Name": "Nova College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17779
  },
  {
    "Name": "RCR Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17931
  },
  {
    "Name": "QIS College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18537
  },
  {
    "Name": "Simhadri Educational Society Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18873
  },
  {
    "Name": "ABR College of Engineering and Technology (ABRCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18979
  },
  {
    "Name": "Sir C.R. Reddy College PG Management Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 19528
  },
  {
    "Name": "St. Johns College of Engineering and Technology (SJCET, Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20019
  },
  {
    "Name": "Potti Sriramulu College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20425
  },
  {
    "Name": "Raos Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20837
  },
  {
    "Name": "Rajiv Gandhi Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21079
  },
  {
    "Name": "Chadalawada Ramanamma Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21109
  },
  {
    "Name": "Sumourya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21343
  },
  {
    "Name": "Adarsh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 22048
  },
  {
    "Name": "Siddharth Institute of Engineering and Technology (SIETK)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 23199
  },
  {
    "Name": "VRN College of Computer Science and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 19197
  },
  {
    "Name": "Chirala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 19634
  },
  {
    "Name": "Kandula Obul Reddy Memorial College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20036
  },
  {
    "Name": "Allagadda Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20526
  },
  {
    "Name": "Sreenivasa Institute of Technology and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20956
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Renigunta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21093
  },
  {
    "Name": "West Godavari Institute of Science And Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21111
  },
  {
    "Name": "Dravidian University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21430
  },
  {
    "Name": "Santhiram Engineering College (SREC Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 22734
  },
  {
    "Name": "KMM Institute Of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 23263
  },
  {
    "Name": "Sree Vyshnavi MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 23330
  },
  {
    "Name": "Sharada Post Graduate Institute of Research and Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 24451
  },
  {
    "Name": "GATES Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 24570
  },
  {
    "Name": "Srinivasa Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 24888
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 24961
  },
  {
    "Name": "K.G.R.L. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 25642
  },
  {
    "Name": "Edge International B School",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26241
  },
  {
    "Name": "Jagan's Institute of Management and Computer Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 26376
  },
  {
    "Name": "Ramireddy Subbarami Reddy Engineering College (RSREC)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26484
  },
  {
    "Name": "Royal School Of Information and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26732
  },
  {
    "Name": "R K College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 23936
  },
  {
    "Name": "Vijayam Business School",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 24560
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 24605
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 24933
  },
  {
    "Name": "Sri Mittapalli College of Engineering - Guntur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 25490
  },
  {
    "Name": "Chaitanya Institute Of Science And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26028
  },
  {
    "Name": "Sri Vani Institute Of Management And Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26355
  },
  {
    "Name": "Rise Krishna Sai Prakasam Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26443
  },
  {
    "Name": "Dr CSN Degree And PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26586
  },
  {
    "Name": "D.N.R College (DNRC Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26766
  },
  {
    "Name": "SDGS College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 26827
  },
  {
    "Name": "Bhimavaram Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27082
  },
  {
    "Name": "Akshara Institute of Management and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 27400
  },
  {
    "Name": "Prabhath Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27777
  },
  {
    "Name": "Sree Konaseema Bhanoji Ramars P.G. College (SKBR College)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28180
  },
  {
    "Name": "Madhura Sai Institute of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28489
  },
  {
    "Name": "Dr.AER College of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28633
  },
  {
    "Name": "Sairam Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28887
  },
  {
    "Name": "KKC Institute of Post Graduate Stuides",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 29578
  },
  {
    "Name": "Sri Balaji Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 30703
  },
  {
    "Name": "Viswam Degree and PG College",
    "Course": "Master of Business Management (MBA)",
    "Category": "General",
    "Score": 26957
  },
  {
    "Name": "Chiranjeevi Reddy Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27395
  },
  {
    "Name": "Krishna Chaitanya Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27488
  },
  {
    "Name": "Vemu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27914
  },
  {
    "Name": "Dwarampudi Lakshmana Reddy College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28446
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "MBA in General Management",
    "Category": "General",
    "Score": 28609
  },
  {
    "Name": "Seshachala Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28756
  },
  {
    "Name": "Eswar College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28916
  },
  {
    "Name": "Sri Harsha Institute of PG Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 30116
  },
  {
    "Name": "Audisankara Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 30757
  },
  {
    "Name": "Loyola Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 31817
  },
  {
    "Name": "Priyadarshini Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 32264
  },
  {
    "Name": "Sri Sarathi Institute Of Engineering  And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 32500
  },
  {
    "Name": "MJR Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 32947
  },
  {
    "Name": "Bheemi Reddy Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 34196
  },
  {
    "Name": "Vignan's Nirula Institute of Technology and Science for Women",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 34472
  },
  {
    "Name": "Golden Valley Integrated Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 34757
  },
  {
    "Name": "PRR Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 35266
  },
  {
    "Name": "Narayana Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 35720
  },
  {
    "Name": "Harinadha Reddy Institute Of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 35895
  },
  {
    "Name": "Sri Padmavathi College of Computer Sciences and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 36002
  },
  {
    "Name": "Shree Vivekananda Institute of Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 36090
  },
  {
    "Name": "Narayana Engineering College, Gudur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 36694
  },
  {
    "Name": "Sri Datta Sai School Of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 33810
  },
  {
    "Name": "Audisankara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18011
  },
  {
    "Name": "Sri Sai Institute Of Technology and Science (SSITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 40872
  },
  {
    "Name": "Prabhath Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20267
  },
  {
    "Name": "Gokula Krishna College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 36644
  },
  {
    "Name": "Newton's Institute of Engineering (NIE Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 22676
  },
  {
    "Name": "Sri Venkatesa Perumal College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27706
  },
  {
    "Name": "Sri Venkateswara Paladugu Nagaiah Chowdary",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 40481
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Retail Management",
    "Category": "OBC",
    "Score": 56
  },
  {
    "Name": "RCR Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 1889
  },
  {
    "Name": "College of Commerce, Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 3069
  },
  {
    "Name": "Vignan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 5484
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "MBA (Fintech)",
    "Category": "OBC",
    "Score": 5786
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "Master of Bussiness Administration (MBA)",
    "Category": "OBC",
    "Score": 8045
  },
  {
    "Name": "Adikavi Nannaya University - MSN Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8401
  },
  {
    "Name": "Kakinada Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10111
  },
  {
    "Name": "Lakireddy Bali Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10613
  },
  {
    "Name": "Avanthi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 11167
  },
  {
    "Name": "Jawaharlal Nehru Technological University, Kakinada",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 1119
  },
  {
    "Name": "Krishna University",
    "Course": "Discontinued (Sep 2022)- Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 2772
  },
  {
    "Name": "Vignan's LARA Institute of Technology & Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 5389
  },
  {
    "Name": "Dr. Lankapalli Bullayya College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 5699
  },
  {
    "Name": "Paari School of Business, SRM University-AP",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 7251
  },
  {
    "Name": "Madanapalle Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8339
  },
  {
    "Name": "Dr. Abdul Haq Urdu University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8824
  },
  {
    "Name": "Dr. B.R. Ambedkar University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10370
  },
  {
    "Name": "Andhra Loyola Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10842
  },
  {
    "Name": "BABA Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 11318
  },
  {
    "Name": "Rayalaseema University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 11826
  },
  {
    "Name": "P.V.K.K Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 14624
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "MBA in General Management",
    "Category": "OBC",
    "Score": 15962
  },
  {
    "Name": "Aditya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 16551
  },
  {
    "Name": "Sai Rajeswari Institute of Technology",
    "Course": "MBA in General Management",
    "Category": "OBC",
    "Score": 17590
  },
  {
    "Name": "NRI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 18952
  },
  {
    "Name": "Dr CSN Degree And PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 19804
  },
  {
    "Name": "Gudlavalleru Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 20236
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "MBA in Human Resource Management",
    "Category": "OBC",
    "Score": 21705
  },
  {
    "Name": "Dr. MRAR PG Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21831
  },
  {
    "Name": "Sri Krishnadevaraya Institute of Management (SKIM College)",
    "Course": "MBA in Finance",
    "Category": "OBC",
    "Score": 14361
  },
  {
    "Name": "Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 15703
  },
  {
    "Name": "Bheemi Reddy Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 16547
  },
  {
    "Name": "Avanthi's Research and Technological Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 17338
  },
  {
    "Name": "Vemu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 18889
  },
  {
    "Name": "RVR and JC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 19448
  },
  {
    "Name": "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 19887
  },
  {
    "Name": "ABR College of Engineering and Technology (ABRCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 20553
  },
  {
    "Name": "Narayana Engineering College (NEC, Nellore)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21748
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 22540
  },
  {
    "Name": "Newton's Institute of Engineering (NIE Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 22732
  },
  {
    "Name": "Ramireddy Subbarami Reddy Engineering College (RSREC)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 23834
  },
  {
    "Name": "Sri YN College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 24623
  },
  {
    "Name": "Samata College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 25204
  },
  {
    "Name": "Satya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 25825
  },
  {
    "Name": "Haindavi PG college (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26129
  },
  {
    "Name": "Hindu College of Management",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "OBC",
    "Score": 26420
  },
  {
    "Name": "VRN College of Computer Science and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27062
  },
  {
    "Name": "Kuppam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27146
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27798
  },
  {
    "Name": "Annamacharya P.G. College of Computer Studies (APGCCS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 23028
  },
  {
    "Name": "Sir C R Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 24566
  },
  {
    "Name": "Rayalaseema Institute of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 24919
  },
  {
    "Name": "Vignan Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 25410
  },
  {
    "Name": "Rajeev Gandhi Memorial College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26022
  },
  {
    "Name": "S.R.K. Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26221
  },
  {
    "Name": "MJR Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26887
  },
  {
    "Name": "Anantha Lakshmi Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27084
  },
  {
    "Name": "Rajiv Gandhi Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27386
  },
  {
    "Name": "Kallam Haranadhareddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 28332
  },
  {
    "Name": "Nadimpalli Satyanarayana Raju Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 28821
  },
  {
    "Name": "Srinivasa Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 29432
  },
  {
    "Name": "Pace Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 30499
  },
  {
    "Name": "Chaitanya Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 32050
  },
  {
    "Name": "Sri Venkateswara College of Engineering, Tirupati",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 32194
  },
  {
    "Name": "Sri Balaji Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 32585
  },
  {
    "Name": "Potti Sriramulu College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 33790
  },
  {
    "Name": "Kbn College Pg Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 34711
  },
  {
    "Name": "Visakha Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 34932
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Retail Management",
    "Category": "SC",
    "Score": 135
  },
  {
    "Name": "Krishna University",
    "Course": "Discontinued (Sep 2022)- Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 1991
  },
  {
    "Name": "Velagapudi Ramakrishna Siddhartha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 2555
  },
  {
    "Name": "Vignan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 4260
  },
  {
    "Name": "Aditya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 5902
  },
  {
    "Name": "Eluru College of Engineering and Technology (ECET, Eluru)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7271
  },
  {
    "Name": "Ramachandra College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7743
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8050
  },
  {
    "Name": "Vignan's LARA Institute of Technology & Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 9830
  },
  {
    "Name": "Sri Krishnadevaraya Institute of Management (SKIM College)",
    "Course": "MBA in Finance",
    "Category": "SC",
    "Score": 11071
  },
  {
    "Name": "Jawaharlal Nehru Technological University, Kakinada",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 1113
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 2537
  },
  {
    "Name": "Gayatri Vidya Parishad College for Degree and PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 2912
  },
  {
    "Name": "Adikavi Nannaya University - MSN Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 4634
  },
  {
    "Name": "College of Commerce, Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 6495
  },
  {
    "Name": "Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7705
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute of Technology (KVSRIT)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7933
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8790
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 10214
  },
  {
    "Name": "Hindu College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 11387
  },
  {
    "Name": "JNTUA - Jawaharlal Nehru Technological University, Anantapur",
    "Course": "MBA in General Management",
    "Category": "SC",
    "Score": 11780
  },
  {
    "Name": "Rayalaseema University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 13049
  },
  {
    "Name": "Wings Business School (WBS, Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 13783
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 14243
  },
  {
    "Name": "Annamacharya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 14907
  },
  {
    "Name": "St. Ann's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 16215
  },
  {
    "Name": "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 16343
  },
  {
    "Name": "Adithe Satyanarayana PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17452
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in international Business",
    "Category": "SC",
    "Score": 17658
  },
  {
    "Name": "Aditya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18088
  },
  {
    "Name": "Avanthi's St.Theressa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 12758
  },
  {
    "Name": "Gayatri College for PG Courses",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 13127
  },
  {
    "Name": "Dadi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 13994
  },
  {
    "Name": "Kbn College Pg Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 14605
  },
  {
    "Name": "Madanapalle Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15259
  },
  {
    "Name": "Sri Annamacharya Institute of Technology and Sciences, Rajampet",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 16341
  },
  {
    "Name": "Lakireddy Bali Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17349
  },
  {
    "Name": "Kallam Haranadhareddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17600
  },
  {
    "Name": "Vemu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17690
  },
  {
    "Name": "Prasad V Potluri Siddhartha Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18354
  },
  {
    "Name": "Kakinada Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18493
  },
  {
    "Name": "Narasaraopeta Engineering College (Autonomous)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18749
  },
  {
    "Name": "Vignan Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19109
  },
  {
    "Name": "Avanthi's Research and Technological Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19297
  },
  {
    "Name": "Pace Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19694
  },
  {
    "Name": "VRN College of Computer Science and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 20566
  },
  {
    "Name": "KSRM College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21518
  },
  {
    "Name": "Ramireddy Subbarami Reddy Engineering College (RSREC)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21819
  },
  {
    "Name": "The Hindu College - MBA",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22073
  },
  {
    "Name": "Aditya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22266
  },
  {
    "Name": "Prakasam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18599
  },
  {
    "Name": "RVR and JC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18760
  },
  {
    "Name": "Kakinada Institute of Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19150
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Category": "SC",
    "Score": 19332
  },
  {
    "Name": "Dravidian University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19869
  },
  {
    "Name": "Sri Vasavi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21104
  },
  {
    "Name": "Sree Vahini Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21745
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology, Chittoor",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22049
  },
  {
    "Name": "Sri Sivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22088
  },
  {
    "Name": "Dr. Abdul Haq Urdu University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22380
  },
  {
    "Name": "West Godavari Institute of Science And Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 23164
  },
  {
    "Name": "ABR College of Engineering and Technology (ABRCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 24704
  },
  {
    "Name": "MVR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 25187
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 26009
  },
  {
    "Name": "KMM Institute Of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 26739
  },
  {
    "Name": "Avanthi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 26920
  },
  {
    "Name": "Sir C.R. Reddy College PG Management Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27347
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "MBA in Tourism Management",
    "Category": "SC",
    "Score": 27769
  },
  {
    "Name": "Rajiv Gandhi Degree College, Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27943
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "SC",
    "Score": 28815
  },
  {
    "Name": "Sri YN College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 24443
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "MBA in Business Analytics",
    "Category": "SC",
    "Score": 24809
  },
  {
    "Name": "Sri Sai Institute Of Technology and Science (SSITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 25257
  },
  {
    "Name": "Sreerama Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 26057
  },
  {
    "Name": "DNR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 26769
  },
  {
    "Name": "Sri Venkateswara College of Engineering, Tirupati",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27128
  },
  {
    "Name": "Vasavi MCA And MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27540
  },
  {
    "Name": "Newton's Institute of Engineering (NIE Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27923
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 28000
  },
  {
    "Name": "Bhimavaram Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 29110
  },
  {
    "Name": "Sumourya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 29586
  },
  {
    "Name": "Allagadda Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30132
  },
  {
    "Name": "Audisankara Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30397
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30870
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Renigunta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31018
  },
  {
    "Name": "PBR Visvodaya Institute of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31452
  },
  {
    "Name": "Akkineni Nageswara Rao College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31633
  },
  {
    "Name": "Potti Sriramulu College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31732
  },
  {
    "Name": "Chadalawada Ramanamma Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32002
  },
  {
    "Name": "Royal School Of Information and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32324
  },
  {
    "Name": "Srinivasa Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 29836
  },
  {
    "Name": "MJR Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30195
  },
  {
    "Name": "NRI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30797
  },
  {
    "Name": "Samata College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30940
  },
  {
    "Name": "K. Chandrakala PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31038
  },
  {
    "Name": "Sri Venkatesa Perumal College of Engineering and Technology (SVPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31628
  },
  {
    "Name": "Visakha Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31636
  },
  {
    "Name": "GATES Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31869
  },
  {
    "Name": "Visakha Institute for Professional STDS - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32207
  },
  {
    "Name": "Global Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32666
  },
  {
    "Name": "Prabhath Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32940
  },
  {
    "Name": "Chirala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 33236
  },
  {
    "Name": "Pydah College PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 33498
  },
  {
    "Name": "GATE Institute of Technology And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34179
  },
  {
    "Name": "Sasi Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34289
  },
  {
    "Name": "VSM College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34909
  },
  {
    "Name": "Krishna Chaitanya Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35248
  },
  {
    "Name": "Rajiv Gandhi Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35592
  },
  {
    "Name": "Dr CSN Degree And PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35806
  },
  {
    "Name": "Anantha Lakshmi Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35965
  },
  {
    "Name": "Nadimpalli Satyanarayana Raju Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 33150
  },
  {
    "Name": "Devineni Venkata Ramana and Dr. Hima Sekhar MIC College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 33490
  },
  {
    "Name": "SVR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34073
  },
  {
    "Name": "RCR Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34237
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "MBA in General Management",
    "Category": "SC",
    "Score": 34332
  },
  {
    "Name": "SD College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35229
  },
  {
    "Name": "Sapthagiri Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35435
  },
  {
    "Name": "Sri Vani Institute Of Management And Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35681
  },
  {
    "Name": "Mother Theresa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 35963
  },
  {
    "Name": "Sai Rajeswari Institute of Technology",
    "Course": "MBA in General Management",
    "Category": "SC",
    "Score": 36046
  },
  {
    "Name": "Bheemi Reddy Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36076
  },
  {
    "Name": "BABA Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36120
  },
  {
    "Name": "Sairam Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36247
  },
  {
    "Name": "Narayana Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36380
  },
  {
    "Name": "GIET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36445
  },
  {
    "Name": "Akshara Institute of Management and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36487
  },
  {
    "Name": "TJPS College - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36655
  },
  {
    "Name": "Kuppam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36672
  },
  {
    "Name": "Sri Balaji Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36932
  },
  {
    "Name": "Yalamarty Institute Of Computer Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37034
  },
  {
    "Name": "Adarsh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36087
  },
  {
    "Name": "Sri Harsha Institute of PG Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36198
  },
  {
    "Name": "GVR & S College Of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36252
  },
  {
    "Name": "Gudlavalleru Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36423
  },
  {
    "Name": "Narayana Engineering College (NEC, Nellore)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36471
  },
  {
    "Name": "Siddharth Institute of Engineering and Technology (SIETK)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36634
  },
  {
    "Name": "K.G.R.L. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36659
  },
  {
    "Name": "Priyadarshini Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36781
  },
  {
    "Name": "Rayalaseema Institute of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36990
  },
  {
    "Name": "Viswam Degree and PG College",
    "Course": "Master of Business Management (MBA)",
    "Category": "SC",
    "Score": 37043
  },
  {
    "Name": "Haindavi PG college (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37128
  },
  {
    "Name": "Sri Sai College of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37178
  },
  {
    "Name": "Satya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 38323
  },
  {
    "Name": "Sanskrithi School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 39435
  },
  {
    "Name": "SVKP & Dr. K S Raju Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 40240
  },
  {
    "Name": "Sri Venkateswara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 41550
  },
  {
    "Name": "Sree Vyshnavi MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37158
  },
  {
    "Name": "Dwarampudi Lakshmana Reddy College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37248
  },
  {
    "Name": "S.R.K. Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 39090
  },
  {
    "Name": "Sanketika Vidya Parishad Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 39832
  },
  {
    "Name": "Swarnandhra College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 41330
  },
  {
    "Name": "P.V.K.K Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 41752
  },
  {
    "Name": "RCR Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 1302
  },
  {
    "Name": "Sanketika Vidya Parishad Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 3418
  },
  {
    "Name": "Aditya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 5938
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "MBA in General Management",
    "Category": "ST",
    "Score": 8251
  },
  {
    "Name": "Narayana Engineering College (NEC, Nellore)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 8491
  },
  {
    "Name": "Avanthi's St.Theressa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 10355
  },
  {
    "Name": "Sai Rajeswari Institute of Technology",
    "Course": "MBA in General Management",
    "Category": "ST",
    "Score": 11818
  },
  {
    "Name": "Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 12611
  },
  {
    "Name": "Sree Vahini Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 13776
  },
  {
    "Name": "Satya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 14260
  },
  {
    "Name": "College of Commerce, Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 2129
  },
  {
    "Name": "Aditya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 4239
  },
  {
    "Name": "Kakinada Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 6349
  },
  {
    "Name": "Rayalaseema Institute of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 8372
  },
  {
    "Name": "Prasad V Potluri Siddhartha Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 8533
  },
  {
    "Name": "Wings Business School (WBS, Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 10508
  },
  {
    "Name": "Potti Sriramulu College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 12395
  },
  {
    "Name": "Jawaharlal Nehru Technological University, Kakinada",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 13108
  },
  {
    "Name": "Kuppam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 14029
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 14461
  },
  {
    "Name": "GIET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 14986
  },
  {
    "Name": "Sri Vidya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15480
  },
  {
    "Name": "St. Ann's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 16082
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 16893
  },
  {
    "Name": "The Hindu College - MBA",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 17270
  },
  {
    "Name": "VSM College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18429
  },
  {
    "Name": "K.G.R.L. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19482
  },
  {
    "Name": "Rajiv Gandhi Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19698
  },
  {
    "Name": "Madanapalle Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20072
  },
  {
    "Name": "St. Johns College of Engineering and Technology (SJCET, Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20730
  },
  {
    "Name": "Pydah College PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15367
  },
  {
    "Name": "Hindu College of Management",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "ST",
    "Score": 15684
  },
  {
    "Name": "BABA Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 16395
  },
  {
    "Name": "NRI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 17160
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18246
  },
  {
    "Name": "Lakireddy Bali Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18442
  },
  {
    "Name": "Sapthagiri Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19587
  },
  {
    "Name": "Vignan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20018
  },
  {
    "Name": "Andhra Loyola Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20289
  },
  {
    "Name": "Vemu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20774
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "ST",
    "Score": 21015
  },
  {
    "Name": "Anantha Lakshmi Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21953
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology, Chittoor",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22674
  },
  {
    "Name": "Adarsh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 23290
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in international Business",
    "Category": "ST",
    "Score": 24137
  },
  {
    "Name": "MJR Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24354
  },
  {
    "Name": "Sumourya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24875
  },
  {
    "Name": "VRN College of Computer Science and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 26098
  },
  {
    "Name": "DNR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 28480
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 29562
  },
  {
    "Name": "Dr. K.V. Subba Reddy School of Business Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21885
  },
  {
    "Name": "Sri YN College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22490
  },
  {
    "Name": "Hindu College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22928
  },
  {
    "Name": "Sreenivasa Institute of Technology and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 23820
  },
  {
    "Name": "GVR & S College Of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24153
  },
  {
    "Name": "Global Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24641
  },
  {
    "Name": "P.V.K.K Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 25667
  },
  {
    "Name": "Chadalawada Ramanamma Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 26906
  },
  {
    "Name": "Royal School Of Information and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 28934
  },
  {
    "Name": "Rayalaseema University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 30077
  },
  {
    "Name": "Krishna Chaitanya Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 30306
  },
  {
    "Name": "Sri Krishnadevaraya Institute of Management (SKIM College)",
    "Course": "MBA in Finance",
    "Category": "ST",
    "Score": 30760
  },
  {
    "Name": "Kandula Obul Reddy Memorial College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 32782
  },
  {
    "Name": "Sri Venkateswara College of Engineering, Tirupati",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 34676
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute of Technology (KVSRIT)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 36358
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 37742
  },
  {
    "Name": "Sanskrithi School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 38477
  },
  {
    "Name": "PBR Visvodaya Institute of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 41432
  },
  {
    "Name": "KSRM College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 30457
  },
  {
    "Name": "Narayana Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 31830
  },
  {
    "Name": "Viswam Degree and PG College",
    "Course": "Master of Business Management (MBA)",
    "Category": "ST",
    "Score": 34611
  },
  {
    "Name": "TJPS College - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 36222
  },
  {
    "Name": "Sri Balaji Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 37061
  },
  {
    "Name": "Maharaj Vijayaram Gajapathi Raj College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 37820
  },
  {
    "Name": "Nadimpalli Satyanarayana Raju Institute",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 38914
  },
  {
    "Name": "ANUCDE Guntur",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 47
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Retail Management",
    "Category": "PWD",
    "Score": 185
  },
  {
    "Name": "Jawaharlal Nehru Technological University, Kakinada",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 255
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 307
  },
  {
    "Name": "Paladugu Parvathi Devi College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 517
  },
  {
    "Name": "Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 966
  },
  {
    "Name": "College of Commerce, Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1036
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1197
  },
  {
    "Name": "Prakasam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1477
  },
  {
    "Name": "Velagapudi Ramakrishna Siddhartha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1543
  },
  {
    "Name": "Dr.Agarala Eswara Reddi Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 67
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Banking and Financial Services",
    "Category": "PWD",
    "Score": 196
  },
  {
    "Name": "Chaitanya Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 269
  },
  {
    "Name": "Adikavi Nannaya University College of Arts and Commerce",
    "Course": "Master in Business Administration",
    "Category": "PWD",
    "Score": 322
  },
  {
    "Name": "Krishna University",
    "Course": "Discontinued (Sep 2022)- Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 562
  },
  {
    "Name": "Aditya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1012
  },
  {
    "Name": "Vignan's LARA Institute of Technology & Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1141
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in international Business",
    "Category": "PWD",
    "Score": 1367
  },
  {
    "Name": "Malineni Lakshmaiah MBA College",
    "Course": "Master of Business Administration",
    "Category": "PWD",
    "Score": 1535
  },
  {
    "Name": "Gayatri Vidya Parishad College for Degree and PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1561
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in Hospital Administration",
    "Category": "PWD",
    "Score": 1619
  },
  {
    "Name": "Dr. B.R. Ambedkar University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1807
  },
  {
    "Name": "Sanketika Vidya Parishad Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2068
  },
  {
    "Name": "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2118
  },
  {
    "Name": "Andhra Loyola Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2544
  },
  {
    "Name": "Rayalaseema University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2915
  },
  {
    "Name": "Adikavi Nannaya University College of Arts and Commerce",
    "Course": "MBA in Tourism and Hospitality Management",
    "Category": "PWD",
    "Score": 3059
  },
  {
    "Name": "Sri Sivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3289
  },
  {
    "Name": "SVKP & Dr. K S Raju Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3578
  },
  {
    "Name": "Visakha Institute for Professional STDS - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3626
  },
  {
    "Name": "Dr. Lankapalli Bullayya College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1758
  },
  {
    "Name": "Vignan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1877
  },
  {
    "Name": "JNTUA - Jawaharlal Nehru Technological University, Anantapur",
    "Course": "MBA in General Management",
    "Category": "PWD",
    "Score": 2077
  },
  {
    "Name": "Prasad V Potluri Siddhartha Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2196
  },
  {
    "Name": "Adikavi Nannaya University - MSN Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2851
  },
  {
    "Name": "Maharajah's Post Graduate College (MRPG)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3057
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3081
  },
  {
    "Name": "Nalanda Institute of PG Studies",
    "Course": "MBA in Pharmaceutical Management",
    "Category": "PWD",
    "Score": 3484
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in Travel and Tourism Management",
    "Category": "PWD",
    "Score": 3584
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3795
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3862
  },
  {
    "Name": "Vikas Group Of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4014
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "MBA (Fintech)",
    "Category": "PWD",
    "Score": 4414
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Category": "PWD",
    "Score": 4462
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "MBA in Human Resource Management",
    "Category": "PWD",
    "Score": 4550
  },
  {
    "Name": "Dr. MRAR PG Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4726
  },
  {
    "Name": "Sasi Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4786
  },
  {
    "Name": "NRI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4910
  },
  {
    "Name": "Aditya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5132
  },
  {
    "Name": "SD College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5266
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "MBA in Business Analytics",
    "Category": "PWD",
    "Score": 3982
  },
  {
    "Name": "RVR and JC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4211
  },
  {
    "Name": "Sri Kalahastiswara Institute Of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4428
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4541
  },
  {
    "Name": "Noble Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4642
  },
  {
    "Name": "Aditya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4777
  },
  {
    "Name": "Lakireddy Bali Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4825
  },
  {
    "Name": "Krishna Chaitanya Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5037
  },
  {
    "Name": "Dadi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5177
  },
  {
    "Name": "Adikavi Nannaya University (AKNUDOA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5340
  },
  {
    "Name": "Sri Krishnadevaraya Institute of Management (SKIM College)",
    "Course": "MBA in Finance",
    "Category": "PWD",
    "Score": 5503
  },
  {
    "Name": "Vikas Institute of Business Management, Kadapa",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5655
  },
  {
    "Name": "Sreerama Institute of Management",
    "Course": "MBA in General Management",
    "Category": "PWD",
    "Score": 5805
  },
  {
    "Name": "Sri Venkateswara College of Engineering, Tirupati",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5820
  },
  {
    "Name": "Eluru College of Engineering and Technology (ECET, Eluru)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5941
  },
  {
    "Name": "G Pullaiah College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6442
  },
  {
    "Name": "Vasavi MCA And MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6563
  },
  {
    "Name": "Bonam Venkata Chalamayya Engineering College",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6672
  },
  {
    "Name": "Madanapalle Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6866
  },
  {
    "Name": "Samata College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 7171
  },
  {
    "Name": "GVR & S College Of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5588
  },
  {
    "Name": "Sreerama Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5752
  },
  {
    "Name": "Aditya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5816
  },
  {
    "Name": "BABA Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 5829
  },
  {
    "Name": "Kakinada Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6127
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute of Technology (KVSRIT)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6490
  },
  {
    "Name": "Rajiv Gandhi Degree College, Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6637
  },
  {
    "Name": "Vignanasudha Institute of Management And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6688
  },
  {
    "Name": "Nadimpalli Satyanarayana Raju Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 6952
  },
  {
    "Name": "Universal College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 7374
  },
  {
    "Name": "Gudlavalleru Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 7625
  },
  {
    "Name": "Rajeev Gandhi Memorial College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 7780
  },
  {
    "Name": "GIET Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 8325
  },
  {
    "Name": "Nalanda Institute of PG Studies",
    "Course": "MBA in Banking and Financial Services",
    "Category": "PWD",
    "Score": 8676
  },
  {
    "Name": "Avanthi's St.Theressa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 9239
  },
  {
    "Name": "Hindu College of Management",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "PWD",
    "Score": 9417
  },
  {
    "Name": "Chaitanya Bharathi Institute of Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 9756
  },
  {
    "Name": "S.R.K. Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 10316
  },
  {
    "Name": "Haindavi PG college (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 10640
  },
  {
    "Name": "Mandava Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 10778
  },
  {
    "Name": "Sri Srinivasa Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 7699
  },
  {
    "Name": "Godavari Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 8116
  },
  {
    "Name": "Sanskrithi School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 8591
  },
  {
    "Name": "Guntur Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 9081
  },
  {
    "Name": "Sree Vahini Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 9303
  },
  {
    "Name": "Ramachandra College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 9504
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 9854
  },
  {
    "Name": "Pydah College PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 10414
  },
  {
    "Name": "Sanjeev Institute of Planning and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 10735
  },
  {
    "Name": "Sri Vasavi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11241
  },
  {
    "Name": "MVR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11341
  },
  {
    "Name": "Avanthi's Research and Technological Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11471
  },
  {
    "Name": "Anantha Lakshmi Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11522
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "PWD",
    "Score": 11635
  },
  {
    "Name": "Dr. K.V. Subba Reddy School of Business Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11700
  },
  {
    "Name": "Lingayas Institute Of Management And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12089
  },
  {
    "Name": "Gayatri College for PG Courses",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12464
  },
  {
    "Name": "Vignan Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12640
  },
  {
    "Name": "P.V.K.K Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12801
  },
  {
    "Name": "Narayana Engineering College (NEC, Nellore)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12958
  },
  {
    "Name": "GIET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11457
  },
  {
    "Name": "Miracle Educational Society Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11487
  },
  {
    "Name": "Sree Sai MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11555
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology, Chittoor",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11679
  },
  {
    "Name": "Mother Theresa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11817
  },
  {
    "Name": "Hindu College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12449
  },
  {
    "Name": "Nri Institute Of Technology, Guntur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12477
  },
  {
    "Name": "Gitams Business and IT School",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12760
  },
  {
    "Name": "Satya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 12809
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 13071
  },
  {
    "Name": "Kakinada Institute of Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 13107
  },
  {
    "Name": "Annamacharya P.G. College of Computer Studies (APGCCS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 13194
  },
  {
    "Name": "Narasaraopeta Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 13677
  },
  {
    "Name": "Priyadarshini Institute of Technology and Management (PITM, Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14094
  },
  {
    "Name": "VSM College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14286
  },
  {
    "Name": "Palivela PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14508
  },
  {
    "Name": "Visakha Institute Of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14532
  },
  {
    "Name": "Sir C R Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14984
  },
  {
    "Name": "S.R.K. Institute Of Technology",
    "Course": "MBA in Applied Management",
    "Category": "PWD",
    "Score": 15094
  },
  {
    "Name": "TJPS College - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16051
  },
  {
    "Name": "DNR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 13193
  },
  {
    "Name": "The Hindu College - MBA",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 13407
  },
  {
    "Name": "Kuppam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14014
  },
  {
    "Name": "Annamacharya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14135
  },
  {
    "Name": "Sapthagiri Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14294
  },
  {
    "Name": "Vkr, Vnb and Agk College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14523
  },
  {
    "Name": "Avanthi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 14933
  },
  {
    "Name": "Narasaraopeta Engineering College (Autonomous)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 15004
  },
  {
    "Name": "Pace Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 15532
  },
  {
    "Name": "Mother Theresa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16078
  },
  {
    "Name": "Visakha Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16159
  },
  {
    "Name": "Adithe Satyanarayana PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16277
  },
  {
    "Name": "Global Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16836
  },
  {
    "Name": "Kallam Haranadhareddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16885
  },
  {
    "Name": "Kbn College Pg Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17159
  },
  {
    "Name": "KSRM College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17296
  },
  {
    "Name": "Swarnandhra College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17774
  },
  {
    "Name": "Sai Rajeswari Institute of Technology",
    "Course": "MBA in General Management",
    "Category": "PWD",
    "Score": 17904
  },
  {
    "Name": "B.A. and K.R. MCA COLLEGE",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 18466
  },
  {
    "Name": "Sree Rama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 18777
  },
  {
    "Name": "Sri YN College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16266
  },
  {
    "Name": "Wings Business School (WBS, Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16703
  },
  {
    "Name": "Vikas College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16854
  },
  {
    "Name": "St. Ann's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 16913
  },
  {
    "Name": "PBR Visvodaya Institute of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17205
  },
  {
    "Name": "Akkineni Nageswara Rao College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17460
  },
  {
    "Name": "Nova College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17779
  },
  {
    "Name": "RCR Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 17931
  },
  {
    "Name": "QIS College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 18537
  },
  {
    "Name": "Simhadri Educational Society Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 18873
  },
  {
    "Name": "ABR College of Engineering and Technology (ABRCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 18979
  },
  {
    "Name": "Sir C.R. Reddy College PG Management Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 19528
  },
  {
    "Name": "St. Johns College of Engineering and Technology (SJCET, Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 20019
  },
  {
    "Name": "Potti Sriramulu College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 20425
  },
  {
    "Name": "Raos Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 20837
  },
  {
    "Name": "Rajiv Gandhi Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21079
  },
  {
    "Name": "Chadalawada Ramanamma Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21109
  },
  {
    "Name": "Sumourya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21343
  },
  {
    "Name": "Adarsh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 22048
  },
  {
    "Name": "Siddharth Institute of Engineering and Technology (SIETK)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 23199
  },
  {
    "Name": "VRN College of Computer Science and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 19197
  },
  {
    "Name": "Chirala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 19634
  },
  {
    "Name": "Kandula Obul Reddy Memorial College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 20036
  },
  {
    "Name": "Allagadda Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 20526
  },
  {
    "Name": "Sreenivasa Institute of Technology and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 20956
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Renigunta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21093
  },
  {
    "Name": "West Godavari Institute of Science And Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21111
  },
  {
    "Name": "Dravidian University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21430
  },
  {
    "Name": "Santhiram Engineering College (SREC Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 22734
  },
  {
    "Name": "KMM Institute Of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 23263
  },
  {
    "Name": "Sree Vyshnavi MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 23330
  },
  {
    "Name": "Sharada Post Graduate Institute of Research and Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24451
  },
  {
    "Name": "GATES Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24570
  },
  {
    "Name": "Srinivasa Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24888
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24961
  },
  {
    "Name": "K.G.R.L. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 25642
  },
  {
    "Name": "Edge International B School",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26241
  },
  {
    "Name": "Jagan's Institute of Management and Computer Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26376
  },
  {
    "Name": "Ramireddy Subbarami Reddy Engineering College (RSREC)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26484
  },
  {
    "Name": "Royal School Of Information and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26732
  },
  {
    "Name": "R K College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 23936
  },
  {
    "Name": "Vijayam Business School",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24560
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24605
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 24933
  },
  {
    "Name": "Sri Mittapalli College of Engineering - Guntur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 25490
  },
  {
    "Name": "Chaitanya Institute Of Science And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26028
  },
  {
    "Name": "Sri Vani Institute Of Management And Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26355
  },
  {
    "Name": "Rise Krishna Sai Prakasam Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26443
  },
  {
    "Name": "Dr CSN Degree And PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26586
  },
  {
    "Name": "D.N.R College (DNRC Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26766
  },
  {
    "Name": "SDGS College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 26827
  },
  {
    "Name": "Bhimavaram Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 27082
  },
  {
    "Name": "Akshara Institute of Management and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 27400
  },
  {
    "Name": "Prabhath Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 27777
  },
  {
    "Name": "Sree Konaseema Bhanoji Ramars P.G. College (SKBR College)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28180
  },
  {
    "Name": "Madhura Sai Institute of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28489
  },
  {
    "Name": "Dr.AER College of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28633
  },
  {
    "Name": "Sairam Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28887
  },
  {
    "Name": "KKC Institute of Post Graduate Stuides",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 29578
  },
  {
    "Name": "Sri Balaji Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 30703
  },
  {
    "Name": "Viswam Degree and PG College",
    "Course": "Master of Business Management (MBA)",
    "Category": "PWD",
    "Score": 26957
  },
  {
    "Name": "Chiranjeevi Reddy Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 27395
  },
  {
    "Name": "Krishna Chaitanya Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 27488
  },
  {
    "Name": "Vemu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 27914
  },
  {
    "Name": "Dwarampudi Lakshmana Reddy College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28446
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "MBA in General Management",
    "Category": "PWD",
    "Score": 28609
  },
  {
    "Name": "Seshachala Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28756
  },
  {
    "Name": "Eswar College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 28916
  },
  {
    "Name": "Sri Harsha Institute of PG Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 30116
  },
  {
    "Name": "Audisankara Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 30757
  },
  {
    "Name": "Viswam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 31128
  },
  {
    "Name": "Sri Vidya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 32047
  },
  {
    "Name": "Sir C.V Raman Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 32359
  },
  {
    "Name": "Sri Ramakrishna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 32664
  },
  {
    "Name": "Sri Sai College of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34042
  },
  {
    "Name": "Rayalaseema Institute of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34233
  },
  {
    "Name": "SVR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34696
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34943
  },
  {
    "Name": "Balaji Institute of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 35292
  },
  {
    "Name": "K. Chandrakala PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 35872
  },
  {
    "Name": "Loyola Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 31817
  },
  {
    "Name": "Priyadarshini Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 32264
  },
  {
    "Name": "Sri Sarathi Institute Of Engineering  And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 32500
  },
  {
    "Name": "MJR Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 32947
  },
  {
    "Name": "Bheemi Reddy Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34196
  },
  {
    "Name": "Vignan's Nirula Institute of Technology and Science for Women",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34472
  },
  {
    "Name": "Golden Valley Integrated Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 34757
  },
  {
    "Name": "PRR Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 35266
  },
  {
    "Name": "Narayana Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 35720
  },
  {
    "Name": "Harinadha Reddy Institute Of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 35895
  },
  {
    "Name": "Sri Padmavathi College of Computer Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 36002
  },
  {
    "Name": "Prabhath Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 36014
  },
  {
    "Name": "Shree Vivekananda Institute of Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 36090
  },
  {
    "Name": "Gokula Krishna College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 36644
  },
  {
    "Name": "Narayana Engineering College, Gudur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 36694
  },
  {
    "Name": "Sri Datta Sai School Of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 38416
  },
  {
    "Name": "Newton's Institute of Engineering (NIE Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 37655
  },
  {
    "Name": "Audisankara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 40297
  },
  {
    "Name": "Sri Venkatesa Perumal College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 38471
  },
  {
    "Name": "Sri Venkateswara Paladugu Nagaiah Chowdary",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 40481
  },
  {
    "Name": "Sri Sai Institute Of Technology and Science (SSITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 40872
  },
  {
    "Name": "ANUCDE Guntur",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 47
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Retail Management",
    "Category": "EWS",
    "Score": 185
  },
  {
    "Name": "Jawaharlal Nehru Technological University, Kakinada",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 255
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 307
  },
  {
    "Name": "Krishna University",
    "Course": "Discontinued (Sep 2022)- Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 562
  },
  {
    "Name": "Prasad V Potluri Siddhartha Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1090
  },
  {
    "Name": "College of Commerce, Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1524
  },
  {
    "Name": "Vignan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1541
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in Hospital Administration",
    "Category": "EWS",
    "Score": 1619
  },
  {
    "Name": "Velagapudi Ramakrishna Siddhartha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1674
  },
  {
    "Name": "Dr.Agarala Eswara Reddi Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 67
  },
  {
    "Name": "College of Arts and Commerce, Andhra University, Visakhapatnam",
    "Course": "MBA in Banking and Financial Services",
    "Category": "EWS",
    "Score": 196
  },
  {
    "Name": "Chaitanya Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 269
  },
  {
    "Name": "Adikavi Nannaya University College of Arts and Commerce",
    "Course": "Master in Business Administration",
    "Category": "EWS",
    "Score": 322
  },
  {
    "Name": "Aditya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1012
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in international Business",
    "Category": "EWS",
    "Score": 1367
  },
  {
    "Name": "Malineni Lakshmaiah MBA College",
    "Course": "Master of Business Administration",
    "Category": "EWS",
    "Score": 1535
  },
  {
    "Name": "Gayatri Vidya Parishad College for Degree and PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1561
  },
  {
    "Name": "Maharajah's Post Graduate College (MRPG)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1638
  },
  {
    "Name": "Dr. Lankapalli Bullayya College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1758
  },
  {
    "Name": "Dr. B.R. Ambedkar University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1807
  },
  {
    "Name": "G Pullaiah College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2034
  },
  {
    "Name": "Paladugu Parvathi Devi College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2253
  },
  {
    "Name": "Andhra Loyola Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2544
  },
  {
    "Name": "Adikavi Nannaya University College of Arts and Commerce",
    "Course": "MBA in Tourism and Hospitality Management",
    "Category": "EWS",
    "Score": 3059
  },
  {
    "Name": "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3481
  },
  {
    "Name": "RVR and JC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3709
  },
  {
    "Name": "Vikas Group Of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4014
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "MBA (Fintech)",
    "Category": "EWS",
    "Score": 4414
  },
  {
    "Name": "Aditya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4777
  },
  {
    "Name": "Yogi Vemana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1917
  },
  {
    "Name": "JNTUA - Jawaharlal Nehru Technological University, Anantapur",
    "Course": "MBA in General Management",
    "Category": "EWS",
    "Score": 2077
  },
  {
    "Name": "Sanketika Vidya Parishad Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2317
  },
  {
    "Name": "Adikavi Nannaya University - MSN Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2851
  },
  {
    "Name": "Prakasam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3185
  },
  {
    "Name": "Acharya Nagarjuna University",
    "Course": "MBA in Travel and Tourism Management",
    "Category": "EWS",
    "Score": 3584
  },
  {
    "Name": "JNTUA College of Engineering, Ananthapuramu",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3795
  },
  {
    "Name": "Sasi Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4058
  },
  {
    "Name": "Dr. MRAR PG Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4726
  },
  {
    "Name": "Lakireddy Bali Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4825
  },
  {
    "Name": "Sreenivasa Institute of Technology and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5003
  },
  {
    "Name": "Parvathaneni Brahmayya Siddhartha College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5131
  },
  {
    "Name": "Dadi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5177
  },
  {
    "Name": "GVR & S College Of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5588
  },
  {
    "Name": "Vikas Institute of Business Management, Kadapa",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5655
  },
  {
    "Name": "Visakha Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5683
  },
  {
    "Name": "BABA Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5829
  },
  {
    "Name": "Kakinada Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6127
  },
  {
    "Name": "Sri Venkateswara College of Engineering, Tirupati",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6421
  },
  {
    "Name": "Ramireddy Subbarami Reddy Engineering College (RSREC)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6526
  },
  {
    "Name": "Krishna Chaitanya Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5037
  },
  {
    "Name": "Aditya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5132
  },
  {
    "Name": "Adikavi Nannaya University (AKNUDOA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5340
  },
  {
    "Name": "Noble Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5653
  },
  {
    "Name": "Visakha Institute for Professional STDS - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5677
  },
  {
    "Name": "Aditya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5816
  },
  {
    "Name": "Eluru College of Engineering and Technology (ECET, Eluru)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5941
  },
  {
    "Name": "Sri Vasavi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6385
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute of Technology (KVSRIT)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6490
  },
  {
    "Name": "Bonam Venkata Chalamayya Engineering College",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6672
  },
  {
    "Name": "Madanapalle Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6866
  },
  {
    "Name": "SVKP & Dr. K S Raju Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 7083
  },
  {
    "Name": "Samata College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 7814
  },
  {
    "Name": "Godavari Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 8116
  },
  {
    "Name": "Rajiv Gandhi Degree College, Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9013
  },
  {
    "Name": "Avanthi's St.Theressa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9239
  },
  {
    "Name": "Sree Konaseema Bhanoji Ramars P.G. College (SKBR College)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9463
  },
  {
    "Name": "Chaitanya Bharathi Institute of Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9756
  },
  {
    "Name": "Sree Rama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9844
  },
  {
    "Name": "Potti Sriramulu College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9974
  },
  {
    "Name": "Rayalaseema University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 6959
  },
  {
    "Name": "Gudlavalleru Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 7625
  },
  {
    "Name": "SD College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 7892
  },
  {
    "Name": "GIET Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 8325
  },
  {
    "Name": "Guntur Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9081
  },
  {
    "Name": "Hindu College of Management",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "EWS",
    "Score": 9417
  },
  {
    "Name": "P.V.K.K Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9507
  },
  {
    "Name": "Rajeev Gandhi Memorial College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9760
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9854
  },
  {
    "Name": "Swarnandhra College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10304
  },
  {
    "Name": "Sanskrithi School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10386
  },
  {
    "Name": "Haindavi PG college (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10640
  },
  {
    "Name": "Madhura Sai Institute of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10916
  },
  {
    "Name": "GIET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11457
  },
  {
    "Name": "Miracle Educational Society Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11487
  },
  {
    "Name": "Sree Sai MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11555
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology, Chittoor",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11679
  },
  {
    "Name": "PBR Visvodaya Institute of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11861
  },
  {
    "Name": "Vikas College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12448
  },
  {
    "Name": "Gayatri College for PG Courses",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12464
  },
  {
    "Name": "Sree Vahini Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10459
  },
  {
    "Name": "Mandava Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10778
  },
  {
    "Name": "Srinivasa Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11112
  },
  {
    "Name": "Avanthi's Research and Technological Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11471
  },
  {
    "Name": "Anantha Lakshmi Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11522
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "MBA in Healthcare and Hospital Management",
    "Category": "EWS",
    "Score": 11635
  },
  {
    "Name": "Dr. K.V. Subba Reddy School of Business Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11700
  },
  {
    "Name": "Lingayas Institute Of Management And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12089
  },
  {
    "Name": "Hindu College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12449
  },
  {
    "Name": "Nri Institute Of Technology, Guntur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12477
  },
  {
    "Name": "Satya Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12515
  },
  {
    "Name": "Gitams Business and IT School",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12760
  },
  {
    "Name": "Kakinada Institute of Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13107
  },
  {
    "Name": "Annamacharya P.G. College of Computer Studies (APGCCS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13194
  },
  {
    "Name": "Sri Vidya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13549
  },
  {
    "Name": "MVR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13802
  },
  {
    "Name": "Annamacharya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14135
  },
  {
    "Name": "Pydah College PG Courses",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14276
  },
  {
    "Name": "Avanthi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14933
  },
  {
    "Name": "Wings Business School (WBS, Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15049
  },
  {
    "Name": "Vignan Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12640
  },
  {
    "Name": "Dr. K.V. Subba Reddy Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13071
  },
  {
    "Name": "DNR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13193
  },
  {
    "Name": "The Hindu College - MBA",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13407
  },
  {
    "Name": "Narasaraopeta Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13677
  },
  {
    "Name": "Kuppam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14014
  },
  {
    "Name": "Sri Sivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14176
  },
  {
    "Name": "Sapthagiri Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14294
  },
  {
    "Name": "Sir C R Reddy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14984
  },
  {
    "Name": "Vemu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15297
  },
  {
    "Name": "VSM College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15554
  },
  {
    "Name": "Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15912
  },
  {
    "Name": "Adithe Satyanarayana PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16277
  },
  {
    "Name": "Global Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16836
  },
  {
    "Name": "St. Ann's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16913
  },
  {
    "Name": "Kbn College Pg Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 17159
  },
  {
    "Name": "KSRM College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 17296
  },
  {
    "Name": "Sumourya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 17730
  },
  {
    "Name": "Sai Rajeswari Institute of Technology",
    "Course": "MBA in General Management",
    "Category": "EWS",
    "Score": 17904
  },
  {
    "Name": "Vignanasudha Institute of Management And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 18213
  },
  {
    "Name": "NRI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15730
  },
  {
    "Name": "Mother Theresa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16078
  },
  {
    "Name": "Sri Balaji Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16751
  },
  {
    "Name": "Kallam Haranadhareddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16885
  },
  {
    "Name": "Pace Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16942
  },
  {
    "Name": "Nadimpalli Satyanarayana Raju Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 17202
  },
  {
    "Name": "Akkineni Nageswara Rao College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 17460
  },
  {
    "Name": "Nova College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 17779
  },
  {
    "Name": "Audisankara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 18011
  },
  {
    "Name": "Santhiram Engineering College (SREC Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 18267
  },
  {
    "Name": "B.A. and K.R. MCA COLLEGE",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 18466
  },
  {
    "Name": "ABR College of Engineering and Technology (ABRCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 18979
  },
  {
    "Name": "Sir C.R. Reddy College PG Management Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19528
  },
  {
    "Name": "Vikrama Simhapuri University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19681
  },
  {
    "Name": "Kandula Obul Reddy Memorial College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20036
  },
  {
    "Name": "Prabhath Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20267
  },
  {
    "Name": "Allagadda Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20526
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Renigunta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21093
  },
  {
    "Name": "West Godavari Institute of Science And Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21111
  },
  {
    "Name": "Dravidian University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21430
  },
  {
    "Name": "Sri Vani Institute Of Management And Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 18794
  },
  {
    "Name": "Rise Krishna Sai Prakasam Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19289
  },
  {
    "Name": "Chirala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19634
  },
  {
    "Name": "St. Johns College of Engineering and Technology (SJCET, Kurnool)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20019
  },
  {
    "Name": "Vasavi MCA And MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20097
  },
  {
    "Name": "Simhadri Educational Society Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20464
  },
  {
    "Name": "Ramachandra College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20540
  },
  {
    "Name": "Chadalawada Ramanamma Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21109
  },
  {
    "Name": "Narasaraopeta Engineering College (Autonomous)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21167
  },
  {
    "Name": "Adarsh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 22048
  },
  {
    "Name": "Sri YN College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 22117
  },
  {
    "Name": "KMM Institute Of Technology And Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 23263
  },
  {
    "Name": "R K College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 23936
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 24605
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 24961
  },
  {
    "Name": "Raos Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 25444
  },
  {
    "Name": "K.G.R.L. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 25642
  },
  {
    "Name": "Edge International B School",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26241
  },
  {
    "Name": "Dr CSN Degree And PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26586
  },
  {
    "Name": "Sri Kalahastiswara Institute Of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26955
  },
  {
    "Name": "Newton's Institute of Engineering (NIE Guntur)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 22676
  },
  {
    "Name": "TJPS College - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 23920
  },
  {
    "Name": "GATES Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 24570
  },
  {
    "Name": "Amrita Sai Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 24933
  },
  {
    "Name": "Visakha Institute Of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 25076
  },
  {
    "Name": "Sri Mittapalli College of Engineering - Guntur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 25490
  },
  {
    "Name": "Chaitanya Institute Of Science And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26028
  },
  {
    "Name": "Jagan's Institute of Management and Computer Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26376
  },
  {
    "Name": "D.N.R College (DNRC Andhra Pradesh)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26766
  },
  {
    "Name": "Viswam Degree and PG College",
    "Course": "Master of Business Management (MBA)",
    "Category": "EWS",
    "Score": 26957
  },
  {
    "Name": "Palivela PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26987
  },
  {
    "Name": "Bhimavaram Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27082
  },
  {
    "Name": "Chiranjeevi Reddy Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27395
  },
  {
    "Name": "Krishna Chaitanya Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27488
  },
  {
    "Name": "Prabhath Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27777
  },
  {
    "Name": "Sree Vyshnavi MBA College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28195
  },
  {
    "Name": "SDGS College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28413
  },
  {
    "Name": "Gonna Institute of Information Technology and Sciences",
    "Course": "MBA in General Management",
    "Category": "EWS",
    "Score": 28609
  },
  {
    "Name": "Sairam Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28887
  },
  {
    "Name": "Sharada Post Graduate Institute of Research and Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 29254
  },
  {
    "Name": "PRR Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27028
  },
  {
    "Name": "QIS College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27286
  },
  {
    "Name": "Akshara Institute of Management and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27400
  },
  {
    "Name": "Sri Venkatesa Perumal College of Engineering and Technology (SVPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27706
  },
  {
    "Name": "RCR Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27988
  },
  {
    "Name": "Royal School Of Information and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28365
  },
  {
    "Name": "Dwarampudi Lakshmana Reddy College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28446
  },
  {
    "Name": "Dr.AER College of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28633
  },
  {
    "Name": "Eswar College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28916
  },
  {
    "Name": "KKC Institute of Post Graduate Stuides",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 29578
  },
  {
    "Name": "VRN College of Computer Science and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 29892
  },
  {
    "Name": "Narayana Engineering College (NEC, Nellore)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 30630
  },
  {
    "Name": "Loyola Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 31817
  },
  {
    "Name": "Sir C.V Raman Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 32359
  },
  {
    "Name": "Sri Ramakrishna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 32664
  },
  {
    "Name": "Sri Sai Institute Of Technology and Science (SSITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33066
  },
  {
    "Name": "Sanjeev Institute of Planning and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33225
  },
  {
    "Name": "Sri Datta Sai School Of Business",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33810
  },
  {
    "Name": "Rayalaseema Institute of Information And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33915
  },
  {
    "Name": "Vignan's Nirula Institute of Technology and Science for Women",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 34472
  },
  {
    "Name": "Sri Harsha Institute of PG Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 30116
  },
  {
    "Name": "Audisankara Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 30757
  },
  {
    "Name": "Seshachala Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 31997
  },
  {
    "Name": "Sri Sarathi Institute Of Engineering  And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 32500
  },
  {
    "Name": "MJR Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 32947
  },
  {
    "Name": "Rajiv Gandhi Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33128
  },
  {
    "Name": "Sreerama Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33317
  },
  {
    "Name": "Narayana Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 33865
  },
  {
    "Name": "Bheemi Reddy Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 34196
  },
  {
    "Name": "SVR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 34696
  },
  {
    "Name": "Golden Valley Integrated Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 34757
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 34943
  },
  {
    "Name": "K. Chandrakala PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 35872
  },
  {
    "Name": "Priyadarshini Institute Of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 36377
  },
  {
    "Name": "Siddharth Institute of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 36735
  },
  {
    "Name": "Sri Sai College of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 34906
  },
  {
    "Name": "Balaji Institute of IT And Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 35292
  },
  {
    "Name": "Harinadha Reddy Institute Of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 35895
  },
  {
    "Name": "Gokula Krishna College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 36644
  },
  {
    "Name": "Vijayam Business School",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 36907
  }
]