[
  {
    "Name": "Stanford GSB",
    "Score": 328,
    "Course": "MBA"
  },
  {
    "Name": "Harvard Business School",
    "Score": 326,
    "Course": "MBA"
  },
  {
    "Name": "The Wharton School",
    "Score": 324,
    "Course": "MBA"
  },
  {
    "Name": "Northwestern Kellogg",
    "Score": 326,
    "Course": "MBA"
  },
  {
    "Name": "Chicago Booth",
    "Score": 325,
    "Course": "MBA"
  },
  {
    "Name": "Columbia Business School",
    "Score": "N/A",
    "Course": "MBA"
  },
  {
    "Name": "MIT Sloan",
    "Score": 324,
    "Course": "MBA"
  },
  {
    "Name": "Dartmouth Tuck",
    "Score": 322,
    "Course": "MBA"
  },
  {
    "Name": "Berkeley Haas",
    "Score": 324,
    "Course": "MBA"
  },
  {
    "Name": "Virginia Darden",
    "Score": 321,
    "Course": "MBA"
  },
  {
    "Name": "Yale SOM",
    "Score": 330,
    "Course": "MBA"
  },
  {
    "Name": "Duke Fuqua",
    "Score": 318,
    "Course": "MBA"
  },
  {
    "Name": "Michigan Ross",
    "Score": 321,
    "Course": "MBA"
  },
  {
    "Name": "UT Austin McCombs",
    "Score": 321,
    "Course": "MBA"
  },
  {
    "Name": "Cornell Johnson",
    "Score": "N/A",
    "Course": "MBA"
  },
  {
    "Name": "UCLA Anderson",
    "Score": 327,
    "Course": "MBA"
  },
  {
    "Name": "NYU Stern",
    "Score": 326,
    "Course": "MBA"
  },
  {
    "Name": "Carnegie Mellon Tepper",
    "Score": 324,
    "Course": "MBA"
  },
  {
    "Name": "UNC Kenan-Flagler",
    "Score": "N/A",
    "Course": "MBA"
  },
  {
    "Name": "Emory Goizueta",
    "Score": "N/A",
    "Course": "MBA"
  },
  {
    "Name": "USC Marshall",
    "Score": 323,
    "Course": "MBA"
  },
  {
    "Name": "Georgetown McDonough",
    "Score": 318,
    "Course": "MBA"
  },
  {
    "Name": "UW Foster",
    "Score": 322,
    "Course": "MBA"
  },
  {
    "Name": "Rice Jones",
    "Score": 317,
    "Course": "MBA"
  }
]