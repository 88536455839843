import React from 'react';

const AdvertiseSection = () => {
  return (
    <a
      href="https://ecadera.com"
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full"
    >
      <div className="flex flex-col md:flex-row items-center bg-gradient-to-r from-blue-100 via-white to-blue-100 rounded-lg shadow-lg p-6 space-y-6 md:space-y-0 md:space-x-6 hover:shadow-2xl transition-shadow duration-300">
        {/* Left Side Image */}
        <div className="flex-shrink-0 w-full md:w-1/3">
          <img
            src="https://th.bing.com/th/id/OIP.3s1197PsCvK0rjfSkXmQRQHaFj?w=222&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
            className="w-full h-32 md:h-40 object-contain rounded-md"
            alt="Left Advertisement"
          />
        </div>

        {/* Middle Content */}
        <div className="flex-grow text-center md:text-left">
          <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-2">
            100% Job Guarantee with Ecadera
          </h2>
          <p className="text-gray-700 text-sm md:text-base leading-relaxed mb-4">
            Worried about getting the job? Ecadera is here to take the stress
            out of job hunting with a 100% Job Guarantee in top MNCs. Our goal
            is to get you hired—fast and for real.
          </p>
          <a
            href="https://ecadera.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-500 text-white px-6 py-2 rounded-md text-sm md:text-base font-semibold hover:bg-blue-600 transition-colors duration-300"
          >
            Take the first step towards your dream career
          </a>
        </div>

        {/* Right Side Image */}
        <div className="flex-shrink-0 w-full md:w-1/3">
          <img
            src="https://media.licdn.com/dms/image/v2/C560BAQGaX5XrkHCrhA/company-logo_200_200/company-logo_200_200/0/1646638753842/ecadera_logo?e=1745452800&v=beta&t=LwwypbY4EgtEDssMqUOGwt3QegRaxNreH_W9a1t0Ww0"
            className="w-full h-32 md:h-40 object-contain rounded-md"
            alt="Right Advertisement"
          />
        </div>
      </div>
    </a>
  );
};

export default AdvertiseSection;
