import React, { useState } from "react";
import { Modal, Input, Form, Button } from "antd";
import data from "./Data/data.json"; // Ensure this path and data are correct

const NeetUGAllIndiaPredictor = () => {
  const [quota, setQuota] = useState("");
  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");
  const [minRank, setMinRank] = useState("");
  const [error, setError] = useState("");
  const [showData, setShowData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleShowData = () => {
    const isUserFilled = localStorage.getItem("userDetails");

    if (!isUserFilled) {
      setIsModalVisible(true); // Show modal if user details are not saved
      return;
    }

    if (!quota || !course || !category || !minRank) {
      setError("We cannot predict without all data!");
      setShowData(false);
    } else {
      setError("");
      setShowData(true);
      setCurrentPage(1);
    }
  };

  const handleModalSubmit = async (values) => {
    try {
      // Send the data to the API
      const response = await fetch('https://caderaedu.com/api/form/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fullName: values.fullName,
          email: values.email,
          mobileNumber: values.mobileNumber,
          city: values.city,
          courseInterestedIn: values.courseInterestedIn,
        }),
      });

      if (response.ok) {
        // Successfully submitted, save user details in localStorage
        localStorage.setItem('userDetails', JSON.stringify(values));
        setIsModalVisible(false);
        handleShowData(); // Trigger data processing after modal submit
      } else {
        // Handle server errors
        setError('Failed to submit your details, please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const filteredData = data.filter((row) => {
    return (
      (!quota || row["Allotted Quota"].includes(quota)) &&
      (!course || row.Course.includes(course)) &&
      (!category || row["Candidate Category"] === category) &&
      (!minRank || row.Rank >= Number(minRank))
    );
  });

  // Get unique institutions
  const uniqueInstitutes = [
    ...new Map(filteredData.map((item) => [item["Allotted Institute"], item])).values(),
  ];

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentData = uniqueInstitutes.slice(indexOfFirstResult, indexOfLastResult);
  const totalPages = Math.ceil(uniqueInstitutes.length / resultsPerPage);

  const changePage = (direction) => {
    setCurrentPage((prevPage) =>
      direction === "next"
        ? Math.min(prevPage + 1, totalPages)
        : Math.max(prevPage - 1, 1)
    );
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-6 text-center">
        NEET UG All India Counselling Predictor
      </h2>

      {error && (
        <div className="bg-red-100 text-red-700 p-4 mb-6 rounded-lg border border-red-300">
          {error}
        </div>
      )}

      <div className="flex justify-center items-center  px-4 py-6">
        <div className="w-full max-w-2xl bg-white p-6 rounded-lg shadow-lg">
          {/* Quota Dropdown */}
          <label className="flex flex-col mb-4">
            <span className="mb-2 font-semibold text-gray-700">Quota:</span>
            <select
              value={quota}
              onChange={handleInputChange(setQuota)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option value="">Select Quota</option>
              <option value="Open Seat Quota">Open Seat Quota</option>
              <option value="All India">All India</option>
              <option value="Delhi University Quota">Delhi University Quota</option>
              <option value="Deemed/Paid Seats Quota">Deemed/Paid Seats Quota</option>
            </select>
          </label>

          {/* Course Dropdown */}
          <label className="flex flex-col mb-4">
            <span className="mb-2 font-semibold text-gray-700">Course:</span>
            <select
              value={course}
              onChange={handleInputChange(setCourse)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option value="">Select Course</option>
              <option value="MBBS">MBBS</option>
              <option value="BDS">BDS</option>
              <option value="B.Sc. Nursing">B.Sc. Nursing</option>
            </select>
          </label>

          {/* Category Dropdown */}
          <label className="flex flex-col mb-4">
            <span className="mb-2 font-semibold text-gray-700">Category:</span>
            <select
              value={category}
              onChange={handleInputChange(setCategory)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option value="">Select Category</option>
              <option value="General">General</option>
              <option value="OBC">OBC</option>
              <option value="SC">SC</option>
              <option value="EWS">EWS</option>
              <option value="ST">ST</option>
            </select>
          </label>

          {/* Minimum Rank Input */}
          <label className="flex flex-col mb-4">
            <span className="mb-2 font-semibold text-gray-700">Rank:</span>
            <input
              type="number"
              value={minRank}
              onChange={handleInputChange(setMinRank)}
              placeholder="e.g. 1000"
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </label>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <button
          onClick={handleShowData}
          className="bg-teal-500 text-white px-4 py-2 rounded-lg shadow-md transition-all"
        >
          Predict Institute
        </button>
      </div>

      {/* Modal for First-Time Form */}
      <Modal
        title="Enter Your Details"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form layout="vertical" onFinish={handleModalSubmit}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: "Please enter your name" }]} >
            <Input placeholder="Enter your full name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email" }, { type: "email", message: "Please enter a valid email" }]}>
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[{ required: true, message: "Please enter your mobile number" }, { pattern: /^[0-9]{10}$/, message: "Please enter a valid 10-digit mobile number" }]}>
            <Input placeholder="Enter your mobile number" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}>
            <Input placeholder="Enter your city" />
          </Form.Item>
          <Form.Item
            label="Course Interested In"
            name="courseInterestedIn"
            rules={[{ required: true, message: "Please enter your course" }]}>
            <Input placeholder="Enter your course" />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form>
      </Modal>

      {/* Show Data */}
      {showData && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Results:</h3>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse min-w-[600px]">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Sr. No.</th>
                  <th className="px-4 py-2 border">Allotted Institute</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2 text-center">{index + indexOfFirstResult + 1}</td>
                    <td className="px-4 py-2">{item["Allotted Institute"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="flex justify-between mt-4">
              <Button onClick={() => changePage("prev")} disabled={currentPage === 1}>
                Prev
              </Button>
              <span>{`${currentPage} of ${totalPages}`}</span>
              <Button onClick={() => changePage("next")} disabled={currentPage === totalPages}>
                Next
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NeetUGAllIndiaPredictor;
