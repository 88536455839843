import React, { useState } from 'react';
import { CheckCircle } from 'lucide-react';
import axios from 'axios';

const Hero = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    pincode: '',
    agreed: false,
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'http://localhost:3003/api/studyabroadforms',
        formData
      );
      if (response.status === 200 || response.status === 201) {
        setSubmissionSuccess(true);
        setFormData({
          name: '',
          email: '',
          mobileNumber: '',
          pincode: '',
          agreed: false,
        });
        setTimeout(() => setSubmissionSuccess(false), 3000); // Hide success message after 3 seconds
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="relative min-h-[600px] overflow-hidden">
      {/* Video Background */}
      <div className="absolute inset-0 z-0">
        <video
          className="w-full h-full object-cover opacity-50"
          autoPlay
          loop
          muted
        >
          <source src="https://videos.pexels.com/video-files/8061028/8061028-hd_1920_1080_25fps.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="z-10 container mx-auto px-10 py-12 md:py-24 flex flex-col md:flex-row items-center justify-between">
        {/* Left Content */}
        <div className="md:w-1/2 mb-8 md:mb-0 text-left">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Take the First Step to<br />
            <span className="text-5xl md:text-7xl animate-typing text-blue-500">
              STUDY ABROAD
            </span>
          </h1>
          <div className="space-y-4">
            <div className="flex items-center justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <p>Courses starting from ₹8 Lakhs*</p>
            </div>
            <div className="flex items-center justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <p>Scholarship worth ₹10,00,000*</p>
            </div>
            <div className="flex items-center justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <p>Offer letter in less than 48 hours*</p>
            </div>
          </div>
        </div>

        {/* Right Form */}
        <div className="relative z-10 md:w-1/3 bg-white rounded-lg p-6 text-gray-800">
          {submissionSuccess ? (
            <div className="text-center text-green-500 font-bold text-lg">
              🎉 Successfully Submitted! 🎉
            </div>
          ) : (
            <>
              <h2 className="text-2xl font-bold text-center mb-6">
                Start your Study Abroad Journey
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <input
                    type="text"
                    placeholder="Enter Full Name*"
                    className="w-full px-4 py-2 border rounded-md"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Enter Email Address*"
                    className="w-full px-4 py-2 border rounded-md"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="flex">
                  <select className="px-2 py-2 border rounded-l-md bg-gray-50">
                    <option>+91</option>
                  </select>
                  <input
                    type="tel"
                    placeholder="Mobile number*"
                    className="w-full px-4 py-2 border border-l-0 rounded-r-md"
                    value={formData.mobileNumber}
                    onChange={(e) =>
                      setFormData({ ...formData, mobileNumber: e.target.value })
                    }
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Country you want*"
                    className="w-full px-4 py-2 border rounded-md"
                    value={formData.pincode}
                    onChange={(e) =>
                      setFormData({ ...formData, pincode: e.target.value })
                    }
                  />
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="terms"
                    className="mr-2"
                    checked={formData.agreed}
                    onChange={(e) =>
                      setFormData({ ...formData, agreed: e.target.checked })
                    }
                  />
                  <label htmlFor="terms" className="text-sm">
                    I have read and agreed to{' '}
                    <a href="#" className="text-blue-500">
                      terms
                    </a>{' '}
                    &{' '}
                    <a href="#" className="text-blue-500">
                      privacy policy
                    </a>
                  </label>
                </div>
                <button className="w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition-colors">
                  Book your free consultation
                </button>
              </form>
            </>
          )}
        </div>
      </div>

      <style jsx>{`
        @keyframes typing {
          0% {
            width: 0;
          }
          50% {
            width: 100%;
          }
          100% {
            width: 0;
          }
        }

        .animate-typing {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          width: 0;
          border-right: 3px solid;
          animation: typing 4s steps(30) 0s infinite;
        }
      `}</style>
    </div>
  );
};

export default Hero;