import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Form } from "antd";
import "./neetpg.css"; // Include the custom CSS file for styles
import data from "./Neetpg-data.json"; // Your data
function App() {
  const [allottedQuota, setAllottedQuota] = useState("");
  const [candidateCategory, setCandidateCategory] = useState("");
  const [course, setCourse] = useState("");
  const [rank, setRank] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResults, setShowResults] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formError, setFormError] = useState("");

  const rowsPerPage = 10;
  const handleModalClose = () => {
    if (!formData.fullName || !formData.email || !formData.mobileNumber || !formData.city || !formData.courseInterestedIn) {
      alert("Please fill all the details before closing.");
    } else {
      setShowSignupModal(false);
    }
  };
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    city: "",
    courseInterestedIn: "",
  });
  useEffect(() => {
    const storedFormData = localStorage.getItem("neetpgFormData");
    if (storedFormData) {
        setFormData(JSON.parse(storedFormData));
        setIsFormSubmitted(true);
    }
}, []);

  // Load form data from local storage on mount
  useEffect(() => {
    const storedFormData = localStorage.getItem("neetpgFormData");
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
      setIsFormSubmitted(true);
    }
  }, []);

  const handleFilter = () => {
    // Check if form is submitted
    if (!isFormSubmitted) {
      setShowSignupModal(true);
      return;
    }
  
    // Check if all fields are filled
    if (!allottedQuota || !candidateCategory || !course || !rank) {
      alert("Please fill all the fields to predict institutes.");
      return;
    }
  
    const filtered = data
      .filter((item) =>
        (allottedQuota ? item["Allotted Quota"] === allottedQuota : true) &&
        (candidateCategory ? item["Candidate Category"] === candidateCategory : true) &&
        (course ? item["Course"] === course : true) &&
        (rank ? item["Rank"] >= Number(rank) : true)
      )
      .sort((a, b) => a["Rank"] - b["Rank"]);
  
    const uniqueInstitutes = [];
    const uniqueInstituteNames = new Set();
    filtered.forEach((item) => {
      if (!uniqueInstituteNames.has(item["Allotted Institute"])) {
        uniqueInstitutes.push(item);
        uniqueInstituteNames.add(item["Allotted Institute"]);
      }
    });
  
    setFilteredData(uniqueInstitutes);
    setCurrentPage(1);
    setShowResults(true);
  };
  

  const handleFormSubmit = async () => {
    setFormError(""); // Clear any previous error messages
    try {
      const response = await fetch("https://caderaedu.com/api/form/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (result.status === "success") {
        localStorage.setItem("neetpgFormData", JSON.stringify(formData)); // Save form data to local storage
        setIsFormSubmitted(true);
        setShowSignupModal(false);
        alert("Form submitted successfully!");
      } else {
        setFormError("Failed to submit form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormError("An error occurred while submitting the form.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const uniqueAllottedQuotas = [...new Set(data.map((item) => item["Allotted Quota"]))];
  const uniqueCandidateCategories = [...new Set(data.map((item) => item["Candidate Category"]))];
  const uniqueCourses = [...new Set(data.map((item) => item["Course"]))];

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  return (
    <div className="app-container">
      <h1 className="title">NEET-PG Predictor</h1>
      <Modal
  title="Enter details to use"
  visible={showSignupModal}
  onCancel={handleModalClose} // Modified the onCancel to handle data validation
  footer={null}
>
  <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
    <Form.Item label="Full Name" required>
      <Input
        name="fullName"
        value={formData.fullName}
        onChange={handleInputChange}
      />
    </Form.Item>
    <Form.Item label="Email" required>
      <Input
        name="email"
        value={formData.email}
        onChange={handleInputChange}
      />
    </Form.Item>
    <Form.Item label="Mobile Number" required>
      <Input
        name="mobileNumber"
        value={formData.mobileNumber}
        onChange={handleInputChange}
      />
    </Form.Item>
    <Form.Item label="City" required>
      <Input
        name="city"
        value={formData.city}
        onChange={handleInputChange}
      />
    </Form.Item>
    <Form.Item label="Course Interested In" required>
      <Input
        name="courseInterestedIn"
        value={formData.courseInterestedIn}
        onChange={handleInputChange}
      />
    </Form.Item>
    <Button type="primary" htmlType="submit">
      Submit
    </Button>
  </Form>
  {formError && <p style={{ color: "red" }}>{formError}</p>}
</Modal>

      <div className="filter-container">
        <div className="filter-item">
          <label>Allotted Quota:</label>
          <select value={allottedQuota} onChange={(e) => setAllottedQuota(e.target.value)}>
            <option value="">Select Allotted Quota</option>
            {uniqueAllottedQuotas.map((quota, index) => (
              <option key={index} value={quota}>
                {quota}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Candidate Category:</label>
          <select
            value={candidateCategory}
            onChange={(e) => setCandidateCategory(e.target.value)}
          >
            <option value="">Select Candidate Category</option>
            {uniqueCandidateCategories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Course:</label>
          <select value={course} onChange={(e) => setCourse(e.target.value)}>
            <option value="">Select Course</option>
            {uniqueCourses.map((course, index) => (
              <option key={index} value={course}>
                {course}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Rank:</label>
          <input
            type="number"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            placeholder="Enter Rank"
          />
        </div>

        <button className="filter-btn" onClick={handleFilter}>
          Predict Institutes
        </button>
      </div>

      {showResults && (
  <>
    {filteredData.length === 0 ? (
      <p className="no-results-message">
        You are not able to get the college on this rank or course not
        available on this quota.
      </p>
    ) : (
      <>
        <h2 className="results-title">Predict Institutes</h2>
        <table className="results-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Allotted Institute</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{item["Allotted Institute"]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            className="pagination-btn"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="pagination-info">
            Page {currentPage} of {Math.ceil(filteredData.length / rowsPerPage)}
          </span>
          <button
            className="pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage * rowsPerPage >= filteredData.length}
          >
            Next
          </button>
        </div>
      </>
    )}
  </>
)}

    </div>
  );
}

export default App;
