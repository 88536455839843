import React, { useState, useEffect } from "react"; // Added useEffect import
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  Jeemainlogo,
  jeeadvancedlogo,
  gatelogo,
  wbjeelogo,
  bitsatlogo,
  viteee,
} from "../../images/index";

// BTech Exams array
const btechExams = [
  { id: 1, name: "JAC DELHI", icon: "https://th.bing.com/th/id/OIP.sZIWSje23SbEK2jitygcqAAAAA?w=151&h=158&c=7&r=0&o=5&dpr=1.3&pid=1.7" },
  { id: 2, name: "JOSAA", icon: jeeadvancedlogo },
  { id: 3, name: "GATE", icon: gatelogo },
  { id: 4, name: "WBJEE", icon: wbjeelogo },
  { id: 5, name: "BITSAT", icon: bitsatlogo },
  { id: 6, name: "VITEEE", icon: viteee },
  // Add more exams as needed
];

const BtechPredictor = () => {
  const [selectedExams, setSelectedExams] = useState([]);
  const navigate = useNavigate();

  const handleExamSelect = (exam) => {
    // Navigate based on selected exam
    if (exam.name === "JAC DELHI") {
      navigate("/freejacdelhipredictor");
    } else if (exam.name === "JOSAA") {
      navigate("/freejosaapredictor");
    } else if (exam.name === "GATE") {
      navigate("#"); // Replace with the actual route
    } else if (exam.name === "WBJEE") {
      navigate("#"); // Replace with the actual route
    } else if (exam.name === "BITSAT") {
      navigate("#"); // Replace with the actual route
    } else if (exam.name === "VITEEE") {
      navigate("#"); // Replace with the actual route
    } else {
      console.log("No valid exam selected for navigation");
    }
  };

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        BTech College Predictor 
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on BTech exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
        Select exam you have taken:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {btechExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-black">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">
              {exam.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BtechPredictor;
