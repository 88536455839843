[
  {
    "Name": "Kalol Institute of Management",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 161,
    "Category": "General"
  },
  {
    "Name": "Patel Group of Institutions",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 164,
    "Category": "General"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 218,
    "Category": "General"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 222,
    "Category": "General"
  },
  {
    "Name": "Christ Institute of Management (CIM, Rajkot)",
    "Course": "Integrated MBA",
    "Score": 230,
    "Category": "General"
  },
  {
    "Name": "Kalol Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 284,
    "Category": "General"
  },
  {
    "Name": "R. H. Patel Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 301,
    "Category": "General"
  },
  {
    "Name": "R. H. Patel Institute of Management",
    "Course": "Integrated Master of Business Administration",
    "Score": 216,
    "Category": "General"
  },
  {
    "Name": "Noble University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 225,
    "Category": "General"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 243,
    "Category": "General"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 280,
    "Category": "General"
  },
  {
    "Name": "N.R.Vekaria Institute of Business Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 285,
    "Category": "General"
  },
  {
    "Name": "Christ Institute of Management (CIM, Rajkot)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 287,
    "Category": "General"
  },
  {
    "Name": "R.B Institute of Management Studies (RBIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 299,
    "Category": "General"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 221,
    "Category": "OBC"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 122,
    "Category": "OBC"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 264,
    "Category": "OBC"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 294,
    "Category": "OBC"
  },
  {
    "Name": "Kalol Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 289,
    "Category": "OBC"
  },
  {
    "Name": "Christ Institute of Management (CIM, Rajkot)",
    "Course": "Integrated MBA",
    "Score": 129,
    "Category": "SC"
  },
  {
    "Name": "Noble University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 151,
    "Category": "SC"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 158,
    "Category": "SC"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "B.Voc. in Banking Financial Services and Insurance",
    "Score": 169,
    "Category": "SC"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 180,
    "Category": "SC"
  },
  {
    "Name": "Kalol Institute of Management",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 162,
    "Category": "SC"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 270,
    "Category": "SC"
  },
  {
    "Name": "Kalol Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 295,
    "Category": "SC"
  },
  {
    "Name": "Patel Group of Institutions",
    "Course": "Intergrated Master of Computer Application (IMCA)",
    "Score": 30,
    "Category": "ST"
  },
  {
    "Name": "R. H. Patel Institute of Management",
    "Course": "Integrated Master of Business Administration",
    "Score": 216,
    "Category": "ST"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 218,
    "Category": "ST"
  },
  {
    "Name": "Kalol Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 222,
    "Category": "ST"
  },
  {
    "Name": "Gujarat Technological University",
    "Course": "Bachelor of Business Administration",
    "Score": 62.8,
    "Category": "ST"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 149,
    "Category": "ST"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 222,
    "Category": "ST"
  },
  {
    "Name": "Christ Institute of Management (CIM, Rajkot)",
    "Course": "Integrated MBA",
    "Score": 230,
    "Category": "ST"
  },
  {
    "Name": "Laxmi Institute of Management",
    "Course": "B.Voc. in BFSI",
    "Score": 337,
    "Category": "ST"
  },
  {
    "Name": "R. H. Patel Institute of Management",
    "Course": "Integrated Master of Business Administration",
    "Score": 216,
    "Category": "PWD"
  },
  {
    "Name": "Christ Institute of Management (CIM, Rajkot)",
    "Course": "Integrated MBA",
    "Score": 230,
    "Category": "PWD"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 218,
    "Category": "PWD"
  },
  {
    "Name": "Shri Jaysukhlal Vadhar Institute of Management Studies",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 222,
    "Category": "PWD"
  },
  {
    "Name": "R.N.G. Patel Institute of Technology",
    "Course": "B.Voc. in Production Technology",
    "Score": 325,
    "Category": "PWD"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "B.Voc in Sales & Marketing",
    "Score": 330,
    "Category": "PWD"
  },
  {
    "Name": "Kalyan Polytechnic College",
    "Course": "B. Voc. in Production Technology",
    "Score": 333,
    "Category": "PWD"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "B.Voc. in Banking Financial Services and Insurance",
    "Score": 297,
    "Category": "PWD"
  },
  {
    "Name": "R.N.G. Patel Institute of Technology",
    "Course": "B.Voc. in Production Technology",
    "Score": 233,
    "Category": "EWS"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 242,
    "Category": "EWS"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "B.Voc. in Banking Financial Services and Insurance",
    "Score": 143,
    "Category": "EWS"
  },
  {
    "Name": "Shri Sunshine Education Trust Group of Institute",
    "Course": "Intergrated Master of Business Administration (IMBA)",
    "Score": 196,
    "Category": "EWS"
  },
  {
    "Name": "Noble University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 267,
    "Category": "EWS"
  }
]