import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  List,
  Typography,
  Card,
  Modal,
  Form,
  message,
  Pagination,
} from "antd";
import axios from "axios";
import karnatakapgcetData from "./KARNATAKAPGCET.json";

const { Title } = Typography;
const { Option } = Select;

const PredictInstitute = () => {
  const [category, setCategory] = useState("");
  const [score, setScore] = useState("");
  const [results, setResults] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasClickedPredictkarnataka, sethasClickedPredictkarnataka] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    const hasClicked = localStorage.getItem("hasClickedPredictkarnataka");
    if (hasClicked === "true") {
      sethasClickedPredictkarnataka(true);
    }
  }, []);

  const handleModalSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://caderaedu.com/api/form/submit",
        values
      );

      if (response.status === 201) {
        message.success("Successfully submitted!");
        localStorage.setItem("hasClickedPredictkarnataka", "true");
        sethasClickedPredictkarnataka(true);
        setIsModalVisible(false);
      }
    } catch (error) {
      message.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePredict = () => {
    if (!hasClickedPredictkarnataka) {
      setIsModalVisible(true);
    } else if (category && score) {
      const filtered = karnatakapgcetData.filter(
        (institute) =>
          institute.Category.toLowerCase() === category.toLowerCase() &&
          parseFloat(score) <= institute.Score
      );
      setResults(filtered);
      setCurrentPage(1); // Reset to first page when new results are loaded
    } else {
      message.warning("Please select a category and enter a valid score.");
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // Paginate results
  const paginatedResults = results.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <Card
        style={{
          maxWidth: 600,
          margin: "50px auto",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          Karnataka PGCET Predictor
        </Title>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Category:</label>
          <Select
            style={{ width: "100%", marginTop: 5 }}
            value={category}
            onChange={(value) => setCategory(value)}
            placeholder="Select Category"
          >
            {[
              ...new Set(
                karnatakapgcetData.map((institute) => institute.Category)
              ),
            ].map((categoryName, index) => (
              <Option key={index} value={categoryName}>
                {categoryName}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Rank:</label>
          <Input
            type="number"
            value={score}
            onChange={(e) => setScore(e.target.value)}
            placeholder="Enter your score"
            style={{ marginTop: 5 }}
          />
        </div>

        <Button
          type="primary"
          block
          onClick={handlePredict}
          style={{ marginTop: 10, borderRadius: 5 }}
        >
          Predict
        </Button>

        {results.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            <Title level={4} style={{ textAlign: "center" }}>
              Matching Institutes:
            </Title>
            <List
              bordered
              dataSource={paginatedResults}
              renderItem={(institute) => (
                <List.Item>
                  <strong>{institute.Name}</strong> - {institute.Course} (
                  {institute.Category})
                </List.Item>
              )}
              style={{ marginTop: 10 }}
            />
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={results.length}
              onChange={handlePageChange}
              style={{ marginTop: 20, textAlign: "center" }}
            />
          </div>
        ) : (
          <div style={{ marginTop: 30, textAlign: "center" }}>
            No matching institutes found.
          </div>
        )}
      </Card>

      <Modal
        title="Enter Details to use the predictor"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        closable={false}
      >
        <Form layout="vertical" onFinish={handleModalSubmit}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input type="email" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[
              { required: true, message: "Please enter your mobile number" },
            ]}
          >
            <Input type="tel" placeholder="Enter your mobile number" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="Enter your city" />
          </Form.Item>
          <Form.Item
            label="Course"
            name="courseInterestedIn"
            rules={[{ required: true, message: "Please enter your course" }]}
          >
            <Input placeholder="Enter your course" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PredictInstitute;
