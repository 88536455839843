import React, { useState } from "react";
import "../NEETPGPredictor/neetpg.css"; // Include the custom CSS file for styles
import data from "./JOSAA_SEAT_ALLOTMENT_ROUND_5.json"; // Your data
// import CompulsoryTimesignup from "../CompulsoryTimesignup";

function JOSAAPredictor() {
  const [quota, setQuota] = useState("");
  const [seatType, setSeatType] = useState("");
  const [course, setCourse] = useState("");
  const [rank, setRank] = useState("");
  const [gender, setGender] = useState(""); // New state for Gender
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResults, setShowResults] = useState(false);
  const rowsPerPage = 10;
//   const [isFormSubmitted, setIsFormSubmitted] = useState(false);
//   const [showSignupModal, setShowSignupModal] = useState(false);

  // Get unique values for dropdowns
  const uniqueQuotas = [...new Set(data.map((item) => item["Quota"]))];
  const uniqueSeatTypes = [...new Set(data.map((item) => item["Seat Type"]))];
  const uniqueCourses = [
    ...new Set(data.map((item) => item["Academic Program Name"])),
  ];
  const uniqueGenders = [...new Set(data.map((item) => item["Gender"]))]; // Extract unique genders

  const handleFilter = () => {
    if (!quota || !seatType || !course || !rank || !gender) {
      alert("Please fill all the fields to predict institutes.");
      return;
    }

    const filtered = data
      .filter(
        (item) =>
          (quota ? item["Quota"] === quota : true) &&
          (seatType ? item["Seat Type"] === seatType : true) &&
          (course ? item["Academic Program Name"] === course : true) &&
          (rank ? Number(rank) <= item["Closing Rank"] : true) &&
          (gender ? item["Gender"] === gender : true) // Filter by Gender
      )
      .sort((a, b) => a["Opening Rank"] - b["Opening Rank"]);

    setFilteredData(filtered);
    setCurrentPage(1);
    setShowResults(true);
  };

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  return (
    <div className="app-container">
      <h1 className="title">JOSAA Predictor</h1>
      {/* <CompulsoryTimesignup
        onFormSubmit={() => {
          setIsFormSubmitted(true);
          setShowSignupModal(false);
        }}
        showSignupModal={showSignupModal}
        onCloseModal={() => setShowSignupModal(false)}
      /> */}

      <div className="filter-container">
        <div className="filter-item">
          <label>Allotted Quota:</label>
          <select value={quota} onChange={(e) => setQuota(e.target.value)}>
            <option value="">Select Allotted Quota</option>
            {uniqueQuotas.map((q, index) => (
              <option key={index} value={q}>
                {q}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Candidate Category:</label>
          <select
            value={seatType}
            onChange={(e) => setSeatType(e.target.value)}
          >
            <option value="">Select Candidate Category</option>
            {uniqueSeatTypes.map((st, index) => (
              <option key={index} value={st}>
                {st}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Course:</label>
          <select value={course} onChange={(e) => setCourse(e.target.value)}>
            <option value="">Select Course</option>
            {uniqueCourses.map((c, index) => (
              <option key={index} value={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item">
          <label>Gender:</label>
          <select value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="">Select Gender</option>
            {uniqueGenders.map((g, index) => (
              <option key={index} value={g}>
                {g}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item">
          <label>Rank:</label>
          <input
            type="number"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            placeholder="Enter Rank"
          />
        </div>

        <button className="filter-btn" onClick={handleFilter}>
          Predict Institutes
        </button>
      </div>

      {showResults && (
        <>
          <h2 className="results-title">Predicted Institutes</h2>

          {filteredData.length === 0 ? (
            <p className="no-results-message">
              No institute available for the entered criteria.
            </p>
          ) : (
            <>
              <table className="results-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Institute</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{item["Institute"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <button
                  className="pagination-btn"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="pagination-info">
                  Page {currentPage} of{" "}
                  {Math.ceil(filteredData.length / rowsPerPage)}
                </span>
                <button
                  className="pagination-btn"
                  onClick={handleNextPage}
                  disabled={currentPage * rowsPerPage >= filteredData.length}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
export default JOSAAPredictor;
