import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Jeemainlogo,
  jeeadvancedlogo,
  gatelogo,
  wbjeelogo,
  bitsatlogo,
  viteee,
} from "../../images/index";

// BTech Exams array
const btechExams = [
  { id: 1, name: "JAC DELHI", icon: Jeemainlogo, route: "/jacdelhipredictor" },
  { id: 2, name: "JEE", icon: jeeadvancedlogo, route: "/JOSAAPredictor" },
  { id: 3, name: "GATE", icon: gatelogo, route: "/gate-predictor" },
  { id: 4, name: "WBJEE", icon: wbjeelogo, route: "/wbjee-predictor" },
  { id: 5, name: "BITSAT", icon: bitsatlogo, route: "/bitsat-predictor" },
  { id: 6, name: "VITEEE", icon: viteee, route: "/viteee-predictor" },
];

const BtechPredictormobileview = () => {
  const navigate = useNavigate();

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExamClick = (route) => {
    navigate(route);
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        BTech College Predictor 
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on BTech exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
        Select an exam to proceed:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {btechExams.map((exam) => (
          <div
            key={exam.id}
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleExamClick(exam.route)}
          >
            <div className="bg-white p-4 rounded-lg shadow-md text-center transform hover:scale-105 transition-transform duration-200 ease-in-out relative">
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            <p className="text-sm font-semibold text-gray-800 mt-1">
              {exam.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BtechPredictormobileview;
