import React from "react";

const KyrgyzstanPage = () => {
  return (
    <div className="relative">
      {/* Header Section */}
      <div className="bg-cover bg-center h-[300px] text-yellow-400 flex flex-col justify-center items-center text-center"
     style={{ backgroundImage: "url('https://images.unsplash.com/20/cambridge.JPG?q=80&w=2047&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}>
  <h1 className="text-4xl font-bold bg-[#68a4a5] text-white px-2 py-1 rounded-md">MBBS IN Kazakhstan</h1>
  <p className="text-lg mt-2 bg-[#68a4a5] text-white px-2 py-1 rounded-md">CaderaEdu - STUDY ABROAD</p>
</div>


      {/* Main Content Section */}
      {/* <div className="px-4 py-8 text-center">
        <h2 className="text-3xl font-semibold mb-4">MBBS in Kyrgyzstan</h2>
        <div className="flex items-center justify-center space-x-4">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Flag_of_Kyrgyzstan.svg/2560px-Flag_of_Kyrgyzstan.svg.png"
            alt="Kyrgyzstan Flag"
            className="w-24 h-16"
          />
          <p className="text-gray-700 text-lg">
            Explore the opportunities for MBBS education in Kyrgyzstan. It's affordable and provides a great learning experience with recognized degrees.
          </p>
        </div>
      </div> */}

      {/* Floating Buttons */}
      {/* <a
        href="#"
        className="fixed right-4 top-1/2 transform -translate-y-1/2 bg-red-600 text-white px-4 py-2 text-center font-medium rounded-md writing-mode-vertical-rl hover:bg-red-700"
      >
        Book Free Consultation Now
      </a>
      <a
        href="https://wa.me/"
        className="fixed right-4 bottom-4 bg-green-500 w-12 h-12 flex items-center justify-center rounded-full shadow-lg hover:bg-green-600"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          className="w-6 h-6"
        />
      </a> */}
    </div>
  );
};

export default KyrgyzstanPage;
