[
  {
    "Name": "Indus College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 112,
    "Category": "General"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "MBA in Business Administration",
    "Score": 808,
    "Category": "General"
  },
  {
    "Name": "HM School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1502,
    "Category": "General"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2305,
    "Category": "General"
  },
  {
    "Name": "DRIEMS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2706,
    "Category": "General"
  },
  {
    "Name": "Cambridge School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2903,
    "Category": "General"
  },
  {
    "Name": "United School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3368,
    "Category": "General"
  },
  {
    "Name": "IIPM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3597,
    "Category": "General"
  },
  {
    "Name": "Vignan Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4077,
    "Category": "General"
  },
  {
    "Name": "Rourkela Institute of Management Studies",
    "Course": "Master in Business Administration (MBA)",
    "Score": 4590,
    "Category": "General"
  },
  {
    "Name": "Gandhi Institute For Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 375,
    "Category": "General"
  },
  {
    "Name": "Maharaja Business School",
    "Course": "MBA in Finance Management",
    "Score": 1401,
    "Category": "General"
  },
  {
    "Name": "Bhubaneswar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1611,
    "Category": "General"
  },
  {
    "Name": "TITE - Templecity Institute of Technology and Engineering",
    "Course": "MBA in General Management",
    "Score": 2457,
    "Category": "General"
  },
  {
    "Name": "Academy of Business Administration (ABA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2803,
    "Category": "General"
  },
  {
    "Name": "Zenith School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 3279,
    "Category": "General"
  },
  {
    "Name": "Koustuv Business School - KBS BBSR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3541,
    "Category": "General"
  },
  {
    "Name": "Astha School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4019,
    "Category": "General"
  },
  {
    "Name": "Dr Ambedkar Memorial Institute of Information Technology and Management Science (DAMITS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4301,
    "Category": "General"
  },
  {
    "Name": "Gandhi Institute of Technology and Management Bhubaneswar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4931,
    "Category": "General"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5033,
    "Category": "General"
  },
  {
    "Name": "Bhubaneswar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5584,
    "Category": "General"
  },
  {
    "Name": "TAT - Trident Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7536,
    "Category": "General"
  },
  {
    "Name": "GITA - Gandhi Institute for Technological Advancement",
    "Course": "Master of Business Management",
    "Score": 5467,
    "Category": "General"
  },
  {
    "Name": "Gandhi Institute for Education and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7194,
    "Category": "General"
  },
  {
    "Name": "Indus College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 112,
    "Category": "OBC"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "MBA in Business Administration",
    "Score": 808,
    "Category": "OBC"
  },
  {
    "Name": "HM School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1502,
    "Category": "OBC"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2305,
    "Category": "OBC"
  },
  {
    "Name": "DRIEMS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2706,
    "Category": "OBC"
  },
  {
    "Name": "Cambridge School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2903,
    "Category": "OBC"
  },
  {
    "Name": "United School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3368,
    "Category": "OBC"
  },
  {
    "Name": "IIPM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3597,
    "Category": "OBC"
  },
  {
    "Name": "Vignan Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4077,
    "Category": "OBC"
  },
  {
    "Name": "Rourkela Institute of Management Studies",
    "Course": "Master in Business Administration (MBA)",
    "Score": 4590,
    "Category": "OBC"
  },
  {
    "Name": "Gandhi Institute For Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 375,
    "Category": "OBC"
  },
  {
    "Name": "Maharaja Business School",
    "Course": "MBA in Finance Management",
    "Score": 1401,
    "Category": "OBC"
  },
  {
    "Name": "Bhubaneswar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1611,
    "Category": "OBC"
  },
  {
    "Name": "TITE - Templecity Institute of Technology and Engineering",
    "Course": "MBA in General Management",
    "Score": 2457,
    "Category": "OBC"
  },
  {
    "Name": "Academy of Business Administration (ABA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2803,
    "Category": "OBC"
  },
  {
    "Name": "Zenith School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 3279,
    "Category": "OBC"
  },
  {
    "Name": "Koustuv Business School - KBS BBSR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3541,
    "Category": "OBC"
  },
  {
    "Name": "Astha School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4019,
    "Category": "OBC"
  },
  {
    "Name": "Dr Ambedkar Memorial Institute of Information Technology and Management Science (DAMITS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4301,
    "Category": "OBC"
  },
  {
    "Name": "Gandhi Institute of Technology and Management Bhubaneswar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4931,
    "Category": "OBC"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5033,
    "Category": "OBC"
  },
  {
    "Name": "Bhubaneswar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5584,
    "Category": "OBC"
  },
  {
    "Name": "TAT - Trident Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7536,
    "Category": "OBC"
  },
  {
    "Name": "GITA - Gandhi Institute for Technological Advancement",
    "Course": "Master of Business Management",
    "Score": 5467,
    "Category": "OBC"
  },
  {
    "Name": "Gandhi Institute for Education and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7194,
    "Category": "OBC"
  },
  {
    "Name": "Indus College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 112,
    "Category": "SC"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "MBA in Business Administration",
    "Score": 808,
    "Category": "SC"
  },
  {
    "Name": "HM School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1502,
    "Category": "SC"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2305,
    "Category": "SC"
  },
  {
    "Name": "DRIEMS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2706,
    "Category": "SC"
  },
  {
    "Name": "Cambridge School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2903,
    "Category": "SC"
  },
  {
    "Name": "United School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3368,
    "Category": "SC"
  },
  {
    "Name": "IIPM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3597,
    "Category": "SC"
  },
  {
    "Name": "Vignan Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4077,
    "Category": "SC"
  },
  {
    "Name": "Rourkela Institute of Management Studies",
    "Course": "Master in Business Administration (MBA)",
    "Score": 4590,
    "Category": "SC"
  },
  {
    "Name": "Gandhi Institute For Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 375,
    "Category": "SC"
  },
  {
    "Name": "Maharaja Business School",
    "Course": "MBA in Finance Management",
    "Score": 1401,
    "Category": "SC"
  },
  {
    "Name": "Bhubaneswar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1611,
    "Category": "SC"
  },
  {
    "Name": "TITE - Templecity Institute of Technology and Engineering",
    "Course": "MBA in General Management",
    "Score": 2457,
    "Category": "SC"
  },
  {
    "Name": "Academy of Business Administration (ABA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2803,
    "Category": "SC"
  },
  {
    "Name": "Zenith School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 3279,
    "Category": "SC"
  },
  {
    "Name": "Koustuv Business School - KBS BBSR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3541,
    "Category": "SC"
  },
  {
    "Name": "Astha School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4019,
    "Category": "SC"
  },
  {
    "Name": "Dr Ambedkar Memorial Institute of Information Technology and Management Science (DAMITS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4301,
    "Category": "SC"
  },
  {
    "Name": "Gandhi Institute of Technology and Management Bhubaneswar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4931,
    "Category": "SC"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5033,
    "Category": "SC"
  },
  {
    "Name": "Bhubaneswar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5584,
    "Category": "SC"
  },
  {
    "Name": "TAT - Trident Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7536,
    "Category": "SC"
  },
  {
    "Name": "GITA - Gandhi Institute for Technological Advancement",
    "Course": "Master of Business Management",
    "Score": 5467,
    "Category": "SC"
  },
  {
    "Name": "Gandhi Institute for Education and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7194,
    "Category": "SC"
  },
  {
    "Name": "Indus College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 112,
    "Category": "ST"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management Studies",
    "Course": "MBA in Business Administration",
    "Score": 808,
    "Category": "ST"
  },
  {
    "Name": "HM School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1502,
    "Category": "ST"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2305,
    "Category": "ST"
  },
  {
    "Name": "DRIEMS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2706,
    "Category": "ST"
  },
  {
    "Name": "Cambridge School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2903,
    "Category": "ST"
  },
  {
    "Name": "United School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3368,
    "Category": "ST"
  },
  {
    "Name": "IIPM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3597,
    "Category": "ST"
  },
  {
    "Name": "Vignan Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4077,
    "Category": "ST"
  },
  {
    "Name": "Rourkela Institute of Management Studies",
    "Course": "Master in Business Administration (MBA)",
    "Score": 4590,
    "Category": "ST"
  },
  {
    "Name": "Gandhi Institute For Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 375,
    "Category": "ST"
  },
  {
    "Name": "Maharaja Business School",
    "Course": "MBA in Finance Management",
    "Score": 1401,
    "Category": "ST"
  },
  {
    "Name": "Bhubaneswar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1611,
    "Category": "ST"
  },
  {
    "Name": "TITE - Templecity Institute of Technology and Engineering",
    "Course": "MBA in General Management",
    "Score": 2457,
    "Category": "ST"
  },
  {
    "Name": "Academy of Business Administration (ABA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2803,
    "Category": "ST"
  },
  {
    "Name": "Zenith School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 3279,
    "Category": "ST"
  },
  {
    "Name": "Koustuv Business School - KBS BBSR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3541,
    "Category": "ST"
  },
  {
    "Name": "Astha School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4019,
    "Category": "ST"
  },
  {
    "Name": "Dr Ambedkar Memorial Institute of Information Technology and Management Science (DAMITS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4301,
    "Category": "ST"
  },
  {
    "Name": "Gandhi Institute of Technology and Management Bhubaneswar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4931,
    "Category": "ST"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5033,
    "Category": "ST"
  },
  {
    "Name": "Bhubaneswar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5584,
    "Category": "ST"
  },
  {
    "Name": "TAT - Trident Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7536,
    "Category": "ST"
  },
  {
    "Name": "GITA - Gandhi Institute for Technological Advancement",
    "Course": "Master of Business Management",
    "Score": 5467,
    "Category": "ST"
  },
  {
    "Name": "Gandhi Institute for Education and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7194,
    "Category": "ST"
  },
  {
    "Name": "Indus College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 112,
    "Category": "PWD"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management Studies",
    "Course": "MBA in Business Administration",
    "Score": 808,
    "Category": "PWD"
  },
  {
    "Name": "HM School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1502,
    "Category": "PWD"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2305,
    "Category": "PWD"
  },
  {
    "Name": "DRIEMS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2706,
    "Category": "PWD"
  },
  {
    "Name": "Cambridge School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2903,
    "Category": "PWD"
  },
  {
    "Name": "United School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3368,
    "Category": "PWD"
  },
  {
    "Name": "IIPM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3597,
    "Category": "PWD"
  },
  {
    "Name": "Vignan Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4077,
    "Category": "PWD"
  },
  {
    "Name": "Rourkela Institute of Management Studies",
    "Course": "Master in Business Administration (MBA)",
    "Score": 4590,
    "Category": "PWD"
  },
  {
    "Name": "Gandhi Institute For Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 375,
    "Category": "PWD"
  },
  {
    "Name": "Maharaja Business School",
    "Course": "MBA in Finance Management",
    "Score": 1401,
    "Category": "PWD"
  },
  {
    "Name": "Bhubaneswar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1611,
    "Category": "PWD"
  },
  {
    "Name": "TITE - Templecity Institute of Technology and Engineering",
    "Course": "MBA in General Management",
    "Score": 2457,
    "Category": "PWD"
  },
  {
    "Name": "Academy of Business Administration (ABA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2803,
    "Category": "PWD"
  },
  {
    "Name": "Zenith School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 3279,
    "Category": "PWD"
  },
  {
    "Name": "Koustuv Business School - KBS BBSR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3541,
    "Category": "PWD"
  },
  {
    "Name": "Astha School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4019,
    "Category": "PWD"
  },
  {
    "Name": "Dr Ambedkar Memorial Institute of Information Technology and Management Science (DAMITS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4301,
    "Category": "PWD"
  },
  {
    "Name": "Gandhi Institute of Technology and Management Bhubaneswar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4931,
    "Category": "PWD"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5033,
    "Category": "PWD"
  },
  {
    "Name": "Bhubaneswar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5584,
    "Category": "PWD"
  },
  {
    "Name": "TAT - Trident Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7536,
    "Category": "PWD"
  },
  {
    "Name": "GITA - Gandhi Institute for Technological Advancement",
    "Course": "Master of Business Management",
    "Score": 5467,
    "Category": "PWD"
  },
  {
    "Name": "Gandhi Institute for Education and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7194,
    "Category": "PWD"
  },
  {
    "Name": "Indus College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 112,
    "Category": "EWS"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management Studies",
    "Course": "MBA in Business Administration",
    "Score": 808,
    "Category": "EWS"
  },
  {
    "Name": "HM School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1502,
    "Category": "EWS"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2305,
    "Category": "EWS"
  },
  {
    "Name": "DRIEMS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2706,
    "Category": "EWS"
  },
  {
    "Name": "Cambridge School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2903,
    "Category": "EWS"
  },
  {
    "Name": "United School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3368,
    "Category": "EWS"
  },
  {
    "Name": "IIPM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3597,
    "Category": "EWS"
  },
  {
    "Name": "Vignan Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4077,
    "Category": "EWS"
  },
  {
    "Name": "Rourkela Institute of Management Studies",
    "Course": "Master in Business Administration (MBA)",
    "Score": 4590,
    "Category": "EWS"
  },
  {
    "Name": "Gandhi Institute For Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 375,
    "Category": "EWS"
  },
  {
    "Name": "Maharaja Business School",
    "Course": "MBA in Finance Management",
    "Score": 1401,
    "Category": "EWS"
  },
  {
    "Name": "Bhubaneswar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1611,
    "Category": "EWS"
  },
  {
    "Name": "TITE - Templecity Institute of Technology and Engineering",
    "Course": "MBA in General Management",
    "Score": 2457,
    "Category": "EWS"
  },
  {
    "Name": "Academy of Business Administration (ABA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2803,
    "Category": "EWS"
  },
  {
    "Name": "Zenith School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 3279,
    "Category": "EWS"
  },
  {
    "Name": "Koustuv Business School - KBS BBSR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3541,
    "Category": "EWS"
  },
  {
    "Name": "Astha School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4019,
    "Category": "EWS"
  },
  {
    "Name": "Dr Ambedkar Memorial Institute of Information Technology and Management Science (DAMITS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4301,
    "Category": "EWS"
  },
  {
    "Name": "Gandhi Institute of Technology and Management Bhubaneswar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4931,
    "Category": "EWS"
  },
  {
    "Name": "Biju Patnaik Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5033,
    "Category": "EWS"
  },
  {
    "Name": "Bhubaneswar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5584,
    "Category": "EWS"
  },
  {
    "Name": "TAT - Trident Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7536,
    "Category": "EWS"
  },
  {
    "Name": "GITA - Gandhi Institute for Technological Advancement",
    "Course": "Master of Business Management",
    "Score": 5467,
    "Category": "EWS"
  },
  {
    "Name": "Gandhi Institute for Education and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7194,
    "Category": "EWS"
  }
]
