[
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Management Science",
    "Score": 89.79,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in E-Commerce",
    "Score": 88.38,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Tourism",
    "Score": 84.07,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Human Resource",
    "Score": 79.67,
    "Category": "General"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in E-Commerce",
    "Score": 78.07,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Hospital Administration",
    "Score": 77.35,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Analytics",
    "Score": 76.28,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Economics",
    "Score": 75.04,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Foreign Trade",
    "Score": 74.26,
    "Category": "General"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in Hospital Administration",
    "Score": 73.97,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Administration",
    "Score": 89.71,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Foreign Trade",
    "Score": 84.56,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Marketing Management",
    "Score": 83.17,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Services",
    "Score": 78.72,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Entrepreneurship",
    "Score": 77.38,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 76.63,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Media Management",
    "Score": 75.39,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in International Business",
    "Score": 74.55,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Tourism Administration",
    "Score": 74.17,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Computer Management",
    "Score": 73.59,
    "Category": "General"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "(Discontinued May 2023) MBA in Disaster management",
    "Score": 72,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Rural Development",
    "Score": 70.19,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Public Administration and Policy",
    "Score": 70.72,
    "Category": "General"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in E-Commerce",
    "Score": 88,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Foreign Trade",
    "Score": 83,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Administration",
    "Score": 80.59,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Human Resource",
    "Score": 79,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Hospital Administration",
    "Score": 77.54,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 75.87,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Analytics",
    "Score": 73.91,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Tourism Administration",
    "Score": 72.01,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Foreign Trade",
    "Score": 71.82,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in International Business",
    "Score": 71.2,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Management Science",
    "Score": 85.78,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Tourism",
    "Score": 82.28,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Marketing Management",
    "Score": 79.8,
    "Category": "OBC"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in E-Commerce",
    "Score": 78.16,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Services",
    "Score": 77.19,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Entrepreneurship",
    "Score": 75.19,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Computer Management",
    "Score": 73.63,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Economics",
    "Score": 71.98,
    "Category": "OBC"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in Hospital Administration",
    "Score": 71.43,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Media Management",
    "Score": 71.07,
    "Category": "OBC"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "(Discontinued May 2023) MBA in Disaster management",
    "Score": 60.44,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Public Administration and Policy",
    "Score": 65.7,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Rural Development",
    "Score": 68.86,
    "Category": "OBC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in E-Commerce",
    "Score": 76.17,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Management Science",
    "Score": 70.15,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Marketing Management",
    "Score": 66.69,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Economics",
    "Score": 61.5,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Public Administration and Policy",
    "Score": 59.9,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Analytics",
    "Score": 58.53,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Rural Development",
    "Score": 58.04,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Media Management",
    "Score": 55.75,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Tourism Administration",
    "Score": 52.71,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Computer Management",
    "Score": 51.44,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Administration",
    "Score": 72.32,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Human Resource",
    "Score": 67.79,
    "Category": "SC"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in E-Commerce",
    "Score": 63.42,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Hospital Administration",
    "Score": 59.6,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Services",
    "Score": 58.14,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Foreign Trade",
    "Score": 56.78,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in International Business",
    "Score": 52.98,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Entrepreneurship",
    "Score": 52.27,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Tourism",
    "Score": 51.27,
    "Category": "SC"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in Hospital Administration",
    "Score": 51.26,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Foreign Trade",
    "Score": 50.63,
    "Category": "SC"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "(Discontinued May 2023) MBA in Disaster management",
    "Score": 50.71,
    "Category": "SC"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in E-Commerce",
    "Score": 86.66,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Foreign Trade",
    "Score": 82.16,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Administration",
    "Score": 80.59,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Human Resource",
    "Score": 78.84,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Hospital Administration",
    "Score": 77.54,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 76.13,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Computer Management",
    "Score": 72.94,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in International Business",
    "Score": 71.98,
    "Category": "ST"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in Hospital Administration",
    "Score": 71.17,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Media Management",
    "Score": 70.05,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Management Science",
    "Score": 85.87,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Tourism",
    "Score": 82.11,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Marketing Management",
    "Score": 80.53,
    "Category": "ST"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in E-Commerce",
    "Score": 77.56,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Services",
    "Score": 77.31,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Entrepreneurship",
    "Score": 74.6,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Analytics",
    "Score": 72.2,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Foreign Trade",
    "Score": 71.7,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Economics",
    "Score": 71.07,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Tourism Administration",
    "Score": 69.89,
    "Category": "ST"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "(Discontinued May 2023) MBA in Disaster management",
    "Score": 69.64,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Rural Development",
    "Score": 66.45,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Public Administration and Policy",
    "Score": 67,
    "Category": "ST"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in E-Commerce",
    "Score": 85.79,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Administration",
    "Score": 68,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Tourism",
    "Score": 66.76,
    "Category": "PWD"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "(Discontinued May 2023) MBA in Disaster management",
    "Score": 65.65,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Economics",
    "Score": 62.94,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in International Business",
    "Score": 61.93,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 60.06,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Entrepreneurship",
    "Score": 58.22,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Analytics",
    "Score": 57.5,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Foreign Trade",
    "Score": 81,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Management Science",
    "Score": 67.02,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Human Resource",
    "Score": 65.95,
    "Category": "PWD"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in E-Commerce",
    "Score": 62.94,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Marketing Management",
    "Score": 62.25,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Services",
    "Score": 61.07,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Tourism Administration",
    "Score": 59.2,
    "Category": "PWD"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in Hospital Administration",
    "Score": 57.88,
    "Category": "PWD"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in E-Commerce",
    "Score": 87.35,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Marketing Management",
    "Score": 82.08,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Tourism",
    "Score": 80.44,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Human Resource",
    "Score": 77.75,
    "Category": "EWS"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in E-Commerce",
    "Score": 76.82,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Analytics",
    "Score": 74.42,
    "Category": "EWS"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "(Discontinued May 2023) MBA in Disaster management",
    "Score": 74.25,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 73.85,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Business Economics",
    "Score": 71.6,
    "Category": "EWS"
  },
  {
    "Name": "Devi Ahilya Vishwavidyalaya, Takshashila Campus",
    "Course": "MBA in Hospital Administration",
    "Score": 70.96,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Management Science",
    "Score": 86.2,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Foreign Trade",
    "Score": 81.19,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Administration",
    "Score": 80.35,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "Integrated MBA in Hospital Administration",
    "Score": 77.62,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Financial Services",
    "Score": 75.24,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Entrepreneurship",
    "Score": 74.37,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Computer Management",
    "Score": 74.25,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Tourism Administration",
    "Score": 72.07,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Foreign Trade",
    "Score": 71.18,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in International Business",
    "Score": 70.8,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Media Management",
    "Score": 69.88,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Rural Development",
    "Score": 65.1,
    "Category": "EWS"
  },
  {
    "Name": "DAVV - Devi Ahilya Vishwavidyalaya",
    "Course": "MBA in Public Administration and Policy",
    "Score": 66.4,
    "Category": "EWS"
  }
]