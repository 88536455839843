import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};

const CollegeRanking = () => {
  const [collegesData, setCollegesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the backend API
    const fetchColleges = async () => {
      try {
        const response = await fetch('https://caderaedu.com/api/college/all');
        const data = await response.json();
        setCollegesData(data.colleges);
        setLoading(false);
      } catch (err) {
        setError('Failed to load college data.');
        setLoading(false);
      }
    };

    fetchColleges();
  }, []);

  const generatePagination = (data) => {
    const indexOfLastCollege = currentPage * itemsPerPage;
    const indexOfFirstCollege = indexOfLastCollege - itemsPerPage;
    return data.slice(indexOfFirstCollege, indexOfLastCollege);
  };

  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/college/${slug}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const paginatedColleges = generatePagination(collegesData);

  return (
    <div className="container mx-auto my-8 px-4">
      {/* Heading */}
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8">Top Colleges</h2>

      {/* Colleges List */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                Rank
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                College
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                Courses Offered
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                Fees
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                AIR
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedColleges.map((college, index) => (
              <tr key={index} className={`border-b ${index % 2 !== 0 ? 'bg-blue-50' : ''}`}>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">
                  {((currentPage - 1) * itemsPerPage) + (index + 1)}
                </td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">
                  <div>
                    <button className="font-semibold text-left text-blue-400" onClick={() => handleCollegeClick(college.name)}>{college.name}</button>
                    <div className="text-gray-500 text-xs sm:text-sm">{college.location || 'N/A'}</div>
                  </div>
                </td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.courses[0].name || 'N/A'}</td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.courses[0].fee || 'N/A'}</td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.college_rank || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 text-sm bg-gray-300 rounded-l-md disabled:opacity-50"
        >
          Previous
        </button>
        <span className="px-4 py-2 text-sm">
          Page {currentPage} of {Math.ceil(collegesData.length / itemsPerPage)}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(collegesData.length / itemsPerPage)}
          className="px-4 py-2 text-sm bg-gray-300 rounded-r-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CollegeRanking;
