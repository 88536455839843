import React from "react";

const Sidebar = (props) => {
  return (
    <aside className="w-full md:w-1/3 p-4">
      <div className="bg-white p-4 shadow-md rounded-md">
      <a href={props.applynowlink} >
      <button className="bg-orange-500 w-full text-white py-2 rounded-md">
        Apply Now  
      </button>    
    </a>
        {/* <button className="bg-teal-500 w-full text-white py-2 mt-4 rounded-md">College Predictor</button> */}
       <a href={props.brochurelink}>
        <button className="bg-blue-500 w-full text-white py-2 mt-4 rounded-md">
          Download Brochure
          </button>
          </a>

        {/* <button className="bg-teal-500 w-full text-white py-2 mt-4 rounded-md">College Predictor</button> */}
       
      </div>
      {/* <div className="mt-4 bg-white p-4 shadow-md rounded-md">
        <h2 className="text-lg font-semibold">Top Courses</h2>
        <p className="mt-2">B.Sc Programming & Data Science</p>
        <p className="mt-2">Certificate Course in Statistics</p>
      </div> */}
      <div className="bg-white rounded-md p-4 shadow-md">
        <h2 className="text-lg font-semibold text-gray-700 mb-2">{props.SidebarAdvertiseNameVideos1}</h2>

        {/* Video 1 */}
        <div className="mb-4">
          <iframe
            width="100%"
            height="200"
            src={props.SidebarAdvertiseVideosSction1}
            title="YouTube video"
            className="rounded-md"
          ></iframe>
        </div>
        <h2 className="text-lg font-semibold text-gray-700 mb-2">{props.SidebarAdvertiseNameVideos2}</h2>
        {/* Video 2 */}
        <div className="mb-4">
          <iframe
            width="100%"
            height="200"
            src={props.SidebarAdvertiseVideosSction2}
            title="YouTube video"
            className="rounded-md"
          ></iframe>
        </div>

        {/* View All Videos Button */}
        {/* <button className="w-full bg-gray-200 text-gray-700 font-semibold py-2 rounded-full shadow-lg">
          View All Videos
        </button> */}
      </div>
    </aside>
  );
};

export default Sidebar;