import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  List,
  Typography,
  Card,
  Modal,
  Form,
  message,
} from "antd";
import axios from "axios";
import davumetData from "./DAVVCET.json";
const { Title } = Typography;
const { Option } = Select;
const PredictInstitute = () => {
  const [category, setCategory] = useState("");
  const [score, setScore] = useState("");
  const [results, setResults] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasClickedPredictDavvcet, sethasClickedPredictDavvcet] =
    useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const hasClicked = localStorage.getItem("hasClickedPredictDavvcet");
    if (hasClicked === "true") {
      sethasClickedPredictDavvcet(true);
    }
  }, []);
  const handleModalSubmit = async (values) => {
    setLoading(true);
    console.log("Form Values:", values);
    try {
      const response = await axios.post(
        "https://caderaedu.com/api/form/submit",
        values
      );
      console.log("API Response:", response);
      if (response.status === 201) {
        message.success("Successfully submitted!");
        localStorage.setItem("hasClickedPredictDavvcet", "true");
        sethasClickedPredictDavvcet(true);
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      message.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handlePredict = () => {
    if (!hasClickedPredictDavvcet) {
      setIsModalVisible(true);
    } else if (category && score) {
      const filtered = davumetData.filter(
        (institute) =>
          institute.Category.toLowerCase() === category.toLowerCase() &&
          parseFloat(score) >= institute.Score
      );
      setResults(filtered);
    } else {
      message.warning("Please select a category and enter a valid score.");
    }
  };
  return (
    <>
      <Card
        style={{
          maxWidth: 600,
          margin: "50px auto",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          DAVVCET Predictor
        </Title>
        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Category:</label>
          <Select
            style={{ width: "100%", marginTop: 5 }}
            value={category}
            onChange={(value) => setCategory(value)}
            placeholder="Select Category"
          >
            {[
              ...new Set(davumetData.map((institute) => institute.Category)),
            ].map((categoryName, index) => (
              <Option key={index} value={categoryName}>
                {categoryName}
              </Option>
            ))}
          </Select>
        </div>
        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Score (out of 100):</label>
          <Input
            type="number"
            value={score}
            onChange={(e) => setScore(e.target.value)}
            placeholder="Enter your score"
            style={{ marginTop: 5 }}
          />
        </div>
        <Button
          type="primary"
          block
          onClick={handlePredict}
          style={{ marginTop: 10, borderRadius: 5 }}
        >
          Predict
        </Button>
        {results.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            <Title level={4} style={{ textAlign: "center" }}>
              Matching Institutes:
            </Title>
            <List
              bordered
              dataSource={results}
              renderItem={(institute) => (
                <List.Item>
                  <strong>{institute.Name}</strong> - {institute.Course} (
                  {institute.Category})
                </List.Item>
              )}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : (
          <div style={{ marginTop: 30, textAlign: "center" }}>
            No matching institutes found.
          </div>
        )}
      </Card>
      <Modal
        title="Enter Details to use the predictor"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        closable={false}
      >
        <Form layout="vertical" onFinish={handleModalSubmit}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input type="email" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[
              { required: true, message: "Please enter your mobile number" },
            ]}
          >
            <Input type="tel" placeholder="Enter your mobile number" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="Enter your city" />
          </Form.Item>
          <Form.Item
            label="Course"
            name="courseInterestedIn"
            rules={[{ required: true, message: "Please enter your course" }]}
          >
            <Input placeholder="Enter your course" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default PredictInstitute;
