import React from "react";

export default function Info() {
  return (
    <div className="p-4 max-w-7xl mx-auto">
      {/* Section: Title */}
      <section className="mb-8">
        <h1 className="text-2xl font-bold mb-4">MBBS in Kazakhstan - Quick Highlights 2024</h1>
        <table className="table-auto w-full text-left border border-gray-300">
          <tbody>
            <tr className="border-b">
              <th className="p-2">Intake for MBBS Course</th>
              <td className="p-2">October</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">Basic Eligibility</th>
              <td className="p-2">60% in PCB for General, 40% for SC/ST</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">NEET Exam</th>
              <td className="p-2">Yes, it is compulsory</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">IELTS & TOEFL</th>
              <td className="p-2">Not required</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">Minimum Course Fee</th>
              <td className="p-2">Rs. 23 Lakhs/Year</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">Maximum Course Fee</th>
              <td className="p-2">Rs. 5 Lakhs/Year</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">Top Medical University</th>
              <td className="p-2">Al-Farabi Kazakh National University</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">Cost of Living in Kazakhstan</th>
              <td className="p-2">Rs. 6 - 10 Thousand/Month</td>
            </tr>
            <tr className="border-b">
              <th className="p-2">Medium of Teaching</th>
              <td className="p-2">English</td>
            </tr>
            <tr>
              <th className="p-2">Universities Recognition</th>
              <td className="p-2">NMC & WHO approved</td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Section: Why Study Medicine in Kazakhstan */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Why Should You Study Medicine in Kazakhstan?</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>Affordable tuition fee structure.</li>
          <li>Globally recognized medical degree.</li>
          <li>No donation or capitation fees required.</li>
          <li>High-quality education at cheaper fees.</li>
          <li>Modern technology and capable infrastructure.</li>
          <li>Tuition fee and accommodation are affordable.</li>
        </ul>
      </section>

      {/* Section: Eligibility Criteria */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Eligibility Criteria</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>Minimum 50% marks in PCB for general category and 40% for SC/ST and OBC as per NMC rule.</li>
          <li>Applicant must be above 17 years of age.</li>
          <li>No entrance examination is required for admission.</li>
          <li>No upper age limit to study in Kazakhstan.</li>
        </ul>
      </section>

      {/* Section: Admission Procedure */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Admission Procedure for Studying MBBS in Kazakhstan</h2>
        <ol className="list-decimal list-inside space-y-2">
          <li>Fill the application form with all the correct points of interest.</li>
          <li>Wait for the admission letter upon approval.</li>
          <li>Submit passport for visa processing at the Delhi Office.</li>
          <li>Attend an interview at the Embassy of Kazakhstan in Delhi.</li>
          <li>Once the visa is approved, the student may book tickets for Kazakhstan.</li>
        </ol>
      </section>

      {/* Section: Advantages */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Advantages of Studying MBBS in Kazakhstan</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>High quality of medical education.</li>
          <li>Globally recognized universities.</li>
          <li>Simple admission process.</li>
          <li>Cost of living and education is affordable.</li>
        </ul>
      </section>

      {/* Section: Disadvantages */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Disadvantages of MBBS from Kazakhstan</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>Colder climate compared to India.</li>
          <li>Low FMGE pass rates compared to other countries.</li>
          <li>Limited exposure to advanced technology in some universities.</li>
        </ul>
      </section>
    </div>
  );
}
