import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd"; // Using Ant Design's Card component for display
import axios from "axios"; // Optional if you prefer Axios for making requests

const ScrapedContent = () => {
  const [content, setContent] = useState([]); // State to store the fetched content
  const [loading, setLoading] = useState(true); // Loading state to show spinner while fetching data
  const [error, setError] = useState(null); // Error state to handle any potential errors

  useEffect(() => {
    // Function to fetch scraped data from the backend
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:3003/api/scrape");
        setContent(response.data.content); // Set the fetched content in the state
        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data"); // Handle error
        setLoading(false); // Set loading to false after error
      }
    };

    fetchData();
  }, []);

  const highlightNewText = (text) => {
    // Define a simple check for new content. You can modify this based on actual data.
    const keyword = "New"; // Example keyword to highlight
    if (text.includes(keyword)) {
      return text.replace(new RegExp(keyword, "gi"), `<span style="color: red; font-weight: bold;">${keyword}</span>`);
    }
    return text;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
        <h3>Loading data...</h3>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h3>{error}</h3>
      </div>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
    <h1 style={{ textAlign: "center", marginBottom: "20px" }}>MCC Latest Updates</h1>
    <Row gutter={[16, 16]} justify="center">
      {content.length > 0 ? (
        content.map((item, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <a href={`https://mcc.nic.in/`} target="_blank" rel="noopener noreferrer">
              <Card
                title={`MCC Latest News ${index + 1}`}
                bordered={false}
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  marginBottom: "20px",
                  transition: "all 0.3s ease",
                }}
                hoverable
              >
                <p dangerouslySetInnerHTML={{ __html: highlightNewText(item) }} />
              </Card>
            </a>
          </Col>
        ))
      ) : (
        <h3>No data available</h3>
      )}
    </Row>
  </div>
  
  );
};

export default ScrapedContent;
