[
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 100,
    "Category": "General"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 100,
    "Category": "ST"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 100,
    "Category": "PWD"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 100,
    "Category": "EWS"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 80,
    "Category": "EWS"
  }
]