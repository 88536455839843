import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, "");
};

const Btech = (props) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const collegesPerPage = 5;
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await fetch(
          `https://caderaedu.com/api/college/filter?category=${encodeURIComponent(
            props.title
          )}`
        );
        const colleges = await response.json();
        setData(colleges);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchColleges();
  }, [props.title]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [data]);

  const indexOfLastCollege = currentPage * collegesPerPage;
  const indexOfFirstCollege = indexOfLastCollege - collegesPerPage;
  const currentColleges = filteredData.slice(
    indexOfFirstCollege,
    indexOfLastCollege
  );

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / collegesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")}...`;
    }
    return text;
  };

  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/college/${slug}`);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={`Find top colleges for ${props.title}`} />
        <meta name="author" content="CaderaEdu" />
        <meta property="og:title" content={`Top Colleges for ${props.title}`} />
        <meta
          property="og:description"
          content={`Explore colleges offering courses in ${props.title}.`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://example.com/path/to/your/preview-image.jpg"
        />
        <meta property="og:url" content={`https://caderaedu.com/btech`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Top Colleges for ${props.title}`} />
        <meta
          name="twitter:description"
          content={`Explore colleges offering courses in ${props.title}.`}
        />
        <meta
          name="twitter:image"
          content="https://example.com/path/to/your/preview-image.jpg"
        />
        <title>Top Colleges for {props.title}</title>
      </Helmet>

      <section className="bg-white shadow-sm py-6">
        <div className="container mx-auto px-4 py-6">
          <h1 className="text-3xl font-bold text-left">{props.title}</h1>
        </div>

        {/* Advertise Section */}
        {/* <a href="#" className="block">
          <div className="flex flex-wrap justify-center items-center gap-4 p-4">
            <img
              src="https://del1.vultrobjects.com/caderaedu/expo%20ad%20%282%29.jpg"
              className="w-1/3 sm:w-1/4 h-auto object-cover rounded-lg"
            />
            <img
              src="https://del1.vultrobjects.com/caderaedu/Blank%20Company%20Profile%20Business%20Presentation%20in%20Red%20Maroon%20White%20Geometric%20Style.png"
              className="w-1/3 sm:w-1/4 h-auto object-cover rounded-lg"
            />
            <img
              src="https://del1.vultrobjects.com/caderaedu/expo%20ad%20%282%29.jpg"
              className="w-1/3 sm:w-1/4 h-auto object-cover rounded-lg"
            />
          </div>
        </a> */}

        {/* Writer Section */}
        <div className="border p-6 rounded-lg shadow-lg mx-4 sm:mx-auto my-4">
          <div className="flex items-center mb-4">
            <img
              src="https://th.bing.com/th/id/OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa?w=176&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
              className="rounded-full w-14 h-14 mr-4"
            />
            <div>
              <h1 className="text-xl font-bold">{props.name}</h1>
              <h2 className="text-sm text-gray-500">{props.designation}</h2>
            </div>
          </div>
          <p className="text-gray-700">
            {isExpanded
              ? props.contentAboutTitle
              : truncateText(props.contentAboutTitle, 100)}
          </p>
          <button
            onClick={handleReadMore}
            className="mt-4 text-blue-500 hover:underline"
          >
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
      </section>

      {/* Colleges List */}
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-xl sm:text-2xl font-semibold mb-4">
          Found {filteredData.length} Colleges
        </h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white table-auto shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="text-left py-2 px-4">#</th>
                <th className="text-left py-2 px-4">College Name</th>
                <th className="text-left py-2 px-4">Location</th>
                <th className="text-left py-2 px-4">Courses Offered</th>
                <th className="text-left py-2 px-4">Fees</th>
                <th className="text-left py-2 px-4">Ranking</th>
              </tr>
            </thead>
            <tbody>
              {currentColleges.map((college, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">
                    {indexOfFirstCollege + index + 1}
                  </td>
                  <td
                    className="text-sm py-2 px-4 text-blue-600 cursor-pointer"
                    onClick={() => handleCollegeClick(college.name)}
                  >
                    {college.name}
                  </td>
                  <td className="py-2 px-4">{college.location || "N/A"}</td>
                  <td className="text-sm py-2 px-4">
                    {college.courses[0]?.name || "N/A"}
                  </td>
                  <td className=" text-sm py-2 px-4">
                    {college.courses[0]?.fee || "N/A"}
                  </td>
                  <td className=" text-sm py-2 px-4">{college.college_rank || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="container mx-auto mt-6 text-center p-4">
        <button
          className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="mx-4 text-lg">
          Page {currentPage} of{" "}
          {filteredData.length === 0
            ? 1
            : Math.ceil(filteredData.length / collegesPerPage)}
        </span>
        <button
          className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 ${
            currentPage === Math.ceil(filteredData.length / collegesPerPage)
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={nextPage}
          disabled={
            currentPage === Math.ceil(filteredData.length / collegesPerPage)
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Btech;
