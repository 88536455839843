import React, { useState } from 'react';
import UniversitiesAndCourses from './UniversitiesandCourses';
import Testimonials from './Testimonial';
import CareerSupport from './CareerSupport';
import Features from './Feature';
import Counsellor from './Counsellor';
import UniversityDelegates from './UniversityDelegates';
import MBAPredictormobileview from '../MBAPredictermobileview';

const LandingPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNo: '',
    exam: '',
    location: '',
    degree: '',
    collegeRegion: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure 'exam' field is selected
    if (!formData.exam) {
      setSubmissionStatus('exam_required');
      return;
    }

    const response = await fetch('https://caderaedu.com/api/advertisement-forms/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setSubmissionStatus('submitted');
      setFormData({
        name: '',
        email: '',
        mobileNo: '',
        exam: '',
        location: '',
        degree: '',
        collegeRegion: '',
      });
      setTimeout(() => setSubmissionStatus(''), 3000); // Reset after 3 seconds
    } else {
      setSubmissionStatus('error');
    }
  };

  return (
    <div className="min-h-screen flex flex-col relative">
      <main
        className="flex flex-col md:flex-row justify-center items-center w-full flex-grow relative py-10"
        style={{
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(https://cdn.pixabay.com/video/2018/09/05/18088-288458760_large.mp4)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Left Section */}
        <div className="flex flex-col justify-center items-start text-white px-8 py-12 md:w-1/2 p-10">
          <h1 className="text-5xl font-extrabold mb-6 leading-tight">
            Apply For Top MBA Colleges Today
          </h1>
          <p className="text-lg md:text-xl mb-6 max-w-xl">
            Enhance your career with flexible and affordable MBA programs that fit your lifestyle.
          </p>
        </div>

        {/* Right Section */}
        <div className="bg-white shadow-lg rounded-2xl p-8 md:w-1/3 w-full max-w-md">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Enquire Now</h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your name"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your email"
              />
            </div>

            <div>
              <label htmlFor="mobileNo" className="block text-sm font-medium text-gray-700">
                Mobile Number
              </label>
              <input
                type="tel"
                id="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your mobile number"
              />
            </div>

            <div>
              <label htmlFor="exam" className="block text-sm font-medium text-gray-700">
                Exam
              </label>
              <select
                id="exam"
                value={formData.exam}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Exam</option>
                <option value="cat">CAT</option>
                <option value="mat">MAT</option>
                <option value="snap">SNAP</option>
                <option value="xat">XAT</option>
                <option value="cmat">CMAT</option>
                <option value="ibsat">IBSAT</option>
                <option value="na">N/A</option>
              </select>
            </div>

            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <input
                type="text"
                id="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your location"
              />
            </div>

            <div>
              <label htmlFor="degree" className="block text-sm font-medium text-gray-700">
                Degree
              </label>
              <input
                id="degree"
                value={formData.degree}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your Degree"
              />
            </div>

            <div>
              <label htmlFor="collegeRegion" className="block text-sm font-medium text-gray-700">
                College Region
              </label>
              <input
                id="collegeRegion"
                value={formData.collegeRegion}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter your College Region"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-3 rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
            >
              Submit
            </button>
          </form>

          {submissionStatus === 'exam_required' && (
            <div className="mt-4 p-4 text-center text-white bg-yellow-500 rounded-lg">
              Please select an exam.
            </div>
          )}

          {submissionStatus === 'submitted' && (
            <div className="mt-4 p-4 text-center text-white bg-green-500 rounded-lg">
              Form submitted successfully!
            </div>
          )}

          {submissionStatus === 'error' && (
            <div className="mt-4 p-4 text-center text-white bg-red-500 rounded-lg">
              There was an error submitting the form. Please try again.
            </div>
          )}

          <p className="text-xs text-gray-500 mt-4">
            By proceeding, you are giving us consent to call/SMS/WhatsApp/email with reference to your enquiry.
          </p>
        </div>
      </main>

      {/* Additional Sections */}
      <UniversitiesAndCourses />
      <Counsellor />
      <UniversityDelegates />
      <Testimonials />
      <CareerSupport />
      <MBAPredictormobileview/>
    </div>
  );
};

export default LandingPage;
