// import React from 'react';

// const Features = () => {
//   const features = [
//     {
//       icon: '✔', // Replace with an SVG or image if needed
//       title: '19 Years of Experience',
//     },
//     {
//       icon: '🌟',
//       title: '35,000+ Success Stories',
//     },
//     {
//       icon: '🎓',
//       title: '120+ University Partnerships',
//     },
//   ];

//   return (
//     <div className="flex flex-wrap justify-center items-center gap-6 p-6 bg-gray-50">
//       {features.map((feature, index) => (
//         <div
//           key={index}
//           className="flex flex-col items-center justify-center bg-blue-900 text-white rounded-lg shadow-lg p-6 w-48 h-40 text-center"
//         >
//           <div className="text-4xl mb-3">{feature.icon}</div>
//           <div className="text-lg font-medium">{feature.title}</div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Features;