// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";

// function ExamSyllabus(props) {
//   const navigate = useNavigate();
//   const { examName } = useParams();
//   const highlights = [
//     { feature: "Exam Mode", details: "Computer-Based Test" },
//     { feature: "Frequency", details: "Once a year" },
//     { feature: "Total Duration", details: "120 minutes" },
//   ];

//   const examPattern = [
//     { section: "VARC", questions: 24, duration: "40 minutes" },
//     { section: "DILR", questions: 20, duration: "40 minutes" },
//     { section: "Quant", questions: 22, duration: "40 minutes" },
//   ];

//   const syllabusSections = [
//     {
//       title: "VARC",
//       topics: [
//         "Reading Comprehension",
//         "Grammar and Sentence Correction",
//         "Vocabulary",
//       ],
//     },
//     {
//       title: "DILR",
//       topics: [
//         "Data Interpretation (Graphs, Charts, Tables)",
//         "Logical Puzzles and Sets",
//       ],
//     },
//     {
//       title: "Quantitative Aptitude",
//       topics: ["Algebra", "Geometry", "Arithmetic"],
//     },
//   ];

//   const faqs = [
//     {
//       question: "What is the mode of CAT exam?",
//       answer: "CAT is conducted in a computer-based mode across various test centers in India.",
//     },
//     {
//       question: "How many sections are there in CAT?",
//       answer: "There are three sections: VARC, DILR, and Quant.",
//     },
//     {
//       question: "What is the marking scheme?",
//       answer: "Each correct answer carries +3 marks, and there's a -1 penalty for incorrect answers.",
//     },
//   ];

//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" },
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   return (
//     <div className="bg-gray-100 p-4 md:p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.ExamSyllabusHeaderHeading1} {props.ExamSyllabusYear} : {props.ExamSyllabusaddwhichheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.ExamSyllabusHeaderadmitcardname}: {props.ExamSyllabusHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.ExamSyllabusHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.ExamSyllabusHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)}
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <div className="p-6 bg-gray-50 text-gray-800">
//         {/* Title */}
//         <h1 className="text-2xl font-bold text-gray-800 mb-6">
//           {props.ExamSyllabusDescriptionheading1}
//         </h1>
//         <p>
//         {props.ExamSyllabusheading1Description1}
//         </p>

//         {/* CAT Exam Highlights */}
//         <Section title={props.ExamSyllabusTable1headingtitleName1}>
//           <div className="overflow-x-auto">
//             <table className="min-w-full bg-white border border-gray-200">
//               <thead>
//                 <tr>
//                   <th
//                     scope="col"
//                     className="px-4 py-2 text-left bg-gray-100 border-b"
//                   >
//                      {props.ExamSyllabusTable1headingName1}
//                   </th>
//                   <th
//                     scope="col"
//                     className="px-4 py-2 text-left bg-gray-100 border-b"
//                   >
//                     {props.ExamSyllabusTable1headingName2}
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {highlights.map((item, index) => (
//                   <tr key={index} className="border-b">
//                     <td className="px-4 py-2 font-medium text-gray-700">
//                       {item.feature}
//                     </td>
//                     <td className="px-4 py-2 text-gray-600">{item.details}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </Section>

//         {/* CAT Exam Pattern */}
//         <Section title={props.ExamSyllabusTable2headingtitleName1}>
//           <div className="overflow-x-auto">
//             <table className="min-w-full bg-white border border-gray-200">
//               <thead>
//                 <tr>
//                   <th
//                     scope="col"
//                     className="px-4 py-2 text-left bg-gray-100 border-b"
//                   >
//                     {props.ExamSyllabusSectionTable2headingName1}
//                   </th>
//                   <th
//                     scope="col"
//                     className="px-4 py-2 text-left bg-gray-100 border-b"
//                   >
//                     {props.ExamSyllabusQuestionsTable2headingName2}
//                   </th>
//                   <th
//                     scope="col"
//                     className="px-4 py-2 text-left bg-gray-100 border-b"
//                   >
//                    {props.ExamSyllabusDurationTable2headingName2}
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {examPattern.map((pattern, index) => (
//                   <tr key={index} className="border-b">
//                     <td className="px-4 py-2 font-medium text-gray-700">
//                       {pattern.section}
//                     </td>
//                     <td className="px-4 py-2 text-gray-600">
//                       {pattern.questions}
//                     </td>
//                     <td className="px-4 py-2 text-gray-600">
//                       {pattern.duration}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </Section>

//         {/* Section-wise Syllabus */}
//         {/* <Section title="Section-wise Syllabus">
//           {syllabusSections.map((section, index) => (
//             <div key={index} className="mb-4">
//               <h3 className="font-semibold text-gray-800 mb-2">
//                 {section.title}
//               </h3>
//               <ul className="list-disc pl-5 text-gray-600">
//                 {section.topics.map((topic, idx) => (
//                   <li key={idx}>{topic}</li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </Section> */}

//         {/* FAQ Section */}
//         <Section title="Frequently Asked Questions">
//           {faqs.map((faq, index) => (
//             <div key={index} className="mb-4">
//               <h4 className="font-semibold text-gray-800">{faq.question}</h4>
//               <p className="text-gray-600">{faq.answer}</p>
//             </div>
//           ))}
//         </Section>
//       </div>
//     </div>
//   );
// }

// const Section = ({ title, children }) => (
//   <div className="mb-8">
//     <h2 className="text-lg font-bold text-gray-800 mb-4">{title}</h2>
//     {children}
//   </div>
// );

// export default ExamSyllabus;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ExamSyllabusComponent from "./ExamSyllabusComponent";


function ExamSyllabus() {
  const navigate = useNavigate();
  const { examName } = useParams();

  const [examData, setExamData] = useState(null);

  // Fetch exam data from the backend when the component mounts
  useEffect(() => {
    async function fetchExamData() {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    }

    fetchExamData();
  }, [examName]);

  if (!examData) {
    return <div>Loading...</div>;
  }

  // const tabs = [
  //   { name: "Overview", path: `/exam/${examName}` },
  //   { name: "Admit Card", path: `/exam/${examName}/admitcard` },
  //   { name: "Mock Test", path: `/exam/${examName}/mocktest` },
  //   { name: "Dates", path: `/exam/${examName}/dates` },
  //   { name: "Syllabus", path: `/exam/${examName}/syllabus` },
  //   { name: "Pattern", path: `/exam/${examName}/pattern` },
  //   { name: "Notification", path: `/exam/${examName}/notification` },
  //   { name: "Results", path: `/exam/${examName}/results` },
  // ];
  const {
    year,
    admitCardName,
    admitCardDate,
    examPattern,
    faqs,
    tabs,
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
    heading2Description,
    examName: name,
  } = examData;
  return (
    <div className="bg-gray-100 p-4 md:p-8">
      <div className="p-6 rounded-lg shadow-md bg-white">
      <div className="flex flex-col items-center justify-center px-4">
  <h1 className="text-xl sm:text-2xl font-bold text-gray-800 text-center flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
  {heading2Description && (
      <img
        src={heading2Description}
        alt="image"
        className="h-50 sm:h-12 w-auto" // Adjust size as needed
      />
    )}
    <span className="text-center">{name} {year}: Exam, Syllabus, Exam Pattern, Notification, Dates, Mock Test</span>
  </h1>
</div>
        <div className="flex items-center space-x-2 mt-4">
          {/* <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {examData.admitCardName}:{" "}
            {new Date(examData.admitCardDate).toLocaleDateString()}
          </div> */}
          <div className="ml-auto space-x-4">
            {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
              Download Guide
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Sample Papers
            </button> */}
          </div>
        </div>
      </div>

      {/* Navigation Section with Tabs */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
      <nav className="flex space-x-6 overflow-x-auto" style={{ backgroundColor: 'rgb(104, 164, 165)',color:'white' }}>
          {examData.tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-black-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Displaying Highlights, Exam Pattern, Syllabus Sections, and FAQs */}
      <div className="p-6 bg-gray-50 text-gray-800">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">Highlights</h1>
        <ul>
          {examData.highlights.map((highlight, index) => (
            <li key={index} className="mb-2">
              <strong>{highlight.feature}:</strong> {highlight.details}
            </li>
          ))}
        </ul>

        <h2 className="text-2xl font-bold mt-6">Exam Pattern</h2>
        <ul>
          {examData.examPattern.map((pattern, index) => (
            <li key={index} className="mb-2">
              <strong>{pattern.section}:</strong> {pattern.questions} questions,{" "}
              {pattern.duration}
            </li>
          ))}
        </ul>


       <ExamSyllabusComponent/>


        <h2 className="text-2xl font-bold mt-6">FAQs</h2>
        {examData.faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <h3 className="font-semibold">{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExamSyllabus;
