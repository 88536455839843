import React from "react";

export default function Syllabus() {
  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Total Duration of MBBS in Kazakhstan</h2>
      <p className="text-center text-gray-600 mb-8">
        It takes <strong className="text-gray-800">6 years</strong> to complete the MBBS course in the universities of Kazakhstan. 1 year is reserved for the
        internship period at the end of the last semester.
      </p>

      <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Syllabus of MBBS Course in Kazakhstan</h2>
      <p className="text-center text-gray-600 mb-8">
        Refer to the syllabus given below that is designed to prepare advanced subjects that students study in Kazakhstan:
      </p>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300 text-sm">
          <thead>
            <tr className="bg-blue-600 text-white">
              <th className="border border-gray-300 px-6 py-3 text-left">1st Year</th>
              <th className="border border-gray-300 px-6 py-3 text-left">2nd Year</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            <tr>
              <td className="border border-gray-300 px-6 py-3">Fundamentals of Psychology</td>
              <td className="border border-gray-300 px-6 py-3">Human Anatomy</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Bioorganic Chemistry</td>
              <td className="border border-gray-300 px-6 py-3">Histology</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Histology</td>
              <td className="border border-gray-300 px-6 py-3">Biochemistry</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Medical Chemistry</td>
              <td className="border border-gray-300 px-6 py-3">Physiology</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Embryology</td>
              <td className="border border-gray-300 px-6 py-3">Philosophy</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Human Anatomy</td>
              <td className="border border-gray-300 px-6 py-3">Emergency Medicine</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Cytology</td>
              <td className="border border-gray-300 px-6 py-3">Microbiology</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Elective Courses</td>
              <td className="border border-gray-300 px-6 py-3">Immunology</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3"></td>
              <td className="border border-gray-300 px-6 py-3">Patient Care</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3"></td>
              <td className="border border-gray-300 px-6 py-3">Embryology</td>
            </tr>
          </tbody>
        </table>

        <table className="w-full border-collapse border border-gray-300 text-sm mt-8">
          <thead>
            <tr className="bg-blue-600 text-white">
              <th className="border border-gray-300 px-6 py-3 text-left">3rd Year</th>
              <th className="border border-gray-300 px-6 py-3 text-left">4th Year</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            <tr>
              <td className="border border-gray-300 px-6 py-3">Pathophysiology</td>
              <td className="border border-gray-300 px-6 py-3">Pediatrics</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Pharmacology</td>
              <td className="border border-gray-300 px-6 py-3">Internal Medicine</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Pathomorphology</td>
              <td className="border border-gray-300 px-6 py-3">Tuberculosis</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Hygiene and Ecology</td>
              <td className="border border-gray-300 px-6 py-3">Surgery</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Pediatrics</td>
              <td className="border border-gray-300 px-6 py-3">Obstetrics and Gynecology</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Internal Medicine</td>
              <td className="border border-gray-300 px-6 py-3">Neurology</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">General Surgery</td>
              <td className="border border-gray-300 px-6 py-3">Ophthalmology</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Radiology</td>
              <td className="border border-gray-300 px-6 py-3">Medical Psychology</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Elective Courses</td>
              <td className="border border-gray-300 px-6 py-3">Urology</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3"></td>
              <td className="border border-gray-300 px-6 py-3">Narcology</td>
            </tr>
          </tbody>
        </table>

        <table className="w-full border-collapse border border-gray-300 text-sm mt-8">
          <thead>
            <tr className="bg-blue-600 text-white">
              <th className="border border-gray-300 px-6 py-3 text-left">5th Year</th>
              <th className="border border-gray-300 px-6 py-3 text-left">6th Year</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            <tr>
              <td className="border border-gray-300 px-6 py-3">Epidemiology</td>
              <td className="border border-gray-300 px-6 py-3">Internal Medicine</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Oncology</td>
              <td className="border border-gray-300 px-6 py-3">Infectious Diseases</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Social Medicine</td>
              <td className="border border-gray-300 px-6 py-3">Pediatrics</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Internal Medicine</td>
              <td className="border border-gray-300 px-6 py-3">Surgery</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Surgery</td>
              <td className="border border-gray-300 px-6 py-3">General Practice</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Pediatrics</td>
              <td className="border border-gray-300 px-6 py-3">Social Medicine</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Medical Genetics</td>
              <td className="border border-gray-300 px-6 py-3">Oncology</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Traumatology</td>
              <td className="border border-gray-300 px-6 py-3">Obstetrics</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Gynecology</td>
              <td className="border border-gray-300 px-6 py-3">Elective Courses</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border border-gray-300 px-6 py-3">Clinical Immunology</td>
              <td className="border border-gray-300 px-6 py-3"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-12">
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Faculties for MBBS in Kazakhstan</h2>
        <p className="text-center text-gray-600 mb-4">See the different types of medicine-related courses offered in Kazakhstan:</p>
        <ul className="list-disc list-inside text-gray-700 space-y-2">
          <li>General Medicine</li>
          <li>Therapeutic</li>
          <li>Pediatrics</li>
          <li>Medico-prophylactic</li>
          <li>Stomatology</li>
          <li>Pharmacy</li>
          <li>Public Health and Pharmacy</li>
          <li>Postgraduate</li>
        </ul>
      </div>
    </div>
  );
}
