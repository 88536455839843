[
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Regional Institute of Cooperative Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bheemanna Khandre Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Indian Institute of Plantation Management",
    "Course": "PGDM in Food Processing and Business Management",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Hillside Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Adept Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "East Point Group of Institutions",
    "Course": "Master of Business Administration (MBA Global)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sri Sairam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "SSIT - Sri Siddhartha Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Godutai Engineering College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Nandi Institute of Technology and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Operations Management",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Acharya School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "B. V. V. Sangha's Institute of Management Studies",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Lingaraj Appa Engineering College, Bidar",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Bharatesh Education Trust'S Global Business School - BET's",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Sharnbasva University",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "PA College of Engineering",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Poornaprajna Institute of Management",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Seshadripuram Institute of Management Studies",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "St. Joseph Engineering College - SJEC",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Krupanidhi College Of Management, Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "IBMR Business School, Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "R.K.Institute of Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "MSNM Besant Institute of PG Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "K.S. Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Corporate MBA",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "R.V. College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "General"
  },
  {
    "Name": "A.V.K Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "General"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "General"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "General"
  },
  {
    "Name": "AIET - Appa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "General"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "General"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "General"
  },
  {
    "Name": "AIEMS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "General"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42,
    "Category": "General"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "OBC"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69,
    "Category": "OBC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61,
    "Category": "OBC"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59,
    "Category": "OBC"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51,
    "Category": "OBC"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51,
    "Category": "OBC"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48,
    "Category": "OBC"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "OBC"
  },
  {
    "Name": "A.V.K Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "OBC"
  },
  {
    "Name": "AIET - Appa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "OBC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68,
    "Category": "OBC"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56,
    "Category": "OBC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51,
    "Category": "OBC"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49,
    "Category": "OBC"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48,
    "Category": "OBC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "OBC"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "OBC"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "OBC"
  },
  {
    "Name": "BITM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "OBC"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42,
    "Category": "OBC"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "OBC"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61,
    "Category": "OBC"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "OBC"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "OBC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Operations Management",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "C.N.K.Reddy College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Acharya School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce(Autonomous)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Krupanidhi College Of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "IBMR Business School, Hubli",
    "Course": "Master of Business Administration (MBA) + Post Graduate Program in Management (PGPM)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "OBC"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "R.K.Institute of Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "MSNM Besant Institute of PG Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "K.S. Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Corporate MBA",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "R.V. College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "SDVS Sangh's Annapoorna Institute of Management Research (AIMR Belgaum)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Regional Institute of Cooperative Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Bheemanna Khandre Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Indian Institute of Plantation Management",
    "Course": "PGDM in Food Processing and Business Management",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Hillside Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Adept Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "East Point Group of Institutions",
    "Course": "Master of Business Administration (MBA Global)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sri Sairam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "SSIT - Sri Siddhartha Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Godutai Engineering College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Nandi Institute of Technology and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "AIET - Appa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Anjuman Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "A.V.K Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "SC"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35,
    "Category": "SC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34,
    "Category": "SC"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32,
    "Category": "SC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31,
    "Category": "SC"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Bheemanna Khandre Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Indian Institute of Plantation Management",
    "Course": "PGDM in Food Processing and Business Management",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Hillside Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Adept Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "East Point Group of Institutions",
    "Course": "Master of Business Administration (MBA Global)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sri Sairam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SSIT - Sri Siddhartha Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Godutai Engineering College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Nandi Institute of Technology and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Operations Management",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Acharya School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "R.K.Institute of Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Krupanidhi College Of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "IBMR Business School, Hubli",
    "Course": "Master of Business Administration (MBA) + Post Graduate Program in Management (PGPM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Corporate MBA",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "MSNM Besant Institute of PG Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "K.S. Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "SDVS Sangh's Annapoorna Institute of Management Research (AIMR Belgaum)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "R.V. College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54,
    "Category": "ST"
  },
  {
    "Name": "AIET - Appa Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31,
    "Category": "ST"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31,
    "Category": "ST"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31,
    "Category": "ST"
  },
  {
    "Name": "A.V.K Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30,
    "Category": "ST"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31,
    "Category": "ST"
  },
  {
    "Name": "Anjuman Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31,
    "Category": "ST"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30,
    "Category": "ST"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30,
    "Category": "ST"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "PWD"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69,
    "Category": "PWD"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61,
    "Category": "PWD"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59,
    "Category": "PWD"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51,
    "Category": "PWD"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51,
    "Category": "PWD"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48,
    "Category": "PWD"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "PWD"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "PWD"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "PWD"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68,
    "Category": "PWD"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56,
    "Category": "PWD"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51,
    "Category": "PWD"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49,
    "Category": "PWD"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48,
    "Category": "PWD"
  },
  {
    "Name": "A.V.K Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "PWD"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "PWD"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "PWD"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42,
    "Category": "PWD"
  },
  {
    "Name": "BITM - Ballari Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "PWD"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bheemanna Khandre Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Indian Institute of Plantation Management",
    "Course": "PGDM in Food Processing and Business Management",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "East Point Group of Institutions",
    "Course": "Master of Business Administration (MBA Global)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sri Sairam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SSIT - Sri Siddhartha Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "R S College of Management & Science - Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Acharya School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "C.N.K.Reddy College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce(Autonomous)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Pooja Bhagavat Memorial Mahajana Education Centre",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Krupanidhi College Of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "IBMR Business School, Hubli",
    "Course": "Master of Business Administration (MBA) + Post Graduate Program in Management (PGPM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "R.K.Institute of Management and Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bhavans Priyamvada Birla Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "MSNM Besant Institute of PG Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Corporate MBA",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "SDVS Sangh's Annapoorna Institute of Management Research (AIMR Belgaum)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "K.S. Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "R.V. College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Patel Group of Institutions, Banglore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Regional Institute of Cooperative Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "NMAM Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Computer Applications (MCA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Alliance Ascent College, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 108,
    "Category": "EWS"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "EWS"
  },
  {
    "Name": "AIEMS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "EWS"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "EWS"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "EWS"
  },
  {
    "Name": "A.V.K Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46,
    "Category": "EWS"
  },
  {
    "Name": "AIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "EWS"
  },
  {
    "Name": "BITM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45,
    "Category": "EWS"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42,
    "Category": "EWS"
  }
]