import React, { useState } from 'react';
import axios from 'axios';
import { Button, Upload, Table, message, Alert, Row, Col, Input, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const FileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state

  const handleFileChange = (info) => {
    setSelectedFiles(info.fileList);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('file', file.originFileObj);
    });

    try {
      const auth = 'Basic ' + btoa(`${username}:${password}`); // Use the entered username and password

      const response = await axios.post(
        'https://caderaedu.com/api/files/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: auth,
          },
        }
      );

      if (response.data.files) {
        setUploadedFiles(response.data.files);
        setResponseMessage('Files uploaded successfully!');
        message.success('Files uploaded successfully!');
      } else {
        setResponseMessage('Failed to upload files.');
        message.error('Failed to upload files.');
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
      message.error(`Error: ${error.message}`);
    }
  };

  const handleLogin = () => {
    if (username === 'admin' && password === '1234567') {
      setIsAuthenticated(true); // Set authenticated state to true
      message.success('Login successful');
    } else {
      message.error('Invalid username or password');
    }
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
      width: '30%',
    },
    {
      title: 'File URL',
      dataIndex: 'url',
      key: 'url',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
      width: '70%',
    },
  ];

  if (!isAuthenticated) {
    return (
      <div style={{ padding: '20px' }}>
        <h1 style={{ textAlign: 'center', fontSize: '24px', marginBottom: '20px' }}>
          Enter credentials for the File Upload Access
        </h1>

        <Form onFinish={handleLogin} style={{ maxWidth: 400, margin: '0 auto' }}>
          <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input value={username} onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center', fontSize: '24px', marginBottom: '20px' }}>
        Upload Files to Vultr S3 Bucket
      </h1>

      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} md={10}>
          <div style={{ marginBottom: '20px' }}>
            <Upload
              multiple
              beforeUpload={() => false}
              onChange={handleFileChange}
              fileList={selectedFiles}
              showUploadList={{ showPreviewIcon: false }}
            >
              <Button icon={<UploadOutlined />} size="large" style={{ width: '100%' }}>
                Select Files
              </Button>
            </Upload>
          </div>
        </Col>

        <Col xs={24} md={10}>
          <Button
            type="primary"
            size="large"
            onClick={handleFileUpload}
            disabled={selectedFiles.length === 0}
            style={{ width: '100%' }}
          >
            Upload
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={uploadedFiles}
        rowKey="filename"
        style={{ marginTop: '30px' }}
        scroll={{ x: '100%' }}
        bordered
        pagination={false}
      />

      {responseMessage && (
        <Alert
          message={responseMessage}
          type={responseMessage.includes('successfully') ? 'success' : 'error'}
          showIcon
          style={{ marginTop: '20px' }}
        />
      )}
    </div>
  );
};

export default FileUpload;
