import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const cities = [
  { name: "New Delhi", image: require("../../images/Delhi.png") },
  { name: "Ahmedabad", image: require("../../images/Ahmedabad.png") },
  { name: "Gurgaon", image: require("../../images/Gurugram.png") },
  { name: "Bhopal", image: require("../../images/Bhopal.png") },
  { name: "Indore", image: require("../../images/castle.png") },
  { name: "Mumbai", image: require("../../images/Mumbai.png") },
  { name: "Nagpur", image: require("../../images/Nagpur.png") },
  { name: "Pune", image: require("../../images/Pune.png") },
  { name: "Jaipur", image: require("../../images/Jaipur.png") },
  { name: "Chennai", image: require("../../images/Chennai.png") },
];

const TopCities = () => {
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = (direction) => {
    const scrollAmount = 300; // Amount to scroll
    if (direction === "left") {
      scrollContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    } else {
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleCityClick = (city) => {
    navigate(`/collegesbycity/${city.name}`); // Navigate to the colleges page for the city
  };

  return (
    <Box sx={{ padding: "20px", backgroundColor: "#f8f9fa" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
          color: "#333",
        }}
      >
        Top Cities to Study
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton onClick={() => handleScroll("left")}>
          <ArrowBackIosIcon sx={{ color: "#555" }} />
        </IconButton>
        <Box
          ref={scrollContainerRef}
          sx={{
            display: "flex",
            overflowX: "auto",
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": { display: "none" }, // For Chrome, Safari
            scrollBehavior: "smooth",
            gap: "10px",
            padding: "10px 0",
            flexWrap: "nowrap",
            width: "80%",
          }}
        >
          {cities.map((city, index) => (
            <Card
              key={index}
              onClick={() => handleCityClick(city)}
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                textAlign: "center",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "#fff",
                flex: "0 0 auto",
                padding: "10px",
                cursor: "pointer", // Add cursor pointer for clickable cards
                transition: "transform 0.2s ease-in-out",
                "&:hover": { transform: "scale(1.05)" },
              }}
            >
              <CardContent>
                <Box
                  component="img"
                  src={city.image}
                  alt={city.name}
                  sx={{
                    width: "100%",
                    height: "100px",
                    objectFit: "contain",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "500", color: "#333" }}
                >
                  {city.name}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
        <IconButton onClick={() => handleScroll("right")}>
          <ArrowForwardIosIcon sx={{ color: "#555" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TopCities;
