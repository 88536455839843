import React from "react";
import { Hero, StudyGoals, MainContent, CollegeGrid, CollegeRanking, HeroMob, NewsSection, AdmissionChips} from "./index";
import LatestNotification from "./LatestNotifications";
import CollegeRankingTable from "./CollegeRankingTable";
import AdvertiseSection from "./AdvertiseSection";
import Predictmobileview from "./Predictmobileview";
import Studygoalsmobileview from "./Studygoalsmobileview";
import Topcoursesmobileview from "./Topcoursesmobileview";
import ExamMobile from "./ExamSection/ExamnsMobile";
import TopCities from "./TopCities";
import TopAbroadCities from "./TopAbroadCities";
import TopExams from "./TopExams";
const Home = () => {
  return (
    <div>
      <Hero />
      <HeroMob/>
      <StudyGoals />
     
      <Studygoalsmobileview/>
      <TopCities />
      <TopExams/>
      <TopAbroadCities/>
      {/* <AdmissionChips/> */}
      <NewsSection/>
      <Topcoursesmobileview/>
      <Predictmobileview/>
      <AdvertiseSection />
      <ExamMobile/>
      <CollegeRanking />
      <CollegeGrid />
      <CollegeRankingTable />
      <MainContent />
       <LatestNotification />
    </div>
  );
};

export default Home;
