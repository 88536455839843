import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStethoscope,
  faHeartbeat,
  faUserMd,
  faNotesMedical,
  faBookOpen,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { clat, ailet, slat, dullb } from "../../images";

const exams = [
  {
    id: 1,
    name: "GGSIPU LLB  (based on clat ug rank)",
    icon: "https://th.bing.com/th/id/OIP.5kAZnkhYvxW9kd4cU8aiiwAAAA?w=169&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    route: "/ggsipullbpredictor",
  },
  {
    id: 2,
    name: "GGSIPU LLM  (based on clat pg rank)",
    icon: "https://th.bing.com/th/id/OIP.5kAZnkhYvxW9kd4cU8aiiwAAAA?w=169&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    route: "/ggsipullmpredictor",
  },
  {
    id: 3,
    name: "NLU LLB Predictor(Based on CLAT UG Rank)",
    icon: clat,
    route: "/nlullbpredictor",
  },
  {
    id: 4,
    name: "NLU LLM Predictor(Based on CLAT PG Rank)",
    icon: clat,
    route: "/nlullmpredictor",
  },
  {
    id: 5,
    name: "AILET",
    icon: ailet,
    route: "/ailet",
  },
  {
    id: 6,
    name: "DU LLB Entrance Exam",
    icon: dullb,
    route: "/dulbentranceexam",
  },
];

const LawPredictormobile = () => {
  const navigate = useNavigate();
  const itemsPerPage = 6;
  const totalPages = Math.ceil(exams.length / itemsPerPage);

  const handleExamClick = (route) => {
    navigate(route);
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4 text-center text-black">
        Law College Predictor 
      </h1>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Law exams you have taken.
      </p>

      <div className="grid grid-cols-3 gap-6">
        {exams.map((exam) => (
          <div
            key={exam.id}
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleExamClick(exam.route)}
          >
            <div className="bg-white p-4 rounded-lg shadow-md text-center transform hover:scale-105 transition-transform duration-200 ease-in-out">
              <div className="w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            <p
              className="font-semibold text-gray-800 mt-1 text-center"
              style={{ fontSize: "13px" }}
            >
              {exam.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LawPredictormobile;
