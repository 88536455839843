import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MockTestStructure from "./MockTestStructure";
import ExamSchedule from "./ExamSchedule";
import ExamSyllabusComponent from "./ExamSyllabusComponent";
import ExamPatternComponent from "./ExamPatternComponent";
import ExamNotificationComponent from "./ExamNotificationComponent";
import DatesComponent from "./DatesComponent";

const CatExam = () => {
  const navigate = useNavigate();
  const { examName } = useParams(); // Capture dynamic exam type from URL
  const [examData, setExamData] = useState(null);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        console.log(examName);
        const response = await fetch(
          `https://caderaedu.com/api/exam/${examName}`
        );
        if (response.ok) {
          const data = await response.json();
          setExamData(data);
        } else {
          console.error("Error fetching exam data");
        }
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  if (!examData)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333",
          animation: "fadeIn 1.5s ease-in-out",
        }}
      >
        {!examData && (
          <>
            <p
              style={{
                fontSize: "30px",
                color: "#ff4d4f",
                marginBottom: "10px",
                animation: "bounce 2s infinite",
              }}
            >
              OOPS !!! This time NO DATA FOUND....
            </p>
            <p
              style={{
                fontSize: "20px",
                color: "#555",
                animation: "fadeIn 2s 1s forwards",
                opacity: 0,
              }}
            >
              CaderaEdu is working hard to update things. Please check back
              soon!
            </p>
          </>
        )}
        <style>
          {`
            @keyframes fadeIn {
                from { opacity: 0; }
                to { opacity: 1; }
            }

            @keyframes bounce {
                0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
                40% { transform: translateY(-20px); }
                60% { transform: translateY(-10px); }
            }
        `}
        </style>
      </div>
    );

  const {
    faqs,
    institutionCourses,
    tabs,
    examName: name,
    year,
    admitCardName,
    admitCardDate,
    heading2Description,
  } = examData;

  return (
    <div className="bg-gray-100 p-4 md:p-8">
      <div className="p-6 rounded-lg shadow-md bg-white mb-6">
        <div className="flex flex-col items-center justify-center px-4">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800 text-center flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
            {heading2Description && (
              <img
                src={heading2Description}
                alt="image"
                className="h-50 sm:h-12 w-auto" // Adjust size as needed
              />
            )}
            <span className="text-center">
              {name} {year}: Exam, Syllabus, Exam Pattern, Notification, Dates,
              Mock Test
            </span>
          </h1>
        </div>
      </div>

      {/* Navigation Section with Buttons */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav
          className="flex space-x-6 overflow-x-auto"
          style={{ backgroundColor: "rgb(104, 164, 165)", color: "white" }}
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-white-600 hover:text-blue-500 "
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Institution Courses Section */}
      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <div className="bg-white-200 text-black-100 px-3 py-1 rounded-md text-sm">
          {admitCardName}: {new Date(admitCardDate).toLocaleDateString()}
        </div>
        <h2 className="text-lg font-semibold">Institution Courses</h2>
        <div className="overflow-auto">
          <table className="min-w-full bg-white text-left text-gray-600 border rounded-lg">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Institution</th>
                <th className="px-4 py-2 border-b">Courses Offered</th>
              </tr>
            </thead>
            <tbody>
              {institutionCourses.map((course, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-b">{course.institution}</td>
                  <td className="px-4 py-2 border-b">
                    {course.coursesOffered}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <MockTestStructure />
        <ExamSchedule />
        <ExamSyllabusComponent />
        <ExamNotificationComponent />
        <ExamPatternComponent />
        <DatesComponent />
      </div>

      {/* FAQs Section */}
      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <h2 className="text-lg font-semibold">Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-gray-200 py-2">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-left w-full flex justify-between items-center"
      >
        <span className="font-semibold">{question}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

export default CatExam;
