import React, { useState, useEffect } from "react";
import {
  colleges,
  predictor,
  counselling,
  Scholarship,
  Counsellingupdates,
  pricing
} from "../../images/index";

function CompulsoryTimesignup({ onFormSubmit }) {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    city: "",
    courseInterestedIn: "",
  });
  const [loading, setLoading] = useState(false); // Add loading state
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Show modal after a delay of 0.1 seconds (100ms)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSignupModal(true);
    }, 100);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handleCloseModal = () => {
    window.location.href = "/"; // Redirect to the specified URL
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Submit form data to the backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await fetch("https://caderaedu.com/api/form/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send the form data as JSON
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }

      const result = await response.json();
      setSuccessMessage("Form submitted successfully!");
      onFormSubmit(true); // Call parent callback to enable the predictor button
      setShowSignupModal(false); // Close modal after successful submission
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
    } finally {
      setLoading(false); // Turn off loading spinner
    }
  };

  return (
    <div className="App">
      {showSignupModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8 relative w-full max-w-4xl mx-4 shadow-lg flex">
            {/* Left Side (Cards Section) */}
            <div className="hidden md:block md:w-1/2 bg-gray-100 p-6 rounded-t-lg md:rounded-l-lg md:rounded-t-none mb-4 md:mb-0">
              <h3 className="text-xl font-bold mb-4 text-center">
                How CaderaEdu helps you
              </h3>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={counselling}
                    width={60}
                    height={60}
                    alt="24X7 Dedicated Counsellor"
                    className="mr-4"
                  />
                  <span>24X7 Dedicated Counsellor</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={colleges}
                    width={50}
                    height={50}
                    alt="Top Ranking Colleges"
                    className="mr-4"
                  />
                  <span>Top Ranking Colleges</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={Counsellingupdates}
                    width={50}
                    height={50}
                    alt="Counselling Updates"
                    className="mr-4"
                  />
                  <span>Counselling Updates</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={predictor}
                    width={50}
                    height={50}
                    alt="College Predictors"
                    className="mr-4"
                  />
                  <span>College Predictors</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={pricing}
                    width={50}
                    height={50}
                    alt="Reasonable Pricing"
                    className="mr-4"
                  />
                  <span>Reasonable pricing</span>
                </div>
              </div>
            </div>

            {/* Right Side (Form Section) */}
            <div className="md:w-1/2 w-full bg-white  md:p-2 rounded-b-lg md:rounded-r-lg md:rounded-b-none relative">
              <span
                className="absolute top-2 right-2 text-2xl cursor-pointer font-bold"
                onClick={handleCloseModal}
              >
                &times;
              </span>
              <h2 className="text-xl font-bold text-teal-600 mb-4">
                Register Now To Use The Predictor...
              </h2>
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label className="block text-left font-semibold">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Full Name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    Mobile Number *
                  </label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Mobile Number"
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    City You Live In *
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="City"
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    Course Interested In *
                  </label>
                  <input
                    type="text"
                    name="courseInterestedIn"
                    value={formData.courseInterestedIn}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Course"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-teal-600 text-white py-2 rounded-md font-semibold hover:bg-teal-700"
                  disabled={loading} // Disable button during loading
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>

              {/* Show success or error message */}
              {successMessage && (
                <p className="mt-4 text-green-500">{successMessage}</p>
              )}
              {errorMessage && (
                <p className="mt-4 text-red-500">{errorMessage}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompulsoryTimesignup;