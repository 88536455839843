import React, { useState } from 'react';

const FAQs = () => {
  const faqs = [
    { question: "How many Indian students are studying in Kazakhstan?", answer: "There are more than 8000 Indian students studying in Kazakhstan out of these, 5000 students are pursuing medical degrees." },
    { question: "Which are the highly recommended medical universities for Indian students?", answer: "Kazakhstan offers several NMC-approved universities such as Kazakh National Medical University and Al-Farabi Kazakh National University." },
    { question: "When does the MBBS course start in Kazakhstan?", answer: "The MBBS course typically starts in September or October each year." },
    { question: "Is Kazakhstan a good option for MBBS?", answer: "Yes, Kazakhstan is a popular choice for MBBS due to affordable tuition fees and recognized medical colleges." },
    { question: "Can I get scholarships or any kind of subsidized allowances while studying MBBS in Kazakhstan?", answer: "Yes, some universities in Kazakhstan offer scholarships based on academic performance." },
    { question: "How many NMC-recognized medical colleges are there in Kazakhstan?", answer: "There are more than 12 NMC-recognized medical colleges in Kazakhstan." },
    { question: "Is there any language barrier while studying MBBS in Kazakhstan?", answer: "No, most medical universities offer courses in English to accommodate international students." },
    { question: "After MBBS from Kazakhstan, is the NMC screening test required or not?", answer: "Yes, students need to pass the NMC screening test (FMGE/NExT) to practice in India." },
    { question: "Is the MBBS degree from Kazakhstan valid in India?", answer: "Yes, degrees from NMC-recognized universities are valid in India." },
    { question: "What is the safety index of Kazakhstan for Indians?", answer: "Kazakhstan is considered a safe country for Indian students, with a friendly and welcoming environment." }
  ];

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">MBBS in Kazakhstan - Important FAQs</h1>
      <div>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <div
        className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-gray-700 font-medium">{question}</span>
        <span className="text-xl font-bold text-gray-600">{isOpen ? '×' : '+'}</span>
      </div>
      {isOpen && (
        <div className="p-4 text-gray-600 bg-gray-50">{answer}</div>
      )}
    </div>
  );
};

export default FAQs;
