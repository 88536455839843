import React, { useState } from "react";

const ResponseItem = ({ model, response }) => {
  const [showFullResponse, setShowFullResponse] = useState(false);

  const toggleResponse = () => {
    setShowFullResponse(!showFullResponse);
  };

  return (
    <li>
      <strong>{model}:</strong>{" "}
      {showFullResponse ? (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: response }}
            style={{
              border: "1px solid #ddd",
              padding: "10px",
              borderRadius: "5px",
              marginTop: "10px",
              backgroundColor: "#f9f9f9",
            }}
          />
          <button
            onClick={toggleResponse}
            style={{
              marginLeft: "10px",
              padding: "5px 10px",
              background: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Hide
          </button>
        </div>
      ) : (
        <button
          onClick={toggleResponse}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            background: "transparent",
            color: "#007bff",
            border: "none",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Show Response
        </button>
      )}
    </li>
  );
};

export default ResponseItem;
