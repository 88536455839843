import React from 'react';


const Counsellor = () => {

  
    const features = [
      {
        icon: '✔', // Replace with an SVG or image if needed
        title: '19 Years of Experience',
      },
      {
        icon: '🌟',
        title: '35,000+ Success Stories',
      },
      {
        icon: '🎓',
        title: '120+ University Partnerships',
      },
    ];

  return (
    <div className="bg-gray-200 text-center py-8 px-8">
      <div className="flex flex-wrap justify-center items-center gap-6 p-6 bg-gray-200 s-15">
  {features.map((feature, index) => (
    
    <div
      key={index}
      className="flex flex-col items-center justify-center bg-teal-500 text-white rounded-lg shadow-lg p-6 w-80 h-72 text-center" // Increased size
    >
      <div className="text-5xl mb-3">{feature.icon}</div> 
      <div className="text-lg font-medium">{feature.title}</div>
    </div>
  ))}
</div>
    <h2 className="text-2xl md:text-3xl font-bold text-blue-900 mb-4"><br></br>
        Conquer Your Dreams Of Studying in the MBA 
      </h2><br></br><br></br>
      

<button className="bg-teal-500 hover:bg-pink-600 text-white font-semibold py-5 px-10 rounded-full text-2xl md:text-xl shadow-md">
  TALK TO A COUNSELLOR
</button>


    </div>
  );
};

export default Counsellor;