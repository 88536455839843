[
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "MBA in Food and Agri Business Management",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Executives (MBA-PGPX)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Enterprise Management (PGPEM)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Executive Post Graduate Programme in Management (EPGPM)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "ePost Graduate Diploma in Advanced Business Analytics",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "Faculty of Management Studies, University of Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98,
    "Category": "General"
  },
  {
    "Name": "Management Development Institute",
    "Course": "PGDM in International Business + Master in Management (MIM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Post Graduate Program in Agribusiness Management (PGP-ABM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Public Policy and Management (PGPPM)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive PGP for Visionary Leadership in Manufacturing",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "Department of Management Studies, IIT Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.04,
    "Category": "General"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 97.5,
    "Category": "General"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Discontinued (Sep 2024) - Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Discontinued (Feb 2023)- Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Programme in Human Resource Management (PGP-HRM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Business Leadership (PGP-BL)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Executive PG Diploma in Management and Artificial Intelligence Online",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Finance Management (PGP-F)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management - Noida Campus",
    "Course": "Post Graduate Program in Sustainable Management (PGP-SM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (EPGP)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Liberal Studies and Management (PGP-LSM)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "MBA in Entrepreneurship and Innovation",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGP)",
    "Score": 97,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96,
    "Category": "General"
  },
  {
    "Name": "DOMS IIT Roorkee",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Human Resources",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) (Delhi)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Human Resources (PGPM-HR)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "MBA in Analytics",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive MBA in Analytics",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Human Resource Management",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (ePGP)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Working Executive (PGPMWE)",
    "Score": 94,
    "Category": "General"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post-Graduate Program in Management for the Centrally Funded Technical Ins",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program for Experienced Professionals (PGPEx)",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Digital Enterprise Management",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Online Post Graduate Diploma in Business Administration for Working Executives",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Digital Business Management",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 91,
    "Category": "General"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIT Kanpur - Department of Industrial and Management Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Financial Management",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program in Digital Governance and Management (PGPDGM)",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Hospital & Healthcare Management",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) for Working Professionals",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Global Supply Chain Management",
    "Score": 92,
    "Category": "General"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Discontinued (May 2022)- Executive PG Diploma Program in Management (ePGPx)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Executive Post Graduate Diploma in Sports Management (EPGDSM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Master of Business Administration (Working Executives)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Executive Master of Business Management",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Executive MBA in Digital Transformation and Analytics",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Marketing",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 88,
    "Category": "General"
  },
  {
    "Name": "DoMS IIT Madras - Department of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.11,
    "Category": "General"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Management (PGPM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School (IBS), Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "MBA in Tourism and Hospitality Management",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "MBA in Hospital Administration and Healthcare Management",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program for Executives (Managing Business in Emerging Economies) - PGPEx (MBEE)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 88,
    "Category": "General"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "MBA in Human Resource Management",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Development Management (PGPDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Manipur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Executive Post Graduate Diploma in Management (EPGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies, BHU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Management",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "BITS School of Management (BITSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "PGDM in Business Management",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Banaras Hindu University",
    "Course": "Post Graduate Diploma in Business Administration (PGDBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Delhi School of Management, DTU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84,
    "Category": "General"
  },
  {
    "Name": "MICA",
    "Course": "PGDM in Communications",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Indian Institute of Forest Management",
    "Course": "MBA in Forestry Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "NIT Trichy - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Trinity College for Management and Technology",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "MBA in Business Analytics",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "PGDM in Fintech",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Shiva Institute of Management Studies (SIMS Ghaziabad)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Camellia School of Business Management - CSBM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Prin L.N. Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Amity University, Gurugram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 72,
    "Category": "General"
  },
  {
    "Name": "The ICFAI University, Mizoram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Infinity Business School",
    "Course": "Post Graduate Programs in Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "JRE Group of Institutions (JRE)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Biotechnology Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Vignan's Foundation for Science, Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Universal AI University",
    "Course": "Post Graduate Diploma in Management (PGDM General)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "PGDM in International Business",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School (IBS), Ahmedabad",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Asia-Pacific Institute of Management, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM) (General)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Jaipur National University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Hospitality Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Agriculture and Food Business",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Jagran Institute of Communication and Management (JICM)",
    "Course": "PGDM in Marketing Communication Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Lovely Professional University, Admission Office",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Raipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "GNA University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "NIT Surathkal - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Entrepreneurship and Venture Creation",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Banking Finance and Allied Services",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance and Human Resource Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Sport Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Competitive Intelligence and Strategic Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Asia Pacific Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ICOFP Mumbai - International College of Financial Planning",
    "Course": "MBA in Financial Planning",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Human Resource Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in International Finance and Accounting integrated with ACCA, UK",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Human Resource Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Operations and Systems Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Luxury and Fashion Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Universal AI University",
    "Course": "PGDM in Foreign with Collaboration Cardiff Metropolitan University, UK",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Digital Business integrated with HETIC France School of Digital Leadership",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "LPU - Lovely Professional University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School, Mumbai",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Aviation and Aerospace Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Supply Chain Analytics and Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "GD Goenka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "SRIRAM Group of Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "IBMR-IBS College, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School (IBS), Gurgaon",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School (IBS), Pune",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "RICS School of Built Environment, Amity University, Noida",
    "Course": "MBA in Construction Project Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "MATS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Lucknow",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Natural Resources and Sustainable Development",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Insurance and Banking Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in Advertising and Communication",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "ICFAI Business School (IBS), Bangalore",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity Global Business School, Noida",
    "Course": "Post Graduate Program in Management (PGPM) + Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Gandhinagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Media Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Asian Business School (ABS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in International Business",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in Research and Business Analytics",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "PGDM in Retail Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Institute of Management, Nirma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "IILM Institute For Higher Education",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "International Business School, Pune",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "JIMS Kalkaji - Jagannath International Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBM ( Business Management)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "PGDM in Insurance Business Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "SSN School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "SGIT School of Management, Sanskar Educational Group",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Doon Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "The Indian Institute of Financial Planning",
    "Course": "Post Graduate Diploma in Management (PGDM Plus)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Kolkata",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Jagan Institute of Management Studies, Jaipur",
    "Course": "PGDM + SAP with Business Analytics",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "AIMED - Aachi Institute of Management and Entrepreneurial Development",
    "Course": "PGDM in Entrepreneurial Development (PGDME)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "IIKM- The Corporate B School",
    "Course": "Work Integrated MBA Digital Marketing and E-Commerce",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Finance (Corporate Finance, Investment Finance)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Kochi Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "MBA + M.S. in Business Analytics Dual Degree Programme with University at Buffalo, USA",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (One Year)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Course": "MBA in International Business",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Lotus Business School",
    "Course": "MBA in Agri Business Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "FOSTIIMA Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Computer Application",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Sharda School of Business Studies",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "IIEBM - Indus Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Christ College, Pune",
    "Course": "Bachelor of Business Administration",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Eastern Institute for Integrated Learning in Management - EIILM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Christ - Lavasa, Pune Campus",
    "Course": "Discontinued (June 2023) Post Graduate Diploma in Retail and Omnichannel",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Kohinoor Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "National Institute of Bank Management",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "ICBM-School of Business Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM) (Triple Specialization)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "SMS - School of Management Sciences, Varanasi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "The NorthCap University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "IIRM Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "SVIMS Business School",
    "Course": "PGDM in Marketing",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "SoM, Pandit Deendayal Energy University (PDEU)",
    "Course": "MBA in General Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Indian Institute of Social Welfare and Business Management",
    "Course": "Master of Business Administration (MBA) (Day)",
    "Score": 50.35,
    "Category": "General"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPFM (Financial Management)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPDM (Digital Marketing)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPEX (Executive Management)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPSCM (Supply Chain Management)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBA and DS (Business Analytics and Data Science)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Dhruva College Of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Krupanidhi College of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "I Business Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Myra School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Garden City University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 35,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "AIM - Army Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Srusti Academy of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "MDSU - Maharshi Dayanand Saraswati University",
    "Course": "MBA in Service Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "KIET Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "International School of Management Sciences - ISMS",
    "Course": "Post Graduate Program in Management",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 35,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "IIHMR University",
    "Course": "MBA in Hospital and Health Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "DY Patil University School of Management",
    "Course": "MBA in Finance Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "PIET - Panipat Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "OPJS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "KIIT School of Rural Management (KSRM)",
    "Course": "MBA in Rural Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "University of Engineering and Management, Jaipur",
    "Course": "MBA (Regular with Dual Specialization)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Bennett University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "International Institute of Sports & Management",
    "Course": "Master's Degree in Sports Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "PDM University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Centurion University of Technology and Management, Bhubaneswar Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Taxila Business School",
    "Course": "PGDM in Business Analytics with Triple Specialization",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "GIMT - Gandhi Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "NITTE School of Management - NSOM",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Adamas University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "FLAME University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Raffles University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Poddar Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "GLF Business School",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Narayana Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (Dual Specialization)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Sister Nivedita University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Ramaiah Institute of Management Studies- RIMS",
    "Course": "Master of Business Administration (MBA) (Industry Integrated)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "SKFGI - Supreme Knowledge Foundation Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Dr K.N Modi University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Discontinued (Oct 2024)- Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "BBIT - Budge Budge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Athena School of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Teri School of Advanced Studies (TSAS)",
    "Course": "Discontinued (Jan 2024) - MBA in Infrastructure Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Aravali College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Pioneer Institute of Professional Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "MIMA Institute of Management",
    "Course": "PGDM in Business Administration",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Faculty of Management Studies, Manav Rachna International Institute of Research and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Atharva School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Xavier School of Human Resource Management, Xavier University Bhubaneswar",
    "Course": "MBA in Human Resource Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Shridhar University (SU)",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "G.L. Bajaj Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Indore Indira Business School",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "HLM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Arya College of Engineering and Information",
    "Course": "MBA in Human Resource and Development",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "SOA University - Siksha 'O' Anusandhan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Manipal University, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Gautam Buddha University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "GNIOT Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Shobhit Institute of Engineering and Technology",
    "Course": "MBA in Finance",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "Jagannath University Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "MBA in Food and Agri Business Management",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Executives (MBA-PGPX)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Enterprise Management (PGPEM)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Executive Post Graduate Programme in Management (EPGPM)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "ePost Graduate Diploma in Advanced Business Analytics",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Discontinued (Sep 2024) - Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Programme in Human Resource Management (PGP-HRM)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Public Policy and Management (PGPPM)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive PGP for Visionary Leadership in Manufacturing",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "Department of Management Studies, IIT Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.04,
    "Category": "OBC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Post Graduate Program in Agribusiness Management (PGP-ABM)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Discontinued (Feb 2023)- Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (EPGP)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Business Leadership (PGP-BL)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Finance Management (PGP-F)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Executive PG Diploma in Management and Artificial Intelligence Online",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96,
    "Category": "OBC"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Human Resources",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (ePGP)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Liberal Studies and Management (PGP-LSM)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGP)",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "MBA in Entrepreneurship and Innovation",
    "Score": 97,
    "Category": "OBC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) (Delhi)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Human Resources (PGPM-HR)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Working Executive (PGPMWE)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "MBA in Analytics",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive MBA in Analytics",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Digital Enterprise Management",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Online Post Graduate Diploma in Business Administration for Working Executives",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Digital Business Management",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Human Resource Management",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post-Graduate Program in Management for the Centrally Funded Technical Ins",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program for Experienced Professionals (PGPEx)",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program in Digital Governance and Management (PGPDGM)",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Global Supply Chain Management",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Hospital & Healthcare Management",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) for Working Professionals",
    "Score": 92,
    "Category": "OBC"
  },
  {
    "Name": "Faculty of Management Studies, University of Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.07,
    "Category": "OBC"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Master of Business Administration (Working Executives)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program for Executives (Managing Business in Emerging Economies) - PGPEx (MBEE)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "MBA in Hospital Administration and Healthcare Management",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 88,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 91,
    "Category": "OBC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Financial Management",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Discontinued (May 2022)- Executive PG Diploma Program in Management (ePGPx)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Executive Post Graduate Diploma in Sports Management (EPGDSM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "MBA in Tourism and Hospitality Management",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Marketing",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Executive Master of Business Management",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Executive MBA in Digital Transformation and Analytics",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 88,
    "Category": "OBC"
  },
  {
    "Name": "DOMS IIT Roorkee",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.5,
    "Category": "OBC"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "MBA in Human Resource Management",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School (IBS), Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Development Management (PGPDM)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Banaras Hindu University",
    "Course": "Post Graduate Diploma in Business Administration (PGDBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "BITS School of Management (BITSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "PGDM in Business Management",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Management (PGPM)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "IIT Kanpur - Department of Industrial and Management Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Manipur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Executive Post Graduate Diploma in Management (EPGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Management Development Institute",
    "Course": "PGDM in International Business + Master in Management (MIM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "PGDM in Fintech",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies, BHU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Shiva Institute of Management Studies (SIMS Ghaziabad)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "NIT Trichy - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Prin L.N. Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School (IBS), Ahmedabad",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Infinity Business School",
    "Course": "Post Graduate Programs in Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "JRE Group of Institutions (JRE)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Biotechnology Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Vignan's Foundation for Science, Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Jagran Institute of Communication and Management (JICM)",
    "Course": "PGDM in Marketing Communication Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "The ICFAI University, Mizoram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Asia-Pacific Institute of Management, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM) (General)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Jaipur National University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Hospitality Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Agriculture and Food Business",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "DoMS IIT Madras - Department of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Universal AI University",
    "Course": "Post Graduate Diploma in Management (PGDM General)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Lovely Professional University, Admission Office",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Competitive Intelligence and Strategic Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Asia Pacific Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Entrepreneurship and Venture Creation",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICOFP Mumbai - International College of Financial Planning",
    "Course": "MBA in Financial Planning",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Human Resource Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in International Finance and Accounting integrated with ACCA, UK",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Human Resource Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Raipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "GNA University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "NIT Surathkal - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management - Noida Campus",
    "Course": "Post Graduate Program in Sustainable Management (PGP-SM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Banking Finance and Allied Services",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance and Human Resource Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Operations and Systems Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Luxury and Fashion Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Aviation and Aerospace Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "SRIRAM Group of Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IBMR-IBS College, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School (IBS), Gurgaon",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School (IBS), Pune",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School (IBS), Bangalore",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Sport Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Universal AI University",
    "Course": "PGDM in Foreign with Collaboration Cardiff Metropolitan University, UK",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "LPU - Lovely Professional University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School, Mumbai",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ICFAI Business School, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "RICS School of Built Environment, Amity University, Noida",
    "Course": "MBA in Construction Project Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity Global Business School, Noida",
    "Course": "Post Graduate Program in Management (PGPM) + Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Lucknow",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Natural Resources and Sustainable Development",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Insurance and Banking Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Digital Business integrated with HETIC France School of Digital Leadership",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "MATS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Delhi School of Management, DTU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Gandhinagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Media Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Supply Chain Analytics and Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "GD Goenka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "MICA",
    "Course": "PGDM in Communications",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Indian Institute of Forest Management",
    "Course": "MBA in Forestry Management",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Camellia School of Business Management - CSBM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Gurugram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "SSN School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "SGIT School of Management, Sanskar Educational Group",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Trinity College for Management and Technology",
    "Course": "Post Graduate Diploma in Management",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management, Nirma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "IILM Institute For Higher Education",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Doon Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "The Indian Institute of Financial Planning",
    "Course": "Post Graduate Diploma in Management (PGDM Plus)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Kolkata",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Asian Business School (ABS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "AIMED - Aachi Institute of Management and Entrepreneurial Development",
    "Course": "PGDM in Entrepreneurial Development (PGDME)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "IIKM- The Corporate B School",
    "Course": "Work Integrated MBA Digital Marketing and E-Commerce",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "International Business School, Pune",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "JIMS Kalkaji - Jagannath International Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBM ( Business Management)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Jaipur",
    "Course": "PGDM + SAP with Business Analytics",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (One Year)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Course": "MBA in International Business",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Lotus Business School",
    "Course": "MBA in Agri Business Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "FOSTIIMA Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Computer Application",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "IIEBM - Indus Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Christ College, Pune",
    "Course": "Bachelor of Business Administration",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "SoM, Pandit Deendayal Energy University (PDEU)",
    "Course": "MBA in General Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Finance (Corporate Finance, Investment Finance)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Kochi Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "MBA + M.S. in Business Analytics Dual Degree Programme with University at Buffalo, USA",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Eastern Institute for Integrated Learning in Management - EIILM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Christ - Lavasa, Pune Campus",
    "Course": "Discontinued (June 2023) Post Graduate Diploma in Retail and Omnichannel",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Sharda School of Business Studies",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Kohinoor Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "National Institute of Bank Management",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "ICBM-School of Business Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM) (Triple Specialization)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPFM (Financial Management)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPDM (Digital Marketing)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBA and DS (Business Analytics and Data Science)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Dhruva College Of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "SMS - School of Management Sciences, Varanasi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "The NorthCap University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "IIRM Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "SVIMS Business School",
    "Course": "PGDM in Marketing",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPEX (Executive Management)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPSCM (Supply Chain Management)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Krupanidhi College of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "I Business Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Myra School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Garden City University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 35,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "AIM - Army Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Srusti Academy of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Indian Institute of Social Welfare and Business Management",
    "Course": "Master of Business Administration (MBA) (Day)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "International School of Management Sciences - ISMS",
    "Course": "Post Graduate Program in Management",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 35,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "IIHMR University",
    "Course": "MBA in Hospital and Health Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "DY Patil University School of Management",
    "Course": "MBA in Finance Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "MDSU - Maharshi Dayanand Saraswati University",
    "Course": "MBA in Service Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "KIET Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "PIET - Panipat Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "OPJS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "KIIT School of Rural Management (KSRM)",
    "Course": "MBA in Rural Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "University of Engineering and Management, Jaipur",
    "Course": "MBA (Regular with Dual Specialization)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Taxila Business School",
    "Course": "PGDM in Business Analytics with Triple Specialization",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "GIMT - Gandhi Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "NITTE School of Management - NSOM",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Adamas University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "FLAME University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Bennett University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Sports & Management",
    "Course": "Master's Degree in Sports Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "PDM University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Centurion University of Technology and Management, Bhubaneswar Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Ramaiah Institute of Management Studies- RIMS",
    "Course": "Master of Business Administration (MBA) (Industry Integrated)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "SKFGI - Supreme Knowledge Foundation Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Dr K.N Modi University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Discontinued (Oct 2024)- Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "BBIT - Budge Budge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Raffles University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Poddar Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "GLF Business School",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Narayana Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (Dual Specialization)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Sister Nivedita University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "MIMA Institute of Management",
    "Course": "PGDM in Business Administration",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Faculty of Management Studies, Manav Rachna International Institute of Research and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Athena School of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Teri School of Advanced Studies (TSAS)",
    "Course": "Discontinued (Jan 2024) - MBA in Infrastructure Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Aravali College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Pioneer Institute of Professional Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "HLM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "SOA University - Siksha 'O' Anusandhan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Gautam Buddha University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Shobhit Institute of Engineering and Technology",
    "Course": "MBA in Finance",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Atharva School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Xavier School of Human Resource Management, Xavier University Bhubaneswar",
    "Course": "MBA in Human Resource Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Shridhar University (SU)",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "G.L. Bajaj Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Indore Indira Business School",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Arya College of Engineering and Information Technology",
    "Course": "MBA in Human Resource and Development",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Manipal University, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "GNIOT Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "Jagannath University Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "OBC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "MBA in Food and Agri Business Management",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Executives (MBA-PGPX)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Enterprise Management (PGPEM)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Executive Post Graduate Programme in Management (EPGPM)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "ePost Graduate Diploma in Advanced Business Analytics",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Discontinued (Sep 2024) - Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Programme in Human Resource Management (PGP-HRM)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Public Policy and Management (PGPPM)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive PGP for Visionary Leadership in Manufacturing",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "Department of Management Studies, IIT Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.04,
    "Category": "SC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Post Graduate Program in Agribusiness Management (PGP-ABM)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Discontinued (Feb 2023)- Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (EPGP)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Business Leadership (PGP-BL)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Executive PG Diploma in Management and Artificial Intelligence Online",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Finance Management (PGP-F)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96,
    "Category": "SC"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Human Resources",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (ePGP)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Liberal Studies and Management (PGP-LSM)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "MBA in Entrepreneurship and Innovation",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGP)",
    "Score": 97,
    "Category": "SC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) (Delhi)",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Human Resources (PGPM-HR)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "MBA in Analytics",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Working Executive (PGPMWE)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post-Graduate Program in Management for the Centrally Funded Technical Ins",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program for Experienced Professionals (PGPEx)",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program in Digital Governance and Management (PGPDGM)",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Hospital & Healthcare Management",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) for Working Professionals",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive MBA in Analytics",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Human Resource Management",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 94,
    "Category": "SC"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Digital Enterprise Management",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Online Post Graduate Diploma in Business Administration for Working Executives",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Digital Business Management",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Global Supply Chain Management",
    "Score": 92,
    "Category": "SC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Financial Management",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Discontinued (May 2022)- Executive PG Diploma Program in Management (ePGPx)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Executive Post Graduate Diploma in Sports Management (EPGDSM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "MBA in Tourism and Hospitality Management",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "MBA in Hospital Administration and Healthcare Management",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program for Executives (Managing Business in Emerging Economies) - PGPEx (MBEE)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 88,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 91,
    "Category": "SC"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Master of Business Administration (Working Executives)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Executive Master of Business Management",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Executive MBA in Digital Transformation and Analytics",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Marketing",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 88,
    "Category": "SC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Management (PGPM)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "MBA in Human Resource Management",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Manipur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "BITS School of Management (BITSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "PGDM in Business Management",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Banaras Hindu University",
    "Course": "Post Graduate Diploma in Business Administration (PGDBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Faculty of Management Studies, University of Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.19,
    "Category": "SC"
  },
  {
    "Name": "Shiva Institute of Management Studies (SIMS Ghaziabad)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School (IBS), Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Development Management (PGPDM)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Executive Post Graduate Diploma in Management (EPGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "NIT Trichy - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Prin L.N. Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "IIT Kanpur - Department of Industrial and Management Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Management Development Institute",
    "Course": "PGDM in International Business + Master in Management (MIM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School (IBS), Ahmedabad",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "PGDM in Fintech",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "The ICFAI University, Mizoram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Infinity Business School",
    "Course": "Post Graduate Programs in Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Asia-Pacific Institute of Management, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM) (General)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "JRE Group of Institutions (JRE)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Insurance and Banking Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Hospitality Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Agriculture and Food Business",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Jagran Institute of Communication and Management (JICM)",
    "Course": "PGDM in Marketing Communication Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Universal AI University",
    "Course": "Post Graduate Diploma in Management (PGDM General)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Raipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "GNA University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Jaipur National University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Biotechnology Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Vignan's Foundation for Science, Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Studies, BHU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Lovely Professional University, Admission Office",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Competitive Intelligence and Strategic Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Asia Pacific Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "NIT Surathkal - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management - Noida Campus",
    "Course": "Post Graduate Program in Sustainable Management (PGP-SM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Banking Finance and Allied Services",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance and Human Resource Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Sport Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Universal AI University",
    "Course": "PGDM in Foreign with Collaboration Cardiff Metropolitan University, UK",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Entrepreneurship and Venture Creation",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICOFP Mumbai - International College of Financial Planning",
    "Course": "MBA in Financial Planning",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Human Resource Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in International Finance and Accounting integrated with ACCA, UK",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Human Resource Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Operations and Systems Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Luxury and Fashion Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Aviation and Aerospace Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Digital Business integrated with HETIC France School of Digital Leadership",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "LPU - Lovely Professional University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School, Mumbai",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "RICS School of Built Environment, Amity University, Noida",
    "Course": "MBA in Construction Project Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Supply Chain Analytics and Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "GD Goenka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "SRIRAM Group of Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "IBMR-IBS College, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School (IBS), Gurgaon",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School (IBS), Pune",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ICFAI Business School (IBS), Bangalore",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "MATS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Lucknow",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Natural Resources and Sustainable Development",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "DOMS IIT Roorkee",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.3,
    "Category": "SC"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity Global Business School, Noida",
    "Course": "Post Graduate Program in Management (PGPM) + Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Gandhinagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Media Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "SSN School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management, Nirma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "MICA",
    "Course": "PGDM in Communications",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Doon Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "International Business School, Pune",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Indian Institute of Forest Management",
    "Course": "MBA in Forestry Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Kolkata",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Asian Business School (ABS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "SGIT School of Management, Sanskar Educational Group",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IILM Institute For Higher Education",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "The Indian Institute of Financial Planning",
    "Course": "Post Graduate Diploma in Management (PGDM Plus)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "JIMS Kalkaji - Jagannath International Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBM ( Business Management)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Jaipur",
    "Course": "PGDM + SAP with Business Analytics",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "DoMS IIT Madras - Department of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Trinity College for Management and Technology",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Camellia School of Business Management - CSBM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "AIMED - Aachi Institute of Management and Entrepreneurial Development",
    "Course": "PGDM in Entrepreneurial Development (PGDME)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IIKM- The Corporate B School",
    "Course": "Work Integrated MBA Digital Marketing and E-Commerce",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "MBA in Business Analytics",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Sharda School of Business Studies",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (One Year)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Course": "MBA in International Business",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Lotus Business School",
    "Course": "MBA in Agri Business Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "FOSTIIMA Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Computer Application",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Finance (Corporate Finance, Investment Finance)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IIEBM - Indus Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Kochi Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "MBA + M.S. in Business Analytics Dual Degree Programme with University at Buffalo, USA",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Eastern Institute for Integrated Learning in Management - EIILM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "SoM, Pandit Deendayal Energy University (PDEU)",
    "Course": "MBA in General Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Delhi School of Management, DTU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Gurugram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Christ - Lavasa, Pune Campus",
    "Course": "Discontinued (June 2023) Post Graduate Diploma in Retail and Omnichannel",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Christ College, Pune",
    "Course": "Bachelor of Business Administration",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Kohinoor Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "National Institute of Bank Management",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "ICBM-School of Business Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM) (Triple Specialization)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPFM (Financial Management)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPDM (Digital Marketing)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBA and DS (Business Analytics and Data Science)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Dhruva College Of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "SMS - School of Management Sciences, Varanasi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "The NorthCap University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "IIRM Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "SVIMS Business School",
    "Course": "PGDM in Marketing",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPEX (Executive Management)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPSCM (Supply Chain Management)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Krupanidhi College of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "I Business Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Myra School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Garden City University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 35,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "AIM - Army Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Srusti Academy of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Indian Institute of Social Welfare and Business Management",
    "Course": "Master of Business Administration (MBA) (Day)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "International School of Management Sciences - ISMS",
    "Course": "Post Graduate Program in Management",
    "Score": 40,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 35,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "IIHMR University",
    "Course": "MBA in Hospital and Health Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "DY Patil University School of Management",
    "Course": "MBA in Finance Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "MDSU - Maharshi Dayanand Saraswati University",
    "Course": "MBA in Service Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "KIET Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "PIET - Panipat Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "OPJS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "KIIT School of Rural Management (KSRM)",
    "Course": "MBA in Rural Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "University of Engineering and Management, Jaipur",
    "Course": "MBA (Regular with Dual Specialization)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Taxila Business School",
    "Course": "PGDM in Business Analytics with Triple Specialization",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "GIMT - Gandhi Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "NITTE School of Management - NSOM",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Adamas University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "FLAME University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Bennett University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Sports & Management",
    "Course": "Master's Degree in Sports Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "PDM University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Centurion University of Technology and Management, Bhubaneswar Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Ramaiah Institute of Management Studies- RIMS",
    "Course": "Master of Business Administration (MBA) (Industry Integrated)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "SKFGI - Supreme Knowledge Foundation Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Dr K.N Modi University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Discontinued (Oct 2024)- Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "BBIT - Budge Budge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Raffles University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Poddar Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "GLF Business School",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Narayana Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (Dual Specialization)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Sister Nivedita University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "MIMA Institute of Management",
    "Course": "PGDM in Business Administration",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Faculty of Management Studies, Manav Rachna International Institute of Research and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Athena School of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Teri School of Advanced Studies (TSAS)",
    "Course": "Discontinued (Jan 2024) - MBA in Infrastructure Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Aravali College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Pioneer Institute of Professional Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "HLM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "SOA University - Siksha 'O' Anusandhan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Gautam Buddha University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Shobhit Institute of Engineering and Technology",
    "Course": "MBA in Finance",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Atharva School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Xavier School of Human Resource Management, Xavier University Bhubaneswar",
    "Course": "MBA in Human Resource Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Shridhar University (SU)",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "G.L. Bajaj Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Indore Indira Business School",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Arya College of Engineering and Information Technology",
    "Course": "MBA in Human Resource and Development",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Manipal University, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "GNIOT Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "Jagannath University Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "SC"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "MBA in Food and Agri Business Management",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Executives (MBA-PGPX)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Enterprise Management (PGPEM)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Executive Post Graduate Programme in Management (EPGPM)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "ePost Graduate Diploma in Advanced Business Analytics",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Discontinued (Sep 2024) - Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Programme in Human Resource Management (PGP-HRM)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Public Policy and Management (PGPPM)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive PGP for Visionary Leadership in Manufacturing",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "Department of Management Studies, IIT Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.04,
    "Category": "ST"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Post Graduate Program in Agribusiness Management (PGP-ABM)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Discontinued (Feb 2023)- Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (EPGP)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Business Leadership (PGP-BL)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Finance Management (PGP-F)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Executive PG Diploma in Management and Artificial Intelligence Online",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96,
    "Category": "ST"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Human Resources",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (ePGP)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Liberal Studies and Management (PGP-LSM)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGP)",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "MBA in Entrepreneurship and Innovation",
    "Score": 97,
    "Category": "ST"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) (Delhi)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Human Resources (PGPM-HR)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Human Resource Management",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post-Graduate Program in Management for the Centrally Funded Technical Ins",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program for Experienced Professionals (PGPEx)",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program in Digital Governance and Management (PGPDGM)",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Global Supply Chain Management",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Hospital & Healthcare Management",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Working Executive (PGPMWE)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "MBA in Analytics",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive MBA in Analytics",
    "Score": 94,
    "Category": "ST"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Digital Enterprise Management",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Online Post Graduate Diploma in Business Administration for Working Executives",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Digital Business Management",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) for Working Professionals",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Financial Management",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Discontinued (May 2022)- Executive PG Diploma Program in Management (ePGPx)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Executive Post Graduate Diploma in Sports Management (EPGDSM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "MBA in Tourism and Hospitality Management",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Marketing",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Executive Master of Business Management",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Executive MBA in Digital Transformation and Analytics",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 88,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 91,
    "Category": "ST"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Master of Business Administration (Working Executives)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program for Executives (Managing Business in Emerging Economies) - PGPEx (MBEE)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "MBA in Hospital Administration and Healthcare Management",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 88,
    "Category": "ST"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Management (PGPM)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "MBA in Human Resource Management",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Manipur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Executive Post Graduate Diploma in Management (EPGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "NIT Trichy - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School (IBS), Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Development Management (PGPDM)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Banaras Hindu University",
    "Course": "Post Graduate Diploma in Business Administration (PGDBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "BITS School of Management (BITSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "PGDM in Business Management",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Shiva Institute of Management Studies (SIMS Ghaziabad)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Prin L.N. Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School (IBS), Ahmedabad",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "JRE Group of Institutions (JRE)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "PGDM in Fintech",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "IIT Kanpur - Department of Industrial and Management Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "The ICFAI University, Mizoram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Infinity Business School",
    "Course": "Post Graduate Programs in Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Asia-Pacific Institute of Management, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM) (General)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Jaipur National University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Insurance and Banking Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Biotechnology Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Vignan's Foundation for Science, Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies, BHU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Lovely Professional University, Admission Office",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Competitive Intelligence and Strategic Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Asia Pacific Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICOFP Mumbai - International College of Financial Planning",
    "Course": "MBA in Financial Planning",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Human Resource Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Hospitality Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Agriculture and Food Business",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Jagran Institute of Communication and Management (JICM)",
    "Course": "PGDM in Marketing Communication Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Universal AI University",
    "Course": "Post Graduate Diploma in Management (PGDM General)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Raipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "GNA University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "NIT Surathkal - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Entrepreneurship and Venture Creation",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Banking Finance and Allied Services",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in International Finance and Accounting integrated with ACCA, UK",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Human Resource Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Operations and Systems Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Luxury and Fashion Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Aviation and Aerospace Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "LPU - Lovely Professional University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance and Human Resource Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Sport Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Universal AI University",
    "Course": "PGDM in Foreign with Collaboration Cardiff Metropolitan University, UK",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "SRIRAM Group of Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "IBMR-IBS College, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School, Mumbai",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "RICS School of Built Environment, Amity University, Noida",
    "Course": "MBA in Construction Project Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "MATS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Lucknow",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Natural Resources and Sustainable Development",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School (IBS), Gurgaon",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School (IBS), Pune",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ICFAI Business School (IBS), Bangalore",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity Global Business School, Noida",
    "Course": "Post Graduate Program in Management (PGPM) + Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Gandhinagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Media Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Supply Chain Analytics and Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Digital Business integrated with HETIC France School of Digital Leadership",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management, Nirma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "SGIT School of Management, Sanskar Educational Group",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Doon Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "The Indian Institute of Financial Planning",
    "Course": "Post Graduate Diploma in Management (PGDM Plus)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Kolkata",
    "Course": "PGDM in Marketing",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "GD Goenka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "SSN School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Management Development Institute",
    "Course": "PGDM in International Business + Master in Management (MIM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IILM Institute For Higher Education",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "International Business School, Pune",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "JIMS Kalkaji - Jagannath International Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBM ( Business Management)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Asian Business School (ABS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (One Year)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management - Noida Campus",
    "Course": "Post Graduate Program in Sustainable Management (PGP-SM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "AIMED - Aachi Institute of Management and Entrepreneurial Development",
    "Course": "PGDM in Entrepreneurial Development (PGDME)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IIKM- The Corporate B School",
    "Course": "Work Integrated MBA Digital Marketing and E-Commerce",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IIEBM - Indus Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Jagan Institute of Management Studies, Jaipur",
    "Course": "PGDM + SAP with Business Analytics",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Course": "MBA in International Business",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Lotus Business School",
    "Course": "MBA in Agri Business Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "FOSTIIMA Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Computer Application",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Kochi Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Christ College, Pune",
    "Course": "Bachelor of Business Administration",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "SoM, Pandit Deendayal Energy University (PDEU)",
    "Course": "MBA in General Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Christ - Lavasa, Pune Campus",
    "Course": "Discontinued (June 2023) Post Graduate Diploma in Retail and Omnichannel",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Finance (Corporate Finance, Investment Finance)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "MBA + M.S. in Business Analytics Dual Degree Programme with University at Buffalo, USA",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Eastern Institute for Integrated Learning in Management - EIILM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "MBA in Business Analytics",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Sharda School of Business Studies",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Faculty of Management Studies, University of Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.1,
    "Category": "ST"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "DoMS IIT Madras - Department of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Management",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "MICA",
    "Course": "PGDM in Communications",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "National Institute of Bank Management",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Indian Institute of Forest Management",
    "Course": "MBA in Forestry Management",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "SMS - School of Management Sciences, Varanasi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "The NorthCap University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPFM (Financial Management)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Delhi School of Management, DTU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Kohinoor Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "ICBM-School of Business Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM) (Triple Specialization)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "DOMS IIT Roorkee",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Trinity College for Management and Technology",
    "Course": "Post Graduate Diploma in Management",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "IIRM Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Camellia School of Business Management - CSBM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPDM (Digital Marketing)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPSCM (Supply Chain Management)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBA and DS (Business Analytics and Data Science)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Dhruva College Of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "SVIMS Business School",
    "Course": "PGDM in Marketing",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPEX (Executive Management)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Gurugram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Krupanidhi College of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "I Business Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Myra School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "International School of Management Sciences - ISMS",
    "Course": "Post Graduate Program in Management",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 35,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "AIM - Army Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Srusti Academy of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Garden City University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 35,
    "Category": "ST"
  },
  {
    "Name": "Indian Institute of Social Welfare and Business Management",
    "Course": "Master of Business Administration (MBA) (Day)",
    "Score": 35,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "IIHMR University",
    "Course": "MBA in Hospital and Health Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "DY Patil University School of Management",
    "Course": "MBA in Finance Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "MDSU - Maharshi Dayanand Saraswati University",
    "Course": "MBA in Service Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "KIET Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "PIET - Panipat Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "OPJS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "KIIT School of Rural Management (KSRM)",
    "Course": "MBA in Rural Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "University of Engineering and Management, Jaipur",
    "Course": "MBA (Regular with Dual Specialization)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Taxila Business School",
    "Course": "PGDM in Business Analytics with Triple Specialization",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "GIMT - Gandhi Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "NITTE School of Management - NSOM",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Adamas University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "FLAME University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Bennett University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Sports & Management",
    "Course": "Master's Degree in Sports Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "PDM University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Centurion University of Technology and Management, Bhubaneswar Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Ramaiah Institute of Management Studies- RIMS",
    "Course": "Master of Business Administration (MBA) (Industry Integrated)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "SKFGI - Supreme Knowledge Foundation Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Dr K.N Modi University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Discontinued (Oct 2024)- Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "BBIT - Budge Budge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Raffles University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Poddar Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "GLF Business School",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Narayana Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (Dual Specialization)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Sister Nivedita University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "MIMA Institute of Management",
    "Course": "PGDM in Business Administration",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Faculty of Management Studies, Manav Rachna International Institute of Research and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Athena School of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Teri School of Advanced Studies (TSAS)",
    "Course": "Discontinued (Jan 2024) - MBA in Infrastructure Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Aravali College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Pioneer Institute of Professional Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "HLM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "SOA University - Siksha 'O' Anusandhan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Gautam Buddha University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Shobhit Institute of Engineering and Technology",
    "Course": "MBA in Finance",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Atharva School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Xavier School of Human Resource Management, Xavier University Bhubaneswar",
    "Course": "MBA in Human Resource Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Shridhar University (SU)",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "G.L. Bajaj Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Indore Indira Business School",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Arya College of Engineering and Information Technology",
    "Course": "MBA in Human Resource and Development",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Manipal University, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "GNIOT Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "Jagannath University Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "ST"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "MBA in Food and Agri Business Management",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Executives (MBA-PGPX)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Enterprise Management (PGPEM)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Executive Post Graduate Programme in Management (EPGPM)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "ePost Graduate Diploma in Advanced Business Analytics",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Discontinued (Sep 2024) - Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Programme in Human Resource Management (PGP-HRM)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Public Policy and Management (PGPPM)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive PGP for Visionary Leadership in Manufacturing",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "Department of Management Studies, IIT Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.04,
    "Category": "PWD"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Post Graduate Program in Agribusiness Management (PGP-ABM)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Discontinued (Feb 2023)- Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (EPGP)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Business Leadership (PGP-BL)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Executive PG Diploma in Management and Artificial Intelligence Online",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Finance Management (PGP-F)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96,
    "Category": "PWD"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Human Resources",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (ePGP)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Liberal Studies and Management (PGP-LSM)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "MBA in Entrepreneurship and Innovation",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGP)",
    "Score": 97,
    "Category": "PWD"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) (Delhi)",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Human Resources (PGPM-HR)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "MBA in Analytics",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Working Executive (PGPMWE)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post-Graduate Program in Management for the Centrally Funded Technical Ins",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program for Experienced Professionals (PGPEx)",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program in Digital Governance and Management (PGPDGM)",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Hospital & Healthcare Management",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) for Working Professionals",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive MBA in Analytics",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Human Resource Management",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 94,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Digital Enterprise Management",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Online Post Graduate Diploma in Business Administration for Working Executives",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Digital Business Management",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Global Supply Chain Management",
    "Score": 92,
    "Category": "PWD"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Financial Management",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Discontinued (May 2022)- Executive PG Diploma Program in Management (ePGPx)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Executive Post Graduate Diploma in Sports Management (EPGDSM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "MBA in Tourism and Hospitality Management",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "MBA in Hospital Administration and Healthcare Management",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program for Executives (Managing Business in Emerging Economies) - PGPEx (MBEE)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 88,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 91,
    "Category": "PWD"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Master of Business Administration (Working Executives)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Executive Master of Business Management",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Executive MBA in Digital Transformation and Analytics",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Marketing",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 88,
    "Category": "PWD"
  },
  {
    "Name": "Faculty of Management Studies, University of Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.9,
    "Category": "PWD"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Management (PGPM)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School (IBS), Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Development Management (PGPDM)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Shiva Institute of Management Studies (SIMS Ghaziabad)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "MBA in Human Resource Management",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Manipur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "BITS School of Management (BITSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "PGDM in Business Management",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Banaras Hindu University",
    "Course": "Post Graduate Diploma in Business Administration (PGDBA)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Executive Post Graduate Diploma in Management (EPGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "NIT Trichy - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Prin L.N. Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "IIT Kanpur - Department of Industrial and Management Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Management Development Institute",
    "Course": "PGDM in International Business + Master in Management (MIM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School (IBS), Ahmedabad",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "PGDM in Fintech",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "The ICFAI University, Mizoram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Infinity Business School",
    "Course": "Post Graduate Programs in Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Asia-Pacific Institute of Management, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM) (General)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "JRE Group of Institutions (JRE)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Biotechnology Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Vignan's Foundation for Science, Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies, BHU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Lovely Professional University, Admission Office",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Competitive Intelligence and Strategic Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Asia Pacific Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "NIT Surathkal - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Jaipur National University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Insurance and Banking Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Hospitality Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Agriculture and Food Business",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Jagran Institute of Communication and Management (JICM)",
    "Course": "PGDM in Marketing Communication Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Universal AI University",
    "Course": "Post Graduate Diploma in Management (PGDM General)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Raipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "GNA University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Entrepreneurship and Venture Creation",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICOFP Mumbai - International College of Financial Planning",
    "Course": "MBA in Financial Planning",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Human Resource Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in International Finance and Accounting integrated with ACCA, UK",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Human Resource Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Operations and Systems Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Luxury and Fashion Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Aviation and Aerospace Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management - Noida Campus",
    "Course": "Post Graduate Program in Sustainable Management (PGP-SM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Banking Finance and Allied Services",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance and Human Resource Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Sport Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Universal AI University",
    "Course": "PGDM in Foreign with Collaboration Cardiff Metropolitan University, UK",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Supply Chain Analytics and Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "GD Goenka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "LPU - Lovely Professional University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School, Mumbai",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "RICS School of Built Environment, Amity University, Noida",
    "Course": "MBA in Construction Project Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Digital Business integrated with HETIC France School of Digital Leadership",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "SRIRAM Group of Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "IBMR-IBS College, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School (IBS), Gurgaon",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School (IBS), Pune",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ICFAI Business School (IBS), Bangalore",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "MATS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Lucknow",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Natural Resources and Sustainable Development",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "SSN School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management, Nirma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "MICA",
    "Course": "PGDM in Communications",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity Global Business School, Noida",
    "Course": "Post Graduate Program in Management (PGPM) + Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Gandhinagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Media Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "SGIT School of Management, Sanskar Educational Group",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IILM Institute For Higher Education",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "The Indian Institute of Financial Planning",
    "Course": "Post Graduate Diploma in Management (PGDM Plus)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "JIMS Kalkaji - Jagannath International Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBM ( Business Management)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Jagan Institute of Management Studies, Jaipur",
    "Course": "PGDM + SAP with Business Analytics",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "DoMS IIT Madras - Department of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Doon Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "International Business School, Pune",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Indian Institute of Forest Management",
    "Course": "MBA in Forestry Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Kolkata",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Asian Business School (ABS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Trinity College for Management and Technology",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (One Year)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Course": "MBA in International Business",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Lotus Business School",
    "Course": "MBA in Agri Business Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "FOSTIIMA Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Computer Application",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Finance (Corporate Finance, Investment Finance)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IIEBM - Indus Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Camellia School of Business Management - CSBM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "AIMED - Aachi Institute of Management and Entrepreneurial Development",
    "Course": "PGDM in Entrepreneurial Development (PGDME)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IIKM- The Corporate B School",
    "Course": "Work Integrated MBA Digital Marketing and E-Commerce",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "MBA in Business Analytics",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Sharda School of Business Studies",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Kochi Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Christ College, Pune",
    "Course": "Bachelor of Business Administration",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Kohinoor Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "National Institute of Bank Management",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ICBM-School of Business Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM) (Triple Specialization)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "MBA + M.S. in Business Analytics Dual Degree Programme with University at Buffalo, USA",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Eastern Institute for Integrated Learning in Management - EIILM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "SoM, Pandit Deendayal Energy University (PDEU)",
    "Course": "MBA in General Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Delhi School of Management, DTU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Amity University, Gurugram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Christ - Lavasa, Pune Campus",
    "Course": "Discontinued (June 2023) Post Graduate Diploma in Retail and Omnichannel",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "SMS - School of Management Sciences, Varanasi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "The NorthCap University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPFM (Financial Management)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPDM (Digital Marketing)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBA and DS (Business Analytics and Data Science)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Dhruva College Of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "DOMS IIT Roorkee",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "IIRM Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "SVIMS Business School",
    "Course": "PGDM in Marketing",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPEX (Executive Management)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPSCM (Supply Chain Management)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Krupanidhi College of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "I Business Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Myra School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Garden City University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 35,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "AIM - Army Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Srusti Academy of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Indian Institute of Social Welfare and Business Management",
    "Course": "Master of Business Administration (MBA) (Day)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "International School of Management Sciences - ISMS",
    "Course": "Post Graduate Program in Management",
    "Score": 40,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 35,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "IIHMR University",
    "Course": "MBA in Hospital and Health Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "DY Patil University School of Management",
    "Course": "MBA in Finance Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "MDSU - Maharshi Dayanand Saraswati University",
    "Course": "MBA in Service Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "KIET Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "PIET - Panipat Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "OPJS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "KIIT School of Rural Management (KSRM)",
    "Course": "MBA in Rural Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "University of Engineering and Management, Jaipur",
    "Course": "MBA (Regular with Dual Specialization)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Taxila Business School",
    "Course": "PGDM in Business Analytics with Triple Specialization",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "GIMT - Gandhi Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "NITTE School of Management - NSOM",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Adamas University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "FLAME University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Bennett University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Sports & Management",
    "Course": "Master's Degree in Sports Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "PDM University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Centurion University of Technology and Management, Bhubaneswar Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Ramaiah Institute of Management Studies- RIMS",
    "Course": "Master of Business Administration (MBA) (Industry Integrated)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "SKFGI - Supreme Knowledge Foundation Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Dr K.N Modi University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Discontinued (Oct 2024)- Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "BBIT - Budge Budge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Raffles University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Poddar Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "GLF Business School",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Narayana Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (Dual Specialization)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Sister Nivedita University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "MIMA Institute of Management",
    "Course": "PGDM in Business Administration",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Faculty of Management Studies, Manav Rachna International Institute of Research and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Athena School of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Teri School of Advanced Studies (TSAS)",
    "Course": "Discontinued (Jan 2024) - MBA in Infrastructure Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Aravali College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Pioneer Institute of Professional Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "HLM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "SOA University - Siksha 'O' Anusandhan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Gautam Buddha University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Shobhit Institute of Engineering and Technology",
    "Course": "MBA in Finance",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Atharva School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Xavier School of Human Resource Management, Xavier University Bhubaneswar",
    "Course": "MBA in Human Resource Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Shridhar University (SU)",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "G.L. Bajaj Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Indore Indira Business School",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Arya College of Engineering and Information Technology",
    "Course": "MBA in Human Resource and Development",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Manipal University, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "GNIOT Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "Jagannath University Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "PWD"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "MBA in Food and Agri Business Management",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Executives (MBA-PGPX)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Enterprise Management (PGPEM)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Executive Post Graduate Programme in Management (EPGPM)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "Faculty of Management Studies, University of Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98,
    "Category": "EWS"
  },
  {
    "Name": "Management Development Institute",
    "Course": "PGDM in International Business + Master in Management (MIM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Post Graduate Program in Agribusiness Management (PGP-ABM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "Post Graduate Program in Public Policy and Management (PGPPM)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Calcutta - Indian Institute of Management",
    "Course": "Executive PGP for Visionary Leadership in Manufacturing",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bangalore - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ahmedabad - Indian Institute of Management",
    "Course": "ePost Graduate Diploma in Advanced Business Analytics",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "Department of Management Studies, IIT Delhi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.04,
    "Category": "EWS"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 97.5,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "Discontinued (Sep 2024) - Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Discontinued (Feb 2023)- Post Graduate Program in Management (PGPM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management",
    "Course": "MBA in Entrepreneurship and Innovation",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGP)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Post Graduate Programme in Human Resource Management (PGP-HRM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Business Leadership (PGP-BL)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96,
    "Category": "EWS"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) (Delhi)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "DOMS IIT Roorkee",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Indore - Indian Institute of Management",
    "Course": "Executive PG Diploma in Management and Artificial Intelligence Online",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Finance Management (PGP-F)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Lucknow - Indian Institute of Management - Noida Campus",
    "Course": "Post Graduate Program in Sustainable Management (PGP-SM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (EPGP)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kozhikode - Indian Institute of Management",
    "Course": "Post Graduate Program in Liberal Studies and Management (PGP-LSM)",
    "Score": 97,
    "Category": "EWS"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96,
    "Category": "EWS"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "MBA in Human Resources",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Nagpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Amritsar - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Business Analytics",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Post Graduate Program in Management for Working Executive (PGPMWE)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Human Resources (PGPM-HR)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Online Post Graduate Diploma in Business Administration for Working Executives",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Digital Business Management",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Sambalpur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "MBA in Analytics",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Kashipur - Indian Institute of Management",
    "Course": "Executive MBA in Analytics",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Trichy - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "MBA in Human Resource Management",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Ranchi - Indian Institute of Management",
    "Course": "Executive MBA",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Raipur - Indian Institute of Management",
    "Course": "Executive Post Graduate Program in Management (ePGP)",
    "Score": 94,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "MBA in Hospital & Healthcare Management",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Executive Master of Business Administration (EMBA) for Working Professionals",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post-Graduate Program in Management for the Centrally Funded Technical Ins",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program for Experienced Professionals (PGPEx)",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Visakhapatnam - Indian Institute of Management",
    "Course": "Post Graduate Program in Digital Governance and Management (PGPDGM)",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIT Kanpur - Department of Industrial and Management Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Financial Management",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Executive Master of Business Management",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Executive MBA in Digital Transformation and Analytics",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Global Supply Chain Management",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Bodh Gaya - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "IIM Udaipur - Indian Institute of Management",
    "Course": "MBA in Digital Enterprise Management",
    "Score": 92,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 91,
    "Category": "EWS"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Shailesh J. Mehta School of Management, IIT Bombay",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "MBA in Hospital Administration and Healthcare Management",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Marketing",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Jammu - Indian Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Master of Business Administration (Working Executives)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 88,
    "Category": "EWS"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86,
    "Category": "EWS"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Management (PGPM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School (IBS), Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "IIM Shillong - Indian Institute of Management",
    "Course": "Post Graduate Program for Executives (Managing Business in Emerging Economies) - PGPEx (MBEE)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Discontinued (May 2022)- Executive PG Diploma Program in Management (ePGPx)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Rohtak - Indian Institute of Management",
    "Course": "Executive Post Graduate Diploma in Sports Management (EPGDSM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IIM Sirmaur - Indian Institute of Management",
    "Course": "MBA in Tourism and Hospitality Management",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 88,
    "Category": "EWS"
  },
  {
    "Name": "DoMS IIT Madras - Department of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.11,
    "Category": "EWS"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "University Business School, Chandigarh",
    "Course": "MBA in Human Resource Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "BITS School of Management (BITSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "PGDM in Business Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Banaras Hindu University",
    "Course": "Post Graduate Diploma in Business Administration (PGDBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Delhi School of Management, DTU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Programme in Development Management (PGPDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Executive Post Graduate Diploma in Management (EPGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Manipur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "VGSOM IIT Kharagpur - Vinod Gupta School of Management",
    "Course": "Post Graduate Diploma in Business Analytics (PGDBA)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "MICA",
    "Course": "PGDM in Communications",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Shiva Institute of Management Studies (SIMS Ghaziabad)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Indian Institute of Forest Management",
    "Course": "MBA in Forestry Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "NIT Trichy - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "MBA in Business Analytics",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "PGDM in Fintech",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "IIT Dhanbad (ISM) - Indian Institute of Technology (ISM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Camellia School of Business Management - CSBM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Prin L.N. Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Gurugram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Trinity College for Management and Technology",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School (IBS), Ahmedabad",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Asia-Pacific Institute of Management, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM) (General)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Jaipur National University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Digital Business integrated with HETIC France School of Digital Leadership",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "SRIRAM Group of Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "IBMR-IBS College, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "The ICFAI University, Mizoram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Infinity Business School",
    "Course": "Post Graduate Programs in Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "JRE Group of Institutions (JRE)",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Supply Chain Analytics and Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "GD Goenka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "LPU - Lovely Professional University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School (IBS), Gurgaon",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School (IBS), Pune",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School (IBS), Bangalore",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity Global Business School, Noida",
    "Course": "Post Graduate Program in Management (PGPM) + Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Gandhinagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Media Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity International Business School (AIBS), Amity University, Noida",
    "Course": "MBA in International Business",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School, Mumbai",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICFAI Business School, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "RICS School of Built Environment, Amity University, Noida",
    "Course": "MBA in Construction Project Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "MATS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Lucknow",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Natural Resources and Sustainable Development",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Insurance and Banking Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Hospitality Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Biotechnology Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Vignan's Foundation for Science, Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Studies, BHU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Lovely Professional University, Admission Office",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Competitive Intelligence and Strategic Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Asia Pacific Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ICOFP Mumbai - International College of Financial Planning",
    "Course": "MBA in Financial Planning",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Human Resource Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in International Finance and Accounting integrated with ACCA, UK",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Agriculture and Food Business",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Jagran Institute of Communication and Management (JICM)",
    "Course": "PGDM in Marketing Communication Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Universal AI University",
    "Course": "Post Graduate Diploma in Management (PGDM General)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Raipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "GNA University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "NIT Surathkal - National Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Entrepreneurship and Venture Creation",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Banking Finance and Allied Services",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Marketing and Human Resource Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Operations and Systems Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Luxury and Fashion Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Aviation and Aerospace Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Intelligence and Data Analytics",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "SSN School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "SGIT School of Management, Sanskar Educational Group",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Finance and Human Resource Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "CMS Business School, Jain Deemed-to-be University, Bangalore",
    "Course": "MBA in Sport Management in partnership with MBA ESG India",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in International Business with Artificial Intelligence",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Universal AI University",
    "Course": "PGDM in Foreign with Collaboration Cardiff Metropolitan University, UK",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Noida",
    "Course": "MBA in Business Analytics",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management, Nirma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "IILM Institute For Higher Education",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Doon Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "The Indian Institute of Financial Planning",
    "Course": "Post Graduate Diploma in Management (PGDM Plus)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Kolkata",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Asian Business School (ABS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Sharda School of Business Studies",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "IIEBM - Indus Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Christ College, Pune",
    "Course": "Bachelor of Business Administration",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "International Business School, Pune",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "JIMS Kalkaji - Jagannath International Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBM ( Business Management)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Jagan Institute of Management Studies, Jaipur",
    "Course": "PGDM + SAP with Business Analytics",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Justice K S Hegde Institute of Management",
    "Course": "MBA in Finance (Corporate Finance, Investment Finance)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Kochi Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "MBA + M.S. in Business Analytics Dual Degree Programme with University at Buffalo, USA",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Eastern Institute for Integrated Learning in Management - EIILM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "SoM, Pandit Deendayal Energy University (PDEU)",
    "Course": "MBA in General Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Marketing",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (One Year)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Course": "MBA in International Business",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Lotus Business School",
    "Course": "MBA in Agri Business Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "FOSTIIMA Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Computer Application",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Kohinoor Management School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "National Institute of Bank Management",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Christ - Lavasa, Pune Campus",
    "Course": "Discontinued (June 2023) Post Graduate Diploma in Retail and Omnichannel",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Bangalore",
    "Course": "PGDM in Human Resource Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "AIMED - Aachi Institute of Management and Entrepreneurial Development",
    "Course": "PGDM in Entrepreneurial Development (PGDME)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "IIKM- The Corporate B School",
    "Course": "Work Integrated MBA Digital Marketing and E-Commerce",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Indian Institute of Social Welfare and Business Management",
    "Course": "Master of Business Administration (MBA) (Day)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "ICBM-School of Business Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM) (Triple Specialization)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "SMS - School of Management Sciences, Varanasi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPBA and DS (Business Analytics and Data Science)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "The NorthCap University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "IIRM Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "SVIMS Business School",
    "Course": "PGDM in Marketing",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPEX (Executive Management)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Amrita School of Business, Amrita Vishwa Vidyapeetham - Bengaluru Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPSCM (Supply Chain Management)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPFM (Financial Management)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Bengal Institute of Business Studies",
    "Course": "MBA - PGPDM (Digital Marketing)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Dhruva College Of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Myra School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "International School of Management Sciences - ISMS",
    "Course": "Post Graduate Program in Management",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Krupanidhi College of Management, Krupanidhi Group of Institutions",
    "Course": "PGDM in Healthcare Analytics",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "I Business Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "AIM - Army Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Srusti Academy of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Garden City University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "IIHMR University",
    "Course": "MBA in Hospital and Health Management",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "DY Patil University School of Management",
    "Course": "MBA in Finance Management",
    "Score": 40,
    "Category": "EWS"
  },
  {
    "Name": "MDSU - Maharshi Dayanand Saraswati University",
    "Course": "MBA in Service Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "KIET Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "PIET - Panipat Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "SKFGI - Supreme Knowledge Foundation Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Dr K.N Modi University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Discontinued (Oct 2024)- Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Regional College of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Taxila Business School",
    "Course": "PGDM in Business Analytics with Triple Specialization",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "MIMA Institute of Management",
    "Course": "PGDM in Business Administration",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Faculty of Management Studies, Manav Rachna International Institute of Research and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "BBIT - Budge Budge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Athena School of Management",
    "Course": "Post Graduate Program in Management (PGPM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Teri School of Advanced Studies (TSAS)",
    "Course": "Discontinued (Jan 2024) - MBA in Infrastructure Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Aravali College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Pioneer Institute of Professional Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "HLM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "SOA University - Siksha 'O' Anusandhan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Gautam Buddha University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Shobhit Institute of Engineering and Technology",
    "Course": "MBA in Finance",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Atharva School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Xavier School of Human Resource Management, Xavier University Bhubaneswar",
    "Course": "MBA in Human Resource Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Shridhar University (SU)",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "G.L. Bajaj Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Indore Indira Business School",
    "Course": "Post Graduate Diploma in Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Arya College of Engineering and Information Technology",
    "Course": "MBA in Human Resource and Development",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Manipal University, Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "GNIOT Institute of Management Studies",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Jagannath University Jaipur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "GIMT - Gandhi Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "NITTE School of Management - NSOM",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Adamas University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "FLAME University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Raffles University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Poddar Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "GLF Business School",
    "Course": "MBA in Marketing",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Narayana Business School",
    "Course": "Post Graduate Diploma in Management (PGDM) (Dual Specialization)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Sister Nivedita University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "OPJS University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "KIIT School of Rural Management (KSRM)",
    "Course": "MBA in Rural Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "University of Engineering and Management, Jaipur",
    "Course": "MBA (Regular with Dual Specialization)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Bennett University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Sports & Management",
    "Course": "Master's Degree in Sports Management",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "PDM University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Centurion University of Technology and Management, Bhubaneswar Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25,
    "Category": "EWS"
  },
  {
    "Name": "Ramaiah Institute of Management Studies- RIMS",
    "Course": "Master of Business Administration (MBA) (Industry Integrated)",
    "Score": 25,
    "Category": "EWS"
  }
]