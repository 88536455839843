[
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 49
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "MBA in Technology Management",
    "Category": "General",
    "Score": 124
  },
  {
    "Name": "Chaitanya Bharathi Institute of Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 194
  },
  {
    "Name": "Nizam College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 281
  },
  {
    "Name": "Pendekanti Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 749
  },
  {
    "Name": "Amjad Ali Khan College of Business Administration (AAKCBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 799
  },
  {
    "Name": "Keshav Memorial Institute of Technology",
    "Course": "Master of Business Administration(MBA)",
    "Category": "General",
    "Score": 888
  },
  {
    "Name": "Palamuru University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1031
  },
  {
    "Name": "BV Raju Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1092
  },
  {
    "Name": "Bharathi Institute Of Business Management",
    "Course": "Masters in Business Management",
    "Category": "General",
    "Score": 1156
  },
  {
    "Name": "School of Management Studies, Jawaharlal Nehru Technological University, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 97
  },
  {
    "Name": "Badruka College Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 191
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "MBA in Tourism and Travel Management",
    "Category": "General",
    "Score": 199
  },
  {
    "Name": "Institute of Aeronautical Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 528
  },
  {
    "Name": "Satavahana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 765
  },
  {
    "Name": "Osmania University PG College, Narsapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 824
  },
  {
    "Name": "Faculty of Commerce and Business Management- Kakatiya University Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 901
  },
  {
    "Name": "Vidya Jyothi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1065
  },
  {
    "Name": "Pragati Mahavidyalaya Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1108
  },
  {
    "Name": "Bhavan's Vivekananda College of Science, Humanities and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1162
  },
  {
    "Name": "Dr. B.R. Ambedkar Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1260
  },
  {
    "Name": "Anurag University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1330
  },
  {
    "Name": "Teegala Krishna Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1474
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1644
  },
  {
    "Name": "Palamuru University Self Finance",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1860
  },
  {
    "Name": "Vivekananda PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "General",
    "Score": 2054
  },
  {
    "Name": "Marri Laxman Reddy Institute of Technology and Management (MLRITM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2227
  },
  {
    "Name": "Post Graduate College, Siddipet",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2309
  },
  {
    "Name": "CMR College of  Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2322
  },
  {
    "Name": "DRK College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2502
  },
  {
    "Name": "Kakatiya Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1261
  },
  {
    "Name": "Raja Bahadur Venkata Rama Reddy Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1401
  },
  {
    "Name": "V.V.Sangh's Basaveshwara Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1564
  },
  {
    "Name": "Malla Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 1779
  },
  {
    "Name": "Guru Nanak Institutions Technical Campus",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "General",
    "Score": 2003
  },
  {
    "Name": "Methodist College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2166
  },
  {
    "Name": "Shadan Institute of Computer Studies for Boys",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2284
  },
  {
    "Name": "Mahatma Gandhi University College of Commerce and Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2318
  },
  {
    "Name": "Jayamukhi Institute of Technological Sciences (JITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2494
  },
  {
    "Name": "R.G. Kedia College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2723
  },
  {
    "Name": "Nalla Malla Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2756
  },
  {
    "Name": "S. D. Signodia College of Arts and Commerce and PG Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3038
  },
  {
    "Name": "Sai Sudhir College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3173
  },
  {
    "Name": "MLR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3403
  },
  {
    "Name": "J.B. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3448
  },
  {
    "Name": "Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3724
  },
  {
    "Name": "Princeton P.G. College Of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3969
  },
  {
    "Name": "Mother Teresa PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4113
  },
  {
    "Name": "Bharat Institute of Engineering and Technology, Telangana",
    "Course": "Master of Business Management (MBA)",
    "Category": "General",
    "Score": 4262
  },
  {
    "Name": "Pallavi Engineering College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "General",
    "Score": 4282
  },
  {
    "Name": "Aurora's PG College, Panjagutta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 2778
  },
  {
    "Name": "Malla Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3114
  },
  {
    "Name": "Holy Mary Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3192
  },
  {
    "Name": "Vani Niketan Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3404
  },
  {
    "Name": "Noble PG College",
    "Course": "Discontinued (Aug 2021)- Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 3623
  },
  {
    "Name": "CMR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3836
  },
  {
    "Name": "University Arts and Science College, Subedari - Kakatiya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 3999
  },
  {
    "Name": "CMR Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4164
  },
  {
    "Name": "St Marys Centenary Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4263
  },
  {
    "Name": "Avanthi P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4388
  },
  {
    "Name": "KLR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4417
  },
  {
    "Name": "Sai Spurthi Institute of Technology (SSIT, Khammam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4558
  },
  {
    "Name": "Aurora's Post Graduate College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4612
  },
  {
    "Name": "Aurora's PG College, Nampally",
    "Course": "Master of Business Administration(MBA)",
    "Category": "General",
    "Score": 4720
  },
  {
    "Name": "St. Johns Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4854
  },
  {
    "Name": "Kommuri Pratap Reddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4914
  },
  {
    "Name": "Aradhana School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5078
  },
  {
    "Name": "Anu Bose Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5250
  },
  {
    "Name": "Holy Mother P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5277
  },
  {
    "Name": "Holy Mary Institute of Technology & Science",
    "Course": "MBA in Big Data Analytics",
    "Category": "General",
    "Score": 5542
  },
  {
    "Name": "Malla Reddy Institute Of Management (MRIM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4440
  },
  {
    "Name": "Joginpalli B.R.Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4596
  },
  {
    "Name": "TKR Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4665
  },
  {
    "Name": "Gouthami P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4792
  },
  {
    "Name": "Akshara Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 4906
  },
  {
    "Name": "Vijaya Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5077
  },
  {
    "Name": "Malla Reddy Engineering College And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5162
  },
  {
    "Name": "Vivekvardhini School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5270
  },
  {
    "Name": "Pullareddy Institute of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5491
  },
  {
    "Name": "Vijaya Rural Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5573
  },
  {
    "Name": "Aurora's Post Graduate College, Ramanthapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5577
  },
  {
    "Name": "Adarsh Post Graduate College of Computer Science, Mahabubnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5764
  },
  {
    "Name": "Bright Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5903
  },
  {
    "Name": "Aristotle PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6035
  },
  {
    "Name": "PG Centre, Wanaparthy - Palamuru University.",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration",
    "Category": "General",
    "Score": 6232
  },
  {
    "Name": "Visvesvaraya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6308
  },
  {
    "Name": "Guru Nanak Institute of Technology, Telangana",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6359
  },
  {
    "Name": "Mahaveer Institute Of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6423
  },
  {
    "Name": "School of Business Management, Global Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6599
  },
  {
    "Name": "Mantra School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6865
  },
  {
    "Name": "Nava Bharathi College of PG Studies - Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5648
  },
  {
    "Name": "Jaya Prakash Narayan College of Engineering JPNCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5828
  },
  {
    "Name": "Kasireddy Narayan Reddy College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 5953
  },
  {
    "Name": "Padala Rama Reddi College of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6224
  },
  {
    "Name": "RGR Siddhanthi Degree College",
    "Course": "Master of Business Administration",
    "Category": "General",
    "Score": 6297
  },
  {
    "Name": "Kingston PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6320
  },
  {
    "Name": "Suprabhath Institute For Management and Computer Studies",
    "Course": "Masters in Business Studies",
    "Category": "General",
    "Score": 6420
  },
  {
    "Name": "Nishitha Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6465
  },
  {
    "Name": "Mahatma Gandhi University College of Commerce and Business Management",
    "Course": "MBA in Tourism and Travel Management",
    "Category": "General",
    "Score": 6628
  },
  {
    "Name": "Vivekananda Degree And Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 6949
  },
  {
    "Name": "TKR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7015
  },
  {
    "Name": "St. Vincent P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7437
  },
  {
    "Name": "Balaji Institute of Technology and Science (BITS Warangal)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7947
  },
  {
    "Name": "University Post Graduate College, Khammam - Kakatiya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8043
  },
  {
    "Name": "Omega PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8178
  },
  {
    "Name": "St John's PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8429
  },
  {
    "Name": "SR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8796
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8960
  },
  {
    "Name": "Immanuel Business School",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9447
  },
  {
    "Name": "Vaagdevi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9719
  },
  {
    "Name": "Sree Chaitanya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7305
  },
  {
    "Name": "Scient Institute of Technology (SIT, Ibrahimpatnam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7444
  },
  {
    "Name": "CKM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 7961
  },
  {
    "Name": "Ellenki College of Engineering  and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8099
  },
  {
    "Name": "Global Institute of Management, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8364
  },
  {
    "Name": "Sri Indu PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8483
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "General",
    "Score": 8888
  },
  {
    "Name": "DVM College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 8983
  },
  {
    "Name": "Samskruti Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9465
  },
  {
    "Name": "Siddhartha Institute of Engineering and Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9746
  },
  {
    "Name": "Princeton P.G. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 9772
  },
  {
    "Name": "Sri Shivani Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10067
  },
  {
    "Name": "Vidya Dayini College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10175
  },
  {
    "Name": "Sree Chaitanya Institute of Technological Sciences (SCITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10284
  },
  {
    "Name": "Narsimha Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10504
  },
  {
    "Name": "Vijaya School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11125
  },
  {
    "Name": "Jyothishmathi Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11382
  },
  {
    "Name": "St. Xaviers PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11521
  },
  {
    "Name": "Indur Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11705
  },
  {
    "Name": "Vijaya P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11866
  },
  {
    "Name": "St. Joseph's PG College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 10020
  },
  {
    "Name": "Manpower Development College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 10161
  },
  {
    "Name": "Mother Teresa Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10275
  },
  {
    "Name": "KGR Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10378
  },
  {
    "Name": "Reah School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 10664
  },
  {
    "Name": "Vennela Institute Of Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11222
  },
  {
    "Name": "Arjun College of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11436
  },
  {
    "Name": "Siddhartha Institute Of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11687
  },
  {
    "Name": "Sri Indu Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 11768
  },
  {
    "Name": "RKLK PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12015
  },
  {
    "Name": "Samskruti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12119
  },
  {
    "Name": "Vaagdevi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12203
  },
  {
    "Name": "Aurobindo College of Business Management- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12535
  },
  {
    "Name": "Vision P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12851
  },
  {
    "Name": "Crescent College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13534
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13750
  },
  {
    "Name": "Sri Chaitanya Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13845
  },
  {
    "Name": "New Science PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14219
  },
  {
    "Name": "Rousseau Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14689
  },
  {
    "Name": "Brilliant Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14937
  },
  {
    "Name": "Nigama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12153
  },
  {
    "Name": "Pannala Ram Reddy College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12498
  },
  {
    "Name": "Mother Teresa College of Management and Computer Applications",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12747
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology, Hayathnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 12950
  },
  {
    "Name": "University Post Graduate College,Godavarikhani - Satavahana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13672
  },
  {
    "Name": "Abdulkalam Institute of Technological Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13815
  },
  {
    "Name": "Swarna Bharathi Institute of Science and Technology (SBIST, Khammam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 13854
  },
  {
    "Name": "SRM PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 14841
  },
  {
    "Name": "Kakatiya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15366
  },
  {
    "Name": "Vinayaka  College of IT and Business Management, Kondapak",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15396
  },
  {
    "Name": "Dhanvanthari Institute Of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15657
  },
  {
    "Name": "Indur P.G. College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15980
  },
  {
    "Name": "Lal Bahadur College P.G. Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16214
  },
  {
    "Name": "Suprabhat Institute of Computer Studies",
    "Course": "M.B.A. in Business Management",
    "Category": "General",
    "Score": 16875
  },
  {
    "Name": "SRM Degree and PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17359
  },
  {
    "Name": "Trinity College of Engineering and Technology, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17894
  },
  {
    "Name": "Unity P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17974
  },
  {
    "Name": "Madhira Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 18712
  },
  {
    "Name": "P.V. Ram Reddy P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 19329
  },
  {
    "Name": "Kshatriya College of Engineering (KCEA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15553
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 15888
  },
  {
    "Name": "Sree Visvesvaraya Institute of Technology and Science (SVITS, Mahabubnagar)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16187
  },
  {
    "Name": "Avanthi P.G. and Research Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 16580
  },
  {
    "Name": "Vivekananda College of Computer Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17174
  },
  {
    "Name": "Mother Theressa College Of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17720
  },
  {
    "Name": "Bomma Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17938
  },
  {
    "Name": "Stanford P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 17994
  },
  {
    "Name": "Vaageswari College of Engineering",
    "Course": "Masters in Business Management",
    "Category": "General",
    "Score": 19080
  },
  {
    "Name": "Sai Krishna College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 19338
  },
  {
    "Name": "Priyadarshini P.G. College (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 19403
  },
  {
    "Name": "Aurobindo College of Computer Science- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20213
  },
  {
    "Name": "Apoorva Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21251
  },
  {
    "Name": "Sree Dattha Group of Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 22887
  },
  {
    "Name": "Gate Institute of Technology and Sciences",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "General",
    "Score": 25537
  },
  {
    "Name": "SVS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28141
  },
  {
    "Name": "Talla Padmavathi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28595
  },
  {
    "Name": "Sri Gaayathri College of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 30224
  },
  {
    "Name": "Siddhartha Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 19706
  },
  {
    "Name": "Vaagdevi Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 20895
  },
  {
    "Name": "Trinity College of Engineering and Technology,",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 21846
  },
  {
    "Name": "Jaya Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 23305
  },
  {
    "Name": "Ganapathy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 27908
  },
  {
    "Name": "Vinuthna College Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 28568
  },
  {
    "Name": "Alluri Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "General",
    "Score": 29542
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21
  },
  {
    "Name": "Nizam College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 1006
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "MBA in Tourism and Travel Management",
    "Category": "OBC",
    "Score": 1704
  },
  {
    "Name": "Anurag University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 2417
  },
  {
    "Name": "Palamuru University Self Finance",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 3385
  },
  {
    "Name": "Post Graduate College, Siddipet",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 4427
  },
  {
    "Name": "Dr. B.R. Ambedkar Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 4967
  },
  {
    "Name": "V.V.Sangh's Basaveshwara Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 5799
  },
  {
    "Name": "Malla Reddy Engineering College And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 6231
  },
  {
    "Name": "MVSR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 6795
  },
  {
    "Name": "School of Management Studies, Jawaharlal Nehru Technological University, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 209
  },
  {
    "Name": "Badruka College Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 1273
  },
  {
    "Name": "Faculty of Commerce and Business Management- Kakatiya University Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 2115
  },
  {
    "Name": "A.V. College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 2871
  },
  {
    "Name": "CMR College of  Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 4378
  },
  {
    "Name": "Mahatma Gandhi University College of Commerce and Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 4948
  },
  {
    "Name": "St Marys Centenary Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 5663
  },
  {
    "Name": "TKR Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 6084
  },
  {
    "Name": "Institute of Aeronautical Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 6676
  },
  {
    "Name": "Pallavi Engineering College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "OBC",
    "Score": 7434
  },
  {
    "Name": "Marri Laxman Reddy Institute of Technology and Management (MLRITM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 7570
  },
  {
    "Name": "Princeton P.G. College Of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8220
  },
  {
    "Name": "Holy Mary Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8618
  },
  {
    "Name": "Aristotle PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 9366
  },
  {
    "Name": "Methodist College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 9696
  },
  {
    "Name": "Avanthi P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10312
  },
  {
    "Name": "Aradhana School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10701
  },
  {
    "Name": "Omega PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10969
  },
  {
    "Name": "Aurora's PG College, Nampally",
    "Course": "Master of Business Administration(MBA)",
    "Category": "OBC",
    "Score": 11054
  },
  {
    "Name": "KGR Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 11539
  },
  {
    "Name": "Holy Mother P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8015
  },
  {
    "Name": "CMR Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8296
  },
  {
    "Name": "Telangana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 8937
  },
  {
    "Name": "Suprabhath Institute For Management and Computer Studies",
    "Course": "Masters in Business Studies",
    "Category": "OBC",
    "Score": 9650
  },
  {
    "Name": "CMR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 9727
  },
  {
    "Name": "Aurora's PG College, Panjagutta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10349
  },
  {
    "Name": "Kommuri Pratap Reddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 10795
  },
  {
    "Name": "R.G. Kedia College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 11014
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 11170
  },
  {
    "Name": "Manpower Development College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 12253
  },
  {
    "Name": "Malla Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 13346
  },
  {
    "Name": "Princeton P.G. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 14302
  },
  {
    "Name": "Sri Chaitanya Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 14938
  },
  {
    "Name": "Vivekvardhini School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 15644
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "OBC",
    "Score": 15975
  },
  {
    "Name": "SR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 16976
  },
  {
    "Name": "Nigama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 17416
  },
  {
    "Name": "Reah School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 17870
  },
  {
    "Name": "Vivekananda Degree And Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 18472
  },
  {
    "Name": "Global Institute of Management, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 19602
  },
  {
    "Name": "Sree Chaitanya Institute of Technological Sciences (SCITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 13996
  },
  {
    "Name": "Aurora's Post Graduate College, Ramanthapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 14430
  },
  {
    "Name": "Guru Nanak Institutions Technical Campus",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "OBC",
    "Score": 15513
  },
  {
    "Name": "Aurobindo College of Business Management- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 15958
  },
  {
    "Name": "Vignana Bharathi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 16208
  },
  {
    "Name": "Siddhartha Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 17104
  },
  {
    "Name": "Kshatriya College of Engineering (KCEA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 17769
  },
  {
    "Name": "New Science PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 18330
  },
  {
    "Name": "SRM Degree and PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 19318
  },
  {
    "Name": "Samskruti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 20143
  },
  {
    "Name": "St. Vincent P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 20388
  },
  {
    "Name": "Vaagdevi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21470
  },
  {
    "Name": "Vaagdevi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21577
  },
  {
    "Name": "Sri Shivani Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21780
  },
  {
    "Name": "Dhanvanthari Institute Of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 23103
  },
  {
    "Name": "Holy Mary Institute of Technology & Science",
    "Course": "MBA in Big Data Analytics",
    "Category": "OBC",
    "Score": 23914
  },
  {
    "Name": "Mother Teresa College of Management and Computer Applications",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 25402
  },
  {
    "Name": "Vinayaka  College of IT and Business Management, Kondapak",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 25697
  },
  {
    "Name": "St. Xaviers PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26054
  },
  {
    "Name": "Jaya Prakash Narayan College of Engineering JPNCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26653
  },
  {
    "Name": "School of Business Management, Global Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 20412
  },
  {
    "Name": "St. Joseph's PG College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21533
  },
  {
    "Name": "Balaji Institute of Technology and Science (BITS Warangal)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 21690
  },
  {
    "Name": "Bomma Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 22862
  },
  {
    "Name": "Sri Indu Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 23674
  },
  {
    "Name": "Kingston PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 24689
  },
  {
    "Name": "Suprabhat Institute of Computer Studies",
    "Course": "M.B.A. in Business Management",
    "Category": "OBC",
    "Score": 25556
  },
  {
    "Name": "Kakatiya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 25880
  },
  {
    "Name": "P.V. Ram Reddy P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 26484
  },
  {
    "Name": "Vijaya School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27122
  },
  {
    "Name": "SRM PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 27743
  },
  {
    "Name": "Visvesvaraya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 29073
  },
  {
    "Name": "Apoorva Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 29823
  },
  {
    "Name": "Siddhartha Institute Of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 30699
  },
  {
    "Name": "Nishitha Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 31654
  },
  {
    "Name": "Trinity College of Engineering and Technology, Peddapalli",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 35458
  },
  {
    "Name": "Samskruti Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 36233
  },
  {
    "Name": "PG Centre, Wanaparthy - Palamuru University.",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration",
    "Category": "OBC",
    "Score": 38608
  },
  {
    "Name": "Avanthi P.G. and Research Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 40859
  },
  {
    "Name": "Jaya Institute of Business Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 43646
  },
  {
    "Name": "St. Johns Institute of Science and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 28964
  },
  {
    "Name": "Madhira Institute of Technology and Sciences",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 30336
  },
  {
    "Name": "SVS Institute of Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 30748
  },
  {
    "Name": "Vision P.G. College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 32011
  },
  {
    "Name": "Priyadarshini P.G. College (MBA)",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 35951
  },
  {
    "Name": "Sai Krishna College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 37813
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology, Hayathnagar",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 40054
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 42134
  },
  {
    "Name": "Joginpalli B.R.Engineering College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 44100
  },
  {
    "Name": "Vivekananda College of Computer Sciences",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 44955
  },
  {
    "Name": "Vaagdevi Institute of Management Sciences",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 46574
  },
  {
    "Name": "Aurobindo College of Computer Science",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 48138
  },
  {
    "Name": "Vijaya Engineering College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 48625
  },
  {
    "Name": "Alluri Institute of Management Sciences",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 48990
  },
  {
    "Name": "Ganapathy Engineering College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 51744
  },
  {
    "Name": "Balaji Institute of Management Science",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 51951
  },
  {
    "Name": "Trinity College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 51959
  },
  {
    "Name": "Rousseau Institute of Management Studies",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 55368
  },
  {
    "Name": "Unity P.G. College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "OBC",
    "Score": 55928
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 86
  },
  {
    "Name": "Badruka College Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 552
  },
  {
    "Name": "Chaitanya Bharathi Institute of Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 1205
  },
  {
    "Name": "Faculty of Commerce and Business Management- Kakatiya University Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 1881
  },
  {
    "Name": "Bhavan's Vivekananda College of Science, Humanities and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 2045
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "MBA in Tourism and Travel Management",
    "Category": "SC",
    "Score": 2542
  },
  {
    "Name": "St Marys Centenary Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 3596
  },
  {
    "Name": "Vivekvardhini School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 4740
  },
  {
    "Name": "Pendekanti Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 5600
  },
  {
    "Name": "SNIST - Sreenidhi Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 5987
  },
  {
    "Name": "School of Management Studies, Jawaharlal Nehru Technological University, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 298
  },
  {
    "Name": "Keshav Memorial Institute of Technology",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 1196
  },
  {
    "Name": "Nizam College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 1652
  },
  {
    "Name": "Osmania University PG College, Narsapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 1967
  },
  {
    "Name": "MVSR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 2388
  },
  {
    "Name": "Raja Bahadur Venkata Rama Reddy Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 3503
  },
  {
    "Name": "Dr. B.R. Ambedkar Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 4077
  },
  {
    "Name": "Vidya Jyothi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 4759
  },
  {
    "Name": "Anurag University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 5659
  },
  {
    "Name": "Vardhaman College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 6591
  },
  {
    "Name": "Avanthi P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7039
  },
  {
    "Name": "Vivekananda PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 7180
  },
  {
    "Name": "Vignana Bharathi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7843
  },
  {
    "Name": "Post Graduate College, Siddipet",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8022
  },
  {
    "Name": "Mahatma Gandhi University College of Commerce and Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8219
  },
  {
    "Name": "Marri Laxman Reddy Institute of Technology and Management (MLRITM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8339
  },
  {
    "Name": "Pragati Mahavidyalaya Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8651
  },
  {
    "Name": "Suprabhath Institute For Management and Computer Studies",
    "Course": "Masters in Business Studies",
    "Category": "SC",
    "Score": 8959
  },
  {
    "Name": "DRK College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 9793
  },
  {
    "Name": "R.G. Kedia College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 10714
  },
  {
    "Name": "Pullareddy Institute of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7054
  },
  {
    "Name": "Mother Teresa PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7604
  },
  {
    "Name": "Aurora's Post Graduate College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 7899
  },
  {
    "Name": "Aristotle PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8183
  },
  {
    "Name": "Holy Mary Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8250
  },
  {
    "Name": "CMR College of  Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8359
  },
  {
    "Name": "Global Institute of Management, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 8892
  },
  {
    "Name": "Malla Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 9232
  },
  {
    "Name": "MLR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 10225
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 11173
  },
  {
    "Name": "Aurora's PG College, Panjagutta",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 11360
  },
  {
    "Name": "Sardar Patel College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 12340
  },
  {
    "Name": "Nalla Narasimha Reddy Education Societys Group of Institutions",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 12850
  },
  {
    "Name": "Princeton P.G. College Of Information Technology",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 13641
  },
  {
    "Name": "Vani Niketan Institute of Management Studies",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 13813
  },
  {
    "Name": "TKR Institute of Management and Science",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 14379
  },
  {
    "Name": "Balaji Institute of Technology and Science (BITS Warangal)",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 14601
  },
  {
    "Name": "Princeton P.G. College of Management",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 14703
  },
  {
    "Name": "Guru Nanak Institute of Technology, Telangana",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 15015
  },
  {
    "Name": "Sri Shivani Institute Of Management",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 15310
  },
  {
    "Name": "Aradhana School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 11867
  },
  {
    "Name": "Visvesvaraya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 12794
  },
  {
    "Name": "TKR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 12998
  },
  {
    "Name": "Jaya Prakash Narayan College of Engineering JPNCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 13802
  },
  {
    "Name": "Omega PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 14153
  },
  {
    "Name": "Aurora's PG College, Nampally",
    "Course": "Master of Business Administration(MBA)",
    "Category": "SC",
    "Score": 14470
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 14603
  },
  {
    "Name": "CMR Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 14765
  },
  {
    "Name": "Vaagdevi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15148
  },
  {
    "Name": "CMR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15594
  },
  {
    "Name": "University Post Graduate College, Khammam - Kakatiya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15732
  },
  {
    "Name": "Malla Reddy Engineering College And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15849
  },
  {
    "Name": "University Arts and Science College, Subedari - Kakatiya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 16837
  },
  {
    "Name": "Sai Sudhir College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17012
  },
  {
    "Name": "Malla Reddy Institute Of Management (MRIM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17676
  },
  {
    "Name": "Ellenki College of Engineering  and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17742
  },
  {
    "Name": "Sree Chaitanya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18170
  },
  {
    "Name": "Vidya Dayini College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18203
  },
  {
    "Name": "V.V.Sangh's Basaveshwara Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18569
  },
  {
    "Name": "SR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18845
  },
  {
    "Name": "DRK Institute Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15772
  },
  {
    "Name": "Holy Mother P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 15957
  },
  {
    "Name": "Bharat Institute of Engineering and Technology, Telangana",
    "Course": "Master of Business Management (MBA)",
    "Category": "SC",
    "Score": 16855
  },
  {
    "Name": "Vivekananda Degree And Pg College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17069
  },
  {
    "Name": "St. Xaviers PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17732
  },
  {
    "Name": "Sri Indu PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 17936
  },
  {
    "Name": "St. Vincent P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18185
  },
  {
    "Name": "Kommuri Pratap Reddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 18476
  },
  {
    "Name": "Suprabhat Institute of Computer Studies",
    "Course": "M.B.A. in Business Management",
    "Category": "SC",
    "Score": 18672
  },
  {
    "Name": "Mother Teresa College of Management and Computer Applications",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19159
  },
  {
    "Name": "University Post Graduate College,Godavarikhani - Satavahana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19442
  },
  {
    "Name": "Akshara Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19780
  },
  {
    "Name": "Aurobindo College of Business Management- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 20542
  },
  {
    "Name": "School of Business Management, Global Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21234
  },
  {
    "Name": "RGR Siddhanthi Degree College",
    "Course": "Master of Business Administration",
    "Category": "SC",
    "Score": 21689
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21999
  },
  {
    "Name": "Sree Chaitanya Institute of Technological Sciences (SCITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22168
  },
  {
    "Name": "Sri Chaitanya Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22515
  },
  {
    "Name": "PG Centre, Wanaparthy - Palamuru University.",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration",
    "Category": "SC",
    "Score": 22719
  },
  {
    "Name": "Vision P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22838
  },
  {
    "Name": "Noble PG College",
    "Course": "Discontinued (Aug 2021)- Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 19471
  },
  {
    "Name": "Geethanjali College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 20346
  },
  {
    "Name": "Siddhartha Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21635
  },
  {
    "Name": "St. Johns Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 21745
  },
  {
    "Name": "Trinity College of Engineering and Technology, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22116
  },
  {
    "Name": "Sri Indu Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22289
  },
  {
    "Name": "Palamuru University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22715
  },
  {
    "Name": "Trinity College of Engineering and Technology, Peddapalli",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 22823
  },
  {
    "Name": "Mother Theressa College Of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 23049
  },
  {
    "Name": "St. Joseph's PG College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 23111
  },
  {
    "Name": "Vaagdevi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 23879
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology, Hayathnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 24084
  },
  {
    "Name": "Anurag Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 24901
  },
  {
    "Name": "Vaageswari College of Engineering",
    "Course": "Masters in Business Management",
    "Category": "SC",
    "Score": 25123
  },
  {
    "Name": "Kingston PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 25763
  },
  {
    "Name": "Indur Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27486
  },
  {
    "Name": "Vijaya School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27891
  },
  {
    "Name": "Padala Rama Reddi College of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 28697
  },
  {
    "Name": "Mantra School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 29156
  },
  {
    "Name": "Reah School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 23753
  },
  {
    "Name": "Rousseau Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 23931
  },
  {
    "Name": "KGR Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 24495
  },
  {
    "Name": "Siddhartha Institute Of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 24937
  },
  {
    "Name": "Aurora's Post Graduate College, Ramanthapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 25277
  },
  {
    "Name": "DVM College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 26963
  },
  {
    "Name": "Bright Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 27654
  },
  {
    "Name": "St John's PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 28579
  },
  {
    "Name": "KG Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 29103
  },
  {
    "Name": "Samskruti Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 29640
  },
  {
    "Name": "Nigama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30464
  },
  {
    "Name": "P.V. Ram Reddy P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30651
  },
  {
    "Name": "Avanthi P.G. and Research Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31867
  },
  {
    "Name": "Samskruti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32246
  },
  {
    "Name": "SRM PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 33124
  },
  {
    "Name": "Manpower Development College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34317
  },
  {
    "Name": "Lal Bahadur College P.G. Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 36908
  },
  {
    "Name": "Vivekananda College of Computer Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37550
  },
  {
    "Name": "RKLK PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 39351
  },
  {
    "Name": "Narsimha Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 41558
  },
  {
    "Name": "Swarna Bharathi Institute of Science and Technology (SBIST, Khammam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 30606
  },
  {
    "Name": "Apoorva Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 31358
  },
  {
    "Name": "Vijaya P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32218
  },
  {
    "Name": "Bomma Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 32994
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 33167
  },
  {
    "Name": "New Science PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 34945
  },
  {
    "Name": "Nishitha Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 37219
  },
  {
    "Name": "KLR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 38879
  },
  {
    "Name": "Dhanvanthari Institute Of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 40391
  },
  {
    "Name": "Vinayaka  College of IT and Business Management, Kondapak",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 41737
  },
  {
    "Name": "Siddhartha Institute of Engineering and Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 42579
  },
  {
    "Name": "CKM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 42673
  },
  {
    "Name": "Mother Teresa Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 45637
  },
  {
    "Name": "Alluri Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 49427
  },
  {
    "Name": "Stanford P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 51850
  },
  {
    "Name": "Aurobindo College of Computer Science- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 54248
  },
  {
    "Name": "SRM Degree and PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 55828
  },
  {
    "Name": "Gate Institute of Technology and Sciences",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "SC",
    "Score": 57581
  },
  {
    "Name": "Vennela Institute Of Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 58206
  },
  {
    "Name": "Kakatiya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 59150
  },
  {
    "Name": "Sree Dattha Group of Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 42670
  },
  {
    "Name": "Vaagdevi Institute of Management Sciences",
    "Course": "Masters in Business Management",
    "Category": "SC",
    "Score": 43637
  },
  {
    "Name": "SVS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 46097
  },
  {
    "Name": "Balaji Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 49756
  },
  {
    "Name": "Priyadarshini P.G. College (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 54155
  },
  {
    "Name": "Unity P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 55129
  },
  {
    "Name": "Ganapathy Engineering Collge",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 56088
  },
  {
    "Name": "Indur P.G. College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 58154
  },
  {
    "Name": "Gouthami P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 58473
  },
  {
    "Name": "Talla Padmavathi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 59414
  },
  {
    "Name": "Sree Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 60025
  },
  {
    "Name": "Bharathi Institute Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "SC",
    "Score": 60800
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 203
  },
  {
    "Name": "School of Management Studies, Jawaharlal Nehru Technological University, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 627
  },
  {
    "Name": "Badruka College Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 1703
  },
  {
    "Name": "Vivekananda PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "ST",
    "Score": 3304
  },
  {
    "Name": "R.G. Kedia College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 5217
  },
  {
    "Name": "Osmania University PG College, Narsapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 6997
  },
  {
    "Name": "Vardhaman College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 7190
  },
  {
    "Name": "Faculty of Commerce and Business Management- Kakatiya University Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 8940
  },
  {
    "Name": "Malla Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 10245
  },
  {
    "Name": "Keshav Memorial Institute of Technology",
    "Course": "Master of Business Administration(MBA)",
    "Category": "ST",
    "Score": 11251
  },
  {
    "Name": "Nizam College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 328
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "MBA in Tourism and Travel Management",
    "Category": "ST",
    "Score": 829
  },
  {
    "Name": "Kakatiya Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 1835
  },
  {
    "Name": "Aurora's Post Graduate College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 3496
  },
  {
    "Name": "Institute of Aeronautical Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 6317
  },
  {
    "Name": "SNIST - Sreenidhi Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 7158
  },
  {
    "Name": "Holy Mary Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 7222
  },
  {
    "Name": "Guru Nanak Institute of Technology, Telangana",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 9854
  },
  {
    "Name": "Aurora's PG College, Panjagutta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 10673
  },
  {
    "Name": "Malla Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 11258
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 11972
  },
  {
    "Name": "St. Vincent P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 12756
  },
  {
    "Name": "Palamuru University Self Finance",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 13227
  },
  {
    "Name": "Siddhartha Institute Of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15160
  },
  {
    "Name": "Vaagdevi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15753
  },
  {
    "Name": "Malla Reddy Engineering College And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15920
  },
  {
    "Name": "Vishwa Vishwani Institute of Systems and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 16842
  },
  {
    "Name": "DRK Institute Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18109
  },
  {
    "Name": "Brilliant Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18485
  },
  {
    "Name": "Sai Spurthi Institute of Technology (SSIT, Khammam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18919
  },
  {
    "Name": "Vignana Bharathi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 12668
  },
  {
    "Name": "Noble PG College",
    "Course": "Discontinued (Aug 2021)- Masters of Business Administration (MBA)",
    "Category": "ST",
    "Score": 12853
  },
  {
    "Name": "Crescent College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 14931
  },
  {
    "Name": "Vani Niketan Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15262
  },
  {
    "Name": "Malla Reddy Institute Of Management (MRIM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 15801
  },
  {
    "Name": "Princeton P.G. College Of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 16447
  },
  {
    "Name": "Holy Mary Institute of Technology & Science",
    "Course": "MBA in Big Data Analytics",
    "Category": "ST",
    "Score": 17057
  },
  {
    "Name": "Swarna Bharathi Institute of Science and Technology (SBIST, Khammam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18319
  },
  {
    "Name": "SR Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 18746
  },
  {
    "Name": "Mahaveer Institute Of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19321
  },
  {
    "Name": "Post Graduate College, Siddipet",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19462
  },
  {
    "Name": "Holy Mother P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19714
  },
  {
    "Name": "Palamuru University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20291
  },
  {
    "Name": "St Marys Centenary Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20715
  },
  {
    "Name": "Visvesvaraya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21512
  },
  {
    "Name": "Mantra School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21904
  },
  {
    "Name": "Aurora's Post Graduate College, Ramanthapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22048
  },
  {
    "Name": "CMR College of  Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22138
  },
  {
    "Name": "University Arts and Science College, Subedari - Kakatiya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22779
  },
  {
    "Name": "Sri Indu PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 23285
  },
  {
    "Name": "Nishitha Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19636
  },
  {
    "Name": "J.B. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 19944
  },
  {
    "Name": "Mother Teresa PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 20303
  },
  {
    "Name": "Global Institute of Management, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21029
  },
  {
    "Name": "Sri Indu Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21538
  },
  {
    "Name": "Omega PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 21948
  },
  {
    "Name": "University Post Graduate College, Khammam - Kakatiya University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 22101
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "ST",
    "Score": 22722
  },
  {
    "Name": "KGR Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 23154
  },
  {
    "Name": "Suprabhath Institute For Management and Computer Studies",
    "Course": "Masters in Business Studies",
    "Category": "ST",
    "Score": 23836
  },
  {
    "Name": "Sree Chaitanya Institute of Technological Sciences (SCITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 23848
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology, Hayathnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24029
  },
  {
    "Name": "Sree Chaitanya College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24452
  },
  {
    "Name": "CMR Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 25318
  },
  {
    "Name": "Pannala Ram Reddy College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 25972
  },
  {
    "Name": "Suprabhat Institute of Computer Studies",
    "Course": "M.B.A. in Business Management",
    "Category": "ST",
    "Score": 26318
  },
  {
    "Name": "Vijaya School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 27318
  },
  {
    "Name": "Joginpalli B.R.Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 27835
  },
  {
    "Name": "TKR Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 29735
  },
  {
    "Name": "Adarsh Post Graduate College of Computer Science, Mahabubnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 31008
  },
  {
    "Name": "Madhira Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 23908
  },
  {
    "Name": "MLR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24219
  },
  {
    "Name": "Siddhartha Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 24933
  },
  {
    "Name": "Sree Dattha Group of Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 25423
  },
  {
    "Name": "Aurora's PG College, Nampally",
    "Course": "Master of Business Administration(MBA)",
    "Category": "ST",
    "Score": 26267
  },
  {
    "Name": "Kommuri Pratap Reddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 27219
  },
  {
    "Name": "St John's PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 27688
  },
  {
    "Name": "Sardar Patel College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 27843
  },
  {
    "Name": "Mother Teresa College of Management and Computer Applications",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 29836
  },
  {
    "Name": "Methodist College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 31463
  },
  {
    "Name": "RGR Siddhanthi Degree College",
    "Course": "Master of Business Administration",
    "Category": "ST",
    "Score": 31724
  },
  {
    "Name": "Vijaya Rural Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 32092
  },
  {
    "Name": "Avanthi P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 32413
  },
  {
    "Name": "PG Centre, Wanaparthy - Palamuru University.",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration",
    "Category": "ST",
    "Score": 32988
  },
  {
    "Name": "Vaageswari College of Engineering",
    "Course": "Masters in Business Management",
    "Category": "ST",
    "Score": 35156
  },
  {
    "Name": "Akshara Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 35666
  },
  {
    "Name": "Sri Chaitanya Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 37562
  },
  {
    "Name": "Apoorva Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 38828
  },
  {
    "Name": "Aristotle PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 40011
  },
  {
    "Name": "Vivekvardhini School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 31789
  },
  {
    "Name": "School of Business Management, Global Education Centre",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 32328
  },
  {
    "Name": "St. Johns Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 32642
  },
  {
    "Name": "Trinity College of Engineering and Technology, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 33244
  },
  {
    "Name": "Bomma Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 35337
  },
  {
    "Name": "Anu Bose Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 36120
  },
  {
    "Name": "Jaya Institute of Business Management",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "ST",
    "Score": 37119
  },
  {
    "Name": "P.V. Ram Reddy P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 38279
  },
  {
    "Name": "New Science PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 39250
  },
  {
    "Name": "Vinayaka  College of IT and Business Management, Kondapak",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 41441
  },
  {
    "Name": "Ellenki College of Engineering  and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 41569
  },
  {
    "Name": "Manpower Development College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "ST",
    "Score": 42088
  },
  {
    "Name": "Teegala Krishna Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 42300
  },
  {
    "Name": "Mahatma Gandhi University College of Commerce and Business Management",
    "Course": "MBA in Tourism and Travel Management",
    "Category": "ST",
    "Score": 44657
  },
  {
    "Name": "St. Joseph's PG College",
    "Course": "Masters of Business Administration (MBA)",
    "Category": "ST",
    "Score": 48272
  },
  {
    "Name": "SVS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 48455
  },
  {
    "Name": "Unity P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 49049
  },
  {
    "Name": "Pullareddy Institute of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 49508
  },
  {
    "Name": "Kingston PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 50053
  },
  {
    "Name": "Vidya Dayini College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 51830
  },
  {
    "Name": "Aurobindo College of Business Management- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 42024
  },
  {
    "Name": "Netaji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 42189
  },
  {
    "Name": "Sai Krishna College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 42359
  },
  {
    "Name": "Rousseau Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 48134
  },
  {
    "Name": "Vaagdevi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 48356
  },
  {
    "Name": "Jayamukhi Institute of Technological Sciences (JITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 48610
  },
  {
    "Name": "St. Xaviers PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 49112
  },
  {
    "Name": "SRM PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 49732
  },
  {
    "Name": "Indur P.G. College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 50086
  },
  {
    "Name": "Sri Shivani Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 51914
  },
  {
    "Name": "Princeton P.G. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 53113
  },
  {
    "Name": "Vivekananda College of Computer Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 53618
  },
  {
    "Name": "Talla Padmavathi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 54427
  },
  {
    "Name": "SRM Degree and PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 56777
  },
  {
    "Name": "Samskruti Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 57174
  },
  {
    "Name": "Nigama Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 57702
  },
  {
    "Name": "Vaagdevi Institute of Management Sciences",
    "Course": "Masters in Business Management",
    "Category": "ST",
    "Score": 57951
  },
  {
    "Name": "Priyadarshini P.G. College (MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 58340
  },
  {
    "Name": "University Post Graduate College,Godavarikhani - Satavahana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 58850
  },
  {
    "Name": "Arjun College of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 59811
  },
  {
    "Name": "Scient Institute of Technology (SIT, Ibrahimpatnam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 53606
  },
  {
    "Name": "DVM College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 53623
  },
  {
    "Name": "Trinity College of Engineering and Technology, Peddapalli",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 56112
  },
  {
    "Name": "Aurobindo College of Computer Science- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 57044
  },
  {
    "Name": "Vijaya P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 57553
  },
  {
    "Name": "Balaji Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 57819
  },
  {
    "Name": "Nalla Malla Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 58097
  },
  {
    "Name": "Kakatiya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 58810
  },
  {
    "Name": "Avanthi P.G. and Research Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 59022
  },
  {
    "Name": "Reah School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 59822
  },
  {
    "Name": "Alluri Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "ST",
    "Score": 60418
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2
  },
  {
    "Name": "Amjad Ali Khan College of Business Administration (AAKCBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 799
  },
  {
    "Name": "Shadan Institute of Computer Studies for Boys",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 2284
  },
  {
    "Name": "Aurora's Post Graduate College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 4612
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 15888
  },
  {
    "Name": "Siddhartha Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 585
  },
  {
    "Name": "Dr. B.R. Ambedkar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 1493
  },
  {
    "Name": "Arjun College of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 3996
  },
  {
    "Name": "St. Xaviers PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 11696
  },
  {
    "Name": "Siddhartha Institute of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "PWD",
    "Score": 21910
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20
  },
  {
    "Name": "Siddhartha Institute of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 585
  },
  {
    "Name": "Nizam College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 979
  },
  {
    "Name": "A.V. College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1961
  },
  {
    "Name": "Institute of Aeronautical Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2637
  },
  {
    "Name": "CMR College of  Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3224
  },
  {
    "Name": "Vivekananda PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "EWS",
    "Score": 3504
  },
  {
    "Name": "Arjun College of Technology and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3996
  },
  {
    "Name": "Aurora's PG College, Panjagutta",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4590
  },
  {
    "Name": "CMR Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5386
  },
  {
    "Name": "University College of Commerce and Business Management, Osmania University",
    "Course": "MBA in Technology Management",
    "Category": "EWS",
    "Score": 248
  },
  {
    "Name": "Amjad Ali Khan College of Business Administration (AAKCBA)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 799
  },
  {
    "Name": "Bhavan's Vivekananda College of Science, Humanities and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 1291
  },
  {
    "Name": "Shadan Institute of Computer Studies for Boys",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2284
  },
  {
    "Name": "Mahatma Gandhi University College of Commerce and Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 2746
  },
  {
    "Name": "Malla Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3246
  },
  {
    "Name": "Dr. B.R. Ambedkar Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 3790
  },
  {
    "Name": "BV Raju Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4138
  },
  {
    "Name": "Aurora's Post Graduate College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 4612
  },
  {
    "Name": "Vivekvardhini School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5519
  },
  {
    "Name": "Marri Laxman Reddy Institute of Technology and Management (MLRITM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 5785
  },
  {
    "Name": "Palamuru University Self Finance",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 7816
  },
  {
    "Name": "Malla Reddy Engineering College And Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 8239
  },
  {
    "Name": "Mother Teresa PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 8941
  },
  {
    "Name": "Faculty of Commerce and Business Management- Kakatiya University Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9236
  },
  {
    "Name": "Vardhaman College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9822
  },
  {
    "Name": "Visvesvaraya College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10480
  },
  {
    "Name": "Bright Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11367
  },
  {
    "Name": "Aristotle PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 12597
  },
  {
    "Name": "CMR Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13222
  },
  {
    "Name": "Sardar Patel College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 7533
  },
  {
    "Name": "Osmania University PG College, Narsapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 8064
  },
  {
    "Name": "Nishitha Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 8302
  },
  {
    "Name": "Avanthi P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9091
  },
  {
    "Name": "Mother Teresa College of Management and Computer Applications",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 9697
  },
  {
    "Name": "Princeton P.G. College Of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10409
  },
  {
    "Name": "Guru Nanak Institute of Technology, Telangana",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 10971
  },
  {
    "Name": "Satavahana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 11812
  },
  {
    "Name": "J.B. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13023
  },
  {
    "Name": "Vignana Bharathi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 13931
  },
  {
    "Name": "Sree Dattha Group of Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14287
  },
  {
    "Name": "Holy Mary Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14584
  },
  {
    "Name": "Aurora's PG College, Nampally",
    "Course": "Master of Business Administration(MBA)",
    "Category": "EWS",
    "Score": 15755
  },
  {
    "Name": "Avanthi Institute Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15888
  },
  {
    "Name": "Malla Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16872
  },
  {
    "Name": "Guru Nanak Institutions Technical Campus",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Category": "EWS",
    "Score": 18323
  },
  {
    "Name": "Vinayaka  College of IT and Business Management, Kondapak",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19131
  },
  {
    "Name": "Teegala Krishna Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19952
  },
  {
    "Name": "KGR Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20900
  },
  {
    "Name": "Siddhartha Institute of Engineering and Technology, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21910
  },
  {
    "Name": "Vani Niketan Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 14563
  },
  {
    "Name": "TKR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15452
  },
  {
    "Name": "Kakatiya Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 15781
  },
  {
    "Name": "Jaya Prakash Narayan College of Engineering JPNCE)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16284
  },
  {
    "Name": "KLR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 16964
  },
  {
    "Name": "Suprabhath Institute For Management and Computer Studies",
    "Course": "Masters in Business Studies",
    "Category": "EWS",
    "Score": 18999
  },
  {
    "Name": "Pannala Ram Reddy College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 19835
  },
  {
    "Name": "St. Johns Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 20792
  },
  {
    "Name": "Global Institute of Management, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 21626
  },
  {
    "Name": "PG Centre, Wanaparthy - Palamuru University.",
    "Course": "Discontinued (Oct 2022)- Master of Business Administration",
    "Category": "EWS",
    "Score": 21979
  },
  {
    "Name": "Aurora's Post Graduate College, Ramanthapur",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 25627
  },
  {
    "Name": "Kommuri Pratap Reddy Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26896
  },
  {
    "Name": "Swarna Bharathi Institute of Science and Technology (SBIST, Khammam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27594
  },
  {
    "Name": "Gouthami P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28204
  },
  {
    "Name": "Akshara Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28888
  },
  {
    "Name": "Kakatiya Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 30298
  },
  {
    "Name": "Mantra School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 30684
  },
  {
    "Name": "Apoorva Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 32117
  },
  {
    "Name": "Vaagdevi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 35656
  },
  {
    "Name": "St. Xaviers PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 39395
  },
  {
    "Name": "Geethanjali College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 26506
  },
  {
    "Name": "Nalla Malla Reddy Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 27332
  },
  {
    "Name": "St Marys Centenary Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28121
  },
  {
    "Name": "KG Reddy College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 28727
  },
  {
    "Name": "Vaagdevi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 29302
  },
  {
    "Name": "Vaageswari College of Engineering",
    "Course": "Masters in Business Management",
    "Category": "EWS",
    "Score": 30421
  },
  {
    "Name": "Sri Indu Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 30705
  },
  {
    "Name": "St. Vincent P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 35189
  },
  {
    "Name": "University Post Graduate College,Godavarikhani - Satavahana University",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 37838
  },
  {
    "Name": "Omega PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 40865
  },
  {
    "Name": "Pullareddy Institute of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 41741
  },
  {
    "Name": "Aradhana School Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 43361
  },
  {
    "Name": "Trinity College of Engineering and Technology, Peddapalli",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 44766
  },
  {
    "Name": "Annamacharya Institute of Technology and Sciences, Ranga Reddy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 44980
  },
  {
    "Name": "Samskruti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 45246
  },
  {
    "Name": "SRM Degree and PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 46775
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration(MBA)",
    "Category": "EWS",
    "Score": 49774
  },
  {
    "Name": "Aurobindo College of Business Management- Olive P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 51329
  },
  {
    "Name": "Sri Shivani Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 52454
  },
  {
    "Name": "Vennela Institute Of Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 55172
  },
  {
    "Name": "Jyothishmathi Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 42609
  },
  {
    "Name": "Malla Reddy Institute Of Management (MRIM)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 44080
  },
  {
    "Name": "Suprabhat Institute of Computer Studies",
    "Course": "M.B.A. in Business Management",
    "Category": "EWS",
    "Score": 44778
  },
  {
    "Name": "Ellenki College of Engineering  and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 45245
  },
  {
    "Name": "SRM PG College, Karimnagar",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 46552
  },
  {
    "Name": "Vaagdevi Degree and PG College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 49173
  },
  {
    "Name": "Siddhartha Institute Of Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 50913
  },
  {
    "Name": "Sree Chaitanya Institute of Technological Sciences (SCITS)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 51753
  },
  {
    "Name": "Scient Institute of Technology (SIT, Ibrahimpatnam)",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 54854
  },
  {
    "Name": "Padala Rama Reddi College of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 55187
  },
  {
    "Name": "P.V. Ram Reddy P.G. College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 55455
  },
  {
    "Name": "Manpower Development College",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 56512
  },
  {
    "Name": "Bomma Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 57064
  },
  {
    "Name": "Avanthi P.G. and Research Academy",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 55766
  },
  {
    "Name": "TKR Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Category": "EWS",
    "Score": 56604
  }
]