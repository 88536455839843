import React, { useState } from "react";
import { Modal, Input, Button, Form, Select } from "antd";
import data from "../JACDELHIPredictor/PREDICTORJACDELHIJSON.json";

const InstitutePredictor = () => {
  const [filters, setFilters] = useState({
    Region: "",
    Category: "",
    Branch: "",
    Rank: "",
  });
  const [filteredInstitutes, setFilteredInstitutes] = useState([]);
  const [error, setError] = useState("");
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [userDataJACDelhi, setuserDataJACDelhi] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    city: "",
    courseInterestedIn: "",
  });

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
    setError("");
  };

  const applyFilters = () => {
    if (!filters.Region || !filters.Category || !filters.Branch || !filters.Rank) {
      setError("All fields are mandatory. Please select or fill in all the details.");
      return;
    }

    const saveduserDataJACDelhi = localStorage.getItem("userDataJACDelhi");
    if (!saveduserDataJACDelhi) {
      setShowSignupModal(true); // Show modal if user data is not found
      return;
    }

    const filtered = data
      .filter((item) => {
        return (
          (!filters.Region || item.Region === filters.Region) &&
          (!filters.Category || item.Category === filters.Category) &&
          (!filters.Branch || item.Branch === filters.Branch) &&
          (!filters.Rank || item.Rank >= parseInt(filters.Rank))
        );
      })
      .map((item) => item.Institute);

    const uniqueFiltered = [...new Set(filtered)];
    setFilteredInstitutes(uniqueFiltered);
    setHasAppliedFilters(true);
  };

  const handleUserSubmit = async () => {
    try {
      localStorage.setItem("userDataJACDelhi", JSON.stringify(userDataJACDelhi));
      setShowSignupModal(false);

      const response = await fetch("https://caderaedu.com/api/form/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDataJACDelhi),
      });

      if (!response.ok) {
        throw new Error("Failed to submit data.");
      }

      console.log("User data submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const uniqueOptions = (key) => {
    return [...new Set(data.map((item) => item[key]))];
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>JAC DELHI Predictor</h2>
      <div style={styles.filterContainer}>
        {["Region", "Category", "Branch"].map((field) => (
          <div style={styles.filterItem} key={field}>
            <label style={styles.label}>{field}:</label>
            <Select
              style={{ width: "100%" }}
              placeholder={`Select ${field}`}
              onChange={(value) => handleFilterChange(field, value)}
              options={uniqueOptions(field).map((option) => ({
                label: option,
                value: option,
              }))}
            />
          </div>
        ))}
        <div style={styles.filterItem}>
          <label style={styles.label}>Rank:</label>
          <Input
            type="number"
            placeholder="Enter Rank"
            onChange={(e) => handleFilterChange("Rank", e.target.value)}
          />
        </div>
      </div>
      {error && <p style={styles.error}>{error}</p>}
      <Button type="primary" style={{ width: "100%" }} onClick={applyFilters}>
        Predict Institute
      </Button>
      <h3 style={styles.subHeading}>Predicted Institutes:</h3>
      {hasAppliedFilters && filteredInstitutes.length === 0 && (
        <p style={styles.noData}>No matching institute found.</p>
      )}
      {filteredInstitutes.length > 0 && (
        <ul style={styles.list}>
          {filteredInstitutes.map((institute, index) => (
            <li key={index} style={styles.listItem}>
              {institute}
            </li>
          ))}
        </ul>
      )}

      <Modal
        title="Sign Up"
        visible={showSignupModal}
        footer={null}
        onCancel={() => setShowSignupModal(false)}
      >
        <Form layout="vertical" onFinish={handleUserSubmit}>
          <Form.Item label="Full Name" required>
            <Input
              value={userDataJACDelhi.fullName}
              onChange={(e) =>
                setuserDataJACDelhi({ ...userDataJACDelhi, fullName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Email" required>
            <Input
              type="email"
              value={userDataJACDelhi.email}
              onChange={(e) =>
                setuserDataJACDelhi({ ...userDataJACDelhi, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Mobile Number" required>
            <Input
              type="tel"
              value={userDataJACDelhi.mobileNumber}
              onChange={(e) =>
                setuserDataJACDelhi({ ...userDataJACDelhi, mobileNumber: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="City" required>
            <Input
              value={userDataJACDelhi.city}
              onChange={(e) =>
                setuserDataJACDelhi({ ...userDataJACDelhi, city: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Course Interested In" required>
            <Input
              value={userDataJACDelhi.courseInterestedIn}
              onChange={(e) =>
                setuserDataJACDelhi({ ...userDataJACDelhi, courseInterestedIn: e.target.value })
              }
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    textAlign: "center",
    color: "#333",
    marginBottom: "20px",
    fontSize: "23px",
    fontWeight: "bold",
  },
  filterContainer: {
    marginBottom: "20px",
  },
  filterItem: {
    marginBottom: "10px",
  },
  label: {
    fontWeight: "bold",
  },
  subHeading: {
    marginTop: "20px",
    color: "#333",
  },
  list: {
    padding: 0,
    listStyle: "none",
  },
  listItem: {
    padding: "10px",
    backgroundColor: "#e9ecef",
    marginBottom: "5px",
    borderRadius: "4px",
  },
  error: {
    color: "red",
    marginBottom: "10px",
  },
  noData: {
    color: "#888",
  },
};

export default InstitutePredictor;
