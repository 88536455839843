import React from "react";
import { useNavigate } from "react-router-dom";
import { nidlogo, niftwhite, uceed, niftblack } from "../../images";

const exams = [
  {
    id: 1,
    name: "NID Entrance Exam (BDes, GDPD)",
    icon: nidlogo,
    route: "/nid-exam",
  },
  {
    id: 2,
    name: "NIFT Entrance Exam (B.Des)",
    icon: niftwhite,
    route: "/nift-bdes",
  },
  { id: 3, name: "UCEED", icon: uceed, route: "/uceed-exam" },
  {
    id: 4,
    name: "NIFT Entrance Exam (BFTech)",
    icon: niftblack,
    route: "/nift-bftech",
  },
  {
    id: 5,
    name: "NIFT  Entrance Exam (M.Des, MFM)",
    icon: niftblack,
    route: "/nift-mdes-mfm",
  },
  {
    id: 6,
    name: "NIFT  Entrance Exam (MFTech)",
    icon: niftblack,
    route: "/nift-mftech",
  },
];

const DesignPredictormobile = () => {
  const navigate = useNavigate();

  const handleImageClick = (route) => {
    navigate(route);
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4 text-center text-black">
        Design College Predictor 2024
      </h1>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Design exams you have taken.
      </p>

      <div className="grid grid-cols-3 gap-6">
        {exams.map((exam) => (
          <div
            key={exam.id}
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleImageClick(exam.route)}
          >
            <div className="bg-white p-4 rounded-lg shadow-md text-center transform hover:scale-105 transition-transform duration-200 ease-in-out">
              <div className="w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            <p className="text-sm font-semibold text-gray-800 mt-1 text-center">
              {exam.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignPredictormobile;
