[
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Finance Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Human Resource Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "MBA iConnect in International Business",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "PGDM in Media and Communication Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "PGDM in Business Analytics",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "Discontinued (June 2023) Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "SOIL School of Business Design",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Christ - Kengeri Campus",
    "Course": "PGDM in International Business",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in International Business",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Logistic and Supply Chain Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies, Noida",
    "Course": "PGDM in Business Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in Research and Business Analytics",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "EMPI Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "IIMS - International Institute of Management Sciences",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "PGDM in Insurance Business Management",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Lexicon Management Institute of Leadership and Excellence",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Pravara Centre for Management Research and Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Master of International Business (MIB)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Christ University",
    "Course": "PGDM in Retail Management",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "PGDM in General Management",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "ITM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Christ Institute of Management",
    "Course": "Master of Business Administration",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "Ramaiah Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "Calcutta Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "National Institute of Financial Management",
    "Course": "PGDM in Financial Markets",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "GL Bajaj Institute of Management and Research",
    "Course": "Discontinued (Oct 2024)- Master of Business Administration",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Accurate Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "General"
  },
  {
    "Name": "CII School of Logistics, Amity University - Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Adarsh Institute of Management and Information",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "General"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Finance Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Human Resource Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Logistic and Supply Chain Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies, Noida",
    "Course": "PGDM in Business Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "IIMS - International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Media and Communication Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Business Analytics",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "SOIL School of Business Design",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Christ - Kengeri Campus",
    "Course": "Discontinued (June 2023) Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Pravara Centre for Management Research and Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Lexicon Management Institute of Leadership and Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ITM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Christ Institute of Management",
    "Course": "Master of Business Administration",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "GITAM School of Business, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Ramaiah Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "CII School of Logistics, Amity University - Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "OBC"
  },
  {
    "Name": "Calcutta Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "National Institute of Financial Management",
    "Course": "PGDM in Financial Markets",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "GL Bajaj Institute of Management and Research - GLBIMR",
    "Course": "Discontinued (Oct 2024)- Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Accurate Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "OBC"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Finance Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Human Resource Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Logistic and Supply Chain Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Studies, Noida",
    "Course": "PGDM in Business Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "IIMS - International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Media and Communication Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Business Analytics",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "SOIL School of Business Design",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Christ - Kengeri Campus",
    "Course": "Discontinued (June 2023) Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Pravara Centre for Management Research and Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Lexicon Management Institute of Leadership and Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ITM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Christ Institute of Management",
    "Course": "Master of Business Administration",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "GITAM School of Business, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Ramaiah Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "CII School of Logistics, Amity University - Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "SC"
  },
  {
    "Name": "Calcutta Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "National Institute of Financial Management",
    "Course": "PGDM in Financial Markets",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "GL Bajaj Institute of Management and Research - GLBIMR",
    "Course": "Discontinued (Oct 2024)- Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Accurate Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "SC"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Finance Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Human Resource Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Logistic and Supply Chain Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies, Noida",
    "Course": "PGDM in Business Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "IIMS - International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Media and Communication Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Business Analytics",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "SOIL School of Business Design",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Christ - Kengeri Campus",
    "Course": "Discontinued (June 2023) Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Pravara Centre for Management Research and Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Lexicon Management Institute of Leadership and Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ITM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Christ Institute of Management",
    "Course": "Master of Business Administration",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "Ramaiah Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "CII School of Logistics, Amity University - Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Calcutta Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "ST"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "National Institute of Financial Management",
    "Course": "PGDM in Financial Markets",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "GL Bajaj Institute of Management and Research - GLBIMR",
    "Course": "Discontinued (Oct 2024)- Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Accurate Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Finance Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Human Resource Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Logistic and Supply Chain Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies, Noida",
    "Course": "PGDM in Business Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "IIMS - International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Media and Communication Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Business Analytics",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "SOIL School of Business Design",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Christ - Kengeri Campus",
    "Course": "Discontinued (June 2023) Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Pravara Centre for Management Research and Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Lexicon Management Institute of Leadership and Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ITM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Christ Institute of Management",
    "Course": "Master of Business Administration",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "GITAM School of Business, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "Ramaiah Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "CII School of Logistics, Amity University - Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "PWD"
  },
  {
    "Name": "Calcutta Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "PWD"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "National Institute of Financial Management",
    "Course": "PGDM in Financial Markets",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "GL Bajaj Institute of Management and Research - GLBIMR",
    "Course": "Discontinued (Oct 2024)- Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Accurate Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "PWD"
  },
  {
    "Name": "Amity Business School (ABS), Amity University, Noida",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Finance Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Human Resource Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Human Resource Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Durgadevi Saraf Global Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Balaji Institute of Modern Management, Sri Balaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Marketing Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Retail Management and E-Commerce",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in International Business",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Logistic and Supply Chain Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Financial Services",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Operation and Supply Chain Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Digital Marketing and Media Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Studies, Noida",
    "Course": "PGDM in Business Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "VIT Vellore",
    "Course": "MBA (Master of Business Administration)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "IIMS - International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Media and Communication Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Finance",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Business Analytics",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Fintech",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "ISB&M - International School of Business and Media, Pune",
    "Course": "PGDM in Business Analytics",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "SOIL School of Business Design",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Christ - Kengeri Campus",
    "Course": "Discontinued (June 2023) Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Pravara Centre for Management Research and Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "PSG Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Lexicon Management Institute of Leadership and Excellence",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ITM Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Christ Institute of Management",
    "Course": "Master of Business Administration",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "Christ University",
    "Course": "Discontinued (Sep 2024)- MBA (Business Analytics)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Visakhapatnam",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Rajagiri Centre for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "JK Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "Ramaiah Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "EMPI Business School",
    "Course": "PGDM in General Management (Marketing and Financial Management)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "CII School of Logistics, Amity University - Noida",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "EWS"
  },
  {
    "Name": "Calcutta Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "EWS"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "National Institute of Financial Management",
    "Course": "PGDM in Financial Markets",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "GL Bajaj Institute of Management and Research - GLBIMR",
    "Course": "Discontinued (Oct 2024)- Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Accurate Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55,
    "Category": "EWS"
  }
]