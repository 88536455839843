[
  {
    "Name": "INSEAD",
    "Score": 715,
    "Course": "MBA"
  },
  {
    "Name": "London Business School",
    "Score": 708,
    "Course": "MBA"
  },
  {
    "Name": "London School of Economics and Political Science (LSE)",
    "Score": 708,
    "Course": "MBA"
  },
  {
    "Name": "University of Navarra (IESE)",
    "Score": 585,
    "Course": "MBA"
  },
  {
    "Name": "HEC Paris School of Management",
    "Score": 695,
    "Course": "MBA"
  },
  {
    "Name": "University of Cambridge",
    "Score": 695,
    "Course": "MBA"
  },
  {
    "Name": "University of Oxford",
    "Score": 695,
    "Course": "MBA"
  },
  {
    "Name": "ESADE Ramon Llull",
    "Score": 675,
    "Course": "MBA"
  },
  {
    "Name": "The University of Warwick",
    "Score": 655,
    "Course": "MBA"
  },
  {
    "Name": "Bocconi University",
    "Score": 700,
    "Course": "MBA"
  },
  {
    "Name": "Copenhagen Business School",
    "Score": 655,
    "Course": "MBA"
  },
  {
    "Name": "Erasmus University Rotterdam",
    "Score": 645,
    "Course": "MBA"
  },
  {
    "Name": "Imperial College London",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "The University of Manchester",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "Cranfield University",
    "Score": 685,
    "Course": "MBA"
  },
  {
    "Name": "University of London",
    "Score": 755,
    "Course": "MBA"
  },
  {
    "Name": "Lancaster University Management School",
    "Score": 585,
    "Course": "MBA"
  },
  {
    "Name": "Strathclyde Business School",
    "Score": 615,
    "Course": "MBA"
  },
  {
    "Name": "University of Edinburgh Business School",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "Stanford University",
    "Score": 737,
    "Course": "MBA"
  },
  {
    "Name": "Northwestern University",
    "Score": 729,
    "Course": "MBA"
  },
  {
    "Name": "Yale University",
    "Score": 725,
    "Course": "MBA"
  },
  {
    "Name": "University of Chicago",
    "Score": 730,
    "Course": "MBA"
  },
  {
    "Name": "University of Pennsylvania",
    "Score": 733,
    "Course": "MBA"
  },
  {
    "Name": "Harvard University",
    "Score": 730,
    "Course": "MBA"
  },
  {
    "Name": "Columbia University",
    "Score": 729,
    "Course": "MBA"
  },
  {
    "Name": "Dartmouth College",
    "Score": 726,
    "Course": "MBA"
  },
  {
    "Name": "Massachusetts Institute of Technology (MIT)",
    "Score": 730,
    "Course": "MBA"
  },
  {
    "Name": "University of California, Los Angeles (UCLA)",
    "Score": 711,
    "Course": "MBA"
  },
  {
    "Name": "University of California, Berkeley (UCB)",
    "Score": 730,
    "Course": "MBA"
  },
  {
    "Name": "University of Michigan",
    "Score": 720,
    "Course": "MBA"
  },
  {
    "Name": "New York University (NYU)",
    "Score": 733,
    "Course": "MBA"
  },
  {
    "Name": "University of Texas at Austin",
    "Score": 706,
    "Course": "MBA"
  },
  {
    "Name": "Cornell University",
    "Score": 710,
    "Course": "MBA"
  },
  {
    "Name": "Carnegie Mellon University",
    "Score": 702,
    "Course": "MBA"
  },
  {
    "Name": "Georgia Institute of Technology",
    "Score": 690,
    "Course": "MBA"
  },
  {
    "Name": "Boston University",
    "Score": 683,
    "Course": "MBA"
  },
  {
    "Name": "Duke University",
    "Score": 685,
    "Course": "MBA"
  },
  {
    "Name": "Berkeley College",
    "Score": 720,
    "Course": "MBA"
  },
  {
    "Name": "McGill University",
    "Score": 675,
    "Course": "MBA"
  },
  {
    "Name": "University of Toronto",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "York University",
    "Score": 665,
    "Course": "MBA"
  },
  {
    "Name": "University of British Columbia",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "Queen's University–Kingston",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "Université de Montréal",
    "Score": 635,
    "Course": "MBA"
  },
  {
    "Name": "University of Alberta",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "McMaster University",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "Simon Fraser University",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "The University of Western Ontario",
    "Score": 535,
    "Course": "MBA"
  },
  {
    "Name": "Concordia University",
    "Score": 585,
    "Course": "MBA"
  },
  {
    "Name": "The University of Melbourne",
    "Score": 565,
    "Course": "MBA"
  },
  {
    "Name": "The University of New South Wales (UNSW Sydney)",
    "Score": 679,
    "Course": "MBA"
  },
  {
    "Name": "The Australian National University",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "The University of Sydney",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "RMIT University",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "Monash University",
    "Score": 685,
    "Course": "MBA"
  },
  {
    "Name": "University of Queensland",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "Deakin University",
    "Score": 585,
    "Course": "MBA"
  },
  {
    "Name": "University of Wollongong",
    "Score": 595,
    "Course": "MBA"
  },
  {
    "Name": "Griffith University",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "Central Queensland University",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "The Hong Kong University of Science and Technology",
    "Score": 665,
    "Course": "MBA"
  },
  {
    "Name": "Nanyang Technological University, Singapore (NTU)",
    "Score": 605,
    "Course": "MBA"
  },
  {
    "Name": "Tsinghua University",
    "Score": 665,
    "Course": "MBA"
  },
  {
    "Name": "National University of Singapore (NUS)",
    "Score": 675,
    "Course": "MBA"
  },
  {
    "Name": "Seoul National University",
    "Score": 655,
    "Course": "MBA"
  },
  {
    "Name": "Peking University",
    "Score": 675,
    "Course": "MBA"
  },
  {
    "Name": "The Chinese University of Hong Kong (CUHK)",
    "Score": 685,
    "Course": "MBA"
  },
  {
    "Name": "Middlesex University, Dubai",
    "Score": 555,
    "Course": "MBA"
  },
  {
    "Name": "IIM Ahmedabad",
    "Score": 705,
    "Course": "PGPX"
  },
  {
    "Name": "IIM Bangalore",
    "Score": 695,
    "Course": "EPGP"
  },
  {
    "Name": "SP Jain",
    "Score": 655,
    "Course": "PGPM-Post Graduate Programme in Management"
  },
  {
    "Name": "IIM Kolkata",
    "Score": 705,
    "Course": "PGPEX"
  },
  {
    "Name": "BITS Pilani",
    "Score": 605,
    "Course": "MBA- master's in business administration"
  },
  {
    "Name": "IIM Lucknow",
    "Score": 685,
    "Course": "IPMX"
  },
  {
    "Name": "ISB (Indian School of Business) Hyderabad",
    "Score": 715,
    "Course": "PGP"
  },
  {
    "Name": "IIM Indore",
    "Score": 705,
    "Course": "EPGP"
  },
  {
    "Name": "IMT Ghaziabad",
    "Score": 695,
    "Course": "PGDM-Post Graduate Diploma in Management PGDMEx-Post Graduate Diploma in Management for Executives"
  },
  {
    "Name": "XLRI Jamshedpur",
    "Score": 685,
    "Course": "PGDM-Post Graduate Diploma in Management"
  },
  {
    "Name": "IFMR",
    "Score": 645,
    "Course": "MBA"
  },
  {
    "Name": "IIT Madras",
    "Score": 655,
    "Course": "MBA"
  },
  {
    "Name": "IIFT",
    "Score": 655,
    "Course": "MBA"
  }
]