[
  {
    "Name": "Faculty of Commerce and Management, Kurukshetra University",
    "Course": "MBA (Five-Year Integrated Practice Oriented Programme)",
    "Score": 47,
    "Category": "PWD"
  },
  {
    "Name": "Faculty of Commerce and Management, Kurukshetra University",
    "Course": "MBA (Five-Year Integrated Practice Oriented Programme)",
    "Score": 56.4,
    "Category": "General"
  },
  {
    "Name": "Faculty of Commerce and Management, Kurukshetra University",
    "Course": "MBA (Five-Year Integrated Practice Oriented Programme)",
    "Score": 56.4,
    "Category": "EWS"
  },
  {
    "Name": "Faculty of Commerce and Management, Kurukshetra University",
    "Course": "MBA (Five-Year Integrated Practice Oriented Programme)",
    "Score": 56.4,
    "Category": "OBC"
  },
  {
    "Name": "Faculty of Commerce and Management, Kurukshetra University",
    "Course": "MBA (Five-Year Integrated Practice Oriented Programme)",
    "Score": 56.4,
    "Category": "SC"
  },
  {
    "Name": "Faculty of Commerce and Management, Kurukshetra University",
    "Course": "MBA (Five-Year Integrated Practice Oriented Programme)",
    "Score": 56.4,
    "Category": "ST"
  }
]