import React from "react";

export default function CareerOptions() {
  return (
    <div className="p-6 bg-gray-50 border rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
        Career Options: After Completing MBBS Degree From Kazakhstan
      </h1>

      <p className="text-base text-gray-700 mb-6">
        Completing an MBBS degree from Kazakhstan opens numerous career opportunities for Indian students. Kazakhstan's medical universities are recognized by global organizations like the NMC, WHO, and ECFMG, ensuring the degree holds international value.
      </p>

      <div className="mb-8">
        <h2 className="text-xl font-bold text-blue-600 mb-4">
          National Medical Commission (NMC) Screening Test
        </h2>
        <p className="text-base text-gray-700">
          Indian students who complete their MBBS from Kazakhstan are required to clear the Foreign Medical Graduates Examination (FMGE), now integrated into the National Exit Test (NEXT). This is a licensure exam that ensures students are eligible to practice medicine in India.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold text-blue-600 mb-4">
          Postgraduate (PG) Options after MBBS in Kazakhstan
        </h2>
        <p className="text-base text-gray-700 font-bold mb-3">
          Students have several postgraduate options after completing their MBBS:
        </p>
        <ul className="list-disc list-inside text-base text-gray-700 space-y-3">
          <li>
            Pursue a PG degree in India by clearing the NEXT PG exam. Specializations such as internal medicine, surgery, pediatrics, and more are available.
          </li>
          <li>
            Enroll in a PG program in Kazakhstan, which offers affordable fees and advanced medical training facilities.
          </li>
          <li>
            Explore opportunities in countries like the USA, UK, Canada, and Germany. To do so, students need to pass relevant exams like USMLE (USA), PLAB (UK), or others as per the country’s requirement.
          </li>
          <li>
            Start a medical career directly by applying for hospital internships or residencies in Kazakhstan or other recognized countries.
          </li>
        </ul>
      </div>

      <div>
        <h2 className="text-xl font-bold text-blue-600 mb-4">
          Advantages of Studying MBBS in Kazakhstan
        </h2>
        <ul className="list-disc list-inside text-base text-gray-700 space-y-3">
          <li>Affordable tuition fees and cost of living compared to other countries.</li>
          <li>Globally recognized medical degree with English-medium instruction.</li>
          <li>High-quality education with modern infrastructure and experienced faculty.</li>
          <li>Opportunities for clinical practice in leading hospitals during studies.</li>
          <li>Gateway to postgraduate studies or a medical career worldwide.</li>
        </ul>
      </div>
    </div>
  );
}
