import React from "react";

export default function TopColleges() {
  return (
    <div className="p-4 bg-white rounded-md shadow-lg max-w-7xl mx-auto">
      {/* Section: Fee Structure */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">MBBS in Kazakhstan Fee Structure 2024</h2>
        <p className="mb-4">Here are the average tuition fees and hostel fees of the top universities in Kazakhstan:</p>
        <table className="table-auto w-full text-left border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 border">Name of the University</th>
              <th className="p-2 border">Tuition Fees (For 1 Year)</th>
              <th className="p-2 border">Hostel Fee (For 1 Year)</th>
            </tr>
          </thead>
          <tbody>
            {[
              { university: "Kazakh National Medical University", tuition: "4500 USD", hostel: "650 USD" },
              { university: "Astana Medical University", tuition: "3200 USD", hostel: "700 USD" },
              { university: "UIB International Medical School", tuition: "3500 USD", hostel: "700 USD" },
              { university: "South Kazakhstan Medical Academy", tuition: "4250 USD", hostel: "700 USD" },
              { university: "Karaganda State Medical University", tuition: "3700 USD", hostel: "600 USD" },
              { university: "Kazakh Medical University of Continuing Education", tuition: "3500 USD", hostel: "700 USD" },
              { university: "Kazakh Russian Medical University", tuition: "4000 USD", hostel: "700 USD" },
              { university: "Kokshetau State University", tuition: "3500 USD", hostel: "500 USD" },
              { university: "North Kazakhstan State University", tuition: "3500 USD", hostel: "500 USD" },
              { university: "Semey State Medical University", tuition: "3200 USD", hostel: "600 USD" },
              { university: "West Kazakhstan Marat Ospanov State Medical University", tuition: "3500 USD", hostel: "500 USD" },
              { university: "Al-Farabi Kazakh National University", tuition: "4200 USD", hostel: "700 USD" },
            ].map((item, index) => (
              <tr key={index} className="border-b">
                <td className="p-2 border">{item.university}</td>
                <td className="p-2 border">{item.tuition}</td>
                <td className="p-2 border">{item.hostel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Section: Rankings */}
      <section>
        <h2 className="text-2xl font-bold mb-4">Top Colleges in Kazakhstan</h2>
        <p className="mb-4">National & International ranking of all the top medical colleges in Kazakhstan:</p>
        <table className="table-auto w-full text-left border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 border">Name of the University</th>
              <th className="p-2 border">Country Ranking</th>
              <th className="p-2 border">World Ranking</th>
            </tr>
          </thead>
          <tbody>
            {[
              { university: "Kazakh National Medical University", countryRank: 6, worldRank: 4279 },
              { university: "Karaganda State Medical University", countryRank: 34, worldRank: 7540 },
              { university: "Semey State Medical University", countryRank: 15, worldRank: 6008 },
              { university: "South Kazakhstan State Medical Academy", countryRank: 21, worldRank: 6901 },
              { university: "West Kazakhstan State Medical Academy", countryRank: 54, worldRank: 9192 },
            ].map((item, index) => (
              <tr key={index} className="border-b">
                <td className="p-2 border">{item.university}</td>
                <td className="p-2 border">{item.countryRank}</td>
                <td className="p-2 border">{item.worldRank}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}
