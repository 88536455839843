import React, { useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiRefreshCw, FiCopy } from "react-icons/fi";
import { FaEraser } from "react-icons/fa"; // Import share icon
import { FaHistory } from "react-icons/fa";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined";
import { marked } from "marked";
import ResponseItem from "./ResponseItem";
import { checkAuthStatus, loginRedirect } from "../firebase";
const genAI = new GoogleGenerativeAI("AIzaSyDDkGZxuso50j3ZKUFjrLNcxG77jBoZY30");
const API_KEY = "AIzaSyB34QVcg42nahHorHdeGUSkdoUy6hoLPO0"; // Replace with your Google API key
const SEARCH_ENGINE_ID = "83c5972f89dfd4689"; // Replace with your Search Engine ID
//import "./GeminiComponent.css";
const GeminiComponent = () => {
  const [input, setInput] = useState("");
  const [responses, setResponses] = useState([null, null, null, null]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gridSize, setGridSize] = useState(4);
  const [selectedModels, setSelectedModels] = useState([
    "Gemini",
    "ChatGPT",
    "Claude",
    "Perplexity",
  ]);
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [webAccess, setWebAccess] = useState([false, false, false, false]);
  const models = [
    {
      name: "Gemini",
      img: "https://th.bing.com/th/id/OIP.uEocsubHXbVX3egDqAY-YwAAAA?w=146&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    },
    {
      name: "ChatGPT",
      img: "https://th.bing.com/th/id/OIP.fTGQtKmOTbOO5qHurf6rPwHaEK?w=263&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    },
    {
      name: "Claude",
      img: "https://th.bing.com/th/id/OIP.67VP2NuBgm1YRWhR4W038gAAAA?w=176&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    },
    {
      name: "Perplexity",
      img: "https://th.bing.com/th/id/OIP.j_46DDENRisOFr1rg6LiOAHaHa?w=155&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    },
  ];

  const handleInputChange = (e) => setInput(e.target.value);

  const handleModelChange = (index, selectedOption) => {
    const updatedModels = [...selectedModels];
    updatedModels[index] = selectedOption.value;
    setSelectedModels(updatedModels);
  };

  const fetchWebResults = async (query) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(
          query
        )}&key=${API_KEY}&cx=${SEARCH_ENGINE_ID}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch web results");
      }

      const data = await response.json();

      if (data.items && data.items.length > 0) {
        // Convert web results into HTML format
        return data.items
          .map((item) => {
            return `
            <div class="web-result">
              <h3><a href="${item.link}" target="_blank">${item.title}</a></h3>
              <p>${item.snippet}</p>
            </div>
          `;
          })
          .join("");
      } else {
        return `<p>No web results found for "${query}".</p>`;
      }
    } catch (err) {
      console.error("Error fetching web results:", err);
      throw new Error("Failed to fetch web results");
    }
  };
  const fetchResponse = async (model, index) => {
    try {
      let updatedInput = input;

      if (history.length > 0) {
        updatedInput =
          history.map((item) => item.input).join(" ") + " " + input;
      }

      if (webAccess) {
        const webResult = await fetchWebResults(input);

        return `<div class="web-response">${webResult}</div>`;
      } else {
        const modelInstance = genAI.getGenerativeModel({
          model: "gemini-1.5-flash",
        });

        const result = await modelInstance.generateContent([updatedInput]);

        console.log("LLM Response:", result);

        if (
          result &&
          result.response &&
          result.response.candidates &&
          result.response.candidates.length > 0
        ) {
          let llmResponse = result.response.candidates[0].content.parts[0].text;

          if (typeof llmResponse === "string") {
            llmResponse = marked(llmResponse);

            return `<div class="llm-response">${llmResponse}</div>`;
          } else {
            throw new Error("LLM response is not a valid string");
          }
        } else {
          throw new Error("No candidates found in LLM response");
        }
      }
    } catch (err) {
      console.error("Error fetching response:", err);
      throw new Error("Failed to fetch response");
    }
  };

  const displayfourgrid = () => {
    console.log("3");
    setGridSize(4);
    setResponses(["Gemini", "ChatGPT", "Claude", "Perplexity"]);
  };

  const displaythreegrid = () => {
    console.log("3");
    setGridSize(3);
    setResponses(["Gemini", "ChatGPT", "Claude"]);
  };

  const displaytwogrid = async () => {
    console.log("2");
    setGridSize(2);
    setResponses(["Gemini", "ChatGPT"]);
  };

  const fetchResponses = async () => {
    setLoading(true);
    setError(null);
    setResponses(Array(gridSize).fill(null));

    try {
      const newResponses = await Promise.all(
        selectedModels.slice(0, gridSize).map((model) => fetchResponse(model))
      );

      console.log(newResponses);

      setResponses(newResponses); // Store only LLM responses
      setHistory((prevHistory) => [
        ...prevHistory,
        { input, responses: newResponses },
      ]);
    } catch (err) {
      setError(err.response ? err.response.data : "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const toggleHistory = () => setShowHistory(!showHistory);
  const clearHistory = () => setHistory([]);
  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  }
  const regenerateResponse = debounce(async (index) => {
    try {
      const newResponse = await fetchResponse(selectedModels[index]);
      const updatedResponses = [...responses];
      updatedResponses[index] = newResponse;
      setResponses(updatedResponses);
    } catch (err) {
      setError(
        `Failed to regenerate response for ${selectedModels[index]}:${err.message}`
      );
    }
  }, 2000);
  const clearResponse = (index) => {
    const updatedResponses = [...responses];
    updatedResponses[index] = null;
    setResponses(updatedResponses);
  };
  const modelOptions = models.map((model) => ({
    value: model.name,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={model.img}
          alt={model.name}
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        {model.name}
      </div>
    ),
  }));

  const handleKeyDown = (e) => {
    if (e.key === "Enter") fetchResponses();
  };
  const isMobile = window.innerWidth <= 768;
  return (
    <>
      <div
        style={{
          textAlign: "center", // Centers the text horizontally
          fontSize: "2rem", // Adjust font size
          fontWeight: "bold", // Makes the text bold
          padding: "7px", // Adds padding around the heading
          color: "Black", // White text color
        }}
      >
        ADMISSION AI CHAT
      </div>
      <div
        style={{
          textAlign: "center", // Centers the text horizontally
          fontSize: "1.5rem", // Adjust font size
          fontWeight: "normal", // Makes the text normal weight
          padding: "7px", // Adds padding around the heading
          color: "Black", // Text color
        }}
      >
        Search any college anywhere!
      </div>
      <div style={{ display: "flex", fontFamily: "Arial", positon: "fixed" }}>
        <div style={{ flex: 1, padding: "20px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "repeat(2, 1fr)",
              gap: "20px",
            }}
          >
            {responses.map((response, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "20px",
                  background: "#f9f9f9",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",

                  height: gridSize === 2 ? "550px" : "250px",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Select
                    value={modelOptions.find(
                      (option) => option.value === selectedModels[index]
                    )}
                    onChange={(selectedOption) =>
                      handleModelChange(index, selectedOption)
                    }
                    options={modelOptions}
                    isSearchable={false}
                    styles={{
                      container: (base) => ({ ...base, width: "30%" }),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={
                        isMobile
                          ? {
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }
                          : {}
                      }
                    >
                      <label style={{ marginRight: "10px" }}>Web Access:</label>

                      <button
                        onClick={() => setWebAccess(!webAccess)}
                        style={{
                          padding: "10px 20px",
                          background: webAccess ? "#28a745" : "#007bff",
                          color: "#fff",
                          borderRadius: "5px",
                          marginLeft: "10px",
                        }}
                      >
                        {webAccess ? "Web Access: On" : "Web Access: Off"}
                      </button>
                    </div>
                    <button
                      onClick={() => clearResponse(index)}
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        border: "none",
                      }}
                    >
                      <FaEraser />
                    </button>
                    <button
                      onClick={toggleHistory}
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      title="History"
                    >
                      <FaHistory size={20} color="black" />
                    </button>
                  </div>
                  {error && <p style={{ color: "red" }}>Error:{error}</p>}

                  {showHistory && (
                    <div
                      style={{
                        position: "fixed",
                        top: "10%",
                        left: "10%",
                        width: "80%",
                        background: "#fff",
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
                        zIndex: 1000,
                      }}
                    >
                      <h2>History</h2>
                      <div
                        style={{
                          maxHeight: "400px", // Set a maximum height
                          overflowY: "auto", // Enable scrolling for overflow
                          marginBottom: "10px",
                        }}
                      >
                        {history.length === 0 ? (
                          <p>No history available</p>
                        ) : (
                          <ul>
                            {history.map((item, index) => (
                              <li key={index}>
                                <strong>Input:</strong> {item.input}
                                <ul>
                                  {item.responses.map((res, i) => (
                                    <ResponseItem
                                      key={i}
                                      model={selectedModels[i]}
                                      response={res}
                                    />
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <button
                        onClick={clearHistory}
                        style={{
                          padding: "10px 20px",
                          background: "#dc3545",
                          color: "#fff",
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                      >
                        Clear History
                      </button>
                      <button
                        onClick={toggleHistory}
                        style={{
                          padding: "10px 20px",
                          background: "#007bff",
                          color: "#fff",
                          borderRadius: "5px",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "lightgrey",
                    padding: "5px",
                    borderRadius: "7px",
                    marginTop: "15px",
                  }}
                >
                  <strong style={{ marginLeft: "10px", marginTop: "5px" }}>
                    USER:
                  </strong>
                  <p
                    style={{
                      fontWeight: "bolder",
                      color: "brown",
                    }}
                  >
                    {input}
                  </p>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CopyToClipboard text={input || ""}>
                      <button
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        title="Copy"
                      >
                        <FiCopy size={20} />
                      </button>
                    </CopyToClipboard>
                    <button
                      onClick={() => regenerateResponse(index)}
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      title="Regenerate"
                    >
                      <FiRefreshCw size={20} color="#007bff" />
                    </button>
                    {/* Share Button */}
                  </div>
                </div>
                <div>
                  <strong>ASSISTANT:</strong>
                  <p>
                    {response ? (
                      <div
                        className="response-content"
                        dangerouslySetInnerHTML={{
                          __html: responses[index],
                        }}
                      />
                    ) : (
                      <p></p>
                    )}
                  </p>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CopyToClipboard text={response || ""}>
                      <button
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        title="Copy"
                      >
                        <FiCopy size={20} color="black" />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: "flex", marginTop: "5%" }}>
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter your input"
              style={{
                fontweight: "bold",
                padding: "10px",
                width: "80%",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
            <ViewStreamOutlinedIcon
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={displaytwogrid}
            />
            {/* <img src="./dots.png" alt="image icon" /> */}
            <ViewComfyOutlinedIcon
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={displaythreegrid}
            />
            <WindowOutlinedIcon
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={displayfourgrid}
            />

            <button
              onClick={fetchResponses}
              style={{
                padding: "10px 20px",
                background: "#007bff",
                color: "#fff",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Send"}
            </button>
          </div>
          {error && <p style={{ color: "red" }}>Error: {error}</p>}
        </div>
      </div>
    </>
  );
};
export default GeminiComponent;
