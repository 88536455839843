import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStethoscope,
  faHeartbeat,
  faUserMd,
  faNotesMedical,
  faBookOpen,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { catlogo, xatlogo, matlogo, Gmatlogo, cmatlogo, snaplogo, ibsatlogo, NMATlogo, tisnetlogo } from "../../images/index";

// MBA Exams array
const mbaExams = [
  { id: 1, name: "GGSIPU MBA", icon: "https://media.9curry.com/uploads/organization/image/742/ggsipu.png" },
  { id: 2, name: "XAT", icon: xatlogo },
  { id: 3, name: "MAT", icon: matlogo },
  { id: 4, name: "GMAT", icon: Gmatlogo },
  { id: 5, name: "CMAT", icon: cmatlogo },
  { id: 6, name: "SNAP", icon: snaplogo },
  { id: 7, name: "IBSAT", icon: ibsatlogo },
  { id: 8, name: "NMAT", icon: NMATlogo },
  { id: 9, name: "TISSNET", icon: tisnetlogo },
];

const MBAPredictor = () => {
  const [selectedExams, setSelectedExams] = useState([]);
  const navigate = useNavigate();

  const handleExamClick = (exam) => {
    // Navigate based on selected exam
    if (exam.name === "GGSIPU MBA") {
      navigate("/freeggsipupredictor");
    } else if (exam.name === "XAT") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "MAT") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "GMAT") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "CMAT") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "SNAP") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "IBSAT") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "NMAT") {
      navigate("#"); // Replace with actual route
    } else if (exam.name === "TISSNET") {
      navigate("#"); // Replace with actual route
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        MBA Course Predictor
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on MBA exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
        Select exam you have taken:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {mbaExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamClick(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">{exam.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MBAPredictor;
