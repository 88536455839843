import React from 'react';
const Predictor = () => {

  const Btechimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/Btechimg.jpg";
  const designimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/designimg.jpg";
  const hospilatityimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/hospilatityimg.jpg";
  const lawimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/lawimg.jpg";
  const managementimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/managementimg.jpg";
  const medicalimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/medicalimg.jpg";
  const mtechimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/mtechimg.jpg";
  const scienceimg =
    "https://del1.vultrobjects.com/caderaedu/Predictor%20Mobile%20Images/scienceimg.jpg";


  return (
    <div className="p-6 bg-gradient-to-r from-customTeal to-yellow-500 rounded-xl shadow-lg">
      <h3 className="text-2xl font-bold text-black mb-6 text-center">
        Predict Colleges Based on Exams You Have Taken
      </h3>
      <div className="space-y-6">
        <a
          href="/BtechPredictor" // Replace with actual URL for B. Tech.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Btechimg}
            alt="B. Tech."
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">B. Tech.</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/MBAPredictor" // Replace with actual URL for MBA.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={managementimg}
            alt="MBA"
            className="w-40 h-14 object-cover mr-4 border-2"
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">MBA</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/MedicalPredictor" // Replace with actual URL for Medical.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={medicalimg}
            alt="Medical"
            className="w-40 h-14 object-cover mr-4 border-2"
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Medical</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/MTechPredictormobileview" // Replace with actual URL for M. Tech.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={mtechimg}
            alt="M. Tech"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">M. Tech</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/DesignPredictorMobile" // Replace with actual URL for Design.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={designimg}
            alt="Design"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Design</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/LawPredictor" // Replace with actual URL for Law.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={lawimg}
            alt="Law"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Law</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/hospitalitypredictor" // Replace with actual URL for Hospitality.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={hospilatityimg}
            alt="Hospitality"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Hospitality</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>

        <a
          href="/sciencepredictor" // Replace with actual URL for Science.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={scienceimg}
            alt="Science"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Science</h3>
            <p className="text-gray-600">Predictor</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Predictor;
