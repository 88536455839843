[
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "(Discontinued May 2023) MBA (FULL TIME)",
    "Score": 1,
    "Category": "General"
  },
  {
    "Name": "Madhav Institute of Technology and Science",
    "Course": "Master of Business Administration(MBA)",
    "Score": 160,
    "Category": "General"
  },
  {
    "Name": "Gyan Ganga Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 174,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management Jabalpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 212,
    "Category": "General"
  },
  {
    "Name": "Jiwaji University, Gwalior",
    "Course": "Master of Business Administration (MBA)",
    "Score": 240,
    "Category": "General"
  },
  {
    "Name": "Lakshmi Narain College of Technology Excellence",
    "Course": "MBA in Marketing Management",
    "Score": 298,
    "Category": "General"
  },
  {
    "Name": "Institute of Technology and Management, Gwalior",
    "Course": "Master of Business Administration(MBA)",
    "Score": 359,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 481,
    "Category": "General"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "MBA in Marketing Management",
    "Score": 582,
    "Category": "General"
  },
  {
    "Name": "Shree Atal Bihari Vajpayee Govt Art's and Commerce College",
    "Course": "Master of Business Administration(MBA)",
    "Score": 691,
    "Category": "General"
  },
  {
    "Name": "Academy of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 116,
    "Category": "General"
  },
  {
    "Name": "International Institute of Professional Studies, Devi Ahilya Vishwavidhyalaya",
    "Course": "Discontinued( June 2022)- Master of Business Administration(MBA)",
    "Score": 173,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 211,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 213,
    "Category": "General"
  },
  {
    "Name": "Shri Ram Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 256,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in International Business",
    "Score": 340,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Personnel Administration",
    "Score": 393,
    "Category": "General"
  },
  {
    "Name": "Vindhya Institute of Management and Research, Satna",
    "Course": "Master of Business Administration(MBA)",
    "Score": 531,
    "Category": "General"
  },
  {
    "Name": "Millennium Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 642,
    "Category": "General"
  },
  {
    "Name": "Oriental College of Management, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 711,
    "Category": "General"
  },
  {
    "Name": "Radiant Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 735,
    "Category": "General"
  },
  {
    "Name": "Bansal Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 747,
    "Category": "General"
  },
  {
    "Name": "Shri G. S. Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 786,
    "Category": "General"
  },
  {
    "Name": "Astral Institute of Technology and Research (Astral, Indore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 812,
    "Category": "General"
  },
  {
    "Name": "Maharana Pratap College of Technology Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 862,
    "Category": "General"
  },
  {
    "Name": "Malwa Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 878,
    "Category": "General"
  },
  {
    "Name": "BSSS Institute of Advanced Studies",
    "Course": "MBA Dual and Post graduate programme in Managerial capacity building (PGP-MCB) from synergy University, Moscow",
    "Score": 893,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 931,
    "Category": "General"
  },
  {
    "Name": "Arihant Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 944,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research, Gwalior",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1052,
    "Category": "General"
  },
  {
    "Name": "LNCT Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 738,
    "Category": "General"
  },
  {
    "Name": "Graduate School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 756,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management and Research, Gwalior",
    "Course": "MBA in Business Analytics",
    "Score": 804,
    "Category": "General"
  },
  {
    "Name": "Girdhar Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 844,
    "Category": "General"
  },
  {
    "Name": "Apex Institute of Management and Research (AIMR, Indore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 867,
    "Category": "General"
  },
  {
    "Name": "IPS Academy",
    "Course": "MBA in International Business",
    "Score": 892,
    "Category": "General"
  },
  {
    "Name": "Idyllic Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 897,
    "Category": "General"
  },
  {
    "Name": "RGPV - Rajiv Gandhi Proudyogiki Vishwavidyalaya",
    "Course": "Master of Business Administration(MBA)",
    "Score": 931,
    "Category": "General"
  },
  {
    "Name": "Oriental Institute of Management, Lalburra",
    "Course": "MBA in Finance",
    "Score": 1014,
    "Category": "General"
  },
  {
    "Name": "Prestige Institute of Management Dewas",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1053,
    "Category": "General"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1055,
    "Category": "General"
  },
  {
    "Name": "Gyan Ganga College of Technology (GGCT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1089,
    "Category": "General"
  },
  {
    "Name": "Indore Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1108,
    "Category": "General"
  },
  {
    "Name": "Shri RGP Gujarati Professional Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1135,
    "Category": "General"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 1148,
    "Category": "General"
  },
  {
    "Name": "Acropolis Faculty of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1176,
    "Category": "General"
  },
  {
    "Name": "Lakshmi Narain College of Technology Excellence",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1198,
    "Category": "General"
  },
  {
    "Name": "Jawaharlal Nehru College Of Technology, Rewa",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1201,
    "Category": "General"
  },
  {
    "Name": "Acropolis Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1210,
    "Category": "General"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1216,
    "Category": "General"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Financial Administration",
    "Score": 1057,
    "Category": "General"
  },
  {
    "Name": "Indore International College, Dhar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1103,
    "Category": "General"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Financial Administration",
    "Score": 1134,
    "Category": "General"
  },
  {
    "Name": "Maharaja Ranjit Singh Group of Institutions",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1145,
    "Category": "General"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Marketing Management",
    "Score": 1162,
    "Category": "General"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Marketing Management",
    "Score": 1181,
    "Category": "General"
  },
  {
    "Name": "Govindram Seksaria Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1199,
    "Category": "General"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 1207,
    "Category": "General"
  },
  {
    "Name": "IPS Academy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1212,
    "Category": "General"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1220,
    "Category": "General"
  },
  {
    "Name": "BSSS Institute of Advanced Studies",
    "Course": "MBA Finance and Post graduate programme in Managerial capacity building (PGP-MCB) from synergy University, Moscow",
    "Score": 1222,
    "Category": "General"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "MBA in Financial Administration",
    "Score": 1314,
    "Category": "General"
  },
  {
    "Name": "Adarsh College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1456,
    "Category": "General"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Bhopal",
    "Course": "MBA in Financial Management",
    "Score": 1509,
    "Category": "General"
  },
  {
    "Name": "Chameli Devi Institute of Professional Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1652,
    "Category": "General"
  },
  {
    "Name": "CH College, Indore",
    "Course": "MBA in Marketing Management",
    "Score": 1766,
    "Category": "General"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2064,
    "Category": "General"
  },
  {
    "Name": "Rajeev Gandhi Prodyogiki Mahavidhyalaya, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2273,
    "Category": "General"
  },
  {
    "Name": "Bhopal Institute of Technology and Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2394,
    "Category": "General"
  },
  {
    "Name": "IPER - Institute of Professional Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1223,
    "Category": "General"
  },
  {
    "Name": "Sri Aurobindo Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1329,
    "Category": "General"
  },
  {
    "Name": "Sri Aurobindo Institute of Technology",
    "Course": "MBA in Hospital Administration",
    "Score": 1473,
    "Category": "General"
  },
  {
    "Name": "Artemisia Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1608,
    "Category": "General"
  },
  {
    "Name": "Hitkarini College of Engineering and Technology (HCET)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1695,
    "Category": "General"
  },
  {
    "Name": "Rajeev Gandhi Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1885,
    "Category": "General"
  },
  {
    "Name": "IPS Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2104,
    "Category": "General"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2291,
    "Category": "General"
  },
  {
    "Name": "Sushila Devi Bansal College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2434,
    "Category": "General"
  },
  {
    "Name": "BSSS Institute of Advanced Studies",
    "Course": "MBA Dual and Post graduate programme in Managerial capacity building (PGP-MCB) from synergy University, Moscow",
    "Score": 332,
    "Category": "OBC"
  },
  {
    "Name": "Laxmipati Institute Of Science & Technology,Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 392,
    "Category": "OBC"
  },
  {
    "Name": "BSSS Institute of Advanced Studies",
    "Course": "MBA Finance and Post graduate programme in Managerial capacity building (PGP-MCB) from synergy University, Moscow",
    "Score": 504,
    "Category": "OBC"
  },
  {
    "Name": "Oriental College of Technology, Bhopal",
    "Course": "Master of Business Administration(MBA)",
    "Score": 625,
    "Category": "OBC"
  },
  {
    "Name": "Shri Ram Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 748,
    "Category": "OBC"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Financial Administration",
    "Score": 853,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Financial Administration",
    "Score": 967,
    "Category": "OBC"
  },
  {
    "Name": "Sri Dadaji Institute of Technology and Science",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 1061,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1121,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 1164,
    "Category": "OBC"
  },
  {
    "Name": "Vikrant Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 366,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 490,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management Jabalpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 575,
    "Category": "OBC"
  },
  {
    "Name": "BM Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 663,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 801,
    "Category": "OBC"
  },
  {
    "Name": "Idyllic Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 908,
    "Category": "OBC"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Marketing Management",
    "Score": 1002,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy",
    "Course": "MBA in International Business",
    "Score": 1067,
    "Category": "OBC"
  },
  {
    "Name": "Acropolis Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1143,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research, Gwalior",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1166,
    "Category": "OBC"
  },
  {
    "Name": "IPER - Institute of Professional Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1174,
    "Category": "OBC"
  },
  {
    "Name": "Indore Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1179,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 1195,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Marketing Management",
    "Score": 1202,
    "Category": "OBC"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Bhopal",
    "Course": "MBA in Financial Management",
    "Score": 1205,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 1214,
    "Category": "OBC"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1504,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 1985,
    "Category": "OBC"
  },
  {
    "Name": "Rajeev Gandhi Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1997,
    "Category": "OBC"
  },
  {
    "Name": "Patel Group of Institutions (Patel College of Science & Technology, Indore)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2032,
    "Category": "OBC"
  },
  {
    "Name": "Chameli Devi Institute of Professional Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1175,
    "Category": "OBC"
  },
  {
    "Name": "Acropolis Faculty of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1183,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Personnel Administration",
    "Score": 1196,
    "Category": "OBC"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1204,
    "Category": "OBC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in International Business",
    "Score": 1209,
    "Category": "OBC"
  },
  {
    "Name": "IPS Academy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1224,
    "Category": "OBC"
  },
  {
    "Name": "Lakshmi Narain College of Technology Excellence",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1551,
    "Category": "OBC"
  },
  {
    "Name": "Sri Aurobindo Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1988,
    "Category": "OBC"
  },
  {
    "Name": "Apex Institute of Management and Research (AIMR, Indore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2016,
    "Category": "OBC"
  },
  {
    "Name": "Shri Ram College of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2257,
    "Category": "OBC"
  },
  {
    "Name": "TIT - Technocrates Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2323,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Technology and Management, Gwalior",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2411,
    "Category": "OBC"
  },
  {
    "Name": "Gyan Ganga Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2455,
    "Category": "OBC"
  },
  {
    "Name": "Gyan Ganga College of Technology (GGCT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2512,
    "Category": "OBC"
  },
  {
    "Name": "Sushila Devi Bansal College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2364,
    "Category": "OBC"
  },
  {
    "Name": "Govindram Seksaria Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2447,
    "Category": "OBC"
  },
  {
    "Name": "Sushila Devi Bansal College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2495,
    "Category": "OBC"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Indore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 290,
    "Category": "SC"
  },
  {
    "Name": "IPER - Institute of Professional Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 736,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research, Gwalior",
    "Course": "Master of Business Administration (MBA)",
    "Score": 953,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1167,
    "Category": "SC"
  },
  {
    "Name": "Acropolis Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2331,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in International Business",
    "Score": 2506,
    "Category": "SC"
  },
  {
    "Name": "Sushila Devi Bansal College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2529,
    "Category": "SC"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Financial Administration",
    "Score": 630,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 751,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 1132,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Personnel Administration",
    "Score": 1507,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 2475,
    "Category": "SC"
  },
  {
    "Name": "IPS Academy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2515,
    "Category": "SC"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 532,
    "Category": "ST"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Personnel Administration",
    "Score": 2148,
    "Category": "ST"
  },
  {
    "Name": "IPS Academy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2453,
    "Category": "ST"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2011,
    "Category": "ST"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in International Business",
    "Score": 2405,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies, Devi Ahilya Vishwavidyalaya",
    "Course": "(Discontinued May 2023) MBA (FULL TIME)",
    "Score": 82,
    "Category": "PWD"
  },
  {
    "Name": "Madhav Institute of Technology and Science",
    "Course": "Master of Business Administration(MBA)",
    "Score": 160,
    "Category": "PWD"
  },
  {
    "Name": "Shri Ram Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 256,
    "Category": "PWD"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "MBA in Marketing Management",
    "Score": 582,
    "Category": "PWD"
  },
  {
    "Name": "Millennium Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 642,
    "Category": "PWD"
  },
  {
    "Name": "Radiant Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 735,
    "Category": "PWD"
  },
  {
    "Name": "Oriental College of Management, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 764,
    "Category": "PWD"
  },
  {
    "Name": "Astral Institute of Technology and Research (Astral, Indore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 812,
    "Category": "PWD"
  },
  {
    "Name": "Maharana Pratap College of Technology Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 862,
    "Category": "PWD"
  },
  {
    "Name": "Malwa Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 878,
    "Category": "PWD"
  },
  {
    "Name": "Academy of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 116,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Professional Studies, Devi Ahilya Vishwavidhyalaya",
    "Course": "Discontinued( June 2022)- Master of Business Administration(MBA)",
    "Score": 173,
    "Category": "PWD"
  },
  {
    "Name": "Lakshmi Narain College of Technology Excellence",
    "Course": "MBA in Marketing Management",
    "Score": 298,
    "Category": "PWD"
  },
  {
    "Name": "Jiwaji University, Gwalior",
    "Course": "Master of Business Administration (MBA)",
    "Score": 593,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 734,
    "Category": "PWD"
  },
  {
    "Name": "Graduate School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 756,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 774,
    "Category": "PWD"
  },
  {
    "Name": "Girdhar Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 844,
    "Category": "PWD"
  },
  {
    "Name": "Apex Institute of Management and Research (AIMR, Indore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 867,
    "Category": "PWD"
  },
  {
    "Name": "Idyllic Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 897,
    "Category": "PWD"
  },
  {
    "Name": "RGPV - Rajiv Gandhi Proudyogiki Vishwavidyalaya",
    "Course": "Master of Business Administration(MBA)",
    "Score": 931,
    "Category": "PWD"
  },
  {
    "Name": "Oriental Institute of Management, Lalburra",
    "Course": "MBA in Finance",
    "Score": 1014,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in International Business",
    "Score": 1053,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Personnel Administration",
    "Score": 1115,
    "Category": "PWD"
  },
  {
    "Name": "Maharaja Ranjit Singh Group of Institutions",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1145,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Advertising and Public Relations",
    "Score": 1225,
    "Category": "PWD"
  },
  {
    "Name": "Arihant Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1320,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management Jabalpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1339,
    "Category": "PWD"
  },
  {
    "Name": "Adarsh College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1456,
    "Category": "PWD"
  },
  {
    "Name": "LNCT Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1474,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 931,
    "Category": "PWD"
  },
  {
    "Name": "Shri G. S. Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1050,
    "Category": "PWD"
  },
  {
    "Name": "Indore International College, Dhar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1103,
    "Category": "PWD"
  },
  {
    "Name": "Shri RGP Gujarati Professional Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1135,
    "Category": "PWD"
  },
  {
    "Name": "Jawaharlal Nehru College Of Technology, Rewa",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1201,
    "Category": "PWD"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "MBA in Financial Administration",
    "Score": 1314,
    "Category": "PWD"
  },
  {
    "Name": "Sri Aurobindo Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1329,
    "Category": "PWD"
  },
  {
    "Name": "Bansal Institute of Science and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1389,
    "Category": "PWD"
  },
  {
    "Name": "Sri Aurobindo Institute of Technology",
    "Course": "MBA in Hospital Administration",
    "Score": 1473,
    "Category": "PWD"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Bhopal",
    "Course": "MBA in Financial Management",
    "Score": 1509,
    "Category": "PWD"
  },
  {
    "Name": "Artemisia Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1608,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Financial Administration",
    "Score": 1655,
    "Category": "PWD"
  },
  {
    "Name": "Hitkarini College of Engineering and Technology (HCET)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1695,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Technology and Management, Gwalior",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1805,
    "Category": "PWD"
  },
  {
    "Name": "BSSS Institute of Advanced Studies",
    "Course": "MBA Dual and Post graduate programme in Managerial capacity building (PGP-MCB) from synergy University, Moscow",
    "Score": 1914,
    "Category": "PWD"
  },
  {
    "Name": "Shree Atal Bihari Vajpayee Govt Art's and Commerce College",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1991,
    "Category": "PWD"
  },
  {
    "Name": "IPS Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2104,
    "Category": "PWD"
  },
  {
    "Name": "Rajeev Gandhi Prodyogiki Mahavidhyalaya, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2273,
    "Category": "PWD"
  },
  {
    "Name": "Chameli Devi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2291,
    "Category": "PWD"
  },
  {
    "Name": "Lakshmi Narain College of Technology Excellence",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2389,
    "Category": "PWD"
  },
  {
    "Name": "Chameli Devi Institute of Professional Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 1652,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management Dewas",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1689,
    "Category": "PWD"
  },
  {
    "Name": "CH College, Indore",
    "Course": "MBA in Marketing Management",
    "Score": 1766,
    "Category": "PWD"
  },
  {
    "Name": "Rajeev Gandhi Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1885,
    "Category": "PWD"
  },
  {
    "Name": "Gyan Ganga College of Technology (GGCT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1929,
    "Category": "PWD"
  },
  {
    "Name": "Lal Bahadur Shastri Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2064,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy",
    "Course": "MBA in International Business",
    "Score": 2170,
    "Category": "PWD"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2288,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Marketing Management",
    "Score": 2313,
    "Category": "PWD"
  },
  {
    "Name": "Bhopal Institute of Technology and Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2394,
    "Category": "PWD"
  },
  {
    "Name": "Acropolis Faculty of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2401,
    "Category": "PWD"
  },
  {
    "Name": "Sushila Devi Bansal College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2434,
    "Category": "PWD"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Marketing Management",
    "Score": 2450,
    "Category": "PWD"
  },
  {
    "Name": "Govindram Seksaria Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2469,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 2479,
    "Category": "PWD"
  },
  {
    "Name": "Acropolis Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2498,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2516,
    "Category": "PWD"
  },
  {
    "Name": "IPER - Institute of Professional Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2522,
    "Category": "PWD"
  },
  {
    "Name": "BSSS Institute of Advanced Studies",
    "Course": "MBA Finance and Post graduate programme in Managerial capacity building (PGP-MCB) from synergy University, Moscow",
    "Score": 2527,
    "Category": "PWD"
  },
  {
    "Name": "Vindhya Institute of Management and Research, Satna",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2423,
    "Category": "PWD"
  },
  {
    "Name": "Lakshmi Narain College of Technology, Bhopal",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2435,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 2467,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research, Gwalior",
    "Course": "MBA in Business Analytics",
    "Score": 2477,
    "Category": "PWD"
  },
  {
    "Name": "Indore Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2489,
    "Category": "PWD"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Financial Administration",
    "Score": 2500,
    "Category": "PWD"
  },
  {
    "Name": "Gyan Ganga Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2521,
    "Category": "PWD"
  },
  {
    "Name": "Prestige Institute of Management and Research, Gwalior",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2526,
    "Category": "PWD"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 2528,
    "Category": "PWD"
  },
  {
    "Name": "Acropolis Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 368,
    "Category": "EWS"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Marketing Management",
    "Score": 572,
    "Category": "EWS"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 902,
    "Category": "EWS"
  },
  {
    "Name": "Shivajirao Kadam Institute of Technology & Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1016,
    "Category": "EWS"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Financial Administration",
    "Score": 1081,
    "Category": "EWS"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "MBA in Financial Administration",
    "Score": 1099,
    "Category": "EWS"
  },
  {
    "Name": "IPS Academy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1217,
    "Category": "EWS"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research",
    "Course": "MBA in Marketing Management",
    "Score": 2375,
    "Category": "EWS"
  },
  {
    "Name": "Indore Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 521,
    "Category": "EWS"
  },
  {
    "Name": "Govindram Seksaria Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 757,
    "Category": "EWS"
  },
  {
    "Name": "IPER - Institute of Professional Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 961,
    "Category": "EWS"
  },
  {
    "Name": "IPS Academy Institute of Business Management and Research, Sanwer",
    "Course": "MBA in Financial Administration",
    "Score": 1031,
    "Category": "EWS"
  },
  {
    "Name": "IPS Academy",
    "Course": "MBA in International Business",
    "Score": 1090,
    "Category": "EWS"
  },
  {
    "Name": "Acropolis Faculty of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1156,
    "Category": "EWS"
  },
  {
    "Name": "Shri Vaishnav Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2124,
    "Category": "EWS"
  }
]
