import React from "react";

const Footer = () => {
  return (
    <div className="flex flex-col">
      <footer className="bg-black text-white py-[5px] text-center border border-gray-500 mt-auto">
        <div className="footer-content max-w-[1200px] mx-auto px-5">
          <div className="footer-links flex flex-wrap justify-between py-5">
            {/* Footer Column */}
            <div className="footer-column w-full sm:w-1/2 md:w-1/5 mx-5 mb-4">
              <h3 className="mb-4 font-bold">Top Colleges</h3>
              <ul className="list-none p-0">
                
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/Btech">B.Tech/B.E</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MTech">M.Tech</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MCA">MCA</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/BCA">BCA</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MBA/PGDM">M.B.A</a>
                </li>
              </ul>
            </div>
            {/* Repeat similar structure for other columns */}
            <div className="footer-column w-full sm:w-1/2 md:w-1/5 mx-5 mb-4">
              <h3 className="mb-4 font-bold">Top Universities</h3>
              <ul className="list-none p-0">
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/Btech">Engineering</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MBBS">Medical</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MBA/PGDM">Management</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/law">Law</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/commerce">Commerce</a>
                </li>
              </ul>
            </div>
            <div className="footer-column w-full sm:w-1/2 md:w-1/5 mx-5 mb-4">
              <h3 className="mb-4 font-bold">Top Exams</h3>
              <ul className="list-none p-0">
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/exam/JEE">Engineering</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="#">Medical</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="#">Management</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="#">Law</a>
                </li>
              </ul>
            </div>
            <div className="footer-column w-full sm:w-1/2 md:w-1/5 mx-5 mb-4">
              <h3 className="mb-4 font-bold">Best Studies In India</h3>
              <ul className="list-none p-0">
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/Btech">Engineering</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MBBS">Medical</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/MBA/PGDM">Management</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/law">Law</a>
                </li>
              </ul>
            </div>
            <div className="footer-column w-full sm:w-1/2 md:w-1/5 mx-5 mb-4">
              <h3 className="mb-4 font-bold">Entrance Exams</h3>
              <ul className="list-none p-0">
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/exam/Jee">JEE Mains</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/exam/neetug">NEET UG</a>
                </li>
                <li className="mb-0 hover:text-[#68a4a5] transition-colors duration-300">
                  <a href="/exam/cat">CAT</a>
                </li>
              </ul>
            </div>
          </div>
          <p className="mt-8">© 2025 Cadera Edu | All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
