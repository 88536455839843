[
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 109,
    "Category": "General"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in Port and Shipping Management",
    "Score": 347,
    "Category": "General"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in Port and Shipping Management",
    "Score": "1,004",
    "Category": "General"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 221,
    "Category": "General"
  },
  {
    "Name": "IMU - IMU Kolkata Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": "1,012",
    "Category": "General"
  },
  {
    "Name": "IMU - IMU Vizag Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 391,
    "Category": "General"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 213,
    "Category": "OBC"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in Port and Shipping Management",
    "Score": 386,
    "Category": "OBC"
  },
  {
    "Name": "IMU - IMU Kolkata Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": "1,009",
    "Category": "OBC"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 388,
    "Category": "OBC"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in Port and Shipping Management",
    "Score": 397,
    "Category": "OBC"
  },
  {
    "Name": "IMU - IMU Vizag Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": "1,011",
    "Category": "OBC"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": "1,002",
    "Category": "SC"
  },
  {
    "Name": "IMU - IMU Kolkata Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 355,
    "Category": "SC"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in Port and Shipping Management",
    "Score": 419,
    "Category": "SC"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 311,
    "Category": "SC"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in Port and Shipping Management",
    "Score": 409,
    "Category": "SC"
  },
  {
    "Name": "IMU - IMU Vizag Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 92,
    "Category": "ST"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 304,
    "Category": "ST"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in Port and Shipping Management",
    "Score": 342,
    "Category": "ST"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 122,
    "Category": "ST"
  },
  {
    "Name": "IMU - IMU Kolkata Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 272,
    "Category": "ST"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 122,
    "Category": "PWD"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 184,
    "Category": "PWD"
  },
  {
    "Name": "IMU - IMU Vizag Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 336,
    "Category": "PWD"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in Port and Shipping Management",
    "Score": 157,
    "Category": "PWD"
  },
  {
    "Name": "IMU - IMU Kolkata Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 314,
    "Category": "PWD"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in Port and Shipping Management",
    "Score": 357,
    "Category": "PWD"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 332,
    "Category": "EWS"
  },
  {
    "Name": "IMU - IMU Vizag Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 193,
    "Category": "EWS"
  },
  {
    "Name": "IMU - IMU Kolkata Indian Maritime University",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 290,
    "Category": "EWS"
  },
  {
    "Name": "IMU Kochi",
    "Course": "MBA in International Transportation and Logistics Management",
    "Score": 304,
    "Category": "EWS"
  },
  {
    "Name": "IMU Chennai Indian Maritime University (IMUC)",
    "Course": "MBA in Port and Shipping Management",
    "Score": 226,
    "Category": "EWS"
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  },
  {
    "Name": "",
    "Course": "",
    "Score": "",
    "Category": ""
  }
]