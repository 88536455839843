import React from "react";
import { useNavigate } from "react-router-dom";
import { neetuglogo, aiimslogo, neetpglogo, NEETMDSlogo, pucetlogo, UPPGlogo } from "../../images/index";

const exams = [
  { id: 1, name: "NEET UG", icon: neetuglogo },
  { id: 2, name: "AIIMS", icon: aiimslogo },
  { id: 3, name: "NEET PG", icon: neetpglogo },
  { id: 4, name: "NEET MDS", icon: NEETMDSlogo },
  { id: 5, name: "PU-CET (UG)", icon: pucetlogo },
  { id: 6, name: "UP PG", icon: UPPGlogo },
];

const MedicalPredictormobileview = () => {
  const navigate = useNavigate();

  const handleExamSelect = (exam) => {
    const examRoutes = {
      "NEET UG": "/NeetUGAllIndiaPredictor",
      "NEET PG": "/NeetPGPredictor",
      "UP PG": "/UPPgPredictor",
    };

    const route = examRoutes[exam.name];
    if (route) {
      navigate(route);
    } else {
      console.log("No valid route available for the selected exam");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        Medical College Predictor 
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Medical exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
        Select exam you have taken:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {exams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">{exam.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicalPredictormobileview;
