[
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Business Management",
    "Score": 95,
    "Category": "General"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Human Resource Management",
    "Score": 93,
    "Category": "General"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM Dual Country Programme",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Big Data Analytics",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 93.5,
    "Category": "General"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Banking, Insurance and Financial Services",
    "Score": 85,
    "Category": "General"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "General"
  },
  {
    "Name": "SOIL Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Balaji Institute of International Business, Sri Balaji University",
    "Course": "MBA in Marketing Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara Institute for Management Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "PGDM in International Business",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Rajagiri Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "PGDM in Insurance Business Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Human Resource Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Chandragupt Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "MATS Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "IMI Bhubaneswar - International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "SRM University, Delhi-NCR, Sonepat, Haryana (SRMUH)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 69,
    "Category": "General"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "PGDM in Retail Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "GIBS Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Jaipur - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Jindal Global Business School, O.P. Jindal Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Shiv Nadar University (SNU)",
    "Course": "Master of Business Administration (MBA) Global",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Birla Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Firebird Institute of Research in Management (FIRM)",
    "Course": "Global MBA",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "NTPC School of Business",
    "Course": "PGDM in Energy Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Globsyn Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Pune Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "School of Business, UPES",
    "Course": "MBA in Operations Management",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "SPSU - Sir Padampat Singhania University",
    "Course": "Master of Business Administration(MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "IMS Unison University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Jaipuria Indore - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "SRM School of Management, SRMIST - SRM Institute of Science and Technology, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Rajalakshmi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Apeejay Institute of Mass Communication Dwarka Delhi (AIMC)",
    "Course": "PGDM in Mass Communication",
    "Score": 50,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Marketing",
    "Score": 35,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Finance",
    "Score": 35,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Social Service",
    "Course": "Post Graduate Diploma in Rural Management",
    "Score": 25,
    "Category": "General"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Business Management",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Human Resource Management",
    "Score": 93,
    "Category": "OBC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM Dual Country Programme",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 93.5,
    "Category": "OBC"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Big Data Analytics",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "OBC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Balaji Institute of International Business, Sri Balaji University",
    "Course": "MBA in Marketing Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara Institute for Management Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Rajagiri Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Jaipur - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "IMI Bhubaneswar - International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "SRM University, Delhi-NCR, Sonepat, Haryana (SRMUH)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "Chandragupt Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "MATS Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "NTPC School of Business",
    "Course": "PGDM in Energy Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneur Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "SRM School of Management, SRMIST  Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Rajalakshmi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Firebird Institute of Research in Management (FIRM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurs",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Pune Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Apeejay Institute of Mass Communication Dwarka",
    "Course": "PGDM in Mass Communication",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "School of Business, UPES",
    "Course": "MBA in Operations Management",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "SPSU - Sir Padampat Singhania University",
    "Course": "Master of Business Administration(MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Business Management",
    "Score": 95,
    "Category": "SC"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Human Resource Management",
    "Score": 93,
    "Category": "SC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM Dual Country Programme",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84,
    "Category": "SC"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 93.5,
    "Category": "SC"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Big Data Analytics",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "SC"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Balaji Institute of International Business, Sri Balaji University",
    "Course": "MBA in Marketing Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara Institute for Management Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Rajagiri Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Jaipur - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IMI Bhubaneswar - International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "SRM University, Delhi-NCR, Sonepat, Haryana (SRMUH)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Chandragupt Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "MATS Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Jindal Global Business School, O.P. Jindal Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Shiv Nadar University (SNU)",
    "Course": "Master of Business Administration (MBA) Global",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Birla Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Globsyn Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "IMS Unison University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Indore - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "NTPC School of Business",
    "Course": "PGDM in Energy Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship Kochi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "SRM School of Management, SRMIST,Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Rajalakshmi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Firebird Institute of Research in Management (FIRM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Pune Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "Apeejay Institute of Mass Communication",
    "Course": "PGDM in Mass Communication",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "School of Business, UPES Dehradun",
    "Course": "MBA in Operations Management",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "SPSU - Sir Padampat Singhania University",
    "Course": "Master of Business Administration(MBA)",
    "Score": 50,
    "Category": "SC"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Business Management",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Human Resource Management",
    "Score": 93,
    "Category": "ST"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM Dual Country Programme",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84,
    "Category": "ST"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 93.5,
    "Category": "ST"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Big Data Analytics",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Balaji Institute of International Business, Sri Balaji University",
    "Course": "MBA in Marketing Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara Institute for Management Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Rajagiri Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Jaipur - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IMI Bhubaneswar - International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "SRM University, Delhi-NCR, Sonepat, Haryana (SRMUH)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Chandragupt Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "MATS Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Jindal Global Business School, O.P. Jindal Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Shiv Nadar University (SNU)",
    "Course": "Master of Business Administration (MBA) Global",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Birla Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Globsyn Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "IMS Unison University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Indore - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "NTPC School of Business",
    "Course": "PGDM in Energy Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "SRM School of Management, SRMIST Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Rajalakshmi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Firebird Institute of Research in Management (FIRM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Pune Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "School of Business, UPES Dehradun",
    "Course": "MBA in Operations Management",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "Apeejay Institute of Mass Communication Dwarka",
    "Course": "PGDM in Mass Communication",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "SPSU - Sir Padampat Singhania University",
    "Course": "Master of Business Administration(MBA)",
    "Score": 50,
    "Category": "ST"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Business Management",
    "Score": 95,
    "Category": "PWD"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Human Resource Management",
    "Score": 93,
    "Category": "PWD"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM Dual Country Programme",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 93.5,
    "Category": "PWD"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Big Data Analytics",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "PWD"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Balaji Institute of International Business, Sri Balaji University",
    "Course": "MBA in Marketing Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara Institute for Management Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Rajagiri Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Jaipur - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IMI Bhubaneswar - International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "SRM University, Delhi-NCR, Sonepat, Haryana (SRMUH)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Chandragupt Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "MATS Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Jindal Global Business School, O.P. Jindal Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Shiv Nadar University (SNU)",
    "Course": "Master of Business Administration (MBA) Global",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Birla Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Globsyn Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "IMS Unison University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Indore - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "NTPC School of Business",
    "Course": "PGDM in Energy Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "SRM School of Management, SRMIST Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Rajalakshmi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Firebird Institute of Research in Management (FIRM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Pune Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "School of Business, UPES Dehradun",
    "Course": "MBA in Operations Management",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "Apeejay Institute of Mass Communication Dwarka",
    "Course": "PGDM in Mass Communication",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "SPSU - Sir Padampat Singhania University",
    "Course": "Master of Business Administration(MBA)",
    "Score": 50,
    "Category": "PWD"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Business Management",
    "Score": 95,
    "Category": "EWS"
  },
  {
    "Name": "XLRI Xavier School of Management",
    "Course": "PGDM in Human Resource Management",
    "Score": 93,
    "Category": "EWS"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM Dual Country Programme",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Banking and Financial Services",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Human Resources Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Healthcare Management",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "K J Somaiya Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Rural Management, Anand",
    "Course": "Post Graduate Diploma in Management (Rural Management)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "S.P. Jain Institute of Management and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 93.5,
    "Category": "EWS"
  },
  {
    "Name": "International Management Institute, New Delhi",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Great Lakes Institute of Management, Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "IMT Ghaziabad - Institute of Management Technology",
    "Course": "PGDM in Banking and Financial Services",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "Goa Institute of Management",
    "Course": "PGDM in Big Data Analytics",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in Marketing",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "MBA in International Business",
    "Score": 85,
    "Category": "EWS"
  },
  {
    "Name": "T A Pai Management Institute, Manipal, MAHE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management, XIM University",
    "Course": "MBA in Business Management",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "MICA",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "Amrita School of Business, Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "FORE School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Balaji Institute of International Business, Sri Balaji University",
    "Course": "MBA in Marketing Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Post Graduate Diploma in Management",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara Institute for Management Development",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "KIIT School of Management, Kalinga Institute of Industrial Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Prin. L N Welingkar Institute of Management Development and Research",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "Birla Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "IMT Nagpur - Institute of Management Technology",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "LBSIM - Lal Bahadur Shastri Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "IFMR Graduate school of Business, KREA University (IFMR GSB), Sricity",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Lucknow - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Great Lakes Institute of Management, Gurgaon",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship, Bangalore",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Jagdish Sheth School of Management (JAGSoM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Loyola Institute of Business Administration",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "VIT Business School, Vellore Institute of Technology, Vellore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Rajagiri Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "BITS Pilani - Birla Institute of Technology and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "New Delhi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Jagan Institute of Management Studies, Rohini",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Entrepreneurship Development Institute of India",
    "Course": "PGDM in Entrepreneurship",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Jaipur - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Management Studies (IIMS Pune)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph's Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "IMI Bhubaneswar - International Management Institute",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "SRM University, Delhi-NCR, Sonepat, Haryana (SRMUH)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Indus Business Academy (IBA)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 70,
    "Category": "EWS"
  },
  {
    "Name": "Chandragupt Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "MATS Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Praxis Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Noida - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Usha and Lakshmi Mittal Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "IPE Hyderabad - Institute of Public Enterprise",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Vignana Jyothi Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Jindal Global Business School, O.P. Jindal Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Management Technology, Hyderabad",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Shiv Nadar University (SNU)",
    "Course": "Master of Business Administration (MBA) Global",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Birla Global University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Globsyn Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Fortune Institute of International Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "IES's Management College and Research Centre",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "IMS Unison University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Indore - Jaipuria Institute of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "AIMS Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Chetana's Institute of Management and Research",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Delhi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "DSI - Dayananda Sagar Institutions",
    "Course": "Post Graduate Diploma in Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "NTPC School of Business",
    "Course": "PGDM in Energy Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Jaypee Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "ASM - Apeejay School of Management",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "SCMS Cochin School of Business",
    "Course": "PGDM in General Management",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA+MIP (MasterTrail Certificate in Industry Practice)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Vivekanand Business School (VBS)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management and Entrepreneurship",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "SRM School of Management, SRMIST Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Jaipuria Institute of Management, Ghaziabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Rajalakshmi School of Business",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Firebird Institute of Research in Management (FIRM)",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "IMS Ghaziabad (University Courses Campus)",
    "Course": "Master of International Business (MIB)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Pune Business School",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Xavier Institute of Management Chennai",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "ITM Business School, Navi Mumbai",
    "Course": "MBA iConnect in Marketing",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "School of Business, UPES Dehradun",
    "Course": "MBA in Operations Management",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "Apeejay Institute of Mass Communication Dwarka",
    "Course": "PGDM in Mass Communication",
    "Score": 50,
    "Category": "EWS"
  },
  {
    "Name": "SPSU - Sir Padampat Singhania University",
    "Course": "Master of Business Administration(MBA)",
    "Score": 50,
    "Category": "EWS"
  }
]