// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCHkzLOQEFfNtq7CADDLiSOu-Gw8gCZ4VE",
  authDomain: "cadera-edu.firebaseapp.com",
  projectId: "cadera-edu",
  storageBucket: "cadera-edu.appspot.com",
  messagingSenderId: "66206291019",
  appId: "1:66206291019:web:e74d3dd7a03d3fbd8ba9ce",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and setup Google provider
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    console.log("Google sign-in success:", result.user);
  } catch (error) {
    console.error("Google sign-in error:", error);
  }
};
const signUserOut = async () => {
  try {
    await signOut(auth);
    console.log("User signed out successfully");
  } catch (error) {
    console.error("Sign-out error:", error.message);
  }
};

export const getCurrentUserUID = () => {
  const user = auth.currentUser;
  return user ? user.uid : null;
};

export const checkAuthStatus = () => {
  return new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      resolve(user);
    });
  });
};

export const loginRedirect = () => {
  window.location.href = "/login";
};

// Export auth, provider, and email-password functions

export {
  auth,
  provider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithGoogle,
  sendPasswordResetEmail,
  signUserOut
};

