[
  {
    "Name": "Bhai Gurdas Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14,
    "Category": "General"
  },
  {
    "Name": "Gian Jyoti Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "I.K Gujral Punjab Technical University (PTU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "General"
  },
  {
    "Name": "Chandigarh Business School of Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 148,
    "Category": "General"
  },
  {
    "Name": "St. Soldier Management and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 161,
    "Category": "General"
  },
  {
    "Name": "Rayat Bahra, Hoshiarpur Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 187,
    "Category": "General"
  },
  {
    "Name": "Bhai Gurdas Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14,
    "Category": "OBC"
  },
  {
    "Name": "Gian Jyoti Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "I.K Gujral Punjab Technical University (PTU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "OBC"
  },
  {
    "Name": "Chandigarh Business School of Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 148,
    "Category": "OBC"
  },
  {
    "Name": "St. Soldier Management and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 161,
    "Category": "OBC"
  },
  {
    "Name": "Rayat Bahra, Hoshiarpur Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 187,
    "Category": "OBC"
  },
  {
    "Name": "Bhai Gurdas Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14,
    "Category": "SC"
  },
  {
    "Name": "Gian Jyoti Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "SC"
  },
  {
    "Name": "I.K Gujral Punjab Technical University (PTU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "SC"
  },
  {
    "Name": "Chandigarh Business School of Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 148,
    "Category": "SC"
  },
  {
    "Name": "St. Soldier Management and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 161,
    "Category": "SC"
  },
  {
    "Name": "Rayat Bahra, Hoshiarpur Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 187,
    "Category": "SC"
  },
  {
    "Name": "Bhai Gurdas Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14,
    "Category": "PWD"
  },
  {
    "Name": "Gian Jyoti Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "I.K Gujral Punjab Technical University (PTU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "Chandigarh Business School of Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 148,
    "Category": "PWD"
  },
  {
    "Name": "St. Soldier Management and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 161,
    "Category": "PWD"
  },
  {
    "Name": "Rayat Bahra, Hoshiarpur Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 187,
    "Category": "PWD"
  },
  {
    "Name": "Bhai Gurdas Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14,
    "Category": "ST"
  },
  {
    "Name": "Gian Jyoti Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "ST"
  },
  {
    "Name": "I.K Gujral Punjab Technical University (PTU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "Chandigarh Business School of Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 148,
    "Category": "ST"
  },
  {
    "Name": "St. Soldier Management and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 161,
    "Category": "ST"
  },
  {
    "Name": "Rayat Bahra, Hoshiarpur Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 187,
    "Category": "ST"
  },
  {
    "Name": "Bhai Gurdas Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14,
    "Category": "EWS"
  },
  {
    "Name": "Gian Jyoti Institute of Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "EWS"
  },
  {
    "Name": "I.K Gujral Punjab Technical University (PTU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "EWS"
  },
  {
    "Name": "Chandigarh Business School of Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 148,
    "Category": "EWS"
  },
  {
    "Name": "St. Soldier Management and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 161,
    "Category": "EWS"
  },
  {
    "Name": "Rayat Bahra, Hoshiarpur Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 187,
    "Category": "EWS"
  }
]