[
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.5,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Energy and Environment",
    "Score": 93,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Agri Business Management",
    "Score": 93,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "Discontinued (Sep 2021)- Post Graduate Diploma in Operations Management",
    "Score": 87,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Digital and Telecom Management, Symbiosis International, Pune",
    "Course": "MBA in Digital and Telecom Management (Systems and Finance)",
    "Score": 83,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Management Studies, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Information Technology and Business Management",
    "Score": 76,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Media and Communication, Symbiosis International, Pune",
    "Course": "MBA in Communication Management",
    "Score": 73,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Health Sciences, Symbiosis International, Pune",
    "Course": "MBA in Hospital and Healthcare Management",
    "Score": 58,
    "Category": "General"
  },
  {
    "Name": "Symbiosis School of Sports Sciences, Symbiosis International, Pune",
    "Course": "MBA in Sports Management",
    "Score": 55,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Centre for Management & Human Resource Development, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in International Business",
    "Score": 93,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "MBA in Operations Management",
    "Score": 87,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Computer Studies and Research, Symbiosis International, Pune",
    "Course": "MBA in Information Technology",
    "Score": 82,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Data Sciences and Data Analytics",
    "Score": 76,
    "Category": "General"
  },
  {
    "Name": "Symbiosis School of Media & Communication, Symbiosis International, Bangalore",
    "Course": "Discontinued (Aug 2022) -MBA in Communication Management",
    "Score": 75,
    "Category": "General"
  },
  {
    "Name": "Symbiosis School of Banking and Finance, Symbiosis International, Pune",
    "Course": "MBA in Banking and Finance Management",
    "Score": 60,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58,
    "Category": "General"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.5,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in International Business",
    "Score": 93,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Agri Business Management",
    "Score": 93,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "Discontinued (Sep 2021)- Post Graduate Diploma in Operations Management",
    "Score": 87,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Digital and Telecom Management, Symbiosis International, Pune",
    "Course": "MBA in Digital and Telecom Management (Systems and Finance)",
    "Score": 83,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Management Studies, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Data Sciences and Data Analytics",
    "Score": 76,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Media and Communication, Symbiosis International, Pune",
    "Course": "MBA in Communication Management",
    "Score": 73,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Health Sciences, Symbiosis International, Pune",
    "Course": "MBA in Hospital and Healthcare Management",
    "Score": 58,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis School of Sports Sciences, Symbiosis International, Pune",
    "Course": "MBA in Sports Management",
    "Score": 55,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Centre for Management & Human Resource Development, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Energy and Environment",
    "Score": 93,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "MBA in Operations Management",
    "Score": 87,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Computer Studies and Research, Symbiosis International, Pune",
    "Course": "MBA in Information Technology",
    "Score": 82,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Information Technology and Business Management",
    "Score": 76,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis School of Media & Communication, Symbiosis International, Bangalore",
    "Course": "Discontinued (Aug 2022) -MBA in Communication Management",
    "Score": 75,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis School of Banking and Finance, Symbiosis International, Pune",
    "Course": "MBA in Banking and Finance Management",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58,
    "Category": "OBC"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.5,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Energy and Environment",
    "Score": 93,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Agri Business Management",
    "Score": 93,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "Discontinued (Sep 2021)- Post Graduate Diploma in Operations Management",
    "Score": 87,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Digital and Telecom Management, Symbiosis International, Pune",
    "Course": "MBA in Digital and Telecom Management (Systems and Finance)",
    "Score": 83,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Management Studies, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Information Technology and Business Management",
    "Score": 76,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Media and Communication, Symbiosis International, Pune",
    "Course": "MBA in Communication Management",
    "Score": 73,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Health Sciences, Symbiosis International, Pune",
    "Course": "MBA in Hospital and Healthcare Management",
    "Score": 58,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis School of Sports Sciences, Symbiosis International, Pune",
    "Course": "MBA in Sports Management",
    "Score": 55,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Centre for Management & Human Resource Development, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in International Business",
    "Score": 93,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "MBA in Operations Management",
    "Score": 87,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Computer Studies and Research, Symbiosis International, Pune",
    "Course": "MBA in Information Technology",
    "Score": 82,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Data Sciences and Data Analytics",
    "Score": 76,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis School of Media & Communication, Symbiosis International, Bangalore",
    "Course": "Discontinued (Aug 2022) -MBA in Communication Management",
    "Score": 75,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis School of Banking and Finance, Symbiosis International, Pune",
    "Course": "MBA in Banking and Finance Management",
    "Score": 60,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58,
    "Category": "SC"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.5,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in International Business",
    "Score": 93,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Agri Business Management",
    "Score": 93,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "MBA in Operations Management",
    "Score": 87,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Digital and Telecom Management, Symbiosis International, Pune",
    "Course": "MBA in Digital and Telecom Management (Systems and Finance)",
    "Score": 83,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Management Studies, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Data Sciences and Data Analytics",
    "Score": 76,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Media and Communication, Symbiosis International, Pune",
    "Course": "MBA in Communication Management",
    "Score": 73,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis School of Sports Sciences, Symbiosis International, Pune",
    "Course": "MBA in Sports Management",
    "Score": 55,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Centre for Management & Human Resource Development, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Energy and Environment",
    "Score": 93,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "Discontinued (Sep 2021)- Post Graduate Diploma in Operations Management",
    "Score": 87,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Computer Studies and Research, Symbiosis International, Pune",
    "Course": "MBA in Information Technology",
    "Score": 82,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Information Technology and Business Management",
    "Score": 76,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis School of Media & Communication, Symbiosis International, Bangalore",
    "Course": "Discontinued (Aug 2022) -MBA in Communication Management",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis School of Banking and Finance, Symbiosis International, Pune",
    "Course": "MBA in Banking and Finance Management",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Health Sciences, Symbiosis International, Pune",
    "Course": "MBA in Hospital and Healthcare Management",
    "Score": 58,
    "Category": "ST"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.5,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Energy and Environment",
    "Score": 93,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Agri Business Management",
    "Score": 93,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "Discontinued (Sep 2021)- Post Graduate Diploma in Operations Management",
    "Score": 87,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Digital and Telecom Management, Symbiosis International, Pune",
    "Course": "MBA in Digital and Telecom Management (Systems and Finance)",
    "Score": 83,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Management Studies, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Information Technology and Business Management",
    "Score": 76,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Media and Communication, Symbiosis International, Pune",
    "Course": "MBA in Communication Management",
    "Score": 73,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis School of Sports Sciences, Symbiosis International, Pune",
    "Course": "MBA in Sports Management",
    "Score": 55,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Centre for Management & Human Resource Development, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in International Business",
    "Score": 93,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "MBA in Operations Management",
    "Score": 87,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Computer Studies and Research, Symbiosis International, Pune",
    "Course": "MBA in Information Technology",
    "Score": 82,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Data Sciences and Data Analytics",
    "Score": 76,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis School of Media & Communication, Symbiosis International, Bangalore",
    "Course": "Discontinued (Aug 2022) -MBA in Communication Management",
    "Score": 75,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis School of Banking and Finance, Symbiosis International, Pune",
    "Course": "MBA in Banking and Finance Management",
    "Score": 60,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Health Sciences, Symbiosis International, Pune",
    "Course": "MBA in Hospital and Healthcare Management",
    "Score": 58,
    "Category": "PWD"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.5,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Energy and Environment",
    "Score": 93,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in International Business",
    "Score": 93,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "MBA in Operations Management",
    "Score": 87,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Digital and Telecom Management, Symbiosis International, Pune",
    "Course": "MBA in Digital and Telecom Management (Systems and Finance)",
    "Score": 83,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Management Studies, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Data Sciences and Data Analytics",
    "Score": 76,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Media and Communication, Symbiosis International, Pune",
    "Course": "MBA in Communication Management",
    "Score": 73,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Hyderabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis School of Sports Sciences, Symbiosis International, Pune",
    "Course": "MBA in Sports Management",
    "Score": 55,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Centre for Management & Human Resource Development, Symbiosis International, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of International Business, Symbiosis International, Pune",
    "Course": "MBA in Agri Business Management",
    "Score": 93,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Business Management, Symbiosis International, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Operations Management, Symbiosis International, Nashik",
    "Course": "Discontinued (Sep 2021)- Post Graduate Diploma in Operations Management",
    "Score": 87,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Computer Studies and Research, Symbiosis International, Pune",
    "Course": "MBA in Information Technology",
    "Score": 82,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Centre for Information Technology, Symbiosis International, Pune",
    "Course": "MBA in Information Technology and Business Management",
    "Score": 76,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis School of Media & Communication, Symbiosis International, Bangalore",
    "Course": "Discontinued (Aug 2022) -MBA in Communication Management",
    "Score": 75,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis School of Banking and Finance, Symbiosis International, Pune",
    "Course": "MBA in Banking and Finance Management",
    "Score": 60,
    "Category": "EWS"
  },
  {
    "Name": "Symbiosis Institute of Health Sciences, Symbiosis International, Pune",
    "Course": "MBA in Hospital and Healthcare Management",
    "Score": 58,
    "Category": "EWS"
  }
]