[
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "MBA in Travel and Tourism Management",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Pondicherry University",
    "Course": "M.B.A in Banking Technology",
    "Score": 84,
    "Category": "General"
  },
  {
    "Name": "Indian Culinary Institute, Noida",
    "Course": "MBA in Culinary Art",
    "Score": 73,
    "Category": "General"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued (Dec 2023) - Master of Business Administration (MBA Executive)",
    "Score": 70,
    "Category": "General"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Marketing Management",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Central University of Tamil Nadu",
    "Course": "MBA in Tourism & Hospitality Management",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 36,
    "Category": "General"
  },
  {
    "Name": "Central University of Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34,
    "Category": "General"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Human Resource Management",
    "Score": 34,
    "Category": "General"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 34,
    "Category": "General"
  },
  {
    "Name": "Hemvati Nandan Bahuguna Garhwal University [HNBGU]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 88,
    "Category": "General"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued(September,2022)Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "General"
  },
  {
    "Name": "Tripura University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "General"
  },
  {
    "Name": "Central University of Haryana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59,
    "Category": "General"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Rural Management",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Central University of Tamil Nadu",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "General"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35,
    "Category": "General"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA In Marketing Management",
    "Score": 34,
    "Category": "General"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Finance",
    "Score": 34,
    "Category": "General"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Defence Studies and Strategic Management",
    "Score": 34,
    "Category": "General"
  },
  {
    "Name": "Pondicherry University",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 33,
    "Category": "General"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.38,
    "Category": "General"
  },
  {
    "Name": "Khwaja Moinuddin Chishti Language University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11,
    "Category": "General"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 5,
    "Category": "General"
  },
  {
    "Name": "Pondicherry University - Karaikal Campus",
    "Course": "MBA in Insurance Management",
    "Score": 100,
    "Category": "OBC"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "OBC"
  },
  {
    "Name": "Hemvati Nandan Bahuguna Garhwal University [HNBGU]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 87,
    "Category": "OBC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 80,
    "Category": "OBC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued (Dec 2023) - Master of Business Administration (MBA Executive)",
    "Score": 70,
    "Category": "OBC"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "MBA in Travel and Tourism Management",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "Discontinued (July 24)- Executive MBA",
    "Score": 60,
    "Category": "OBC"
  },
  {
    "Name": "Central University of Haryana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48,
    "Category": "OBC"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Rural Management",
    "Score": 40,
    "Category": "OBC"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA In Marketing Management",
    "Score": 34,
    "Category": "OBC"
  },
  {
    "Name": "Mahatma Gandhi Central University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96,
    "Category": "OBC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "M.B.A in Banking Technology",
    "Score": 94,
    "Category": "OBC"
  },
  {
    "Name": "Indian Culinary Institute",
    "Course": "MBA in Culinary Art",
    "Score": 83,
    "Category": "OBC"
  },
  {
    "Name": "Sikkim University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "OBC"
  },
  {
    "Name": "Tripura University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Indian Culinary Institute, Noida",
    "Course": "MBA in Culinary Art",
    "Score": 65,
    "Category": "OBC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued(September,2022)Master of Business Administration (MBA)",
    "Score": 50,
    "Category": "OBC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 41,
    "Category": "OBC"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35,
    "Category": "OBC"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Human Resource Management",
    "Score": 34,
    "Category": "OBC"
  },
  {
    "Name": "Pondicherry University - Port Blair Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 96,
    "Category": "SC"
  },
  {
    "Name": "Central Tribal University of Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Indian Culinary Institute",
    "Course": "MBA in Culinary Art",
    "Score": 86,
    "Category": "SC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued (Dec 2023) - Master of Business Administration (MBA Executive)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "M.B.A in Banking Technology",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Indian Culinary Institute, Noida",
    "Course": "MBA in Culinary Art",
    "Score": 59,
    "Category": "SC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 52,
    "Category": "SC"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University - Port Blair Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 100,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 96,
    "Category": "SC"
  },
  {
    "Name": "Central Tribal University of Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Indian Culinary Institute",
    "Course": "MBA in Culinary Art",
    "Score": 86,
    "Category": "SC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued (Dec 2023) - Master of Business Administration (MBA Executive)",
    "Score": 70,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "M.B.A in Banking Technology",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Indian Culinary Institute, Noida",
    "Course": "MBA in Culinary Art",
    "Score": 59,
    "Category": "SC"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 52,
    "Category": "SC"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "SC"
  },
  {
    "Name": "Pondicherry University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99,
    "Category": "ST"
  },
  {
    "Name": "Central University of Haryana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Data Analytics",
    "Score": 91,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 89,
    "Category": "ST"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "MBA in Travel and Tourism Management",
    "Score": 86,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University - Karaikal Campus",
    "Course": "MBA in Insurance Management",
    "Score": 86,
    "Category": "ST"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Marketing Management",
    "Score": 83,
    "Category": "ST"
  },
  {
    "Name": "Hemvati Nandan Bahuguna Garhwal University [HNBGU]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72,
    "Category": "ST"
  },
  {
    "Name": "CUHP - Central University of Himachal Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69,
    "Category": "ST"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Rural Management",
    "Score": 56,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Financial Technology",
    "Score": 96,
    "Category": "ST"
  },
  {
    "Name": "Central University of Punjab",
    "Course": "MBA in Agribusiness",
    "Score": 93,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University - Karaikal Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "ST"
  },
  {
    "Name": "Sikkim University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in International Business",
    "Score": 86,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University - Port Blair Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85,
    "Category": "ST"
  },
  {
    "Name": "Central University of Tamil Nadu",
    "Course": "MBA in Tourism & Hospitality Management",
    "Score": 75,
    "Category": "ST"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued (Dec 2023) - Master of Business Administration (MBA Executive)",
    "Score": 70,
    "Category": "ST"
  },
  {
    "Name": "Central University of Rajasthan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60,
    "Category": "ST"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA In Marketing Management",
    "Score": 56,
    "Category": "ST"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Human Resource Management",
    "Score": 56,
    "Category": "ST"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 56,
    "Category": "ST"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 52,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 46,
    "Category": "ST"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued(September,2022)Master of Business Administration (MBA)",
    "Score": 38,
    "Category": "ST"
  },
  {
    "Name": "Central University of Andhra Pradesh",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27,
    "Category": "ST"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.13,
    "Category": "ST"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 5,
    "Category": "ST"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU)",
    "Course": "MBA in Finance",
    "Score": 56,
    "Category": "ST"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU)",
    "Course": "MBA in Defence Studies and Strategic Management",
    "Score": 56,
    "Category": "ST"
  },
  {
    "Name": "Pondicherry University",
    "Course": "M.B.A in Banking Technology",
    "Score": 46,
    "Category": "ST"
  },
  {
    "Name": "Mahatma Gandhi Central University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38,
    "Category": "ST"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35,
    "Category": "ST"
  },
  {
    "Name": "Central University of Tamil Nadu",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24,
    "Category": "ST"
  },
  {
    "Name": "Tripura University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10,
    "Category": "ST"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 99,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "Master of Business Administration (MBA) (Part Time)",
    "Score": 89,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 89,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University - Port Blair Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Human Resource Management",
    "Score": 79,
    "Category": "PWD"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 79,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Rajasthan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Financial Technology",
    "Score": 67,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University - Karaikal Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "PWD"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Rural Management",
    "Score": 89,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University - Karaikal Campus",
    "Course": "MBA in Insurance Management",
    "Score": 89,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Data Analytics",
    "Score": 89,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued(September,2022)Master of Business Administration (MBA)",
    "Score": 80,
    "Category": "PWD"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA In Marketing Management",
    "Score": 79,
    "Category": "PWD"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Finance",
    "Score": 79,
    "Category": "PWD"
  },
  {
    "Name": "Babasaheb Bhimrao Ambedkar University (BBAU), Lucknow",
    "Course": "MBA in Defence Studies and Strategic Management",
    "Score": 79,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "Discontinued (Dec 2023) - Master of Business Administration (MBA Executive)",
    "Score": 70,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Haryana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 61,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Jammu",
    "Course": "MBA in Marketing Management",
    "Score": 55,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 38,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Kerala",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Punjab",
    "Course": "MBA in Agribusiness",
    "Score": 43,
    "Category": "PWD"
  },
  {
    "Name": "Pondicherry University",
    "Course": "MBA in International Business",
    "Score": 38,
    "Category": "PWD"
  }
]