import React, { useState } from 'react';

const Landingpagepopup = ({ setIsOpen, setSubmissionStatus: setSubmissionStatusProp }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNo: '',
    exam: '',
    location: '',
    degree: '',
    collegeRegion: '',
  });

  const [submissionStatus, setLocalSubmissionStatus] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure 'exam' field is selected
    if (!formData.exam) {
      setLocalSubmissionStatus('exam_required');
      return;
    }

    try {
      const response = await fetch('https://caderaedu.com/api/advertisement-forms/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setLocalSubmissionStatus('submitted');
        setFormData({
          name: '',
          email: '',
          mobileNo: '',
          exam: '',
          location: '',
          degree: '',
          collegeRegion: '',
        });
      } else {
        setLocalSubmissionStatus('error');
      }
    } catch (error) {
      setLocalSubmissionStatus('error');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white rounded-lg p-6 shadow-lg w-96">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
          onClick={() => setIsOpen(false)}
        >
          ✕
        </button>
        {submissionStatus === 'submitted' && (
          <div className="mb-4 text-green-600 font-medium">Form submitted successfully!</div>
        )}
        {submissionStatus === 'error' && (
          <div className="mb-4 text-red-600 font-medium">Failed to submit the form. Please try again.</div>
        )}
        {submissionStatus === 'exam_required' && (
          <div className="mb-4 text-red-600 font-medium">Please select an exam before submitting.</div>
        )}
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter your name"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter your email"
            />
          </div>
          <div>
            <label htmlFor="mobileNo" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter your mobile number"
            />
          </div>
          <div>
            <label htmlFor="exam" className="block text-sm font-medium text-gray-700">
              Exam
            </label>
            <select
              id="exam"
              value={formData.exam}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Select Exam</option>
              <option value="cat">CAT</option>
              <option value="mat">MAT</option>
              <option value="snap">SNAP</option>
              <option value="xat">XAT</option>
              <option value="cmat">CMAT</option>
              <option value="ibsat">IBSAT</option>
              <option value="na">N/A</option>
            </select>
          </div>
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Location
            </label>
            <input
              type="text"
              id="location"
              value={formData.location}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter your location"
            />
          </div>
          <div>
            <label htmlFor="degree" className="block text-sm font-medium text-gray-700">
              Degree
            </label>
            <input
              type="text"
              id="degree"
              value={formData.degree}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter your degree"
            />
          </div>
          <div>
            <label htmlFor="collegeRegion" className="block text-sm font-medium text-gray-700">
              College Region
            </label>
            <input
              type="text"
              id="collegeRegion"
              value={formData.collegeRegion}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter your college region"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition-all"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Landingpagepopup;
