import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { gatelogo1, pgecetlogo, tancetlogo, bitshdlogo, upseelogo, vitmeelogo, nimcetlogo, jamlogo, mhtcetlogo } from "../../images/index";

const mtechExams = [
  { id: 1, name: "GATE", icon: gatelogo1 },
  { id: 2, name: "PGECET", icon: pgecetlogo },
  { id: 3, name: "TANCET", icon: tancetlogo },
  { id: 4, name: "BITS HD", icon: bitshdlogo },
  { id: 5, name: "UPSEE", icon: upseelogo },
  { id: 6, name: "VITMEE", icon: vitmeelogo },
  { id: 7, name: "NIMCET", icon: nimcetlogo },
  { id: 8, name: "JAM", icon: jamlogo },
  { id: 9, name: "MHT CET", icon: mhtcetlogo },
];

const MTechPredictorMobileView = () => {
  const [selectedExams, setSelectedExams] = useState([]);
  const navigate = useNavigate();

  const handleExamClick = (exam) => {
    setSelectedExams([exam.name]); // Update the selected exam

    // Navigate based on the selected exam
    switch (exam.name) {
      case "GATE":
        navigate("/gate-route"); // Replace with the actual route
        break;
      case "PGECET":
        navigate("/pgecet-route");
        break;
      case "TANCET":
        navigate("/tancet-route");
        break;
      case "BITS HD":
        navigate("/bitshd-route");
        break;
      case "UPSEE":
        navigate("/upsee-route");
        break;
      case "VITMEE":
        navigate("/vitmee-route");
        break;
      case "NIMCET":
        navigate("/nimcet-route");
        break;
      case "JAM":
        navigate("/jam-route");
        break;
      case "MHT CET":
        navigate("/mhtcet-route");
        break;
      default:
        console.log("No valid route defined for this exam");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        M.Tech Course Predictor 2024
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on M.Tech exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
        Select exam you have taken:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {mtechExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamClick(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-black">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">{exam.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MTechPredictorMobileView;
