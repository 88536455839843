import React from "react";
import Sidebar from "./sidebar";

const Department = (props) => {
  return (
    <div className="flex flex-col lg:flex-row p-4 space-y-8 lg:space-y-0 lg:space-x-4">
      {/* Department Content Section */}
      <div className="lg:w-2/3 space-y-8">
        {/* Department Section */}
        <div className="bg-white p-6 rounded-md shadow-lg">
          <h2 className="text-xl font-semibold mb-4">
            {props.CollegeName}, Departments
          </h2>
          <div className="flex flex-col lg:flex-row items-start space-y-4 lg:space-y-0 lg:space-x-4">
            <img
              src="https://th.bing.com/th/id/OIP.8BN2nqytLEh4-arek0TRDwHaEK?w=283&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
              alt="Department Image"
              className="w-full lg:w-32 h-24 object-cover rounded-md"
            />
            <div className="flex-1">
              <h3 className="text-lg font-bold">
                {props.DepartmentName1}, {props.CollegeName}
              </h3>
              <p>{props.DepartmentName1Location}</p>
              <p>{props.CoursesinDepartment1}</p>
              <p className="text-green-500 font-semibold">
                {props.DepartmentFees1}
              </p>
            </div>
          </div>
        </div>

        {/* Q&A Section */}
        <div className="bg-white p-6 rounded-md shadow-lg">
          {/* <h3 className="text-xl font-semibold mb-4">
            {props.CollegeName}: Answered Questions
          </h3> */}

          {/* Check if table1 data is available */}
          {props.table1Data ? (
            <div>
              {/* Table 1 - If data exists */}
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2">Question</th>
                    <th className="p-2">Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Render table rows based on the provided data */}
                  {props.table1Data.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">{item.question}</td>
                      <td className="p-2">{item.answer}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-600">
              {/* Currently, there is no FAQ available. */}
            </p>
          )}

          {/* Check if table2 data is available */}
          {props.table2Data && (
            <div>
              {/* Table 2 - Always shown if data exists */}
              <h4 className="text-lg font-semibold mb-2">
                Additional Information:
              </h4>
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2">Detail</th>
                    <th className="p-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {props.table2Data.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">{item.detail}</td>
                      <td className="p-2">{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Example for individual questions */}
          {[
            props.Question1,
            props.Question2,
            props.Question3,
            props.Question4,
          ].map(
            (question, index) =>
              question && (
                <div className="mb-4" key={index}>
                  <p className="font-semibold text-gray-800">{question}</p>
                  <p className="text-gray-600">{props[`Answer${index + 1}`]}</p>
                </div>
              )
          )}
        </div>
      </div>

      {/* Sidebar Section */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default Department;
