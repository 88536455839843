import React, { useState } from "react";
import collegeData from "../UPPGPredictor/UPPg.json"; // Importing data from JSON file
import CompulsoryTimesignup from "../CompulsoryTimesignup";

const FilterComponent = () => {
  const [selectedBranch, setSelectedBranch] = useState("");
  const [rankInput, setRankInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueColleges, setUniqueColleges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track form submission
  const [showSignupModal, setShowSignupModal] = useState(false); // State to control modal visibility
  const [isPredictionAttempted, setIsPredictionAttempted] = useState(false); // Track prediction attempt

  // Function to handle filtering and setting unique colleges
  const handlePredict = () => {
    if (!selectedBranch || !rankInput) {
      alert("Both fields are mandatory!");
      return;
    }

    const filtered = collegeData
      .filter((college) => {
        return (
          college.Branch_name === selectedBranch &&
          college.Rank >= parseInt(rankInput)
        );
      })
      .sort((a, b) => a.Rank - b.Rank); // Sorting by rank in ascending order

    // Extract unique college names
    const uniqueColleges = [
      ...new Set(filtered.map((college) => college.college_name)),
    ];

    setUniqueColleges(uniqueColleges);
    setFilteredData(filtered); // Optional: Set filtered data if needed
    setCurrentPage(1); // Reset to first page on filter change
    setIsPredictionAttempted(true); // Set prediction attempt to true
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Get current page data
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = uniqueColleges.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(uniqueColleges.length / rowsPerPage);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold text-center mb-6">
        {" "}
        UttarPradesh PG Predictor
      </h1>

      {/* <CompulsoryTimesignup
        onFormSubmit={() => {
          setIsFormSubmitted(true); // Set form submitted to true once form is completed
          setShowSignupModal(false); // Close modal after successful submission
        }}
        showSignupModal={showSignupModal} // Pass down the state for modal visibility
        onCloseModal={() => setShowSignupModal(false)} // Function to close the modal
      /> */}

      <div className="mb-4">
        {/* Branch_name Dropdown */}
        <label className="mr-2">Select Branch:</label>
        <select
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
          className="border-black border rounded p-2"
        >
          <option value="">Select Branch</option>
          {[...new Set(collegeData.map((college) => college.Branch_name))].map(
            (branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            )
          )}
        </select>
      </div>

      <div className="mb-4">
        {/* Rank Input */}
        <label className="mr-2">Enter Rank:</label>
        <input
          type="number"
          value={rankInput}
          onChange={(e) => setRankInput(e.target.value)}
          className="border-black border rounded p-2"
        />
      </div>

      <div>
        <button
          onClick={handlePredict}
        //   disabled={!isFormSubmitted} // Button only enabled after form submission
          className=
            "bg-teal-500 text-white px-4 py-2 rounded-lg shadow-md transition-all"
        >
          Predict Institute
        </button>
      </div>

      <div className="mt-4">
        {/* Display unique college names with pagination */}
        {currentRows.length > 0 ? (
          <div>
            <table className="table-auto border-collapse border border-black w-full">
              <thead>
                <tr>
                  <th className="border-black border px-2 py-2">S.No</th>
                  <th className="border-black border px-4 py-2">
                    College Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((collegeName, index) => (
                  <tr key={index}>
                    <td className="border-black border px-4 py-2">
                      {indexOfFirstRow + index + 1}
                    </td>
                    <td className="border-black border px-4 py-2">
                      {collegeName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
              <button
                className="bg-teal-500 text-black px-4 py-2 rounded disabled:opacity-50"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              <span>
                Page {currentPage} of {totalPages}
              </span>

              <button
                className="bg-teal-500 text-black px-4 py-2 rounded disabled:opacity-50"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          isPredictionAttempted && (
            <p> Oops! You are not able to get any college with this rank.</p>
          )
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
