import React from "react";
import Sidebar from "./Sidebar";
import Tabs from "./Tabs";
import ImageSection from '../StudyAbroadLandingPage/ImageSection';
const Studyabroad = () => {
  return (
    <div className="flex flex-col lg:flex-row bg-gray-100 min-h-screen">
      {/* Left Content */}
     
      <div className="w-full ">
        {/* Persistent Section */}
        <div className="bg-white shadow-md  rounded-md mb-4">
        <ImageSection/>
          {/* <h1 className="text-2xl font-bold mb-2">MBBS in Ukraine</h1>
          <p className="text-gray-600">
            MBBS in Ukraine is the foremost option for Indian students to pursue low-cost medical education...
          </p> */}
        </div>

        {/* Tabs Section */}
        <Tabs />
      </div>

      {/* Sidebar */}
      {/* <div className="w-full lg:w-1/4 p-4"> */}
        {/* <Sidebar /> */}
      {/* </div> */}
    </div>
  );
};

export default Studyabroad;