import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './PricingPlans.css';

const PricingTable = () => {
  return (
    <div className="pricing-table">
      <p style={{ fontSize: '28px' }}>Plans & Pricing</p>
      <div className="toggle-switch">
        <input type="checkbox" id="toggleSwitch" />
        <label htmlFor="toggleSwitch">
          Pay Now <span>Limited Time Offer!!!!</span>
        </label>
      </div>
      <div className="plans">
        <div className="plan">
          <h2>Basic</h2>
          <div className="price">
            <span>₹19</span>.00<br/>
          </div>
          <button>Subscribe</button>
          <ul>
            <li><FontAwesomeIcon icon={faCheck} />AI Chatbot (Limited Access)</li>
            <li><FontAwesomeIcon icon={faCheck} />Free Predictors</li>
            <li><FontAwesomeIcon icon={faCheck} />1 on 1 Counselling</li>
          </ul>
          <p>
            <strong>SEO, social, and PPC tools:</strong> Competitor analysis, keyword research, website audit, advertising and social media tools, and more
          </p>
        </div>
        <div className="plan">
          <h2>Standard</h2>
          <div className="price">
            <span>₹49</span>.00<br/>
          </div>
          <button>Subscribe</button>
          <ul>
            <li><FontAwesomeIcon icon={faCheck} />15 projects</li>
            <li><FontAwesomeIcon icon={faCheck} />1,500 keywords to track</li>
            <li><FontAwesomeIcon icon={faCheck} />30,000 results per report</li>
          </ul>
          <p>
            <strong>All Pro features plus:</strong> Content Marketing Toolkit, historical data, multi-location and device tracking, Looker Studio integration, and more
          </p>
        </div>
        <div className="plan">
          <h2>Premium</h2>
          <div className="price">
            <span>₹999</span>.00<br/>
          </div>
          <button>Subscribe</button>
          <ul>
            <li><FontAwesomeIcon icon={faCheck} />40 projects</li>
            <li><FontAwesomeIcon icon={faCheck} />5,000 keywords to track</li>
            <li><FontAwesomeIcon icon={faCheck} />50,000 results per report</li>
          </ul>
          <p>
            <strong>All Guru features plus:</strong> Share of Voice, extended limits, API access, PLA analytics, free migration from third-party tools, and more
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;