// import React, { useEffect, useState } from "react";
// import { checkAuthStatus, getCurrentUserUID } from "../firebase";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import GeminiComponent from "./GeminiComponent";
// import Predictor from "./Predictor";
// const Dashboard = () => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [activeTab, setActiveTab] = useState("dashboard");
//   const navigate = useNavigate();
//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const user = await checkAuthStatus();
//         if (!user) {
//           navigate("/login");
//           return;
//         }
//         const uid = getCurrentUserUID();
//         const response = await axios.get(
//           `https://caderaedu.com/api/user/get/${uid}`
//         );
//         if (response.data && response.data.user) {
//           setUser(response.data.user);
//         } else {
//           console.error("User data not found in response");
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchUserData();
//   }, [navigate]);
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };
//   if (loading) {
//     return <p>Loading...</p>;
//   }
//   if (!user) {
//     return null;
//   }
//   if (user.planStatus === "free") {
//     navigate("/pricingplans");
//     return null;
//   }
//   return (
//     <div className="flex flex-col sm:flex-row min-h-screen relative">
//       {isSidebarOpen && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-50 z-10"
//           onClick={() => setIsSidebarOpen(false)}
//         ></div>
//       )}
//       <div
//         className={`bg-[#68a4a5] text-white w-64 sm:w-[23.05%] h-[130vh] p-5 fixed top-0 z-20 transform ${
//           isSidebarOpen ? "translate-x-0" : "-translate-x-full"
//         } transition-transform duration-300 ease-in-out sm:relative sm:translate-x-0`}
//       >
//         <button
//           onClick={() => setIsSidebarOpen(false)}
//           className="text-white sm:hidden mb-5 text-2xl absolute top-0.5 right-0.5"
//         >
//           &times;
//         </button>
//         <ul className="space-y-4">
//           <li
//             className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] ${
//               activeTab === "dashboard" ? "bg-[#4e8b8b]" : ""
//             }`}
//             onClick={() => handleTabClick("dashboard")}
//           >
//             Dashboard
//           </li>
//           <li
//             className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] ${
//               activeTab === "ai-chat" ? "bg-[#4e8b8b]" : ""
//             }`}
//             onClick={() => handleTabClick("ai-chat")}
//           >
//             AI Chat
//           </li>
//           <li
//             className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] ${
//               activeTab === "Predictor" ? "bg-[#4e8b8b]" : ""
//             }`}
//             onClick={() => handleTabClick("Predictor")}
//           >
//             College Predictor
//           </li>
//         </ul>
//       </div>
//       <div className="flex-1 bg-gray-100 p-8 ml-0 overflow-auto">
//         {!isSidebarOpen && (
//           <button
//             onClick={() => setIsSidebarOpen(true)}
//             className=" text-black py-2 rounded sm:hidden mb-4 flex items-center text-lg"
//           >
//             <span className="mr-2 text-2xl">&#9776;</span>
//           </button>
//         )}
//         {activeTab === "dashboard" && (
//           <>
//             <h2 className="text-3xl font-bold mb-4">Dashboard Overview</h2>
//             <p className="text-gray-600 mb-8">
//               Welcome to your education management dashboard
//             </p>
//             <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
//               <div className="bg-white p-6 rounded shadow">
//                 <h3 className="text-lg font-bold">Name</h3>
//                 <p className="text-2xl truncate">{user.username || "User"}</p>
//               </div>
//               <div className="bg-white p-6 rounded shadow">
//                 <h3 className="text-lg font-bold">Email</h3>
//                 <p className="text-2xl truncate">{user.email || "Email"}</p>
//               </div>
//               <div className="bg-white p-6 rounded shadow">
//                 <h3 className="text-lg font-bold">Status</h3>
//                 <p className="text-2xl">{user.planStatus || "Loading..."}</p>
//               </div>
//             </div>
//           </>
//         )}
//         {activeTab === "ai-chat" && <GeminiComponent />}
//         {activeTab === "Predictor" && <Predictor />}
//       </div>
//     </div>
//   );
// };
// export default Dashboard;
import React, { useState, useEffect } from "react";
import GeminiComponent from "./GeminiComponent";
import Predictor from "./Predictor";
//import LatestNotification from "../components/LatestNotifications";
import { Link } from "react-router-dom"; 
const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [selectedCategory, setSelectedCategory] = useState(""); // Currently selected category
  const [previousCategory, setPreviousCategory] = useState(""); // Last valid category
  const [colleges, setColleges] = useState([]); // State to store college names
  const [loading, setLoading] = useState(false); // State to track loading status

  const mockUser = {
    username: "John Doe",
    email: "johndoe@example.com",
    planStatus: "premium",
  };

  const categories = ["Management", "Engineering", "Law", "Medical"];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCategoryChange = async (event) => {
    const category = event.target.value;
    setSelectedCategory(category);

    if (category !== "SelectCategory") {
      setPreviousCategory(category); // Update only for valid categories
      setActiveTab("LatestNotification");

      // Fetch colleges from the API
      setLoading(true); // Start loading
      try {
        const response = await fetch(`https://caderaedu.com/api/college/filterByType?type=${category}`);
        const data = await response.json();
        setColleges(data); // Set the fetched colleges to state
      } catch (error) {
        console.error("Error fetching colleges:", error);
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  return (
    <div className="flex flex-col sm:flex-row min-h-screen relative">
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
      <div
        className={`bg-[#68a4a5] text-white w-64 sm:w-[23.05%] h-[130vh] p-5 fixed top-0 z-20 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out sm:relative sm:translate-x-0`}
      >
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="text-white sm:hidden mb-5 text-2xl absolute top-0.5 right-0.5"
        >
          &times;
        </button>
        <div className="space-y-6">
          {/* Sidebar Navigation */}
          <ul className="space-y-4">
            <li
              className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] ${
                activeTab === "dashboard" ? "bg-[#4e8b8b]" : ""
              }`}
              onClick={() => handleTabClick("dashboard")}
            >
              Dashboard
            </li>
            <li
              className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] ${
                activeTab === "ai-chat" ? "bg-[#4e8b8b]" : ""
              }`}
              onClick={() => handleTabClick("ai-chat")}
            >
              AI Chat
            </li>
            <li
              className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] ${
                activeTab === "Predictor" ? "bg-[#4e8b8b]" : ""
              }`}
              onClick={() => handleTabClick("Predictor")}
            >
              College Predictor
            </li>
          </ul>

          {/* Category Dropdown */}
          <div className="space-y-4">
            <select
              id="category"
              value={selectedCategory}
              onChange={handleCategoryChange}
              className={`p-3 rounded cursor-pointer hover:bg-[#4e8b8b] bg-[#68a4a5] text-white w-full`}
            >
              <option value={"SelectCategory"} className="bg-white text-black">
                Select College Type
              </option>
              {categories.map((category) => (
                <option
                  key={category}
                  value={category}
                  className="bg-white text-black"
                >
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex-1 bg-gray-100 p-8 ml-0 overflow-auto">
        {!isSidebarOpen && (
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="text-black py-2 rounded sm:hidden mb-4 flex items-center text-lg"
          >
            <span className="mr-2 text-2xl">&#9776;</span>
          </button>
        )}
        {activeTab === "dashboard" && (
          <>
            <h2 className="text-3xl font-bold mb-4">Dashboard Overview</h2>
            <p className="text-gray-600 mb-8">
              Welcome to your education management dashboard
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
              <div className="bg-white p-6 rounded shadow">
                <h3 className="text-lg font-bold">Name</h3>
                <p className="text-2xl truncate">{mockUser.username}</p>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <h3 className="text-lg font-bold">Email</h3>
                <p className="text-2xl truncate">{mockUser.email}</p>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <h3 className="text-lg font-bold">Status</h3>
                <p className="text-2xl">{mockUser.planStatus}</p>
              </div>
            </div>
          </>
        )}
        {activeTab === "ai-chat" && <GeminiComponent />}
        {activeTab === "Predictor" && <Predictor />}
        {activeTab === "LatestNotification" && (
  <>
    {loading ? (
      <h1>Loading colleges...</h1>
    ) : selectedCategory === "" ? (
      <h1>Please select a category</h1>
    ) : colleges.length > 0 ? (
      <>
        <h1>Colleges- {previousCategory}</h1>
        <table className="table-auto mt-4 border-collapse border border-gray-300 w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">#</th>
              <th className="border border-gray-300 px-4 py-2">College Name</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {colleges.map((college, index) => (
              <tr key={college._id} className="hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2 text-center">{index + 1}</td>
                <td className="border border-gray-300 px-4 py-2">{college.name}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  <Link
                    to={`/college/${college.slug}`}
                    className="text-blue-500 hover:underline"
                  >
                    View Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    ) : (
      <h1>No colleges found for this category</h1>
    )}
  </>
)}

       
      </div>
    </div>
  );
};

export default Dashboard;
