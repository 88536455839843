[
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 163,
    "Category": "General"
  },
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (Part-Time) (Non - Experienced)",
    "Score": 169,
    "Category": "General"
  },
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 135,
    "Category": "OBC"
  },
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 135,
    "Category": "SC"
  },
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 135,
    "Category": "ST"
  },
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 135,
    "Category": "PWD"
  },
  {
    "Name": "Cochin University of Science and Technology, Kochi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 135,
    "Category": "EWS"
  }
]