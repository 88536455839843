import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  List,
  Typography,
  Card,
  Modal,
  Form,
  message,
} from "antd";
import axios from "axios"; // Import axios for API request
import iiftData from "./NLUDATA.json"; // Import the JSON file (update path as per location)

const { Title } = Typography;
const { Option } = Select;

const categoryFullForms = {
  General: "General",
  SC: "Scheduled Caste",
  ST: "Scheduled Tribe",
  OBC: "Other Backward Class",
  EWS: "Economically Weaker Section",
  GC_TL: "General Category of Telangana",
  SC_TL: "Scheduled Caste of Telangana",
  ST_TL: "Scheduled Tribe of Telangana",
  OBC_A_TL: "OBC(A) of Telangana",
  OBC_B_TL: "OBC(B) of Telangana",
  OBC_C_TL: "OBC(C) of Telangana",
  OBC_D_TL: "OBC(D) of Telangana",
  OBC_E_TL: "OBC(E) of Telangana",
  EWS_TL: "EWS of Telangana",
  W: "Women",
  PWD: "Person With Disability",
  GC_KA: "General Category of Karnataka",
  SC_KA: "Scheduled Caste of Karnataka",
  ST_KA: "Scheduled Tribe of Karnataka",
  OBC_KA: "OBC of Karnataka",
  EWS_KA: "EWS of Karnataka",
  GC_WB: "General Category of West Bengal Domicile",
  SC_WB: "Scheduled Caste of West Bengal",
  ST_WB: "Scheduled Tribe of West Bengal",
  OBC_A_WB: "Other Backward Class - A of West Bengal",
  OBC_B_WB: "Other Backward Class - B of West Bengal",
  PWD_WB: "Person With Disability of West Bengal",
  GC_RJ: "General Category of Rajasthan",
  OBC_RJ: "Other Backward Caste of Rajasthan",
  SC_RJ: "Scheduled Caste of Rajasthan",
  ST_RJ: "Scheduled Tribe of Rajasthan",
  MBC_RJ: "Most Backward Class of Rajasthan",
  PWD_RJ: "Person With Disability of Rajasthan",
  PWD_SC_RJ: "PWD Scheduled Caste of Rajasthan",
  PWD_ST_RJ: "PWD Scheduled Tribe of Rajasthan",
  PWD_OBC_RJ: "PWD Other Backward Caste of Rajasthan",
  GC_CHT: "General Category of Chhattisgarh Domicile",
  SC_CHT: "Scheduled Caste of Chhattisgarh",
  ST_CHT: "Scheduled Tribe of Chhattisgarh",
  OBC_CHT: "Other Backward Class of Chhattisgarh",
  PWD_CHT: "Person With Disability of Chhattisgarh",
  W_SC: "Women Scheduled Caste",
  W_ST: "Women Scheduled Tribe",
  W_OBC: "Women Other Backward Class",
  DFF_CHT: "Dependents of Freedom Fighters of Chhattisgarh",
  W_CHT: "Women of Chhattisgarh",
  W_SC_CHT: "Women of Chhattisgarh SC",
  W_ST_CHT: "Women of Chhattisgarh ST",
  W_OBC_CHT: "Women of Chhattisgarh OBC",
  GC_DDN: "General Category of Dadra and Nagar Haveli and Daman and Diu",
  OBC_DDN: "Other Backward Class of Dadra and Nagar Haveli and Daman and Diu",
  EWS_DDN: "EWS of Dadra and Nagar Haveli and Daman and Diu",
  SC_DDN: "Scheduled Caste of Dadra and Nagar Haveli and Daman and Diu",
  ST_DDN: "Scheduled Tribe of Dadra and Nagar Haveli and Daman and Diu",
  GC_UP: "General Category of Uttar Pradesh",
  EWS_UP: "EWS of Uttar Pradesh",
  SC_UP: "Scheduled Caste of Uttar Pradesh",
  ST_UP: "Scheduled Tribe of Uttar Pradesh",
  OBC_UP: "Other Backward Class of Uttar Pradesh",
  DFF_UP: "Dependents of Freedom Fighters of Uttar Pradesh",
  CDP_UP: "Sons/Daughters of Defence Personnel deployed in UP or Defence Personnel of UP either retired or killed / Disabled",
  GC_TR: "General Category of Tripura",
  SC_TR: "Scheduled Caste of Tripura",
  ST_TR: "Scheduled Tribe of Tripura",
  PWD_TR: "Person With Disability of Tripura",
  GC_TL: "General Category of Telangana",
  SC_TL: "Scheduled Caste of Telangana",
  ST_TL: "Scheduled Tribe of Telangana",
  OBC_A_TL: "OBC(A) of Telangana",
  OBC_B_TL: "OBC(B) of Telangana",
  OBC_C_TL: "OBC(C) of Telangana",
  OBC_D_TL: "OBC(D) of Telangana",
  OBC_E_TL: "OBC(E) of Telangana",
  EWS_TL: "EWS of Telangana",
  EWS_BH: "EWS of Bihar",
  SC_BR: "Scheduled Caste of Bihar",
  ST_BR: "Scheduled Tribe of Bihar",
  EBC_BR: "Extremely Backward Classes of Bihar",
  BC_BR: "Backward Classes of Bihar",
  W_BC_BR: "Women of Backward Classes of Bihar",
  General: "General",
  SC: "Scheduled Caste",
  ST: "Scheduled Tribe",
  OBC: "Other Backward Class",
  EWS: "Economically Weaker Section",
  GC_TL: "General Category of Telangana",
  SC_TL: "Scheduled Caste of Telangana",
  ST_TL: "Scheduled Tribe of Telangana",
  OBC_A_TL: "OBC(A) of Telangana",
  OBC_B_TL: "OBC(B) of Telangana",
  OBC_C_TL: "OBC(C) of Telangana",
  OBC_D_TL: "OBC(D) of Telangana",
  OBC_E_TL: "OBC(E) of Telangana",
  EWS_TL: "EWS of Telangana",
  W: "Women",
  PWD: "Person With Disability",
  EWS_BH: "EWS of Bihar",
  SC_BR: "Scheduled Caste of Bihar",
  ST_BR: "Scheduled Tribe of Bihar",
  EBC_BR: "Extremely Backward Classes of Bihar",
  BC_BR: "Backward Classes of Bihar",
  W_BC_BR: "Women of Backward Classes of Bihar",
  GC_KL: "General Category of Kerala",
  SC_KL: "Scheduled Caste of Kerala",
  ST_KL: "Scheduled Tribe of Kerala",
  EZ_KL: "SEBC - Ezhava, Thiyya, Billava",
  MU_KL: "SEBC - Muslims",
  OBH_KL: "SEBC - Other Backward Hindus",
  LA_KL: "SEBC - Latin Catholic and Anglo Indian",
  OBX_KL: "SEBC - Other Backward Christian",
  KU_KL: "SEBC - Kudumbi",
  KUS_KL: "SEBC - Kusavan and related communities",
  DV_KL: "SEBC - Dheevara and related communities",
  VK_KL: "SEBC - Viswakarma and related communities",
  EWS_KL: "EWS of Kerala",
  T_KL: "Transgenders (Kerala)",
  JKM: "J&K Migrants",
  GC_JD: "General Category of Jharkhand",
  SC_JD: "Scheduled Caste of Jharkhand",
  EWS_JD: "EWS of Jharkhand",
  ST_JD: "Scheduled Tribe of Jharkhand",
  EBC_JD: "Extremely Backward Classes of Jharkhand",
  BC_JD: "Backward Classes of Jharkhand",
  PWD: "Person With Disability",
  PWD_JD: "Person With Disability of Jharkhand",
  JKRES: "J & K Residents",
  SC_AS: "Scheduled Caste of Assam",
  STP_AS: "Scheduled Tribe (Plains) of Assam",
  STH_AS: "Scheduled Tribe (Hills) of Assam",
  OBC_AS: "OBC(NCL)/MOBC(NCL) of Assam",
  GC_AS: "Permanent Residents of Assam",
  SC_AP: "Scheduled Caste of Andhra Pradesh",
  ST_AP: "Scheduled Tribe of Andhra Pradesh",
  BC_A_AP: "BC (Group-A) of Andhra Pradesh",
  BC_B_AP: "BC (Group-B) of Andhra Pradesh",
  BC_C_AP: "BC (Group-C) of Andhra Pradesh",
  BC_D_AP: "BC (Group-D) of Andhra Pradesh",
  BC_E_AP: "BC (Group-E) of Andhra Pradesh",
  PWD: "Person With Disability",
  CAP_AP: "Children of Armed Personnel (CAP) of Andhra Pradesh",
  NCC_AP: "National Cadet Corps (NCC) of Andhra Pradesh",
  ESP_AP: "Eminent Sports Persons of Andhra Pradesh",
  W_AP: "Women of Andhra Pradesh",
  GC_AP: "General Category of Andhra Pradesh",
  EWS_AP: "Economically Weaker Section of Andhra Pradesh",
  GC_TN: "General Category of Tamil Nadu",
  SCA_TN: "Scheduled Caste (Arunthathiyars) of Tamil Nadu",
  BCM_TN: "Backward Class (Muslim) of Tamil Nadu",
  MBC_DNC_TN: "Most Backward Class and Denotified Communities of Tamil Nadu",
  SCO_TN: "Scheduled Caste (Others) of Tamil Nadu",
  BCO_TN: "Backward Class (other than Backward Classes Muslims) of Tamil Nadu",
  WKM_KP_KHF: "Wards of Kashmiri Migrants / Kashmiri Pandits / Kashmiri Hindu Families (Non-Migrants)",
  GSSQ_MBC_TN: "Tamil Nadu M.B.C/D.N.C Government School Student Quota",
  GSSQ_SCO_TN: "Tamil Nadu S.C(O) Government School Student Quota",
  PWD_TN: "Person With Disability of Tamil Nadu",
  ESP_TN: "Eminent Sports Persons of Tamil Nadu",
  XS_TN: "Sons/ Daughters of Ex-servicemen of Tamil Nadu",
  SCA_TN: "Scheduled Caste (Arunthathiyars) of Tamil Nadu",
BCM_TN: "Backward Class (Muslim) of Tamil Nadu",
MBC__DNC_TN: "Most Backward Class and Denotified Communities of Tamil Nadu",
SCO_TN: "Scheduled Caste (Others) of Tamil Nadu",
SC_TN: "Scheduled Caste of Tamil Nadu",
ST_TN: "Scheduled Tribe of Tamil Nadu",
BCO_TN: "Backward Class (other than Backward Classes Muslims) of Tamil Nadu",
WKM_KP_KHF: "Wards of Kashmiri Migrants / Kashmiri Pandits / Kashmiri Hindu Families (Non-Migrants)",
GSSQ_MBC_TN: "Tamil Nadu M.B.C/D.N.C Government School Student Quota",
GSSQ_SCO_TN: "Tamil Nadu S.C(O) Government School Student Quota",
PWD_TN: "Person With Disability of Tamil Nadu",
ESP_TN: "Eminent Sports Persons of Tamil Nadu",
XS_TN: "Sons/ Daughters of Ex-servicemen of Tamil Nadu",
GSSQ_BCM_TN: "Tamilnadu B.C(M) Government School Student Quota",
GSSQ_SCA_TN: "Tamilnadu S.C(A) Government School Student Quota",
SC_MH: "Scheduled Caste of Maharashtra",
ST_MH: "Scheduled Tribe of Maharashtra",
OBC_MH: "Other Backward Class of Maharashtra",
DTA_MH: "Denotified Tribes (A) of Maharashtra",
NTB_MH: "Nomadic Tribes (B) of Maharashtra",
NTC_MH: "Nomadic Tribes (C) of Maharashtra",
NTD_MH: "Nomadic Tribes (D) of Maharashtra",
SBC_MH: "Special Backward Class of Maharashtra",
XS_MH: "Wards of Ex Servicemen/ Defence Personnel",
EWS_MH:"EWS Category Maharashtra",
WLRGEC_HR: "Ward of Land owners of RGEC [WL]",
GC_HR: "General Category of Haryana",
EWS_HR: "EWS of Haryana",
SC_HR: "Scheduled Caste of Haryana",
DSC_HR: "Deprived Scheduled Castes of Haryana",
BC_A_HR: "BC-A of Haryana",
BC_B_HR: "BC-B of Haryana",
};

const subCategoryFullForms = {
  General: "General",
  SC: "Scheduled Caste",
  ST: "Scheduled Tribe",
  OBC: "Other Backward Class",
  EWS: "Economically Weaker Section",
  GC_TL: "General Category of Telangana",
  SC_TL: "Scheduled Caste of Telangana",
  ST_TL: "Scheduled Tribe of Telangana",
  OBC_A_TL: "OBC(A) of Telangana",
  OBC_B_TL: "OBC(B) of Telangana",
  OBC_C_TL: "OBC(C) of Telangana",
  OBC_D_TL: "OBC(D) of Telangana",
  OBC_E_TL: "OBC(E) of Telangana",
  EWS_TL: "EWS of Telangana",
  W: "Women",
  PWD: "Person With Disability",
  GC_KA: "General Category of Karnataka",
  SC_KA: "Scheduled Caste of Karnataka",
  ST_KA: "Scheduled Tribe of Karnataka",
  OBC_KA: "OBC of Karnataka",
  EWS_KA: "EWS of Karnataka",
  GC_WB: "General Category of West Bengal Domicile",
  SC_WB: "Scheduled Caste of West Bengal",
  ST_WB: "Scheduled Tribe of West Bengal",
  OBC_A_WB: "Other Backward Class - A of West Bengal",
  OBC_B_WB: "Other Backward Class - B of West Bengal",
  PWD_WB: "Person With Disability of West Bengal",
  GC_RJ: "General Category of Rajasthan",
  OBC_RJ: "Other Backward Caste of Rajasthan",
  SC_RJ: "Scheduled Caste of Rajasthan",
  ST_RJ: "Scheduled Tribe of Rajasthan",
  MBC_RJ: "Most Backward Class of Rajasthan",
  PWD_RJ: "Person With Disability of Rajasthan",
  PWD_SC_RJ: "PWD Scheduled Caste of Rajasthan",
  PWD_ST_RJ: "PWD Scheduled Tribe of Rajasthan",
  PWD_OBC_RJ: "PWD Other Backward Caste of Rajasthan",
  GC_CHT: "General Category of Chhattisgarh Domicile",
  SC_CHT: "Scheduled Caste of Chhattisgarh",
  ST_CHT: "Scheduled Tribe of Chhattisgarh",
  OBC_CHT: "Other Backward Class of Chhattisgarh",
  PWD_CHT: "Person With Disability of Chhattisgarh",
  W_SC: "Women Scheduled Caste",
  W_ST: "Women Scheduled Tribe",
  W_OBC: "Women Other Backward Class",
  DFF_CHT: "Dependents of Freedom Fighters of Chhattisgarh",
  W_CHT: "Women of Chhattisgarh",
  W_SC_CHT: "Women of Chhattisgarh SC",
  W_ST_CHT: "Women of Chhattisgarh ST",
  W_OBC_CHT: "Women of Chhattisgarh OBC",
  GC_DDN: "General Category of Dadra and Nagar Haveli and Daman and Diu",
  OBC_DDN: "Other Backward Class of Dadra and Nagar Haveli and Daman and Diu",
  EWS_DDN: "EWS of Dadra and Nagar Haveli and Daman and Diu",
  SC_DDN: "Scheduled Caste of Dadra and Nagar Haveli and Daman and Diu",
  ST_DDN: "Scheduled Tribe of Dadra and Nagar Haveli and Daman and Diu",
  GC_UP: "General Category of Uttar Pradesh",
  EWS_UP: "EWS of Uttar Pradesh",
  SC_UP: "Scheduled Caste of Uttar Pradesh",
  ST_UP: "Scheduled Tribe of Uttar Pradesh",
  OBC_UP: "Other Backward Class of Uttar Pradesh",
  DFF_UP: "Dependents of Freedom Fighters of Uttar Pradesh",
  CDP_UP: "Sons/Daughters of Defence Personnel deployed in UP or Defence Personnel of UP either retired or killed / Disabled",
  GC_TR: "General Category of Tripura",
  SC_TR: "Scheduled Caste of Tripura",
  ST_TR: "Scheduled Tribe of Tripura",
  PWD_TR: "Person With Disability of Tripura",
  GC_TL: "General Category of Telangana",
  SC_TL: "Scheduled Caste of Telangana",
  ST_TL: "Scheduled Tribe of Telangana",
  OBC_A_TL: "OBC(A) of Telangana",
  OBC_B_TL: "OBC(B) of Telangana",
  OBC_C_TL: "OBC(C) of Telangana",
  OBC_D_TL: "OBC(D) of Telangana",
  OBC_E_TL: "OBC(E) of Telangana",
  EWS_TL: "EWS of Telangana",
  EWS_BH: "EWS of Bihar",
  SC_BR: "Scheduled Caste of Bihar",
  ST_BR: "Scheduled Tribe of Bihar",
  EBC_BR: "Extremely Backward Classes of Bihar",
  BC_BR: "Backward Classes of Bihar",
  W_BC_BR: "Women of Backward Classes of Bihar",
  General: "General",
  SC: "Scheduled Caste",
  ST: "Scheduled Tribe",
  OBC: "Other Backward Class",
  EWS: "Economically Weaker Section",
  GC_TL: "General Category of Telangana",
  SC_TL: "Scheduled Caste of Telangana",
  ST_TL: "Scheduled Tribe of Telangana",
  OBC_A_TL: "OBC(A) of Telangana",
  OBC_B_TL: "OBC(B) of Telangana",
  OBC_C_TL: "OBC(C) of Telangana",
  OBC_D_TL: "OBC(D) of Telangana",
  OBC_E_TL: "OBC(E) of Telangana",
  EWS_TL: "EWS of Telangana",
  W: "Women",
  PWD: "Person With Disability",
  EWS_BH: "EWS of Bihar",
  SC_BR: "Scheduled Caste of Bihar",
  ST_BR: "Scheduled Tribe of Bihar",
  EBC_BR: "Extremely Backward Classes of Bihar",
  BC_BR: "Backward Classes of Bihar",
  W_BC_BR: "Women of Backward Classes of Bihar",
  GC_KL: "General Category of Kerala",
  SC_KL: "Scheduled Caste of Kerala",
  ST_KL: "Scheduled Tribe of Kerala",
  EZ_KL: "SEBC - Ezhava, Thiyya, Billava",
  MU_KL: "SEBC - Muslims",
  OBH_KL: "SEBC - Other Backward Hindus",
  LA_KL: "SEBC - Latin Catholic and Anglo Indian",
  OBX_KL: "SEBC - Other Backward Christian",
  KU_KL: "SEBC - Kudumbi",
  KUS_KL: "SEBC - Kusavan and related communities",
  DV_KL: "SEBC - Dheevara and related communities",
  VK_KL: "SEBC - Viswakarma and related communities",
  EWS_KL: "EWS of Kerala",
  T_KL: "Transgenders (Kerala)",
  JKM: "J&K Migrants",
  GC_JD: "General Category of Jharkhand",
  SC_JD: "Scheduled Caste of Jharkhand",
  EWS_JD: "EWS of Jharkhand",
  ST_JD: "Scheduled Tribe of Jharkhand",
  EBC_JD: "Extremely Backward Classes of Jharkhand",
  BC_JD: "Backward Classes of Jharkhand",
  PWD: "Person With Disability",
  PWD_JD: "Person With Disability of Jharkhand",
  JKRES: "J & K Residents",
  SC_AS: "Scheduled Caste of Assam",
  STP_AS: "Scheduled Tribe (Plains) of Assam",
  STH_AS: "Scheduled Tribe (Hills) of Assam",
  OBC_AS: "OBC(NCL)/MOBC(NCL) of Assam",
  GC_AS: "Permanent Residents of Assam",
  SC_AP: "Scheduled Caste of Andhra Pradesh",
  ST_AP: "Scheduled Tribe of Andhra Pradesh",
  BC_A_AP: "BC (Group-A) of Andhra Pradesh",
  BC_B_AP: "BC (Group-B) of Andhra Pradesh",
  BC_C_AP: "BC (Group-C) of Andhra Pradesh",
  BC_D_AP: "BC (Group-D) of Andhra Pradesh",
  BC_E_AP: "BC (Group-E) of Andhra Pradesh",
  "PWD*": "Person With Disability above 80%",
  CAP_AP: "Children of Armed Personnel (CAP) of Andhra Pradesh",
  NCC_AP: "National Cadet Corps (NCC) of Andhra Pradesh",
  ESP_AP: "Eminent Sports Persons of Andhra Pradesh",
  W_AP: "Women of Andhra Pradesh",
  GC_AP: "General Category of Andhra Pradesh",
  EWS_AP: "Economically Weaker Section of Andhra Pradesh",
  GC_TN: "General Category of Tamil Nadu",
  SCA_TN: "Scheduled Caste (Arunthathiyars) of Tamil Nadu",
  BCM_TN: "Backward Class (Muslim) of Tamil Nadu",
  MBC_DNC_TN: "Most Backward Class and Denotified Communities of Tamil Nadu",
  SCO_TN: "Scheduled Caste (Others) of Tamil Nadu",
  BCO_TN: "Backward Class (other than Backward Classes Muslims) of Tamil Nadu",
  WKM_KP_KHF: "Wards of Kashmiri Migrants / Kashmiri Pandits / Kashmiri Hindu Families (Non-Migrants)",
  GSSQ_MBC_TN: "Tamil Nadu M.B.C/D.N.C Government School Student Quota",
  GSSQ_SCO_TN: "Tamil Nadu S.C(O) Government School Student Quota",
  PWD_TN: "Person With Disability of Tamil Nadu",
  ESP_TN: "Eminent Sports Persons of Tamil Nadu",
  XS_TN: "Sons/ Daughters of Ex-servicemen of Tamil Nadu",
  SCA_TN: "Scheduled Caste (Arunthathiyars) of Tamil Nadu",
BCM_TN: "Backward Class (Muslim) of Tamil Nadu",
MBC__DNC_TN: "Most Backward Class and Denotified Communities of Tamil Nadu",
SCO_TN: "Scheduled Caste (Others) of Tamil Nadu",
SC_TN: "Scheduled Caste of Tamil Nadu",
ST_TN: "Scheduled Tribe of Tamil Nadu",
BCO_TN: "Backward Class (other than Backward Classes Muslims) of Tamil Nadu",
WKM_KP_KHF: "Wards of Kashmiri Migrants / Kashmiri Pandits / Kashmiri Hindu Families (Non-Migrants)",
GSSQ_MBC_TN: "Tamil Nadu M.B.C/D.N.C Government School Student Quota",
GSSQ_SCO_TN: "Tamil Nadu S.C(O) Government School Student Quota",
PWD_TN: "Person With Disability of Tamil Nadu",
ESP_TN: "Eminent Sports Persons of Tamil Nadu",
XS_TN: "Sons/ Daughters of Ex-servicemen of Tamil Nadu",
GSSQ_BCM_TN: "Tamilnadu B.C(M) Government School Student Quota",
GSSQ_SCA_TN: "Tamilnadu S.C(A) Government School Student Quota",
SC_MH: "Scheduled Caste of Maharashtra",
ST_MH: "Scheduled Tribe of Maharashtra",
OBC_MH: "Other Backward Class of Maharashtra",
DTA_MH: "Denotified Tribes (A) of Maharashtra",
NTB_MH: "Nomadic Tribes (B) of Maharashtra",
NTC_MH: "Nomadic Tribes (C) of Maharashtra",
NTD_MH: "Nomadic Tribes (D) of Maharashtra",
SBC_MH: "Special Backward Class of Maharashtra",
XS_MH: "Wards of Ex Servicemen/ Defence Personnel",
EWS_MH:"EWS Category Maharashtra",
WLRGEC_HR: "Ward of Land owners of RGEC [WL]",
GC_HR: "General Category of Haryana",
EWS_HR: "EWS of Haryana",
SC_HR: "Scheduled Caste of Haryana",
DSC_HR: "Deprived Scheduled Castes of Haryana",
BC_A_HR: "BC-A of Haryana",
BC_B_HR: "BC-B of Haryana",
"W,GC_KA":"WOMEN,GENERAL OF KARNATAKA",
"W,GC_KA*":"WOMEN,GENERAL OF KARNATAKA",
"GC_KA":"General of karnataka",
"OBC_KA":"OBC of karnataka",
"W,EWS_KA":"Women,EWS category of karnataka",
"W,SC_KA":"Women,SC Category of karnataka",
"W,OBC_KA":"women,obc of karnataka",
"PWD,OBC_KA":"Person with disability,obc of karnataka",
"PWD,W,GC_KA":"Person with disability,women,general of karnataka",
"PWD,W":"Person with disability,women",
 "PWD,OBC":"Person with disability,OBC Category",
 "W,ST_KA":"Women, ST category of karnataka",
 "W,PWD":"Women,Person with disability",
 "GC_MP,W":"Women,General of Madhya Pradesh",
 WLRGEC_HR: "Ward of Land owners of RGEC [WL]",
 GC_HR: "General Category of Haryana",
 EWS_HR: "EWS of Haryana",
 SC_HR: "Scheduled Caste of Haryana",
 DSC_HR: "Deprived Scheduled Castes of Haryana",
 BC_A_HR: "BC-A of Haryana",
 BC_B_HR: "BC-B of Haryana",
 GC_MP: "General Category of Madhya Pradesh",
 SC_MP: "Scheduled Caste of Madhya Pradesh",
 "SC_MP,W": "Women ,Scheduled Caste of Madhya Pradesh",
 ST_MP: "Scheduled Tribe of Madhya Pradesh",
 OBC_MP: "Other Backward Class of Madhya Pradesh",
 "OBC_MP,W": "Other Backward Class of Madhya Pradesh,Women Category",
 "OBC_MP,PWD":"Other Backward Class of Madhya Pradesh,Person with disability",
 "ST_MP,W": "Scheduled Tribe of Madhya Pradesh,Women",
 "SC_MP,W,PWD":"Scheduled Caste of Madhya Pradesh,Women,Person with disability",
 GC_GJ : "Gujarat Domicile",
 "W,GC_GJ":"Women,Gujarat Domicile",
 GC_DDN: "General Category of Dadra and Nagar Haveli and Daman and Diu",
 "W,GC_DDN": "Women,General Category of Dadra and Nagar Haveli and Daman and Diu",
 OBC_DDN: "Other Backward Class of Dadra and Nagar Haveli and Daman and Diu",
 "W,OBC_DDN": "Women of Other Backward Class of Dadra and Nagar Haveli and Daman and Diu",
 EWS_DDN: "EWS of Dadra and Nagar Haveli and Daman and Diu",
 SC_DDN: "Scheduled Caste of Dadra and Nagar Haveli and Daman and Diu",
 "W,SC_DDN": "Women,Scheduled Caste of Dadra and Nagar Haveli and Daman and Diu",
 ST_DDN: "Scheduled Tribe of Dadra and Nagar Haveli and Daman and Diu",
 CDFP_MP : "Son/Grandson/Daughter/Grand daughter of freedom fighter Or Wards of war martyr of Defense",
 GC_CHT: "General Category of Chhattisgarh Domicile",
 SC_CHT: "Scheduled Caste of Chhattisgarh",
 ST_CHT: "Scheduled Tribe of Chhattisgarh",
 OBC_CHT: "Other Backward Class of Chhattisgarh",
 PWD_CHT: "Person With Disability of Chhattisgarh",
 W_CHT: "Women of Chhattisgarh",
 W_SC_CHT: "Women of Chhattisgarh SC",
 W_ST_CHT: "Women of Chhattisgarh ST",
 W_OBC_CHT: "Women of Chhattisgarh OBC",
 DFF_CHT: "Dependents of Freedom Fighters of Chhattisgarh",
 W_SC: "Women Scheduled Caste",
 W_ST: "Women Scheduled Tribe",
 W_OBC: "Women Other Backward Class",
 SC_UP: "Scheduled Caste of Uttar Pradesh",
ST_UP: "Scheduled Tribe of Uttar Pradesh",
OBC_UP: "Other Backward Class of Uttar Pradesh",
W_UP: "Women",
PWD_UP: "Person With Disability",
DFF_UP: "Dependents of Freedom Fighters of Uttar Pradesh",
CDP_UP: "Sons/Daughters of Defence Personnel deployed in UP or Defence Personne",
"PWD,CDP_UP":"PWD Category,Sons/Daughters of Defence Personnel deployed in UP or Defence Personnel",
"W,CDP_UP": "Women,Daughters of Defence Personnel deployed in UP or Defence Personnel",
RES_PB : "Resident of Punjab", 
BC_PB : "BC of Punjab",
"RES_PB,BC_PB":"Resident of Punjab,BC of Punjab",
"PWD,RES_PB":"PWD,Resident of Punjab",
DOM_OD: "General Category of Odisha",
SC_OD: "Scheduled Caste of Odisha",
ST_OD: "Scheduled Tribe of Odisha",
PWD_OD: "PWD of Odisha",
PWD_SC: "PWD Scheduled Caste",
PWD_ST: "PWD Scheduled Tribe",
SC_AS: "Scheduled Caste of Assam",
STP_AS: "Scheduled Tribe (Plains) of Assam",
STH_AS: "Scheduled Tribe (Hills) of Assam",
OBC_AS: "OBC(NCL)/MOBC(NCL) of Assam",
GC_AS: "Permanent Residents of Assam",
"GC_AS,PWD":"PWD,Resident of Assam",
WSRD_HP:"Wards of Serving/Retired/Defence Personnel",  
GC_HP:"Himachal Pradesh Domicile",
PWD_MH:"PWD,Maharasthra Region",
EWS_MP:"EWS,Madhya Pradesh Region",
"GC_MP,W,CDFP_\nMP":"General of MP,women,defence quota",
};


const PredictInstitute = () => {
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [rank, setRank] = useState("");
  const [results, setResults] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasClickedPredictnlu, sethasClickedPredictnlu] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state

  useEffect(() => {
    const hasClicked = localStorage.getItem("hasClickedPredictnlu");
    if (hasClicked === "true") {
      sethasClickedPredictnlu(true); // If user has clicked Predict before, don't show the modal again
    }
  }, []);

  const handleModalSubmit = async (values) => {
    setLoading(true); // Set loading to true when form is being submitted
    console.log("Form Values:", values); // Log the form values to check if they are correct

    try {
      // Send form data to the API
      const response = await axios.post(
        "https://caderaedu.com/api/form/submit",
        values
      );
      console.log("API Response:", response); // Log the response from the API

      if (response.status === 201) {
        message.success("Successfully submitted!");
        localStorage.setItem("hasClickedPredictnlu", "true"); // Set to true to avoid showing modal again
        sethasClickedPredictnlu(true); // Update the state to avoid future modal shows

        // Close the modal after ensuring the form submission is successful
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error during form submission:", error); // Log the error if the request fails
      message.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after submission completes (either success or error)
    }
  };

  const handlePredict = () => {
    if (!hasClickedPredictnlu) {
      setIsModalVisible(true); // Show modal if it's the first time clicking
    } else if (category && subCategory && rank) {
      // Ensure category, subCategory, and rank are provided
      const filtered = iiftData.filter(
        (institute) =>
          institute.Category.toLowerCase() === category.toLowerCase() &&
          institute.SubCategory.toLowerCase() === subCategory.toLowerCase() &&
          parseInt(rank) <= institute.Rank
      );

      // Use a Set to remove duplicates based on "College Name"
      const uniqueColleges = Array.from(
        new Map(
          filtered.map((item) => [item["College Name"], item])
        ).values()
      );

      setResults(uniqueColleges);
    } else {
      message.warning("Please select a category, subcategory, and enter a valid rank.");
    }
  };

  return (
    <>
      <Card
        style={{
          maxWidth: 600,
          margin: "50px auto",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          NLU LLB Predictor
        </Title>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Category:</label>
          <Select
            style={{ width: "100%", marginTop: 5 }}
            value={category}
            onChange={(value) => setCategory(value)}
            placeholder="Select Category"
          >
            {[...new Set(iiftData.map((institute) => institute.Category))].map(
              (categoryName, index) => (
                <Option key={index} value={categoryName}>
                  {categoryFullForms[categoryName] || categoryName}
                </Option>
              )
            )}
          </Select>
        </div>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>SubCategory:</label>
          <Select
            style={{ width: "100%", marginTop: 5 }}
            value={subCategory}
            onChange={(value) => setSubCategory(value)}
            placeholder="Select SubCategory"
          >
            {[...new Set(iiftData.map((institute) => institute.SubCategory))].map(
              (subCategoryName, index) => (
                <Option key={index} value={subCategoryName}>
                  {subCategoryFullForms[subCategoryName] || subCategoryName}
                </Option>
              )
            )}
          </Select>
        </div>

        <div style={{ marginBottom: 20 }}>
          <label style={{ fontWeight: 500 }}>Rank:</label>
          <Input
            type="number"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            placeholder="Enter your rank"
            style={{ marginTop: 5 }}
          />
        </div>

        <Button
          type="primary"
          block
          onClick={handlePredict}
          style={{ marginTop: 10, borderRadius: 5 }}
        >
          Predict
        </Button>

        {results.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            <Title level={4} style={{ textAlign: "center" }}>
              Matching Institutes:
            </Title>
            <List
              bordered
              dataSource={results}
              renderItem={(institute) => (
                <List.Item>
                  <strong>{institute["College Name"]}</strong> {institute.Category}, {institute.SubCategory}
                </List.Item>
              )}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : (
          <div style={{ marginTop: 30, textAlign: "center" }}>
            No matching institutes found.
          </div>
        )}
      </Card>

      <Modal
        title="Enter Details to use the predictor"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        closable={false}
      >
        <Form layout="vertical" onFinish={handleModalSubmit}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input type="email" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[{ required: true, message: "Please enter your mobile number" }]}
          >
            <Input type="tel" placeholder="Enter your mobile number" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="Enter your city" />
          </Form.Item>
          <Form.Item
            label="Course"
            name="courseInterestedIn"
            rules={[{ required: true, message: "Please enter your course" }]}
          >
            <Input placeholder="Enter your course" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading} // Disable button when loading
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PredictInstitute;
