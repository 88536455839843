import React from "react";

const services = [
  {
    title: "CAREER CONSULTANCY",
    description:
      "Get relevant career paths traced by experts to ascertain the best opportunities",
    icon: "📋", // Replace with an actual icon/image if available
  },
  {
    title: "PLACEMENT ASSISTANCE",
    description:
      "Make informed career choices with career counselling sessions from industry experts",
    icon: "📍", // Replace with an actual icon/image if available
  },
  {
    title: "NETWORKING",
    description:
      "Informative and career-building workshop sessions by industry experts via leading courses",
    icon: "🌐", // Replace with an actual icon/image if available
  },
];

const CareerSupport = () => {
  return (
    <div className="p-6 py-12 sm:p-12 bg-gray-200">
      <h2 className="text-3xl sm:text-5xl font-extrabold text-center text-gray-900 mb-8">
        Transform Your Career with Our Expert Support
      </h2>
      {/* Services Container */}
      <div className="flex flex-wrap justify-center gap-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white p-8 rounded-xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-lg w-[300px] sm:w-[350px] text-center"
          >
            <div className="text-5xl mb-6 text-indigo-600">{service.icon}</div>
            <h3 className="text-xl font-semibold text-gray-800 mb-4">{service.title}</h3>
            <p className="text-gray-700 leading-relaxed">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerSupport;