import React from 'react';
import Hero from './Hero'; // Import the Hero component
import Universities from './Universities'; // Import the Universities component
import WhyChooseUs from './WhyChooseUs'; // Import the WhyChooseUs component
import SuccessStories from './SuccessStory';

export default function StudyAbroadLandingPage() {
  return (
    <div>
      {/* Hero Section */}
      <Hero />

      {/* Universities Section */}
      <Universities />

      {/* Why Choose Us Section */}
      <WhyChooseUs />
      <SuccessStories/>
    </div>
  );
}
