import React, { useState } from "react";
import Info from './Info';
import TopColleges from './TopColleges';
import Syllabus from './Syllabus';
import CareerOptions from './CareerOptions';
import Faqs from './Faqs';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Info");

  const renderTabContent = () => {
    switch (activeTab) {
      case "Info":
        return <Info />;
      case "Top Colleges":
        return <TopColleges />;
      case "Syllabus":
        return <Syllabus />;
      case "Career Options":
        return <CareerOptions />;
      case "FAQ":
        return <Faqs />;
      default:
        return <Info />;
    }
  };

  return (
    <div className="bg-white shadow-md p-4 rounded-md">
      <div className="flex overflow-x-auto space-x-4 pb-4 border-b mb-4">
        {["Info", "Top Colleges", "Syllabus", "Career Options", "FAQ"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-6 py-3 text-sm font-medium rounded-full transition duration-300 ease-in-out transform ${
              activeTab === tab
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-500 hover:text-blue-500"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div>{renderTabContent()}</div>
    </div>
  );
};

export default Tabs;
