[
  {
    "Name": "Dr. Virendra Swarup Institute of Professional Studies (VSIPS)",
    "Course": "Integrated MBA",
    "Score": 10124,
    "Category": "General"
  },
  {
    "Name": "Greater Noida Institute of Technology",
    "Course": "Integrated BBA + MBA",
    "Score": 9852,
    "Category": "General"
  },
  {
    "Name": "Buddha Institute of Technology (BIT Gorakhpur)",
    "Course": "B.Voc. in Software Development",
    "Score": 2877,
    "Category": "General"
  },
  {
    "Name": "Global Institute of Technology, Noida",
    "Course": "Integrated BBA+ MBA",
    "Score": 2602,
    "Category": "General"
  },
  {
    "Name": "Raja Balwant Singh Management Technical Campus",
    "Course": "Integrated MBA",
    "Score": 3309,
    "Category": "General"
  },
  {
    "Name": "SSLD Varshney Engineering College",
    "Course": "B.Voc. in Software Development",
    "Score": 3941,
    "Category": "General"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "Integrated BBA + MBA",
    "Score": 3779,
    "Category": "General"
  },
  {
    "Name": "ABES Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 21503,
    "Category": "General"
  },
  {
    "Name": "Meerut Institute of Technology",
    "Course": "B.Voc in Software Development",
    "Score": 2903,
    "Category": "General"
  },
  {
    "Name": "Shri Siddhi Vinayak Group of Institutions",
    "Course": "Integrated BBA+ MBA",
    "Score": 3139,
    "Category": "General"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "B.Voc. in Software Development",
    "Score": 3587,
    "Category": "General"
  },
  {
    "Name": "Noida Institute of Engineering and Technology (NIET)",
    "Course": "Integrated BBA+MBA",
    "Score": 4949,
    "Category": "General"
  },
  {
    "Name": "Dr. Virendra Swarup Institute of Professional Studies (VSIPS)",
    "Course": "Integrated MBA",
    "Score": 10124,
    "Category": "OBC"
  },
  {
    "Name": "ABES Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2786,
    "Category": "OBC"
  },
  {
    "Name": "Meerut Institute of Technology",
    "Course": "B.Voc in Software Development",
    "Score": 2903,
    "Category": "OBC"
  },
  {
    "Name": "SSLD Varshney Engineering College",
    "Course": "B.Voc. in Software Development",
    "Score": 3941,
    "Category": "OBC"
  },
  {
    "Name": "Greater Noida Institute of Technology",
    "Course": "Integrated BBA + MBA",
    "Score": 9852,
    "Category": "OBC"
  },
  {
    "Name": "Global Institute of Technology, Noida",
    "Course": "Integrated BBA+ MBA",
    "Score": 2602,
    "Category": "OBC"
  },
  {
    "Name": "Buddha Institute of Technology (BIT Gorakhpur)",
    "Course": "B.Voc. in Software Development",
    "Score": 2877,
    "Category": "OBC"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "B.Voc. in Software Development",
    "Score": 3587,
    "Category": "OBC"
  },
  {
    "Name": "Noida Institute of Engineering and Technology (NIET)",
    "Course": "Integrated BBA+MBA",
    "Score": 6169,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Virendra Swarup Institute of Professional Studies (VSIPS)",
    "Course": "Integrated MBA",
    "Score": 10124,
    "Category": "SC"
  },
  {
    "Name": "ABES Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2786,
    "Category": "SC"
  },
  {
    "Name": "Meerut Institute of Technology",
    "Course": "B.Voc in Software Development",
    "Score": 2903,
    "Category": "SC"
  },
  {
    "Name": "SSLD Varshney Engineering College",
    "Course": "B.Voc. in Software Development",
    "Score": 3941,
    "Category": "SC"
  },
  {
    "Name": "Greater Noida Institute of Technology",
    "Course": "Integrated BBA + MBA",
    "Score": 9852,
    "Category": "SC"
  },
  {
    "Name": "Global Institute of Technology, Noida",
    "Course": "Integrated BBA+ MBA",
    "Score": 2602,
    "Category": "SC"
  },
  {
    "Name": "Buddha Institute of Technology (BIT Gorakhpur)",
    "Course": "B.Voc. in Software Development",
    "Score": 2877,
    "Category": "SC"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "B.Voc. in Software Development",
    "Score": 3587,
    "Category": "SC"
  },
  {
    "Name": "Noida Institute of Engineering and Technology (NIET)",
    "Course": "Integrated BBA+MBA",
    "Score": 6169,
    "Category": "SC"
  },
  {
    "Name": "Dr. Virendra Swarup Institute of Professional Studies (VSIPS)",
    "Course": "Integrated MBA",
    "Score": 690,
    "Category": "ST"
  },
  {
    "Name": "ABES Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2786,
    "Category": "ST"
  },
  {
    "Name": "Meerut Institute of Technology",
    "Course": "B.Voc in Software Development",
    "Score": 2903,
    "Category": "ST"
  },
  {
    "Name": "SSLD Varshney Engineering College",
    "Course": "B.Voc. in Software Development",
    "Score": 3941,
    "Category": "ST"
  },
  {
    "Name": "Greater Noida Institute of Technology",
    "Course": "Integrated BBA + MBA",
    "Score": 9852,
    "Category": "ST"
  },
  {
    "Name": "Global Institute of Technology, Noida",
    "Course": "Integrated BBA+ MBA",
    "Score": 2602,
    "Category": "ST"
  },
  {
    "Name": "Buddha Institute of Technology (BIT Gorakhpur)",
    "Course": "B.Voc. in Software Development",
    "Score": 2877,
    "Category": "ST"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "B.Voc. in Software Development",
    "Score": 3587,
    "Category": "ST"
  },
  { 
    "Name": "Noida Institute of Engineering and Technology (NIET)",
    "Course": "Integrated BBA+MBA",
    "Score": 6169,
    "Category": "ST"
  },
  {
    "Name": "Dr. Virendra Swarup Institute of Professional Studies (VSIPS)",
    "Course": "Integrated MBA",
    "Score": 690,
    "Category": "PWD"
  },
  {
    "Name": "ABES Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2786,
    "Category": "PWD"
  },
  {
    "Name": "Meerut Institute of Technology",
    "Course": "B.Voc in Software Development",
    "Score": 2903,
    "Category": "PWD"
  },
  {
    "Name": "SSLD Varshney Engineering College",
    "Course": "B.Voc. in Software Development",
    "Score": 3941,
    "Category": "PWD"
  },
  {
    "Name": "Greater Noida Institute of Technology",
    "Course": "Integrated BBA + MBA",
    "Score": 9852,
    "Category": "PWD"
  },
  {
    "Name": "Global Institute of Technology, Noida",
    "Course": "Integrated BBA+ MBA",
    "Score": 2602,
    "Category": "PWD"
  },
  {
    "Name": "Buddha Institute of Technology (BIT Gorakhpur)",
    "Course": "B.Voc. in Software Development",
    "Score": 2877,
    "Category": "PWD"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "B.Voc. in Software Development",
    "Score": 3587,
    "Category": "PWD"
  },
  {
    "Name": "Noida Institute of Engineering and Technology (NIET)",
    "Course": "Integrated BBA+MBA",
    "Score": 6169,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Virendra Swarup Institute of Professional Studies (VSIPS)",
    "Course": "Integrated MBA",
    "Score": 690,
    "Category": "EWS"
  },
  {
    "Name": "ABES Business School",
    "Course": "Master of Business Administration(MBA)",
    "Score": 2786,
    "Category": "EWS"
  },
  {
    "Name": "Meerut Institute of Technology",
    "Course": "B.Voc in Software Development",
    "Score": 2903,
    "Category": "EWS"
  },
  {
    "Name": "SSLD Varshney Engineering College",
    "Course": "B.Voc. in Software Development",
    "Score": 3941,
    "Category": "EWS"
  },
  {
    "Name": "Greater Noida Institute of Technology",
    "Course": "Integrated BBA + MBA",
    "Score": 9852,
    "Category": "EWS"
  },
  {
    "Name": "Global Institute of Technology, Noida",
    "Course": "Integrated BBA+ MBA",
    "Score": 2602,
    "Category": "EWS"
  },
  {
    "Name": "Buddha Institute of Technology (BIT Gorakhpur)",
    "Course": "B.Voc. in Software Development",
    "Score": 2877,
    "Category": "EWS"
  },
  {
    "Name": "SR Group of Institutions",
    "Course": "B.Voc. in Software Development",
    "Score": 3587,
    "Category": "EWS"
  },
  {
    "Name": "Noida Institute of Engineering and Technology (NIET)",
    "Course": "Integrated BBA+MBA",
    "Score": 6169,
    "Category": "EWS"
  }
]