import React, { useState } from "react";

const SeatIntakeTable = ({ courses, CollegeName }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedQuota, setSelectedQuota] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const itemsPerPage = 5;
  const allQuotas =
    courses?.flatMap((course) =>
      course.quotas.map((quota) => quota.quotaType)
    ) || [];
  const uniqueQuotas = Array.from(new Set(allQuotas)).sort();

  const allCourses = courses?.map((course) => course.name) || [];
  const uniqueCourses = Array.from(new Set(allCourses)).sort();

  const rows =
    courses?.flatMap((course) =>
      course.quotas.map((quota) => ({
        courseName: course.name,
        quotaType: quota.quotaType,
        seats: quota.seats,
      }))
    ) || [];
  const filteredRows = rows.filter((row) => {
    const isQuotaMatch = selectedQuota ? row.quotaType === selectedQuota : true;
    const isCourseMatch = selectedCourse
      ? row.courseName === selectedCourse
      : true;
    const isValidCourseName = row.courseName && row.courseName.trim() !== "";

    return isQuotaMatch && isCourseMatch && isValidCourseName;
  });

  const totalPages = Math.ceil(filteredRows.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentRows = filteredRows.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleQuotaChange = (event) => {
    setSelectedQuota(event.target.value);
    setCurrentPage(1);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-semibold mb-6">
        {CollegeName ? `${CollegeName} Seat Intake` : "Seat Intake"}
      </h2>

      {/* Quota Filter */}
      <div className="mb-6">
        <label className="block text-lg font-medium mb-2">
          Filter by Quota:
        </label>
        <select
          className="block w-full max-w-sm px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          value={selectedQuota}
          onChange={handleQuotaChange}
        >
          <option value="">All Quotas</option>
          {uniqueQuotas.map((quota, index) => (
            <option key={index} value={quota}>
              {quota}
            </option>
          ))}
        </select>
      </div>

      {/* Course Filter */}
      <div className="mb-6">
        <label className="block text-lg font-medium mb-2">
          Filter by Course:
        </label>
        <select
          className="block w-full max-w-sm px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          value={selectedCourse}
          onChange={handleCourseChange}
        >
          <option value="">All Courses</option>
          {uniqueCourses.map((course, index) => (
            <option key={index} value={course}>
              {course}
            </option>
          ))}
        </select>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse bg-white">
          <thead>
            <tr className="bg-blue-100">
              <th className="px-4 py-2 text-left border">Course Name</th>
              <th className="px-4 py-2 text-left border">Quota</th>
              <th className="px-4 py-2 text-left border">Seat Intake</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.length > 0 ? (
              currentRows.map((row, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 border">{row.courseName}</td>
                  <td className="px-4 py-2 border">{row.quotaType}</td>
                  <td className="px-4 py-2 border">{row.seats}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="px-4 py-2 text-center border">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {filteredRows.length > itemsPerPage && (
        <div className="flex justify-center items-center mt-4 space-x-4">
          {/* Previous Button */}
          <button
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? "bg-gray-300"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          {/* Current Page Display */}
          <span className="text-xs font-medium">
            {currentPage} of {totalPages}
          </span>
          {/* Next Button */}
          <button
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? "bg-gray-300"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default SeatIntakeTable;
