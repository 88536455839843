import React, { useState } from "react";
import Sidebar from "./sidebar";

const Cutoff = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedQuota, setSelectedQuota] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(""); // New state for selected course
  const itemsPerPage = 5;

  const isDataAvailable = props.courses;

  const allQuotas =
    props.courses?.flatMap((course) =>
      course.quotas.map((quota) => quota.quotaType)
    ) || [];
  const uniqueQuotas = Array.from(new Set(allQuotas)).sort();

  const allCourses = props.courses?.map((course) => course.name) || []; // List of all course names
  const uniqueCourses = Array.from(new Set(allCourses)).sort(); // Unique course names

  const rows =
    props.courses?.flatMap((course) =>
      course.quotas.map((quota) => ({
        courseName: course.name,
        quotaType: quota.quotaType,
        closingRank: quota.closingRank,
      }))
    ) || [];

  // Filter based on selected quota and course
  const filteredRows = rows.filter((row) => {
    const isQuotaMatch = selectedQuota ? row.quotaType === selectedQuota : true;
    const isCourseMatch = selectedCourse
      ? row.courseName === selectedCourse
      : true;

    // Ensure that rows with missing or empty course names are excluded
    const isValidCourseName = row.courseName && row.courseName.trim() !== "";

    return isQuotaMatch && isCourseMatch && isValidCourseName;
  });

  const totalPages = Math.ceil(filteredRows.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentRows = filteredRows.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleQuotaChange = (event) => {
    setSelectedQuota(event.target.value);
    setCurrentPage(1);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(1);
  };

  const managementTable = (
    <div className="overflow-x-auto">
      <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
        {props.ManagementCourseName}
      </h2>
      {isDataAvailable ? (
        <>
          {/* Quota Filter */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Filter by Quota:
            </label>
            <select
              className="block w-full max-w-sm px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
              value={selectedQuota}
              onChange={handleQuotaChange}
            >
              <option value="">All Quotas</option>
              {uniqueQuotas.map((quota, index) => (
                <option key={index} value={quota}>
                  {quota}
                </option>
              ))}
            </select>
          </div>

          {/* Course Dropdown */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Select Course:
            </label>
            <select
              className="block w-full max-w-sm px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              <option value="">All Courses</option>
              {uniqueCourses.map((course, index) => (
                <option key={index} value={course}>
                  {course}
                </option>
              ))}
            </select>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full table-auto bg-white shadow-lg rounded-lg mb-8">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-2 md:p-4 text-left border">Course Name</th>
                  <th className="p-2 md:p-4 text-left border">Quota</th>
                  <th className="p-2 md:p-4 text-left border">Cutoff</th>
                </tr>
              </thead>
              <tbody>
                {currentRows.length > 0 ? (
                  currentRows.map((row, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2 md:p-4 border">{row.courseName}</td>
                      <td className="p-2 md:p-4 border">{row.quotaType}</td>
                      <td className="p-2 md:p-4 border">{row.closingRank}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="p-2 md:p-4 text-center">
                      No cutoff data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Pagination Controls */}
          {filteredRows.length > itemsPerPage && (
            <div className="flex justify-center items-center mt-4 space-x-4">
              {/* Previous Button */}
              <button
                className={`px-4 py-2 rounded ${
                  currentPage === 1
                    ? "bg-gray-300"
                    : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              {/* Current Page Display */}
              <span className="text-xs font-medium">
                {currentPage} of {totalPages}
              </span>
              {/* Next Button */}
              <button
                className={`px-4 py-2 rounded ${
                  currentPage === totalPages
                    ? "bg-gray-300"
                    : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="bg-gray-100 p-4 rounded-lg mb-6 text-center">
          <p className="text-sm md:text-base text-gray-600">
            No cutoff data available.
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto p-6 flex flex-col lg:flex-row">
      <div className="lg:w-3/4 w-full lg:pr-6">
        {props.CutoffParagraph1 && (
          <div className="mb-6">
            <p className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800">
              {props.CutoffParagraph1}
            </p>
          </div>
        )}

        {/* Table of Contents */}
        {props.CutoffTopic && props.CutoffAboutInfo && (
          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <h2 className="text-xl md:text-2xl font-semibold mb-2">
              {props.CutoffTopic}
            </h2>
            <p className="text-sm md:text-base">{props.CutoffAboutInfo}</p>
          </div>
        )}
        {managementTable}
      </div>

      {/* Sidebar */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseVideosSction2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default Cutoff;
