import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NCHMCT, upcat, mhtcet, ipucet } from "../../images";

const exams = [
  {
    id: 1,
    name: "NCHMCT JEE",
    icon: NCHMCT,
  },
  {
    id: 2,
    name: "UPCAT",
    icon: upcat,
  },
  { id: 3, name: "MAH HM CET", icon: mhtcet },
  {
    id: 4,
    name: "IPUCET",
    icon: ipucet,
  },
];

const HospitalityPredictormobile = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = exams.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(exams.length / itemsPerPage);

  const handleExamSelect = (exam) => {
    if (exam.name === "NCHMCT JEE") {
      navigate("/nchmct"); // Replace with actual route for NCHMCT JEE
    } else if (exam.name === "UPCAT") {
      navigate("/upcat"); // Replace with actual route for UPCAT
    } else if (exam.name === "MAH HM CET") {
      navigate("/mahhmcet"); // Replace with actual route for MAH HM CET
    } else if (exam.name === "IPUCET") {
      navigate("/ipucet"); // Replace with actual route for IPUCET
    } else {
      console.log("No valid route for the selected exam");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4 text-center text-black">
        Hospitality College Predictor 2024
      </h1>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Hospitality exams you have taken.
      </p>

      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div
            key={exam.id}
            className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out"
            onClick={() => handleExamSelect(exam)}
          >
            <div className="w-16 h-16 mx-auto mb-2">
              <img
                src={exam.icon}
                alt={exam.name}
                className="w-full h-full object-contain"
              />
            </div>
            <p className="text-sm font-semibold text-gray-800 mt-1 text-center">
              {exam.name}
            </p>
          </div>
        ))}
      </div>

      <div className="flex mt-6 space-x-4">
        {/* <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button> */}
      </div>
    </div>
  );
};

export default HospitalityPredictormobile;
