import React, { useState, useEffect } from "react";
import { CollegeCard } from "./index";
import { collegeData } from "../data/collageData";

const CollageGrid = () => {
  const [collegesData, setCollegesData] = useState([]);
  const [displayCount, setDisplayCount] = useState(12); // Show 12 items initially
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the backend API
    const fetchColleges = async () => {
      try {
        const response = await fetch("https://caderaedu.com/api/college/all");
        const data = await response.json();
        setCollegesData(data.colleges);
        setLoading(false);
      } catch (err) {
        setError("Failed to load college data.");
        setLoading(false);
      }
    };

    fetchColleges();
  }, []);

  const loadMore = () => {
    setDisplayCount((prevCount) => prevCount + 10); // Increase by 10 each time
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="bg-gray-100 py-6 sm:py-5 md:py-10">
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 sm:mb-6 md:mb-8">
        Top Colleges
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 px-4 sm:px-6 md:px-10">
        {collegesData
          .slice(0, displayCount) // Get the required number of items
          .reverse() // Reverse the order so the last item appears first
          .map((college, index) => (
            <CollegeCard key={index} college={college} />
          ))}
      </div>
      {displayCount < collegesData.length && (
        <div className="text-center mt-6">
          <button
            onClick={loadMore}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default CollageGrid;
