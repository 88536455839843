[
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76,
    "Category": "General"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 303,
    "Category": "General"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 434,
    "Category": "General"
  },
  {
    "Name": "Bangalore Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 858,
    "Category": "General"
  },
  {
    "Name": "Dayananda Sagar College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1170,
    "Category": "General"
  },
  {
    "Name": "BMS Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1244,
    "Category": "General"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1542,
    "Category": "General"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1853,
    "Category": "General"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in Fintech",
    "Score": 2128,
    "Category": "General"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2265,
    "Category": "General"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 239,
    "Category": "General"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 360,
    "Category": "General"
  },
  {
    "Name": "M.S. Ramaiah College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 594,
    "Category": "General"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1052,
    "Category": "General"
  },
  {
    "Name": "KLE Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1189,
    "Category": "General"
  },
  {
    "Name": "DSU - Dayananda Sagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1503,
    "Category": "General"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1649,
    "Category": "General"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2117,
    "Category": "General"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2206,
    "Category": "General"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Financial Management",
    "Score": 2393,
    "Category": "General"
  },
  {
    "Name": "RNS First Grade College(BCA, BBA, B.Com)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2501,
    "Category": "General"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2616,
    "Category": "General"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2685,
    "Category": "General"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2953,
    "Category": "General"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2980,
    "Category": "General"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3114,
    "Category": "General"
  },
  {
    "Name": "ABBS",
    "Course": "MBA in Business Analytics",
    "Score": 3258,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 3625,
    "Category": "General"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3693,
    "Category": "General"
  },
  {
    "Name": "CMR University (OMBR Campus)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4062,
    "Category": "General"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2528,
    "Category": "General"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2683,
    "Category": "General"
  },
  {
    "Name": "CMR Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2811,
    "Category": "General"
  },
  {
    "Name": "KLE Dr. M.S.Sheshgiri College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2959,
    "Category": "General"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in International Business",
    "Score": 3055,
    "Category": "General"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3176,
    "Category": "General"
  },
  {
    "Name": "Christ Academy Institute for Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3437,
    "Category": "General"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3683,
    "Category": "General"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3740,
    "Category": "General"
  },
  {
    "Name": "Acharya Institute Of Management And Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4072,
    "Category": "General"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4408,
    "Category": "General"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4473,
    "Category": "General"
  },
  {
    "Name": "Amity University, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4571,
    "Category": "General"
  },
  {
    "Name": "School of Engineering and Technology, CMR University",
    "Course": "Master of Business Administration (MBA Dual)",
    "Score": 4918,
    "Category": "General"
  },
  {
    "Name": "SJB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5080,
    "Category": "General"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5190,
    "Category": "General"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5369,
    "Category": "General"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5606,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 5862,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Operations and Supply Chain Management",
    "Score": 5862,
    "Category": "General"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4445,
    "Category": "General"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 4512,
    "Category": "General"
  },
  {
    "Name": "IFIM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4643,
    "Category": "General"
  },
  {
    "Name": "St. Aloysius College Magaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4987,
    "Category": "General"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5100,
    "Category": "General"
  },
  {
    "Name": "SJB Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5282,
    "Category": "General"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5536,
    "Category": "General"
  },
  {
    "Name": "Acharya Institute of Graduate Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5841,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Transformation",
    "Score": 5862,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in E-Business Management",
    "Score": 5862,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A. in Marketing",
    "Score": 5862,
    "Category": "General"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Digital Marketing",
    "Score": 5903,
    "Category": "General"
  },
  {
    "Name": "Government Ramnarayan Chellaram College of Commerce and Management",
    "Course": "Master of Business Adminsitrations",
    "Score": 5941,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Marketing",
    "Score": 6457,
    "Category": "General"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6995,
    "Category": "General"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7075,
    "Category": "General"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7258,
    "Category": "General"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7307,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Banking and Finance Management",
    "Score": 7704,
    "Category": "General"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7910,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A in Human Resource",
    "Score": 5862,
    "Category": "General"
  },
  {
    "Name": "B.N Bahadur Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5919,
    "Category": "General"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6208,
    "Category": "General"
  },
  {
    "Name": "SDM P.G. Centre for Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6528,
    "Category": "General"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7023,
    "Category": "General"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7195,
    "Category": "General"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7277,
    "Category": "General"
  },
  {
    "Name": "BGS College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7678,
    "Category": "General"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7894,
    "Category": "General"
  },
  {
    "Name": "Community Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7992,
    "Category": "General"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8050,
    "Category": "General"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Discontinued (May 2024) Master of Business Administration (MBA)",
    "Score": 8146,
    "Category": "General"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8683,
    "Category": "General"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8891,
    "Category": "General"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9246,
    "Category": "General"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9328,
    "Category": "General"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9396,
    "Category": "General"
  },
  {
    "Name": "St. Claret College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9504,
    "Category": "General"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9626,
    "Category": "General"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10186,
    "Category": "General"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8144,
    "Category": "General"
  },
  {
    "Name": "Chanakya University",
    "Course": "Master in Business Administration (MBA)",
    "Score": 8506,
    "Category": "General"
  },
  {
    "Name": "Mangalore University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8858,
    "Category": "General"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9148,
    "Category": "General"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 9255,
    "Category": "General"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9390,
    "Category": "General"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9406,
    "Category": "General"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9587,
    "Category": "General"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9978,
    "Category": "General"
  },
  {
    "Name": "MITE - Mangalore Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10197,
    "Category": "General"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10208,
    "Category": "General"
  },
  {
    "Name": "Department of  PG Studies, Visvesvaraya Technological University, Mysuru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10367,
    "Category": "General"
  },
  {
    "Name": "Maharani's Arts, Commerce and Management College for Wormen",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10826,
    "Category": "General"
  },
  {
    "Name": "Maharaja Institute of Technology, Thandavapura",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10983,
    "Category": "General"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11301,
    "Category": "General"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Retail Management",
    "Score": 11356,
    "Category": "General"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11560,
    "Category": "General"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11800,
    "Category": "General"
  },
  {
    "Name": "SBRR Mahajana First Grade College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11924,
    "Category": "General"
  },
  {
    "Name": "Maharanis Commerce and Management College for Women, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12031,
    "Category": "General"
  },
  {
    "Name": "Gulbarga University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10252,
    "Category": "General"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10488,
    "Category": "General"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10881,
    "Category": "General"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11040,
    "Category": "General"
  },
  {
    "Name": "St.Philominas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11305,
    "Category": "General"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11418,
    "Category": "General"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11674,
    "Category": "General"
  },
  {
    "Name": "Government First Grade College, Vijayanagar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11912,
    "Category": "General"
  },
  {
    "Name": "Yuvarajas College, University of Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11980,
    "Category": "General"
  },
  {
    "Name": "Maharani Cluster University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12070,
    "Category": "General"
  },
  {
    "Name": "Kousali Institute of Management Studies, Karnataka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12169,
    "Category": "General"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12503,
    "Category": "General"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12627,
    "Category": "General"
  },
  {
    "Name": "Tumkur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13206,
    "Category": "General"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13374,
    "Category": "General"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14018,
    "Category": "General"
  },
  {
    "Name": "A.J. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14156,
    "Category": "General"
  },
  {
    "Name": "BGS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14289,
    "Category": "General"
  },
  {
    "Name": "The East Point College of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14360,
    "Category": "General"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14625,
    "Category": "General"
  },
  {
    "Name": "PES College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12295,
    "Category": "General"
  },
  {
    "Name": "Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12586,
    "Category": "General"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13168,
    "Category": "General"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13319,
    "Category": "General"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13544,
    "Category": "General"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14062,
    "Category": "General"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14174,
    "Category": "General"
  },
  {
    "Name": "Akash Institute of Engineering and Technology",
    "Course": "MBA",
    "Score": 14323,
    "Category": "General"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 14405,
    "Category": "General"
  },
  {
    "Name": "Vijayanagara Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14756,
    "Category": "General"
  },
  {
    "Name": "BLDEAs V. P. Dr. P. G. Halakatti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14777,
    "Category": "General"
  },
  {
    "Name": "Karnataka State Rural Development and Panchayat Raj University, Gadag",
    "Course": "MBA in Rural Management",
    "Score": 14938,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15147,
    "Category": "General"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15419,
    "Category": "General"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Information Management",
    "Score": 15460,
    "Category": "General"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15822,
    "Category": "General"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16147,
    "Category": "General"
  },
  {
    "Name": "University B.D.T College of Engineering, Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16488,
    "Category": "General"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16908,
    "Category": "General"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17018,
    "Category": "General"
  },
  {
    "Name": "Maharaja Institute of Technology, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14792,
    "Category": "General"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15102,
    "Category": "General"
  },
  {
    "Name": "Jain College of Engineering and Research, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15179,
    "Category": "General"
  },
  {
    "Name": "Kuvempu University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15444,
    "Category": "General"
  },
  {
    "Name": "St. Agnes College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15597,
    "Category": "General"
  },
  {
    "Name": "Institute of Business Management and Research,Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15969,
    "Category": "General"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16207,
    "Category": "General"
  },
  {
    "Name": "KLEs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16493,
    "Category": "General"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16929,
    "Category": "General"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17124,
    "Category": "General"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17125,
    "Category": "General"
  },
  {
    "Name": "Sri Devaraj Urs Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17633,
    "Category": "General"
  },
  {
    "Name": "Parivarthana Business School'",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18321,
    "Category": "General"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18483,
    "Category": "General"
  },
  {
    "Name": "S.E.A College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18866,
    "Category": "General"
  },
  {
    "Name": "Hill Side Institute of Management",
    "Course": "MBA in Business Management",
    "Score": 19261,
    "Category": "General"
  },
  {
    "Name": "Gopalan College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19759,
    "Category": "General"
  },
  {
    "Name": "JIT - Jain Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20043,
    "Category": "General"
  },
  {
    "Name": "Satellite Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20389,
    "Category": "General"
  },
  {
    "Name": "Rani Channamma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21003,
    "Category": "General"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17462,
    "Category": "General"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Hospital Administration",
    "Score": 17850,
    "Category": "General"
  },
  {
    "Name": "Nagarjuna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18451,
    "Category": "General"
  },
  {
    "Name": "St. Francis College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 18724,
    "Category": "General"
  },
  {
    "Name": "Bangalore Integrated Management Academy, BIMA",
    "Course": "MBA",
    "Score": 19082,
    "Category": "General"
  },
  {
    "Name": "Sahyadri Commerce and Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19317,
    "Category": "General"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "MBA",
    "Score": 19778,
    "Category": "General"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20262,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 20843,
    "Category": "General"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 21024,
    "Category": "General"
  },
  {
    "Name": "Jain College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21030,
    "Category": "General"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21131,
    "Category": "General"
  },
  {
    "Name": "Brindavan College (National Fgc)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21204,
    "Category": "General"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21991,
    "Category": "General"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22103,
    "Category": "General"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22449,
    "Category": "General"
  },
  {
    "Name": "Shri Madhwa Vadiraja Institute of Technology and Management (SMVITM)",
    "Course": "MBA",
    "Score": 22828,
    "Category": "General"
  },
  {
    "Name": "Sindhi Institute of Management - SIM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23131,
    "Category": "General"
  },
  {
    "Name": "Mysuru Royal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23571,
    "Category": "General"
  },
  {
    "Name": "R. L. Jalappa Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23706,
    "Category": "General"
  },
  {
    "Name": "Government First Grade College,Davanagere",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21062,
    "Category": "General"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21162,
    "Category": "General"
  },
  {
    "Name": "K.S. School of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21571,
    "Category": "General"
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22089,
    "Category": "General"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22216,
    "Category": "General"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22623,
    "Category": "General"
  },
  {
    "Name": "RIT - Rajeev Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22834,
    "Category": "General"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 23518,
    "Category": "General"
  },
  {
    "Name": "Silicon City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23700,
    "Category": "General"
  },
  {
    "Name": "Soundarya Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23840,
    "Category": "General"
  },
  {
    "Name": "Nagarjuna College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23945,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Human Resource Management",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Marketing Management",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship and Family Business Management",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Health Care Management and Hospital Administration",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Davangere University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24356,
    "Category": "General"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24525,
    "Category": "General"
  },
  {
    "Name": "Karnataka University, Dharwad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24963,
    "Category": "General"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25330,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Financial Management",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Business Analytics and Big Data Analytics",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Marketing",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Transformation",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship Startup Management",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Project Management and Information Technology",
    "Score": 24205,
    "Category": "General"
  },
  {
    "Name": "ATMA College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 24469,
    "Category": "General"
  },
  {
    "Name": "GM University",
    "Course": "MBA in General Management",
    "Score": 24531,
    "Category": "General"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25169,
    "Category": "General"
  },
  {
    "Name": "R.R. Institute of Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25351,
    "Category": "General"
  },
  {
    "Name": "K L E S`s K L E College of Engineering and Technology, Chikodi",
    "Course": "MBA",
    "Score": 25376,
    "Category": "General"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "MBA in Innovation, Entrepreneurship & Venture Development",
    "Score": 25577,
    "Category": "General"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25680,
    "Category": "General"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26004,
    "Category": "General"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26128,
    "Category": "General"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26558,
    "Category": "General"
  },
  {
    "Name": "Sambhram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26991,
    "Category": "General"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27601,
    "Category": "General"
  },
  {
    "Name": "Patel Institute of Science and Management.",
    "Course": "Master of Business Administration",
    "Score": 27968,
    "Category": "General"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28296,
    "Category": "General"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25570,
    "Category": "General"
  },
  {
    "Name": "St. Francis De Sales College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25581,
    "Category": "General"
  },
  {
    "Name": "Jyothi Institute of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25932,
    "Category": "General"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26045,
    "Category": "General"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26383,
    "Category": "General"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26670,
    "Category": "General"
  },
  {
    "Name": "Adithya Institue of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27423,
    "Category": "General"
  },
  {
    "Name": "R.S. College of Management and Science",
    "Course": "MBA in Information Management",
    "Score": 27967,
    "Category": "General"
  },
  {
    "Name": "Padmashree Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28278,
    "Category": "General"
  },
  {
    "Name": "Aditya College Of Engineering and Technology Yelahanka, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28492,
    "Category": "General"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28604,
    "Category": "General"
  },
  {
    "Name": "Oxford College of Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29002,
    "Category": "General"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29339,
    "Category": "General"
  },
  {
    "Name": "Sri Siddhartha Academy of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29791,
    "Category": "General"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30003,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA) (Exclusively for Women)",
    "Score": 30278,
    "Category": "General"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30405,
    "Category": "General"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31059,
    "Category": "General"
  },
  {
    "Name": "GRV Business Management Academy",
    "Course": "MBA + PGDBA",
    "Score": 31496,
    "Category": "General"
  },
  {
    "Name": "Manel Srinivas Nayak Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32066,
    "Category": "General"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28834,
    "Category": "General"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "MBA in Information Management",
    "Score": 29332,
    "Category": "General"
  },
  {
    "Name": "Al-Ameen Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29367,
    "Category": "General"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29928,
    "Category": "General"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Pharmacy Administration",
    "Score": 30261,
    "Category": "General"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA in General Management",
    "Score": 30326,
    "Category": "General"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30768,
    "Category": "General"
  },
  {
    "Name": "Impact College of Engineering and Applied Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31169,
    "Category": "General"
  },
  {
    "Name": "One School of Business",
    "Course": "Global MBA",
    "Score": 31883,
    "Category": "General"
  },
  {
    "Name": "Sampurna Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32371,
    "Category": "General"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32808,
    "Category": "General"
  },
  {
    "Name": "Rai Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32893,
    "Category": "General"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33537,
    "Category": "General"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "MBA in General Management",
    "Score": 33800,
    "Category": "General"
  },
  {
    "Name": "Impact Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33998,
    "Category": "General"
  },
  {
    "Name": "International Academy of Management and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34359,
    "Category": "General"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34718,
    "Category": "General"
  },
  {
    "Name": "Biluru Gurubasava Mahaswamiji Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34755,
    "Category": "General"
  },
  {
    "Name": "Dr. Sri. Sri. Sri. Shivakumara Mahaswamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34799,
    "Category": "General"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 34972,
    "Category": "General"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Hospital Administration",
    "Score": 32872,
    "Category": "General"
  },
  {
    "Name": "Rathinam School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33414,
    "Category": "General"
  },
  {
    "Name": "Imperial Institute of Advanced Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 33571,
    "Category": "General"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33830,
    "Category": "General"
  },
  {
    "Name": "Dr. Kariappa School of Art and Design Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34330,
    "Category": "General"
  },
  {
    "Name": "Srinivas University",
    "Course": "Discontinued (Mar 2024)-Master of Business Administration (MBA Hons.) (Dual Specialization)",
    "Score": 34477,
    "Category": "General"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34739,
    "Category": "General"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34786,
    "Category": "General"
  },
  {
    "Name": "H K E Society's SLN College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34960,
    "Category": "General"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 35066,
    "Category": "General"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35235,
    "Category": "General"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35428,
    "Category": "General"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "MBA",
    "Score": 35537,
    "Category": "General"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35620,
    "Category": "General"
  },
  {
    "Name": "C.B. Bhandari Jain College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35675,
    "Category": "General"
  },
  {
    "Name": "Srinivas University",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 35734,
    "Category": "General"
  },
  {
    "Name": "United International Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35740,
    "Category": "General"
  },
  {
    "Name": "Indo Asian Academy Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35879,
    "Category": "General"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35927,
    "Category": "General"
  },
  {
    "Name": "APS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35949,
    "Category": "General"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35386,
    "Category": "General"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35510,
    "Category": "General"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35617,
    "Category": "General"
  },
  {
    "Name": "One School of Business",
    "Course": "MBA Advance",
    "Score": 35636,
    "Category": "General"
  },
  {
    "Name": "MQI College of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35710,
    "Category": "General"
  },
  {
    "Name": "Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration",
    "Score": 35738,
    "Category": "General"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35866,
    "Category": "General"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35916,
    "Category": "General"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35940,
    "Category": "General"
  },
  {
    "Name": "City Engineering College (CEC, Banglore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35962,
    "Category": "General"
  },
  {
    "Name": "MEWA Vanguard Business School",
    "Course": "MBA Vanguard",
    "Score": 35971,
    "Category": "General"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Agricultural Marketing",
    "Score": 35978,
    "Category": "General"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36043,
    "Category": "General"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36081,
    "Category": "General"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36128,
    "Category": "General"
  },
  {
    "Name": "A.V.K. Institute of Management",
    "Course": "M.B.A. in Marketing, HR, Finance",
    "Score": 36151,
    "Category": "General"
  },
  {
    "Name": "Hillside Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36169,
    "Category": "General"
  },
  {
    "Name": "BCET - Bangalore College of Engineering and Technology",
    "Course": "MBA",
    "Score": 36186,
    "Category": "General"
  },
  {
    "Name": "T. John Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36217,
    "Category": "General"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36230,
    "Category": "General"
  },
  {
    "Name": "Dr. H N National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35972,
    "Category": "General"
  },
  {
    "Name": "KU Post Graduate Center, Gadag",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35993,
    "Category": "General"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36073,
    "Category": "General"
  },
  {
    "Name": "T. John Group of Institutions",
    "Course": "Master of Business Administration (BU)",
    "Score": 36094,
    "Category": "General"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36129,
    "Category": "General"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36167,
    "Category": "General"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36182,
    "Category": "General"
  },
  {
    "Name": "MVM College of Arts Science & Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36216,
    "Category": "General"
  },
  {
    "Name": "GM University",
    "Course": "MBA in International Business",
    "Score": 36229,
    "Category": "General"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36240,
    "Category": "General"
  },
  {
    "Name": "IZee Business School",
    "Course": "MBA + PGPM Global",
    "Score": 42297,
    "Category": "General"
  },
  {
    "Name": "Harsha Institutions",
    "Course": "Master of Business Administration (MBA)- Value Added",
    "Score": 42905,
    "Category": "General"
  },
  {
    "Name": "Vogue Dr. Kariappa Business School",
    "Course": "Hybrid MBA",
    "Score": 41771,
    "Category": "General"
  },
  {
    "Name": "National Power Training Institute, Karnatak",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42862,
    "Category": "General"
  },
  {
    "Name": "Shri Madhwa Vadiraja Institute of Technology and Management (SMVITM)",
    "Course": "MBA",
    "Score": 43006,
    "Category": "General"
  },
  {
    "Name": "Dhanwantari Academy for Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36402,
    "Category": "General"
  },
  {
    "Name": "Regional Institute of Cooperative Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40984,
    "Category": "General"
  },
  {
    "Name": "BTL Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43168,
    "Category": "General"
  },
  {
    "Name": "Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42995,
    "Category": "General"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76,
    "Category": "OBC"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 303,
    "Category": "OBC"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 434,
    "Category": "OBC"
  },
  {
    "Name": "Bangalore Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 858,
    "Category": "OBC"
  },
  {
    "Name": "Dayananda Sagar College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1170,
    "Category": "OBC"
  },
  {
    "Name": "BMS Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1244,
    "Category": "OBC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1542,
    "Category": "OBC"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1853,
    "Category": "OBC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in Fintech",
    "Score": 2128,
    "Category": "OBC"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2265,
    "Category": "OBC"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 239,
    "Category": "OBC"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 360,
    "Category": "OBC"
  },
  {
    "Name": "M.S. Ramaiah College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 594,
    "Category": "OBC"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1052,
    "Category": "OBC"
  },
  {
    "Name": "KLE Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1189,
    "Category": "OBC"
  },
  {
    "Name": "DSU - Dayananda Sagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1503,
    "Category": "OBC"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1649,
    "Category": "OBC"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2117,
    "Category": "OBC"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2206,
    "Category": "OBC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Financial Management",
    "Score": 2393,
    "Category": "OBC"
  },
  {
    "Name": "RNS First Grade College(BCA, BBA, B.Com)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2501,
    "Category": "OBC"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2616,
    "Category": "OBC"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2685,
    "Category": "OBC"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2953,
    "Category": "OBC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2980,
    "Category": "OBC"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3114,
    "Category": "OBC"
  },
  {
    "Name": "ABBS",
    "Course": "MBA in Business Analytics",
    "Score": 3258,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 3625,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3693,
    "Category": "OBC"
  },
  {
    "Name": "CMR University (OMBR Campus)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4062,
    "Category": "OBC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2528,
    "Category": "OBC"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2683,
    "Category": "OBC"
  },
  {
    "Name": "CMR Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2811,
    "Category": "OBC"
  },
  {
    "Name": "KLE Dr. M.S.Sheshgiri College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2959,
    "Category": "OBC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in International Business",
    "Score": 3055,
    "Category": "OBC"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3176,
    "Category": "OBC"
  },
  {
    "Name": "Christ Academy Institute for Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3437,
    "Category": "OBC"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3683,
    "Category": "OBC"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3740,
    "Category": "OBC"
  },
  {
    "Name": "Acharya Institute Of Management And Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4072,
    "Category": "OBC"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4408,
    "Category": "OBC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4473,
    "Category": "OBC"
  },
  {
    "Name": "Amity University, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4571,
    "Category": "OBC"
  },
  {
    "Name": "School of Engineering and Technology, CMR University",
    "Course": "Master of Business Administration (MBA Dual)",
    "Score": 4918,
    "Category": "OBC"
  },
  {
    "Name": "SJB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5080,
    "Category": "OBC"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5190,
    "Category": "OBC"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5369,
    "Category": "OBC"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5606,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 5862,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Operations and Supply Chain Management",
    "Score": 5862,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A. in Marketing",
    "Score": 5862,
    "Category": "OBC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Digital Marketing",
    "Score": 5903,
    "Category": "OBC"
  },
  {
    "Name": "Government Ramnarayan Chellaram College of Commerce and Management",
    "Course": "Master of Business Adminsitrations",
    "Score": 5941,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Marketing",
    "Score": 6457,
    "Category": "OBC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6995,
    "Category": "OBC"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7075,
    "Category": "OBC"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7258,
    "Category": "OBC"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7307,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Banking and Finance Management",
    "Score": 7704,
    "Category": "OBC"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7910,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A in Human Resource",
    "Score": 5862,
    "Category": "OBC"
  },
  {
    "Name": "B.N Bahadur Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5919,
    "Category": "OBC"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6208,
    "Category": "OBC"
  },
  {
    "Name": "SDM P.G. Centre for Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6528,
    "Category": "OBC"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7023,
    "Category": "OBC"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7195,
    "Category": "OBC"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7277,
    "Category": "OBC"
  },
  {
    "Name": "BGS College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7678,
    "Category": "OBC"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7894,
    "Category": "OBC"
  },
  {
    "Name": "Community Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7992,
    "Category": "OBC"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8050,
    "Category": "OBC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Discontinued (May 2024) Master of Business Administration (MBA)",
    "Score": 8146,
    "Category": "OBC"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8683,
    "Category": "OBC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8891,
    "Category": "OBC"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9246,
    "Category": "OBC"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9328,
    "Category": "OBC"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9396,
    "Category": "OBC"
  },
  {
    "Name": "St. Claret College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9504,
    "Category": "OBC"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9626,
    "Category": "OBC"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10186,
    "Category": "OBC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8144,
    "Category": "OBC"
  },
  {
    "Name": "Chanakya University",
    "Course": "Master in Business Administration (MBA)",
    "Score": 8506,
    "Category": "OBC"
  },
  {
    "Name": "Mangalore University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8858,
    "Category": "OBC"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9148,
    "Category": "OBC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 9255,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9390,
    "Category": "OBC"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9406,
    "Category": "OBC"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9587,
    "Category": "OBC"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9978,
    "Category": "OBC"
  },
  {
    "Name": "MITE - Mangalore Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10197,
    "Category": "OBC"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10208,
    "Category": "OBC"
  },
  {
    "Name": "Department of  PG Studies, Visvesvaraya Technological University, Mysuru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10367,
    "Category": "OBC"
  },
  {
    "Name": "Maharani's Arts, Commerce and Management College for Wormen",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10826,
    "Category": "OBC"
  },
  {
    "Name": "Maharaja Institute of Technology, Thandavapura",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10983,
    "Category": "OBC"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11301,
    "Category": "OBC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Retail Management",
    "Score": 11356,
    "Category": "OBC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11560,
    "Category": "OBC"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11800,
    "Category": "OBC"
  },
  {
    "Name": "SBRR Mahajana First Grade College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11924,
    "Category": "OBC"
  },
  {
    "Name": "Maharanis Commerce and Management College for Women, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12031,
    "Category": "OBC"
  },
  {
    "Name": "Kousali Institute of Management Studies, Karnataka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12169,
    "Category": "OBC"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12503,
    "Category": "OBC"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12627,
    "Category": "OBC"
  },
  {
    "Name": "Tumkur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13206,
    "Category": "OBC"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13374,
    "Category": "OBC"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14018,
    "Category": "OBC"
  },
  {
    "Name": "A.J. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14156,
    "Category": "OBC"
  },
  {
    "Name": "BGS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14289,
    "Category": "OBC"
  },
  {
    "Name": "The East Point College of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14360,
    "Category": "OBC"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14625,
    "Category": "OBC"
  },
  {
    "Name": "PES College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12295,
    "Category": "OBC"
  },
  {
    "Name": "Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12586,
    "Category": "OBC"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13168,
    "Category": "OBC"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13319,
    "Category": "OBC"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13544,
    "Category": "OBC"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14062,
    "Category": "OBC"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14174,
    "Category": "OBC"
  },
  {
    "Name": "Akash Institute of Engineering and Technology",
    "Course": "MBA",
    "Score": 14323,
    "Category": "OBC"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 14405,
    "Category": "OBC"
  },
  {
    "Name": "Vijayanagara Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14756,
    "Category": "OBC"
  },
  {
    "Name": "BLDEAs V. P. Dr. P. G. Halakatti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14777,
    "Category": "OBC"
  },
  {
    "Name": "Karnataka State Rural Development and Panchayat Raj University, Gadag",
    "Course": "MBA in Rural Management",
    "Score": 14938,
    "Category": "OBC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15147,
    "Category": "OBC"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15419,
    "Category": "OBC"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Information Management",
    "Score": 15460,
    "Category": "OBC"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15822,
    "Category": "OBC"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16147,
    "Category": "OBC"
  },
  {
    "Name": "University B.D.T College of Engineering, Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16488,
    "Category": "OBC"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16908,
    "Category": "OBC"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17018,
    "Category": "OBC"
  },
  {
    "Name": "Maharaja Institute of Technology, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14792,
    "Category": "OBC"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15102,
    "Category": "OBC"
  },
  {
    "Name": "Jain College of Engineering and Research, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15179,
    "Category": "OBC"
  },
  {
    "Name": "Kuvempu University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15444,
    "Category": "OBC"
  },
  {
    "Name": "St. Agnes College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15597,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Business Management and Research,Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15969,
    "Category": "OBC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16207,
    "Category": "OBC"
  },
  {
    "Name": "KLEs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16493,
    "Category": "OBC"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16929,
    "Category": "OBC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17124,
    "Category": "OBC"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17125,
    "Category": "OBC"
  },
  {
    "Name": "Sri Devaraj Urs Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17633,
    "Category": "OBC"
  },
  {
    "Name": "Parivarthana Business School'",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18321,
    "Category": "OBC"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18483,
    "Category": "OBC"
  },
  {
    "Name": "S.E.A College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18866,
    "Category": "OBC"
  },
  {
    "Name": "Hill Side Institute of Management",
    "Course": "MBA in Business Management",
    "Score": 19261,
    "Category": "OBC"
  },
  {
    "Name": "Gopalan College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19759,
    "Category": "OBC"
  },
  {
    "Name": "JIT - Jain Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20043,
    "Category": "OBC"
  },
  {
    "Name": "Satellite Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20389,
    "Category": "OBC"
  },
  {
    "Name": "Rani Channamma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21003,
    "Category": "OBC"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17462,
    "Category": "OBC"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Hospital Administration",
    "Score": 17850,
    "Category": "OBC"
  },
  {
    "Name": "Nagarjuna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18451,
    "Category": "OBC"
  },
  {
    "Name": "St. Francis College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 18724,
    "Category": "OBC"
  },
  {
    "Name": "Bangalore Integrated Management Academy, BIMA",
    "Course": "MBA",
    "Score": 19082,
    "Category": "OBC"
  },
  {
    "Name": "Sahyadri Commerce and Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19317,
    "Category": "OBC"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "MBA",
    "Score": 19778,
    "Category": "OBC"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20262,
    "Category": "OBC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 20843,
    "Category": "OBC"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 21024,
    "Category": "OBC"
  },
  {
    "Name": "Jain College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21030,
    "Category": "OBC"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21131,
    "Category": "OBC"
  },
  {
    "Name": "Brindavan College (National Fgc)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21204,
    "Category": "OBC"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21991,
    "Category": "OBC"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22103,
    "Category": "OBC"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22449,
    "Category": "OBC"
  },
  {
    "Name": "Shri Madhwa Vadiraja Institute of Technology and Management (SMVITM)",
    "Course": "MBA",
    "Score": 22828,
    "Category": "OBC"
  },
  {
    "Name": "Sindhi Institute of Management - SIM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23131,
    "Category": "OBC"
  },
  {
    "Name": "Mysuru Royal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23571,
    "Category": "OBC"
  },
  {
    "Name": "R. L. Jalappa Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23706,
    "Category": "OBC"
  },
  {
    "Name": "Government First Grade College,Davanagere",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21062,
    "Category": "OBC"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21162,
    "Category": "OBC"
  },
  {
    "Name": "K.S. School of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21571,
    "Category": "OBC"
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22089,
    "Category": "OBC"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22216,
    "Category": "OBC"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22623,
    "Category": "OBC"
  },
  {
    "Name": "RIT - Rajeev Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22834,
    "Category": "OBC"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 23518,
    "Category": "OBC"
  },
  {
    "Name": "Silicon City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23700,
    "Category": "OBC"
  },
  {
    "Name": "Soundarya Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23840,
    "Category": "OBC"
  },
  {
    "Name": "Nagarjuna College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23945,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Human Resource Management",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Marketing Management",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship and Family Business Management",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Health Care Management and Hospital Administration",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Davangere University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24356,
    "Category": "OBC"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24525,
    "Category": "OBC"
  },
  {
    "Name": "Karnataka University, Dharwad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24963,
    "Category": "OBC"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25330,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Financial Management",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Business Analytics and Big Data Analytics",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Marketing",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Transformation",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship Startup Management",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Project Management and Information Technology",
    "Score": 24205,
    "Category": "OBC"
  },
  {
    "Name": "ATMA College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 24469,
    "Category": "OBC"
  },
  {
    "Name": "GM University",
    "Course": "MBA in General Management",
    "Score": 24531,
    "Category": "OBC"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25169,
    "Category": "OBC"
  },
  {
    "Name": "R.R. Institute of Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25351,
    "Category": "OBC"
  },
  {
    "Name": "K L E S`s K L E College of Engineering and Technology, Chikodi",
    "Course": "MBA",
    "Score": 25376,
    "Category": "OBC"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "MBA in Innovation, Entrepreneurship & Venture Development",
    "Score": 25577,
    "Category": "OBC"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25680,
    "Category": "OBC"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26004,
    "Category": "OBC"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26128,
    "Category": "OBC"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26558,
    "Category": "OBC"
  },
  {
    "Name": "Sambhram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26991,
    "Category": "OBC"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27601,
    "Category": "OBC"
  },
  {
    "Name": "Patel Institute of Science and Management.",
    "Course": "Master of Business Administration",
    "Score": 27968,
    "Category": "OBC"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28296,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25570,
    "Category": "OBC"
  },
  {
    "Name": "St. Francis De Sales College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25581,
    "Category": "OBC"
  },
  {
    "Name": "Jyothi Institute of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25932,
    "Category": "OBC"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26045,
    "Category": "OBC"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26383,
    "Category": "OBC"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26670,
    "Category": "OBC"
  },
  {
    "Name": "Adithya Institue of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27423,
    "Category": "OBC"
  },
  {
    "Name": "R.S. College of Management and Science",
    "Course": "MBA in Information Management",
    "Score": 27967,
    "Category": "OBC"
  },
  {
    "Name": "Padmashree Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28278,
    "Category": "OBC"
  },
  {
    "Name": "Aditya College Of Engineering and Technology Yelahanka, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28492,
    "Category": "OBC"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28604,
    "Category": "OBC"
  },
  {
    "Name": "Oxford College of Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29002,
    "Category": "OBC"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29339,
    "Category": "OBC"
  },
  {
    "Name": "Sri Siddhartha Academy of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29791,
    "Category": "OBC"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30003,
    "Category": "OBC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA) (Exclusively for Women)",
    "Score": 30278,
    "Category": "OBC"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30768,
    "Category": "OBC"
  },
  {
    "Name": "Impact College of Engineering and Applied Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31169,
    "Category": "OBC"
  },
  {
    "Name": "One School of Business",
    "Course": "Global MBA",
    "Score": 31883,
    "Category": "OBC"
  },
  {
    "Name": "Sampurna Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32371,
    "Category": "OBC"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28834,
    "Category": "OBC"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "MBA in Information Management",
    "Score": 29332,
    "Category": "OBC"
  },
  {
    "Name": "Al-Ameen Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29367,
    "Category": "OBC"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29928,
    "Category": "OBC"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Pharmacy Administration",
    "Score": 30261,
    "Category": "OBC"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30405,
    "Category": "OBC"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31059,
    "Category": "OBC"
  },
  {
    "Name": "GRV Business Management Academy",
    "Course": "MBA + PGDBA",
    "Score": 31496,
    "Category": "OBC"
  },
  {
    "Name": "Manel Srinivas Nayak Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32066,
    "Category": "OBC"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA in General Management",
    "Score": 32538,
    "Category": "OBC"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32808,
    "Category": "OBC"
  },
  {
    "Name": "Rai Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32893,
    "Category": "OBC"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33537,
    "Category": "OBC"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "MBA in General Management",
    "Score": 33800,
    "Category": "OBC"
  },
  {
    "Name": "Impact Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33998,
    "Category": "OBC"
  },
  {
    "Name": "International Academy of Management and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34359,
    "Category": "OBC"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34718,
    "Category": "OBC"
  },
  {
    "Name": "Biluru Gurubasava Mahaswamiji Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34755,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Sri. Sri. Sri. Shivakumara Mahaswamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34799,
    "Category": "OBC"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 34972,
    "Category": "OBC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Hospital Administration",
    "Score": 32872,
    "Category": "OBC"
  },
  {
    "Name": "Rathinam School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33414,
    "Category": "OBC"
  },
  {
    "Name": "Imperial Institute of Advanced Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 33571,
    "Category": "OBC"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33830,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Kariappa School of Art and Design Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34330,
    "Category": "OBC"
  },
  {
    "Name": "Srinivas University",
    "Course": "Discontinued (Mar 2024)-Master of Business Administration (MBA Hons.) (Dual Specialization)",
    "Score": 34477,
    "Category": "OBC"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34739,
    "Category": "OBC"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34786,
    "Category": "OBC"
  },
  {
    "Name": "H K E Society's SLN College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34960,
    "Category": "OBC"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 35066,
    "Category": "OBC"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35235,
    "Category": "OBC"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35428,
    "Category": "OBC"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "MBA",
    "Score": 35537,
    "Category": "OBC"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35620,
    "Category": "OBC"
  },
  {
    "Name": "C.B. Bhandari Jain College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35675,
    "Category": "OBC"
  },
  {
    "Name": "Srinivas University",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 35734,
    "Category": "OBC"
  },
  {
    "Name": "United International Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35740,
    "Category": "OBC"
  },
  {
    "Name": "Indo Asian Academy Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35879,
    "Category": "OBC"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35927,
    "Category": "OBC"
  },
  {
    "Name": "APS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35949,
    "Category": "OBC"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35386,
    "Category": "OBC"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35510,
    "Category": "OBC"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35617,
    "Category": "OBC"
  },
  {
    "Name": "One School of Business",
    "Course": "MBA Advance",
    "Score": 35636,
    "Category": "OBC"
  },
  {
    "Name": "MQI College of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35710,
    "Category": "OBC"
  },
  {
    "Name": "Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration",
    "Score": 35738,
    "Category": "OBC"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35866,
    "Category": "OBC"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35916,
    "Category": "OBC"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35940,
    "Category": "OBC"
  },
  {
    "Name": "City Engineering College (CEC, Banglore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35962,
    "Category": "OBC"
  },
  {
    "Name": "MEWA Vanguard Business School",
    "Course": "MBA Vanguard",
    "Score": 27038,
    "Category": "OBC"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Agricultural Marketing",
    "Score": 38881,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17098,
    "Category": "OBC"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22278,
    "Category": "OBC"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34268,
    "Category": "OBC"
  },
  {
    "Name": "A.V.K. Institute of Management",
    "Course": "M.B.A. in Marketing, HR, Finance",
    "Score": 36151,
    "Category": "OBC"
  },
  {
    "Name": "Hillside Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36169,
    "Category": "OBC"
  },
  {
    "Name": "BCET - Bangalore College of Engineering and Technology",
    "Course": "MBA",
    "Score": 43247,
    "Category": "OBC"
  },
  {
    "Name": "T. John Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19490,
    "Category": "OBC"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32451,
    "Category": "OBC"
  },
  {
    "Name": "Dr. H N National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35972,
    "Category": "OBC"
  },
  {
    "Name": "KU Post Graduate Center, Gadag",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35993,
    "Category": "OBC"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36073,
    "Category": "OBC"
  },
  {
    "Name": "T. John Group of Institutions",
    "Course": "Master of Business Administration (BU)",
    "Score": 36094,
    "Category": "OBC"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36129,
    "Category": "OBC"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36167,
    "Category": "OBC"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36182,
    "Category": "OBC"
  },
  {
    "Name": "MVM College of Arts Science & Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36216,
    "Category": "OBC"
  },
  {
    "Name": "GM University",
    "Course": "MBA in International Business",
    "Score": 36229,
    "Category": "OBC"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36240,
    "Category": "OBC"
  },
  {
    "Name": "IZee Business School",
    "Course": "MBA + PGPM Global",
    "Score": 42297,
    "Category": "OBC"
  },
  {
    "Name": "Harsha Institutions",
    "Course": "Master of Business Administration (MBA)- Value Added",
    "Score": 42905,
    "Category": "OBC"
  },
  {
    "Name": "Vogue Dr. Kariappa Business School",
    "Course": "Hybrid MBA",
    "Score": 41771,
    "Category": "OBC"
  },
  {
    "Name": "National Power Training Institute, Karnatak",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42862,
    "Category": "OBC"
  },
  {
    "Name": "Shri Madhwa Vadiraja Institute of Technology and Management (SMVITM)",
    "Course": "MBA",
    "Score": 43006,
    "Category": "OBC"
  },
  {
    "Name": "Dhanwantari Academy for Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36402,
    "Category": "OBC"
  },
  {
    "Name": "Regional Institute of Cooperative Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40984,
    "Category": "OBC"
  },
  {
    "Name": "BTL Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43168,
    "Category": "OBC"
  },
  {
    "Name": "Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42995,
    "Category": "OBC"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 880,
    "Category": "SC"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1946,
    "Category": "SC"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2281,
    "Category": "SC"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3054,
    "Category": "SC"
  },
  {
    "Name": "M.S. Ramaiah College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3692,
    "Category": "SC"
  },
  {
    "Name": "St. Aloysius College Magaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4987,
    "Category": "SC"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5190,
    "Category": "SC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Financial Management",
    "Score": 6279,
    "Category": "SC"
  },
  {
    "Name": "SDM P.G. Centre for Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6528,
    "Category": "SC"
  },
  {
    "Name": "Christ Academy Institute for Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6646,
    "Category": "SC"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1868,
    "Category": "SC"
  },
  {
    "Name": "Bangalore Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2126,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2552,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3179,
    "Category": "SC"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4850,
    "Category": "SC"
  },
  {
    "Name": "SJB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5080,
    "Category": "SC"
  },
  {
    "Name": "KLE Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5292,
    "Category": "SC"
  },
  {
    "Name": "BMS Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6431,
    "Category": "SC"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "MBA in Innovation, Entrepreneurship & Venture Development",
    "Score": 6588,
    "Category": "SC"
  },
  {
    "Name": "Dayananda Sagar College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6737,
    "Category": "SC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7418,
    "Category": "SC"
  },
  {
    "Name": "IFIM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8010,
    "Category": "SC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8438,
    "Category": "SC"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8932,
    "Category": "SC"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9587,
    "Category": "SC"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9674,
    "Category": "SC"
  },
  {
    "Name": "Acharya Institute Of Management And Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10711,
    "Category": "SC"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10919,
    "Category": "SC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in Fintech",
    "Score": 11302,
    "Category": "SC"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11640,
    "Category": "SC"
  },
  {
    "Name": "DSU - Dayananda Sagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7752,
    "Category": "SC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Discontinued (May 2024) Master of Business Administration (MBA)",
    "Score": 8146,
    "Category": "SC"
  },
  {
    "Name": "CMR Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8919,
    "Category": "SC"
  },
  {
    "Name": "ABBS",
    "Course": "MBA in Business Analytics",
    "Score": 9553,
    "Category": "SC"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9656,
    "Category": "SC"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9713,
    "Category": "SC"
  },
  {
    "Name": "Maharani's Arts, Commerce and Management College for Wormen",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10826,
    "Category": "SC"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11187,
    "Category": "SC"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11543,
    "Category": "SC"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in International Business",
    "Score": 11648,
    "Category": "SC"
  },
  {
    "Name": "RNS First Grade College(BCA, BBA, B.Com)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12219,
    "Category": "SC"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12378,
    "Category": "SC"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "iDream Master of Business Administration (MBA)",
    "Score": 13331,
    "Category": "SC"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13440,
    "Category": "SC"
  },
  {
    "Name": "CMR University (OMBR Campus)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14016,
    "Category": "SC"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14142,
    "Category": "SC"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14586,
    "Category": "SC"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15009,
    "Category": "SC"
  },
  {
    "Name": "KLE Dr. M.S.Sheshgiri College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15248,
    "Category": "SC"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15484,
    "Category": "SC"
  },
  {
    "Name": "B.N Bahadur Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12285,
    "Category": "SC"
  },
  {
    "Name": "Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12586,
    "Category": "SC"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13374,
    "Category": "SC"
  },
  {
    "Name": "Government Ramnarayan Chellaram College of Commerce and Management",
    "Course": "Master of Business Adminsitrations",
    "Score": 13615,
    "Category": "SC"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14062,
    "Category": "SC"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14492,
    "Category": "SC"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14967,
    "Category": "SC"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15151,
    "Category": "SC"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Information Management",
    "Score": 15460,
    "Category": "SC"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15528,
    "Category": "SC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15603,
    "Category": "SC"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16147,
    "Category": "SC"
  },
  {
    "Name": "KLEs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16493,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 16748,
    "Category": "SC"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17125,
    "Category": "SC"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Hospital Administration",
    "Score": 17850,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Marketing",
    "Score": 18060,
    "Category": "SC"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18483,
    "Category": "SC"
  },
  {
    "Name": "SJB Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18879,
    "Category": "SC"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 19073,
    "Category": "SC"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15667,
    "Category": "SC"
  },
  {
    "Name": "Government First Grade College, Vijayanagar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16266,
    "Category": "SC"
  },
  {
    "Name": "School of Engineering and Technology, CMR University",
    "Course": "Master of Business Administration (MBA Dual)",
    "Score": 16580,
    "Category": "SC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Digital Marketing",
    "Score": 16810,
    "Category": "SC"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17272,
    "Category": "SC"
  },
  {
    "Name": "Yuvarajas College, University of Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17973,
    "Category": "SC"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18163,
    "Category": "SC"
  },
  {
    "Name": "S.E.A College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18866,
    "Category": "SC"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19052,
    "Category": "SC"
  },
  {
    "Name": "Amity University, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19130,
    "Category": "SC"
  },
  {
    "Name": "T. John Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19490,
    "Category": "SC"
  },
  {
    "Name": "Acharya Institute of Graduate Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19619,
    "Category": "SC"
  },
  {
    "Name": "Maharani Cluster University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19839,
    "Category": "SC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20707,
    "Category": "SC"
  },
  {
    "Name": "Department of  PG Studies, Visvesvaraya Technological University, Mysuru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20936,
    "Category": "SC"
  },
  {
    "Name": "Maharanis Commerce and Management College for Women, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21282,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 21544,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Operations and Supply Chain Management",
    "Score": 21544,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A. in Marketing",
    "Score": 21544,
    "Category": "SC"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 21683,
    "Category": "SC"
  },
  {
    "Name": "Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21786,
    "Category": "SC"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22023,
    "Category": "SC"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22613,
    "Category": "SC"
  },
  {
    "Name": "Kuvempu University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22881,
    "Category": "SC"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23123,
    "Category": "SC"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23381,
    "Category": "SC"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23610,
    "Category": "SC"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "MBA in General Management",
    "Score": 23848,
    "Category": "SC"
  },
  {
    "Name": "Tumkur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24332,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Banking and Finance Management",
    "Score": 24534,
    "Category": "SC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21927,
    "Category": "SC"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22031,
    "Category": "SC"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22654,
    "Category": "SC"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22958,
    "Category": "SC"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23150,
    "Category": "SC"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 23518,
    "Category": "SC"
  },
  {
    "Name": "SBRR Mahajana First Grade College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23713,
    "Category": "SC"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24247,
    "Category": "SC"
  },
  {
    "Name": "St.Philominas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24495,
    "Category": "SC"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 24709,
    "Category": "SC"
  },
  {
    "Name": "Sri Devaraj Urs Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24764,
    "Category": "SC"
  },
  {
    "Name": "Maharaja Institute of Technology, Thandavapura",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24923,
    "Category": "SC"
  },
  {
    "Name": "Mangalore University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25326,
    "Category": "SC"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25402,
    "Category": "SC"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25629,
    "Category": "SC"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26045,
    "Category": "SC"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26378,
    "Category": "SC"
  },
  {
    "Name": "St. Agnes College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26968,
    "Category": "SC"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 27190,
    "Category": "SC"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27862,
    "Category": "SC"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Retail Management",
    "Score": 24765,
    "Category": "SC"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25071,
    "Category": "SC"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25356,
    "Category": "SC"
  },
  {
    "Name": "Chanakya University",
    "Course": "Master in Business Administration (MBA)",
    "Score": 25571,
    "Category": "SC"
  },
  {
    "Name": "Satellite Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25852,
    "Category": "SC"
  },
  {
    "Name": "Community Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26103,
    "Category": "SC"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26526,
    "Category": "SC"
  },
  {
    "Name": "The East Point College of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27062,
    "Category": "SC"
  },
  {
    "Name": "Sampurna Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27239,
    "Category": "SC"
  },
  {
    "Name": "St. Claret College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27924,
    "Category": "SC"
  },
  {
    "Name": "R.S. College of Management and Science",
    "Course": "MBA in Information Management",
    "Score": 27967,
    "Category": "SC"
  },
  {
    "Name": "Institute of Business Management and Research,Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28841,
    "Category": "SC"
  },
  {
    "Name": "Gulbarga University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29343,
    "Category": "SC"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29532,
    "Category": "SC"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29837,
    "Category": "SC"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29946,
    "Category": "SC"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30177,
    "Category": "SC"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30283,
    "Category": "SC"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30622,
    "Category": "SC"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31030,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28686,
    "Category": "SC"
  },
  {
    "Name": "MQI College of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 29287,
    "Category": "SC"
  },
  {
    "Name": "Sindhi Institute of Management - SIM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29430,
    "Category": "SC"
  },
  {
    "Name": "BGS College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29550,
    "Category": "SC"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29871,
    "Category": "SC"
  },
  {
    "Name": "Jyothi Institute of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30073,
    "Category": "SC"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Pharmacy Administration",
    "Score": 30261,
    "Category": "SC"
  },
  {
    "Name": "A.J. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30397,
    "Category": "SC"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31013,
    "Category": "SC"
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31032,
    "Category": "SC"
  },
  {
    "Name": "Karnataka State Rural Development and Panchayat Raj University, Gadag",
    "Course": "MBA in Rural Management",
    "Score": 31055,
    "Category": "SC"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31455,
    "Category": "SC"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31578,
    "Category": "SC"
  },
  {
    "Name": "Government First Grade College,Davanagere",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31906,
    "Category": "SC"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32235,
    "Category": "SC"
  },
  {
    "Name": "Sahyadri Commerce and Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32319,
    "Category": "SC"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32451,
    "Category": "SC"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32554,
    "Category": "SC"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32684,
    "Category": "SC"
  },
  {
    "Name": "K L E S`s K L E College of Engineering and Technology, Chikodi",
    "Course": "MBA",
    "Score": 32816,
    "Category": "SC"
  },
  {
    "Name": "Akash Institute of Engineering and Technology",
    "Course": "MBA",
    "Score": 31135,
    "Category": "SC"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31566,
    "Category": "SC"
  },
  {
    "Name": "Dr. H N National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31804,
    "Category": "SC"
  },
  {
    "Name": "MITE - Mangalore Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32045,
    "Category": "SC"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32313,
    "Category": "SC"
  },
  {
    "Name": "University B.D.T College of Engineering, Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32439,
    "Category": "SC"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32459,
    "Category": "SC"
  },
  {
    "Name": "Al-Ameen Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32681,
    "Category": "SC"
  },
  {
    "Name": "PES College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32803,
    "Category": "SC"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32837,
    "Category": "SC"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32845,
    "Category": "SC"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32855,
    "Category": "SC"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32901,
    "Category": "SC"
  },
  {
    "Name": "Vijayanagara Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33009,
    "Category": "SC"
  },
  {
    "Name": "Gopalan College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33117,
    "Category": "SC"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "MBA in Information Management",
    "Score": 33203,
    "Category": "SC"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33259,
    "Category": "SC"
  },
  {
    "Name": "Padmashree Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33484,
    "Category": "SC"
  },
  {
    "Name": "Maharaja Institute of Technology, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33520,
    "Category": "SC"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33764,
    "Category": "SC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Hospital Administration",
    "Score": 32852,
    "Category": "SC"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32875,
    "Category": "SC"
  },
  {
    "Name": "Nagarjuna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32915,
    "Category": "SC"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33035,
    "Category": "SC"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "MBA",
    "Score": 33130,
    "Category": "SC"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33253,
    "Category": "SC"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33298,
    "Category": "SC"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA in General Management",
    "Score": 33494,
    "Category": "SC"
  },
  {
    "Name": "Sri Siddhartha Academy of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33690,
    "Category": "SC"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33901,
    "Category": "SC"
  },
  {
    "Name": "R.R. Institute of Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33927,
    "Category": "SC"
  },
  {
    "Name": "H K E Society's SLN College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33979,
    "Category": "SC"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 34092,
    "Category": "SC"
  },
  {
    "Name": "Parivarthana Business School'",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34231,
    "Category": "SC"
  },
  {
    "Name": "Harsha Institutions",
    "Course": "Master of Business Administration (MBA)- Value Added",
    "Score": 34259,
    "Category": "SC"
  },
  {
    "Name": "Silicon City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34274,
    "Category": "SC"
  },
  {
    "Name": "R. L. Jalappa Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34377,
    "Category": "SC"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34399,
    "Category": "SC"
  },
  {
    "Name": "Biluru Gurubasava Mahaswamiji Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34419,
    "Category": "SC"
  },
  {
    "Name": "Srinivas University",
    "Course": "Discontinued (Mar 2024)-Master of Business Administration (MBA Hons.) (Dual Specialization)",
    "Score": 34477,
    "Category": "SC"
  },
  {
    "Name": "Imperial Institute of Advanced Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 33966,
    "Category": "SC"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34020,
    "Category": "SC"
  },
  {
    "Name": "Adithya Institue of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34146,
    "Category": "SC"
  },
  {
    "Name": "Patel Institute of Science and Management.",
    "Course": "Master of Business Administration",
    "Score": 34256,
    "Category": "SC"
  },
  {
    "Name": "International Academy of Management and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34267,
    "Category": "SC"
  },
  {
    "Name": "Dr. Kariappa School of Art and Design Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34330,
    "Category": "SC"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34389,
    "Category": "SC"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34412,
    "Category": "SC"
  },
  {
    "Name": "Mysuru Royal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34465,
    "Category": "SC"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34576,
    "Category": "SC"
  },
  {
    "Name": "GM University",
    "Course": "MBA in General Management",
    "Score": 34697,
    "Category": "SC"
  },
  {
    "Name": "St. Francis De Sales College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34748,
    "Category": "SC"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34872,
    "Category": "SC"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35116,
    "Category": "SC"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35186,
    "Category": "SC"
  },
  {
    "Name": "ATMA College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35264,
    "Category": "SC"
  },
  {
    "Name": "Kousali Institute of Management Studies, Karnataka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35582,
    "Category": "SC"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35714,
    "Category": "SC"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35774,
    "Category": "SC"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35809,
    "Category": "SC"
  },
  {
    "Name": "BGS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34726,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34789,
    "Category": "SC"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35016,
    "Category": "SC"
  },
  {
    "Name": "K.S. School of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35118,
    "Category": "SC"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35246,
    "Category": "SC"
  },
  {
    "Name": "Rani Channamma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35387,
    "Category": "SC"
  },
  {
    "Name": "Impact College of Engineering and Applied Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35602,
    "Category": "SC"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35724,
    "Category": "SC"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35779,
    "Category": "SC"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35841,
    "Category": "SC"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35843,
    "Category": "SC"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35850,
    "Category": "SC"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35881,
    "Category": "SC"
  },
  {
    "Name": "Aditya College Of Engineering and Technology Yelahanka, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35930,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35948,
    "Category": "SC"
  },
  {
    "Name": "Davangere University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36050,
    "Category": "SC"
  },
  {
    "Name": "Brindavan College (National Fgc)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36065,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Engineering and Research, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36117,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Human Resource Management",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35845,
    "Category": "SC"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35877,
    "Category": "SC"
  },
  {
    "Name": "Soundarya Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35912,
    "Category": "SC"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35945,
    "Category": "SC"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35950,
    "Category": "SC"
  },
  {
    "Name": "JIT - Jain Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36056,
    "Category": "SC"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36087,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Financial Management",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Business Analytics and Big Data Analytics",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Marketing",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Marketing Management",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship and Family Business Management",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Health Care Management and Hospital Administration",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36153,
    "Category": "SC"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36179,
    "Category": "SC"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36286,
    "Category": "SC"
  },
  {
    "Name": "Bangalore Integrated Management Academy, BIMA",
    "Course": "MBA",
    "Score": 36303,
    "Category": "SC"
  },
  {
    "Name": "Nagarjuna College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36393,
    "Category": "SC"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37368,
    "Category": "SC"
  },
  {
    "Name": "Karnataka University, Dharwad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38354,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Transformation",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship Startup Management",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Project Management and Information Technology",
    "Score": 36119,
    "Category": "SC"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36156,
    "Category": "SC"
  },
  {
    "Name": "RIT - Rajeev Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36243,
    "Category": "SC"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36288,
    "Category": "SC"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "MBA",
    "Score": 36393,
    "Category": "SC"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37072,
    "Category": "SC"
  },
  {
    "Name": "BLDEAs V. P. Dr. P. G. Halakatti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37752,
    "Category": "SC"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38404,
    "Category": "SC"
  },
  {
    "Name": "Sambhram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39488,
    "Category": "SC"
  },
  {
    "Name": "City Engineering College (CEC, Banglore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40754,
    "Category": "SC"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40805,
    "Category": "SC"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41737,
    "Category": "SC"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41954,
    "Category": "SC"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42238,
    "Category": "SC"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42564,
    "Category": "SC"
  },
  {
    "Name": "One School of Business",
    "Course": "Global MBA",
    "Score": 42653,
    "Category": "SC"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42924,
    "Category": "SC"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43046,
    "Category": "SC"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39501,
    "Category": "SC"
  },
  {
    "Name": "Dhanwantari Academy for Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40799,
    "Category": "SC"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41634,
    "Category": "SC"
  },
  {
    "Name": "Impact Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41853,
    "Category": "SC"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 42053,
    "Category": "SC"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42498,
    "Category": "SC"
  },
  {
    "Name": "One School of Business",
    "Course": "MBA Advance",
    "Score": 42653,
    "Category": "SC"
  },
  {
    "Name": "MEWA Vanguard Business School",
    "Course": "MBA Vanguard",
    "Score": 42762,
    "Category": "SC"
  },
  {
    "Name": "T. John Group of Institutions",
    "Course": "Master of Business Administration (BU)",
    "Score": 42994,
    "Category": "SC"
  },
  {
    "Name": "KU Post Graduate Center, Gadag",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43068,
    "Category": "SC"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43136,
    "Category": "SC"
  },
  {
    "Name": "Dr. Sri. Sri. Sri. Shivakumara Mahaswamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43514,
    "Category": "SC"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43612,
    "Category": "SC"
  },
  {
    "Name": "Manel Srinivas Nayak Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43401,
    "Category": "SC"
  },
  {
    "Name": "Chetan Business School Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43573,
    "Category": "SC"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 204,
    "Category": "ST"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2159,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3553,
    "Category": "ST"
  },
  {
    "Name": "KLE Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4613,
    "Category": "ST"
  },
  {
    "Name": "SJB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5080,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5565,
    "Category": "ST"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6459,
    "Category": "ST"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "MBA in Innovation, Entrepreneurship & Venture Development",
    "Score": 6588,
    "Category": "ST"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Discontinued (May 2024) Master of Business Administration (MBA)",
    "Score": 8146,
    "Category": "ST"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8959,
    "Category": "ST"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1829,
    "Category": "ST"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2254,
    "Category": "ST"
  },
  {
    "Name": "M.S. Ramaiah College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4171,
    "Category": "ST"
  },
  {
    "Name": "St. Aloysius College Magaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4987,
    "Category": "ST"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5190,
    "Category": "ST"
  },
  {
    "Name": "BMS Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5973,
    "Category": "ST"
  },
  {
    "Name": "SDM P.G. Centre for Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6528,
    "Category": "ST"
  },
  {
    "Name": "Bangalore Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7726,
    "Category": "ST"
  },
  {
    "Name": "K.S. School of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8153,
    "Category": "ST"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9069,
    "Category": "ST"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9101,
    "Category": "ST"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9700,
    "Category": "ST"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Financial Management",
    "Score": 10710,
    "Category": "ST"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11242,
    "Category": "ST"
  },
  {
    "Name": "Gulbarga University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11524,
    "Category": "ST"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11788,
    "Category": "ST"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12349,
    "Category": "ST"
  },
  {
    "Name": "Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12586,
    "Category": "ST"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13441,
    "Category": "ST"
  },
  {
    "Name": "Christ Academy Institute for Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13596,
    "Category": "ST"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9587,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 9988,
    "Category": "ST"
  },
  {
    "Name": "Maharani's Arts, Commerce and Management College for Wormen",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10826,
    "Category": "ST"
  },
  {
    "Name": "DSU - Dayananda Sagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11306,
    "Category": "ST"
  },
  {
    "Name": "Manel Srinivas Nayak Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11530,
    "Category": "ST"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12083,
    "Category": "ST"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12512,
    "Category": "ST"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13374,
    "Category": "ST"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13554,
    "Category": "ST"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13647,
    "Category": "ST"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13967,
    "Category": "ST"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14062,
    "Category": "ST"
  },
  {
    "Name": "CMR Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14348,
    "Category": "ST"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14486,
    "Category": "ST"
  },
  {
    "Name": "Soundarya Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14685,
    "Category": "ST"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 15359,
    "Category": "ST"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15818,
    "Category": "ST"
  },
  {
    "Name": "Impact College of Engineering and Applied Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16333,
    "Category": "ST"
  },
  {
    "Name": "KLEs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16493,
    "Category": "ST"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16766,
    "Category": "ST"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14010,
    "Category": "ST"
  },
  {
    "Name": "BLDEAs V. P. Dr. P. G. Halakatti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14133,
    "Category": "ST"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14456,
    "Category": "ST"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14492,
    "Category": "ST"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15017,
    "Category": "ST"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Information Management",
    "Score": 15460,
    "Category": "ST"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16147,
    "Category": "ST"
  },
  {
    "Name": "Sambhram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16373,
    "Category": "ST"
  },
  {
    "Name": "RNS First Grade College(BCA, BBA, B.Com)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16625,
    "Category": "ST"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16792,
    "Category": "ST"
  },
  {
    "Name": "KLE Dr. M.S.Sheshgiri College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17029,
    "Category": "ST"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in Fintech",
    "Score": 17189,
    "Category": "ST"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17272,
    "Category": "ST"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17418,
    "Category": "ST"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17526,
    "Category": "ST"
  },
  {
    "Name": "Yuvarajas College, University of Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17664,
    "Category": "ST"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Hospital Administration",
    "Score": 17850,
    "Category": "ST"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18259,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Marketing",
    "Score": 18370,
    "Category": "ST"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18409,
    "Category": "ST"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17125,
    "Category": "ST"
  },
  {
    "Name": "B.N Bahadur Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17234,
    "Category": "ST"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17394,
    "Category": "ST"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17458,
    "Category": "ST"
  },
  {
    "Name": "St.Philominas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17589,
    "Category": "ST"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17706,
    "Category": "ST"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18072,
    "Category": "ST"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Digital Marketing",
    "Score": 18355,
    "Category": "ST"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Retail Management",
    "Score": 18406,
    "Category": "ST"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18483,
    "Category": "ST"
  },
  {
    "Name": "ABBS",
    "Course": "MBA in Business Analytics",
    "Score": 18574,
    "Category": "ST"
  },
  {
    "Name": "S.E.A College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18866,
    "Category": "ST"
  },
  {
    "Name": "Al-Ameen Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18938,
    "Category": "ST"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18966,
    "Category": "ST"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19202,
    "Category": "ST"
  },
  {
    "Name": "T. John Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19490,
    "Category": "ST"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19697,
    "Category": "ST"
  },
  {
    "Name": "Department of  PG Studies, Visvesvaraya Technological University, Mysuru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20106,
    "Category": "ST"
  },
  {
    "Name": "Government Ramnarayan Chellaram College of Commerce and Management",
    "Course": "Master of Business Adminsitrations",
    "Score": 21007,
    "Category": "ST"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21087,
    "Category": "ST"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18729,
    "Category": "ST"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18907,
    "Category": "ST"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18954,
    "Category": "ST"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19052,
    "Category": "ST"
  },
  {
    "Name": "Hill Side Institute of Management",
    "Course": "MBA in Business Management",
    "Score": 19261,
    "Category": "ST"
  },
  {
    "Name": "CMR University (OMBR Campus)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19508,
    "Category": "ST"
  },
  {
    "Name": "T. John Group of Institutions",
    "Course": "Master of Business Administration (BU)",
    "Score": 19973,
    "Category": "ST"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20776,
    "Category": "ST"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21013,
    "Category": "ST"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21162,
    "Category": "ST"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA in General Management",
    "Score": 21293,
    "Category": "ST"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21477,
    "Category": "ST"
  },
  {
    "Name": "Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21786,
    "Category": "ST"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22260,
    "Category": "ST"
  },
  {
    "Name": "Maharanis Commerce and Management College for Women, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22669,
    "Category": "ST"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22890,
    "Category": "ST"
  },
  {
    "Name": "Mangalore University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22941,
    "Category": "ST"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23036,
    "Category": "ST"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23256,
    "Category": "ST"
  },
  {
    "Name": "Maharaja Institute of Technology, Thandavapura",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23423,
    "Category": "ST"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21341,
    "Category": "ST"
  },
  {
    "Name": "Bangalore Integrated Management Academy, BIMA",
    "Course": "MBA",
    "Score": 21715,
    "Category": "ST"
  },
  {
    "Name": "Akash Institute of Engineering and Technology",
    "Course": "MBA",
    "Score": 21867,
    "Category": "ST"
  },
  {
    "Name": "Impact Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22367,
    "Category": "ST"
  },
  {
    "Name": "Acharya Institute Of Management And Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22826,
    "Category": "ST"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22928,
    "Category": "ST"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22948,
    "Category": "ST"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23053,
    "Category": "ST"
  },
  {
    "Name": "Maharaja Institute of Technology, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23411,
    "Category": "ST"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 23518,
    "Category": "ST"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23537,
    "Category": "ST"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "MBA in General Management",
    "Score": 23848,
    "Category": "ST"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24212,
    "Category": "ST"
  },
  {
    "Name": "PES College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24513,
    "Category": "ST"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 24709,
    "Category": "ST"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25226,
    "Category": "ST"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26045,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Transformation",
    "Score": 26138,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in E-Business Management",
    "Score": 26138,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A in Human Resource",
    "Score": 26138,
    "Category": "ST"
  },
  {
    "Name": "Government First Grade College, Vijayanagar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23603,
    "Category": "ST"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23889,
    "Category": "ST"
  },
  {
    "Name": "Tumkur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24474,
    "Category": "ST"
  },
  {
    "Name": "Jain College of Engineering and Research, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24558,
    "Category": "ST"
  },
  {
    "Name": "Sri Devaraj Urs Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25200,
    "Category": "ST"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25550,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 26138,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Operations and Supply Chain Management",
    "Score": 26138,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A. in Marketing",
    "Score": 26138,
    "Category": "ST"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26536,
    "Category": "ST"
  },
  {
    "Name": "Sindhi Institute of Management - SIM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26867,
    "Category": "ST"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27185,
    "Category": "ST"
  },
  {
    "Name": "Satellite Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27246,
    "Category": "ST"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27416,
    "Category": "ST"
  },
  {
    "Name": "SBRR Mahajana First Grade College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27635,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Human Resource Management",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Marketing Management",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship and Family Business Management",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Health Care Management and Hospital Administration",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Kuvempu University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27140,
    "Category": "ST"
  },
  {
    "Name": "Sampurna Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27239,
    "Category": "ST"
  },
  {
    "Name": "MITE - Mangalore Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27325,
    "Category": "ST"
  },
  {
    "Name": "Maharani Cluster University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27445,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Financial Management",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Business Analytics and Big Data Analytics",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Marketing",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Transformation",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship Startup Management",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Project Management and Information Technology",
    "Score": 27675,
    "Category": "ST"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27840,
    "Category": "ST"
  },
  {
    "Name": "St. Claret College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27863,
    "Category": "ST"
  },
  {
    "Name": "R.S. College of Management and Science",
    "Course": "MBA in Information Management",
    "Score": 27967,
    "Category": "ST"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28237,
    "Category": "ST"
  },
  {
    "Name": "ATMA College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 28409,
    "Category": "ST"
  },
  {
    "Name": "Institute of Business Management and Research,Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28637,
    "Category": "ST"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28650,
    "Category": "ST"
  },
  {
    "Name": "Parivarthana Business School'",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29016,
    "Category": "ST"
  },
  {
    "Name": "University B.D.T College of Engineering, Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29375,
    "Category": "ST"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29462,
    "Category": "ST"
  },
  {
    "Name": "Sahyadri Commerce and Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27841,
    "Category": "ST"
  },
  {
    "Name": "School of Engineering and Technology, CMR University",
    "Course": "Master of Business Administration (MBA Dual)",
    "Score": 27928,
    "Category": "ST"
  },
  {
    "Name": "Amity University, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28093,
    "Category": "ST"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28322,
    "Category": "ST"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28572,
    "Category": "ST"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28644,
    "Category": "ST"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28769,
    "Category": "ST"
  },
  {
    "Name": "MQI College of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 29287,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29385,
    "Category": "ST"
  },
  {
    "Name": "Acharya Institute of Graduate Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29477,
    "Category": "ST"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29676,
    "Category": "ST"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30099,
    "Category": "ST"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Pharmacy Administration",
    "Score": 30261,
    "Category": "ST"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30750,
    "Category": "ST"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31030,
    "Category": "ST"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31100,
    "Category": "ST"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31208,
    "Category": "ST"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31411,
    "Category": "ST"
  },
  {
    "Name": "IFIM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31730,
    "Category": "ST"
  },
  {
    "Name": "Gopalan College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31997,
    "Category": "ST"
  },
  {
    "Name": "Nagarjuna College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29873,
    "Category": "ST"
  },
  {
    "Name": "SJB Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30158,
    "Category": "ST"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30650,
    "Category": "ST"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 31022,
    "Category": "ST"
  },
  {
    "Name": "Karnataka State Rural Development and Panchayat Raj University, Gadag",
    "Course": "MBA in Rural Management",
    "Score": 31055,
    "Category": "ST"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31125,
    "Category": "ST"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31286,
    "Category": "ST"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31508,
    "Category": "ST"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31934,
    "Category": "ST"
  },
  {
    "Name": "BGS College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32311,
    "Category": "ST"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32395,
    "Category": "ST"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32451,
    "Category": "ST"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32470,
    "Category": "ST"
  },
  {
    "Name": "St. Francis College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 32602,
    "Category": "ST"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Hospital Administration",
    "Score": 32852,
    "Category": "ST"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32931,
    "Category": "ST"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33186,
    "Category": "ST"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "MBA in Information Management",
    "Score": 33203,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 33275,
    "Category": "ST"
  },
  {
    "Name": "R.R. Institute of Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33395,
    "Category": "ST"
  },
  {
    "Name": "Mysuru Royal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32412,
    "Category": "ST"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32459,
    "Category": "ST"
  },
  {
    "Name": "Community Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32533,
    "Category": "ST"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32845,
    "Category": "ST"
  },
  {
    "Name": "Vijayanagara Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32891,
    "Category": "ST"
  },
  {
    "Name": "R. L. Jalappa Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32999,
    "Category": "ST"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "MBA",
    "Score": 33201,
    "Category": "ST"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33248,
    "Category": "ST"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33374,
    "Category": "ST"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33477,
    "Category": "ST"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33496,
    "Category": "ST"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33766,
    "Category": "ST"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33787,
    "Category": "ST"
  },
  {
    "Name": "Kousali Institute of Management Studies, Karnataka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33900,
    "Category": "ST"
  },
  {
    "Name": "Imperial Institute of Advanced Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 33966,
    "Category": "ST"
  },
  {
    "Name": "Davangere University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33989,
    "Category": "ST"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34039,
    "Category": "ST"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34083,
    "Category": "ST"
  },
  {
    "Name": "One School of Business",
    "Course": "MBA Advance",
    "Score": 34111,
    "Category": "ST"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "MBA",
    "Score": 34163,
    "Category": "ST"
  },
  {
    "Name": "Dr. Sri. Sri. Sri. Shivakumara Mahaswamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33665,
    "Category": "ST"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33786,
    "Category": "ST"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33809,
    "Category": "ST"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33901,
    "Category": "ST"
  },
  {
    "Name": "H K E Society's SLN College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33979,
    "Category": "ST"
  },
  {
    "Name": "BGS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34013,
    "Category": "ST"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34080,
    "Category": "ST"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 34092,
    "Category": "ST"
  },
  {
    "Name": "One School of Business",
    "Course": "Global MBA",
    "Score": 34111,
    "Category": "ST"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34217,
    "Category": "ST"
  },
  {
    "Name": "K L E S`s K L E College of Engineering and Technology, Chikodi",
    "Course": "MBA",
    "Score": 34252,
    "Category": "ST"
  },
  {
    "Name": "Harsha Institutions",
    "Course": "Master of Business Administration (MBA)- Value Added",
    "Score": 34259,
    "Category": "ST"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34268,
    "Category": "ST"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34383,
    "Category": "ST"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34399,
    "Category": "ST"
  },
  {
    "Name": "Biluru Gurubasava Mahaswamiji Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34419,
    "Category": "ST"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34557,
    "Category": "ST"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35085,
    "Category": "ST"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Banking and Finance Management",
    "Score": 35361,
    "Category": "ST"
  },
  {
    "Name": "Rani Channamma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35551,
    "Category": "ST"
  },
  {
    "Name": "Patel Institute of Science and Management.",
    "Course": "Master of Business Administration",
    "Score": 34256,
    "Category": "ST"
  },
  {
    "Name": "International Academy of Management and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34267,
    "Category": "ST"
  },
  {
    "Name": "Dr. Kariappa School of Art and Design Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34330,
    "Category": "ST"
  },
  {
    "Name": "St. Francis De Sales College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34384,
    "Category": "ST"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34414,
    "Category": "ST"
  },
  {
    "Name": "Srinivas University",
    "Course": "Discontinued (Mar 2024)-Master of Business Administration (MBA Hons.) (Dual Specialization)",
    "Score": 34477,
    "Category": "ST"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34569,
    "Category": "ST"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35152,
    "Category": "ST"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35381,
    "Category": "ST"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35559,
    "Category": "ST"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35658,
    "Category": "ST"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35723,
    "Category": "ST"
  },
  {
    "Name": "The East Point College of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35824,
    "Category": "ST"
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35888,
    "Category": "ST"
  },
  {
    "Name": "Sri Siddhartha Academy of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36218,
    "Category": "ST"
  },
  {
    "Name": "KU Post Graduate Center, Gadag",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37518,
    "Category": "ST"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "iDream Master of Business Administration (MBA)",
    "Score": 37824,
    "Category": "ST"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38240,
    "Category": "ST"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38699,
    "Category": "ST"
  },
  {
    "Name": "RIT - Rajeev Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39314,
    "Category": "ST"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Seshadripuram Institute of Management Studies",
    "Score": 35692,
    "Category": "ST"
  },
  {
    "Name": "JIT - Jain Institute of Technology",
    "Course": "JIT - Jain Institute of Technology",
    "Score": 35743,
    "Category": "ST"
  },
  {
    "Name": "GM University",
    "Course": "GM University",
    "Score": 35855,
    "Category": "ST"
  },
  {
    "Name": "Government First Grade College,Davanagere",
    "Course": "Government First Grade College,Davanagere",
    "Score": 36164,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "East West Group Of Institutions, Magadi Road",
    "Score": 36232,
    "Category": "ST"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Oxbridge Business School",
    "Score": 37572,
    "Category": "ST"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Indian Academy School of Management Studies",
    "Score": 38120,
    "Category": "ST"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Krupanidhi School of Management powered by Sunstone Select",
    "Score": 38477,
    "Category": "ST"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Shushruti Institute of Management Studies",
    "Score": 38710,
    "Category": "ST"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "S J C Institute of Technology",
    "Score": 39347,
    "Category": "ST"
  },
  {
    "Name": "MEWA Vanguard Business School",
    "Course": "MEWA Vanguard Business School",
    "Score": 39435,
    "Category": "ST"
  },
  {
    "Name": "City Engineering College (CEC, Banglore)",
    "Course": "City Engineering College (CEC, Banglore)",
    "Score": 40633,
    "Category": "ST"
  },
  {
    "Name": "GM University",
    "Course": "GM University",
    "Score": 41273,
    "Category": "ST"
  },
  {
    "Name": "Padmashree Institute of Management and Sciences",
    "Course": "Padmashree Institute of Management and Sciences",
    "Score": 41536,
    "Category": "ST"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Surana College Post Graduate Departments",
    "Score": 41850,
    "Category": "ST"
  },
  {
    "Name": "Dhanwantari Academy for Management Studies",
    "Course": "Dhanwantari Academy for Management Studies",
    "Score": 43272,
    "Category": "ST"
  },
  {
    "Name": "St. Agnes College",
    "Course": "St. Agnes College",
    "Score": 43298,
    "Category": "ST"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Institute of Excellence in Management Science",
    "Score": 43605,
    "Category": "ST"
  },
  {
    "Name": "Nagarjuna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40535,
    "Category": "ST"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40860,
    "Category": "ST"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41523,
    "Category": "ST"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41559,
    "Category": "ST"
  },
  {
    "Name": "Sambhram Academy of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43284,
    "Category": "ST"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43382,
    "Category": "ST"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 214,
    "Category": "PWD"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 344,
    "Category": "PWD"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 472,
    "Category": "PWD"
  },
  {
    "Name": "Bangalore Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 651,
    "Category": "PWD"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1111,
    "Category": "PWD"
  },
  {
    "Name": "BMS Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1297,
    "Category": "PWD"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Financial Management",
    "Score": 1478,
    "Category": "PWD"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1712,
    "Category": "PWD"
  },
  {
    "Name": "KLE Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2087,
    "Category": "PWD"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2120,
    "Category": "PWD"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 255,
    "Category": "PWD"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 370,
    "Category": "PWD"
  },
  {
    "Name": "M.S. Ramaiah College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 608,
    "Category": "PWD"
  },
  {
    "Name": "Dayananda Sagar College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 907,
    "Category": "PWD"
  },
  {
    "Name": "DSU - Dayananda Sagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1288,
    "Category": "PWD"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1299,
    "Category": "PWD"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1521,
    "Category": "PWD"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2006,
    "Category": "PWD"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2105,
    "Category": "PWD"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2162,
    "Category": "PWD"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2168,
    "Category": "PWD"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2309,
    "Category": "PWD"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2428,
    "Category": "PWD"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2477,
    "Category": "PWD"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2579,
    "Category": "PWD"
  },
  {
    "Name": "Acharya Institute Of Management And Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2714,
    "Category": "PWD"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3012,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3693,
    "Category": "PWD"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3998,
    "Category": "PWD"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4406,
    "Category": "PWD"
  },
  {
    "Name": "IFIM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2264,
    "Category": "PWD"
  },
  {
    "Name": "Christ Academy Institute for Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2338,
    "Category": "PWD"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2442,
    "Category": "PWD"
  },
  {
    "Name": "ABBS",
    "Course": "MBA in Business Analytics",
    "Score": 2523,
    "Category": "PWD"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2684,
    "Category": "PWD"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2877,
    "Category": "PWD"
  },
  {
    "Name": "CMR Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3236,
    "Category": "PWD"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3998,
    "Category": "PWD"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4285,
    "Category": "PWD"
  },
  {
    "Name": "B.N Bahadur Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4435,
    "Category": "PWD"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4621,
    "Category": "PWD"
  },
  {
    "Name": "KLE Dr. M.S.Sheshgiri College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4718,
    "Category": "PWD"
  },
  {
    "Name": "SJB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5080,
    "Category": "PWD"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5190,
    "Category": "PWD"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5268,
    "Category": "PWD"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5447,
    "Category": "PWD"
  },
  {
    "Name": "Mangalore University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5465,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 5605,
    "Category": "PWD"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5646,
    "Category": "PWD"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5967,
    "Category": "PWD"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4672,
    "Category": "PWD"
  },
  {
    "Name": "St. Aloysius College Magaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4987,
    "Category": "PWD"
  },
  {
    "Name": "Acharya Institute of Graduate Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5151,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 5246,
    "Category": "PWD"
  },
  {
    "Name": "Government Ramnarayan Chellaram College of Commerce and Management",
    "Course": "Master of Business Adminsitrations",
    "Score": 5289,
    "Category": "PWD"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Digital Marketing",
    "Score": 5450,
    "Category": "PWD"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5487,
    "Category": "PWD"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5640,
    "Category": "PWD"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5953,
    "Category": "PWD"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6038,
    "Category": "PWD"
  },
  {
    "Name": "Community Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6333,
    "Category": "PWD"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6416,
    "Category": "PWD"
  },
  {
    "Name": "SDM P.G. Centre for Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6528,
    "Category": "PWD"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6713,
    "Category": "PWD"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6798,
    "Category": "PWD"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7293,
    "Category": "PWD"
  },
  {
    "Name": "PES College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7818,
    "Category": "PWD"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7949,
    "Category": "PWD"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8131,
    "Category": "PWD"
  },
  {
    "Name": "K.S. School of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8153,
    "Category": "PWD"
  },
  {
    "Name": "SJB Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6354,
    "Category": "PWD"
  },
  {
    "Name": "MITE - Mangalore Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6516,
    "Category": "PWD"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "MBA in Innovation, Entrepreneurship & Venture Development",
    "Score": 6588,
    "Category": "PWD"
  },
  {
    "Name": "School of Engineering and Technology, CMR University",
    "Course": "Master of Business Administration (MBA Dual)",
    "Score": 6796,
    "Category": "PWD"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6999,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 7594,
    "Category": "PWD"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7943,
    "Category": "PWD"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8032,
    "Category": "PWD"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Discontinued (May 2024) Master of Business Administration (MBA)",
    "Score": 8146,
    "Category": "PWD"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8215,
    "Category": "PWD"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8445,
    "Category": "PWD"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8637,
    "Category": "PWD"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8872,
    "Category": "PWD"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8905,
    "Category": "PWD"
  },
  {
    "Name": "Yuvarajas College, University of Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9209,
    "Category": "PWD"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9587,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in E-Business Management",
    "Score": 9712,
    "Category": "PWD"
  },
  {
    "Name": "St. Claret College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9805,
    "Category": "PWD"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10025,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Business Management and Research,Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10277,
    "Category": "PWD"
  },
  {
    "Name": "Department of  PG Studies, Visvesvaraya Technological University, Mysuru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8577,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8761,
    "Category": "PWD"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8886,
    "Category": "PWD"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9061,
    "Category": "PWD"
  },
  {
    "Name": "Vijayanagara Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9302,
    "Category": "PWD"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9711,
    "Category": "PWD"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9738,
    "Category": "PWD"
  },
  {
    "Name": "Sindhi Institute of Management - SIM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9855,
    "Category": "PWD"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10244,
    "Category": "PWD"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10401,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Marketing",
    "Score": 10517,
    "Category": "PWD"
  },
  {
    "Name": "SBRR Mahajana First Grade College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10686,
    "Category": "PWD"
  },
  {
    "Name": "Maharaja Institute of Technology, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10732,
    "Category": "PWD"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10843,
    "Category": "PWD"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10910,
    "Category": "PWD"
  },
  {
    "Name": "Kousali Institute of Management Studies, Karnataka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11032,
    "Category": "PWD"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11190,
    "Category": "PWD"
  },
  {
    "Name": "Manel Srinivas Nayak Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11530,
    "Category": "PWD"
  },
  {
    "Name": "R.R. Institute of Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11774,
    "Category": "PWD"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12013,
    "Category": "PWD"
  },
  {
    "Name": "St. Agnes College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10599,
    "Category": "PWD"
  },
  {
    "Name": "Government First Grade College, Vijayanagar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10720,
    "Category": "PWD"
  },
  {
    "Name": "Maharani's Arts, Commerce and Management College for Wormen",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10826,
    "Category": "PWD"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10902,
    "Category": "PWD"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10988,
    "Category": "PWD"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Retail Management",
    "Score": 11107,
    "Category": "PWD"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11242,
    "Category": "PWD"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11772,
    "Category": "PWD"
  },
  {
    "Name": "Maharaja Institute of Technology, Thandavapura",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11879,
    "Category": "PWD"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12041,
    "Category": "PWD"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12056,
    "Category": "PWD"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12164,
    "Category": "PWD"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12195,
    "Category": "PWD"
  },
  {
    "Name": "Nagarjuna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12476,
    "Category": "PWD"
  },
  {
    "Name": "Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12586,
    "Category": "PWD"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12716,
    "Category": "PWD"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12794,
    "Category": "PWD"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13056,
    "Category": "PWD"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13061,
    "Category": "PWD"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13374,
    "Category": "PWD"
  },
  {
    "Name": "Maharani Cluster University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12103,
    "Category": "PWD"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12167,
    "Category": "PWD"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12397,
    "Category": "PWD"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12512,
    "Category": "PWD"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12642,
    "Category": "PWD"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12732,
    "Category": "PWD"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13009,
    "Category": "PWD"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Operations and Supply Chain Management",
    "Score": 13058,
    "Category": "PWD"
  },
  {
    "Name": "RIT - Rajeev Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13083,
    "Category": "PWD"
  },
  {
    "Name": "St. Francis College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 13690,
    "Category": "PWD"
  },
  {
    "Name": "The East Point College of Higher Education",
    "Course": "Master of Business Studies (MBA)",
    "Score": 13787,
    "Category": "PWD"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "Master of Business Studies (MBA)",
    "Score": 13864,
    "Category": "PWD"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "Master of Business Studies (MBA)",
    "Score": 14062,
    "Category": "PWD"
  },
  {
    "Name": "BLDEAs V. P. Dr. P. G. Halakatti College of Engineering and Technology",
    "Course": "Master of Business Studies (MBA)",
    "Score": 14133,
    "Category": "PWD"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Studies (MBA)",
    "Score": 14492,
    "Category": "PWD"
  },
  {
    "Name": "Kuvempu University",
    "Course": "Master of Business Studies (MBA)",
    "Score": 14597,
    "Category": "PWD"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 14770,
    "Category": "PWD"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Studies (MBA)",
    "Score": 15818,
    "Category": "PWD"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 15975,
    "Category": "PWD"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Studies (MBA)",
    "Score": 16058,
    "Category": "PWD"
  },
  {
    "Name": "Gulbarga University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13821,
    "Category": "PWD"
  },
  {
    "Name": "Tumkur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13908,
    "Category": "PWD"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14111,
    "Category": "PWD"
  },
  {
    "Name": "Nagarjuna College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14434,
    "Category": "PWD"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14591,
    "Category": "PWD"
  },
  {
    "Name": "Soundarya Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14685,
    "Category": "PWD"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Information Management",
    "Score": 15460,
    "Category": "PWD"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15836,
    "Category": "PWD"
  },
  {
    "Name": "Satellite Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16000,
    "Category": "PWD"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16147,
    "Category": "PWD"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16278,
    "Category": "PWD"
  },
  {
    "Name": "Sambhram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16373,
    "Category": "PWD"
  },
  {
    "Name": "KLEs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16493,
    "Category": "PWD"
  },
  {
    "Name": "University B.D.T College of Engineering, Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17065,
    "Category": "PWD"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17125,
    "Category": "PWD"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17272,
    "Category": "PWD"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17435,
    "Category": "PWD"
  },
  {
    "Name": "JIT - Jain Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17696,
    "Category": "PWD"
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17714,
    "Category": "PWD"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18483,
    "Category": "PWD"
  },
  {
    "Name": "Impact College of Engineering and Applied Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16333,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16404,
    "Category": "PWD"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16705,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17098,
    "Category": "PWD"
  },
  {
    "Name": "Sahyadri Commerce and Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17208,
    "Category": "PWD"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 17366,
    "Category": "PWD"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17526,
    "Category": "PWD"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17706,
    "Category": "PWD"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Hospital Administration",
    "Score": 17850,
    "Category": "PWD"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18715,
    "Category": "PWD"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18722,
    "Category": "PWD"
  },
  {
    "Name": "Al-Ameen Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18938,
    "Category": "PWD"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19052,
    "Category": "PWD"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19184,
    "Category": "PWD"
  },
  {
    "Name": "T. John Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19490,
    "Category": "PWD"
  },
  {
    "Name": "Davangere University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19779,
    "Category": "PWD"
  },
  {
    "Name": "Government First Grade College,Davanagere",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20291,
    "Category": "PWD"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21013,
    "Category": "PWD"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21162,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Sri. Sri. Sri. Shivakumara Mahaswamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21779,
    "Category": "PWD"
  },
  {
    "Name": "S.E.A College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18866,
    "Category": "PWD"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18954,
    "Category": "PWD"
  },
  {
    "Name": "St. Francis De Sales College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19061,
    "Category": "PWD"
  },
  {
    "Name": "Hill Side Institute of Management",
    "Course": "MBA in Business Management",
    "Score": 19261,
    "Category": "PWD"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19697,
    "Category": "PWD"
  },
  {
    "Name": "T. John Group of Institutions",
    "Course": "Master of Business Administration (BU)",
    "Score": 19973,
    "Category": "PWD"
  },
  {
    "Name": "Rani Channamma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20460,
    "Category": "PWD"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21075,
    "Category": "PWD"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21341,
    "Category": "PWD"
  },
  {
    "Name": "Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21786,
    "Category": "PWD"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22260,
    "Category": "PWD"
  },
  {
    "Name": "Impact Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22367,
    "Category": "PWD"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22907,
    "Category": "PWD"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 23518,
    "Category": "PWD"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA in General Management",
    "Score": 23736,
    "Category": "PWD"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24484,
    "Category": "PWD"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26045,
    "Category": "PWD"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27416,
    "Category": "PWD"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28191,
    "Category": "PWD"
  },
  {
    "Name": "MQI College of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 29287,
    "Category": "PWD"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22278,
    "Category": "PWD"
  },
  {
    "Name": "One School of Business",
    "Course": "MBA Advance",
    "Score": 22760,
    "Category": "PWD"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23036,
    "Category": "PWD"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23537,
    "Category": "PWD"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "MBA in General Management",
    "Score": 23848,
    "Category": "PWD"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 24709,
    "Category": "PWD"
  },
  {
    "Name": "Sampurna Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27239,
    "Category": "PWD"
  },
  {
    "Name": "R.S. College of Management and Science",
    "Course": "MBA in Information Management",
    "Score": 27967,
    "Category": "PWD"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28237,
    "Category": "PWD"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Pharmacy Administration",
    "Score": 30261,
    "Category": "PWD"
  },
  {
    "Name": "Dhanwantari Academy for Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30828,
    "Category": "PWD"
  },
  {
    "Name": "Karnataka State Rural Development and Panchayat Raj University, Gadag",
    "Course": "MBA in Rural Management",
    "Score": 31055,
    "Category": "PWD"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32459,
    "Category": "PWD"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Hospital Administration",
    "Score": 32852,
    "Category": "PWD"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33901,
    "Category": "PWD"
  },
  {
    "Name": "H K E Society's SLN College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33979,
    "Category": "PWD"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 34092,
    "Category": "PWD"
  },
  {
    "Name": "Harsha Institutions",
    "Course": "Master of Business Administration (MBA)- Value Added",
    "Score": 34259,
    "Category": "PWD"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34268,
    "Category": "PWD"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34399,
    "Category": "PWD"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31030,
    "Category": "PWD"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32451,
    "Category": "PWD"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32845,
    "Category": "PWD"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "MBA in Information Management",
    "Score": 33203,
    "Category": "PWD"
  },
  {
    "Name": "Imperial Institute of Advanced Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 33966,
    "Category": "PWD"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34006,
    "Category": "PWD"
  },
  {
    "Name": "Patel Institute of Science and Management.",
    "Course": "Master of Business Administration",
    "Score": 34256,
    "Category": "PWD"
  },
  {
    "Name": "International Academy of Management and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34267,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Kariappa School of Art and Design Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34330,
    "Category": "PWD"
  },
  {
    "Name": "Biluru Gurubasava Mahaswamiji Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34419,
    "Category": "PWD"
  },
  {
    "Name": "Srinivas University",
    "Course": "Discontinued (Mar 2024)-Master of Business Administration (MBA Hons.) (Dual Specialization)",
    "Score": 34477,
    "Category": "PWD"
  },
  {
    "Name": "RV Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76,
    "Category": "EWS"
  },
  {
    "Name": "Ramaiah Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 303,
    "Category": "EWS"
  },
  {
    "Name": "Dayananda Sagar College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 434,
    "Category": "EWS"
  },
  {
    "Name": "Bangalore Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 858,
    "Category": "EWS"
  },
  {
    "Name": "Dayananda Sagar College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1170,
    "Category": "EWS"
  },
  {
    "Name": "BMS Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1244,
    "Category": "EWS"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1542,
    "Category": "EWS"
  },
  {
    "Name": "Reva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1853,
    "Category": "EWS"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in Fintech",
    "Score": 2128,
    "Category": "EWS"
  },
  {
    "Name": "Mount Carmel College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2265,
    "Category": "EWS"
  },
  {
    "Name": "PES University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 239,
    "Category": "EWS"
  },
  {
    "Name": "BMS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 360,
    "Category": "EWS"
  },
  {
    "Name": "M.S. Ramaiah College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 594,
    "Category": "EWS"
  },
  {
    "Name": "M.S. Ramaiah University of Applied Sciences (MSRUAS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1052,
    "Category": "EWS"
  },
  {
    "Name": "KLE Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1189,
    "Category": "EWS"
  },
  {
    "Name": "DSU - Dayananda Sagar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1503,
    "Category": "EWS"
  },
  {
    "Name": "Dayananda Sagar Academy Of Technology And Management (DSATM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1649,
    "Category": "EWS"
  },
  {
    "Name": "R.N.S. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2117,
    "Category": "EWS"
  },
  {
    "Name": "NITTE Meenakshi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2206,
    "Category": "EWS"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Financial Management",
    "Score": 2393,
    "Category": "EWS"
  },
  {
    "Name": "RNS First Grade College(BCA, BBA, B.Com)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2501,
    "Category": "EWS"
  },
  {
    "Name": "NHCE Bangalore - New Horizon College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2616,
    "Category": "EWS"
  },
  {
    "Name": "JSS Academy of Technical Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2685,
    "Category": "EWS"
  },
  {
    "Name": "Siddaganga Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2953,
    "Category": "EWS"
  },
  {
    "Name": "ABBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2980,
    "Category": "EWS"
  },
  {
    "Name": "KLS Gogte Institute of Technology, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3114,
    "Category": "EWS"
  },
  {
    "Name": "ABBS",
    "Course": "MBA in Business Analytics",
    "Score": 3258,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Business Analytics",
    "Score": 3625,
    "Category": "EWS"
  },
  {
    "Name": "Dr. Ambedkar Institute Of Technology (AIT), Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3693,
    "Category": "EWS"
  },
  {
    "Name": "CMR University (OMBR Campus)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4062,
    "Category": "EWS"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2528,
    "Category": "EWS"
  },
  {
    "Name": "B.N.M Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2683,
    "Category": "EWS"
  },
  {
    "Name": "CMR Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2811,
    "Category": "EWS"
  },
  {
    "Name": "KLE Dr. M.S.Sheshgiri College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2959,
    "Category": "EWS"
  },
  {
    "Name": "Kristu Jayanti College",
    "Course": "MBA in International Business",
    "Score": 3055,
    "Category": "EWS"
  },
  {
    "Name": "Sahyadri College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3176,
    "Category": "EWS"
  },
  {
    "Name": "Christ Academy Institute for Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3437,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3683,
    "Category": "EWS"
  },
  {
    "Name": "K.L.E. Society's Institute of Management Studies and Research (KLESIMSR )",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3740,
    "Category": "EWS"
  },
  {
    "Name": "Acharya Institute Of Management And Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4072,
    "Category": "EWS"
  },
  {
    "Name": "Vidyavardhaka College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4408,
    "Category": "EWS"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4473,
    "Category": "EWS"
  },
  {
    "Name": "Amity University, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4571,
    "Category": "EWS"
  },
  {
    "Name": "School of Engineering and Technology, CMR University",
    "Course": "Master of Business Administration (MBA Dual)",
    "Score": 4918,
    "Category": "EWS"
  },
  {
    "Name": "SJB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5080,
    "Category": "EWS"
  },
  {
    "Name": "CMR Center for Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5190,
    "Category": "EWS"
  },
  {
    "Name": "Presidency College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5369,
    "Category": "EWS"
  },
  {
    "Name": "Global Academy of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5606,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "Master of Business Administration (MBA Dual Major Specialization)",
    "Score": 5862,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Operations and Supply Chain Management",
    "Score": 5862,
    "Category": "EWS"
  },
  {
    "Name": "Acharya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4445,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, CMR University",
    "Course": "MBA in Business Analytics and Business Intelligence",
    "Score": 4512,
    "Category": "EWS"
  },
  {
    "Name": "IFIM School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4643,
    "Category": "EWS"
  },
  {
    "Name": "St. Aloysius College Magaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4987,
    "Category": "EWS"
  },
  {
    "Name": "GITAM School of Business, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5100,
    "Category": "EWS"
  },
  {
    "Name": "SJB Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5282,
    "Category": "EWS"
  },
  {
    "Name": "Cambridge Institute of Technology, K R Puram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5536,
    "Category": "EWS"
  },
  {
    "Name": "Acharya Institute of Graduate Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5841,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Transformation",
    "Score": 5862,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in E-Business Management",
    "Score": 5862,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A. in Marketing",
    "Score": 5862,
    "Category": "EWS"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Digital Marketing",
    "Score": 5903,
    "Category": "EWS"
  },
  {
    "Name": "Government Ramnarayan Chellaram College of Commerce and Management",
    "Course": "Master of Business Adminsitrations",
    "Score": 5941,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Digital Marketing",
    "Score": 6457,
    "Category": "EWS"
  },
  {
    "Name": "ISBR Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6995,
    "Category": "EWS"
  },
  {
    "Name": "M.V.J. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7075,
    "Category": "EWS"
  },
  {
    "Name": "KLS Institute of Management Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7258,
    "Category": "EWS"
  },
  {
    "Name": "GSSS Institute of Engineering and Technology for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7307,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Banking and Finance Management",
    "Score": 7704,
    "Category": "EWS"
  },
  {
    "Name": "VCET - Vivekananda College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7910,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "M.B.A in Human Resource",
    "Score": 5862,
    "Category": "EWS"
  },
  {
    "Name": "B.N Bahadur Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5919,
    "Category": "EWS"
  },
  {
    "Name": "Jyoti Nivas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6208,
    "Category": "EWS"
  },
  {
    "Name": "SDM P.G. Centre for Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6528,
    "Category": "EWS"
  },
  {
    "Name": "St. Aloysius Institute of Management and Information Technology (AIMIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7023,
    "Category": "EWS"
  },
  {
    "Name": "AIMS IBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7195,
    "Category": "EWS"
  },
  {
    "Name": "Global Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7277,
    "Category": "EWS"
  },
  {
    "Name": "BGS College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7678,
    "Category": "EWS"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7894,
    "Category": "EWS"
  },
  {
    "Name": "Community Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7992,
    "Category": "EWS"
  },
  {
    "Name": "BIET - Bapuji Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8050,
    "Category": "EWS"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Discontinued (May 2024) Master of Business Administration (MBA)",
    "Score": 8146,
    "Category": "EWS"
  },
  {
    "Name": "Sir M. Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8683,
    "Category": "EWS"
  },
  {
    "Name": "Shri Dharmasthala Manjunatheshwara College Of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8891,
    "Category": "EWS"
  },
  {
    "Name": "Seshadripuram Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9246,
    "Category": "EWS"
  },
  {
    "Name": "Alva's Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9328,
    "Category": "EWS"
  },
  {
    "Name": "JNNCE - Jawaharlal Nehru National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9396,
    "Category": "EWS"
  },
  {
    "Name": "St. Claret College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9504,
    "Category": "EWS"
  },
  {
    "Name": "Vidya Vikas Institute of Engineering and Technology - VVIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9626,
    "Category": "EWS"
  },
  {
    "Name": "AMC Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10186,
    "Category": "EWS"
  },
  {
    "Name": "Bangalore Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8144,
    "Category": "EWS"
  },
  {
    "Name": "Chanakya University",
    "Course": "Master in Business Administration (MBA)",
    "Score": 8506,
    "Category": "EWS"
  },
  {
    "Name": "Mangalore University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8858,
    "Category": "EWS"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9148,
    "Category": "EWS"
  },
  {
    "Name": "School of Management, Presidency University, Bangalore",
    "Course": "MBA in Marketing and Finance",
    "Score": 9255,
    "Category": "EWS"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9390,
    "Category": "EWS"
  },
  {
    "Name": "Don Bosco Institute Of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9406,
    "Category": "EWS"
  },
  {
    "Name": "Rao Bahadur Y.Mahabaleshwarappa Engineering College (RYMEC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9587,
    "Category": "EWS"
  },
  {
    "Name": "PES Institute of Technology and Management (PESITM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9978,
    "Category": "EWS"
  },
  {
    "Name": "MITE - Mangalore Institute of Technology and Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10197,
    "Category": "EWS"
  },
  {
    "Name": "Don Bosco Institute Of Management Studies And Computer Applications Mysore Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10208,
    "Category": "EWS"
  },
  {
    "Name": "Department of  PG Studies, Visvesvaraya Technological University, Mysuru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10367,
    "Category": "EWS"
  },
  {
    "Name": "Maharani's Arts, Commerce and Management College for Wormen",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10826,
    "Category": "EWS"
  },
  {
    "Name": "Maharaja Institute of Technology, Thandavapura",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10983,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Engineering - JCE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11301,
    "Category": "EWS"
  },
  {
    "Name": "JSS Science and Technology University",
    "Course": "MBA in Retail Management",
    "Score": 11356,
    "Category": "EWS"
  },
  {
    "Name": "Alliance School of Business, Alliance University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11560,
    "Category": "EWS"
  },
  {
    "Name": "The Oxford College of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11800,
    "Category": "EWS"
  },
  {
    "Name": "SBRR Mahajana First Grade College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11924,
    "Category": "EWS"
  },
  {
    "Name": "Maharanis Commerce and Management College for Women, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12031,
    "Category": "EWS"
  },
  {
    "Name": "Gulbarga University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10252,
    "Category": "EWS"
  },
  {
    "Name": "Atria Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10488,
    "Category": "EWS"
  },
  {
    "Name": "Bapuji MBA College - BIET MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10881,
    "Category": "EWS"
  },
  {
    "Name": "Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11040,
    "Category": "EWS"
  },
  {
    "Name": "St.Philominas College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11305,
    "Category": "EWS"
  },
  {
    "Name": "The Oxford College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11418,
    "Category": "EWS"
  },
  {
    "Name": "Adarsh Institute of Management and Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11674,
    "Category": "EWS"
  },
  {
    "Name": "Government First Grade College, Vijayanagar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11912,
    "Category": "EWS"
  },
  {
    "Name": "Yuvarajas College, University of Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11980,
    "Category": "EWS"
  },
  {
    "Name": "Maharani Cluster University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12070,
    "Category": "EWS"
  },
  {
    "Name": "Kousali Institute of Management Studies, Karnataka University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12169,
    "Category": "EWS"
  },
  {
    "Name": "Adichunchanagiri Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12503,
    "Category": "EWS"
  },
  {
    "Name": "Proudhadevaraya Institute of Technology (PDIT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12627,
    "Category": "EWS"
  },
  {
    "Name": "Tumkur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13206,
    "Category": "EWS"
  },
  {
    "Name": "Brindavan Group of Institutions Yelahanaka Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13374,
    "Category": "EWS"
  },
  {
    "Name": "RajaRajeswari College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14018,
    "Category": "EWS"
  },
  {
    "Name": "A.J. Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14156,
    "Category": "EWS"
  },
  {
    "Name": "BGS Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14289,
    "Category": "EWS"
  },
  {
    "Name": "The East Point College of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14360,
    "Category": "EWS"
  },
  {
    "Name": "MP Birla Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14625,
    "Category": "EWS"
  },
  {
    "Name": "PES College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12295,
    "Category": "EWS"
  },
  {
    "Name": "Krupanidhi Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12586,
    "Category": "EWS"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13168,
    "Category": "EWS"
  },
  {
    "Name": "BITM - Ballari Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13319,
    "Category": "EWS"
  },
  {
    "Name": "Surana College Post Graduate Departments",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13544,
    "Category": "EWS"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14062,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas Institute of Technology, Srinivas Group of Colleges",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14174,
    "Category": "EWS"
  },
  {
    "Name": "Akash Institute of Engineering and Technology",
    "Course": "MBA",
    "Score": 14323,
    "Category": "EWS"
  },
  {
    "Name": "Krupanidhi School of Management powered by Sunstone Select",
    "Course": "Post Graduate Diploma in Management (PGDM)",
    "Score": 14405,
    "Category": "EWS"
  },
  {
    "Name": "Vijayanagara Sri Krishnadevaraya University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14756,
    "Category": "EWS"
  },
  {
    "Name": "BLDEAs V. P. Dr. P. G. Halakatti College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14777,
    "Category": "EWS"
  },
  {
    "Name": "Karnataka State Rural Development and Panchayat Raj University, Gadag",
    "Course": "MBA in Rural Management",
    "Score": 14938,
    "Category": "EWS"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15147,
    "Category": "EWS"
  },
  {
    "Name": "GMIT - GM Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15419,
    "Category": "EWS"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Information Management",
    "Score": 15460,
    "Category": "EWS"
  },
  {
    "Name": "Angadi Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15822,
    "Category": "EWS"
  },
  {
    "Name": "Amruta Institute of Engineering and Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16147,
    "Category": "EWS"
  },
  {
    "Name": "University B.D.T College of Engineering, Visvesvaraya Technological University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16488,
    "Category": "EWS"
  },
  {
    "Name": "S J C Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16908,
    "Category": "EWS"
  },
  {
    "Name": "Sri Siddhartha Institute of Management Studies (SSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17018,
    "Category": "EWS"
  },
  {
    "Name": "Maharaja Institute of Technology, Mysore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14792,
    "Category": "EWS"
  },
  {
    "Name": "Brindavan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15102,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Engineering and Research, Belagavi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15179,
    "Category": "EWS"
  },
  {
    "Name": "Kuvempu University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15444,
    "Category": "EWS"
  },
  {
    "Name": "St. Agnes College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15597,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Business Management and Research,Hubli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15969,
    "Category": "EWS"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16207,
    "Category": "EWS"
  },
  {
    "Name": "KLEs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16493,
    "Category": "EWS"
  },
  {
    "Name": "Dr. D. Veerendra Heggade Institute of Management Studies & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16929,
    "Category": "EWS"
  },
  {
    "Name": "East West Group Of Institutions, Magadi Road",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17124,
    "Category": "EWS"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17125,
    "Category": "EWS"
  },
  {
    "Name": "Sri Devaraj Urs Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17633,
    "Category": "EWS"
  },
  {
    "Name": "Parivarthana Business School'",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18321,
    "Category": "EWS"
  },
  {
    "Name": "Aditya Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18483,
    "Category": "EWS"
  },
  {
    "Name": "S.E.A College of Arts, Science and Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18866,
    "Category": "EWS"
  },
  {
    "Name": "Hill Side Institute of Management",
    "Course": "MBA in Business Management",
    "Score": 19261,
    "Category": "EWS"
  },
  {
    "Name": "Gopalan College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19759,
    "Category": "EWS"
  },
  {
    "Name": "JIT - Jain Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20043,
    "Category": "EWS"
  },
  {
    "Name": "Satellite Post Graduate Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20389,
    "Category": "EWS"
  },
  {
    "Name": "Rani Channamma University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21003,
    "Category": "EWS"
  },
  {
    "Name": "SVCE - Sri Venkateshwara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17462,
    "Category": "EWS"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Hospital Administration",
    "Score": 17850,
    "Category": "EWS"
  },
  {
    "Name": "Nagarjuna Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18451,
    "Category": "EWS"
  },
  {
    "Name": "St. Francis College",
    "Course": "Master of Business Studies (MBA)",
    "Score": 18724,
    "Category": "EWS"
  },
  {
    "Name": "Bangalore Integrated Management Academy, BIMA",
    "Course": "MBA",
    "Score": 19082,
    "Category": "EWS"
  },
  {
    "Name": "Sahyadri Commerce and Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19317,
    "Category": "EWS"
  },
  {
    "Name": "Seshadripuram College",
    "Course": "MBA",
    "Score": 19778,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Mca and Mba",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20262,
    "Category": "EWS"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Tourism and Travel Management",
    "Score": 20843,
    "Category": "EWS"
  },
  {
    "Name": "Nagarjuna College of Engineering and Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 21024,
    "Category": "EWS"
  },
  {
    "Name": "Jain College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21030,
    "Category": "EWS"
  },
  {
    "Name": "Chetan Business School Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21131,
    "Category": "EWS"
  },
  {
    "Name": "Brindavan College (National Fgc)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21204,
    "Category": "EWS"
  },
  {
    "Name": "RGC - Rajiv Gandhi Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21991,
    "Category": "EWS"
  },
  {
    "Name": "Bharatesh Education Trust'S Global Business School - BET's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22103,
    "Category": "EWS"
  },
  {
    "Name": "AIEMS - Amruta Institute of Engineering & Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22449,
    "Category": "EWS"
  },
  {
    "Name": "Shri Madhwa Vadiraja Institute of Technology and Management (SMVITM)",
    "Course": "MBA",
    "Score": 22828,
    "Category": "EWS"
  },
  {
    "Name": "Sindhi Institute of Management - SIM",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23131,
    "Category": "EWS"
  },
  {
    "Name": "Mysuru Royal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23571,
    "Category": "EWS"
  },
  {
    "Name": "R. L. Jalappa Institute of Technology, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23706,
    "Category": "EWS"
  },
  {
    "Name": "Government First Grade College,Davanagere",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21062,
    "Category": "EWS"
  },
  {
    "Name": "Vanguard Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21162,
    "Category": "EWS"
  },
  {
    "Name": "K.S. School of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21571,
    "Category": "EWS"
  },
  {
    "Name": "S.E.A College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22089,
    "Category": "EWS"
  },
  {
    "Name": "Basaveshwar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22216,
    "Category": "EWS"
  },
  {
    "Name": "Sai Vidya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22623,
    "Category": "EWS"
  },
  {
    "Name": "RIT - Rajeev Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22834,
    "Category": "EWS"
  },
  {
    "Name": "Visvesvaraya Technological University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 23518,
    "Category": "EWS"
  },
  {
    "Name": "Silicon City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23700,
    "Category": "EWS"
  },
  {
    "Name": "Soundarya Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23840,
    "Category": "EWS"
  },
  {
    "Name": "Nagarjuna College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23945,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Human Resource Management",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Logistics and Supply Chain Management",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Marketing Management",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship and Family Business Management",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Health Care Management and Hospital Administration",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Davangere University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24356,
    "Category": "EWS"
  },
  {
    "Name": "City College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24525,
    "Category": "EWS"
  },
  {
    "Name": "Karnataka University, Dharwad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24963,
    "Category": "EWS"
  },
  {
    "Name": "GT Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25330,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Financial Management",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Business Analytics and Big Data Analytics",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Marketing",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Digital Transformation",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Entrepreneurship Startup Management",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "Sapthagiri NPS University",
    "Course": "MBA in Project Management and Information Technology",
    "Score": 24205,
    "Category": "EWS"
  },
  {
    "Name": "ATMA College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 24469,
    "Category": "EWS"
  },
  {
    "Name": "GM University",
    "Course": "MBA in General Management",
    "Score": 24531,
    "Category": "EWS"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25169,
    "Category": "EWS"
  },
  {
    "Name": "R.R. Institute of Advanced Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25351,
    "Category": "EWS"
  },
  {
    "Name": "K L E S`s K L E College of Engineering and Technology, Chikodi",
    "Course": "MBA",
    "Score": 25376,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph Engineering College - SJEC",
    "Course": "MBA in Innovation, Entrepreneurship & Venture Development",
    "Score": 25577,
    "Category": "EWS"
  },
  {
    "Name": "Annapoorna Institute of Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25680,
    "Category": "EWS"
  },
  {
    "Name": "Indian Academy School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26004,
    "Category": "EWS"
  },
  {
    "Name": "Shridevi Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26128,
    "Category": "EWS"
  },
  {
    "Name": "CIT - Channabasaveshwara Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26558,
    "Category": "EWS"
  },
  {
    "Name": "Sambhram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26991,
    "Category": "EWS"
  },
  {
    "Name": "Vivekananda Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27601,
    "Category": "EWS"
  },
  {
    "Name": "Patel Institute of Science and Management.",
    "Course": "Master of Business Administration",
    "Score": 27968,
    "Category": "EWS"
  },
  {
    "Name": "A.J. Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28296,
    "Category": "EWS"
  },
  {
    "Name": "International Institute of Business Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25570,
    "Category": "EWS"
  },
  {
    "Name": "St. Francis De Sales College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25581,
    "Category": "EWS"
  },
  {
    "Name": "Jyothi Institute of Commerce and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25932,
    "Category": "EWS"
  },
  {
    "Name": "Central University of Karnataka",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26045,
    "Category": "EWS"
  },
  {
    "Name": "Kalpataru Institute of Technology - KIT TIPTUR",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26383,
    "Category": "EWS"
  },
  {
    "Name": "Rao Bahadur Y Mahabaleswarappa Engineering College - RYMEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26670,
    "Category": "EWS"
  },
  {
    "Name": "Adithya Institue of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27423,
    "Category": "EWS"
  },
  {
    "Name": "R.S. College of Management and Science",
    "Course": "MBA in Information Management",
    "Score": 27967,
    "Category": "EWS"
  },
  {
    "Name": "Padmashree Institute of Management and Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28278,
    "Category": "EWS"
  },
  {
    "Name": "Aditya College Of Engineering and Technology Yelahanka, Bengaluru",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28492,
    "Category": "EWS"
  },
  {
    "Name": "Haranahalli Ramaswamy Institute of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28604,
    "Category": "EWS"
  },
  {
    "Name": "Oxford College of Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29002,
    "Category": "EWS"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29339,
    "Category": "EWS"
  },
  {
    "Name": "Sri Siddhartha Academy of Higher Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29791,
    "Category": "EWS"
  },
  {
    "Name": "GNDEC - Guru Nanak Dev Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30003,
    "Category": "EWS"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "Master of Business Administration (MBA) (Exclusively for Women)",
    "Score": 30278,
    "Category": "EWS"
  },
  {
    "Name": "SJES College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30768,
    "Category": "EWS"
  },
  {
    "Name": "Impact College of Engineering and Applied Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31169,
    "Category": "EWS"
  },
  {
    "Name": "One School of Business",
    "Course": "Global MBA",
    "Score": 31883,
    "Category": "EWS"
  },
  {
    "Name": "Sampurna Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32371,
    "Category": "EWS"
  },
  {
    "Name": "Canara Bank School Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28834,
    "Category": "EWS"
  },
  {
    "Name": "Veerappa Nisty Engineering College",
    "Course": "MBA in Information Management",
    "Score": 29332,
    "Category": "EWS"
  },
  {
    "Name": "Al-Ameen Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29367,
    "Category": "EWS"
  },
  {
    "Name": "JVIT - Jnana Vikas Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29928,
    "Category": "EWS"
  },
  {
    "Name": "JSS Academy of Higher Education and Research",
    "Course": "MBA in Pharmacy Administration",
    "Score": 30261,
    "Category": "EWS"
  },
  {
    "Name": "Allum Karibasappa Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30405,
    "Category": "EWS"
  },
  {
    "Name": "Administrative Management College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31059,
    "Category": "EWS"
  },
  {
    "Name": "GRV Business Management Academy",
    "Course": "MBA + PGDBA",
    "Score": 31496,
    "Category": "EWS"
  },
  {
    "Name": "Manel Srinivas Nayak Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32066,
    "Category": "EWS"
  },
  {
    "Name": "NSB Academy",
    "Course": "MBA in General Management",
    "Score": 32538,
    "Category": "EWS"
  },
  {
    "Name": "SECAB Institute of Engineering and Technology (SIET Bijapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32808,
    "Category": "EWS"
  },
  {
    "Name": "Rai Technology University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32893,
    "Category": "EWS"
  },
  {
    "Name": "Sambhram Academy of Management Studies (SAMS, Bangalore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33537,
    "Category": "EWS"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "MBA in General Management",
    "Score": 33800,
    "Category": "EWS"
  },
  {
    "Name": "Impact Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33998,
    "Category": "EWS"
  },
  {
    "Name": "International Academy of Management and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34359,
    "Category": "EWS"
  },
  {
    "Name": "Sri Gokula College Of Arts, Science and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34718,
    "Category": "EWS"
  },
  {
    "Name": "Biluru Gurubasava Mahaswamiji Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34755,
    "Category": "EWS"
  },
  {
    "Name": "Dr. Sri. Sri. Sri. Shivakumara Mahaswamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34799,
    "Category": "EWS"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Innovation and Entrepreneurship",
    "Score": 34972,
    "Category": "EWS"
  },
  {
    "Name": "Sharnbasva University",
    "Course": "MBA in Hospital Administration",
    "Score": 32872,
    "Category": "EWS"
  },
  {
    "Name": "Rathinam School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33414,
    "Category": "EWS"
  },
  {
    "Name": "Imperial Institute of Advanced Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 33571,
    "Category": "EWS"
  },
  {
    "Name": "Oxbridge Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33830,
    "Category": "EWS"
  },
  {
    "Name": "Dr. Kariappa School of Art and Design Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34330,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas University",
    "Course": "Discontinued (Mar 2024)-Master of Business Administration (MBA Hons.) (Dual Specialization)",
    "Score": 34477,
    "Category": "EWS"
  },
  {
    "Name": "Svmvv Sangha'S Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34739,
    "Category": "EWS"
  },
  {
    "Name": "Moodlakatte Institute of Technology - MIT KUNDAPURA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34786,
    "Category": "EWS"
  },
  {
    "Name": "H K E Society's SLN College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34960,
    "Category": "EWS"
  },
  {
    "Name": "Koshys Group of Institutions",
    "Course": "Master of Business Administration (Core MBA)",
    "Score": 35066,
    "Category": "EWS"
  },
  {
    "Name": "H.M.S Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35235,
    "Category": "EWS"
  },
  {
    "Name": "Lingaraj Appa Engineering College, Bidar",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35428,
    "Category": "EWS"
  },
  {
    "Name": "Department of PG Studies, Visvesvaraya Technological University, Bangalore",
    "Course": "MBA",
    "Score": 35537,
    "Category": "EWS"
  },
  {
    "Name": "SVR College of Commerce and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35620,
    "Category": "EWS"
  },
  {
    "Name": "C.B. Bhandari Jain College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35675,
    "Category": "EWS"
  },
  {
    "Name": "Srinivas University",
    "Course": "Master of Business Administration (MBA) (Dual Specialization)",
    "Score": 35734,
    "Category": "EWS"
  },
  {
    "Name": "United International Degree College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35740,
    "Category": "EWS"
  },
  {
    "Name": "Indo Asian Academy Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35879,
    "Category": "EWS"
  },
  {
    "Name": "Karnataka College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35927,
    "Category": "EWS"
  },
  {
    "Name": "APS College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35949,
    "Category": "EWS"
  },
  {
    "Name": "KVG College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35386,
    "Category": "EWS"
  },
  {
    "Name": "Shushruti Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35510,
    "Category": "EWS"
  },
  {
    "Name": "Shree Devi Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35617,
    "Category": "EWS"
  },
  {
    "Name": "One School of Business",
    "Course": "MBA Advance",
    "Score": 35636,
    "Category": "EWS"
  },
  {
    "Name": "MQI College of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35710,
    "Category": "EWS"
  },
  {
    "Name": "Visvesvaraya Technological University, Kalaburagi",
    "Course": "Master of Business Administration",
    "Score": 35738,
    "Category": "EWS"
  },
  {
    "Name": "Karavali Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35866,
    "Category": "EWS"
  },
  {
    "Name": "St. George College of Management, Science and Nursing",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35916,
    "Category": "EWS"
  },
  {
    "Name": "B. V. V. Sangha's Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35940,
    "Category": "EWS"
  },
  {
    "Name": "City Engineering College (CEC, Banglore)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35962,
    "Category": "EWS"
  },
  {
    "Name": "MEWA Vanguard Business School",
    "Course": "MBA Vanguard",
    "Score": 35971,
    "Category": "EWS"
  },
  {
    "Name": "GM University",
    "Course": "MBA in Agricultural Marketing",
    "Score": 35978,
    "Category": "EWS"
  },
  {
    "Name": "Institute of Excellence in Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36043,
    "Category": "EWS"
  },
  {
    "Name": "Regional College of Management, Bangalore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36081,
    "Category": "EWS"
  },
  {
    "Name": "PA College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36128,
    "Category": "EWS"
  },
  {
    "Name": "A.V.K. Institute of Management",
    "Course": "M.B.A. in Marketing, HR, Finance",
    "Score": 36151,
    "Category": "EWS"
  },
  {
    "Name": "Hillside Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36169,
    "Category": "EWS"
  },
  {
    "Name": "BCET - Bangalore College of Engineering and Technology",
    "Course": "MBA",
    "Score": 36186,
    "Category": "EWS"
  },
  {
    "Name": "T. John Institute of Management and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36217,
    "Category": "EWS"
  },
  {
    "Name": "Anjuman Institute of Technology and Management (AITM,Bhatkal)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36230,
    "Category": "EWS"
  },
  {
    "Name": "Dr. H N National College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35972,
    "Category": "EWS"
  },
  {
    "Name": "KU Post Graduate Center, Gadag",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35993,
    "Category": "EWS"
  },
  {
    "Name": "RJS Institute of Management Studies (RJSIMS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36073,
    "Category": "EWS"
  },
  {
    "Name": "T. John Group of Institutions",
    "Course": "Master of Business Administration (BU)",
    "Score": 36094,
    "Category": "EWS"
  },
  {
    "Name": "Poornaprajna Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36129,
    "Category": "EWS"
  },
  {
    "Name": "SB College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36167,
    "Category": "EWS"
  },
  {
    "Name": "CanaraBankSchoolofManagementStudies, JnanabharathiCampus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36182,
    "Category": "EWS"
  },
  {
    "Name": "MVM College of Arts Science & Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36216,
    "Category": "EWS"
  },
  {
    "Name": "GM University",
    "Course": "MBA in International Business",
    "Score": 36229,
    "Category": "EWS"
  },
  {
    "Name": "BLDEA's A S Patil College of Commerce",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36240,
    "Category": "EWS"
  },
  {
    "Name": "IZee Business School",
    "Course": "MBA + PGPM Global",
    "Score": 42297,
    "Category": "EWS"
  },
  {
    "Name": "Harsha Institutions",
    "Course": "Master of Business Administration (MBA)- Value Added",
    "Score": 42905,
    "Category": "EWS"
  },
  {
    "Name": "Vogue Dr. Kariappa Business School",
    "Course": "Hybrid MBA",
    "Score": 42862,
    "Category": "EWS"
  },
  {
    "Name": "Shri Madhwa Vadiraja Institute of Technology",
    "Course": "MBA",
    "Score": 43006,
    "Category": "EWS"
  },
  {
    "Name": "Dhanwantari Academy for Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36402,
    "Category": "EWS"
  },
  {
    "Name": "Regional Institute of Cooperative Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40984,
    "Category": "EWS"
  },
  {
    "Name": "BTL Institute of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43168,
    "Category": "EWS"
  },
  {
    "Name": "Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42995,
    "Category": "EWS"
  }
]