import React, { useState } from 'react';
import data from '../JACDELHIPredictor/PREDICTORJACDELHIJSON.json'; // Import the JSON file
import { Modal, Button, Input, Form } from 'antd';
import { useNavigate } from 'react-router-dom'; // Use react-router-dom's useNavigate
import CompulsoryTimesignup from '../CompulsoryTimesignup';
const InstitutePredictor = () => {
  const navigate = useNavigate(); // useNavigate hook to handle redirection
  const [filters, setFilters] = useState({
    Region: '',
    Category: '',
    Branch: '',
    Rank: '',
  });

  const [filteredInstitutes, setFilteredInstitutes] = useState([]);
  const [error, setError] = useState(''); // State to handle error messages
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false); // Track if filters have been applied
  const [showSignupModal, setShowSignupModal] = useState(false); // State to control modal visibility
  const [isPredictionAttempted, setIsPredictionAttempted] = useState(false); // Track prediction attempt
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false); // Track if the form has been submitted before
  const [clickCount, setClickCount] = useState(0); // Track how many times the "Predict Institute" button has been clicked
   const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track form submission
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
    setError(''); // Clear error when a field is updated
  };

  const applyFilters = () => {
    // Check if all fields are filled
    if (!filters.Region || !filters.Category || !filters.Branch || !filters.Rank) {
      setError('All fields are mandatory. Please select or fill in all the details.');
      return;
    }

    const filtered = data
      .filter((item) => {
        return (
          (!filters.Region || item.Region === filters.Region) &&
          (!filters.Category || item.Category === filters.Category) &&
          (!filters.Branch || item.Branch === filters.Branch) &&
          (!filters.Rank || item.Rank >= parseInt(filters.Rank))
        );
      })
      .map((item) => item.Institute); // Extract only the Institute names

    // Remove duplicates using Set
    const uniqueFiltered = [...new Set(filtered)];

    setFilteredInstitutes(uniqueFiltered);
    setHasAppliedFilters(true); // Mark that filters have been applied
    setIsPredictionAttempted(true); // Mark that the prediction has been attempted
  };

  const uniqueOptions = (key) => {
    return [...new Set(data.map((item) => item[key]))];
  };

  const handleModalSubmit = () => {
    if (!formData.name || !formData.email || !formData.mobile) {
      alert('All fields are required!');
    } else {
      console.log('User Data: ', formData); // This is where you can send data to the backend
      setShowSignupModal(false); // Close the modal after submission
      setHasSubmittedForm(true); // Mark the form as submitted
      applyFilters(); // Apply the filters after form submission
    }
  };

  const checkFieldsAndOpenModal = () => {
    if (clickCount >= 1) {
      Modal.info({
        title: 'Limit!! expired',
        content: (
          <div>
            <p>Please select any plan </p>
          </div>
        ),
        onOk: () => navigate('/pricingplans'), // Redirect to pricing plans using navigate
      });
      return;
    }

    if (!filters.Region || !filters.Category || !filters.Branch || !filters.Rank) {
      setError('Please fill in all the fields first before opening the prediction.');
    } else if (!hasSubmittedForm) {
      setShowSignupModal(true); // Show the modal if form is not submitted yet
    } else {
      setClickCount((prevCount) => prevCount + 1); // Increment click count
      applyFilters(); // Directly apply filters if the form is already submitted
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>JAC DELHI Predictor</h2>
      <CompulsoryTimesignup
        onFormSubmit={() => {
          setIsFormSubmitted(true); // Set form submitted to true once form is completed
          setShowSignupModal(false); // Close modal after successful submission
        }}
        showSignupModal={showSignupModal} // Pass down the state for modal visibility
        onCloseModal={() => setShowSignupModal(false)} // Function to close the modal
      />
     
      <Modal
        title="Enter Your Information"
        visible={showSignupModal}
        onCancel={() => setShowSignupModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowSignupModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Name" required>
            <Input
              name="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              placeholder="Enter your name"
            />
          </Form.Item>
          <Form.Item label="Email" required>
            <Input
              name="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              placeholder="Enter your email"
            />
          </Form.Item>
          <Form.Item label="Mobile Number" required>
            <Input
              name="mobile"
              value={formData.mobile}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              placeholder="Enter your mobile number"
            />
          </Form.Item>
        </Form>
      </Modal>

      <div style={styles.filterContainer}>
        <div style={styles.filterItem}>
          <label style={styles.label}>Region:</label>
          <select
            style={styles.select}
            onChange={(e) => handleFilterChange('Region', e.target.value)}
          >
            <option value="">Select Region</option>
            {uniqueOptions('Region').map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.filterItem}>
          <label style={styles.label}>Category:</label>
          <select
            style={styles.select}
            onChange={(e) => handleFilterChange('Category', e.target.value)}
          >
            <option value="">Select Category</option>
            {uniqueOptions('Category').map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.filterItem}>
          <label style={styles.label}>Branch:</label>
          <select
            style={styles.select}
            onChange={(e) => handleFilterChange('Branch', e.target.value)}
          >
            <option value="">Select Branch</option>
            {uniqueOptions('Branch').map((branch, index) => (
              <option key={index} value={branch}>
                {branch}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.filterItem}>
          <label style={styles.label}>Rank:</label>
          <input
            style={styles.input}
            type="number"
            placeholder="Enter Rank"
            onChange={(e) => handleFilterChange('Rank', e.target.value)}
          />
        </div>
      </div>

      {error && <p style={styles.error}>{error}</p>}

      {/* Button to show modal only if all fields are filled */}
      <button
        style={styles.button}
        onClick={checkFieldsAndOpenModal} // Check if fields are filled before showing the modal
      >
        Predict Institute
      </button>

      {/* Once prediction has been attempted, show the results */}
      {isPredictionAttempted && (
        <>
          <h3 style={styles.subHeading}>Predicted Institutes:</h3>
          {hasAppliedFilters && filteredInstitutes.length === 0 && (
            <p style={styles.noData}>No institute found.</p>
          )}
          {filteredInstitutes.length > 0 && (
            <ul style={styles.list}>
              {filteredInstitutes.map((institute, index) => (
                <li key={index} style={styles.listItem}>
                  {institute}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
    fontSize: '23px',
    fontWeight: 'bold',
  },
  filterContainer: {
    marginBottom: '20px',
  },
  filterItem: {
    marginBottom: '10px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#555',
  },
  select: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  subHeading: {
    marginTop: '20px',
    color: '#333',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    padding: '8px 0',
    borderBottom: '1px solid #ddd',
  },
  error: {
    color: 'red',
    fontSize: '14px',
  },
  noData: {
    color: '#888',
    fontSize: '16px',
  },
};

export default InstitutePredictor;
