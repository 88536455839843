[
  {
    "Name": "Indian Institute of Foreign Trade, Delhi",
    "Score": 36,
    "Course": "MBA in International Business",
    "Category": "General"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Score": 34,
    "Course": "MBA in International Business",
    "Category": "General"
  },
  {
    "Name": "IIFT Kakinada - Indian Institute of Foreign Trade",
    "Score": 32,
    "Course": "MBA in International Business",
    "Category": "General"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Score": 27.25,
    "Course": "MBA in International Business",
    "Category": "OBC"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Delhi",
    "Score": 27.25,
    "Course": "MBA in International Business",
    "Category": "OBC"
  },
  {
    "Name": "IIFT Kakinada - Indian Institute of Foreign Trade",
    "Score": 27,
    "Course": "MBA in International Business",
    "Category": "OBC"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Score": 22.25,
    "Course": "MBA in International Business",
    "Category": "SC"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Delhi",
    "Score": 22.25,
    "Course": "MBA in International Business",
    "Category": "SC"
  },
  {
    "Name": "IIFT Kakinada - Indian Institute of Foreign Trade",
    "Score": 22,
    "Course": "MBA in International Business",
    "Category": "SC"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Score": 20.26,
    "Course": "MBA in International Business",
    "Category": "ST"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Delhi",
    "Score": 20.26,
    "Course": "MBA in International Business",
    "Category": "ST"
  },
  {
    "Name": "IIFT Kakinada - Indian Institute of Foreign Trade",
    "Score": 20,
    "Course": "MBA in International Business",
    "Category": "ST"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Score": 22.25,
    "Course": "MBA in International Business",
    "Category": "PWD"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Delhi",
    "Score": 22.25,
    "Course": "MBA in International Business",
    "Category": "PWD"
  },
  {
    "Name": "IIFT Kakinada - Indian Institute of Foreign Trade",
    "Score": 22,
    "Course": "MBA in International Business",
    "Category": "PWD"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Delhi",
    "Score": 36,
    "Course": "MBA in International Business",
    "Category": "Economically Weaker Section"
  },
  {
    "Name": "Indian Institute of Foreign Trade, Kolkata",
    "Score": 34,
    "Course": "MBA in International Business",
    "Category": "Economically Weaker Section"
  },
  {
    "Name": "IIFT Kakinada - Indian Institute of Foreign Trade",
    "Score": 32,
    "Course": "MBA in International Business",
    "Category": "Economically Weaker Section"
  }
]