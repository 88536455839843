// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate for navigation

// function Dates(props) {
//   const navigate = useNavigate(); // Initialize navigate for tab navigation
//   const { examName } = useParams();
//   // FAQ data
//   const faqs = [
//     { question: "Who is eligible to apply for CAT?", answer: "Eligibility details go here." },
//     { question: "How to fill the application form?", answer: "Form filling instructions go here." },
//     { question: "Is CAT tougher than other MBA entrance exams?", answer: "Answer regarding CAT difficulty goes here." },
//   ];

//   // Exam highlights data
//   const highlights = [
//     { feature: "Exam Name", details: "CAT 2024" },
//     { feature: "Conducting Body", details: "Indian Institutes of Management (IIMs)" },
//     { feature: "Exam Mode", details: "Computer-Based Test" },
//     { feature: "Frequency", details: "Once a year" },
//     { feature: "Exam Duration", details: "120 minutes" },
//     { feature: "Number of Sections", details: "3 (VARC, DILR, QA)" },
//   ];

//   // Exam schedule data
//   const schedule = [
//     { activity: "Release of Application Form", timeline: "August 2024" },
//     { activity: "Last Date to Apply", timeline: "September 2024" },
//     { activity: "Admit Card Release", timeline: "October 2024" },
//     { activity: "Exam Date", timeline: "November 2024" },
//     { activity: "Result Declaration", timeline: "December 2024" },
//   ];

//   // Navigation tabs (example, you can adjust paths as needed)
//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   // { name: "Question Papers", path: "/CatExam/questionpapers" },
//   //   // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" }
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.DatesHeaderHeading1} {props.DatesYear} : {props.Datesaddwhichheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.DatesHeaderadmitcardname}: {props.DatesHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.DatesHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.DatesHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)}
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <h1 className="text-2xl font-bold text-gray-800 mb-6">{props.Datesheading1}</h1>

//       {/* Exam Highlights Table */}
//       <Table data={highlights} headers={[props.Datesheading1, props.Datesheading1description1, ]} />

//       {/* Exam Schedule Table */}
//       <Table data={schedule} headers={[props.DatesEventnameheading1, props.DatesExpectedTimelineheading1description1,]} />

//       {/* FAQ Section */}
//       <div className="mt-6">
//         <h2 className="text-xl font-bold text-gray-800 mb-4">Frequently Asked Questions</h2>
//         {faqs.map((faq, index) => (
//           <FAQItem key={index} question={faq.question} answer={faq.answer} />
//         ))}
//       </div>
//     </div>
//   );
// }

// // Table component for reusability
// function Table({ data, headers }) {
//   return (
//     <div className="overflow-x-auto my-4">
//       <table className="min-w-full bg-white border border-gray-200">
//         <thead>
//           <tr>
//             {headers.map((header, index) => (
//               <th key={index} className="px-4 py-2 text-left bg-gray-100 border-b">{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => (
//             <tr key={index} className="border-b">
//               {Object.values(item).map((value, i) => (
//                 <td key={i} className="px-4 py-2 text-gray-600">{value}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// // FAQItem component for displaying FAQ questions and answers
// function FAQItem({ question, answer }) {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="border-b border-gray-200 py-4">
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="w-full text-left flex justify-between items-center"
//       >
//         <span className="text-gray-700 font-semibold">{question}</span>
//         <svg
//           className={`w-6 h-6 transform ${isOpen ? "rotate-180" : "rotate-0"}`}
//           fill="none"
//           viewBox="0 0 24 24"
//           stroke="currentColor"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M19 9l-7 7-7-7"
//           />
//         </svg>
//       </button>
//       {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
//     </div>
//   );
// }

// export default Dates;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExamSchedule from "./ExamSchedule";

function Dates() {
  const navigate = useNavigate();
  const { examName } = useParams();

  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false); // State to toggle the expanded description

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        const data = await response.json();
        setExamData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching exam data:", error);
        setLoading(false);
      }
    };

    fetchExamData();
  }, [examName]);

  if (loading) return <div>Loading...</div>;
  if (!examData) return <div>Exam not found.</div>;

  // Extract necessary data from fetched examData
  const {
    highlights = [],
    schedule = [],
    tabs = [],
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
    admitCardName,
    admitCardDate,
    year,
    examName: name,
    heading2Description,
  } = examData;

  // Handle the "Read More" button functionality
  const handleReadMoreClick = () => {
    setExpanded(!expanded);
  };

  const visibleDescription = expanded
    ? heading1Description
    : heading1Description.split("\n").slice(0, 15).join("\n");

  return (
    <div className="bg-gray-100 p-8">
      {/* Display Header */}
      <div className="p-6 rounded-lg shadow-md bg-white">
        <div className="flex flex-col items-center justify-center px-4">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800 text-center flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
            {heading2Description && (
              <img
                src={heading2Description}
                alt="image"
                className="h-50 sm:h-12 w-auto" // Adjust size as needed
              />
            )}
            <span className="text-center">
              {name} {year}: Exam, Syllabus, Exam Pattern, Notification, Dates, Mock Test
            </span>
          </h1>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-6 overflow-x-auto" style={{ backgroundColor: 'rgb(104, 164, 165)', color: 'white' }}>
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-black-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Exam Highlights */}
      <h2 className="text-xl font-bold text-gray-800 mt-6 mb-2">{heading1}</h2>
      <p className="text-gray-700 mb-4">
        {visibleDescription}
        {!expanded && heading1Description.split("\n").length > 5 && (
          <button
            onClick={handleReadMoreClick}
            className="text-blue-500 hover:underline"
          >
            .........Read more
          </button>
        )}
      </p>
      {expanded && (
        <div>
          <p className="text-gray-700 mb-4">
            {heading1Description}
          </p>
          <button
            onClick={handleReadMoreClick}
            className="text-blue-500 hover:underline"
          >
            Read Less
          </button>
        </div>
      )}

      {/* Exam Highlights Table */}
      <table className="w-full text-left">
        <thead>
          <tr>
            <th className="p-4 border">Title</th>
            <th className="p-4 border">Details</th>
          </tr>
        </thead>
        <tbody>
          {highlights.map((highlight, index) => (
            <tr key={index}>
              <td className="p-4 border">{highlight.feature}</td>
              <td className="p-4 border">{highlight.details}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Exam Schedule */}
      <ExamSchedule examData={examData} />
    </div>
  );
}

export default Dates;

