[
  {
    "Name": "College of Engineering, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.49,
    "Category": "General"
  },
  {
    "Name": "University of Madras",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.07,
    "Category": "General"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "MBA in Tourism Management",
    "Score": 94.37,
    "Category": "General"
  },
  {
    "Name": "Thiagarajar School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.76,
    "Category": "General"
  },
  {
    "Name": "D D Goverdhan Doss Vaishnav College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.97,
    "Category": "General"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Discontinued (Oct 2021) Master of Business Administration (MBA) (Part Time)",
    "Score": 87.68,
    "Category": "General"
  },
  {
    "Name": "Kumaraguru College of Technology",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 85.51,
    "Category": "General"
  },
  {
    "Name": "St.Joseph's College, Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.26,
    "Category": "General"
  },
  {
    "Name": "SRM Easwari Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.62,
    "Category": "General"
  },
  {
    "Name": "SRM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.01,
    "Category": "General"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.01,
    "Category": "General"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "MBA in Waste Management and Social Entrepreneurship",
    "Score": 96.35,
    "Category": "General"
  },
  {
    "Name": "M.O.P. Vaishnav College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.94,
    "Category": "General"
  },
  {
    "Name": "Indira Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.83,
    "Category": "General"
  },
  {
    "Name": "Erode Sengunthar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.97,
    "Category": "General"
  },
  {
    "Name": "Bharathiar University, Coimbatore",
    "Course": "Master of Business Administration (Regular)",
    "Score": 87.68,
    "Category": "General"
  },
  {
    "Name": "SKCET - Sri Krishna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.93,
    "Category": "General"
  },
  {
    "Name": "Ethiraj College for Women (Ethiraj)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.13,
    "Category": "General"
  },
  {
    "Name": "Nesamony Memorial Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.01,
    "Category": "General"
  },
  {
    "Name": "Dr. G.R. Damodaran Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 78.48,
    "Category": "General"
  },
  {
    "Name": "Bannari Amman Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.67,
    "Category": "General"
  },
  {
    "Name": "SRM Valliammai Engineering College, Chengalpattu",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "General"
  },
  {
    "Name": "Velammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "General"
  },
  {
    "Name": "Jayalakshmi Institute of Technology (JIT Dharmapuri)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "General"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in Infrastructure Management",
    "Score": 70.4,
    "Category": "General"
  },
  {
    "Name": "St.Xavier's College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "General"
  },
  {
    "Name": "Meenakshi Sundararajan School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "General"
  },
  {
    "Name": "St. Joseph's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.05,
    "Category": "General"
  },
  {
    "Name": "Guru Nanak College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "General"
  },
  {
    "Name": "Anna University - Coimbatore Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.93,
    "Category": "General"
  },
  {
    "Name": "St Xavier's Catholic College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.67,
    "Category": "General"
  },
  {
    "Name": "Sri Balaji Chockalingam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "General"
  },
  {
    "Name": "MEC - Muthiammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "General"
  },
  {
    "Name": "Sri Krishna College of Technology [SKCT]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "General"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.4,
    "Category": "General"
  },
  {
    "Name": "Rajalakshmi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "General"
  },
  {
    "Name": "KEC - Kongu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.05,
    "Category": "General"
  },
  {
    "Name": "Panimalar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.67,
    "Category": "General"
  },
  {
    "Name": "MEASI Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "General"
  },
  {
    "Name": "Coimbatore Institute Of Engineering And Technology - CIET Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "General"
  },
  {
    "Name": "Periyar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "General"
  },
  {
    "Name": "Coimbatore Institute of Management and Technology (CIMAT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "General"
  },
  {
    "Name": "The American College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "General"
  },
  {
    "Name": "PSGR Krishnammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.56,
    "Category": "General"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in International Business Management",
    "Score": 57.17,
    "Category": "General"
  },
  {
    "Name": "Loyola Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "General"
  },
  {
    "Name": "Udaya School Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.68,
    "Category": "General"
  },
  {
    "Name": "Nehru College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "General"
  },
  {
    "Name": "Maria College of Engineering and Technology (MCET, Kanyakumari)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.67,
    "Category": "General"
  },
  {
    "Name": "Tamilnadu College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "General"
  },
  {
    "Name": "Jerusalem College of Engineering, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "General"
  },
  {
    "Name": "Sri Ramakrishna Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "General"
  },
  {
    "Name": "PSN College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "General"
  },
  {
    "Name": "Bishop Heber College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.01,
    "Category": "General"
  },
  {
    "Name": "Sri Sairam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "General"
  },
  {
    "Name": "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "General"
  },
  {
    "Name": "Francis Xavier Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "General"
  },
  {
    "Name": "Saveetha Engineering College, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.67,
    "Category": "General"
  },
  {
    "Name": "Alpha College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.85,
    "Category": "General"
  },
  {
    "Name": "Auxilium College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "General"
  },
  {
    "Name": "Jeppiaar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "General"
  },
  {
    "Name": "University College of Engineering, Bharathidasan Institute of Technology, Tiruchirappalli, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "General"
  },
  {
    "Name": "St. Peter's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.06,
    "Category": "General"
  },
  {
    "Name": "MAM College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.94,
    "Category": "General"
  },
  {
    "Name": "JCE - Jaya Engineering College Thiruninravur Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "General"
  },
  {
    "Name": "Dhaanish Ahmed College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "General"
  },
  {
    "Name": "CK College Of Engineering And Technology (CKCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "General"
  },
  {
    "Name": "SCAD College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "General"
  },
  {
    "Name": "Vivekanandha Institute of Information and Management Studies, Vivekanandha Educational Institutions for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "General"
  },
  {
    "Name": "NEC - Nandha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "General"
  },
  {
    "Name": "VCFW - Valliammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "General"
  },
  {
    "Name": "M.Kumarasamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "General"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in Business Analytics",
    "Score": 40.96,
    "Category": "General"
  },
  {
    "Name": "C. Abdul Hakeem College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38,
    "Category": "General"
  },
  {
    "Name": "Happy Valley Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "General"
  },
  {
    "Name": "Dr. N.G.P. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "General"
  },
  {
    "Name": "Mailam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "General"
  },
  {
    "Name": "Hindusthan College of Arts and Science [HICAS]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "General"
  },
  {
    "Name": "Velalar College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "General"
  },
  {
    "Name": "Kongu Arts And Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "General"
  },
  {
    "Name": "Arunai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "General"
  },
  {
    "Name": "Nehru Institute of Technology - NIT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "General"
  },
  {
    "Name": "KV Institute of Management and Information Studies (KVIMIS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "General"
  },
  {
    "Name": "SSB - Sri Ganesh School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "General"
  },
  {
    "Name": "Jamal Mohmed College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.86,
    "Category": "General"
  },
  {
    "Name": "Madha Group of Academic Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "General"
  },
  {
    "Name": "Mohamed Sathak College of Arts and Science - MSCAS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "General"
  },
  {
    "Name": "Sri Ramakrishna College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "General"
  },
  {
    "Name": "J.J. College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "General"
  },
  {
    "Name": "IFET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "General"
  },
  {
    "Name": "S.A. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.44,
    "Category": "General"
  },
  {
    "Name": "PSNA College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "General"
  },
  {
    "Name": "Nehru Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "General"
  },
  {
    "Name": "College of Business Management,Krishnagiri",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "General"
  },
  {
    "Name": "Sri Ramanujar Engineering College (SREC, Kolapakkam)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.01,
    "Category": "General"
  },
  {
    "Name": "Tagore Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "General"
  },
  {
    "Name": "Info Institute of Engineering - IIE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "General"
  },
  {
    "Name": "Tagore Institute of Engineering and Technology - TIET Salem",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "General"
  },
  {
    "Name": "HBS - Hallmark Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "General"
  },
  {
    "Name": "Kingston Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.94,
    "Category": "General"
  },
  {
    "Name": "Ponjesly College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.94,
    "Category": "General"
  },
  {
    "Name": "SNS College of Technology, SNS Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "General"
  },
  {
    "Name": "Mother Teresa Women'S University - MTWU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "General"
  },
  {
    "Name": "NIMS - Nehru Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "General"
  },
  {
    "Name": "Sona College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.89,
    "Category": "General"
  },
  {
    "Name": "Sacred Heart College, Tirupattur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "General"
  },
  {
    "Name": "Anna University - Madurai Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.55,
    "Category": "General"
  },
  {
    "Name": "Sri Meenakshi Government College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "General"
  },
  {
    "Name": "P.S.V. College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "General"
  },
  {
    "Name": "K.L.N. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "General"
  },
  {
    "Name": "SRIT - Sri Ramakrishna Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "General"
  },
  {
    "Name": "Hindusthan College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "General"
  },
  {
    "Name": "Er. Perumal Manimekalai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "General"
  },
  {
    "Name": "SNS College of Engineering, SNS Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "General"
  },
  {
    "Name": "P S R Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "General"
  },
  {
    "Name": "St. Joseph College of Engineering, Sriperumbudur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "General"
  },
  {
    "Name": "AVC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.55,
    "Category": "General"
  },
  {
    "Name": "Saranathan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "General"
  },
  {
    "Name": "Government Arts College, Paramakudi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "General"
  },
  {
    "Name": "Vivekananda Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.56,
    "Category": "General"
  },
  {
    "Name": "Dhanalakshmi Srinivasan College Of Engineering And Technology - DSCET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.03,
    "Category": "General"
  },
  {
    "Name": "Gnanam School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "General"
  },
  {
    "Name": "Misrimal Navajee Munoth Jain Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "General"
  },
  {
    "Name": "Annamalai University",
    "Course": "MBA in Financial Management",
    "Score": 13.67,
    "Category": "General"
  },
  {
    "Name": "St. Josephs College of Engineering and Technology, Thanjavur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "General"
  },
  {
    "Name": "Karpagam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "General"
  },
  {
    "Name": "Ganadipathy Tulsi's Jain Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "General"
  },
  {
    "Name": "Annamalai University",
    "Course": "Master of Business Administration (MBA Dual Specialization)",
    "Score": 10.36,
    "Category": "General"
  },
  {
    "Name": "Sree Narayana Guru Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "General"
  },
  {
    "Name": "Kalaignar Karunanidhi Institute of Technology - KKIT",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "General"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 16.03,
    "Category": "General"
  },
  {
    "Name": "CSI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "General"
  },
  {
    "Name": "Muthurangam Government Arts College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "General"
  },
  {
    "Name": "Selvam Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "General"
  },
  {
    "Name": "E.G.S. Pillay Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "General"
  },
  {
    "Name": "Adithya Institute of Technology - AIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "General"
  },
  {
    "Name": "Rathinam Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.27,
    "Category": "General"
  },
  {
    "Name": "Natesan Institute of Co-Operative Management",
    "Course": "MBA in Human Resource Management",
    "Score": 9.15,
    "Category": "General"
  },
  {
    "Name": "Sri Sai Ram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "General"
  },
  {
    "Name": "Mepco Schlenk Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "General"
  },
  {
    "Name": "G R Damodaran Academy of Management (GRDAM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "General"
  },
  {
    "Name": "Dhanalakshmi Srinivasan Engineering College - DSEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "General"
  },
  {
    "Name": "Anand Institute of Higher Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "General"
  },
  {
    "Name": "KIOT - Knowledge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "General"
  },
  {
    "Name": "KRCE - K Ramakrishnan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "General"
  },
  {
    "Name": "Excel Engineering College, Excel Group Institutions",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 6.8,
    "Category": "General"
  },
  {
    "Name": "GRT Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "General"
  },
  {
    "Name": "MCE - Meenakshi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "General"
  },
  {
    "Name": "Erode Builder Educational Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.91,
    "Category": "General"
  },
  {
    "Name": "Sakthi Institute of Information and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "General"
  },
  {
    "Name": "NPR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "General"
  },
  {
    "Name": "Anna University - Tirunelveli Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "General"
  },
  {
    "Name": "Akshaya institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "General"
  },
  {
    "Name": "Amrita College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "General"
  },
  {
    "Name": "Chettinad College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "General"
  },
  {
    "Name": "Sri Vidya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.51,
    "Category": "General"
  },
  {
    "Name": "Thirumalai Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "General"
  },
  {
    "Name": "RVS Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.91,
    "Category": "General"
  },
  {
    "Name": "Adhiparasakthi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "General"
  },
  {
    "Name": "M.A.M. Business School,Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "General"
  },
  {
    "Name": "DSIT - Dhanalakshmi Srinivasan Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "General"
  },
  {
    "Name": "Thangavelu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "General"
  },
  {
    "Name": "Sri Muthukumaran Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.36,
    "Category": "General"
  },
  {
    "Name": "Nalanda School of Business",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "General"
  },
  {
    "Name": "AVS Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.51,
    "Category": "General"
  },
  {
    "Name": "M.A.M College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "General"
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "General"
  },
  {
    "Name": "M.I.E.T. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.3,
    "Category": "General"
  },
  {
    "Name": "A.R.J College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "General"
  },
  {
    "Name": "Vijay Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.62,
    "Category": "General"
  },
  {
    "Name": "Shrimati Indira Gandhi College (SIGC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.36,
    "Category": "General"
  },
  {
    "Name": "Adhiyamaan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "General"
  },
  {
    "Name": "Karpaga Vinayaga College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.43,
    "Category": "General"
  },
  {
    "Name": "Priyadarshini Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "General"
  },
  {
    "Name": "Madurai School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.5,
    "Category": "General"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.52,
    "Category": "OBC"
  },
  {
    "Name": "University of Madras",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96.74,
    "Category": "OBC"
  },
  {
    "Name": "Thiagarajar School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.27,
    "Category": "OBC"
  },
  {
    "Name": "D D Goverdhan Doss Vaishnav College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.93,
    "Category": "OBC"
  },
  {
    "Name": "Kumaraguru College of Technology",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 79.3,
    "Category": "OBC"
  },
  {
    "Name": "Bharathiar University, Coimbatore",
    "Course": "Master of Business Administration (Regular)",
    "Score": 76.67,
    "Category": "OBC"
  },
  {
    "Name": "SRM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "OBC"
  },
  {
    "Name": "Bannari Amman Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "OBC"
  },
  {
    "Name": "Meenakshi Sundararajan School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 57.17,
    "Category": "OBC"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Discontinued (Oct 2021) Master of Business Administration (MBA) (Part Time)",
    "Score": 53.54,
    "Category": "OBC"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.76,
    "Category": "OBC"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "MBA in Waste Management and Social Entrepreneurship",
    "Score": 93.76,
    "Category": "OBC"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "MBA in Tourism Management",
    "Score": 86.68,
    "Category": "OBC"
  },
  {
    "Name": "M.O.P. Vaishnav College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.76,
    "Category": "OBC"
  },
  {
    "Name": "SKCET - Sri Krishna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.4,
    "Category": "OBC"
  },
  {
    "Name": "Ethiraj College for Women (Ethiraj)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "OBC"
  },
  {
    "Name": "St.Joseph's College, Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "OBC"
  },
  {
    "Name": "SRM Easwari Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.01,
    "Category": "OBC"
  },
  {
    "Name": "Velammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.31,
    "Category": "OBC"
  },
  {
    "Name": "Coimbatore Institute of Management and Technology (CIMAT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "OBC"
  },
  {
    "Name": "Periyar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.67,
    "Category": "OBC"
  },
  {
    "Name": "Guru Nanak College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "OBC"
  },
  {
    "Name": "Auxilium College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "OBC"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "OBC"
  },
  {
    "Name": "St.Xavier's College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.44,
    "Category": "OBC"
  },
  {
    "Name": "PSNA College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.01,
    "Category": "OBC"
  },
  {
    "Name": "St. Joseph's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "OBC"
  },
  {
    "Name": "MEASI Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.75,
    "Category": "OBC"
  },
  {
    "Name": "The American College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "OBC"
  },
  {
    "Name": "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "OBC"
  },
  {
    "Name": "Sri Krishna College of Technology [SKCT]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.67,
    "Category": "OBC"
  },
  {
    "Name": "St Xavier's Catholic College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "OBC"
  },
  {
    "Name": "Francis Xavier Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.94,
    "Category": "OBC"
  },
  {
    "Name": "KEC - Kongu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.44,
    "Category": "OBC"
  },
  {
    "Name": "Coimbatore Institute Of Engineering And Technology - CIET Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.86,
    "Category": "OBC"
  },
  {
    "Name": "Bishop Heber College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.01,
    "Category": "OBC"
  },
  {
    "Name": "University College of Engineering, Bharathidasan Institute of Technology, Tiruchirappalli, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "OBC"
  },
  {
    "Name": "Dr. G.R. Damodaran Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 24.75,
    "Category": "OBC"
  },
  {
    "Name": "Saveetha Engineering College, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.89,
    "Category": "OBC"
  },
  {
    "Name": "Sri Sairam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "OBC"
  },
  {
    "Name": "Anna University - Madurai Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "OBC"
  },
  {
    "Name": "AVC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "OBC"
  },
  {
    "Name": "Loyola Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "OBC"
  },
  {
    "Name": "VCFW - Valliammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "OBC"
  },
  {
    "Name": "Sri Meenakshi Government College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "OBC"
  },
  {
    "Name": "Jeppiaar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7.97,
    "Category": "OBC"
  },
  {
    "Name": "Government Arts College, Paramakudi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "OBC"
  },
  {
    "Name": "KIOT - Knowledge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.62,
    "Category": "OBC"
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.5,
    "Category": "OBC"
  },
  {
    "Name": "PSGR Krishnammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "OBC"
  },
  {
    "Name": "SRM Valliammai Engineering College, Chengalpattu",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.13,
    "Category": "OBC"
  },
  {
    "Name": "Panimalar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "OBC"
  },
  {
    "Name": "Anna University - Coimbatore Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "OBC"
  },
  {
    "Name": "Sri Ramakrishna Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "OBC"
  },
  {
    "Name": "Rajalakshmi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "OBC"
  },
  {
    "Name": "KV Institute of Management and Information Studies (KVIMIS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "OBC"
  },
  {
    "Name": "HBS - Hallmark Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "OBC"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.87,
    "Category": "SC"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.68,
    "Category": "SC"
  },
  {
    "Name": "Thiagarajar School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.4,
    "Category": "SC"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "MBA in Waste Management and Social Entrepreneurship",
    "Score": 64.93,
    "Category": "SC"
  },
  {
    "Name": "SRM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "SC"
  },
  {
    "Name": "St.Joseph's College, Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.56,
    "Category": "SC"
  },
  {
    "Name": "Dr. G.R. Damodaran Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 57.17,
    "Category": "SC"
  },
  {
    "Name": "MEASI Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "SC"
  },
  {
    "Name": "Periyar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.67,
    "Category": "SC"
  },
  {
    "Name": "Bishop Heber College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "SC"
  },
  {
    "Name": "University of Madras",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.96,
    "Category": "SC"
  },
  {
    "Name": "D D Goverdhan Doss Vaishnav College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.39,
    "Category": "SC"
  },
  {
    "Name": "M.O.P. Vaishnav College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.67,
    "Category": "SC"
  },
  {
    "Name": "Ethiraj College for Women (Ethiraj)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "SC"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Discontinued (Oct 2021) Master of Business Administration (MBA) (Part Time)",
    "Score": 59.56,
    "Category": "SC"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "MBA in Tourism Management",
    "Score": 58.01,
    "Category": "SC"
  },
  {
    "Name": "Sri Krishna College of Technology [SKCT]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.31,
    "Category": "SC"
  },
  {
    "Name": "Bharathiar University, Coimbatore",
    "Course": "Master of Business Administration (Regular)",
    "Score": 51.79,
    "Category": "SC"
  },
  {
    "Name": "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.82,
    "Category": "SC"
  },
  {
    "Name": "Velammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "SC"
  },
  {
    "Name": "SKCET - Sri Krishna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.94,
    "Category": "SC"
  },
  {
    "Name": "Kumaraguru College of Technology",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 36,
    "Category": "SC"
  },
  {
    "Name": "Coimbatore Institute Of Engineering And Technology - CIET Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "SC"
  },
  {
    "Name": "SRM Easwari Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "SC"
  },
  {
    "Name": "Sri Sairam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "SC"
  },
  {
    "Name": "S.A. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "SC"
  },
  {
    "Name": "HBS - Hallmark Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.89,
    "Category": "SC"
  },
  {
    "Name": "St. Joseph's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "SC"
  },
  {
    "Name": "Anna University - Coimbatore Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "SC"
  },
  {
    "Name": "Auxilium College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "SC"
  },
  {
    "Name": "Panimalar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.94,
    "Category": "SC"
  },
  {
    "Name": "Guru Nanak College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "SC"
  },
  {
    "Name": "SRM Valliammai Engineering College, Chengalpattu",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "SC"
  },
  {
    "Name": "VCFW - Valliammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.86,
    "Category": "SC"
  },
  {
    "Name": "St Xavier's Catholic College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "SC"
  },
  {
    "Name": "University College of Engineering, Bharathidasan Institute of Technology, Tiruchirappalli, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.89,
    "Category": "SC"
  },
  {
    "Name": "Loyola Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "SC"
  },
  {
    "Name": "Rajalakshmi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.56,
    "Category": "SC"
  },
  {
    "Name": "Anna University - Madurai Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "SC"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "SC"
  },
  {
    "Name": "KV Institute of Management and Information",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7.97,
    "Category": "SC"
  },
  {
    "Name": "Muthurangam Government Arts College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.39,
    "Category": "SC"
  },
  {
    "Name": "Jeppiaar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "SC"
  },
  {
    "Name": "Sri Venkateswara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.14,
    "Category": "SC"
  },
  {
    "Name": "University of Madras",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94.65,
    "Category": "ST"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.01,
    "Category": "ST"
  },
  {
    "Name": "Velammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "ST"
  },
  {
    "Name": "Thiagarajar School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.67,
    "Category": "ST"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.67,
    "Category": "ST"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40,
    "Category": "ST"
  },
  {
    "Name": "Kumaraguru College of Technology",
    "Course": "MBA in Innovation, Entrepreneurship and Venture",
    "Score": 29.67,
    "Category": "ST"
  },
  {
    "Name": "KV Institute of Management and Information",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.62,
    "Category": "ST"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.49,
    "Category": "PWD"
  },
  {
    "Name": "University of Madras",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.07,
    "Category": "PWD"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "MBA in Tourism Management",
    "Score": 94.37,
    "Category": "PWD"
  },
  {
    "Name": "Thiagarajar School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.76,
    "Category": "PWD"
  },
  {
    "Name": "Erode Sengunthar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.97,
    "Category": "PWD"
  },
  {
    "Name": "Bharathiar University, Coimbatore",
    "Course": "Master of Business Administration (Regular)",
    "Score": 87.68,
    "Category": "PWD"
  },
  {
    "Name": "Kumaraguru College of Technology",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 85.51,
    "Category": "PWD"
  },
  {
    "Name": "St.Joseph's College, Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.26,
    "Category": "PWD"
  },
  {
    "Name": "SRM Easwari Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.62,
    "Category": "PWD"
  },
  {
    "Name": "SRM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.01,
    "Category": "PWD"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.01,
    "Category": "PWD"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "MBA in Waste Management and Social Entrepreneurship",
    "Score": 96.35,
    "Category": "PWD"
  },
  {
    "Name": "M.O.P. Vaishnav College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.94,
    "Category": "PWD"
  },
  {
    "Name": "Indira Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.83,
    "Category": "PWD"
  },
  {
    "Name": "D D Goverdhan Doss Vaishnav College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.97,
    "Category": "PWD"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Discontinued (Oct 2021) Master of Business Administration (MBA) (Part Time)",
    "Score": 87.68,
    "Category": "PWD"
  },
  {
    "Name": "SKCET - Sri Krishna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.93,
    "Category": "PWD"
  },
  {
    "Name": "Ethiraj College for Women (Ethiraj)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.13,
    "Category": "PWD"
  },
  {
    "Name": "Nesamony Memorial Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.01,
    "Category": "PWD"
  },
  {
    "Name": "Dr. G.R. Damodaran Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 78.48,
    "Category": "PWD"
  },
  {
    "Name": "Bannari Amman Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.67,
    "Category": "PWD"
  },
  {
    "Name": "Velammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "PWD"
  },
  {
    "Name": "Sri Balaji Chockalingam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "PWD"
  },
  {
    "Name": "Jayalakshmi Institute of Technology (JIT Dharmapuri)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "PWD"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in Infrastructure Management",
    "Score": 70.4,
    "Category": "PWD"
  },
  {
    "Name": "St.Xavier's College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "PWD"
  },
  {
    "Name": "Meenakshi Sundararajan School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "PWD"
  },
  {
    "Name": "St. Joseph's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.05,
    "Category": "PWD"
  },
  {
    "Name": "MEASI Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "PWD"
  },
  {
    "Name": "Anna University - Coimbatore Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.93,
    "Category": "PWD"
  },
  {
    "Name": "St Xavier's Catholic College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.67,
    "Category": "PWD"
  },
  {
    "Name": "SRM Valliammai Engineering College, Chengalpattu",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "PWD"
  },
  {
    "Name": "MEC - Muthiammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "PWD"
  },
  {
    "Name": "Sri Krishna College of Technology [SKCT]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "PWD"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.4,
    "Category": "PWD"
  },
  {
    "Name": "Rajalakshmi Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "PWD"
  },
  {
    "Name": "KEC - Kongu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.05,
    "Category": "PWD"
  },
  {
    "Name": "Panimalar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.67,
    "Category": "PWD"
  },
  {
    "Name": "Guru Nanak College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "PWD"
  },
  {
    "Name": "Periyar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "PWD"
  },
  {
    "Name": "Coimbatore Institute Of Engineering And Technology - CIET Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "PWD"
  },
  {
    "Name": "Coimbatore Institute of Management and Technology (CIMAT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "PWD"
  },
  {
    "Name": "The American College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "PWD"
  },
  {
    "Name": "PSGR Krishnammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.56,
    "Category": "PWD"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in International Business Management",
    "Score": 57.17,
    "Category": "PWD"
  },
  {
    "Name": "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "PWD"
  },
  {
    "Name": "Udaya School Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.68,
    "Category": "PWD"
  },
  {
    "Name": "Nehru College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "PWD"
  },
  {
    "Name": "Maria College of Engineering and Technology (MCET, Kanyakumari)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.67,
    "Category": "PWD"
  },
  {
    "Name": "Auxilium College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "PWD"
  },
  {
    "Name": "Jerusalem College of Engineering, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "PWD"
  },
  {
    "Name": "Sri Ramakrishna Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "PWD"
  },
  {
    "Name": "PSN College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "PWD"
  },
  {
    "Name": "Bishop Heber College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.01,
    "Category": "PWD"
  },
  {
    "Name": "Loyola Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "PWD"
  },
  {
    "Name": "Sri Sairam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "PWD"
  },
  {
    "Name": "Francis Xavier Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "PWD"
  },
  {
    "Name": "Saveetha Engineering College, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.67,
    "Category": "PWD"
  },
  {
    "Name": "Alpha College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.85,
    "Category": "PWD"
  },
  {
    "Name": "Jeppiaar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "PWD"
  },
  {
    "Name": "Tamilnadu College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "PWD"
  },
  {
    "Name": "VCFW - Valliammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "PWD"
  },
  {
    "Name": "St. Peter's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.06,
    "Category": "PWD"
  },
  {
    "Name": "MAM College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.94,
    "Category": "PWD"
  },
  {
    "Name": "Dhaanish Ahmed College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "PWD"
  },
  {
    "Name": "Happy Valley Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "PWD"
  },
  {
    "Name": "Dr. N.G.P. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "PWD"
  },
  {
    "Name": "Hindusthan College of Arts and Science [HICAS]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "PWD"
  },
  {
    "Name": "Vivekanandha Institute of Information and Management Studies, Vivekanandha Educational Institutions for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "PWD"
  },
  {
    "Name": "NEC - Nandha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "PWD"
  },
  {
    "Name": "M.Kumarasamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "PWD"
  },
  {
    "Name": "University College of Engineering, Bharathidasan Institute of Technology, Tiruchirappalli, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "PWD"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in Business Analytics",
    "Score": 40.96,
    "Category": "PWD"
  },
  {
    "Name": "C. Abdul Hakeem College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38,
    "Category": "PWD"
  },
  {
    "Name": "JCE - Jaya Engineering College Thiruninravur Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "PWD"
  },
  {
    "Name": "CK College Of Engineering And Technology (CKCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "PWD"
  },
  {
    "Name": "SCAD College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "PWD"
  },
  {
    "Name": "Mailam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "PWD"
  },
  {
    "Name": "Velalar College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "PWD"
  },
  {
    "Name": "Kongu Arts And Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "PWD"
  },
  {
    "Name": "Arunai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "PWD"
  },
  {
    "Name": "PSNA College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "PWD"
  },
  {
    "Name": "SSB - Sri Ganesh School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "PWD"
  },
  {
    "Name": "Nehru Institute of Technology - NIT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "PWD"
  },
  {
    "Name": "Jamal Mohmed College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.86,
    "Category": "PWD"
  },
  {
    "Name": "Mohamed Sathak College of Arts and Science - MSCAS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "PWD"
  },
  {
    "Name": "Tagore Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "PWD"
  },
  {
    "Name": "IFET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "PWD"
  },
  {
    "Name": "Sri Ramakrishna College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "PWD"
  },
  {
    "Name": "J.J. College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "PWD"
  },
  {
    "Name": "S.A. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.44,
    "Category": "PWD"
  },
  {
    "Name": "KV Institute of Management and Information Studies (KVIMIS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "PWD"
  },
  {
    "Name": "College of Business Management,Krishnagiri",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "PWD"
  },
  {
    "Name": "Nehru Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "PWD"
  },
  {
    "Name": "Sri Ramanujar Engineering College (SREC, Kolapakkam)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.01,
    "Category": "PWD"
  },
  {
    "Name": "Madha Group of Academic Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "PWD"
  },
  {
    "Name": "HBS - Hallmark Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "PWD"
  },
  {
    "Name": "Info Institute of Engineering - IIE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "PWD"
  },
  {
    "Name": "Tagore Institute of Engineering and Technology - TIET Salem",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "PWD"
  },
  {
    "Name": "Kingston Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.94,
    "Category": "PWD"
  },
  {
    "Name": "Ponjesly College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.94,
    "Category": "PWD"
  },
  {
    "Name": "Mother Teresa Women'S University - MTWU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "PWD"
  },
  {
    "Name": "SNS College of Technology, SNS Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "PWD"
  },
  {
    "Name": "NIMS - Nehru Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "PWD"
  },
  {
    "Name": "Sona College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.89,
    "Category": "PWD"
  },
  {
    "Name": "P S R Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "PWD"
  },
  {
    "Name": "AVC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.55,
    "Category": "PWD"
  },
  {
    "Name": "P.S.V. College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "PWD"
  },
  {
    "Name": "Sri Meenakshi Government College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "PWD"
  },
  {
    "Name": "Government Arts College, Paramakudi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "PWD"
  },
  {
    "Name": "Hindusthan College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "PWD"
  },
  {
    "Name": "SRIT - Sri Ramakrishna Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "PWD"
  },
  {
    "Name": "Er. Perumal Manimekalai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "PWD"
  },
  {
    "Name": "SNS College of Engineering, SNS Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "PWD"
  },
  {
    "Name": "St. Joseph College of Engineering, Sriperumbudur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "PWD"
  },
  {
    "Name": "Sacred Heart College, Tirupattur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "PWD"
  },
  {
    "Name": "Anna University - Madurai Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.55,
    "Category": "PWD"
  },
  {
    "Name": "Saranathan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "PWD"
  },
  {
    "Name": "K.L.N. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "PWD"
  },
  {
    "Name": "Vivekananda Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.56,
    "Category": "PWD"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 16.03,
    "Category": "PWD"
  },
  {
    "Name": "CSI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "PWD"
  },
  {
    "Name": "Gnanam School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "PWD"
  },
  {
    "Name": "Annamalai University",
    "Course": "MBA in Financial Management",
    "Score": 13.67,
    "Category": "PWD"
  },
  {
    "Name": "E.G.S. Pillay Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "PWD"
  },
  {
    "Name": "Adithya Institute of Technology - AIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "PWD"
  },
  {
    "Name": "Karpagam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "PWD"
  },
  {
    "Name": "Annamalai University",
    "Course": "Master of Business Administration (MBA Dual Specialization)",
    "Score": 10.36,
    "Category": "PWD"
  },
  {
    "Name": "Kalaignar Karunanidhi Institute of Technology - KKIT",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "PWD"
  },
  {
    "Name": "Sree Narayana Guru Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "PWD"
  },
  {
    "Name": "Dhanalakshmi Srinivasan College Of Engineering And Technology - DSCET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.03,
    "Category": "PWD"
  },
  {
    "Name": "Misrimal Navajee Munoth Jain Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "PWD"
  },
  {
    "Name": "Selvam Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "PWD"
  },
  {
    "Name": "Muthurangam Government Arts College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "PWD"
  },
  {
    "Name": "St. Josephs College of Engineering and Technology, Thanjavur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "PWD"
  },
  {
    "Name": "Ganadipathy Tulsi's Jain Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "PWD"
  },
  {
    "Name": "Rathinam Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.27,
    "Category": "PWD"
  },
  {
    "Name": "Sri Sai Ram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "PWD"
  },
  {
    "Name": "Natesan Institute of Co-Operative Management",
    "Course": "MBA in Human Resource Management",
    "Score": 9.15,
    "Category": "PWD"
  },
  {
    "Name": "G R Damodaran Academy of Management (GRDAM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "PWD"
  },
  {
    "Name": "NPR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "PWD"
  },
  {
    "Name": "Anand Institute of Higher Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "PWD"
  },
  {
    "Name": "Anna University - Tirunelveli Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "PWD"
  },
  {
    "Name": "KIOT - Knowledge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "PWD"
  },
  {
    "Name": "Chettinad College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "PWD"
  },
  {
    "Name": "Akshaya institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "PWD"
  },
  {
    "Name": "MCE - Meenakshi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "PWD"
  },
  {
    "Name": "Thirumalai Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "PWD"
  },
  {
    "Name": "Erode Builder Educational Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.91,
    "Category": "PWD"
  },
  {
    "Name": "DSIT - Dhanalakshmi Srinivasan Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "PWD"
  },
  {
    "Name": "Mepco Schlenk Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "PWD"
  },
  {
    "Name": "Dhanalakshmi Srinivasan Engineering College - DSEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "PWD"
  },
  {
    "Name": "Amrita College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "PWD"
  },
  {
    "Name": "KRCE - K Ramakrishnan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "PWD"
  },
  {
    "Name": "Excel Engineering College, Excel Group Institutions",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 6.8,
    "Category": "PWD"
  },
  {
    "Name": "Sri Vidya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.51,
    "Category": "PWD"
  },
  {
    "Name": "GRT Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "PWD"
  },
  {
    "Name": "RVS Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.91,
    "Category": "PWD"
  },
  {
    "Name": "M.A.M. Business School,Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "PWD"
  },
  {
    "Name": "Adhiparasakthi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "PWD"
  },
  {
    "Name": "Sakthi Institute of Information and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "PWD"
  },
  {
    "Name": "A.R.J College of Engineering and Technology (ARJCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "PWD"
  },
  {
    "Name": "Shrimati Indira Gandhi College (SIGC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.36,
    "Category": "PWD"
  },
  {
    "Name": "Nalanda School of Business",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "PWD"
  },
  {
    "Name": "AVS Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.51,
    "Category": "PWD"
  },
  {
    "Name": "Priyadarshini Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "PWD"
  },
  {
    "Name": "M.A.M College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "PWD"
  },
  {
    "Name": "M.I.E.T. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.3,
    "Category": "PWD"
  },
  {
    "Name": "Thangavelu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "PWD"
  },
  {
    "Name": "Vijay Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.62,
    "Category": "PWD"
  },
  {
    "Name": "Sri Muthukumaran Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.36,
    "Category": "PWD"
  },
  {
    "Name": "Adhiyamaan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "PWD"
  },
  {
    "Name": "Karpaga Vinayaga College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.43,
    "Category": "PWD"
  },
  {
    "Name": "Sri Venkateswara College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "PWD"
  },
  {
    "Name": "Madurai School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.5,
    "Category": "PWD"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.49,
    "Category": "EWS"
  },
  {
    "Name": "University of Madras",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.07,
    "Category": "EWS"
  },
  {
    "Name": "College of Engineering, Anna University",
    "Course": "MBA in Tourism Management",
    "Score": 94.37,
    "Category": "EWS"
  },
  {
    "Name": "Thiagarajar School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.76,
    "Category": "EWS"
  },
  {
    "Name": "Erode Sengunthar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.97,
    "Category": "EWS"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Discontinued (Oct 2021) Master of Business Administration (MBA) (Part Time)",
    "Score": 87.68,
    "Category": "EWS"
  },
  {
    "Name": "Kumaraguru College of Technology",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 85.51,
    "Category": "EWS"
  },
  {
    "Name": "St.Joseph's College, Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.26,
    "Category": "EWS"
  },
  {
    "Name": "SRM Easwari Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.62,
    "Category": "EWS"
  },
  {
    "Name": "SRM Arts and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.01,
    "Category": "EWS"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.01,
    "Category": "EWS"
  },
  {
    "Name": "PSGCT Coimbatore",
    "Course": "MBA in Waste Management and Social Entrepreneurship",
    "Score": 96.35,
    "Category": "EWS"
  },
  {
    "Name": "M.O.P. Vaishnav College for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.94,
    "Category": "EWS"
  },
  {
    "Name": "Indira Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.83,
    "Category": "EWS"
  },
  {
    "Name": "D D Goverdhan Doss Vaishnav College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.97,
    "Category": "EWS"
  },
  {
    "Name": "Bharathiar University, Coimbatore",
    "Course": "Master of Business Administration (Regular)",
    "Score": 87.68,
    "Category": "EWS"
  },
  {
    "Name": "SKCET - Sri Krishna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.93,
    "Category": "EWS"
  },
  {
    "Name": "Ethiraj College for Women (Ethiraj)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.13,
    "Category": "EWS"
  },
  {
    "Name": "Nesamony Memorial Christian College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.01,
    "Category": "EWS"
  },
  {
    "Name": "Dr. G.R. Damodaran Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 78.48,
    "Category": "EWS"
  },
  {
    "Name": "Bannari Amman Institute of Technology",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 76.67,
    "Category": "EWS"
  },
  {
    "Name": "Velammal Engineering College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "EWS"
  },
  {
    "Name": "SRM Valliammai Engineering College, Chengalpattu",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "EWS"
  },
  {
    "Name": "Jayalakshmi Institute of Technology (JIT Dharmapuri)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "EWS"
  },
  {
    "Name": "Bharathidasan Institute of Management, Trichy (BIM, Trichy)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 70.4,
    "Category": "EWS"
  },
  {
    "Name": "Rajalakshmi Engineering College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "EWS"
  },
  {
    "Name": "St.Xavier's College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph's College of Engineering",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 68.05,
    "Category": "EWS"
  },
  {
    "Name": "Guru Nanak College",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "EWS"
  },
  {
    "Name": "Anna University - Coimbatore Regional Campus",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 64.93,
    "Category": "EWS"
  },
  {
    "Name": "St Xavier's Catholic College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.67,
    "Category": "EWS"
  },
  {
    "Name": "Sri Balaji Chockalingam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.09,
    "Category": "EWS"
  },
  {
    "Name": "MEC - Muthiammal Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "EWS"
  },
  {
    "Name": "Sri Krishna College of Technology [SKCT]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.53,
    "Category": "EWS"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in Infrastructure Management",
    "Score": 70.4,
    "Category": "EWS"
  },
  {
    "Name": "Meenakshi Sundararajan School of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 69.59,
    "Category": "EWS"
  },
  {
    "Name": "KEC - Kongu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.05,
    "Category": "EWS"
  },
  {
    "Name": "Panimalar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.67,
    "Category": "EWS"
  },
  {
    "Name": "MEASI Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "EWS"
  },
  {
    "Name": "Coimbatore Institute Of Engineering And Technology - CIET Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "EWS"
  },
  {
    "Name": "Periyar University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64,
    "Category": "EWS"
  },
  {
    "Name": "Jerusalem College of Engineering, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "EWS"
  },
  {
    "Name": "The American College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "EWS"
  },
  {
    "Name": "PSGR Krishnammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.56,
    "Category": "EWS"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in International Business Management",
    "Score": 57.17,
    "Category": "EWS"
  },
  {
    "Name": "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "EWS"
  },
  {
    "Name": "Udaya School Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.68,
    "Category": "EWS"
  },
  {
    "Name": "Francis Xavier Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "EWS"
  },
  {
    "Name": "Maria College of Engineering and Technology (MCET, Kanyakumari)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.67,
    "Category": "EWS"
  },
  {
    "Name": "Tamilnadu College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "EWS"
  },
  {
    "Name": "Coimbatore Institute of Management and Technology (CIMAT)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.39,
    "Category": "EWS"
  },
  {
    "Name": "Sri Ramakrishna Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "EWS"
  },
  {
    "Name": "PSN College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.57,
    "Category": "EWS"
  },
  {
    "Name": "Bishop Heber College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.01,
    "Category": "EWS"
  },
  {
    "Name": "Sri Sairam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "EWS"
  },
  {
    "Name": "Loyola Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.54,
    "Category": "EWS"
  },
  {
    "Name": "Nehru College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 51.79,
    "Category": "EWS"
  },
  {
    "Name": "Saveetha Engineering College, Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.67,
    "Category": "EWS"
  },
  {
    "Name": "Alpha College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.85,
    "Category": "EWS"
  },
  {
    "Name": "Auxilium College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "EWS"
  },
  {
    "Name": "Jeppiaar Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "EWS"
  },
  {
    "Name": "M.Kumarasamy College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "EWS"
  },
  {
    "Name": "St. Peter's College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.06,
    "Category": "EWS"
  },
  {
    "Name": "MAM College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.94,
    "Category": "EWS"
  },
  {
    "Name": "JCE - Jaya Engineering College Thiruninravur Chennai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "EWS"
  },
  {
    "Name": "Dhaanish Ahmed College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "EWS"
  },
  {
    "Name": "CK College Of Engineering And Technology (CKCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "EWS"
  },
  {
    "Name": "SCAD College Of Engineering And Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "EWS"
  },
  {
    "Name": "Vivekanandha Institute of Information and Management Studies, Vivekanandha Educational Institutions for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "EWS"
  },
  {
    "Name": "NEC - Nandha Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "EWS"
  },
  {
    "Name": "University College of Engineering, Bharathidasan Institute of Technology, Tiruchirappalli, Anna University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "EWS"
  },
  {
    "Name": "VCFW - Valliammal College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.82,
    "Category": "EWS"
  },
  {
    "Name": "Annamalai University",
    "Course": "Discontinued (Jul 2023)- MBA in Business Analytics",
    "Score": 40.96,
    "Category": "EWS"
  },
  {
    "Name": "C. Abdul Hakeem College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38,
    "Category": "EWS"
  },
  {
    "Name": "Happy Valley Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.03,
    "Category": "EWS"
  },
  {
    "Name": "Dr. N.G.P. Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36,
    "Category": "EWS"
  },
  {
    "Name": "Mailam Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "EWS"
  },
  {
    "Name": "Hindusthan College of Arts and Science [HICAS]",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.27,
    "Category": "EWS"
  },
  {
    "Name": "Velalar College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.36,
    "Category": "EWS"
  },
  {
    "Name": "Kongu Arts And Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "EWS"
  },
  {
    "Name": "Arunai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.45,
    "Category": "EWS"
  },
  {
    "Name": "Nehru Institute of Information Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "EWS"
  },
  {
    "Name": "KV Institute of Management and Information Studies (KVIMIS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "EWS"
  },
  {
    "Name": "College of Business Management,Krishnagiri",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "EWS"
  },
  {
    "Name": "Jamal Mohmed College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.86,
    "Category": "EWS"
  },
  {
    "Name": "Madha Group of Academic Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "EWS"
  },
  {
    "Name": "Mohamed Sathak College of Arts and Science - MSCAS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "EWS"
  },
  {
    "Name": "Sri Ramakrishna College of Arts and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "EWS"
  },
  {
    "Name": "J.J. College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "EWS"
  },
  {
    "Name": "IFET College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "EWS"
  },
  {
    "Name": "S.A. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.44,
    "Category": "EWS"
  },
  {
    "Name": "PSNA College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "EWS"
  },
  {
    "Name": "SSB - Sri Ganesh School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "EWS"
  },
  {
    "Name": "Nehru Institute of Technology - NIT Coimbatore",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.66,
    "Category": "EWS"
  },
  {
    "Name": "Sri Ramanujar Engineering College (SREC, Kolapakkam)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.01,
    "Category": "EWS"
  },
  {
    "Name": "Tagore Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "EWS"
  },
  {
    "Name": "Info Institute of Engineering - IIE",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "EWS"
  },
  {
    "Name": "Tagore Institute of Engineering and Technology - TIET Salem",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "EWS"
  },
  {
    "Name": "HBS - Hallmark Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.37,
    "Category": "EWS"
  },
  {
    "Name": "Kingston Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.94,
    "Category": "EWS"
  },
  {
    "Name": "Ponjesly College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.94,
    "Category": "EWS"
  },
  {
    "Name": "SNS College of Technology, SNS Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "EWS"
  },
  {
    "Name": "Mother Teresa Women'S University - MTWU",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "EWS"
  },
  {
    "Name": "NIMS - Nehru Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "EWS"
  },
  {
    "Name": "Sona College of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.89,
    "Category": "EWS"
  },
  {
    "Name": "St. Joseph College of Engineering, Sriperumbudur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "EWS"
  },
  {
    "Name": "AVC College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.55,
    "Category": "EWS"
  },
  {
    "Name": "Saranathan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "EWS"
  },
  {
    "Name": "P.S.V. College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "EWS"
  },
  {
    "Name": "Government Arts College, Paramakudi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "EWS"
  },
  {
    "Name": "SRIT - Sri Ramakrishna Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "EWS"
  },
  {
    "Name": "Hindusthan College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "EWS"
  },
  {
    "Name": "Er. Perumal Manimekalai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "EWS"
  },
  {
    "Name": "SNS College of Engineering, SNS Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.56,
    "Category": "EWS"
  },
  {
    "Name": "Sacred Heart College, Tirupattur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "EWS"
  },
  {
    "Name": "P S R Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.24,
    "Category": "EWS"
  },
  {
    "Name": "Anna University - Madurai Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.55,
    "Category": "EWS"
  },
  {
    "Name": "Sri Meenakshi Government College For Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.73,
    "Category": "EWS"
  },
  {
    "Name": "K.L.N. College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.15,
    "Category": "EWS"
  },
  {
    "Name": "Vivekananda Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.56,
    "Category": "EWS"
  },
  {
    "Name": "Dhanalakshmi Srinivasan College Of Engineering And Technology - DSCET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.03,
    "Category": "EWS"
  },
  {
    "Name": "Gnanam School of Business",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "EWS"
  },
  {
    "Name": "Misrimal Navajee Munoth Jain Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "EWS"
  },
  {
    "Name": "Muthurangam Government Arts College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "EWS"
  },
  {
    "Name": "St. Josephs College of Engineering and Technology, Thanjavur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "EWS"
  },
  {
    "Name": "Karpagam College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "EWS"
  },
  {
    "Name": "Ganadipathy Tulsi's Jain Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "EWS"
  },
  {
    "Name": "Annamalai University",
    "Course": "Master of Business Administration (MBA Dual Specialization)",
    "Score": 10.36,
    "Category": "EWS"
  },
  {
    "Name": "Sree Narayana Guru Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "EWS"
  },
  {
    "Name": "Sri Sai Ram Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "EWS"
  },
  {
    "Name": "Hindustan Institute of Technology and Science",
    "Course": "MBA in Finance, Marketing and HR",
    "Score": 16.03,
    "Category": "EWS"
  },
  {
    "Name": "CSI Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.64,
    "Category": "EWS"
  },
  {
    "Name": "Annamalai University",
    "Course": "MBA in Financial Management",
    "Score": 13.67,
    "Category": "EWS"
  },
  {
    "Name": "Selvam Educational Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.67,
    "Category": "EWS"
  },
  {
    "Name": "E.G.S. Pillay Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.1,
    "Category": "EWS"
  },
  {
    "Name": "Adithya Institute of Technology - AIET",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.68,
    "Category": "EWS"
  },
  {
    "Name": "Rathinam Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.27,
    "Category": "EWS"
  },
  {
    "Name": "Natesan Institute of Co-Operative Management",
    "Course": "MBA in Human Resource Management",
    "Score": 9.15,
    "Category": "EWS"
  },
  {
    "Name": "Kalaignar Karunanidhi Institute of Technology - KKIT",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.15,
    "Category": "EWS"
  },
  {
    "Name": "Mepco Schlenk Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "EWS"
  },
  {
    "Name": "G R Damodaran Academy of Management (GRDAM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "EWS"
  },
  {
    "Name": "Dhanalakshmi Srinivasan Engineering College - DSEC",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "EWS"
  },
  {
    "Name": "Anand Institute of Higher Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "EWS"
  },
  {
    "Name": "Amrita College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "EWS"
  },
  {
    "Name": "KRCE - K Ramakrishnan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "EWS"
  },
  {
    "Name": "Excel Engineering College, Excel Group Institutions",
    "Course": "MBA in Innovation, Entrepreneurship and Venture Development",
    "Score": 6.8,
    "Category": "EWS"
  },
  {
    "Name": "GRT Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "EWS"
  },
  {
    "Name": "MCE - Meenakshi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "EWS"
  },
  {
    "Name": "Erode Builder Educational Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.91,
    "Category": "EWS"
  },
  {
    "Name": "Adhiparasakthi College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "EWS"
  },
  {
    "Name": "NPR College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.85,
    "Category": "EWS"
  },
  {
    "Name": "Anna University - Tirunelveli Regional Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.43,
    "Category": "EWS"
  },
  {
    "Name": "Akshaya institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "EWS"
  },
  {
    "Name": "KIOT - Knowledge Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "EWS"
  },
  {
    "Name": "Chettinad College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.8,
    "Category": "EWS"
  },
  {
    "Name": "Sri Vidya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.51,
    "Category": "EWS"
  },
  {
    "Name": "Thirumalai Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "EWS"
  },
  {
    "Name": "RVS Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.91,
    "Category": "EWS"
  },
  {
    "Name": "Sakthi Institute of Information and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "EWS"
  },
  {
    "Name": "M.A.M. Business School,Tiruchirappalli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "EWS"
  },
  {
    "Name": "DSIT - Dhanalakshmi Srinivasan Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.7,
    "Category": "EWS"
  },
  {
    "Name": "Thangavelu Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "EWS"
  },
  {
    "Name": "Sri Muthukumaran Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.36,
    "Category": "EWS"
  },
  {
    "Name": "Nalanda School of Business",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "EWS"
  },
  {
    "Name": "AVS Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.51,
    "Category": "EWS"
  },
  {
    "Name": "M.A.M College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "EWS"
  },
  {
    "Name": "Sri Venkateswara College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "EWS"
  },
  {
    "Name": "M.I.E.T. Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.3,
    "Category": "EWS"
  },
  {
    "Name": "A.R.J College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.54,
    "Category": "EWS"
  },
  {
    "Name": "Vijay Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.62,
    "Category": "EWS"
  },
  {
    "Name": "Shrimati Indira Gandhi College (SIGC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.36,
    "Category": "EWS"
  },
  {
    "Name": "Adhiyamaan College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.6,
    "Category": "EWS"
  },
  {
    "Name": "Karpaga Vinayaga College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.43,
    "Category": "EWS"
  },
  {
    "Name": "Priyadarshini Engineering College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.87,
    "Category": "EWS"
  },
  {
    "Name": "Madurai School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.5,
    "Category": "EWS"
  }
]