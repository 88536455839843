import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography, List, CircularProgress, Paper } from "@mui/material";

const CollegeExplorer = () => {
  const { city } = useParams();
  const navigate = useNavigate();
  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [city]); // Dependency on 'city' ensures it scrolls when city changes

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await fetch(
          `https://caderaedu.com/api/college/match-city`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ city }),
          }
        );
        const data = await response.json();
        if (data.success) {
          setColleges(data.colleges);
        } else {
          setError("No colleges found for this city.");
        }
      } catch (err) {
        setError("Failed to load colleges.");
      } finally {
        setLoading(false);
      }
    };

    fetchColleges();
  }, [city]);

  const generateSlug = (name) => name.toLowerCase().replace(/[^a-z]/g, "");
  const handleCollegeClick = (collegeName) => {
    const slug = generateSlug(collegeName);
    navigate(`/college/${slug}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={60} sx={{ color: "#1976d2" }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        variant="h6"
        color="error"
        sx={{
          textAlign: "center",
          marginTop: "20px",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "900px",
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
          textAlign: "center",
          color: "#000",
          fontFamily: "'Playfair Display', serif",
        }}
      >
        Discover Colleges in {city}
      </Typography>
      {colleges.length > 0 ? (
        <List>
          {colleges.map((college, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{
                marginBottom: "15px",
                padding: "15px",
                borderRadius: "10px",
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#f0f8ff",
                },
              }}
              onClick={() => handleCollegeClick(college.name)}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#1976d2",
                  fontFamily: "'Roboto', sans-serif",
                }}
              >
                {college.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#666", marginTop: "5px" }}
              >
                Click to explore more about {college.name}.
              </Typography>
            </Paper>
          ))}
        </List>
      ) : (
        <Typography
          variant="body1"
          sx={{
            color: "#555",
            textAlign: "center",
            fontStyle: "italic",
            fontFamily: "'Roboto', sans-serif",
          }}
        >
          No colleges available for this city.
        </Typography>
      )}
    </Box>
  );
};

export default CollegeExplorer;
