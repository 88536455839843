import React from 'react';
import { Handshake, Trophy, GraduationCap, BookOpen } from 'lucide-react';

const WhyChooseUs = () => {
  const benefits = [
    {
      icon: <Handshake className="w-12 h-12 text-blue-500" />,
      title: '750+',
      description: 'University Partners'
    },
    {
      icon: <Trophy className="w-12 h-12 text-yellow-500" />,
      title: '45,000+',
      description: 'Success Stories'
    },
    {
      icon: <GraduationCap className="w-12 h-12 text-purple-500" />,
      title: 'Scholarship up to 50%*',
      description: 'in admissions',
    },
    {
      icon: <BookOpen className="w-12 h-12 text-green-500" />,
      title: 'Courses starting at ₹8 Lakhs*',
      description: ''
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
          Why Choose <span className="text-blue-500">CaderaEdu?</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-6 rounded-lg hover:shadow-lg transition-shadow"
            >
              <div className="mb-4">{benefit.icon}</div>
              <h3 className="text-xl font-bold mb-2">{benefit.title}</h3>
              {benefit.description && (
                <p className="text-gray-600">{benefit.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;