import React from "react";

const testimonials = [
  {
    quote: 
      "Joining CaderaEdu was a transformative experience. Their exceptional counseling provided me with clear guidance and helped me make informed decisions about my future. It has been a key milestone in my career journey.",
    name: "Sushet Gautam"
  },
  {
    quote: 
      "CaderaEdu’s flexible and personalized counseling approach allowed me to explore opportunities that matched my interests. Their counselors were always supportive, making the process stress-free and empowering.",
    name: "Nirupam Sakar"
  },
  {
    quote: 
      "I chose CaderaEdu because of their exceptional counselors who guided me towards the right path in my career. Their expertise and personalized approach in understanding my goals were unparalleled. I’m grateful for their support and insights.",
    name: "Bala Jaswanth"
  },
];

const Testimonials = () => {
  return (
    <div className="p-10 sm:p-12 bg-gray-200 ">
      <h2 className="text-3xl sm:text-5xl font-extrabold text-center text-gray-800 mb-10">
        Student Testimonials
      </h2>
      {/* Testimonial Container */}
      <div className="flex overflow-x-auto overflow-y-hidden space-x-6 scrollbar-hide lg:justify-center">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-[300px] sm:w-[400px] bg-white p-8 rounded-xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-lg"
          >
            <p className="text-gray-700 italic mb-6">&ldquo;{testimonial.quote}&rdquo;</p>
            <p className="text-indigo-600 font-semibold text-lg">{testimonial.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;