import React, { useState } from "react";
import { Link } from "react-router-dom";

const CollegePredictors = ({
  isDropdownOpen,
  handleDropdownToggle,
  activeSubmenu,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const [hoveredMenu, setHoveredMenu] = useState(null);

  const handleOptionSelect = () => {
    handleDropdownToggle(""); // Closing the dropdown by resetting the open state
  };

  const handleSubmenuHover = (menu) => {
    setHoveredMenu(menu);
  };

  const handleSubmenuLeave = () => {
    setHoveredMenu(null);
  };

  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle("CollegePredictor")}
        className="text-white"
      >
        COLLEGE PREDICTOR {isDropdownOpen === "CollegePredictor" ? "▲" : "▼"}
      </button>
      {isDropdownOpen === "CollegePredictor" && (
        <div
          className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex"
          onMouseLeave={() => handleDropdownToggle("")}
        >
          <div className="w-[150px]">
            <ul className="space-y-2">
              {[
                { label: "Medical", key: "Medical" },
                { label: "B.Tech", key: "B.Tech" },
                { label: "LAW", key: "LAW" },
                { label: "MANAGEMENT", key: "MANAGEMENT" },
                { label: "CollegePredictors", key: "CollegePredictors" },
                { label: "PredictorReviews", key: "PredictorReviews" },
                { label: "PredictorResources", key: "PredictorResources" },
              ].map((item) => (
                <li
                  key={item.key}
                  onMouseEnter={() => handleSubmenuHover(item.key)}
                  className={`flex justify-between items-center text-xs py-2 cursor-pointer transition ${
                    hoveredMenu === item.key ? "bg-gray-200" : ""
                  }`}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>

          {hoveredMenu === "Medical" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleSubmenuHover("Medical")}
            >
              <ul className="space-y-4">
                <li className="text-xs bg-gray-100 px-3 py-2 rounded-md font-semibold transition ease-in-out duration-200 cursor-pointer shadow-md">
                  <Link
                    to="/MedicalPredictor"
                    onClick={handleOptionSelect}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    Medical College Predictors
                  </Link>
                </li>

                {/* Rest of the List Items - Sub-navigation */}
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/NeetUGAllIndiaPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    Neet UG All India
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/NeetPGPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    NEET PG All India
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/UPPgPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    UP PG Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {hoveredMenu === "LAW" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleSubmenuHover("LAW")}
            >
              <ul className="space-y-4">
                {/* First List Item - Parent Navigation */}
                <li className="text-xs bg-gray-100 px-3 py-2 rounded-md font-semibold transition ease-in-out duration-200 cursor-pointer shadow-md">
                  <Link
                    to="/LawPredictor"
                    onClick={handleOptionSelect}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    LAW College Predictors
                  </Link>
                </li>

                {/* Rest of the List Items - Sub-navigation */}
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/ggsipullbpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    GGSIPU LLB Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/ggsipullmpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    GGSIPU LLM Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/nlullbpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    NLU LLB Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/nlullmpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    NLU LLM Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {hoveredMenu === "MANAGEMENT" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleSubmenuHover("MANAGEMENT")}
            >
              <ul className="space-y-4">
                <li className="text-xs bg-gray-100 px-3 py-2 rounded-md font-semibold transition ease-in-out duration-200 cursor-pointer shadow-md">
                  <Link
                    to="/mbapredictor"
                    onClick={handleOptionSelect}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    MBA College Predictors
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/ggsipumbapredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    GGSIPU MBA Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/IIFTPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    IIFT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/NMATPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    NMAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/catpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    CAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/xatpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    XAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/MATPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    MAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/cmatpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    CMAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/snappredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    SNAP Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/upcatpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    UPCAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/cusatpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    CUSAT Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/imucetpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    IMUCET Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/kukentrancepredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    KUK Entrance Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/jmimbapredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    JMI Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/ojeembapredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    OJEE Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/mpmetmbapredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    MPMET Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/davvcetpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    DAVVCET Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/gtumbapredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    GTU MBA Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/gmatpredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    GMAT Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {hoveredMenu === "B.Tech" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleSubmenuHover("B.Tech")}
            >
              <ul className="space-y-4">
                <li className="text-xs bg-gray-100 px-3 py-2 rounded-md font-semibold transition ease-in-out duration-200 cursor-pointer shadow-md">
                  <Link
                    to="/BtechPredictor"
                    onClick={handleOptionSelect}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    Btech College Predictors
                  </Link>
                </li>

                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/jacdelhipredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    JAC DELHI Predictor
                  </Link>
                </li>
                <li className="text-xs hover:bg-gray-50 px-3 py-2 rounded-md transition ease-in-out duration-200 cursor-pointer">
                  <Link
                    to="/JOSAAPredictor"
                    onClick={handleOptionSelect}
                    className="text-gray-600 hover:text-gray-800 font-medium"
                  >
                    JoSAA Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollegePredictors;
