[
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.98,
    "Category": "General"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.89,
    "Category": "General"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "M.B.A. in Business Analytics",
    "Score": 99.68,
    "Category": "General"
  },
  {
    "Name": "Xavier Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.62,
    "Category": "General"
  },
  {
    "Name": "Chetana's Ramprasad Khandelwal Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.26,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 99.9,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Financial Management",
    "Score": 99.9,
    "Category": "General"
  },
  {
    "Name": "Lala Lajpatrai Institute of Management (LLIM)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 97.89,
    "Category": "General"
  },
  {
    "Name": "Dr. D. Y. Patil Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.65,
    "Category": "General"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.49,
    "Category": "General"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 99.95,
    "Category": "General"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.92,
    "Category": "General"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.68,
    "Category": "General"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.2,
    "Category": "General"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.65,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 99.9,
    "Category": "General"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.26,
    "Category": "General"
  },
  {
    "Name": "Rizvi Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.15,
    "Category": "General"
  },
  {
    "Name": "Indira Institute of Management - IIMP",
    "Course": "Master of Business Administration (MBA)",
    "Score": 96.22,
    "Category": "General"
  },
  {
    "Name": "Thakur Institute of Management Studies and Research - TIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98,
    "Category": "General"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Finance",
    "Score": 54.05,
    "Category": "General"
  },
  {
    "Name": "Indira School of Business Studies - ISBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.39,
    "Category": "General"
  },
  {
    "Name": "Thakur College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.96,
    "Category": "General"
  },
  {
    "Name": "Fr. C. Rodrigues Institute of management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 95.14,
    "Category": "General"
  },
  {
    "Name": "GNIMS Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.13,
    "Category": "General"
  },
  {
    "Name": "Bharati Vidyapeeth's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 94.13,
    "Category": "General"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.5,
    "Category": "General"
  },
  {
    "Name": "Shree Chanakya Education Society's Indira College of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.44,
    "Category": "General"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "MBA in Services Management",
    "Score": 92.25,
    "Category": "General"
  },
  {
    "Name": "Neville Wadia Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.82,
    "Category": "General"
  },
  {
    "Name": "University of Mumbai [MU]",
    "Course": "Discontinued (Dec 2023)- Master of Management Studies (MMS)",
    "Score": 97.59,
    "Category": "General"
  },
  {
    "Name": "St. Francis Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.94,
    "Category": "General"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.83,
    "Category": "General"
  },
  {
    "Name": "Dhole Patil College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.29,
    "Category": "General"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94.5,
    "Category": "General"
  },
  {
    "Name": "Durgadevi Saraf Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.05,
    "Category": "General"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.78,
    "Category": "General"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies - JDBIMS",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.76,
    "Category": "General"
  },
  {
    "Name": "Shri Ramdeobaba College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.21,
    "Category": "General"
  },
  {
    "Name": "AIMS - Atharva Institute of Management Studies",
    "Course": "Master of Management Studies (M.M.S.)",
    "Score": 91.39,
    "Category": "General"
  },
  {
    "Name": "Institute Of Management and Science,Sakegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.29,
    "Category": "General"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.02,
    "Category": "General"
  },
  {
    "Name": "Guru Nanak Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.94,
    "Category": "General"
  },
  {
    "Name": "International Institute of Management and Human Resource Development, Sai Balaji Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.38,
    "Category": "General"
  },
  {
    "Name": "DY Patil Akurdi MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "General"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "M.B.A. in Services Management",
    "Score": 88.54,
    "Category": "General"
  },
  {
    "Name": "Sinhgad College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 88.41,
    "Category": "General"
  },
  {
    "Name": "D. Y. Patil Institute of Master of Computer Applications and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 87.82,
    "Category": "General"
  },
  {
    "Name": "St. John College of Engineering & Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.43,
    "Category": "General"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "MBA in Digital Marketing",
    "Score": 86.67,
    "Category": "General"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.12,
    "Category": "General"
  },
  {
    "Name": "Department of Business Management - Nagpur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "General"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 89.89,
    "Category": "General"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.12,
    "Category": "General"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in FinTech",
    "Score": 88.54,
    "Category": "General"
  },
  {
    "Name": "Mumbai Institute of Management and Research (MIMR)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 88.41,
    "Category": "General"
  },
  {
    "Name": "Pankaj Laddhad Institute of Technology and Management Studies, Buldana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 88.02,
    "Category": "General"
  },
  {
    "Name": "Kohinoor Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.65,
    "Category": "General"
  },
  {
    "Name": "Institute of Management and Business Administration, Akole",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.96,
    "Category": "General"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.59,
    "Category": "General"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.55,
    "Category": "General"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.13,
    "Category": "General"
  },
  {
    "Name": "International Centre of Excellence in Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.94,
    "Category": "General"
  },
  {
    "Name": "Institute of Science Poona's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.29,
    "Category": "General"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in Project Management",
    "Score": 83.84,
    "Category": "General"
  },
  {
    "Name": "Institute of Industrial and Computer Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.33,
    "Category": "General"
  },
  {
    "Name": "SIOM - Sinhgad Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.88,
    "Category": "General"
  },
  {
    "Name": "MGM Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.14,
    "Category": "General"
  },
  {
    "Name": "Smt. Kashibai Navale College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 81.64,
    "Category": "General"
  },
  {
    "Name": "All India Shri Shivaji Memorial Society's, Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.88,
    "Category": "General"
  },
  {
    "Name": "SRBS - Sheila Raheja School of Business Management & Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.35,
    "Category": "General"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Finance",
    "Score": 85.93,
    "Category": "General"
  },
  {
    "Name": "S.B Patil Institute of Management, Pimpri Chinchwad Educations Trust's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.89,
    "Category": "General"
  },
  {
    "Name": "Sasmira's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.91,
    "Category": "General"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in information Technology",
    "Score": 83.4,
    "Category": "General"
  },
  {
    "Name": "Dr. V. N. Bedekar Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.07,
    "Category": "General"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 82.75,
    "Category": "General"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Finance",
    "Score": 82.12,
    "Category": "General"
  },
  {
    "Name": "Department of Business Administration and Management, Sant Gadge Baba Amravati University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 81.51,
    "Category": "General"
  },
  {
    "Name": "Rajarshi Shahu College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.88,
    "Category": "General"
  },
  {
    "Name": "Pibm Tirupati Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.94,
    "Category": "General"
  },
  {
    "Name": "Dhananjayrao Gadgil Institute of Co-operative Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.93,
    "Category": "General"
  },
  {
    "Name": "MET's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "General"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.29,
    "Category": "General"
  },
  {
    "Name": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.86,
    "Category": "General"
  },
  {
    "Name": "PR Patil College of Engineering And Technology (PRPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.71,
    "Category": "General"
  },
  {
    "Name": "Jayawant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.39,
    "Category": "General"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in Human Resource Development",
    "Score": 84.04,
    "Category": "General"
  },
  {
    "Name": "NCRDs Sterling Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.34,
    "Category": "General"
  },
  {
    "Name": "OIM - Oriental Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.1,
    "Category": "General"
  },
  {
    "Name": "Maratha Mandirs Babasaheb Gawde Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.76,
    "Category": "General"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research - PIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.69,
    "Category": "General"
  },
  {
    "Name": "International Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.85,
    "Category": "General"
  },
  {
    "Name": "Sir M Visvesvaraya Institute of Management Studies and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 78.24,
    "Category": "General"
  },
  {
    "Name": "Navinchandra Mehta Institute of Technology and Development",
    "Course": "Master of Management Studies (MMS)",
    "Score": 77.77,
    "Category": "General"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.23,
    "Category": "General"
  },
  {
    "Name": "Trinity College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.41,
    "Category": "General"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Management (MIBM Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.77,
    "Category": "General"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Technological University - DBATU",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 74.04,
    "Category": "General"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.18,
    "Category": "General"
  },
  {
    "Name": "P. R. Pote Patil College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.18,
    "Category": "General"
  },
  {
    "Name": "K.C.College of Engineering and Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 71.75,
    "Category": "General"
  },
  {
    "Name": "Natwarlal Maniklal Dalal Postgraduate College of Arts and Commerce (N.M.D College)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71.42,
    "Category": "General"
  },
  {
    "Name": "K. K. Wagh Institute of Engineering Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.85,
    "Category": "General"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.68,
    "Category": "General"
  },
  {
    "Name": "Suryodaya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.41,
    "Category": "General"
  },
  {
    "Name": "Vidyavardhini's College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.31,
    "Category": "General"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 68.4,
    "Category": "General"
  },
  {
    "Name": "Hirachand Nemchand College of Commerce (HNCC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.15,
    "Category": "General"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.08,
    "Category": "General"
  },
  {
    "Name": "ISMR - International School of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.16,
    "Category": "General"
  },
  {
    "Name": "IndSearch Institute of Management Studies and Research, Bavdhan Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71.42,
    "Category": "General"
  },
  {
    "Name": "GNVS Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 71.29,
    "Category": "General"
  },
  {
    "Name": "SKN Sinhgad School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.85,
    "Category": "General"
  },
  {
    "Name": "Ashoka Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.61,
    "Category": "General"
  },
  {
    "Name": "Anjuman-I-Islam's Allana Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.51,
    "Category": "General"
  },
  {
    "Name": "J.D.C. Bytco Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.19,
    "Category": "General"
  },
  {
    "Name": "Progressive Education Society's Modern College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.18,
    "Category": "General"
  },
  {
    "Name": "Marathwada Mitra Mandal's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.15,
    "Category": "General"
  },
  {
    "Name": "MMM's Institute of Management Education Research and Training",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.08,
    "Category": "General"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Finance",
    "Score": 72.16,
    "Category": "General"
  },
  {
    "Name": "G S College of Commerce and Economics",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71.42,
    "Category": "General"
  },
  {
    "Name": "Mahatma Phule Institute of Management and Computer Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71.29,
    "Category": "General"
  },
  {
    "Name": "Sau Leena Kishor Mamidwar Institute of Mananagement Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.85,
    "Category": "General"
  },
  {
    "Name": "SAS Institute Of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 70.61,
    "Category": "General"
  },
  {
    "Name": "Jayawantrao Sawant Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.51,
    "Category": "General"
  },
  {
    "Name": "SIMCA - Sinhgad Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.19,
    "Category": "General"
  },
  {
    "Name": "Chintamanrao Institute of Management Development and Research (CIMDR Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.18,
    "Category": "General"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Human Resource Management",
    "Score": 68.15,
    "Category": "General"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "MBA in Human Resource Management",
    "Score": 68.08,
    "Category": "General"
  },
  {
    "Name": "Indira Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 67.67,
    "Category": "General"
  },
  {
    "Name": "Sadhu Vaswani Institute of Management Studies for Girls (SVIMS Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.05,
    "Category": "General"
  },
  {
    "Name": "MES Institute of Management and Career Courses",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 66.32,
    "Category": "General"
  },
  {
    "Name": "Al Ameen Education & Medical Foundations, College of Engineering & Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "General"
  },
  {
    "Name": "Department of Commerce and Management - MBA Unit, Shivaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.37,
    "Category": "General"
  },
  {
    "Name": "Jayawantrao Sawant College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.22,
    "Category": "General"
  },
  {
    "Name": "Vidya Bharati Mahavidyalaya, Amravati",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "General"
  },
  {
    "Name": "Yeshwantrao Chavan College of Engineering, Nagar Yuwak Shikshan Sanstha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.85,
    "Category": "General"
  },
  {
    "Name": "G H Raisoni Institute of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.81,
    "Category": "General"
  },
  {
    "Name": "Aditya Engineering College, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.65,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.63,
    "Category": "General"
  },
  {
    "Name": "Alard University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.63,
    "Category": "General"
  },
  {
    "Name": "North Maharashtra University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.65,
    "Category": "General"
  },
  {
    "Name": "Prof. Ram Meghe Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.65,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.91,
    "Category": "General"
  },
  {
    "Name": "SIBAR - Sinhgad Institute of Business Administration and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.32,
    "Category": "General"
  },
  {
    "Name": "Pratibha Institute of Business Management (Kamala Education Society's)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.06,
    "Category": "General"
  },
  {
    "Name": "ASM's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.77,
    "Category": "General"
  },
  {
    "Name": "Sinhgad Institute of Business Administration and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.62,
    "Category": "General"
  },
  {
    "Name": "VIVA Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 58.59,
    "Category": "General"
  },
  {
    "Name": "KIT's Institute of Management Education and Research (KIT's IMER)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.63,
    "Category": "General"
  },
  {
    "Name": "Matoshri Ushatai Jadhav Institute of Management Studies and Research Centre (MUIMRC)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.14,
    "Category": "General"
  },
  {
    "Name": "Sankalp Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.65,
    "Category": "General"
  },
  {
    "Name": "Ahirkar Institute of Management Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 62.17,
    "Category": "General"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 61.91,
    "Category": "General"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.07,
    "Category": "General"
  },
  {
    "Name": "S.A.V Acharya Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 59.15,
    "Category": "General"
  },
  {
    "Name": "Nashik District Maratha Vidya Prasarak Samaj's Karmaveer Adv. Baburao Thakare College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.62,
    "Category": "General"
  },
  {
    "Name": "Arihant Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.62,
    "Category": "General"
  },
  {
    "Name": "Sipna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.59,
    "Category": "General"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Agri Business Management/ Entrepreneurship",
    "Score": 58.59,
    "Category": "General"
  },
  {
    "Name": "NBN Sinhgad School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.48,
    "Category": "General"
  },
  {
    "Name": "Institute of Business Management & Research,Chakan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 57.95,
    "Category": "General"
  },
  {
    "Name": "Shri Shivaji Maratha Societys Institute of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 57.43,
    "Category": "General"
  },
  {
    "Name": "Dr.D.Y.Patil Institute Of Management & Entrepreneur Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 57.35,
    "Category": "General"
  },
  {
    "Name": "Tirpude Institute of Management Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.1,
    "Category": "General"
  },
  {
    "Name": "Tirupati Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "General"
  },
  {
    "Name": "Deshbhakt Ratnappa Kumbhar College of Commerce (DRKCC, Kolhapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.88,
    "Category": "General"
  },
  {
    "Name": "Shri Hanuman Vyayam Prasarak Mandal, College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.88,
    "Category": "General"
  },
  {
    "Name": "JSPMs Narhe Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.56,
    "Category": "General"
  },
  {
    "Name": "Pramod Ram Ujagar Tiwari Saket Institute Of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 58.54,
    "Category": "General"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.43,
    "Category": "General"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Marketing Management",
    "Score": 57.71,
    "Category": "General"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Marketing Management",
    "Score": 57.38,
    "Category": "General"
  },
  {
    "Name": "Jhulelal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.42,
    "Category": "General"
  },
  {
    "Name": "Shivajirao S. Jondhale College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 56.1,
    "Category": "General"
  },
  {
    "Name": "Institute of Technical Education, Research and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.21,
    "Category": "General"
  },
  {
    "Name": "D.K.T.E. Society's Textile and Engineering Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.88,
    "Category": "General"
  },
  {
    "Name": "Sinhgad Institutes - Sinhgad Management Institutes",
    "Course": "Master in Management Studies (MMS)",
    "Score": 54.71,
    "Category": "General"
  },
  {
    "Name": "Sanjivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.56,
    "Category": "General"
  },
  {
    "Name": "G H Raisoni College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.56,
    "Category": "General"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Marketing Management",
    "Score": 53.24,
    "Category": "General"
  },
  {
    "Name": "Suryadatta Institute of Business Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.49,
    "Category": "General"
  },
  {
    "Name": "A. C. Patil College of Engineering",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.23,
    "Category": "General"
  },
  {
    "Name": "Sahyadri Institute of Management & Research,Sawarde",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.23,
    "Category": "General"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "MBA in Marketing Management",
    "Score": 51.2,
    "Category": "General"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.9,
    "Category": "General"
  },
  {
    "Name": "Faculty of Engineering, D.Y Patil Technical Campus -Talsande",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.9,
    "Category": "General"
  },
  {
    "Name": "SND College of Engineering & Research Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.55,
    "Category": "General"
  },
  {
    "Name": "Swami Ramanand Teerth Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.31,
    "Category": "General"
  },
  {
    "Name": "PVG's College of Engineering and Technology and G.K. Pate (Wani) Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 54.56,
    "Category": "General"
  },
  {
    "Name": "Vidya Pratishthan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.17,
    "Category": "General"
  },
  {
    "Name": "Gondwana University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.49,
    "Category": "General"
  },
  {
    "Name": "College Of Management And Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.23,
    "Category": "General"
  },
  {
    "Name": "RC Patel Institute of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.07,
    "Category": "General"
  },
  {
    "Name": "Pune Vidyarthi Griha's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "General"
  },
  {
    "Name": "SBS - Sinhgad Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.9,
    "Category": "General"
  },
  {
    "Name": "Zeal Institute Of Business Administration, Computer Application and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.81,
    "Category": "General"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Information Technology Systems Management",
    "Score": 50.34,
    "Category": "General"
  },
  {
    "Name": "Kala Institute Of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 49.96,
    "Category": "General"
  },
  {
    "Name": "S B Jain Institute of Technology, Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.96,
    "Category": "General"
  },
  {
    "Name": "Rjspm's Institute Of Computer And Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.59,
    "Category": "General"
  },
  {
    "Name": "Vivekanand College, Kolhapur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.48,
    "Category": "General"
  },
  {
    "Name": "Datta Meghe Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.79,
    "Category": "General"
  },
  {
    "Name": "Shri Bhairavnath Shikshan Prasarak Mandals Adhalrao Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.79,
    "Category": "General"
  },
  {
    "Name": "Venkateshwara Institue of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.78,
    "Category": "General"
  },
  {
    "Name": "Jaihind Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.46,
    "Category": "General"
  },
  {
    "Name": "SNG Institute Of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.11,
    "Category": "General"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "General"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Studies, (MIBS Pune)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "General"
  },
  {
    "Name": "Amrutvahini College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.59,
    "Category": "General"
  },
  {
    "Name": "Rasiklal M. Dhariwal Sinhgad Technical Institutes Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.59,
    "Category": "General"
  },
  {
    "Name": "Suryadatta Institute of Management and Mass Communication",
    "Course": "MBA + CDP",
    "Score": 49.23,
    "Category": "General"
  },
  {
    "Name": "G.H. Raisoni College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.79,
    "Category": "General"
  },
  {
    "Name": "Rajeev Gandhi College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 48.78,
    "Category": "General"
  },
  {
    "Name": "Rajarshi Shahu Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 48.78,
    "Category": "General"
  },
  {
    "Name": "Hon. Shri. Babanrao Pachpute Vichardhara Trust's Parikrama Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.2,
    "Category": "General"
  },
  {
    "Name": "G H Raisoni School of Business Management, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "General"
  },
  {
    "Name": "Prin. N.G. Naralkar Institute of Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "General"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.55,
    "Category": "General"
  },
  {
    "Name": "Rajaram Shinde College of MBA",
    "Course": "Masters of Management Studies (MMS)",
    "Score": 47.5,
    "Category": "General"
  },
  {
    "Name": "Swayam Siddhi Mitra Sangh'S College of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 47.4,
    "Category": "General"
  },
  {
    "Name": "Allana Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "General"
  },
  {
    "Name": "Hashmatrai and Gangaram Himathmal Mansukhani Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 46.68,
    "Category": "General"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 46.65,
    "Category": "General"
  },
  {
    "Name": "Alamuri Ratnamala Institute of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 46.22,
    "Category": "General"
  },
  {
    "Name": "Siddhant Institute of Business Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.85,
    "Category": "General"
  },
  {
    "Name": "Prin. K.P. Mangalvedhekar Institute of Management Career Development and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 45.57,
    "Category": "General"
  },
  {
    "Name": "MKSSs Smt. Hiraben Nanavati Institute of Management and Research for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "General"
  },
  {
    "Name": "SVPM's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.17,
    "Category": "General"
  },
  {
    "Name": "Dr.D.Y. Patil Center for Management and Research(MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.49,
    "Category": "General"
  },
  {
    "Name": "Samarth Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.31,
    "Category": "General"
  },
  {
    "Name": "JSPMs Kautilya Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "General"
  },
  {
    "Name": "Priyadarshini Lokmanya Tilak Institute of Management Studies and Research (PLTIMSR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.65,
    "Category": "General"
  },
  {
    "Name": "Imperial College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.29,
    "Category": "General"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 45.93,
    "Category": "General"
  },
  {
    "Name": "Rohidas Patil Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 45.57,
    "Category": "General"
  },
  {
    "Name": "Sahyadri Valley College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.57,
    "Category": "General"
  },
  {
    "Name": "Shri Vithal Education and Research Institute's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.3,
    "Category": "General"
  },
  {
    "Name": "Shri Neminath Jain Bhhramcharyashram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.13,
    "Category": "General"
  },
  {
    "Name": "ASM Institute of Professional Studies, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.03,
    "Category": "General"
  },
  {
    "Name": "J.M.Patel Arts,Commerce and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.84,
    "Category": "General"
  },
  {
    "Name": "SSVPS's Bapusaheb Shivajirao Deore College of Engineering (SSVPS's BSD COE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.33,
    "Category": "General"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "MBA in Digital Marketing",
    "Score": 44.33,
    "Category": "General"
  },
  {
    "Name": "Smt. Parmeshwaridevi Durgadutt Tibrewala Lions Juhu College of Arts,Commerce and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.55,
    "Category": "General"
  },
  {
    "Name": "Unique Institute of Management (UIM, Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.85,
    "Category": "General"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.59,
    "Category": "General"
  },
  {
    "Name": "Guru Gobind Singh Foundation's Guru Gobind Singh College of Engineering and Research Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.5,
    "Category": "General"
  },
  {
    "Name": "Brahmdevdada Mane Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.18,
    "Category": "General"
  },
  {
    "Name": "Zeal College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.18,
    "Category": "General"
  },
  {
    "Name": "KDK College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.03,
    "Category": "General"
  },
  {
    "Name": "Rasiklal M Dhariwal Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.83,
    "Category": "General"
  },
  {
    "Name": "Dr. Panjabrao Deshmukh Institute of Management Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.33,
    "Category": "General"
  },
  {
    "Name": "Yashoda Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.99,
    "Category": "General"
  },
  {
    "Name": "Sura Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 43.47,
    "Category": "General"
  },
  {
    "Name": "Deogiri Institute of Engineering and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.71,
    "Category": "General"
  },
  {
    "Name": "D B Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.59,
    "Category": "General"
  },
  {
    "Name": "KJ's Educational Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.18,
    "Category": "General"
  },
  {
    "Name": "P.K. Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.18,
    "Category": "General"
  },
  {
    "Name": "Rajgad Institute of Management Research & Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.21,
    "Category": "General"
  },
  {
    "Name": "Navsahyadri Education Society's Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.21,
    "Category": "General"
  },
  {
    "Name": "Swayam Siddhi College of Management and Research (SSCMR)",
    "Course": "Master in Management Studies (MMS)",
    "Score": 40.93,
    "Category": "General"
  },
  {
    "Name": "Matoshri College of Management & Research Centre,Eklahare",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.92,
    "Category": "General"
  },
  {
    "Name": "Foster Development School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.42,
    "Category": "General"
  },
  {
    "Name": "Karmaveer Bhaurao Patil Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.23,
    "Category": "General"
  },
  {
    "Name": "Sharadchandra Pawar Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.99,
    "Category": "General"
  },
  {
    "Name": "G.S College of Commerce,Wardha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.09,
    "Category": "General"
  },
  {
    "Name": "Brahma Valley College of Engineering and Research Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.49,
    "Category": "General"
  },
  {
    "Name": "Genba Sopanrao Moze College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.49,
    "Category": "General"
  },
  {
    "Name": "JD College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.46,
    "Category": "General"
  },
  {
    "Name": "Akemi Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.21,
    "Category": "General"
  },
  {
    "Name": "V.P. Institute of Management Studies & Research, Sangli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 40.11,
    "Category": "General"
  },
  {
    "Name": "SGSJK's Aruna Manharlal Shah Institute of Management and Research (AMSIMR, Mumbai)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.89,
    "Category": "General"
  },
  {
    "Name": "R.M. Dhariwal Sinhgad Management School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.23,
    "Category": "General"
  },
  {
    "Name": "G H Raisoni Institute of Management and Research, Khaparheda Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.99,
    "Category": "General"
  },
  {
    "Name": "Nagpur Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.89,
    "Category": "General"
  },
  {
    "Name": "Institute of Business Management and Rural Development (IMBRD)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.49,
    "Category": "General"
  },
  {
    "Name": "Dr.BV Hiray College Of Management and Research Centre, Malegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.49,
    "Category": "General"
  },
  {
    "Name": "Navjeevan Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.46,
    "Category": "General"
  },
  {
    "Name": "Ashokrao Mane Group of Institutions (AMGOI)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.46,
    "Category": "General"
  },
  {
    "Name": "NavSahyadri Charitable Trust's, Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.46,
    "Category": "General"
  },
  {
    "Name": "Sai Krupa Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.95,
    "Category": "General"
  },
  {
    "Name": "Punyashlok Ahilyadevi Holkar Solapur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.24,
    "Category": "General"
  },
  {
    "Name": "Swargiya Shri Laxmanji Motghare Charitable Trust, Dr. Arun Motghare College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.99,
    "Category": "General"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 34.92,
    "Category": "General"
  },
  {
    "Name": "Pune Vidyarthi Griha's College of Engineering & S.S. Dhamankar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.92,
    "Category": "General"
  },
  {
    "Name": "Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.83,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in Human Resource Development",
    "Score": 34.8,
    "Category": "General"
  },
  {
    "Name": "Ballarpur Institute of Technology (BIT Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.72,
    "Category": "General"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.72,
    "Category": "General"
  },
  {
    "Name": "Central India College of Business Management and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.43,
    "Category": "General"
  },
  {
    "Name": "Amrutvahini Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.66,
    "Category": "General"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.23,
    "Category": "General"
  },
  {
    "Name": "Anekant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.61,
    "Category": "General"
  },
  {
    "Name": "Ozar Vikas Sanstha's Vishwasatya Arts and Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.92,
    "Category": "General"
  },
  {
    "Name": "Pune Vidyarthi Griha's College Of Engineering",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.83,
    "Category": "General"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 34.82,
    "Category": "General"
  },
  {
    "Name": "ZEAL Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.8,
    "Category": "General"
  },
  {
    "Name": "Mandukarrao Pandav College of Engineering, Bhandara",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.72,
    "Category": "General"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "MBA in Environment Management",
    "Score": 34.29,
    "Category": "General"
  },
  {
    "Name": "Smt. Radhikatai Pandav College of Engineering (SRPCE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.02,
    "Category": "General"
  },
  {
    "Name": "Maharashtra Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.95,
    "Category": "General"
  },
  {
    "Name": "U.D. Pathrikar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.63,
    "Category": "General"
  },
  {
    "Name": "Chetan Dattaji Gaikwad Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.62,
    "Category": "General"
  },
  {
    "Name": "Kasturi Shikshan Sansta's Institute of Management, Shikhrapur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.62,
    "Category": "General"
  },
  {
    "Name": "Global Institute of Management,Sangamner",
    "Course": "Master of Business Administration(MBA)",
    "Score": 32.56,
    "Category": "General"
  },
  {
    "Name": "Adsul's Technical Campus",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 32.15,
    "Category": "General"
  },
  {
    "Name": "YRES's Bapurao Deshmukh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.03,
    "Category": "General"
  },
  {
    "Name": "Shri Someshwar Shikshan Prasarak Mandals Sharadchandra Pawar Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.72,
    "Category": "General"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "MBA in Environment Management",
    "Score": 30.46,
    "Category": "General"
  },
  {
    "Name": "Wainganga College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.01,
    "Category": "General"
  },
  {
    "Name": "Swaraj Education Society's Swaraj Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.75,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in IT",
    "Score": 33.62,
    "Category": "General"
  },
  {
    "Name": "Matsyodari Shikshan Sanstha's College of Engineering and Technology, Jalna",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.62,
    "Category": "General"
  },
  {
    "Name": "Swaminarayan Siddhanta Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.62,
    "Category": "General"
  },
  {
    "Name": "Tatyasaheb Kore Institute of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.53,
    "Category": "General"
  },
  {
    "Name": "Ideal Institute of Management, Kondigre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.05,
    "Category": "General"
  },
  {
    "Name": "Vilasrao Deshmukh College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.72,
    "Category": "General"
  },
  {
    "Name": "Shri Swami Samarth Institute of Management & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.72,
    "Category": "General"
  },
  {
    "Name": "CP and Berar Arts Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.1,
    "Category": "General"
  },
  {
    "Name": "Jaywant Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.1,
    "Category": "General"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.62,
    "Category": "General"
  },
  {
    "Name": "AMI Pune Arham Adhyan Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.37,
    "Category": "General"
  },
  {
    "Name": "Tulsiramji Gaikwad-Patil College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.21,
    "Category": "General"
  },
  {
    "Name": "Parvatibai Genba Moze College of Engineering",
    "Course": "Master of business Administration (MBA)",
    "Score": 27.72,
    "Category": "General"
  },
  {
    "Name": "Dr. Vithalrao Vikhe Patil Foundation's College of Pharmacy",
    "Course": "Master of Business Administration(MBA)",
    "Score": 27.5,
    "Category": "General"
  },
  {
    "Name": "Matrix Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26.75,
    "Category": "General"
  },
  {
    "Name": "Nashik Gramin Shikshan Prasarak Mandal, Brahma Valley Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26.75,
    "Category": "General"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Marketing Management",
    "Score": 26.73,
    "Category": "General"
  },
  {
    "Name": "Pravara Institute of Research and Education in Natural and Social Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.97,
    "Category": "General"
  },
  {
    "Name": "University Sub Center Latur",
    "Course": "Master of Business Administration(MBA)",
    "Score": 30.1,
    "Category": "General"
  },
  {
    "Name": "Maharashtra Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.52,
    "Category": "General"
  },
  {
    "Name": "Nirmal Krida and Samaj Prabodhan Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.74,
    "Category": "General"
  },
  {
    "Name": "Sir Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.21,
    "Category": "General"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "M.B.A in FinTech",
    "Score": 27.56,
    "Category": "General"
  },
  {
    "Name": "D. B. Group of Institutes, Latur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "General"
  },
  {
    "Name": "SSBTs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26.75,
    "Category": "General"
  },
  {
    "Name": "Adarsh Shikshan Prasarak Mandal's K T Patil College of MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26.75,
    "Category": "General"
  },
  {
    "Name": "Sanjay Bhokare Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 26.02,
    "Category": "General"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.51,
    "Category": "General"
  },
  {
    "Name": "Dnyansagar Institute of Management and Research-DIMR",
    "Course": "Master of Business Administration (Prime)",
    "Score": 24.71,
    "Category": "General"
  },
  {
    "Name": "K.B.H. Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "General"
  },
  {
    "Name": "Renaissance Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.3,
    "Category": "General"
  },
  {
    "Name": "Shri Sai Institute of Management & Research, Aurangabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.89,
    "Category": "General"
  },
  {
    "Name": "Sanjivani Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "General"
  },
  {
    "Name": "Godavari Institute Of Management & Research, Jalgaon",
    "Course": "M.B.A. in Health Care and Hospital Management",
    "Score": 21.94,
    "Category": "General"
  },
  {
    "Name": "Aakar Institute of Management and Research Studies",
    "Course": "Master of Marketing Management",
    "Score": 21.73,
    "Category": "General"
  },
  {
    "Name": "Kamla Nehru Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.27,
    "Category": "General"
  },
  {
    "Name": "Kalyani Charitable Trust's K.R.Sapkal College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.19,
    "Category": "General"
  },
  {
    "Name": "Pune District Education Association's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.74,
    "Category": "General"
  },
  {
    "Name": "Mahatma Gandhi Vidyamandir's Institute of Management and Research",
    "Course": "Master of Business Administration (Prime)",
    "Score": 23.79,
    "Category": "General"
  },
  {
    "Name": "ASMA Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.46,
    "Category": "General"
  },
  {
    "Name": "Shreeyash Institute of Management (SIM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23,
    "Category": "General"
  },
  {
    "Name": "Godavari Institute Of Management & Research, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.82,
    "Category": "General"
  },
  {
    "Name": "Sant Dnyaneshwar Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.8,
    "Category": "General"
  },
  {
    "Name": "KT Patil College of Engineering and Technology, Osmanabad",
    "Course": "M.B.A. in Health Care and Hospital Management",
    "Score": 21.75,
    "Category": "General"
  },
  {
    "Name": "Institute of Management Studies and Research",
    "Course": "Master of Marketing Management",
    "Score": 21.27,
    "Category": "General"
  },
  {
    "Name": "Abasaheb Garware Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.47,
    "Category": "General"
  },
  {
    "Name": "SSR Institute of Management and Research (SSRIMR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.99,
    "Category": "General"
  },
  {
    "Name": "M.S. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.7,
    "Category": "General"
  },
  {
    "Name": "Shri Khanderai Pratishthan Dnyansagar Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "General"
  },
  {
    "Name": "Ranibai Agnihotri Institute of Computer Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "General"
  },
  {
    "Name": "Dattakala Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.79,
    "Category": "General"
  },
  {
    "Name": "Nabira Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "General"
  },
  {
    "Name": "Shreeyash College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.89,
    "Category": "General"
  },
  {
    "Name": "Dr. Moonje Institute of Management and Computer Studies,Nashik",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Human Resource Management",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Business Analytics",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "Matoshri Pratishthan Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.13,
    "Category": "General"
  },
  {
    "Name": "Nagarjuna Institute of Engineering, Technology & Management",
    "Course": "MBA in Finance Marketing and Human Resource Management",
    "Score": 13.89,
    "Category": "General"
  },
  {
    "Name": "Pirens Institute of Business Management and Administration",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "General"
  },
  {
    "Name": "Gangamai College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.92,
    "Category": "General"
  },
  {
    "Name": "Institute of Technology and Management, Nanded",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.44,
    "Category": "General"
  },
  {
    "Name": "Real Institute Of Management And Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.35,
    "Category": "General"
  },
  {
    "Name": "Central Institute of Business Management Research and Development (CIBMRD Nagpur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "Marathwada Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Marketing",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "MBA In Information Technology & Systems Management",
    "Score": 15.67,
    "Category": "General"
  },
  {
    "Name": "SOU Devibai Narayandas Chhabada Rural Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.09,
    "Category": "General"
  },
  {
    "Name": "Karanjekar College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.52,
    "Category": "General"
  },
  {
    "Name": "V.P. Institute of Management Studies & Research,Sangli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.23,
    "Category": "General"
  },
  {
    "Name": "Tasgaonkar Group of Institutions",
    "Course": "Master of Management Studies (MMS)",
    "Score": 12.01,
    "Category": "General"
  },
  {
    "Name": "Gourishankar Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.65,
    "Category": "General"
  },
  {
    "Name": "Govindrao Wanjari College of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.41,
    "Category": "General"
  },
  {
    "Name": "Kirsan's Mission Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.16,
    "Category": "General"
  },
  {
    "Name": "Dr. R. G. Bhoyar Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.86,
    "Category": "General"
  },
  {
    "Name": "Sahayog Educational Campus Indira College of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.51,
    "Category": "General"
  },
  {
    "Name": "VSS College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7.53,
    "Category": "General"
  },
  {
    "Name": "Jai Hind College, Mumbai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.19,
    "Category": "General"
  },
  {
    "Name": "Dr. Arun Motghare Mahavidyalaya",
    "Course": "Master of Business Administration(MBA)",
    "Score": 5.11,
    "Category": "General"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "MBA in Financial Technology (FinTech)",
    "Score": 12.21,
    "Category": "General"
  },
  {
    "Name": "Dr.S C Gulhane Prerna College Of Management And Technology Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 11.83,
    "Category": "General"
  },
  {
    "Name": "Pharate Patil Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.78,
    "Category": "General"
  },
  {
    "Name": "Shikshan Prasarak Sanstha's, M.B.A. Institute, Sangamner",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.88,
    "Category": "General"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "MBA in Family Managed Business",
    "Score": 9.02,
    "Category": "General"
  },
  {
    "Name": "Green Heaven Institute of Management and Research (GHIMR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.7,
    "Category": "General"
  },
  {
    "Name": "Shantiniketan Business School (SBS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.3,
    "Category": "General"
  },
  {
    "Name": "Jay Bhavani Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 6.47,
    "Category": "General"
  },
  {
    "Name": "Rajendra Mane College of Engineering and Technology (RMCET)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 5.6,
    "Category": "General"
  },
  {
    "Name": "Akole Taluka Education Societys Technical Campus College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 4.8,
    "Category": "General"
  },
  {
    "Name": "Shrimant Jayshreemaladevi Naik-nimbalkar Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.8,
    "Category": "General"
  },
  {
    "Name": "Dattakala Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "General"
  },
  {
    "Name": "Shikshan Prasarak Mandal's Deptartment of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.53,
    "Category": "General"
  },
  {
    "Name": "Shivajirao S. Jondhle Institute of Management Science and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.6,
    "Category": "General"
  },
  {
    "Name": "Vishwakarma Dadasaheb Chavan Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.34,
    "Category": "General"
  },
  {
    "Name": "Kasturi Education Sanstha's College of Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "General"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.13,
    "Category": "General"
  },
  {
    "Name": "Dr. A.P.J. Abdul Kalam College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.55,
    "Category": "General"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.99,
    "Category": "OBC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.93,
    "Category": "OBC"
  },
  {
    "Name": "Xavier Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.86,
    "Category": "OBC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.85,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 99.77,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 99.77,
    "Category": "OBC"
  },
  {
    "Name": "Chetana's Ramprasad Khandelwal Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.67,
    "Category": "OBC"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.39,
    "Category": "OBC"
  },
  {
    "Name": "Lala Lajpatrai Institute of Management (LLIM)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.3,
    "Category": "OBC"
  },
  {
    "Name": "Rizvi Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.6,
    "Category": "OBC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 99.97,
    "Category": "OBC"
  },
  {
    "Name": "Maharashtra Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.92,
    "Category": "OBC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.86,
    "Category": "OBC"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.83,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Financial Management",
    "Score": 99.77,
    "Category": "OBC"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "M.B.A. in Business Analytics",
    "Score": 99.68,
    "Category": "OBC"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.47,
    "Category": "OBC"
  },
  {
    "Name": "Pankaj Laddhad Institute of Technology and Management Studies, Buldana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.32,
    "Category": "OBC"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.84,
    "Category": "OBC"
  },
  {
    "Name": "Thakur Institute of Management Studies and Research - TIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.57,
    "Category": "OBC"
  },
  {
    "Name": "University of Mumbai [MU]",
    "Course": "Discontinued (Dec 2023)- Master of Management Studies (MMS)",
    "Score": 97.59,
    "Category": "OBC"
  },
  {
    "Name": "St. Francis Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.94,
    "Category": "OBC"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.97,
    "Category": "OBC"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94.5,
    "Category": "OBC"
  },
  {
    "Name": "GNIMS Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.67,
    "Category": "OBC"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.78,
    "Category": "OBC"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "MBA in Services Management",
    "Score": 92.25,
    "Category": "OBC"
  },
  {
    "Name": "Neville Wadia Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.48,
    "Category": "OBC"
  },
  {
    "Name": "AIMS - Atharva Institute of Management Studies",
    "Course": "Master of Management Studies (M.M.S.)",
    "Score": 91.39,
    "Category": "OBC"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 91.06,
    "Category": "OBC"
  },
  {
    "Name": "Indira Institute of Management - IIMP",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.29,
    "Category": "OBC"
  },
  {
    "Name": "Fr. C. Rodrigues Institute of management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.01,
    "Category": "OBC"
  },
  {
    "Name": "Indira School of Business Studies - ISBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.03,
    "Category": "OBC"
  },
  {
    "Name": "Bharati Vidyapeeth's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.94,
    "Category": "OBC"
  },
  {
    "Name": "Durgadevi Saraf Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.05,
    "Category": "OBC"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies - JDBIMS",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.76,
    "Category": "OBC"
  },
  {
    "Name": "Shri Ramdeobaba College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.21,
    "Category": "OBC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.47,
    "Category": "OBC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.22,
    "Category": "OBC"
  },
  {
    "Name": "Department of Business Management - Nagpur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.17,
    "Category": "OBC"
  },
  {
    "Name": "DY Patil Akurdi MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "OBC"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Business Analytics",
    "Score": 88.77,
    "Category": "OBC"
  },
  {
    "Name": "St. John College of Engineering & Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.56,
    "Category": "OBC"
  },
  {
    "Name": "Kohinoor Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.43,
    "Category": "OBC"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.98,
    "Category": "OBC"
  },
  {
    "Name": "D. Y. Patil Institute of Master of Computer Applications and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.98,
    "Category": "OBC"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Finance",
    "Score": 85.93,
    "Category": "OBC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.74,
    "Category": "OBC"
  },
  {
    "Name": "Mumbai Institute of Management and Research (MIMR)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 85.12,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Management and Human Resource Development, Sai Balaji Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.98,
    "Category": "OBC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "OBC"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "M.B.A. in Services Management",
    "Score": 88.54,
    "Category": "OBC"
  },
  {
    "Name": "SRBS - Sheila Raheja School of Business Management & Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.32,
    "Category": "OBC"
  },
  {
    "Name": "Sinhgad College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.43,
    "Category": "OBC"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "Master of Management Studies (MMS)",
    "Score": 85.98,
    "Category": "OBC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "MBA in Digital Marketing",
    "Score": 85.98,
    "Category": "OBC"
  },
  {
    "Name": "Sant Dnyaneshwar Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.81,
    "Category": "OBC"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in information Technology",
    "Score": 85.24,
    "Category": "OBC"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in Human Resource Development",
    "Score": 84.04,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Industrial and Computer Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.7,
    "Category": "OBC"
  },
  {
    "Name": "MGM Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.21,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Science Poona's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.13,
    "Category": "OBC"
  },
  {
    "Name": "Sasmira's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.49,
    "Category": "OBC"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Finance",
    "Score": 82.12,
    "Category": "OBC"
  },
  {
    "Name": "Dhananjayrao Gadgil Institute of Co-operative Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.93,
    "Category": "OBC"
  },
  {
    "Name": "Maratha Mandirs Babasaheb Gawde Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.7,
    "Category": "OBC"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research - PIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.69,
    "Category": "OBC"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.29,
    "Category": "OBC"
  },
  {
    "Name": "PR Patil College of Engineering And Technology (PRPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.71,
    "Category": "OBC"
  },
  {
    "Name": "Navinchandra Mehta Institute of Technology and Development",
    "Course": "Master of Management Studies (MMS)",
    "Score": 76.73,
    "Category": "OBC"
  },
  {
    "Name": "Department of Business Administration and Management, Sant Gadge Baba Amravati University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.21,
    "Category": "OBC"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 82.75,
    "Category": "OBC"
  },
  {
    "Name": "SAS Institute Of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.12,
    "Category": "OBC"
  },
  {
    "Name": "Smt. Parmeshwaridevi Durgadutt Tibrewala Lions Juhu College of Arts,Commerce and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.96,
    "Category": "OBC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in FinTech",
    "Score": 79.85,
    "Category": "OBC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in Project Management",
    "Score": 79.7,
    "Category": "OBC"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.61,
    "Category": "OBC"
  },
  {
    "Name": "All India Shri Shivaji Memorial Society's, Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.8,
    "Category": "OBC"
  },
  {
    "Name": "Pibm Tirupati Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.94,
    "Category": "OBC"
  },
  {
    "Name": "SIOM - Sinhgad Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.73,
    "Category": "OBC"
  },
  {
    "Name": "Rajarshi Shahu College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.59,
    "Category": "OBC"
  },
  {
    "Name": "Sir M Visvesvaraya Institute of Management Studies and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 76.39,
    "Category": "OBC"
  },
  {
    "Name": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.49,
    "Category": "OBC"
  },
  {
    "Name": "MET's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "OBC"
  },
  {
    "Name": "Dr.BV Hiray College Of Management and Research Centre, Malegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.4,
    "Category": "OBC"
  },
  {
    "Name": "Dr. V. N. Bedekar Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 72.86,
    "Category": "OBC"
  },
  {
    "Name": "Anjuman-I-Islam's Allana Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 72.48,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.48,
    "Category": "OBC"
  },
  {
    "Name": "NCRDs Sterling Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.34,
    "Category": "OBC"
  },
  {
    "Name": "Gourishankar Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.85,
    "Category": "OBC"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.45,
    "Category": "OBC"
  },
  {
    "Name": "Smt. Kashibai Navale College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.86,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "OBC"
  },
  {
    "Name": "Yeshwantrao Chavan College of Engineering, Nagar Yuwak Shikshan Sanstha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.4,
    "Category": "OBC"
  },
  {
    "Name": "Sahayog Educational Campus Indira College of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.25,
    "Category": "OBC"
  },
  {
    "Name": "S.B Patil Institute of Management, Pimpri Chinchwad Educations Trust's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.86,
    "Category": "OBC"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Management (MIBM Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.48,
    "Category": "OBC"
  },
  {
    "Name": "SSVPS's Bapusaheb Shivajirao Deore College of Engineering (SSVPS's BSD COE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.78,
    "Category": "OBC"
  },
  {
    "Name": "Vidyavardhini's College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.31,
    "Category": "OBC"
  },
  {
    "Name": "Kalyani Charitable Trust's K.R.Sapkal College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.34,
    "Category": "OBC"
  },
  {
    "Name": "K. K. Wagh Institute of Engineering Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.21,
    "Category": "OBC"
  },
  {
    "Name": "Shri Sai Institute of Management & Research, Aurangabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.22,
    "Category": "OBC"
  },
  {
    "Name": "Sadhu Vaswani Institute of Management Studies for Girls (SVIMS Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.05,
    "Category": "OBC"
  },
  {
    "Name": "Sipna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.44,
    "Category": "OBC"
  },
  {
    "Name": "Al Ameen Education & Medical Foundations, College of Engineering & Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.27,
    "Category": "OBC"
  },
  {
    "Name": "Arihant Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.15,
    "Category": "OBC"
  },
  {
    "Name": "G H Raisoni Institute of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.81,
    "Category": "OBC"
  },
  {
    "Name": "Mahatma Phule Institute of Management and Computer Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "OBC"
  },
  {
    "Name": "Trinity College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "OBC"
  },
  {
    "Name": "GNVS Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.29,
    "Category": "OBC"
  },
  {
    "Name": "Department of Commerce and Management - MBA Unit, Shivaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.22,
    "Category": "OBC"
  },
  {
    "Name": "Indira Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.56,
    "Category": "OBC"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.56,
    "Category": "OBC"
  },
  {
    "Name": "IndSearch Institute of Management Studies and Research, Bavdhan Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.56,
    "Category": "OBC"
  },
  {
    "Name": "Sau Leena Kishor Mamidwar Institute of Mananagement Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.01,
    "Category": "OBC"
  },
  {
    "Name": "MMM's Institute of Management Education Research and Training",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.22,
    "Category": "OBC"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "MBA in Human Resource Management",
    "Score": 63.81,
    "Category": "OBC"
  },
  {
    "Name": "Progressive Education Society's Modern College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.22,
    "Category": "OBC"
  },
  {
    "Name": "Jayawant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.56,
    "Category": "OBC"
  },
  {
    "Name": "OIM - Oriental Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 67.22,
    "Category": "OBC"
  },
  {
    "Name": "North Maharashtra University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "OBC"
  },
  {
    "Name": "SIMCA - Sinhgad Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "OBC"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Human Resource Management",
    "Score": 63.03,
    "Category": "OBC"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Human Resource Management",
    "Score": 62.17,
    "Category": "OBC"
  },
  {
    "Name": "SKN Sinhgad School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.77,
    "Category": "OBC"
  },
  {
    "Name": "MES Institute of Management and Career Courses",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 61.49,
    "Category": "OBC"
  },
  {
    "Name": "Tirpude Institute of Management Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.01,
    "Category": "OBC"
  },
  {
    "Name": "Vilasrao Deshmukh College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.16,
    "Category": "OBC"
  },
  {
    "Name": "Sinhgad Institutes - Sinhgad Management Institutes",
    "Course": "Master in Management Studies (MMS)",
    "Score": 60.13,
    "Category": "OBC"
  },
  {
    "Name": "Jayawantrao Sawant Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.43,
    "Category": "OBC"
  },
  {
    "Name": "Marathwada Mitra Mandal's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "OBC"
  },
  {
    "Name": "Suryadatta Institute of Business Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "OBC"
  },
  {
    "Name": "Ahirkar Institute of Management Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 62.17,
    "Category": "OBC"
  },
  {
    "Name": "ASMA Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.77,
    "Category": "OBC"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.64,
    "Category": "OBC"
  },
  {
    "Name": "J.D.C. Bytco Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.1,
    "Category": "OBC"
  },
  {
    "Name": "Ashoka Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.01,
    "Category": "OBC"
  },
  {
    "Name": "Jayawantrao Sawant College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.13,
    "Category": "OBC"
  },
  {
    "Name": "G S College of Commerce and Economics",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.13,
    "Category": "OBC"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.43,
    "Category": "OBC"
  },
  {
    "Name": "Dhole Patil College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "OBC"
  },
  {
    "Name": "SIBAR - Sinhgad Institute of Business Administration and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "OBC"
  },
  {
    "Name": "KIT's Institute of Management Education and Research (KIT's IMER)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 58.12,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "Master of Management Studies (MMS)",
    "Score": 56.44,
    "Category": "OBC"
  },
  {
    "Name": "Nashik District Maratha Vidya Prasarak Samaj's Karmaveer Adv. Baburao Thakare College of Engineering",
    "Course": "Master of Management Studies (MMS)",
    "Score": 55.53,
    "Category": "OBC"
  },
  {
    "Name": "Tirupati Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "OBC"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "Master of Management Studies (M.M.S.)",
    "Score": 55.15,
    "Category": "OBC"
  },
  {
    "Name": "ASM's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Moonje Institute of Management and Computer Studies,Nashik",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.57,
    "Category": "OBC"
  },
  {
    "Name": "Sinhgad Institute of Business Administration and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.03,
    "Category": "OBC"
  },
  {
    "Name": "G.H. Raisoni College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "OBC"
  },
  {
    "Name": "Vidya Bharati Mahavidyalaya, Amravati",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "OBC"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Marketing Management",
    "Score": 57.71,
    "Category": "OBC"
  },
  {
    "Name": "Natwarlal Maniklal Dalal Postgraduate College of Arts and Commerce (N.M.D College)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.33,
    "Category": "OBC"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 55.34,
    "Category": "OBC"
  },
  {
    "Name": "Tulsiramji Gaikwad-Patil College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "OBC"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "OBC"
  },
  {
    "Name": "Prof. Ram Meghe Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "OBC"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Finance",
    "Score": 54.05,
    "Category": "OBC"
  },
  {
    "Name": "Rohidas Patil Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 54.03,
    "Category": "OBC"
  },
  {
    "Name": "D.K.T.E. Society's Textile and Engineering Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.77,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Business Management & Research,Chakan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.44,
    "Category": "OBC"
  },
  {
    "Name": "Kala Institute Of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.56,
    "Category": "OBC"
  },
  {
    "Name": "Suryadatta Institute of Management and Mass Communication",
    "Course": "MBA + CDP",
    "Score": 52.17,
    "Category": "OBC"
  },
  {
    "Name": "Rjspm's Institute Of Computer And Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "OBC"
  },
  {
    "Name": "Alamuri Ratnamala Institute of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.12,
    "Category": "OBC"
  },
  {
    "Name": "Sankalp Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "OBC"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.52,
    "Category": "OBC"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.34,
    "Category": "OBC"
  },
  {
    "Name": "Vidya Pratishthan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.09,
    "Category": "OBC"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.65,
    "Category": "OBC"
  },
  {
    "Name": "SOU Devibai Narayandas Chhabada Rural Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.02,
    "Category": "OBC"
  },
  {
    "Name": "Alard University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "OBC"
  },
  {
    "Name": "Shri Hanuman Vyayam Prasarak Mandal, College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "OBC"
  },
  {
    "Name": "G H Raisoni College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "OBC"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "MBA in Marketing Management",
    "Score": 51.2,
    "Category": "OBC"
  },
  {
    "Name": "Pune Vidyarthi Griha's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "OBC"
  },
  {
    "Name": "K.C.College of Engineering and Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50.35,
    "Category": "OBC"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Information Technology Systems Management",
    "Score": 50.34,
    "Category": "OBC"
  },
  {
    "Name": "G H Raisoni School of Business Management, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.09,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Technological University - DBATU",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 49.86,
    "Category": "OBC"
  },
  {
    "Name": "VIVA Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 49.08,
    "Category": "OBC"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Marketing Management",
    "Score": 48.79,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.51,
    "Category": "OBC"
  },
  {
    "Name": "Swayam Siddhi Mitra Sangh'S College of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 47.4,
    "Category": "OBC"
  },
  {
    "Name": "JSPMs Narhe Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "OBC"
  },
  {
    "Name": "Allana Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "OBC"
  },
  {
    "Name": "Dr.D.Y. Patil Center for Management and Research(MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.52,
    "Category": "OBC"
  },
  {
    "Name": "S B Jain Institute of Technology, Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.48,
    "Category": "OBC"
  },
  {
    "Name": "Shri Shivaji Maratha Societys Institute of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Arun Motghare Mahavidyalaya",
    "Course": "Master of Business Administration(MBA)",
    "Score": 49.65,
    "Category": "OBC"
  },
  {
    "Name": "Genba Sopanrao Moze College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.86,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in IT",
    "Score": 48.55,
    "Category": "OBC"
  },
  {
    "Name": "NBN Sinhgad School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.5,
    "Category": "OBC"
  },
  {
    "Name": "Pratibha Institute of Business Management (Kamala Education Society's)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "OBC"
  },
  {
    "Name": "JSPMs Kautilya Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "OBC"
  },
  {
    "Name": "Datta Meghe Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.52,
    "Category": "OBC"
  },
  {
    "Name": "SBS - Sinhgad Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.5,
    "Category": "OBC"
  },
  {
    "Name": "Imperial College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.29,
    "Category": "OBC"
  },
  {
    "Name": "Swami Ramanand Teerth Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "OBC"
  },
  {
    "Name": "MKSSs Smt. Hiraben Nanavati Institute of Management and Research for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Panjabrao Deshmukh Institute of Management Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Technical Education, Research and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "OBC"
  },
  {
    "Name": "Chintamanrao Institute of Management Development and Research (CIMDR Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.96,
    "Category": "OBC"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 44.35,
    "Category": "OBC"
  },
  {
    "Name": "Hirachand Nemchand College of Commerce (HNCC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.85,
    "Category": "OBC"
  },
  {
    "Name": "Unique Institute of Management (UIM, Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.85,
    "Category": "OBC"
  },
  {
    "Name": "College Of Management And Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.15,
    "Category": "OBC"
  },
  {
    "Name": "Sanjivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.78,
    "Category": "OBC"
  },
  {
    "Name": "Deshbhakt Ratnappa Kumbhar College of Commerce (DRKCC, Kolhapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "OBC"
  },
  {
    "Name": "Priyadarshini Lokmanya Tilak Institute of Management Studies and Research (PLTIMSR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "OBC"
  },
  {
    "Name": "Dr.D.Y.Patil Institute Of Management & Entrepreneur Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "OBC"
  },
  {
    "Name": "Shri Neminath Jain Bhhramcharyashram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.13,
    "Category": "OBC"
  },
  {
    "Name": "Rasiklal M Dhariwal Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.83,
    "Category": "OBC"
  },
  {
    "Name": "Zeal Institute Of Business Administration, Computer Application and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.02,
    "Category": "OBC"
  },
  {
    "Name": "Faculty of Engineering, D.Y Patil Technical Campus -Talsande",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.99,
    "Category": "OBC"
  },
  {
    "Name": "Shivajirao S. Jondhale College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 42.7,
    "Category": "OBC"
  },
  {
    "Name": "Tasgaonkar Group of Institutions",
    "Course": "Master of Management Studies (MMS)",
    "Score": 41.78,
    "Category": "OBC"
  },
  {
    "Name": "G.S College of Commerce,Wardha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.78,
    "Category": "OBC"
  },
  {
    "Name": "SVPM's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "OBC"
  },
  {
    "Name": "Prin. N.G. Naralkar Institute of Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "OBC"
  },
  {
    "Name": "Siddhant Institute of Business Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.92,
    "Category": "OBC"
  },
  {
    "Name": "NavSahyadri Charitable Trust's, Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.64,
    "Category": "OBC"
  },
  {
    "Name": "A. C. Patil College of Engineering",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.07,
    "Category": "OBC"
  },
  {
    "Name": "Adsul's Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.5,
    "Category": "OBC"
  },
  {
    "Name": "G H Raisoni Institute of Management and Research, Khaparheda Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "OBC"
  },
  {
    "Name": "KDK College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "OBC"
  },
  {
    "Name": "SGSJK's Aruna Manharlal Shah Institute of Management and Research (AMSIMR, Mumbai)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.14,
    "Category": "OBC"
  },
  {
    "Name": "Rajaram Shinde College of MBA",
    "Course": "Masters of Management Studies (MMS)",
    "Score": 37.59,
    "Category": "OBC"
  },
  {
    "Name": "Central India College of Business Management and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.43,
    "Category": "OBC"
  },
  {
    "Name": "Rajeev Gandhi College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.95,
    "Category": "OBC"
  },
  {
    "Name": "D B Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.88,
    "Category": "OBC"
  },
  {
    "Name": "Foster Development School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.42,
    "Category": "OBC"
  },
  {
    "Name": "Hashmatrai and Gangaram Himathmal Mansukhani Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.52,
    "Category": "OBC"
  },
  {
    "Name": "SNG Institute Of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.47,
    "Category": "OBC"
  },
  {
    "Name": "Deogiri Institute of Engineering and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "OBC"
  },
  {
    "Name": "Sura Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.3,
    "Category": "OBC"
  },
  {
    "Name": "R.M. Dhariwal Sinhgad Management School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.14,
    "Category": "OBC"
  },
  {
    "Name": "Karmaveer Bhaurao Patil Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.47,
    "Category": "OBC"
  },
  {
    "Name": "Pramod Ram Ujagar Tiwari Saket Institute Of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 36.51,
    "Category": "OBC"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 36.3,
    "Category": "OBC"
  },
  {
    "Name": "Swargiya Shri Laxmanji Motghare Charitable Trust, Dr. Arun Motghare College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.99,
    "Category": "OBC"
  },
  {
    "Name": "Matrix Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.08,
    "Category": "OBC"
  },
  {
    "Name": "Amrutvahini Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "OBC"
  },
  {
    "Name": "Pune Vidyarthi Griha's College Of Engineering",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.83,
    "Category": "OBC"
  },
  {
    "Name": "Matoshri College of Management & Research Centre,Eklahare",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.82,
    "Category": "OBC"
  },
  {
    "Name": "PVG's College of Engineering and Technology and G.K. Pate (Wani) Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.77,
    "Category": "OBC"
  },
  {
    "Name": "Smt. Radhikatai Pandav College of Engineering (SRPCE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.02,
    "Category": "OBC"
  },
  {
    "Name": "Punyashlok Ahilyadevi Holkar Solapur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.98,
    "Category": "OBC"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 33.16,
    "Category": "OBC"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.3,
    "Category": "OBC"
  },
  {
    "Name": "Venkateshwara Institue of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.85,
    "Category": "OBC"
  },
  {
    "Name": "SND College of Engineering & Research Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "OBC"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Studies, (MIBS Pune)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "OBC"
  },
  {
    "Name": "Amrutvahini College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.82,
    "Category": "OBC"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 34.82,
    "Category": "OBC"
  },
  {
    "Name": "Pravara Institute of Research and Education in Natural and Social Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.11,
    "Category": "OBC"
  },
  {
    "Name": "Wainganga College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.01,
    "Category": "OBC"
  },
  {
    "Name": "Ozar Vikas Sanstha's Vishwasatya Arts and Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.34,
    "Category": "OBC"
  },
  {
    "Name": "Swayam Siddhi College of Management and Research (SSCMR)",
    "Course": "Master in Management Studies (MMS)",
    "Score": 33.13,
    "Category": "OBC"
  },
  {
    "Name": "Brahmdevdada Mane Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.92,
    "Category": "OBC"
  },
  {
    "Name": "ASM Institute of Professional Studies, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "OBC"
  },
  {
    "Name": "Yashoda Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "OBC"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "OBC"
  },
  {
    "Name": "Ranibai Agnihotri Institute of Computer Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.03,
    "Category": "OBC"
  },
  {
    "Name": "Brahma Valley College of Engineering and Research Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.56,
    "Category": "OBC"
  },
  {
    "Name": "Institute Of Management and Science,Sakegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.18,
    "Category": "OBC"
  },
  {
    "Name": "Zeal College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.68,
    "Category": "OBC"
  },
  {
    "Name": "Parvatibai Genba Moze College of Engineering",
    "Course": "Master of business Administration (MBA)",
    "Score": 28.25,
    "Category": "OBC"
  },
  {
    "Name": "P.K. Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.88,
    "Category": "OBC"
  },
  {
    "Name": "Sir Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.88,
    "Category": "OBC"
  },
  {
    "Name": "Nirmal Krida and Samaj Prabodhan Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.72,
    "Category": "OBC"
  },
  {
    "Name": "Shri Bhairavnath Shikshan Prasarak Mandals Adhalrao Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.5,
    "Category": "OBC"
  },
  {
    "Name": "D. B. Group of Institutes, Latur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "OBC"
  },
  {
    "Name": "Prin. K.P. Mangalvedhekar Institute of Management Career Development and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 26.46,
    "Category": "OBC"
  },
  {
    "Name": "Matoshri Ushatai Jadhav Institute of Management Studies and Research Centre (MUIMRC)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25.25,
    "Category": "OBC"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Marketing Management",
    "Score": 25.25,
    "Category": "OBC"
  },
  {
    "Name": "Shri Vithal Education and Research Institute's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "OBC"
  },
  {
    "Name": "Ashokrao Mane Group of Institutions (AMGOI)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.47,
    "Category": "OBC"
  },
  {
    "Name": "K.B.H. Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "OBC"
  },
  {
    "Name": "Matsyodari Shikshan Sanstha's College of Engineering and Technology, Jalna",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.72,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management and Business Administration, Akole",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.5,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Vithalrao Vikhe Patil Foundation's College of Pharmacy",
    "Course": "Master of Business Administration(MBA)",
    "Score": 27.5,
    "Category": "OBC"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 26.57,
    "Category": "OBC"
  },
  {
    "Name": "Sahyadri Institute of Management & Research,Sawarde",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25.28,
    "Category": "OBC"
  },
  {
    "Name": "JD College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.25,
    "Category": "OBC"
  },
  {
    "Name": "Navsahyadri Education Society's Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "OBC"
  },
  {
    "Name": "International Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "OBC"
  },
  {
    "Name": "Hon. Shri. Babanrao Pachpute Vichardhara Trust's Parikrama Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.47,
    "Category": "OBC"
  },
  {
    "Name": "Kamla Nehru Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "OBC"
  },
  {
    "Name": "Rasiklal M. Dhariwal Sinhgad Technical Institutes Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "OBC"
  },
  {
    "Name": "Shri Someshwar Shikshan Prasarak Mandals Sharadchandra Pawar Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "OBC"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "OBC"
  },
  {
    "Name": "M.S. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "OBC"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Agri Business Management/ Entrepreneurship",
    "Score": 22.41,
    "Category": "OBC"
  },
  {
    "Name": "Aakar Institute of Management and Research Studies",
    "Course": "Master of Marketing Management",
    "Score": 21.73,
    "Category": "OBC"
  },
  {
    "Name": "KJ's Educational Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.3,
    "Category": "OBC"
  },
  {
    "Name": "Abasaheb Garware Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.47,
    "Category": "OBC"
  },
  {
    "Name": "Pirens Institute of Business Management and Administration",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "OBC"
  },
  {
    "Name": "Maharashtra Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.23,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Business Management and Rural Development (IMBRD)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "OBC"
  },
  {
    "Name": "Sanjivani Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "OBC"
  },
  {
    "Name": "Kasturi Shikshan Sansta's Institute of Management, Shikhrapur",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "OBC"
  },
  {
    "Name": "SSBTs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "OBC"
  },
  {
    "Name": "KT Patil College of Engineering and Technology, Osmanabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.75,
    "Category": "OBC"
  },
  {
    "Name": "U.D. Pathrikar Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 21.36,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.27,
    "Category": "OBC"
  },
  {
    "Name": "Shri Khanderai Pratishthan Dnyansagar Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "OBC"
  },
  {
    "Name": "Mandukarrao Pandav College of Engineering, Bhandara",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "OBC"
  },
  {
    "Name": "Real Institute Of Management And Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 18.65,
    "Category": "OBC"
  },
  {
    "Name": "Shantiniketan Business School (SBS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.6,
    "Category": "OBC"
  },
  {
    "Name": "ZEAL Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.57,
    "Category": "OBC"
  },
  {
    "Name": "Global Institute of Management,Sangamner",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "OBC"
  },
  {
    "Name": "Nabira Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "OBC"
  },
  {
    "Name": "Central Institute of Business Management Research and Development (CIBMRD Nagpur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.13,
    "Category": "OBC"
  },
  {
    "Name": "Nashik Gramin Shikshan Prasarak Mandal, Brahma Valley Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.11,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.48,
    "Category": "OBC"
  },
  {
    "Name": "Ballarpur Institute of Technology (BIT Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.02,
    "Category": "OBC"
  },
  {
    "Name": "Rajarshi Shahu Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 13.36,
    "Category": "OBC"
  },
  {
    "Name": "V.P. Institute of Management Studies & Research,Sangli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.23,
    "Category": "OBC"
  },
  {
    "Name": "CP and Berar Arts Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.57,
    "Category": "OBC"
  },
  {
    "Name": "International Centre of Excellence in Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.3,
    "Category": "OBC"
  },
  {
    "Name": "Sharadchandra Pawar Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "OBC"
  },
  {
    "Name": "Jhulelal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.47,
    "Category": "OBC"
  },
  {
    "Name": "Ideal Institute of Management, Kondigre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.48,
    "Category": "OBC"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.67,
    "Category": "OBC"
  },
  {
    "Name": "Shreeyash College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.01,
    "Category": "OBC"
  },
  {
    "Name": "Nagpur Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.84,
    "Category": "OBC"
  },
  {
    "Name": "Chetan Dattaji Gaikwad Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.52,
    "Category": "OBC"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "M.B.A in FinTech",
    "Score": 12.12,
    "Category": "OBC"
  },
  {
    "Name": "Pune District Education Association's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.92,
    "Category": "OBC"
  },
  {
    "Name": "Marathwada Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.57,
    "Category": "OBC"
  },
  {
    "Name": "Mahatma Gandhi Vidyamandir's Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.04,
    "Category": "OBC"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "MBA in Environment Management",
    "Score": 9.94,
    "Category": "OBC"
  },
  {
    "Name": "Samarth Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.79,
    "Category": "OBC"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "MBA in Family Managed Business",
    "Score": 8.71,
    "Category": "OBC"
  },
  {
    "Name": "Swaraj Education Society's Swaraj Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.13,
    "Category": "OBC"
  },
  {
    "Name": "Matoshri Pratishthan Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "OBC"
  },
  {
    "Name": "Guru Nanak Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "OBC"
  },
  {
    "Name": "Shri Swami Samarth Institute of Management & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "OBC"
  },
  {
    "Name": "Shrimant Jayshreemaladevi Naik-nimbalkar Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.78,
    "Category": "OBC"
  },
  {
    "Name": "Dnyansagar Institute of Management and Research-DIMR",
    "Course": "Master of Business Administration (Prime)",
    "Score": 10.38,
    "Category": "OBC"
  },
  {
    "Name": "Govindrao Wanjari College of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.97,
    "Category": "OBC"
  },
  {
    "Name": "Jaywant Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.62,
    "Category": "OBC"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Marketing",
    "Score": 8.79,
    "Category": "OBC"
  },
  {
    "Name": "Green Heaven Institute of Management and Research (GHIMR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.7,
    "Category": "OBC"
  },
  {
    "Name": "J.M.Patel Arts,Commerce and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7.06,
    "Category": "OBC"
  },
  {
    "Name": "Shreeyash Institute of Management (SIM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "OBC"
  },
  {
    "Name": "Jai Hind College, Mumbai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.19,
    "Category": "OBC"
  },
  {
    "Name": "Dr. R. G. Bhoyar Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.48,
    "Category": "OBC"
  },
  {
    "Name": "Renaissance Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.07,
    "Category": "OBC"
  },
  {
    "Name": "Godavari Institute Of Management & Research, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.79,
    "Category": "OBC"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Marketing Management",
    "Score": 3.25,
    "Category": "OBC"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Finance",
    "Score": 2.39,
    "Category": "OBC"
  },
  {
    "Name": "Kasturi Education Sanstha's College of Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "OBC"
  },
  {
    "Name": "Vishwakarma Dadasaheb Chavan Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.24,
    "Category": "OBC"
  },
  {
    "Name": "Aditya Engineering College, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.86,
    "Category": "OBC"
  },
  {
    "Name": "Akole Taluka Education Societys Technical Campus College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.83,
    "Category": "OBC"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Business Analytics",
    "Score": 1.13,
    "Category": "OBC"
  },
  {
    "Name": "University Sub Center Latur",
    "Course": "Master of Business Administration(MBA)",
    "Score": 0.3,
    "Category": "OBC"
  },
  {
    "Name": "Jay Bhavani Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 4.47,
    "Category": "OBC"
  },
  {
    "Name": "AMI Pune Arham Adhyan Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.79,
    "Category": "OBC"
  },
  {
    "Name": "Nagarjuna Institute of Engineering, Technology & Management",
    "Course": "MBA in Finance Marketing and Human Resource Management",
    "Score": 2.74,
    "Category": "OBC"
  },
  {
    "Name": "Dattakala Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "OBC"
  },
  {
    "Name": "Sanjay Bhokare Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "OBC"
  },
  {
    "Name": "Shikshan Prasarak Mandal's Deptartment of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.01,
    "Category": "OBC"
  },
  {
    "Name": "Institute of Technology and Management, Nanded",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.83,
    "Category": "OBC"
  },
  {
    "Name": "S.A.V Acharya Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 1.29,
    "Category": "OBC"
  },
  {
    "Name": "Shivajirao S. Jondhle Institute of Management Science and Research",
    "Course": "Master of Management Studies",
    "Score": 0.6,
    "Category": "OBC"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.11,
    "Category": "OBC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.99,
    "Category": "SC"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.96,
    "Category": "SC"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "M.B.A. in Business Analytics",
    "Score": 99.94,
    "Category": "SC"
  },
  {
    "Name": "Xavier Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.93,
    "Category": "SC"
  },
  {
    "Name": "Chetana's Ramprasad Khandelwal Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.91,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 99.9,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Financial Management",
    "Score": 99.9,
    "Category": "SC"
  },
  {
    "Name": "Lala Lajpatrai Institute of Management (LLIM)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.68,
    "Category": "SC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.29,
    "Category": "SC"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.96,
    "Category": "SC"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 99.99,
    "Category": "SC"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.95,
    "Category": "SC"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.93,
    "Category": "SC"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.92,
    "Category": "SC"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.91,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 99.9,
    "Category": "SC"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.86,
    "Category": "SC"
  },
  {
    "Name": "Rizvi Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.49,
    "Category": "SC"
  },
  {
    "Name": "Indira Institute of Management - IIMP",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.1,
    "Category": "SC"
  },
  {
    "Name": "Thakur Institute of Management Studies and Research - TIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.76,
    "Category": "SC"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Finance",
    "Score": 98.51,
    "Category": "SC"
  },
  {
    "Name": "Indira School of Business Studies - ISBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 98.27,
    "Category": "SC"
  },
  {
    "Name": "Thakur College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.96,
    "Category": "SC"
  },
  {
    "Name": "Fr. C. Rodrigues Institute of management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 97.81,
    "Category": "SC"
  },
  {
    "Name": "GNIMS Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 95.69,
    "Category": "SC"
  },
  {
    "Name": "Bharati Vidyapeeth's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 94.9,
    "Category": "SC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.96,
    "Category": "SC"
  },
  {
    "Name": "Shree Chanakya Education Society's Indira College of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 93.44,
    "Category": "SC"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "MBA in Services Management",
    "Score": 92.25,
    "Category": "SC"
  },
  {
    "Name": "Neville Wadia Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.14,
    "Category": "SC"
  },
  {
    "Name": "University of Mumbai [MU]",
    "Course": "Discontinued (Dec 2023)- Master of Management Studies (MMS)",
    "Score": 98.45,
    "Category": "SC"
  },
  {
    "Name": "St. Francis Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.03,
    "Category": "SC"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.83,
    "Category": "SC"
  },
  {
    "Name": "Dhole Patil College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.29,
    "Category": "SC"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.38,
    "Category": "SC"
  },
  {
    "Name": "Durgadevi Saraf Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 94.11,
    "Category": "SC"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.46,
    "Category": "SC"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies - JDBIMS",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.19,
    "Category": "SC"
  },
  {
    "Name": "Shri Ramdeobaba College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.21,
    "Category": "SC"
  },
  {
    "Name": "AIMS - Atharva Institute of Management Studies",
    "Course": "Master of Management Studies (M.M.S.)",
    "Score": 91.11,
    "Category": "SC"
  },
  {
    "Name": "Institute Of Management and Science,Sakegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.29,
    "Category": "SC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.02,
    "Category": "SC"
  },
  {
    "Name": "Guru Nanak Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.94,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Management and Human Resource Development, Sai Balaji Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.38,
    "Category": "SC"
  },
  {
    "Name": "DY Patil Akurdi MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "SC"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "M.B.A. in Services Management",
    "Score": 88.54,
    "Category": "SC"
  },
  {
    "Name": "Sinhgad College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 88.41,
    "Category": "SC"
  },
  {
    "Name": "D. Y. Patil Institute of Master of Computer Applications and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 87.82,
    "Category": "SC"
  },
  {
    "Name": "St. John College of Engineering & Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.43,
    "Category": "SC"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "MBA in Digital Marketing",
    "Score": 86.67,
    "Category": "SC"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90.12,
    "Category": "SC"
  },
  {
    "Name": "Department of Business Management - Nagpur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 90,
    "Category": "SC"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 89.89,
    "Category": "SC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.12,
    "Category": "SC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in FinTech",
    "Score": 88.54,
    "Category": "SC"
  },
  {
    "Name": "Mumbai Institute of Management and Research (MIMR)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 88.41,
    "Category": "SC"
  },
  {
    "Name": "Pankaj Laddhad Institute of Technology and Management Studies, Buldana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 88.02,
    "Category": "SC"
  },
  {
    "Name": "Kohinoor Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.65,
    "Category": "SC"
  },
  {
    "Name": "Institute of Management and Business Administration, Akole",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.96,
    "Category": "SC"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.59,
    "Category": "SC"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.55,
    "Category": "SC"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.13,
    "Category": "SC"
  },
  {
    "Name": "International Centre of Excellence in Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.94,
    "Category": "SC"
  },
  {
    "Name": "Institute of Science Poona's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.29,
    "Category": "SC"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in Project Management",
    "Score": 83.84,
    "Category": "SC"
  },
  {
    "Name": "Institute of Industrial and Computer Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.33,
    "Category": "SC"
  },
  {
    "Name": "SIOM - Sinhgad Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 82.88,
    "Category": "SC"
  },
  {
    "Name": "MGM Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.14,
    "Category": "SC"
  },
  {
    "Name": "Smt. Kashibai Navale College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 81.64,
    "Category": "SC"
  },
  {
    "Name": "All India Shri Shivaji Memorial Society's, Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.88,
    "Category": "SC"
  },
  {
    "Name": "SRBS - Sheila Raheja School of Business Management & Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.35,
    "Category": "SC"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Finance",
    "Score": 85.93,
    "Category": "SC"
  },
  {
    "Name": "S.B Patil Institute of Management, Pimpri Chinchwad Educations Trust's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 84.89,
    "Category": "SC"
  },
  {
    "Name": "Sasmira's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.91,
    "Category": "SC"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in information Technology",
    "Score": 83.4,
    "Category": "SC"
  },
  {
    "Name": "Dr. V. N. Bedekar Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.07,
    "Category": "SC"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 82.75,
    "Category": "SC"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Finance",
    "Score": 82.12,
    "Category": "SC"
  },
  {
    "Name": "Department of Business Administration and Management, Sant Gadge Baba Amravati University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 81.51,
    "Category": "SC"
  },
  {
    "Name": "Rajarshi Shahu College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.88,
    "Category": "SC"
  },
  {
    "Name": "Pibm Tirupati Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 80.65,
    "Category": "SC"
  },
  {
    "Name": "Dhananjayrao Gadgil Institute of Co-operative Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.76,
    "Category": "SC"
  },
  {
    "Name": "MET's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.31,
    "Category": "SC"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.29,
    "Category": "SC"
  },
  {
    "Name": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.77,
    "Category": "SC"
  },
  {
    "Name": "PR Patil College of Engineering And Technology (PRPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.71,
    "Category": "SC"
  },
  {
    "Name": "Jayawant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.05,
    "Category": "SC"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in Human Resource Development",
    "Score": 74.81,
    "Category": "SC"
  },
  {
    "Name": "NCRDs Sterling Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 74.43,
    "Category": "SC"
  },
  {
    "Name": "OIM - Oriental Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 73.85,
    "Category": "SC"
  },
  {
    "Name": "Maratha Mandirs Babasaheb Gawde Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.76,
    "Category": "SC"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research - PIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.69,
    "Category": "SC"
  },
  {
    "Name": "International Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.85,
    "Category": "SC"
  },
  {
    "Name": "Sir M Visvesvaraya Institute of Management Studies and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 78.24,
    "Category": "SC"
  },
  {
    "Name": "Navinchandra Mehta Institute of Technology and Development",
    "Course": "Master of Management Studies (MMS)",
    "Score": 77.77,
    "Category": "SC"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.23,
    "Category": "SC"
  },
  {
    "Name": "Trinity College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.41,
    "Category": "SC"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Management (MIBM Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.77,
    "Category": "SC"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Technological University - DBATU",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 74.04,
    "Category": "SC"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.18,
    "Category": "SC"
  },
  {
    "Name": "P. R. Pote Patil College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.18,
    "Category": "SC"
  },
  {
    "Name": "K.C.College of Engineering and Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 71.75,
    "Category": "SC"
  },
  {
    "Name": "Natwarlal Maniklal Dalal Postgraduate College of Arts and Commerce (N.M.D College)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71.42,
    "Category": "SC"
  },
  {
    "Name": "K. K. Wagh Institute of Engineering Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.85,
    "Category": "SC"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.68,
    "Category": "SC"
  },
  {
    "Name": "Suryodaya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.41,
    "Category": "SC"
  },
  {
    "Name": "Vidyavardhini's College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.31,
    "Category": "SC"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 68.4,
    "Category": "SC"
  },
  {
    "Name": "Hirachand Nemchand College of Commerce (HNCC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.15,
    "Category": "SC"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.08,
    "Category": "SC"
  },
  {
    "Name": "ISMR - International School of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.16,
    "Category": "SC"
  },
  {
    "Name": "IndSearch Institute of Management Studies and Research, Bavdhan Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 71.42,
    "Category": "SC"
  },
  {
    "Name": "GNVS Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 71.29,
    "Category": "SC"
  },
  {
    "Name": "SKN Sinhgad School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.85,
    "Category": "SC"
  },
  {
    "Name": "Ashoka Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.61,
    "Category": "SC"
  },
  {
    "Name": "Anjuman-I-Islam's Allana Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.51,
    "Category": "SC"
  },
  {
    "Name": "J.D.C. Bytco Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 69.19,
    "Category": "SC"
  },
  {
    "Name": "Progressive Education Society's Modern College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.18,
    "Category": "SC"
  },
  {
    "Name": "Marathwada Mitra Mandal's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.15,
    "Category": "SC"
  },
  {
    "Name": "MMM's Institute of Management Education Research and Training",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.08,
    "Category": "SC"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Finance",
    "Score": 68.08,
    "Category": "SC"
  },
  {
    "Name": "G S College of Commerce and Economics",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.18,
    "Category": "SC"
  },
  {
    "Name": "Mahatma Phule Institute of Management and Computer Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.33,
    "Category": "SC"
  },
  {
    "Name": "Sau Leena Kishor Mamidwar Institute of Mananagement Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.01,
    "Category": "SC"
  },
  {
    "Name": "SAS Institute Of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 65.54,
    "Category": "SC"
  },
  {
    "Name": "Jayawantrao Sawant Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.22,
    "Category": "SC"
  },
  {
    "Name": "SIMCA - Sinhgad Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Chintamanrao Institute of Management Development and Research (CIMDR Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.85,
    "Category": "SC"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Human Resource Management",
    "Score": 64.76,
    "Category": "SC"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "MBA in Human Resource Management",
    "Score": 63.81,
    "Category": "SC"
  },
  {
    "Name": "Indira Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 67.67,
    "Category": "SC"
  },
  {
    "Name": "Sadhu Vaswani Institute of Management Studies for Girls (SVIMS Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.05,
    "Category": "SC"
  },
  {
    "Name": "MES Institute of Management and Career Courses",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 66.32,
    "Category": "SC"
  },
  {
    "Name": "Al Ameen Education & Medical Foundations, College of Engineering & Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.79,
    "Category": "SC"
  },
  {
    "Name": "Department of Commerce and Management - MBA Unit, Shivaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.37,
    "Category": "SC"
  },
  {
    "Name": "Jayawantrao Sawant College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.22,
    "Category": "SC"
  },
  {
    "Name": "Vidya Bharati Mahavidyalaya, Amravati",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65,
    "Category": "SC"
  },
  {
    "Name": "Yeshwantrao Chavan College of Engineering, Nagar Yuwak Shikshan Sanstha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.85,
    "Category": "SC"
  },
  {
    "Name": "G H Raisoni Institute of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.81,
    "Category": "SC"
  },
  {
    "Name": "Aditya Engineering College, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.65,
    "Category": "SC"
  },
  {
    "Name": "KIT's Institute of Management Education and Research (KIT's IMER)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.63,
    "Category": "SC"
  },
  {
    "Name": "Matoshri Ushatai Jadhav Institute of Management Studies and Research Centre (MUIMRC)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.14,
    "Category": "SC"
  },
  {
    "Name": "Sankalp Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 62.65,
    "Category": "SC"
  },
  {
    "Name": "Ahirkar Institute of Management Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 62.17,
    "Category": "SC"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 61.91,
    "Category": "SC"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.07,
    "Category": "SC"
  },
  {
    "Name": "S.A.V Acharya Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 59.15,
    "Category": "SC"
  },
  {
    "Name": "Nashik District Maratha Vidya Prasarak Samaj's Karmaveer Adv. Baburao Thakare College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.62,
    "Category": "SC"
  },
  {
    "Name": "Arihant Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.62,
    "Category": "SC"
  },
  {
    "Name": "Sipna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.59,
    "Category": "SC"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Agri Business Management/ Entrepreneurship",
    "Score": 58.59,
    "Category": "SC"
  },
  {
    "Name": "NBN Sinhgad School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.48,
    "Category": "SC"
  },
  {
    "Name": "Institute of Business Management & Research,Chakan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 57.95,
    "Category": "SC"
  },
  {
    "Name": "Shri Shivaji Maratha Societys Institute of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 57.43,
    "Category": "SC"
  },
  {
    "Name": "Dr.D.Y.Patil Institute Of Management & Entrepreneur Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 57.35,
    "Category": "SC"
  },
  {
    "Name": "Tirpude Institute of Management Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.1,
    "Category": "SC"
  },
  {
    "Name": "Tirupati Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "SC"
  },
  {
    "Name": "Deshbhakt Ratnappa Kumbhar College of Commerce (DRKCC, Kolhapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.88,
    "Category": "SC"
  },
  {
    "Name": "Shri Hanuman Vyayam Prasarak Mandal, College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.88,
    "Category": "SC"
  },
  {
    "Name": "JSPMs Narhe Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.56,
    "Category": "SC"
  },
  {
    "Name": "Pramod Ram Ujagar Tiwari Saket Institute Of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 58.54,
    "Category": "SC"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.43,
    "Category": "SC"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Marketing Management",
    "Score": 57.71,
    "Category": "SC"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Marketing Management",
    "Score": 57.38,
    "Category": "SC"
  },
  {
    "Name": "Jhulelal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.42,
    "Category": "SC"
  },
  {
    "Name": "Shivajirao S. Jondhale College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 56.1,
    "Category": "SC"
  },
  {
    "Name": "Institute of Technical Education, Research and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.21,
    "Category": "SC"
  },
  {
    "Name": "D.K.T.E. Society's Textile and Engineering Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.88,
    "Category": "SC"
  },
  {
    "Name": "Sinhgad Institutes - Sinhgad Management Institutes",
    "Course": "Master in Management Studies (MMS)",
    "Score": 54.71,
    "Category": "SC"
  },
  {
    "Name": "Sanjivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.56,
    "Category": "SC"
  },
  {
    "Name": "G H Raisoni College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.56,
    "Category": "SC"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Marketing Management",
    "Score": 53.24,
    "Category": "SC"
  },
  {
    "Name": "Suryadatta Institute of Business Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.49,
    "Category": "SC"
  },
  {
    "Name": "A. C. Patil College of Engineering",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.23,
    "Category": "SC"
  },
  {
    "Name": "Sahyadri Institute of Management & Research,Sawarde",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.23,
    "Category": "SC"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "MBA in Marketing Management",
    "Score": 51.2,
    "Category": "SC"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.9,
    "Category": "SC"
  },
  {
    "Name": "Faculty of Engineering, D.Y Patil Technical Campus -Talsande",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.9,
    "Category": "SC"
  },
  {
    "Name": "SND College of Engineering & Research Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.55,
    "Category": "SC"
  },
  {
    "Name": "Swami Ramanand Teerth Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.31,
    "Category": "SC"
  },
  {
    "Name": "PVG's College of Engineering and Technology and G.K. Pate (Wani) Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 54.56,
    "Category": "SC"
  },
  {
    "Name": "Vidya Pratishthan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.17,
    "Category": "SC"
  },
  {
    "Name": "Gondwana University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.49,
    "Category": "SC"
  },
  {
    "Name": "College Of Management And Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.23,
    "Category": "SC"
  },
  {
    "Name": "RC Patel Institute of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.07,
    "Category": "SC"
  },
  {
    "Name": "Pune Vidyarthi Griha's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "SC"
  },
  {
    "Name": "SBS - Sinhgad Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.9,
    "Category": "SC"
  },
  {
    "Name": "Zeal Institute Of Business Administration, Computer Application and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.81,
    "Category": "SC"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Information Technology Systems Management",
    "Score": 50.34,
    "Category": "SC"
  },
  {
    "Name": "Kala Institute Of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 49.96,
    "Category": "SC"
  },
  {
    "Name": "S B Jain Institute of Technology, Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.96,
    "Category": "SC"
  },
  {
    "Name": "Rjspm's Institute Of Computer And Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.59,
    "Category": "SC"
  },
  {
    "Name": "Vivekanand College, Kolhapur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.48,
    "Category": "SC"
  },
  {
    "Name": "Datta Meghe Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.79,
    "Category": "SC"
  },
  {
    "Name": "Shri Bhairavnath Shikshan Prasarak Mandals Adhalrao Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.79,
    "Category": "SC"
  },
  {
    "Name": "Venkateshwara Institue of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.78,
    "Category": "SC"
  },
  {
    "Name": "Jaihind Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.46,
    "Category": "SC"
  },
  {
    "Name": "SNG Institute Of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.11,
    "Category": "SC"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "SC"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Studies, (MIBS Pune)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "SC"
  },
  {
    "Name": "Amrutvahini College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.59,
    "Category": "SC"
  },
  {
    "Name": "Rasiklal M. Dhariwal Sinhgad Technical Institutes Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 49.59,
    "Category": "SC"
  },
  {
    "Name": "Suryadatta Institute of Management and Mass Communication",
    "Course": "MBA + CDP",
    "Score": 49.23,
    "Category": "SC"
  },
  {
    "Name": "G.H. Raisoni College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.79,
    "Category": "SC"
  },
  {
    "Name": "Rajeev Gandhi College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 48.78,
    "Category": "SC"
  },
  {
    "Name": "Rajarshi Shahu Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 48.78,
    "Category": "SC"
  },
  {
    "Name": "Hon. Shri. Babanrao Pachpute Vichardhara Trust's Parikrama Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.2,
    "Category": "SC"
  },
  {
    "Name": "G H Raisoni School of Business Management, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "SC"
  },
  {
    "Name": "Prin. N.G. Naralkar Institute of Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.57,
    "Category": "SC"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47.55,
    "Category": "SC"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.99,
    "Category": "ST"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.93,
    "Category": "ST"
  },
  {
    "Name": "Xavier Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.86,
    "Category": "ST"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.85,
    "Category": "ST"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "M.B.A. in Business Analytics",
    "Score": 99.68,
    "Category": "ST"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.47,
    "Category": "ST"
  },
  {
    "Name": "Pankaj Laddhad Institute of Technology and Management Studies, Buldana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.32,
    "Category": "ST"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.84,
    "Category": "ST"
  },
  {
    "Name": "Thakur Institute of Management Studies and Research - TIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.57,
    "Category": "ST"
  },
  {
    "Name": "Indira Institute of Management - IIMP",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.29,
    "Category": "ST"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 99.97,
    "Category": "ST"
  },
  {
    "Name": "Maharashtra Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.92,
    "Category": "ST"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.86,
    "Category": "ST"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.83,
    "Category": "ST"
  },
  {
    "Name": "Chetana's Ramprasad Khandelwal Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.67,
    "Category": "ST"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.39,
    "Category": "ST"
  },
  {
    "Name": "Lala Lajpatrai Institute of Management (LLIM)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.3,
    "Category": "ST"
  },
  {
    "Name": "Rizvi Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.6,
    "Category": "ST"
  },
  {
    "Name": "University of Mumbai [MU]",
    "Course": "Discontinued (Dec 2023)- Master of Management Studies (MMS)",
    "Score": 97.59,
    "Category": "ST"
  },
  {
    "Name": "St. Francis Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.94,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 96.35,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 96.35,
    "Category": "ST"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.97,
    "Category": "ST"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94.5,
    "Category": "ST"
  },
  {
    "Name": "GNIMS Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.67,
    "Category": "ST"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.78,
    "Category": "ST"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "MBA in Services Management",
    "Score": 92.25,
    "Category": "ST"
  },
  {
    "Name": "Neville Wadia Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.48,
    "Category": "ST"
  },
  {
    "Name": "AIMS - Atharva Institute of Management Studies",
    "Course": "Master of Management Studies (M.M.S.)",
    "Score": 91.39,
    "Category": "ST"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 91.06,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Financial Management",
    "Score": 96.35,
    "Category": "ST"
  },
  {
    "Name": "Fr. C. Rodrigues Institute of management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.01,
    "Category": "ST"
  },
  {
    "Name": "Indira School of Business Studies - ISBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.03,
    "Category": "ST"
  },
  {
    "Name": "Bharati Vidyapeeth's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.94,
    "Category": "ST"
  },
  {
    "Name": "Durgadevi Saraf Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.05,
    "Category": "ST"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies - JDBIMS",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.76,
    "Category": "ST"
  },
  {
    "Name": "Shri Ramdeobaba College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.21,
    "Category": "ST"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.47,
    "Category": "ST"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.22,
    "Category": "ST"
  },
  {
    "Name": "Department of Business Management - Nagpur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.17,
    "Category": "ST"
  },
  {
    "Name": "DY Patil Akurdi MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "ST"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Business Analytics",
    "Score": 88.77,
    "Category": "ST"
  },
  {
    "Name": "St. John College of Engineering & Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.56,
    "Category": "ST"
  },
  {
    "Name": "Kohinoor Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.43,
    "Category": "ST"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.98,
    "Category": "ST"
  },
  {
    "Name": "D. Y. Patil Institute of Master of Computer Applications and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.98,
    "Category": "ST"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Finance",
    "Score": 85.93,
    "Category": "ST"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.74,
    "Category": "ST"
  },
  {
    "Name": "Mumbai Institute of Management and Research (MIMR)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 85.12,
    "Category": "ST"
  },
  {
    "Name": "Dr. D. Y. Patil Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "ST"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "M.B.A. in Services Management",
    "Score": 88.54,
    "Category": "ST"
  },
  {
    "Name": "SRBS - Sheila Raheja School of Business Management & Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.32,
    "Category": "ST"
  },
  {
    "Name": "Sinhgad College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.43,
    "Category": "ST"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "Master of Management Studies (MMS)",
    "Score": 85.98,
    "Category": "ST"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "MBA in Digital Marketing",
    "Score": 85.98,
    "Category": "ST"
  },
  {
    "Name": "Sant Dnyaneshwar Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.81,
    "Category": "ST"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in information Technology",
    "Score": 85.24,
    "Category": "ST"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in Human Resource Development",
    "Score": 84.04,
    "Category": "ST"
  },
  {
    "Name": "Institute of Industrial and Computer Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.7,
    "Category": "ST"
  },
  {
    "Name": "MGM Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.21,
    "Category": "ST"
  },
  {
    "Name": "Institute of Science Poona's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.13,
    "Category": "ST"
  },
  {
    "Name": "Sasmira's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.49,
    "Category": "ST"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Finance",
    "Score": 82.12,
    "Category": "ST"
  },
  {
    "Name": "Dhananjayrao Gadgil Institute of Co-operative Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.93,
    "Category": "ST"
  },
  {
    "Name": "Maratha Mandirs Babasaheb Gawde Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.7,
    "Category": "ST"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research - PIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.69,
    "Category": "ST"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.29,
    "Category": "ST"
  },
  {
    "Name": "PR Patil College of Engineering And Technology (PRPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.71,
    "Category": "ST"
  },
  {
    "Name": "Navinchandra Mehta Institute of Technology and Development",
    "Course": "Master of Management Studies (MMS)",
    "Score": 76.73,
    "Category": "ST"
  },
  {
    "Name": "Department of Business Administration and Management, Sant Gadge Baba Amravati University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.21,
    "Category": "ST"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 82.75,
    "Category": "ST"
  },
  {
    "Name": "SAS Institute Of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.12,
    "Category": "ST"
  },
  {
    "Name": "Smt. Parmeshwaridevi Durgadutt Tibrewala Lions Juhu College of Arts,Commerce and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.96,
    "Category": "ST"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in FinTech",
    "Score": 79.85,
    "Category": "ST"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in Project Management",
    "Score": 79.7,
    "Category": "ST"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.61,
    "Category": "ST"
  },
  {
    "Name": "All India Shri Shivaji Memorial Society's, Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.8,
    "Category": "ST"
  },
  {
    "Name": "Pibm Tirupati Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.94,
    "Category": "ST"
  },
  {
    "Name": "SIOM - Sinhgad Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.73,
    "Category": "ST"
  },
  {
    "Name": "Rajarshi Shahu College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.59,
    "Category": "ST"
  },
  {
    "Name": "Sir M Visvesvaraya Institute of Management Studies and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 76.39,
    "Category": "ST"
  },
  {
    "Name": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.49,
    "Category": "ST"
  },
  {
    "Name": "MET's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "ST"
  },
  {
    "Name": "Dr.BV Hiray College Of Management and Research Centre, Malegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.4,
    "Category": "ST"
  },
  {
    "Name": "Dr. V. N. Bedekar Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 72.86,
    "Category": "ST"
  },
  {
    "Name": "Anjuman-I-Islam's Allana Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 72.48,
    "Category": "ST"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.48,
    "Category": "ST"
  },
  {
    "Name": "NCRDs Sterling Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.34,
    "Category": "ST"
  },
  {
    "Name": "Gourishankar Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.85,
    "Category": "ST"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.45,
    "Category": "ST"
  },
  {
    "Name": "Smt. Kashibai Navale College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.86,
    "Category": "ST"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "ST"
  },
  {
    "Name": "Yeshwantrao Chavan College of Engineering, Nagar Yuwak Shikshan Sanstha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.4,
    "Category": "ST"
  },
  {
    "Name": "Sahayog Educational Campus Indira College of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.25,
    "Category": "ST"
  },
  {
    "Name": "S.B Patil Institute of Management, Pimpri Chinchwad Educations Trust's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.86,
    "Category": "ST"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Management (MIBM Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.48,
    "Category": "ST"
  },
  {
    "Name": "SSVPS's Bapusaheb Shivajirao Deore College of Engineering (SSVPS's BSD COE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.78,
    "Category": "ST"
  },
  {
    "Name": "Vidyavardhini's College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.31,
    "Category": "ST"
  },
  {
    "Name": "Kalyani Charitable Trust's K.R.Sapkal College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.34,
    "Category": "ST"
  },
  {
    "Name": "K. K. Wagh Institute of Engineering Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.21,
    "Category": "ST"
  },
  {
    "Name": "Shri Sai Institute of Management & Research, Aurangabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.22,
    "Category": "ST"
  },
  {
    "Name": "Sadhu Vaswani Institute of Management Studies for Girls (SVIMS Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.05,
    "Category": "ST"
  },
  {
    "Name": "Sipna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.44,
    "Category": "ST"
  },
  {
    "Name": "Al Ameen Education & Medical Foundations, College of Engineering & Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.27,
    "Category": "ST"
  },
  {
    "Name": "Arihant Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.15,
    "Category": "ST"
  },
  {
    "Name": "G H Raisoni Institute of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.81,
    "Category": "ST"
  },
  {
    "Name": "Mahatma Phule Institute of Management and Computer Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "ST"
  },
  {
    "Name": "Trinity College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "ST"
  },
  {
    "Name": "GNVS Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.29,
    "Category": "ST"
  },
  {
    "Name": "Department of Commerce and Management - MBA Unit, Shivaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.21,
    "Category": "ST"
  },
  {
    "Name": "Indira Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 67.17,
    "Category": "ST"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.44,
    "Category": "ST"
  },
  {
    "Name": "IndSearch Institute of Management Studies and Research, Bavdhan Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.27,
    "Category": "ST"
  },
  {
    "Name": "Sau Leena Kishor Mamidwar Institute of Mananagement Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.01,
    "Category": "ST"
  },
  {
    "Name": "MMM's Institute of Management Education Research and Training",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.86,
    "Category": "ST"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "MBA in Human Resource Management",
    "Score": 63.81,
    "Category": "ST"
  },
  {
    "Name": "Progressive Education Society's Modern College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "ST"
  },
  {
    "Name": "Jayawant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.39,
    "Category": "ST"
  },
  {
    "Name": "OIM - Oriental Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.1,
    "Category": "ST"
  },
  {
    "Name": "North Maharashtra University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "ST"
  },
  {
    "Name": "SIMCA - Sinhgad Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "ST"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Human Resource Management",
    "Score": 63.03,
    "Category": "ST"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Human Resource Management",
    "Score": 62.17,
    "Category": "ST"
  },
  {
    "Name": "SKN Sinhgad School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.77,
    "Category": "ST"
  },
  {
    "Name": "MES Institute of Management and Career Courses",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 61.49,
    "Category": "ST"
  },
  {
    "Name": "Tirpude Institute of Management Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.01,
    "Category": "ST"
  },
  {
    "Name": "Vilasrao Deshmukh College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.16,
    "Category": "ST"
  },
  {
    "Name": "Sinhgad Institutes - Sinhgad Management Institutes",
    "Course": "Master in Management Studies (MMS)",
    "Score": 60.13,
    "Category": "ST"
  },
  {
    "Name": "Jayawantrao Sawant Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.43,
    "Category": "ST"
  },
  {
    "Name": "Marathwada Mitra Mandal's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "ST"
  },
  {
    "Name": "Suryadatta Institute of Business Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "ST"
  },
  {
    "Name": "Ahirkar Institute of Management Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 62.17,
    "Category": "ST"
  },
  {
    "Name": "ASMA Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.77,
    "Category": "ST"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.64,
    "Category": "ST"
  },
  {
    "Name": "J.D.C. Bytco Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.1,
    "Category": "ST"
  },
  {
    "Name": "Ashoka Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.01,
    "Category": "ST"
  },
  {
    "Name": "Jayawantrao Sawant College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.13,
    "Category": "ST"
  },
  {
    "Name": "G S College of Commerce and Economics",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.13,
    "Category": "ST"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.43,
    "Category": "ST"
  },
  {
    "Name": "Dhole Patil College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "ST"
  },
  {
    "Name": "SIBAR - Sinhgad Institute of Business Administration and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "ST"
  },
  {
    "Name": "KIT's Institute of Management Education and Research (KIT's IMER)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.12,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.44,
    "Category": "ST"
  },
  {
    "Name": "Nashik District Maratha Vidya Prasarak Samaj's Karmaveer Adv. Baburao Thakare College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.53,
    "Category": "ST"
  },
  {
    "Name": "Tirupati Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "ST"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "ST"
  },
  {
    "Name": "ASM's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "ST"
  },
  {
    "Name": "Dr. Moonje Institute of Management and Computer Studies,Nashik",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.57,
    "Category": "ST"
  },
  {
    "Name": "Sinhgad Institute of Business Administration and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 54.03,
    "Category": "ST"
  },
  {
    "Name": "G.H. Raisoni College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "ST"
  },
  {
    "Name": "Vidya Bharati Mahavidyalaya, Amravati",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "ST"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Marketing Management",
    "Score": 57.71,
    "Category": "ST"
  },
  {
    "Name": "Natwarlal Maniklal Dalal Postgraduate College of Arts and Commerce (N.M.D College)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.33,
    "Category": "ST"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 55.34,
    "Category": "ST"
  },
  {
    "Name": "Tulsiramji Gaikwad-Patil College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "ST"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "ST"
  },
  {
    "Name": "Prof. Ram Meghe Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "ST"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Finance",
    "Score": 54.05,
    "Category": "ST"
  },
  {
    "Name": "Rohidas Patil Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 54.03,
    "Category": "ST"
  },
  {
    "Name": "D.K.T.E. Society's Textile and Engineering Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.77,
    "Category": "ST"
  },
  {
    "Name": "Institute of Business Management & Research,Chakan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.44,
    "Category": "ST"
  },
  {
    "Name": "Kala Institute Of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.56,
    "Category": "ST"
  },
  {
    "Name": "Suryadatta Institute of Management and Mass Communication",
    "Course": "MBA + CDP",
    "Score": 52.17,
    "Category": "ST"
  },
  {
    "Name": "Rjspm's Institute Of Computer And Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "ST"
  },
  {
    "Name": "Alamuri Ratnamala Institute of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.12,
    "Category": "ST"
  },
  {
    "Name": "Sankalp Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "ST"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.52,
    "Category": "ST"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.34,
    "Category": "ST"
  },
  {
    "Name": "Vidya Pratishthan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.09,
    "Category": "ST"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.65,
    "Category": "ST"
  },
  {
    "Name": "SOU Devibai Narayandas Chhabada Rural Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.02,
    "Category": "ST"
  },
  {
    "Name": "Alard University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "ST"
  },
  {
    "Name": "Shri Hanuman Vyayam Prasarak Mandal, College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "ST"
  },
  {
    "Name": "G H Raisoni College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "ST"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "MBA in Marketing Management",
    "Score": 51.2,
    "Category": "ST"
  },
  {
    "Name": "Pune Vidyarthi Griha's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "ST"
  },
  {
    "Name": "K.C.College of Engineering and Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50.35,
    "Category": "ST"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Information Technology Systems Management",
    "Score": 50.34,
    "Category": "ST"
  },
  {
    "Name": "G H Raisoni School of Business Management, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.09,
    "Category": "ST"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Technological University - DBATU",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 49.86,
    "Category": "ST"
  },
  {
    "Name": "VIVA Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 49.08,
    "Category": "ST"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Marketing Management",
    "Score": 48.79,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.51,
    "Category": "ST"
  },
  {
    "Name": "Swayam Siddhi Mitra Sangh'S College of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 47.4,
    "Category": "ST"
  },
  {
    "Name": "JSPMs Narhe Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "ST"
  },
  {
    "Name": "Allana Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "ST"
  },
  {
    "Name": "Dr.D.Y. Patil Center for Management and Research(MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.52,
    "Category": "ST"
  },
  {
    "Name": "S B Jain Institute of Technology, Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.48,
    "Category": "ST"
  },
  {
    "Name": "Shri Shivaji Maratha Societys Institute of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "ST"
  },
  {
    "Name": "Dr. Arun Motghare Mahavidyalaya",
    "Course": "Master of Business Administration(MBA)",
    "Score": 49.65,
    "Category": "ST"
  },
  {
    "Name": "Genba Sopanrao Moze College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.86,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in IT",
    "Score": 48.55,
    "Category": "ST"
  },
  {
    "Name": "NBN Sinhgad School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.5,
    "Category": "ST"
  },
  {
    "Name": "Pratibha Institute of Business Management (Kamala Education Society's)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "ST"
  },
  {
    "Name": "JSPMs Kautilya Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "ST"
  },
  {
    "Name": "Datta Meghe Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.52,
    "Category": "ST"
  },
  {
    "Name": "SBS - Sinhgad Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.5,
    "Category": "ST"
  },
  {
    "Name": "Imperial College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.29,
    "Category": "ST"
  },
  {
    "Name": "Swami Ramanand Teerth Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "ST"
  },
  {
    "Name": "MKSSs Smt. Hiraben Nanavati Institute of Management and Research for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "ST"
  },
  {
    "Name": "Dr. Panjabrao Deshmukh Institute of Management Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "ST"
  },
  {
    "Name": "Institute of Technical Education, Research and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "ST"
  },
  {
    "Name": "Chintamanrao Institute of Management Development and Research (CIMDR Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.96,
    "Category": "ST"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 44.35,
    "Category": "ST"
  },
  {
    "Name": "Hirachand Nemchand College of Commerce (HNCC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.85,
    "Category": "ST"
  },
  {
    "Name": "Unique Institute of Management (UIM, Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.85,
    "Category": "ST"
  },
  {
    "Name": "College Of Management And Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.15,
    "Category": "ST"
  },
  {
    "Name": "Sanjivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.78,
    "Category": "ST"
  },
  {
    "Name": "Deshbhakt Ratnappa Kumbhar College of Commerce (DRKCC, Kolhapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "ST"
  },
  {
    "Name": "Priyadarshini Lokmanya Tilak Institute of Management Studies and Research (PLTIMSR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "ST"
  },
  {
    "Name": "Dr.D.Y.Patil Institute Of Management & Entrepreneur Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "ST"
  },
  {
    "Name": "Shri Neminath Jain Bhhramcharyashram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.13,
    "Category": "ST"
  },
  {
    "Name": "Rasiklal M Dhariwal Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.83,
    "Category": "ST"
  },
  {
    "Name": "Zeal Institute Of Business Administration, Computer Application and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.02,
    "Category": "ST"
  },
  {
    "Name": "Faculty of Engineering, D.Y Patil Technical Campus -Talsande",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.99,
    "Category": "ST"
  },
  {
    "Name": "Shivajirao S. Jondhale College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 42.7,
    "Category": "ST"
  },
  {
    "Name": "Tasgaonkar Group of Institutions",
    "Course": "Master of Management Studies (MMS)",
    "Score": 41.78,
    "Category": "ST"
  },
  {
    "Name": "G.S College of Commerce,Wardha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.78,
    "Category": "ST"
  },
  {
    "Name": "SVPM's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "ST"
  },
  {
    "Name": "Prin. N.G. Naralkar Institute of Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "ST"
  },
  {
    "Name": "Siddhant Institute of Business Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.92,
    "Category": "ST"
  },
  {
    "Name": "NavSahyadri Charitable Trust's, Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.64,
    "Category": "ST"
  },
  {
    "Name": "A. C. Patil College of Engineering",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.07,
    "Category": "ST"
  },
  {
    "Name": "Adsul's Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.5,
    "Category": "ST"
  },
  {
    "Name": "G H Raisoni Institute of Management and Research, Khaparheda Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "ST"
  },
  {
    "Name": "KDK College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "ST"
  },
  {
    "Name": "SGSJK's Aruna Manharlal Shah Institute of Management and Research (AMSIMR, Mumbai)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.14,
    "Category": "ST"
  },
  {
    "Name": "Rajaram Shinde College of MBA",
    "Course": "Masters of Management Studies (MMS)",
    "Score": 37.59,
    "Category": "ST"
  },
  {
    "Name": "Central India College of Business Management and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.43,
    "Category": "ST"
  },
  {
    "Name": "Rajeev Gandhi College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.95,
    "Category": "ST"
  },
  {
    "Name": "D B Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.88,
    "Category": "ST"
  },
  {
    "Name": "Foster Development School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.42,
    "Category": "ST"
  },
  {
    "Name": "Hashmatrai and Gangaram Himathmal Mansukhani Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.52,
    "Category": "ST"
  },
  {
    "Name": "SNG Institute Of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.47,
    "Category": "ST"
  },
  {
    "Name": "Deogiri Institute of Engineering and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "ST"
  },
  {
    "Name": "Sura Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.3,
    "Category": "ST"
  },
  {
    "Name": "R.M. Dhariwal Sinhgad Management School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.14,
    "Category": "ST"
  },
  {
    "Name": "Karmaveer Bhaurao Patil Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.47,
    "Category": "ST"
  },
  {
    "Name": "Pramod Ram Ujagar Tiwari Saket Institute Of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 36.51,
    "Category": "ST"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 36.3,
    "Category": "ST"
  },
  {
    "Name": "Swargiya Shri Laxmanji Motghare Charitable Trust, Dr. Arun Motghare College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.99,
    "Category": "ST"
  },
  {
    "Name": "Matrix Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.08,
    "Category": "ST"
  },
  {
    "Name": "Amrutvahini Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "ST"
  },
  {
    "Name": "Pune Vidyarthi Griha's College Of Engineering",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.83,
    "Category": "ST"
  },
  {
    "Name": "Matoshri College of Management & Research Centre,Eklahare",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.82,
    "Category": "ST"
  },
  {
    "Name": "PVG's College of Engineering and Technology and G.K. Pate (Wani) Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.77,
    "Category": "ST"
  },
  {
    "Name": "Smt. Radhikatai Pandav College of Engineering (SRPCE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.02,
    "Category": "ST"
  },
  {
    "Name": "Punyashlok Ahilyadevi Holkar Solapur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.98,
    "Category": "ST"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 33.16,
    "Category": "ST"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.3,
    "Category": "ST"
  },
  {
    "Name": "Venkateshwara Institue of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.85,
    "Category": "ST"
  },
  {
    "Name": "SND College of Engineering & Research Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "ST"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Studies, (MIBS Pune)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "ST"
  },
  {
    "Name": "Amrutvahini College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.82,
    "Category": "ST"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 34.82,
    "Category": "ST"
  },
  {
    "Name": "Pravara Institute of Research and Education in Natural and Social Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.11,
    "Category": "ST"
  },
  {
    "Name": "Wainganga College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.01,
    "Category": "ST"
  },
  {
    "Name": "Ozar Vikas Sanstha's Vishwasatya Arts and Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.34,
    "Category": "ST"
  },
  {
    "Name": "Swayam Siddhi College of Management and Research (SSCMR)",
    "Course": "Master in Management Studies (MMS)",
    "Score": 33.13,
    "Category": "ST"
  },
  {
    "Name": "Anekant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.96,
    "Category": "ST"
  },
  {
    "Name": "Navjeevan Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "ST"
  },
  {
    "Name": "Swaminarayan Siddhanta Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in Human Resource Development",
    "Score": 31.21,
    "Category": "ST"
  },
  {
    "Name": "Suryodaya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "ST"
  },
  {
    "Name": "YRES's Bapurao Deshmukh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.03,
    "Category": "ST"
  },
  {
    "Name": "Sai Krupa Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.56,
    "Category": "ST"
  },
  {
    "Name": "Dattakala Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.97,
    "Category": "ST"
  },
  {
    "Name": "Rajendra Mane College of Engineering and Technology (RMCET)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 28.65,
    "Category": "ST"
  },
  {
    "Name": "Rajgad Institute of Management Research & Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.03,
    "Category": "ST"
  },
  {
    "Name": "Brahmdevdada Mane Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.92,
    "Category": "ST"
  },
  {
    "Name": "ASM Institute of Professional Studies, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "ST"
  },
  {
    "Name": "Yashoda Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "ST"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "ST"
  },
  {
    "Name": "Ranibai Agnihotri Institute of Computer Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.03,
    "Category": "ST"
  },
  {
    "Name": "Brahma Valley College of Engineering and Research Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.56,
    "Category": "ST"
  },
  {
    "Name": "Institute Of Management and Science,Sakegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.18,
    "Category": "ST"
  },
  {
    "Name": "Zeal College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.68,
    "Category": "ST"
  },
  {
    "Name": "Parvatibai Genba Moze College of Engineering",
    "Course": "Master of business Administration (MBA)",
    "Score": 28.25,
    "Category": "ST"
  },
  {
    "Name": "P.K. Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.88,
    "Category": "ST"
  },
  {
    "Name": "Sir Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.88,
    "Category": "ST"
  },
  {
    "Name": "Nirmal Krida and Samaj Prabodhan Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.72,
    "Category": "ST"
  },
  {
    "Name": "Shri Bhairavnath Shikshan Prasarak Mandals Adhalrao Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.5,
    "Category": "ST"
  },
  {
    "Name": "D. B. Group of Institutes, Latur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "ST"
  },
  {
    "Name": "Prin. K.P. Mangalvedhekar Institute of Management Career Development and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 26.46,
    "Category": "ST"
  },
  {
    "Name": "Matoshri Ushatai Jadhav Institute of Management Studies and Research Centre (MUIMRC)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25.25,
    "Category": "ST"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Marketing Management",
    "Score": 25.25,
    "Category": "ST"
  },
  {
    "Name": "Shri Vithal Education and Research Institute's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "ST"
  },
  {
    "Name": "Ashokrao Mane Group of Institutions (AMGOI)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.47,
    "Category": "ST"
  },
  {
    "Name": "K.B.H. Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "ST"
  },
  {
    "Name": "Matsyodari Shikshan Sanstha's College of Engineering and Technology, Jalna",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.72,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management and Business Administration, Akole",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.5,
    "Category": "ST"
  },
  {
    "Name": "Dr. Vithalrao Vikhe Patil Foundation's College of Pharmacy",
    "Course": "Master of Business Administration(MBA)",
    "Score": 27.5,
    "Category": "ST"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 26.57,
    "Category": "ST"
  },
  {
    "Name": "Sahyadri Institute of Management & Research,Sawarde",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25.28,
    "Category": "ST"
  },
  {
    "Name": "JD College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.25,
    "Category": "ST"
  },
  {
    "Name": "Navsahyadri Education Society's Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "ST"
  },
  {
    "Name": "International Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "ST"
  },
  {
    "Name": "Hon. Shri. Babanrao Pachpute Vichardhara Trust's Parikrama Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.47,
    "Category": "ST"
  },
  {
    "Name": "Kamla Nehru Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "ST"
  },
  {
    "Name": "Rasiklal M. Dhariwal Sinhgad Technical Institutes Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "ST"
  },
  {
    "Name": "Shri Someshwar Shikshan Prasarak Mandals Sharadchandra Pawar Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "ST"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "ST"
  },
  {
    "Name": "M.S. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "ST"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Agri Business Management/ Entrepreneurship",
    "Score": 22.41,
    "Category": "ST"
  },
  {
    "Name": "Aakar Institute of Management and Research Studies",
    "Course": "Master of Marketing Management",
    "Score": 21.73,
    "Category": "ST"
  },
  {
    "Name": "KJ's Educational Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.3,
    "Category": "ST"
  },
  {
    "Name": "Abasaheb Garware Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.47,
    "Category": "ST"
  },
  {
    "Name": "Pirens Institute of Business Management and Administration",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "ST"
  },
  {
    "Name": "Maharashtra Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.23,
    "Category": "ST"
  },
  {
    "Name": "Institute of Business Management and Rural Development (IMBRD)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "ST"
  },
  {
    "Name": "Sanjivani Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "ST"
  },
  {
    "Name": "Kasturi Shikshan Sansta's Institute of Management, Shikhrapur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "ST"
  },
  {
    "Name": "SSBTs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "ST"
  },
  {
    "Name": "KT Patil College of Engineering and Technology, Osmanabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.75,
    "Category": "ST"
  },
  {
    "Name": "U.D. Pathrikar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.36,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.27,
    "Category": "ST"
  },
  {
    "Name": "Shri Khanderai Pratishthan Dnyansagar Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "ST"
  },
  {
    "Name": "Mandukarrao Pandav College of Engineering, Bhandara",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "ST"
  },
  {
    "Name": "Real Institute Of Management And Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.65,
    "Category": "ST"
  },
  {
    "Name": "Shantiniketan Business School (SBS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.6,
    "Category": "ST"
  },
  {
    "Name": "ZEAL Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.57,
    "Category": "ST"
  },
  {
    "Name": "Global Institute of Management,Sangamner",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "ST"
  },
  {
    "Name": "Nabira Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "ST"
  },
  {
    "Name": "Central Institute of Business Management Research and Development (CIBMRD Nagpur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.13,
    "Category": "ST"
  },
  {
    "Name": "Nashik Gramin Shikshan Prasarak Mandal, Brahma Valley Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.11,
    "Category": "ST"
  },
  {
    "Name": "Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.48,
    "Category": "ST"
  },
  {
    "Name": "Ballarpur Institute of Technology (BIT Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.02,
    "Category": "ST"
  },
  {
    "Name": "Rajarshi Shahu Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 13.36,
    "Category": "ST"
  },
  {
    "Name": "V.P. Institute of Management Studies & Research,Sangli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.23,
    "Category": "ST"
  },
  {
    "Name": "CP and Berar Arts Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.57,
    "Category": "ST"
  },
  {
    "Name": "International Centre of Excellence in Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.3,
    "Category": "ST"
  },
  {
    "Name": "Sharadchandra Pawar Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "ST"
  },
  {
    "Name": "Jhulelal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.47,
    "Category": "ST"
  },
  {
    "Name": "Ideal Institute of Management, Kondigre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.48,
    "Category": "ST"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.67,
    "Category": "ST"
  },
  {
    "Name": "Shreeyash College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.01,
    "Category": "ST"
  },
  {
    "Name": "Nagpur Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.84,
    "Category": "ST"
  },
  {
    "Name": "Chetan Dattaji Gaikwad Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.52,
    "Category": "ST"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "M.B.A in FinTech",
    "Score": 12.12,
    "Category": "ST"
  },
  {
    "Name": "Pune District Education Association's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.92,
    "Category": "ST"
  },
  {
    "Name": "Marathwada Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.57,
    "Category": "ST"
  },
  {
    "Name": "Mahatma Gandhi Vidyamandir's Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.04,
    "Category": "ST"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "MBA in Environment Management",
    "Score": 9.94,
    "Category": "ST"
  },
  {
    "Name": "Samarth Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.79,
    "Category": "ST"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "MBA in Family Managed Business",
    "Score": 8.71,
    "Category": "ST"
  },
  {
    "Name": "Swaraj Education Society's Swaraj Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.13,
    "Category": "ST"
  },
  {
    "Name": "Matoshri Pratishthan Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "ST"
  },
  {
    "Name": "Guru Nanak Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "ST"
  },
  {
    "Name": "Shri Swami Samarth Institute of Management & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "ST"
  },
  {
    "Name": "Shrimant Jayshreemaladevi Naik-nimbalkar Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.78,
    "Category": "ST"
  },
  {
    "Name": "Dnyansagar Institute of Management and Research-DIMR",
    "Course": "Master of Business Administration (Prime)",
    "Score": 10.38,
    "Category": "ST"
  },
  {
    "Name": "Govindrao Wanjari College of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.97,
    "Category": "ST"
  },
  {
    "Name": "Jaywant Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.62,
    "Category": "ST"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Marketing",
    "Score": 8.79,
    "Category": "ST"
  },
  {
    "Name": "Green Heaven Institute of Management and Research (GHIMR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.7,
    "Category": "ST"
  },
  {
    "Name": "J.M.Patel Arts,Commerce and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7.06,
    "Category": "ST"
  },
  {
    "Name": "Shreeyash Institute of Management (SIM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "ST"
  },
  {
    "Name": "Jai Hind College, Mumbai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.19,
    "Category": "ST"
  },
  {
    "Name": "Dr. R. G. Bhoyar Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.48,
    "Category": "ST"
  },
  {
    "Name": "Renaissance Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.07,
    "Category": "ST"
  },
  {
    "Name": "Godavari Institute Of Management & Research, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.79,
    "Category": "ST"
  },
  {
    "Name": "Nagarjuna Institute of Engineering, Technology & Management",
    "Course": "MBA in Finance Marketing and Human Resource Management",
    "Score": 3.51,
    "Category": "ST"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Finance",
    "Score": 2.39,
    "Category": "ST"
  },
  {
    "Name": "Kasturi Education Sanstha's College of Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "ST"
  },
  {
    "Name": "Vishwakarma Dadasaheb Chavan Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.24,
    "Category": "ST"
  },
  {
    "Name": "Aditya Engineering College, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.86,
    "Category": "ST"
  },
  {
    "Name": "Akole Taluka Education Societys Technical Campus College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.83,
    "Category": "ST"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Business Analytics",
    "Score": 1.13,
    "Category": "ST"
  },
  {
    "Name": "University Sub Center Latur",
    "Course": "Master of Business Administration(MBA)",
    "Score": 0.3,
    "Category": "ST"
  },
  {
    "Name": "Jay Bhavani Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 4.47,
    "Category": "ST"
  },
  {
    "Name": "AMI Pune Arham Adhyan Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.79,
    "Category": "ST"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Marketing Management",
    "Score": 3.25,
    "Category": "ST"
  },
  {
    "Name": "Dattakala Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "ST"
  },
  {
    "Name": "Sanjay Bhokare Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "ST"
  },
  {
    "Name": "Shikshan Prasarak Mandal's Deptartment of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.01,
    "Category": "ST"
  },
  {
    "Name": "Institute of Technology and Management, Nanded",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.83,
    "Category": "ST"
  },
  {
    "Name": "S.A.V Acharya Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 1.29,
    "Category": "ST"
  },
  {
    "Name": "Shivajirao S. Jondhle Institute of Management Science and Research",
    "Course": "Master of Management Studies",
    "Score": 0.6,
    "Category": "ST"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.11,
    "Category": "ST"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.99,
    "Category": "PWD"
  },
  {
    "Name": "Prin. L. N. Welingkar Institute of Management Development and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.93,
    "Category": "PWD"
  },
  {
    "Name": "Xavier Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.86,
    "Category": "PWD"
  },
  {
    "Name": "SIES College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.85,
    "Category": "PWD"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "M.B.A. in Business Analytics",
    "Score": 99.68,
    "Category": "PWD"
  },
  {
    "Name": "MET Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.47,
    "Category": "PWD"
  },
  {
    "Name": "Pankaj Laddhad Institute of Technology and Management Studies, Buldana",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.32,
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Marketing Management",
    "Score": 99.26,
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "Master of Human Resource Development (MHRD)",
    "Score": 99.26,
    "Category": "PWD"
  },
  {
    "Name": "Rizvi Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.6,
    "Category": "PWD"
  },
  {
    "Name": "SIMSREE - Sydenham Institute of Management Studies Research and Entrepreneurship Education",
    "Course": "Masters in Management Studies (MMS)",
    "Score": 99.97,
    "Category": "PWD"
  },
  {
    "Name": "Maharashtra Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.92,
    "Category": "PWD"
  },
  {
    "Name": "Department of Management Sciences, Pune University (PUMBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.86,
    "Category": "PWD"
  },
  {
    "Name": "College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.83,
    "Category": "PWD"
  },
  {
    "Name": "Chetana's Ramprasad Khandelwal Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.67,
    "Category": "PWD"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 99.39,
    "Category": "PWD"
  },
  {
    "Name": "Lala Lajpatrai Institute of Management (LLIM)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 99.3,
    "Category": "PWD"
  },
  {
    "Name": "Jamnalal Bajaj Institute of Management Studies",
    "Course": "MMS in Financial Management",
    "Score": 99.26,
    "Category": "PWD"
  },
  {
    "Name": "VESIM - Vivekanand Education Society Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.84,
    "Category": "PWD"
  },
  {
    "Name": "Thakur Institute of Management Studies and Research - TIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 98.57,
    "Category": "PWD"
  },
  {
    "Name": "University of Mumbai [MU]",
    "Course": "Discontinued (Dec 2023)- Master of Management Studies (MMS)",
    "Score": 97.59,
    "Category": "PWD"
  },
  {
    "Name": "St. Francis Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.94,
    "Category": "PWD"
  },
  {
    "Name": "UNIPUNE - Savitribai Phule Pune University (SPPU)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.97,
    "Category": "PWD"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 94.5,
    "Category": "PWD"
  },
  {
    "Name": "GNIMS Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.67,
    "Category": "PWD"
  },
  {
    "Name": "VIT - Vidyalankar Institute of Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.78,
    "Category": "PWD"
  },
  {
    "Name": "Indira College of Engineering and Management, Indira Group of Institutes",
    "Course": "MBA in Services Management",
    "Score": 92.25,
    "Category": "PWD"
  },
  {
    "Name": "Neville Wadia Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.48,
    "Category": "PWD"
  },
  {
    "Name": "AIMS - Atharva Institute of Management Studies",
    "Course": "Master of Management Studies (M.M.S.)",
    "Score": 91.39,
    "Category": "PWD"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 91.06,
    "Category": "PWD"
  },
  {
    "Name": "Indira Institute of Management - IIMP",
    "Course": "Master of Business Administration (MBA)",
    "Score": 97.29,
    "Category": "PWD"
  },
  {
    "Name": "Fr. C. Rodrigues Institute of management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 96.01,
    "Category": "PWD"
  },
  {
    "Name": "Indira School of Business Studies - ISBS",
    "Course": "Master of Business Administration (MBA)",
    "Score": 95.03,
    "Category": "PWD"
  },
  {
    "Name": "Bharati Vidyapeeth's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.94,
    "Category": "PWD"
  },
  {
    "Name": "Durgadevi Saraf Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 93.05,
    "Category": "PWD"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies - JDBIMS",
    "Course": "Master of Management Studies (MMS)",
    "Score": 92.76,
    "Category": "PWD"
  },
  {
    "Name": "Shri Ramdeobaba College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 92.21,
    "Category": "PWD"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.47,
    "Category": "PWD"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 91.22,
    "Category": "PWD"
  },
  {
    "Name": "Department of Business Management - Nagpur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.17,
    "Category": "PWD"
  },
  {
    "Name": "DY Patil Akurdi MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "PWD"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Business Analytics",
    "Score": 88.77,
    "Category": "PWD"
  },
  {
    "Name": "St. John College of Engineering & Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.56,
    "Category": "PWD"
  },
  {
    "Name": "Kohinoor Business School",
    "Course": "Master of Management Studies (MMS)",
    "Score": 86.43,
    "Category": "PWD"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.98,
    "Category": "PWD"
  },
  {
    "Name": "D. Y. Patil Institute of Master of Computer Applications and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.98,
    "Category": "PWD"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Finance",
    "Score": 85.93,
    "Category": "PWD"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.74,
    "Category": "PWD"
  },
  {
    "Name": "Mumbai Institute of Management and Research (MIMR)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 85.12,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Management and Human Resource Development, Sai Balaji Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.98,
    "Category": "PWD"
  },
  {
    "Name": "Dr. D. Y. Patil Institute Of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 89.01,
    "Category": "PWD"
  },
  {
    "Name": "Indira Global Business School",
    "Course": "M.B.A. in Services Management",
    "Score": 88.54,
    "Category": "PWD"
  },
  {
    "Name": "SRBS - Sheila Raheja School of Business Management & Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 87.32,
    "Category": "PWD"
  },
  {
    "Name": "Sinhgad College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 86.43,
    "Category": "PWD"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "Master of Management Studies (MMS)",
    "Score": 85.98,
    "Category": "PWD"
  },
  {
    "Name": "Dr. D. Y. Patil Institute of Management and Research",
    "Course": "MBA in Digital Marketing",
    "Score": 85.98,
    "Category": "PWD"
  },
  {
    "Name": "Sant Dnyaneshwar Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 85.81,
    "Category": "PWD"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in information Technology",
    "Score": 85.24,
    "Category": "PWD"
  },
  {
    "Name": "Poona Institute of Management Sciences and Entrepreneurship",
    "Course": "MBA in Human Resource Development",
    "Score": 84.04,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Industrial and Computer Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.7,
    "Category": "PWD"
  },
  {
    "Name": "MGM Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 83.21,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Science Poona's Institute of Business Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.13,
    "Category": "PWD"
  },
  {
    "Name": "Sasmira's Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.49,
    "Category": "PWD"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Finance",
    "Score": 82.12,
    "Category": "PWD"
  },
  {
    "Name": "Dhananjayrao Gadgil Institute of Co-operative Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.93,
    "Category": "PWD"
  },
  {
    "Name": "Maratha Mandirs Babasaheb Gawde Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.7,
    "Category": "PWD"
  },
  {
    "Name": "Pillai Institute of Management Studies and Research - PIMSR",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.69,
    "Category": "PWD"
  },
  {
    "Name": "RIIM - Arihant Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 78.29,
    "Category": "PWD"
  },
  {
    "Name": "PR Patil College of Engineering And Technology (PRPCET)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.71,
    "Category": "PWD"
  },
  {
    "Name": "Navinchandra Mehta Institute of Technology and Development",
    "Course": "Master of Management Studies (MMS)",
    "Score": 76.73,
    "Category": "PWD"
  },
  {
    "Name": "Department of Business Administration and Management, Sant Gadge Baba Amravati University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 83.21,
    "Category": "PWD"
  },
  {
    "Name": "Parle Tilak Vidyalaya Associations Institute of Management - PTVAIM",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 82.75,
    "Category": "PWD"
  },
  {
    "Name": "SAS Institute Of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 82.12,
    "Category": "PWD"
  },
  {
    "Name": "Smt. Parmeshwaridevi Durgadutt Tibrewala Lions Juhu College of Arts,Commerce and Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 79.96,
    "Category": "PWD"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in FinTech",
    "Score": 79.85,
    "Category": "PWD"
  },
  {
    "Name": "Pune Institute of Business Management",
    "Course": "MBA in Project Management",
    "Score": 79.7,
    "Category": "PWD"
  },
  {
    "Name": "AIMSR - Aditya Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 79.61,
    "Category": "PWD"
  },
  {
    "Name": "All India Shri Shivaji Memorial Society's, Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 77.8,
    "Category": "PWD"
  },
  {
    "Name": "Pibm Tirupati Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.94,
    "Category": "PWD"
  },
  {
    "Name": "SIOM - Sinhgad Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.73,
    "Category": "PWD"
  },
  {
    "Name": "Rajarshi Shahu College of Engineering, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.59,
    "Category": "PWD"
  },
  {
    "Name": "Sir M Visvesvaraya Institute of Management Studies and Research, Mumbai",
    "Course": "Master of Management Studies (MMS)",
    "Score": 76.39,
    "Category": "PWD"
  },
  {
    "Name": "Shri Sant Gajanan Maharaj College of Engineering, Shegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.49,
    "Category": "PWD"
  },
  {
    "Name": "MET's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "PWD"
  },
  {
    "Name": "Dr.BV Hiray College Of Management and Research Centre, Malegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.4,
    "Category": "PWD"
  },
  {
    "Name": "Dr. V. N. Bedekar Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 72.86,
    "Category": "PWD"
  },
  {
    "Name": "Anjuman-I-Islam's Allana Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 72.48,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Ambedkar Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.48,
    "Category": "PWD"
  },
  {
    "Name": "NCRDs Sterling Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.34,
    "Category": "PWD"
  },
  {
    "Name": "Gourishankar Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.85,
    "Category": "PWD"
  },
  {
    "Name": "Sai Balaji International Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 76.45,
    "Category": "PWD"
  },
  {
    "Name": "Smt. Kashibai Navale College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 75.86,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.82,
    "Category": "PWD"
  },
  {
    "Name": "Yeshwantrao Chavan College of Engineering, Nagar Yuwak Shikshan Sanstha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 74.4,
    "Category": "PWD"
  },
  {
    "Name": "Sahayog Educational Campus Indira College of Pharmacy",
    "Course": "Master of Business Administration (MBA)",
    "Score": 73.25,
    "Category": "PWD"
  },
  {
    "Name": "S.B Patil Institute of Management, Pimpri Chinchwad Educations Trust's",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.86,
    "Category": "PWD"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Management (MIBM Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 72.48,
    "Category": "PWD"
  },
  {
    "Name": "SSVPS's Bapusaheb Shivajirao Deore College of Engineering (SSVPS's BSD COE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 70.78,
    "Category": "PWD"
  },
  {
    "Name": "Vidyavardhini's College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 69.31,
    "Category": "PWD"
  },
  {
    "Name": "Kalyani Charitable Trust's K.R.Sapkal College of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.34,
    "Category": "PWD"
  },
  {
    "Name": "K. K. Wagh Institute of Engineering Education and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.21,
    "Category": "PWD"
  },
  {
    "Name": "Shri Sai Institute of Management & Research, Aurangabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.22,
    "Category": "PWD"
  },
  {
    "Name": "Sadhu Vaswani Institute of Management Studies for Girls (SVIMS Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 67.05,
    "Category": "PWD"
  },
  {
    "Name": "Sipna College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.44,
    "Category": "PWD"
  },
  {
    "Name": "Al Ameen Education & Medical Foundations, College of Engineering & Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.27,
    "Category": "PWD"
  },
  {
    "Name": "Arihant Institute of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 65.15,
    "Category": "PWD"
  },
  {
    "Name": "G H Raisoni Institute of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.81,
    "Category": "PWD"
  },
  {
    "Name": "Mahatma Phule Institute of Management and Computer Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "PWD"
  },
  {
    "Name": "Trinity College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "PWD"
  },
  {
    "Name": "GNVS Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.29,
    "Category": "PWD"
  },
  {
    "Name": "Department of Commerce and Management - MBA Unit, Shivaji University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 68.21,
    "Category": "PWD"
  },
  {
    "Name": "Indira Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 67.17,
    "Category": "PWD"
  },
  {
    "Name": "Dr D Y Patil School of Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.44,
    "Category": "PWD"
  },
  {
    "Name": "IndSearch Institute of Management Studies and Research, Bavdhan Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.27,
    "Category": "PWD"
  },
  {
    "Name": "Sau Leena Kishor Mamidwar Institute of Mananagement Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 66.01,
    "Category": "PWD"
  },
  {
    "Name": "MMM's Institute of Management Education Research and Training",
    "Course": "Master of Business Administration (MBA)",
    "Score": 64.86,
    "Category": "PWD"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "MBA in Human Resource Management",
    "Score": 63.81,
    "Category": "PWD"
  },
  {
    "Name": "Progressive Education Society's Modern College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.77,
    "Category": "PWD"
  },
  {
    "Name": "Jayawant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.39,
    "Category": "PWD"
  },
  {
    "Name": "OIM - Oriental Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 63.1,
    "Category": "PWD"
  },
  {
    "Name": "North Maharashtra University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "PWD"
  },
  {
    "Name": "SIMCA - Sinhgad Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "PWD"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Human Resource Management",
    "Score": 63.03,
    "Category": "PWD"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Human Resource Management",
    "Score": 62.17,
    "Category": "PWD"
  },
  {
    "Name": "SKN Sinhgad School of Business Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.77,
    "Category": "PWD"
  },
  {
    "Name": "MES Institute of Management and Career Courses",
    "Course": "Discontinued (Aug 2021)- Master of Business Administration (MBA)",
    "Score": 61.49,
    "Category": "PWD"
  },
  {
    "Name": "Tirpude Institute of Management Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.01,
    "Category": "PWD"
  },
  {
    "Name": "Vilasrao Deshmukh College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.16,
    "Category": "PWD"
  },
  {
    "Name": "Sinhgad Institutes - Sinhgad Management Institutes",
    "Course": "Master in Management Studies (MMS)",
    "Score": 60.13,
    "Category": "PWD"
  },
  {
    "Name": "Jayawantrao Sawant Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 59.43,
    "Category": "PWD"
  },
  {
    "Name": "Marathwada Mitra Mandal's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "PWD"
  },
  {
    "Name": "Suryadatta Institute of Business Management and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 63.1,
    "Category": "PWD"
  },
  {
    "Name": "Ahirkar Institute of Management Studies",
    "Course": "Master of Business Administration(MBA)",
    "Score": 62.17,
    "Category": "PWD"
  },
  {
    "Name": "ASMA Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.77,
    "Category": "PWD"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.64,
    "Category": "PWD"
  },
  {
    "Name": "J.D.C. Bytco Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.1,
    "Category": "PWD"
  },
  {
    "Name": "Ashoka Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 61.01,
    "Category": "PWD"
  },
  {
    "Name": "Jayawantrao Sawant College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.13,
    "Category": "PWD"
  },
  {
    "Name": "G S College of Commerce and Economics",
    "Course": "Master of Business Administration (MBA)",
    "Score": 60.13,
    "Category": "PWD"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.43,
    "Category": "PWD"
  },
  {
    "Name": "Dhole Patil College of Engineering",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "PWD"
  },
  {
    "Name": "SIBAR - Sinhgad Institute of Business Administration and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "PWD"
  },
  {
    "Name": "KIT's Institute of Management Education and Research (KIT's IMER)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 58.12,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 56.44,
    "Category": "PWD"
  },
  {
    "Name": "Nashik District Maratha Vidya Prasarak Samaj's Karmaveer Adv. Baburao Thakare College of Engineering",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 55.53,
    "Category": "PWD"
  },
  {
    "Name": "Tirupati Institute of Management",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "PWD"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "PWD"
  },
  {
    "Name": "ASM's Institute of Business Management and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Moonje Institute of Management and Computer Studies,Nashik",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 54.57,
    "Category": "PWD"
  },
  {
    "Name": "Sinhgad Institute of Business Administration and Computer Application",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 54.03,
    "Category": "PWD"
  },
  {
    "Name": "G.H. Raisoni College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "PWD"
  },
  {
    "Name": "Vidya Bharati Mahavidyalaya, Amravati",
    "Course": "Master of Business Administration (MBA)",
    "Score": 58.26,
    "Category": "PWD"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Marketing Management",
    "Score": 57.71,
    "Category": "PWD"
  },
  {
    "Name": "Natwarlal Maniklal Dalal Postgraduate College of Arts and Commerce (N.M.D College)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 56.33,
    "Category": "PWD"
  },
  {
    "Name": "H.K. Institute of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 55.34,
    "Category": "PWD"
  },
  {
    "Name": "Tulsiramji Gaikwad-Patil College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.34,
    "Category": "PWD"
  },
  {
    "Name": "Sandip Institute of Technology and Research Centre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "PWD"
  },
  {
    "Name": "Prof. Ram Meghe Institute of Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 55.15,
    "Category": "PWD"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "MBA in Finance",
    "Score": 54.05,
    "Category": "PWD"
  },
  {
    "Name": "Rohidas Patil Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 54.03,
    "Category": "PWD"
  },
  {
    "Name": "D.K.T.E. Society's Textile and Engineering Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.77,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Business Management & Research,Chakan",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.44,
    "Category": "PWD"
  },
  {
    "Name": "Kala Institute Of Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.56,
    "Category": "PWD"
  },
  {
    "Name": "Suryadatta Institute of Management and Mass Communication",
    "Course": "MBA + CDP",
    "Score": 52.17,
    "Category": "PWD"
  },
  {
    "Name": "Rjspm's Institute Of Computer And Management Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "PWD"
  },
  {
    "Name": "Alamuri Ratnamala Institute of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 52.12,
    "Category": "PWD"
  },
  {
    "Name": "Sankalp Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "PWD"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.52,
    "Category": "PWD"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.34,
    "Category": "PWD"
  },
  {
    "Name": "Vidya Pratishthan's Institute of Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.09,
    "Category": "PWD"
  },
  {
    "Name": "Mitthulalji Sarda Mba College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.65,
    "Category": "PWD"
  },
  {
    "Name": "SOU Devibai Narayandas Chhabada Rural Education Society",
    "Course": "Master of Business Administration (MBA)",
    "Score": 53.02,
    "Category": "PWD"
  },
  {
    "Name": "Alard University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "PWD"
  },
  {
    "Name": "Shri Hanuman Vyayam Prasarak Mandal, College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "PWD"
  },
  {
    "Name": "G H Raisoni College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 52.17,
    "Category": "PWD"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "MBA in Marketing Management",
    "Score": 51.2,
    "Category": "PWD"
  },
  {
    "Name": "Pune Vidyarthi Griha's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.95,
    "Category": "PWD"
  },
  {
    "Name": "K.C.College of Engineering and Management Studies and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 50.35,
    "Category": "PWD"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Information Technology Systems Management",
    "Score": 50.34,
    "Category": "PWD"
  },
  {
    "Name": "G H Raisoni School of Business Management, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 50.09,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Technological University - DBATU",
    "Course": "MBA in Innovation Entrepreneurship and Venture Development",
    "Score": 49.86,
    "Category": "PWD"
  },
  {
    "Name": "VIVA Institute of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 49.08,
    "Category": "PWD"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Marketing Management",
    "Score": 48.79,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.51,
    "Category": "PWD"
  },
  {
    "Name": "Swayam Siddhi Mitra Sangh'S College of Management and Research",
    "Course": "Master of Management Studies (MMS)",
    "Score": 47.4,
    "Category": "PWD"
  },
  {
    "Name": "JSPMs Narhe Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "PWD"
  },
  {
    "Name": "Allana Institute of Management Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "PWD"
  },
  {
    "Name": "Dr.D.Y. Patil Center for Management and Research(MBA)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.52,
    "Category": "PWD"
  },
  {
    "Name": "S B Jain Institute of Technology, Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.48,
    "Category": "PWD"
  },
  {
    "Name": "Shri Shivaji Maratha Societys Institute of Management & Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Arun Motghare Mahavidyalaya",
    "Course": "Master of Business Administration(MBA)",
    "Score": 49.65,
    "Category": "PWD"
  },
  {
    "Name": "Genba Sopanrao Moze College Of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.86,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in IT",
    "Score": 48.55,
    "Category": "PWD"
  },
  {
    "Name": "NBN Sinhgad School of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 48.5,
    "Category": "PWD"
  },
  {
    "Name": "Pratibha Institute of Business Management (Kamala Education Society's)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 47,
    "Category": "PWD"
  },
  {
    "Name": "JSPMs Kautilya Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.68,
    "Category": "PWD"
  },
  {
    "Name": "Datta Meghe Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.52,
    "Category": "PWD"
  },
  {
    "Name": "SBS - Sinhgad Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.5,
    "Category": "PWD"
  },
  {
    "Name": "Imperial College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 46.29,
    "Category": "PWD"
  },
  {
    "Name": "Swami Ramanand Teerth Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.67,
    "Category": "PWD"
  },
  {
    "Name": "MKSSs Smt. Hiraben Nanavati Institute of Management and Research for Women",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Panjabrao Deshmukh Institute of Management Technology and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Technical Education, Research and Management, Akurdi",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "PWD"
  },
  {
    "Name": "Chintamanrao Institute of Management Development and Research (CIMDR Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.96,
    "Category": "PWD"
  },
  {
    "Name": "Jankidevi Bajaj Institute of Management Studies (JDBIMS Pune)",
    "Course": "Master of Business Administration(MBA)",
    "Score": 44.35,
    "Category": "PWD"
  },
  {
    "Name": "Hirachand Nemchand College of Commerce (HNCC)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 43.85,
    "Category": "PWD"
  },
  {
    "Name": "Unique Institute of Management (UIM, Pune)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.85,
    "Category": "PWD"
  },
  {
    "Name": "College Of Management And Computer Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.15,
    "Category": "PWD"
  },
  {
    "Name": "Sanjivani College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.78,
    "Category": "PWD"
  },
  {
    "Name": "Deshbhakt Ratnappa Kumbhar College of Commerce (DRKCC, Kolhapur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "PWD"
  },
  {
    "Name": "Priyadarshini Lokmanya Tilak Institute of Management Studies and Research (PLTIMSR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "PWD"
  },
  {
    "Name": "Dr.D.Y.Patil Institute Of Management & Entrepreneur Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.45,
    "Category": "PWD"
  },
  {
    "Name": "Shri Neminath Jain Bhhramcharyashram",
    "Course": "Master of Business Administration (MBA)",
    "Score": 45.13,
    "Category": "PWD"
  },
  {
    "Name": "Rasiklal M Dhariwal Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.83,
    "Category": "PWD"
  },
  {
    "Name": "Zeal Institute Of Business Administration, Computer Application and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 44.02,
    "Category": "PWD"
  },
  {
    "Name": "Faculty of Engineering, D.Y Patil Technical Campus -Talsande",
    "Course": "Master of Business Administration (MBA)",
    "Score": 42.99,
    "Category": "PWD"
  },
  {
    "Name": "Shivajirao S. Jondhale College of Engineering and Technology",
    "Course": "Master of Management Studies (MMS)",
    "Score": 42.7,
    "Category": "PWD"
  },
  {
    "Name": "Tasgaonkar Group of Institutions",
    "Course": "Master of Management Studies (MMS)",
    "Score": 41.78,
    "Category": "PWD"
  },
  {
    "Name": "G.S College of Commerce,Wardha",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.78,
    "Category": "PWD"
  },
  {
    "Name": "SVPM's Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "PWD"
  },
  {
    "Name": "Prin. N.G. Naralkar Institute of Career Development and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 41.12,
    "Category": "PWD"
  },
  {
    "Name": "Siddhant Institute of Business Management, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.92,
    "Category": "PWD"
  },
  {
    "Name": "NavSahyadri Charitable Trust's, Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.64,
    "Category": "PWD"
  },
  {
    "Name": "A. C. Patil College of Engineering",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.07,
    "Category": "PWD"
  },
  {
    "Name": "Adsul's Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.5,
    "Category": "PWD"
  },
  {
    "Name": "G H Raisoni Institute of Management and Research, Khaparheda Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "PWD"
  },
  {
    "Name": "KDK College of Engineering, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "PWD"
  },
  {
    "Name": "SGSJK's Aruna Manharlal Shah Institute of Management and Research (AMSIMR, Mumbai)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.14,
    "Category": "PWD"
  },
  {
    "Name": "Rajaram Shinde College of MBA",
    "Course": "Masters of Management Studies (MMS)",
    "Score": 37.59,
    "Category": "PWD"
  },
  {
    "Name": "Central India College of Business Management and Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.43,
    "Category": "PWD"
  },
  {
    "Name": "Rajeev Gandhi College of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 39.95,
    "Category": "PWD"
  },
  {
    "Name": "D B Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.88,
    "Category": "PWD"
  },
  {
    "Name": "Foster Development School of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 39.42,
    "Category": "PWD"
  },
  {
    "Name": "Hashmatrai and Gangaram Himathmal Mansukhani Institute of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.52,
    "Category": "PWD"
  },
  {
    "Name": "SNG Institute Of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.47,
    "Category": "PWD"
  },
  {
    "Name": "Deogiri Institute of Engineering and Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.3,
    "Category": "PWD"
  },
  {
    "Name": "Sura Institute of Business Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 38.3,
    "Category": "PWD"
  },
  {
    "Name": "R.M. Dhariwal Sinhgad Management School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 38.14,
    "Category": "PWD"
  },
  {
    "Name": "Karmaveer Bhaurao Patil Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 37.47,
    "Category": "PWD"
  },
  {
    "Name": "Pramod Ram Ujagar Tiwari Saket Institute Of Management",
    "Course": "Master of Management Studies (MMS)",
    "Score": 36.51,
    "Category": "PWD"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 36.3,
    "Category": "PWD"
  },
  {
    "Name": "Swargiya Shri Laxmanji Motghare Charitable Trust, Dr. Arun Motghare College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.99,
    "Category": "PWD"
  },
  {
    "Name": "Matrix Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.08,
    "Category": "PWD"
  },
  {
    "Name": "Amrutvahini Institute of Management and Business Administration",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "PWD"
  },
  {
    "Name": "Pune Vidyarthi Griha's College Of Engineering",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.83,
    "Category": "PWD"
  },
  {
    "Name": "Matoshri College of Management & Research Centre,Eklahare",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.82,
    "Category": "PWD"
  },
  {
    "Name": "PVG's College of Engineering and Technology and G.K. Pate (Wani) Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 34.77,
    "Category": "PWD"
  },
  {
    "Name": "Smt. Radhikatai Pandav College of Engineering (SRPCE)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.02,
    "Category": "PWD"
  },
  {
    "Name": "Punyashlok Ahilyadevi Holkar Solapur University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.98,
    "Category": "PWD"
  },
  {
    "Name": "Rajarambapu Institute of Technology",
    "Course": "MBA in Innovation Entrepreneurship And Venture Development",
    "Score": 33.16,
    "Category": "PWD"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 36.3,
    "Category": "PWD"
  },
  {
    "Name": "Venkateshwara Institue of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.85,
    "Category": "PWD"
  },
  {
    "Name": "SND College of Engineering & Research Center",
    "Course": "Master of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "PWD"
  },
  {
    "Name": "Progressive Education Societys Modern Institute of Business Studies, (MIBS Pune)",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 35.03,
    "Category": "PWD"
  },
  {
    "Name": "Amrutvahini College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.82,
    "Category": "PWD"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 34.82,
    "Category": "PWD"
  },
  {
    "Name": "Pravara Institute of Research and Education in Natural and Social Sciences",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.11,
    "Category": "PWD"
  },
  {
    "Name": "Wainganga College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 34.01,
    "Category": "PWD"
  },
  {
    "Name": "Ozar Vikas Sanstha's Vishwasatya Arts and Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 33.34,
    "Category": "PWD"
  },
  {
    "Name": "Swayam Siddhi College of Management and Research (SSCMR)",
    "Course": "Master in Management Studies (MMS)",
    "Score": 33.13,
    "Category": "PWD"
  },
  {
    "Name": "Anekant Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.96,
    "Category": "PWD"
  },
  {
    "Name": "Navjeevan Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "PWD"
  },
  {
    "Name": "Swaminarayan Siddhanta Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Research and Technology, Nashik",
    "Course": "MBA in Human Resource Development",
    "Score": 31.21,
    "Category": "PWD"
  },
  {
    "Name": "Suryodaya College of Engineering & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "PWD"
  },
  {
    "Name": "YRES's Bapurao Deshmukh College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.03,
    "Category": "PWD"
  },
  {
    "Name": "Sai Krupa Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.56,
    "Category": "PWD"
  },
  {
    "Name": "Dattakala Group of Institution",
    "Course": "Master of Business Administration (MBA)",
    "Score": 29.97,
    "Category": "PWD"
  },
  {
    "Name": "Rajendra Mane College of Engineering and Technology (RMCET)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 28.65,
    "Category": "PWD"
  },
  {
    "Name": "Rajgad Institute of Management Research & Development",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.03,
    "Category": "PWD"
  },
  {
    "Name": "Brahmdevdada Mane Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.92,
    "Category": "PWD"
  },
  {
    "Name": "ASM Institute of Professional Studies, Pune",
    "Course": "Master of Business Administration (MBA)",
    "Score": 32.54,
    "Category": "PWD"
  },
  {
    "Name": "Yashoda Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "PWD"
  },
  {
    "Name": "Lotus Business School",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.21,
    "Category": "PWD"
  },
  {
    "Name": "Ranibai Agnihotri Institute of Computer Information Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 31.03,
    "Category": "PWD"
  },
  {
    "Name": "Brahma Valley College of Engineering and Research Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.56,
    "Category": "PWD"
  },
  {
    "Name": "Institute Of Management and Science,Sakegaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 30.18,
    "Category": "PWD"
  },
  {
    "Name": "Zeal College of Engineering and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 28.68,
    "Category": "PWD"
  },
  {
    "Name": "Parvatibai Genba Moze College of Engineering",
    "Course": "Master of business Administration (MBA)",
    "Score": 28.25,
    "Category": "PWD"
  },
  {
    "Name": "P.K. Technical Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.88,
    "Category": "PWD"
  },
  {
    "Name": "Sir Visvesvaraya Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.88,
    "Category": "PWD"
  },
  {
    "Name": "Nirmal Krida and Samaj Prabodhan Trust Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.72,
    "Category": "PWD"
  },
  {
    "Name": "Shri Bhairavnath Shikshan Prasarak Mandals Adhalrao Patil Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.5,
    "Category": "PWD"
  },
  {
    "Name": "D. B. Group of Institutes, Latur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.21,
    "Category": "PWD"
  },
  {
    "Name": "Prin. K.P. Mangalvedhekar Institute of Management Career Development and Research",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 26.46,
    "Category": "PWD"
  },
  {
    "Name": "Matoshri Ushatai Jadhav Institute of Management Studies and Research Centre (MUIMRC)",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25.25,
    "Category": "PWD"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "M.B.A. in Marketing Management",
    "Score": 25.25,
    "Category": "PWD"
  },
  {
    "Name": "Shri Vithal Education and Research Institute's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "PWD"
  },
  {
    "Name": "Ashokrao Mane Group of Institutions (AMGOI)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.47,
    "Category": "PWD"
  },
  {
    "Name": "K.B.H. Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "PWD"
  },
  {
    "Name": "Matsyodari Shikshan Sanstha's College of Engineering and Technology, Jalna",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.72,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management and Business Administration, Akole",
    "Course": "Master of Business Administration (MBA)",
    "Score": 27.5,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Vithalrao Vikhe Patil Foundation's College of Pharmacy",
    "Course": "Master of Business Administration(MBA)",
    "Score": 27.5,
    "Category": "PWD"
  },
  {
    "Name": "R. C. Patel Institute of Pharmaceutical Education and Research",
    "Course": "MBA in Pharmaceutical Management",
    "Score": 26.57,
    "Category": "PWD"
  },
  {
    "Name": "Sahyadri Institute of Management & Research,Sawarde",
    "Course": "Master of Management Studies (MMS)",
    "Score": 25.28,
    "Category": "PWD"
  },
  {
    "Name": "JD College of Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 25.25,
    "Category": "PWD"
  },
  {
    "Name": "Navsahyadri Education Society's Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "PWD"
  },
  {
    "Name": "International Institute of Management Science",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.5,
    "Category": "PWD"
  },
  {
    "Name": "Hon. Shri. Babanrao Pachpute Vichardhara Trust's Parikrama Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 24.47,
    "Category": "PWD"
  },
  {
    "Name": "Kamla Nehru Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "PWD"
  },
  {
    "Name": "Rasiklal M. Dhariwal Sinhgad Technical Institutes Campus",
    "Course": "Master of Business Administration (MBA)",
    "Score": 23.77,
    "Category": "PWD"
  },
  {
    "Name": "Shri Someshwar Shikshan Prasarak Mandals Sharadchandra Pawar Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "PWD"
  },
  {
    "Name": "Dr. Babasaheb Ambedkar Marathwada University",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "PWD"
  },
  {
    "Name": "M.S. College of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "PWD"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "MBA in Agri Business Management/ Entrepreneurship",
    "Score": 22.41,
    "Category": "PWD"
  },
  {
    "Name": "Aakar Institute of Management and Research Studies",
    "Course": "Master of Marketing Management",
    "Score": 21.73,
    "Category": "PWD"
  },
  {
    "Name": "KJ's Educational Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.3,
    "Category": "PWD"
  },
  {
    "Name": "Abasaheb Garware Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 20.47,
    "Category": "PWD"
  },
  {
    "Name": "Pirens Institute of Business Management and Administration",
    "Course": "Masters of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "PWD"
  },
  {
    "Name": "Maharashtra Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.23,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Business Management and Rural Development (IMBRD)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "PWD"
  },
  {
    "Name": "Sanjivani Institute Of Technology and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.81,
    "Category": "PWD"
  },
  {
    "Name": "Kasturi Shikshan Sansta's Institute of Management, Shikhrapur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "PWD"
  },
  {
    "Name": "SSBTs College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 22.75,
    "Category": "PWD"
  },
  {
    "Name": "KT Patil College of Engineering and Technology, Osmanabad",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.75,
    "Category": "PWD"
  },
  {
    "Name": "U.D. Pathrikar Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.36,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management Studies and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 21.27,
    "Category": "PWD"
  },
  {
    "Name": "Shri Khanderai Pratishthan Dnyansagar Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "PWD"
  },
  {
    "Name": "Mandukarrao Pandav College of Engineering, Bhandara",
    "Course": "Master of Business Administration (MBA)",
    "Score": 19.38,
    "Category": "PWD"
  },
  {
    "Name": "Real Institute Of Management And Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.65,
    "Category": "PWD"
  },
  {
    "Name": "Shantiniketan Business School (SBS)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.6,
    "Category": "PWD"
  },
  {
    "Name": "ZEAL Institute of Management and Computer Application",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.57,
    "Category": "PWD"
  },
  {
    "Name": "Global Institute of Management,Sangamner",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "PWD"
  },
  {
    "Name": "Nabira Mahavidyalaya",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "PWD"
  },
  {
    "Name": "Central Institute of Business Management Research and Development (CIBMRD Nagpur)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.13,
    "Category": "PWD"
  },
  {
    "Name": "Nashik Gramin Shikshan Prasarak Mandal, Brahma Valley Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.11,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.48,
    "Category": "PWD"
  },
  {
    "Name": "Ballarpur Institute of Technology (BIT Maharashtra)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 14.02,
    "Category": "PWD"
  },
  {
    "Name": "Rajarshi Shahu Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 13.36,
    "Category": "PWD"
  },
  {
    "Name": "V.P. Institute of Management Studies & Research,Sangli",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.23,
    "Category": "PWD"
  },
  {
    "Name": "CP and Berar Arts Commerce College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.57,
    "Category": "PWD"
  },
  {
    "Name": "International Centre of Excellence in Engineering and Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.3,
    "Category": "PWD"
  },
  {
    "Name": "Sharadchandra Pawar Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 18.24,
    "Category": "PWD"
  },
  {
    "Name": "Jhulelal Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 17.47,
    "Category": "PWD"
  },
  {
    "Name": "Ideal Institute of Management, Kondigre",
    "Course": "Master of Business Administration (MBA)",
    "Score": 16.48,
    "Category": "PWD"
  },
  {
    "Name": "KCE Society's Institute of Management and Research (IMR Jalgon)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.67,
    "Category": "PWD"
  },
  {
    "Name": "Shreeyash College of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 15.01,
    "Category": "PWD"
  },
  {
    "Name": "Nagpur Institute of Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 13.84,
    "Category": "PWD"
  },
  {
    "Name": "Chetan Dattaji Gaikwad Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 12.52,
    "Category": "PWD"
  },
  {
    "Name": "Khandesh College Education Society'S College of Engineering and Management",
    "Course": "M.B.A in FinTech",
    "Score": 12.12,
    "Category": "PWD"
  },
  {
    "Name": "Pune District Education Association's College of Engineering",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.92,
    "Category": "PWD"
  },
  {
    "Name": "Marathwada Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.57,
    "Category": "PWD"
  },
  {
    "Name": "Mahatma Gandhi Vidyamandir's Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.04,
    "Category": "PWD"
  },
  {
    "Name": "Chhatrapati Shahu Institute of Business Education and Research",
    "Course": "MBA in Environment Management",
    "Score": 9.94,
    "Category": "PWD"
  },
  {
    "Name": "Samarth Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.79,
    "Category": "PWD"
  },
  {
    "Name": "G H Raisoni Institute of Business Management, Jalgaon",
    "Course": "MBA in Family Managed Business",
    "Score": 8.71,
    "Category": "PWD"
  },
  {
    "Name": "Swaraj Education Society's Swaraj Institute Of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.13,
    "Category": "PWD"
  },
  {
    "Name": "Matoshri Pratishthan Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "PWD"
  },
  {
    "Name": "Guru Nanak Institute of Engineering and Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "PWD"
  },
  {
    "Name": "Shri Swami Samarth Institute of Management & Technology",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.88,
    "Category": "PWD"
  },
  {
    "Name": "Shrimant Jayshreemaladevi Naik-nimbalkar Institute Of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 10.78,
    "Category": "PWD"
  },
  {
    "Name": "Dnyansagar Institute of Management and Research-DIMR",
    "Course": "Master of Business Administration (Prime)",
    "Score": 10.38,
    "Category": "PWD"
  },
  {
    "Name": "Govindrao Wanjari College of Engineering and Technology, Nagpur",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.97,
    "Category": "PWD"
  },
  {
    "Name": "Jaywant Institute of Management",
    "Course": "Master of Business Administration (MBA)",
    "Score": 9.62,
    "Category": "PWD"
  },
  {
    "Name": "Sushila Suryawanshi Management Institute of Technology Advancement, Amravati",
    "Course": "M.B.A. in Marketing",
    "Score": 8.79,
    "Category": "PWD"
  },
  {
    "Name": "Green Heaven Institute of Management and Research (GHIMR)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 8.7,
    "Category": "PWD"
  },
  {
    "Name": "J.M.Patel Arts,Commerce and Science College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 7.06,
    "Category": "PWD"
  },
  {
    "Name": "Shreeyash Institute of Management (SIM)",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.23,
    "Category": "PWD"
  },
  {
    "Name": "Jai Hind College, Mumbai",
    "Course": "Master of Business Administration (MBA)",
    "Score": 6.19,
    "Category": "PWD"
  },
  {
    "Name": "Dr. R. G. Bhoyar Group of Institutions",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.48,
    "Category": "PWD"
  },
  {
    "Name": "Renaissance Institute of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 5.07,
    "Category": "PWD"
  },
  {
    "Name": "Godavari Institute Of Management & Research, Jalgaon",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.79,
    "Category": "PWD"
  },
  {
    "Name": "Suraj Institute of Management, Beed",
    "Course": "MBA in Marketing Management",
    "Score": 3.25,
    "Category": "PWD"
  },
  {
    "Name": "Kishori College of MBA",
    "Course": "MBA in Finance",
    "Score": 2.39,
    "Category": "PWD"
  },
  {
    "Name": "Kasturi Education Sanstha's College of Education",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "PWD"
  },
  {
    "Name": "Vishwakarma Dadasaheb Chavan Institute of Management and Research",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.24,
    "Category": "PWD"
  },
  {
    "Name": "Aditya Engineering College, Beed",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.86,
    "Category": "PWD"
  },
  {
    "Name": "Akole Taluka Education Societys Technical Campus College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.83,
    "Category": "PWD"
  },
  {
    "Name": "Sanjeevan Engineering and Technology Institute (SETI, Panhala)",
    "Course": "M.B.A. in Business Analytics",
    "Score": 1.13,
    "Category": "PWD"
  },
  {
    "Name": "University Sub Center Latur",
    "Course": "Master of Business Administration(MBA)",
    "Score": 0.3,
    "Category": "PWD"
  },
  {
    "Name": "Jay Bhavani Institute of Management",
    "Course": "Master of Business Administration(MBA)",
    "Score": 4.47,
    "Category": "PWD"
  },
  {
    "Name": "AMI Pune Arham Adhyan Management Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 3.79,
    "Category": "PWD"
  },
  {
    "Name": "Nagarjuna Institute of Engineering, Technology & Management",
    "Course": "MBA in Finance Marketing and Human Resource Management",
    "Score": 2.74,
    "Category": "PWD"
  },
  {
    "Name": "Dattakala Institute",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "PWD"
  },
  {
    "Name": "Sanjay Bhokare Group of Institutes",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.38,
    "Category": "PWD"
  },
  {
    "Name": "Shikshan Prasarak Mandal's Deptartment of Management Studies",
    "Course": "Master of Business Administration (MBA)",
    "Score": 2.01,
    "Category": "PWD"
  },
  {
    "Name": "Institute of Technology and Management, Nanded",
    "Course": "Master of Business Administration (MBA)",
    "Score": 1.83,
    "Category": "PWD"
  },
  {
    "Name": "S.A.V Acharya Institute of Management Studies",
    "Course": "Master of Management Studies (MMS)",
    "Score": 1.29,
    "Category": "PWD"
  },
  {
    "Name": "Shivajirao S. Jondhle Institute of Management Science and Research",
    "Course": "Master of Management Studies",
    "Score": 0.6,
    "Category": "PWD"
  },
  {
    "Name": "Aditya M.B.A College",
    "Course": "Master of Business Administration (MBA)",
    "Score": 0.11,
    "Category": "PWD"
  }
]